
<div class="card mt-5" style="max-width: 450px; margin:0 auto">
  <div class="card-header bg-secondary text-white">
    <h5>Reset Password</h5>
  </div>
  <div class="card-body">
    <form [formGroup]="resetForm" (ngSubmit)="reset()" class="form-signin text-center">
      <div>
        <img alt="OpinionRoute NavigatOR" class="mb-5 w-100" src="/assets/imgs/OpinionRoute.jpg" />
      </div>

      <input type="email" class="form-control" [ngClass]="{'is-invalid': resetForm.get('email').errors && resetForm.get('email').touched}" id="email" formControlName="email" aria-describedby="emailHelp" placeholder="Email">
      <div class="invalid-feedback">Please enter a valid email</div>
      <input type="password" class="form-control my-2" [ngClass]="{'is-invalid': resetForm.get('password').errors && resetForm.get('password').touched}" id="password" value="password"  formControlName="password" placeholder="Password">
      @if (resetForm.get('password').hasError('required') && resetForm.get('password').touched) {
        <div class="invalid-feedback" >Password is required</div>
      }
      @if (resetForm.get('password').hasError('passwordComplexity') && resetForm.get('password').touched) {
        <div class="invalid-feedback" >Password must be at least 6 characters and contain an uppercase and lowercase character, a digit, and a non-alphanumeric character</div>
      }
      <input type="password" class="form-control mt-2" [ngClass]="{'is-invalid': resetForm.get('confirmPassword').errors && resetForm.get('confirmPassword').touched ||resetForm.get('confirmPassword').touched &&resetForm.hasError('mismatch')}" id="confirmPassword" value="password"  formControlName="confirmPassword" placeholder="Confirm Password">
      @if (resetForm.get('confirmPassword').hasError('required') && resetForm.get('confirmPassword').touched ) {
        <div class="invalid-feedback" >Confirm password is required</div>
      }
      @if (resetForm.hasError('mismatch') && resetForm.get('confirmPassword').touched ) {
        <div class="invalid-feedback" >Passwords must match</div>
      }

      <button class="btn btn-lg btn-secondary btn-block mt-3" [disabled]="!resetForm.valid" type="submit">Reset</button>
    </form>
  </div>
  <div class="card-footer text-center position-relative or-secondary-bg text-white">
    <small>© 2025 OpinionRoute, LLC. | All Rights Reserved.</small>
  </div>
</div>
