<div class="row d-flex align-items-center mx-0 mb-4">
  <div class="col p-0">
    <span class="nav-secondary nav-font18 font-weight-bold">Quality</span>
  </div>
</div>
<div class="card idsuite my-2">
  @if (!clientMode) {
    <div class="card-header" >
      <div class="row">
        <div class="col-11 p-0">
          <div class="d-flex">
            <div class="sectionbtn nav-item">
              <a class="nav-link or-primary cursor-pointer mb-2" [class.font-weight-bold]="section == 'CleanID'"
              (click)="loadSection('CleanID')">Project</a>
              @if (section == 'CleanID') {
                <a class="btn nav-btn-accent btn-sm mr-2 shadow-none ml-3"
                  href="https://nav2.navigatorsurveys.com/onlinehelp?page=en/kb/articles/cleanid-card" id="help_link"
                  target="_new"><i class="fa-regular fa-book-open"></i>&nbsp;&nbsp;View
                Knowledge Base</a>
              }
              @if (section == 'ResponseID') {
                <a class="btn nav-btn-accent btn-sm mr-2 shadow-none ml-3"
                  href="https://nav2.navigatorsurveys.com/onlinehelp?page=en/kb/articles/quality-tab-project-responseid"
                  id="help_link" target="_new"><i class="fa-regular fa-book-open"></i>&nbsp;&nbsp;View
                Knowledge Base</a>
              }
            </div>
            <div class="sectionbtn nav-item ml-4">
              <a class="nav-link or-primary cursor-pointer mb-2" [class.font-weight-bold]="section == 'ResponseID'"
              (click)="loadSection('ResponseID')">ResponseID</a>
            </div>
          </div>
        </div>
        <div class="col-1 p-0 text-right px-4">
          @if (section == 'CleanID') {
            <div class=" or-secondary mt-1">
              <i (click)="!reportLoading && previewingReport=true"
                class="fas fa-eye fa-lg cursor-pointer ml-2 opacity-90" [class.disabled]="reportLoading" container="body" tooltip="Open Data Quality Report" placement="left"></i>
            </div>
          }
        </div>
      </div>
    </div>
  }

  <div class="card-body py-2">
    <div class="row">
      <iframe #myIframe frameborder="0" width="100%" height="2000" allowtransparency></iframe>
    </div>
  </div>
</div>

<!-- MODAL PREVIEW -->
@if (previewingReport) {
  <app-project-data-quality-report [project]="_project" (reportClosed)="previewingReport=false" (reportIsLoading)="reportLoading=$event"></app-project-data-quality-report>
}
