import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AlertifyService, ProjectService, UtilsService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UnsavedchangesGuardService } from '../../../_guards/unsavedchanges.guard.service';
import { ProjectThirdpartyStatusComponent } from '../project-thirdparty-status/project-thirdparty-status.component';

@Component({
  selector: 'app-project-card-field-plan',
  templateUrl: './project-card-field-plan.component.html',
  styleUrls: ['./project-card-field-plan.component.scss'],
  providers: [ProjectThirdpartyStatusComponent]
})
export class ProjectCardFieldPlanComponent implements OnInit, OnDestroy {
  @Input() title;
  @Input() public set details(e) {
    this.project = e.project;
    this.projectFieldLimitEnabled = this.project.quotaMode !== 'Off';
    // FOR NOW WE JUST SHOW SEGMENT - NEED TO LOOK AT NEW MULTI SEGMENT UI
    this.projectSurveyPartners =
      this.project.projectSegments.length === 0
        ? []
        : this.project.projectSegments[0].projectSurveyPartners;
    this.projectSurveyPartnersNotDeleted = this.projectSurveyPartners.filter(x => !x.isDeleted);
    if (this.projectSurveyPartnersNotDeleted.length > 6) {
      this.vendorAmountInColOne = Math.ceil(this.projectSurveyPartnersNotDeleted.length / 2);
    }
    var atLeastOneVendorEnforced = false
    this.projectSurveyPartnersNotDeleted.forEach(partner => {
      partner.editEnforcePartnerQuota = partner.enforcePartnerQuota;
      if (partner.enforcePartnerQuota) atLeastOneVendorEnforced = true;
    });
    this.atLeastOneVendorEnforced = atLeastOneVendorEnforced;
    this.processData(e.data);
  }

  @Input() mode = 'reduced';
  @Output() cardresize = new EventEmitter();
  @Output() redirectToSection = new EventEmitter();

  project: any;
  completes;
  partnerCompletes: any = {};
  loading = false;
  projectSurveyPartners: any;
  projectSurveyPartnersNotDeleted: any;
  projectStats: any;
  projectFieldLimitEnabled: boolean;
  unsavedChanges = 0;
  bsModalRef: BsModalRef;
  atLeastOneVendorEnforced = false;
  vendorAmountInColOne = 3;
  allocationEnforcementChange = false;
  vendorsSortedBy = 'partnerName-asc';

  constructor(
    private projectService: ProjectService,
    private alertify: AlertifyService,
    private utilService: UtilsService,
    public utils: UtilsService,
    private modalService: BsModalService,
    public thirdPartyComponent: ProjectThirdpartyStatusComponent,
    private unsavedChangesService: UnsavedchangesGuardService) {}

  ngOnInit() {
    this.unsavedChangesService.register(
      this,
      () => this.unsavedChanges > 0
    );
  }

  ngOnDestroy(): void {
    this.unsavedChangesService.deregister([this]);
  }

  processData(data) {
    if (data == null) {
      return;
    }

    let completes: any = [];
    data.forEach((e) => {
      e.completeLois.forEach((c) => {
        completes.push({
          partnerId: e.vendorId,
          date: new Date(e.date).toDateString(),
          loi: c,
        });
      });
    });

    this.projectSurveyPartners
      .filter((e) => e.isDeleted === false)
      .forEach((partner) => {
        this.partnerCompletes[partner.partnerName] = completes.filter(
          (c) => c.partnerId.toLowerCase() === partner.partnerId.toLowerCase()
        ).length;
      });

    this.completes = completes;
  }

  resizeCard(type = null) {
    this.mode = this.mode === 'expanded' ? 'reduced' : 'expanded';
    this.cardresize.emit(this.mode);
  }

  projectStatusChanged(status) {
    this.project.projectStatus = status;
  }

  copyUrl(parnerId){
    let ee= this.projectSurveyPartners.filter(
      (c) => c.partnerId.toLowerCase() === parnerId.toLowerCase());

    let url = ee[0].surveyEntryUrl;
    this.utils.copyTextToClipboard(url);
    this.alertify.success('Link Copied');
  }

  updatePartnerStatus(status, partner) {
    if(partner == null) {
      this.project.projectSegments[0].projectSurveyPartners.forEach((e) => {
        e.partnerStatus = status;
      });
    }
    else {
      partner.partnerStatus = status;
    }
  }

  enableEdit(item, attr, type) {
    this.unsavedChanges++;
    let val = item[attr];
    if (type === 'date') {
      val = new Date(item[attr]);
    }

    if (item.editMode == null) {
      item.editMode = {};
    }
    item.editMode[attr] = { enabled: true, value: val };
  }

  cancelPartnerAllocationChange(partner, type) {
    partner.editMode[type] = null;
    this.unsavedChanges--;
  }

  savePartnerAllocationChange(partner, type) {
    const newValue = partner.editMode[type].value;
    const oldValue = partner[type];
    partner.editMode[type] = null;
    partner[type] = newValue;

    this.projectService.UpdateProjectPartnerAllocation(this.project.id, partner.partnerId, newValue, type === 'partnerSoftQuota').subscribe((data) => {
          this.alertify.success('Allocation updated');
        },
        (error) => {
          this.alertify.error(
            'There was an issue updating this allocation'
          );
          partner[type] = oldValue;
        }
      );
    this.unsavedChanges--;
  }

  handleAllocationEnforcementChange(enforce, partner = null) {
    if (partner) {
      this.savePartnerAllocationEnforcement(partner, enforce);
    }
    else if (this.allocationEnforcementChange){
      this.bulkSavePartnerAllocationEnforcement(this.projectSurveyPartnersNotDeleted.filter(x => x.editEnforcePartnerQuota), enforce);
    }
  }

  savePartnerAllocationEnforcement(partner, event) {
    var enforce = event.target.checked;
    var oldValue = partner.enforcePartnerQuota == true;

    this.projectService.UpdateProjectPartnerAllocationEnforcement(this.project.id, partner.partnerId, enforce).subscribe((data) => {
      this.alertify.success('Vendor limit enforcement updated');
      partner.enforcePartnerQuota = enforce;
      this.atLeastOneVendorEnforced = this.projectSurveyPartnersNotDeleted.some(x => x.enforcePartnerQuota);
      },
      (error) => {
        this.alertify.error(
          'There was an issue updating this partner'
        );
        partner.editEnforcePartnerQuota = oldValue;
      }
    );
  }

  bulkSavePartnerAllocationEnforcement(partners, enforce) {
    this.projectService.BulkUpdateProjectPartnerAllocationEnforcement(this.project.id, partners.map(x => x.partnerId), enforce).subscribe((data) => {
      this.alertify.success('Vendor limit enforcement updated');
      partners.forEach(partner => {
        partner.enforcePartnerQuota = enforce;
      });        
      this.atLeastOneVendorEnforced = this.projectSurveyPartnersNotDeleted.some(x => x.enforcePartnerQuota);
      this.allocationEnforcementChange = false;
      },
      (error) => {
        this.alertify.error(
          'There was an issue updating this partner'
        );
        partners.forEach(partner => {
          partner.editEnforcePartnerQuota = !enforce;
      });
    });
  }

  cancelProjectAllocationChange(project) {
    if (project.editMode.fullLaunchQuota) {
      project.editMode.fullLaunchQuota.value = project.fullLaunchQuota;
      project.editMode.fullLaunchQuota = null;
    }
    else {
      project.editMode.softLaunchQuota.value = project.softLaunchQuota;
      project.editMode.softLaunchQuota = null;
    }
    this.unsavedChanges--;
  }

  saveProjectAllocationChange(project, type) {
    const newValue = project.editMode[type].value;
    const oldValue = project[type];
    project.editMode[type] = null;
    project[type] = newValue;

    this.projectService.UpdateProjectValue(project.id, type, newValue).subscribe((data) => {
          this.processData(this.completes);
          this.alertify.success('Allocation updated');
        },
        (error) => {
          this.alertify.error(
            'There was an issue updating this allocation'
          );
          project[type] = oldValue;
        }
      );
    this.unsavedChanges--;
  }

  copyLink(partner) {
    this.utilService.copyTextToBuffer(partner.surveyEntryUrl);
    this.alertify.success('Partner Entry URL copied');
  }

  changeAllocationCheck(value) {
    const mode = this.projectFieldLimitEnabled ? 'Complete' : 'Off';
    this.loading = true;
    this.projectService.UpdateProjectValue(this.project.id, 'quotaMode', mode).subscribe((data) => {
          this.alertify.success('Limits enforced has been updated for this project');
          this.project.quotaMode = mode;
          this.loading = false;
        },
        (error) => {
          this.alertify.error('Failed to update Limits enforced on this project');
          this.projectFieldLimitEnabled = this.project.quotaMode !== 'Off';
          this.loading = false;
        }
      );
  }

  public openModal(mode) {
    const initialState = { project: this.project, mode};
    this.bsModalRef = this.modalService.show(ProjectThirdpartyStatusComponent, {ignoreBackdropClick: true, keyboard: false, class: 'modal-full', initialState });
    this.bsModalRef.content.saveevent.subscribe((data) => {
      // this.GetProjectStatusThirdParties();
    });
  }

  navigateToLinksAndVendors() {
    this.redirectToSection.emit('links-and-vendors');
  }

  sortVendors(sortBy) {
    this.vendorsSortedBy = sortBy;
    sortBy = sortBy.replace('-asc', '');
    sortBy = sortBy.replace('-desc', '');
    this.projectSurveyPartners.sort((a, b) => {
      if (a[sortBy] === b[sortBy] && sortBy !== 'partnerName') {
        return a.partnerName.localeCompare(b.partnerName);
      }
      if (this.vendorsSortedBy.includes('-desc')) {
        return b[sortBy].localeCompare(a[sortBy]);
      }
      return a[sortBy].localeCompare(b[sortBy]);
    });


  }
}
