<div class="nav-content">
  <!-- BREADCRUMBS -->
  <div class="row">
    <div class="col-12 breadcrumb mr-auto bg-transparent mb-3 py-0">
      <div class="d-flex align-items-center">
        <div class="nav-secondary d-flex align-items-center">
          <app-pagetitle></app-pagetitle>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header text-white  or-primary-bg">
        <span class="h4"><i class="fas fa-chart-bar"></i> Report Builder</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col mb-4">
            <div class="card step-{{step}}">
              <div class="card-header bg-white border-bottom-0 pb-0">
                @if (step < 2) {
                  <span class="h5 or-primary mb-0">Create New Report</span>
                }

                @if (step > 1) {
                  <div class="row">
                    <div class="col">
                      <div class="or-secondary">
                        @if (step > 1) {
                          <small class="cursor-pointer"><u (click)="step1()">Reports</u></small>
                        }
                        @if (step == 2) {
                          <small class="cursor-pointer"> / Fields</small>
                        }
                        @if (step > 2) {
                          <small class="cursor-pointer"> / <span (click)="step2(report.reportType)"><u>Fields</u></span></small>
                        }
                        @if (step == 3) {
                          <small class="cursor-pointer"> / Filters</small>
                        }
                        @if (step > 3) {
                          <small class="cursor-pointer"> / <span (click)="step3()"><u>Filters</u></span></small>
                        }
                        @if (step > 3) {
                          <small class="cursor-pointer"> / Export</small>
                        }
                      </div>
                    </div>
                    <div class="col text-right">
                      <button type="button" class="btn btn-sm btn-outline-secondary mx-1" (click)="back()">Back</button>
                      @if (step < 4) {
                        <button type="button" class="btn btn-sm btn-secondary nav-btn-accent text-white mx-1" (click)="next()">
                          @if (step < 3) {
                            Next
                          }
                          @if (step == 3) {
                            Run
                          }
                        </button>
                      }
                      @if (step == 4) {
                        <button type="button" class="btn btn-sm btn-secondary nav-btn-accent text-white mx-1" (click)="saveReport()"> {{ myReport ? 'Save Report' : 'Save As New Report' }} </button>
                      }
                      @if (step == 4) {
                        <button type="button" class="btn btn-sm btn-success text-white mx-1" (click)="exportReport()">Export Report</button>
                      }
                    </div>
                  </div>
                }

              </div>
              <div class="card-body">
                <!-- First Page (Step 1) -->
                @if (step <= 1) {
                  <div class="row">
                    <div class="col">
                      <div class="alert alert-info w-100 px-3">
                        <h5 class="font-weight-normal or-primary mb-1">Start your report setup here!</h5>
                        <small class="m-0">Select a new report type below to setup or pick from a saved reports to load:</small>
                      </div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center mt-2">
                    <div class="col-9">
                      @for (type of reportTypes; track type) {
                        <div class="justify-content-center w-100 mb-2">
                          <or-action-button [label]="type.displayName" size="large" [description]="type.info" [icon]="type.icon" (selected)="createNewReport(type.name)"></or-action-button>
                        </div>
                      }
                    </div>
                  </div>
                }

                <!-- Creating the Report -->
                @if (step > 1) {
                  <div class="row">
                    <div class="col-5 d-flex align-items-center">
                      <label for="firstName" class="h5 or-primary mb-0">Report Name</label>
                      <input type="text" id="reportName" class="form-control form-control-sm w-50 ml-4" [(ngModel)]="report.reportName" />
                    </div>
                  </div>
                  @if (step == 4 && !myReport) {
                    <div class="row mt-2" >
                      <span class="badge warn font-weight-normal grey4 p-2"><i class="far fa-square-info mr-2 fa-lg" aria-hidden="true"></i>This is not your report so if you save any changes it will create a new report </span>
                    </div>
                  }
                  <div class="row">
                    <div class="col">
                      <!-- Step 2 (Fields) -->
                      @if (step == 2) {
                        <div>
                          <p class="h6 required or-primary mt-4"><i class="fas fa-table fa-sm"></i> Fields</p>
                          <or-listbox
                            [showCount]="true"
                            [items]="moduleData"
                            [headers]="headers"
                            [loadSelected]="report.columns"
                            (selected)="handleSelectedFields($event)">
                          </or-listbox>
                        </div>
                      }
                      <!-- Step 3 (Filters) -->
                      @if (step == 3) {
                        <div class="mt-4">
                          <ng-container *ngTemplateOutlet="filters"></ng-container>
                          @if (loadingData) {
                            <div class="loading"></div>
                            <div>Please wait while the preview is loading</div>
                          }
                          @if (!loadingData) {
                            <or-list [items]="tableData | filter : filterText : reportTableFilterColumns" [layout]="reportTableColumns"></or-list>
                          }
                        </div>
                      }
                      <!-- Step 4 (View Report) -->
                      @if (step == 4) {
                        <div class="mt-4">
                          @if (loadingData) {
                            <div class="loading"></div>
                            <div>Please wait while the report is loading</div>
                          }
                          @if (!loadingData) {
                            @if (!loading) {
                              <div class="table-responsive">
                                <or-list [items]="tableData | filter : filterText : reportTableFilterColumns" [enableExport]=true [layout]="reportTableColumns"></or-list>
                              </div>
                            }
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          @if (step == 1) {
            <div class="col-sm-12 col-lg-8">
              <div class="card step-{{step}}">
                <div class="card-header bg-white border-bottom-0">
                  <span class="h5 or-primary mb-0">Saved Reports</span>
                  @if (loading) {
                    <span class="loading float-right"></span>
                  }
                </div>
                @if (!loading) {
                  <div class="p-4">
                    <or-list
                      [items]="savedReports | filter : filterText : filterColumns"
                      [layout]="layout"
                      rowid="id"
                      [actions]="actions"
                      (action)="handleAction($event)"
                      clickroute="/reports/builder/">
                    </or-list>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #filters>

  @if (filtersList.length > 0) {
    <p class="h6 required or-primary mt-4"><i class="fas fa-filter fa-xs"></i> Filters</p>
  }

  @for (filter of filtersList; track filter) {
    <div class="filter-grid ml-3">
      <div class="d-flex align-items-center">
        <small>{{filter.label}}</small>
      </div>
      <div>
        <div class="grid-{{filter.type}}">
          @if (filter.type === 'text') {
            <div>
              <select [(ngModel)]="filter?.options.operator" class="form-control form-control-xs">
                <option [ngValue]="'contains'">Contains</option>
                <option [ngValue]="'doesntContain'">Doesn't Contain</option>
                <option [ngValue]="'equals'">Equals</option>
              </select>
            </div>
            <div>
              <input type="text" class="form-control form-control-xs" [(ngModel)]="filter?.options.searchText" />
            </div>
          }
          @if (filter.type === 'number') {
            <div>
              <select [(ngModel)]="filter?.options.operator" class="form-control form-control-xs">
                <option [ngValue]="'greaterThan'">Greater Than</option>
                <option [ngValue]="'lessThan'">Less Than</option>
                <option [ngValue]="'equals'">Equals</option>
              </select>
            </div>
            <div>
              <input type="number" class="form-control form-control-xs" [(ngModel)]="filter?.options.value" />
            </div>
          }
          @if (filter.type === 'date') {
            <div>
              <select [(ngModel)]="filter?.options.operator" class="form-control form-control-xs">
                <option [ngValue]="''">None</option>
                <option [ngValue]="'between'">Between</option>
                <option [ngValue]="'before'">Before</option>
                <option [ngValue]="'after'">After</option>
                <option [ngValue]="'week'">This week</option>
                <option [ngValue]="'month'">This month</option>
                <option [ngValue]="'quarter'">This quarter</option>
              </select>
            </div>
            <div>
              @if (filter?.options.operator === 'between') {
                <div>
                  <input
                    type="text" autocomplete="off"
                    class="form-control form-control-xs"
                    placeholder="Enter a date range"
                    [(ngModel)]="filter.options.dateRange"
                    [value]="dateRange"
                    #drp="bsDaterangepicker"
                    bsDaterangepicker
                [bsConfig]="{
                  showPreviousMonth: false,
                  dateInputFormat: 'MMM Do, YYYY',
                  displayOneMonthRange: true,
                  isAnimated: false,
                  isOpen: true,
                  containerClass: 'theme-blue',
                  showWeekNumbers:false
                }">
                  </div>
                }
                @if (filter?.options.operator === 'before' || filter?.options.operator === 'after') {
                  <div>
                    <input
                      type="text" autocomplete="off"
                      class="form-control form-control-xs"
                      placeholder="Enter a date"
                      [(ngModel)]="filter.options.date"
                      [value]="date"
                      #drp="bsDatepicker"
                      bsDatepicker
                [bsConfig]="{
                  showPreviousMonth: false,
                  dateInputFormat: 'MMM Do, YYYY',
                  displayOneMonthRange: true,
                  isAnimated: false,
                  isOpen: true,
                  containerClass: 'theme-blue',
                  showWeekNumbers:false,
                  customTodayClass: 'todays-date'
                }">
                    </div>
                  }
                </div>
              }
              @if (filter.type === 'status') {
                <div>
                  <select [(ngModel)]="filter?.options.searchText" class="form-control form-control-xs">
                    <option [ngValue]="'Closed'">Closed</option>
                    <option [ngValue]="'Full Launch'">Full Launch</option>
                    <option [ngValue]="'Invoiced'">Invoiced</option>
                    <option [ngValue]="'Paused'">Paused</option>
                    <option [ngValue]="'Soft Launch'">Soft Launch</option>
                    <option [ngValue]="'Test'">Test</option>
                  </select>
                </div>
                <div>
                </div>
              }
              @if (report.reportType != 'summary') {
                <div>
                  <button class="btn btn-sm px-0" (click)="removeFilter(filter.id)">
                    <i class="fa fa-minus-circle text-danger"></i>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      }

      @if (report.reportType != 'summary') {
        <div class="btn-group mt-3" role="group">
          <button type="button" class="btn btn-sm btn-secondary">
            <or-dropdown [values]="filterableData" text="Add Filter" (selected)="changeFilter($event)"></or-dropdown>
          </button>
        </div>
      }

      <hr class="or-dottedline">

    </ng-template>
