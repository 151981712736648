
<div dropdown #filterUsersDropdown="bs-dropdown" class="position-relative dropdown" [insideClick]="true" (isOpenChange)="onOpenChange($event)">
  <div dropdownToggle class="nav-font14 dropdown-button d-flex align-items-center px-2 cursor-default" [ngClass]="totalSelected == 0 ? 'grey3' : 'grey5'" [class.isOpen]="filterUsersDropdown.isOpen">
    {{totalSelected == 0 ? 'Select user(s)' : totalSelected + ' Users selected'}}
    <span class="far ml-auto" [ngClass]="filterUsersDropdown.isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></span>
  </div>
  <div *dropdownMenu class="dropdown-menu position-absolute">
    <div class="row mx-0">
      <div class="col px-0">
        <div class="px-2 select-all d-flex align-items-center">
          <div class="or-checkbox d-inline">
            <input type="checkbox" name="open-end-select-all" id="open-end-select-all" (change)="selectAll($event)">
            <label for="open-end-select-all"></label>
          </div>
          <div class="grey5 font-weight-bold nav-font14 text d-inline">Select all ({{filteredUsers.length}})</div>
        </div>
        <div class="user-list">
          @for (user of filteredUsers; track user; let idx = $index) {
            <div class="px-2 user-item d-flex align-items-center">
              <div class="or-checkbox d-inline">
                <input type="checkbox" [(ngModel)]="user.selected" [name]="idx+'-open-end-selected'" [id]="idx+'-open-end-selected'" (change)="select(user, $event)" >
                <label [for]="idx+'-open-end-selected'"></label>
              </div>
              <div class="grey5 nav-font14 text d-inline text-nowrap text-truncate">{{user?.name}}</div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
