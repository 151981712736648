
  <ng-template #modal>
    <div class="header w-100">
      <span class="far fa-times grey4 float-right m-2 p-1" aria-label="Close" (click)="closeReport()"></span>
    </div>
    <div id="reportContainer" class="reportContainer" [innerHtml]="safeReportSvg"></div>
    <div class="footer w-100 pb-4 pt-3">
      <div class="btn-container float-right">
        <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14 px-2" (click)="closeReport()">Close</button>
        <button class="btn nav-btn-accent shadow-none font-weight-bold nav-font14 px-2" (click)="downloadReportAsPng()">
          <i class="fak fa-download mr-2"></i>
          Download
        </button>
      </div>
    </div>
  </ng-template>
