import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component';
import { ComponentsModule, HelpersModule } from 'core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    HelpersModule,
    RouterModule,
  ],
  declarations: [AlertsComponent]
})
export class AlertsModule { }
