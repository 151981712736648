<div class="container-fluid idSuite px-3" [class.m-3]="fullscreen" [class.mb-3]="!fullscreen">
    <!-- Error Handling screen -->
     
        <!-- QC Flow before qcFlow and after processing -->
        <ng-container *ngIf="qcFlowStage === 'summary'">
            <div class="row pr-3">
            </div>
            <div class="row">
                <div class="m-auto" [ngClass]="processing || pending || !qcFlowDetails ? 'col-10' : 'col-12'">
                    <div class="respondentOverview card mx-2">
                        <div class="card-header mt-2">
                            <div class="row d-flex align-items-center">
    
                                <div class="col">
                                    <div class="d-inline nav-secondary nav-font18 font-weight-bold">Data Cleaning <i class="far fa-info-circle nav-font14 grey4 ml-2 cursor-default"></i></div>
                                    <div class=" d-flex align-items-center float-right">
                                        <span class="nav-font14 grey5 mr-2 mt-1" *ngIf="qcFlowDetails?.status.lastReconciled">OR1s last reconciled on {{qcFlowDetails.status.lastReconciled | date: 'M/dd/YYYY'}}</span>
                                           <button class="btn nav-btn-primary shadow-none nav-font14 font-weight-bold mx-2 px-2 pb-1"
                                            (click)="openMyData()"
                                            [class.disabledBtnPrimary]="!qcFlowDetails || processing || pending || (qcFlowDetails && !qcFlowRecords)">
                                            <i class="fak fa-file-view font-weight-normal nav-font16 mr-2"> </i>Open my data
                                        </button>
                                        <button [class.disabledBtnPrimary]="processing || pending"
                                        class="btn nav-btn-accent shadow-none nav-font14 font-weight-bold mx-2 px-2 pb-1"
                                            (click)="openFileSettings(false)">
                                            <i class="far fa-plus font-weight-normal mr-2 padding-right2"> </i>Import data
                                        </button>
                                        <div class="d-inline ml-1 cursor-pointer"
                                            placement="bottom" [tooltip]="(qcFlowDetails && !processing && !pending) ? 'Download analysis' : ''"
                                            [class.disabledBtn]="!qcFlowDetails || pending || processing"
                                            (click)="downloadAnalysis()">
                                            <i class="fak fa-download grey4"> </i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="dataLoading && !processing && !pending">
                            <div class="card-body mx-3 px-0 pt-2">
                                <div class="my-5">
                                    <div class="text-center my-2">
                                        <span class="loading-qc"></span>
                                    </div>
                                    <div class="nav-font18 grey4 text-center">
                                         {{ this.openEnds?.length == 0 ? 'Your data is currently loading': 'Data Complete, waiting for your browser to catch up' }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="card-body grey5 pt-1" *ngIf="!dataLoading">
                            <ng-container *ngIf="!qcFlowDetails && !processing && !pending">
                                <span class="grey5 nav-font16">No data available. Click the 'Import data' button to begin data cleaning.</span>
                            </ng-container>
                            <ng-container *ngIf="qcFlowDetails || qcFlowRecords">
                                <ng-container *ngIf="!processing && !pending">
                                    <div class="row nav-font16 firstRow mt-3" >
                                        <div class="col-5 border-right px-4">
                                            <div class="font-weight-bold mb-3">QC Scorecard</div>
                                            <div class="row nav-font14">
                                                <div class="col-6 responseIdChart px-0">
                                                    <highcharts-chart
                                                        id="respondentOverviewChart"
                                                        [Highcharts]="highcharts"
                                                        [options]="highchartsOptionsRespondentOverview"
                                                        style="width: 100%; height: 100%; display: block;">
                                                    </highcharts-chart>
                                                </div>
    
                                                <div class="col grey5 nav-font14 mt-2 px-0">
                                                    <div *ngIf="qcFlowDetails?.status.acceptedCount > 0" class="">
                                                        <i class="fas fa-square nav-success nav-font16 mr-2"></i>
                                                        <span>Accepted Respondents ({{qcFlowDetails?.status.acceptedCount}})</span>
                                                    </div>
                                                    <div *ngIf="(qcFlowDetails?.status.respondentProcessedCount - (qcFlowDetails?.status.acceptedCount + qcFlowDetails?.status.rejectedCount)) > 0" class="">
                                                        <i class="fas fa-square grey2 nav-font16 mr-2"></i>
                                                        <span>Pending Respondents ({{qcFlowDetails?.status.respondentProcessedCount - (qcFlowDetails?.status.acceptedCount + qcFlowDetails?.status.rejectedCount)}})
                                                        </span>
                                                    </div>
                                                    <div *ngIf="qcFlowDetails?.status.rejectedCount > 0" class="">
                                                        <i class="fas fa-square nav-error nav-font16 mr-2"></i>
                                                        <span>Rejected Respondents ({{qcFlowDetails?.status.rejectedCount}})</span>
                                                    </div>
                                                    <div class="font-weight-bold my-3">{{(qcFlowDetails?.status.acceptedCount/(qcFlowDetails?.status.acceptedCount + qcFlowDetails?.status.rejectedCount) || 0) | percent}} Acceptance Rate </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-7 px-4">
                                            <div class="uploadHistory">
                                                <div class="font-weight-bold mb-3">Import History</div>
                                                <div class="card">
                                                    <div class="card-body grey5 px-0 py-0">
                                                        <div class="row nav-font14" >
                                                            <div class="col">
                                                                <table class="w-100" [class.border-bottom]="qcFlowDetails?.status.fileHistory.length < 4">
                                                                    <thead class="thead">
                                                                      <tr class="">
                                                                          <th class="top-left-border font-weight-bold">
                                                                              {{usedDecipher ? 'Imports' : 'File name'}} <span
                                                                                  class="grey4 font-weight-normal">({{qcFlowDetails?.status.fileHistory.length}})</span>
                                                                              
                                                                              @if (!usedDecipher) {
                                                                                <i (click)="sortFileHistory('name')"
                                                                                    class="far fa-solid fa-sort grey4 text-center ml-2 cursor-pointer"></i>
                                                                              }
                                                                          </th>
                                                                          <th class="text-center font-weight-bold pl-2 pr-0"
                                                                              width="141">Date added <i
                                                                                  (click)="sortFileHistory('date')"
                                                                                  class="far fa-solid fa-sort grey4 text-center ml-2 cursor-pointer"></i>
                                                                          </th>
                                                                          <th class="text-center font-weight-bold px-2"
                                                                              width="135">Count <i
                                                                                  (click)="sortFileHistory('size')"
                                                                                  class="far fa-solid fa-sort grey4 text-center ml-2 cursor-pointer"></i>
                                                                          </th>
                                                                          @if (!usedDecipher) {
                                                                            <th class="text-center font-weight-bold top-right-border"
                                                                                width="90"> Delete
                                                                            </th>
                                                                          }
                                                                          <!-- <th class="text-center top-right-border">Download</th> -->
                                                                      </tr>
                                                                    </thead>
                                                                    <tbody class="border-top">
                                                                        <ng-container *ngFor="let file of qcFlowDetails?.status.fileHistory; let i = index">
                                                                            <tr [class.border-top]="i > 0">
                                                                                <td class="text-truncate">{{file.fileName === 'decipherAPI' ? 'Decipher' : file.fileName}}</td>
                                                                                <td class="text-left"  width="125">{{file.submittedOn | date: 'MM/dd/YYYY'}}</td>
                                                                                <td class="text-center px-2" width="135">{{file.rowCount}}</td>
                                                                                @if (!usedDecipher) {
                                                                                    <td class="text-center" width="90">
                                                                                        <i class="fak fa-trash nav-font16 cursor-pointer nav-error" (click)="openConfirmDeleteDialog(file.id)" ></i>
                                                                                    </td>
                                                                                }
                                                                                <!-- <td class="text-center"><i class="fak fa-download fa-lg cursor-pointer grey4" (click)="downloadUploadedFile(file)"></i></td> -->
                                                                            </tr>
                                                                        </ng-container>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row d-flex align-items-center">
                                        <div class="col-5 d-flex align-items-center">
                                            <div class="grey5 nav-font14 mr-1 mt-1 ml-3">
                                                Reviewers <span class="grey3"> ({{reviewers.length}})</span>
                                            </div>
                                            <div class="d-inline ml-4">
                                                <or-profile-image [showRole]="false" [names]="reviewers" showInitials="true" size="medium"></or-profile-image>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="float-right nav-font14 nav-error mx-4 mt-3 mb-2 cursor-pointer" (click)="openClearSessionDialog()">
                                                <span class=" font-weight-bold"><i class=" font-weight-normal fak fa-trash nav-font16 mx-2"></i> Delete all data</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="processing || pending">
                                    <div class="card-body mx-3 px-0 pt-2">
                                        <div class="row nav-font16 mb-3 grey5 font-weight-normal">
                                            Your data is currently loading.
                                        </div>
                                        <div class="my-5">
                                            <div class="text-center my-2">
                                                <span class="loading-qc"></span>
                                            </div>
                                            <div class="nav-font18 grey4 text-center">
                                                Loading data {{ percentComplete | percent}} complete
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    
        <!-- in QC Flow - upload file ('settings')-->
        <ng-container *ngIf="qcFlowStage === 'settings'">
            <div class="row">
                <div class="m-auto col-10">
                    <div class="card m-2 ">
                        <div class="card-header pb-2">
                            <div class="row mx-0 px-1 py-1 align-items-center">
                                <div class="col d-flex align-items-center px-0 mt-2">
                                    <span class="fas fa-angle-left fa-lg nav-font18 grey4" (click)="cancelQCFlow()"></span>
                                    <h3 class="nav-secondary nav-font18 font-weight-bold mb-0 ml-3">
                                        Data Cleaning: Import Data
                                    </h3>
                                </div>
                                <div class="row ml-auto nav-font14 grey4 align-items-center">
                                    <span class="cursor-pointer font-weight-bold mr-3" (click)="cancelQCFlow()">Cancel</span>
                                </div>
                            </div>
    
                        </div>
                        <div class="card-body p-0 mb-4 mx-4 pb-2 pt-1" [class.mt-4]="qcFlowStage === 'settings'">
                            <!-- QC Flow Settings and data upload -->
    
                                <div class="nav-font16">
                                    <div class="row mb-4 ">
                                        <div class="col-12 grey6">
                                            <ng-container *ngIf="!settingsExist">
                                                @if(canImport){
                                                    <span class="font-weight-bold"> Import Data: </span><label class="required ml-1">Upload the CSV file or Import from Decipher, then select your data to begin</label>
                                                }
                                                @else {
                                                    <span class="font-weight-bold"> Import File: </span><label class="required ml-1">Upload the CSV file, then select your data to begin</label>
                                                }
                                               
                                            </ng-container>
                                            <ng-container *ngIf="settingsExist">
                                                @if (usedDecipher) {
                                                    <span class="font-weight-bold"> Import from Decipher: </span><label class="ml-1">Retrieve the latest data directly from the survey.</label>
                                                }
                                                @else {
                                                    <span class="font-weight-bold"> Import File: </span><label class="required  ml-1">Upload the .CSV file, ensuring it maintains the identical structure as the previously imported file.</label>
                                                }
                                            </ng-container>
                                        </div>
                                    </div>
                                   
                                    <div class="row mb-5 d-flex align-items-center">
                                        @if (!usedDecipher || !settingsExist) {
                                            <div class="col-5 colForUpload pr-4">
                                                <or-upload isCsv=true mode="qc-flow" [fileCleared]=uploadedFile showFileIcon=false
                                                    mainMsg="Click to import" smallMsg="or drag and drop file here"
                                                    (uploadCompleted)="processFile($event)"
                                                    (deletedCompleted)="deleteFileCallback()" publicBucket=false
                                                    [includeProgressBar]=true acceptTypes=".csv"
                                                    [instanceId]="auth.getUser().instanceId" [S3Folder]="s3buckPath" directToS3=true
                                                    [(ngModel)]="uploadedFile"></or-upload>
                                            </div>
                                        }
                                        @if (!settingsExist && canImport) {
                                            <div class="grey6 nav-font16 font-weight-bold">Or</div>
                                            <div class="col-5 colForDecipherUpload pl-4">
                                                <button
                                                    class="btn btn-sm nav-btn-primary px-2 shadow-none font-weight-bold nav-font14"
                                                    (click)="importDecipherQuestionsAPI()">
                                                    <i class="fak fa-upload grey4 pr-1 nav-font16 font-weight-normal"></i>
                                                    Click here to import from Decipher
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    <div *ngIf="!settingsExist">
                                        <hr class="mb-4">
                                        <div [ngClass]=" !fileProcessed ? 'readOnly' : ''">
                                            <div class="row py-3">
                                                <div class="col-12 grey6">
                                                    <span class="font-weight-bold">Select the OR1 Column From CSV File: </span><label class="required  ml-1">Ensure that the OR1s match those in the NavigatOR</label>
                                                </div>
                                            </div>
                                            <div class="row mb-4">
                                                <div class="col-8 grey5 rid-col-picker">
                                                    <div class="btn-group" dropdown #dropdown="bs-dropdown">
                                                        <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container p-0" [ngClass]="dropdown.isOpen ? 'open' : 'closed'">
                                                            <div class="input-group copy-search w-75">
                                                                <!-- <div class="input-group-prepend height24">
                                                                    <div class="input-group-text border-right-0 px-2">
                                                                    <i class="fas fa-search cursor-default"></i>
                                                                    </div>
                                                                </div> -->
                                                        <input #respondentIdSearch [(ngModel)]="respondentIdFilterText"
                                                            class="form-control form-control-sm"
                                                            (keypress)="setIdIfExists($event) ? dropdown.toggle(true) : null"
                                                            (blur)="setIdIfExistsOnBlur(dropdown)"
                                                            (ngModelChange)="updateIdList(); dropdown.isOpen ? null : dropdown.toggle(true)"
                                                            placeholder="Select or type to search">
                                                    </div>
                                                    <!-- <span class="nav-font14">{{respondentIdColumnId == null || respondentIdColumnId == '' ? 'Select column' : respondentIdColumnId}}</span> -->
                                                    <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                                                        <i class="far grey4 float-right nav-font16 ml-auto mr-2"
                                                            [class.fa-angle-up]="dropdown.isOpen"
                                                            [class.fa-angle-down]="!dropdown.isOpen">
                                                        </i>
                                                    </div>
                                                </div>
                                                <div id="dropdownRIDCol" *dropdownMenu
                                                    class="rid-col-picker-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14 m-0"
                                                    role="menu" aria-labelledby="button-basic">
                                                    <div>
                                                        <ul class=" py-0 px-0 my-0 noShadow">
                                                            <ng-container *ngFor="let column of filteredIdColumns">
                                                                <li class="expandable grey4 nav-font14 d-flex align-items-center px-0"
                                                                    (mousedown)="updateRIDCol(column.id, dropdown);">
                                                                    <div
                                                                        class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                                                        <span>{{column.id}}</span>
                                                                    </div>
                                                                    <hr class="p-0 my-0 mx-1">
                                                                </li>
                                                            </ng-container>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3 mb-3">
                                        <div class="col-12 grey5">
                                            <span class="font-weight-bold grey6"> Select Open-Ends For Review:
                                            </span><label class="required ml-1">Select the open-ended questions for data
                                                cleaning, then provide the question text</label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="column-picker-container pb-2 nav-font14">
                                                <div class="d-flex justify-content-center m-0">
                                                    <div class="input-group copy-search">
                                                        <div class="input-group-prepend height24">
                                                            <div class="input-group-text border-right-0">
                                                                <i class="fas fa-search cursor-default"></i>
                                                            </div>
                                                        </div>
                                                        <input [(ngModel)]="openEndFilterText" type="search"
                                                            class="form-control form-control-sm height24"
                                                            (ngModelChange)="updateColumns()" placeholder="Search">
                                                    </div>
                                                </div>
                                                <div class="px-3">
                                                    <div class="or-checkbox d-inline">
                                                        <input type="checkbox" name="open-end-select-all"
                                                            id="open-end-select-all"
                                                            (change)="selectAllColumns($event, 'open-end')">
                                                        <label for="open-end-select-all"></label>
                                                    </div>

                                                    <div class="grey5 d-inline px-2 ml-3">Select all <span
                                                            class="grey4 mx-1">({{filteredOpenEndColumns.length}})</span>
                                                    </div>
                                                </div>
                                                <hr class="my-2">
                                                <div
                                                    class="column-list-container open-end-container overflow-y scrollbar px-3 py-1">
                                                    <ng-container *ngFor="let column of filteredOpenEndColumns">
                                                        <div class="pb-2 d-flex align-items-top">
                                                            <div class="or-checkbox d-inline">
                                                                <input type="checkbox" [(ngModel)]="column.selected"
                                                                    [name]="column.index+'-open-end-selected'"
                                                                    [id]="column.index+'-open-end-selected'"
                                                                    (change)="setColumnType(column, $event, 'open-end')">
                                                                <label
                                                                    [for]="column.index+'-open-end-selected'"></label>
                                                            </div>
                                                            <!-- <input [checked]="column.selected" type="checkbox" name="column" id="column" > -->
                                                            <div class="grey5 d-inline px-2 columnText">{{column.id}}
                                                            </div>
                                                            <div class="w-100">
                                                                <!-- appAutoFocusDirective -->
                                                                <textarea *ngIf="column.selected" class="w-100 px-2"
                                                                    #OEInputText="ngModel" (keyup)="checkCanUpload()"
                                                                    [ngClass]="{'border-danger': !column.text}"
                                                                    placeholder="Enter question text as it appears on the survey"
                                                                    [(ngModel)]="column.text"></textarea>
                                                            </div>
                                                            <!-- <input *ngIf="column.selected" type="text" class="form-control-nav col-10" placeholder="Enter question text as it appears on the survey"> -->
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class=" pb-2">
                                                <div class="m-2 mx-3">
                                                    <div class="grey5 nav-font14 selectedDataHeader">
                                                        Selected Open-Ends <span class="grey4">
                                                            ({{selectedOpenEndCols.length}})</span>
                                                    </div>
                                                </div>
                                                <div
                                                    class="selected-list-container overflow-y scrollbar nav-font14 grey5 px-3 py-1 ">
                                                    <ng-container
                                                        *ngFor="let column of selectedOpenEndCols; let i = index">
                                                        <span>{{i > 0 ? ', ' + column.id : column.id}}</span>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row my-4">
                                        <div class="col-12 grey5">
                                            <span class="font-weight-bold grey6">Select Quality Flags: </span> Select
                                            the in-survey quality flags for review (optional)
                                        </div>
                                    </div>
                                    <div class="row mb-4 pb-2">
                                        <div class="col-8">
                                            <div class="column-picker-container pb-2 nav-font14">
                                                <div class="d-flex justify-content-center m-0">
                                                    <div class="input-group copy-search ">
                                                        <div class="input-group-prepend height24">
                                                            <div class="input-group-text border-right-0">
                                                                <i class="fas fa-search cursor-default"></i>
                                                            </div>
                                                        </div>
                                                        <input [(ngModel)]="flagFilterText" type="search"
                                                            class="form-control form-control-sm height24"
                                                            (ngModelChange)="updateColumns()" placeholder="Search">
                                                    </div>
                                                </div>
                                                <div class="px-3">
                                                    <div class="or-checkbox d-inline">
                                                        <input type="checkbox" name="flag-select-all"
                                                            id="flag-select-all"
                                                            (change)="selectAllColumns($event, 'flag')">
                                                        <label for="flag-select-all"></label>
                                                    </div>
                                                    <div class="grey5 d-inline px-2 ml-3">Select all <span
                                                            class="grey4 mx-1">({{filteredFlagColumns.length}})</span>
                                                    </div>
                                                </div>
                                                <hr class="my-2">
                                                <div class="column-list-container overflow-y scrollbar px-3 py-1">
                                                    <ng-container *ngFor="let column of filteredFlagColumns">
                                                        <div class="pb-2">
                                                            <div class="or-checkbox d-inline">
                                                                <input type="checkbox" [(ngModel)]="column.selected"
                                                                    [name]="column.index+'-flag-selected'"
                                                                    [id]="column.index+'-flag-selected'"
                                                                    (change)="setColumnType(column, $event, 'flag')">
                                                                <label [for]="column.index+'-flag-selected'"></label>
                                                            </div>
                                                            <!-- <input [checked]="column.selected" type="checkbox" name="column" id="column" > -->
                                                            <div class="grey5 d-inline px-2 ml-3 columnText">
                                                                {{column.id}}</div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class=" pb-2">
                                                <div class="m-2 mx-3">
                                                    <div class="grey5 nav-font14 selectedDataHeader">
                                                        Selected Quality Flags <span
                                                            class="grey4">({{selectedFlagCols.length}})</span>
                                                    </div>
                                                </div>
                                                <div
                                                    class="selected-list-container overflow-y scrollbar nav-font14 grey5 px-3 py-1">
                                                    <ng-container
                                                        *ngFor="let column of selectedFlagCols; let i = index">
                                                        <span>{{i > 0 ? ', ' + column.id : column.id}}</span>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer pb-0">
                            <div class="row actions">
                                <div class="col">
                                    <div class="row align-items-center">
                                        <!-- <button class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="cancelQCFlow()">Cancel</button> -->
                                        <div *ngIf="fileProcessed"
                                            [style.visibility]="!settingsExist ? 'visible' : 'hidden'"
                                            [style.height]="!settingsExist ? '50px' : '32px'"
                                            class="enhanceAIHint ml-auto d-flex p-2 align-items-center mr-1">
                                            <div class="">
                                                <i class="fa-duotone fa-lightbulb mb-3"></i>
                                            </div>
                                            <div class=" nav-font14 grey5 d-inline ml-2">Reviewing your data with our AI
                                                tool can save you an average of 40 minutes!</div>
                                        </div>
                                        <div [ngClass]="fileProcessed ? '' : 'ml-auto'">
                                            <ng-container *ngIf="!settingsExist">
                                                <button class="btn btn-sm mx-2 shadow-none font-weight-bold"
                                                    [ngClass]="canUpload && fileProcessed ? 'nav-btn-primary' : 'nav-btn-disabled'"
                                                    [class.disabledBtnPrimary]="canUpload && fileProcessed ? null : true"
                                                    [class.btn-ai-enabled]="qcFlowDetails?.settings?.enhanceWithAI == true"
                                                    (click)="qcFlowDetails.settings.enhanceWithAI = !qcFlowDetails.settings.enhanceWithAI;">
                                                    {{qcFlowDetails?.settings?.enhanceWithAI == true ? 'Remove' :
                                                    'Enhance Using'}} AI
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="settingsExist">
                                                <button class="btn btn-sm mx-2 shadow-none font-weight-bold"
                                                    [ngClass]="(canUpload && fileProcessed) || usedDecipher ? 'nav-btn-primary' : 'nav-btn-disabled'"
                                                    [class.disabledBtnPrimary]="(canUpload && fileProcessed) || usedDecipher ? null : true"
                                                    (click)="cancelQCFlow()">
                                                    Cancel
                                                </button>
                                            </ng-container>
                                            @if (savingDetails && !this.openModalRef?.content?.saving) {
                                                <span class="loading"></span>
                                            }
                                            @if (!usedDecipher) {
                                                <button class="btn btn-sm shadow-none px-3 font-weight-bold"
                                                    [ngClass]="canUpload && fileProcessed ? 'nav-btn-accent' : 'nav-btn-disabled'"
                                                    [attr.disabled]="canUpload && fileProcessed ? null : true"
                                                    (click)="uploadComplete();">Upload
                                                </button>
                                            }
                                            @if (usedDecipher) {
                                                <button class="btn btn-sm shadow-none px-3 font-weight-bold nav-btn-accent"
                                                    [ngClass]="canUpload ? 'nav-btn-accent' : 'nav-btn-disabled'" 
                                                    [attr.disabled]="canUpload ? null : true"                                                                                            
                                                    (click)="Execute();">Import
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </ng-container>

    <!-- data cleaning ('data-cleaning') -->
            <ng-container *ngIf="qcFlowDetails">
            <div class="row pr-3" [style.visibility]="qcFlowStage === 'data-cleaning' ? 'visible' : 'hidden'" >
                <div class=" m-auto" class="col-12 p-0 pr-3">
                    <div class="card" [style.box-shadow]="fullscreen ? 'none' : ''">
                        <div class="card-header pb-2 pl-2" *ngIf="qcFlowStage === 'data-cleaning'">
                            <div class="row mx-0 px-1 py-1 align-items-center">
                                <div class="col d-flex align-items-center px-0">
                                    <span class="fas fa-angle-left fa-lg nav-font18" [ngClass]="fullscreen ? 'grey2 cursor-default' : 'grey4'" (click)="!fullscreen ? cancelQCFlow() : null"></span>
                                    <h3 class="nav-secondary nav-font18 font-weight-bold mb-0 ml-3">
                                        Data Cleaning <i class="far fa-info-circle nav-font14 grey4 ml-2 cursor-default"></i>
                                    </h3>
                                </div>
                                <div class="row ml-auto nav-font14 grey4 align-items-center">
                                        <div class="copy-search-container d-flex align-items-center px-0" [class.no-pointer-events]="pending || processing">
                                            <div class="input-group copy-search">
                                                <div class="input-group-prepend height24">
                                                    <div class="input-group-text border-right-0">
                                                    <i class="fas fa-search cursor-default nav-font14"></i>
                                                    </div>
                                                </div>
                                                <input [(ngModel)]="dataCleaningFilterText" type="search" class="form-control form-control-sm height24" (ngModelChange)="updateTextFilter()" placeholder="Search">
                                            </div>
                                        </div>
                                        <div class="">
                                            <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default mx-2 pt-1"></span>
                                        </div>
                                        <div class="">
                                            <div class=" d-flex align-items-center mx-0 my-assessment-toggle" [class.no-pointer-events]="pending || processing">
                                                <span class="mx-2 d-inline">My Assessment</span>
                                                <div class="d-inline">
                                                    <or-toggle-button class="light" size="small" [(ngModel)]="qcFlowDetails.settings.showMyAssessment" (change)="toggleShowMyAssessment()"></or-toggle-button>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="">
                                            <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default mx-2 pt-1"></span>
                                        </div>
    
                                        <div class="">
                                            <div class="d-inline cursor-pointer" (click)="undoAction()" [class.disabledUndoRedoBtn]="changeIdx == -1">
                                                <i class="font-weight-normal nav-font16 fa-solid fa-arrow-rotate-left mr-2 pr-1"></i>
                                            </div>
                                            <div class="d-inline cursor-pointer" (click)="redoAction()" [class.disabledUndoRedoBtn]="changeLog.length == 0 || changeIdx == (changeLog.length - 1)">
                                                <i  class="font-weight-normal nav-font16 fa-solid fa-arrow-rotate-right"></i>
                                            </div>
                                        </div>
                                        <div class="">
                                            <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default ml-2 mr-3 pt-1"></span>
                                        </div>

                                        <div class="d-inline cursor-pointer" (click)="fullscreen = !fullscreen; checkOverflow();">
                                            <i class="fas fa-md grey4 font-weight-normal nav-font16 mr-2" [ngClass]="fullscreen ? 'fa-arrows-minimize' : 'fa-arrows-maximize'"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="dataCleaningHeaderButtons row grey4 nav-font14 font-weight-bold px-1 pb-1 pt-2 mx-0 mt-2 align-items-center">
                                    <div class="ml-auto d-inline nav-font14 grey4" [class.no-pointer-events]="processing || pending">
                                        @if (!usedDecipher) {
                                            <div class="d-inline cursor-pointer">
                                                <app-qc-flow-column-filter [options]="filterByFileOptions" [filters]="filesFiltered" type="file" [fullscreen]="fullscreen" (filterChange)="applyFilters('file', $event, null)"></app-qc-flow-column-filter>
                                            </div>
                                            <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default mx-3"></span>
                                        }
                                        <div class="d-inline cursor-pointer" (click)="wrapText = !wrapText">
                                            <i class="fak fa-text-wrap mr-1 nav-font16 font-weight-normal"></i>
                                           {{wrapText ? 'Unwrap' : 'Wrap'}} Text
                                        </div>
                                        <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default mx-3"></span>
                                        <div class="d-inline">
                                            <div class="btn-group dropdown my-1" dropdown  #bulkActionDropdown="bs-dropdown">
                                                <div class=" cursor-pointer" [class.open]="bulkActionDropdown.isOpen" id="button-basic" dropdownToggle controls="dropdown-basic">
                                                    <i class="font-weight-normal nav-font16 padding-right2 fak fa-vector-bolt mr-1 "></i>
                                                    Bulk Action
                                                    <i class="ml-2 nav-font16 grey4 fas" [class.fa-chevron-down]="!bulkActionDropdown.isOpen" [class.fa-chevron-up]="bulkActionDropdown.isOpen"></i>
                                                </div>
                                                <div id="dropdown-bulk-action" *dropdownMenu class="bulkActionDropdown dropdown-menu dropdown-menu-right font-weight-normal nav-font14 grey5" (click)="$event.stopPropagation()" container="body" role="menu" aria-labelledby="button-basic">
                                                    <span>Select rows to modify,<br>then click on an action.</span>
                                                    <div class="py-2">
                                                        <div class="font-weight-bold py-1 mb-1">QC Status Actions</div>
                                                        <div [class.disabled]="!oneRecordSelected">
                                                            <div class=" font-weight-normal w-100 py-1" [class.cursor-pointer]="oneRecordSelected" (click)="applyBulkAction('status', 'Accept');">
                                                                <i class="fak fa-message-success nav-success nav-font16 font-weight-normal pr-2"></i>
                                                                <span>Accept</span>
                                                            </div>
                                                            <hr class="p-0 my-0 mx-1">
                                                            <div class="font-weight-normal w-100 py-1" [class.cursor-pointer]="oneRecordSelected" (click)="applyBulkAction('status', 'Pending');">
                                                                <i class="fak fa-clock grey4 pr-2"></i>
                                                                <span>Pending</span>
                                                            </div>
                                                            <hr class="p-0 my-0 mx-1">
                                                            <div class="font-weight-normal w-100 py-1" [class.cursor-pointer]="oneRecordSelected" (click)="applyBulkAction('status', 'Reject');">
                                                                <i class="fak fa-message-error nav-error pr-2"></i>
                                                                <span>Reject</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="qcFlowDetails.settings.showMyAssessment" class="py-2">
                                                        <div class="font-weight-bold py-1 mb-1">My Assessment Actions</div>
                                                        <div [class.disabled]="!oneRecordSelected">
                                                            <div>
                                                                <div class="btn-group dropdown my-1" dropdown #bulkActionsAssessmentDropdown="bs-dropdown">
                                                                    <div class="select-container" [class.cursor-pointer]="oneRecordSelected" [class.open]="bulkActionsAssessmentDropdown.isOpen" id="button-basic" dropdownToggle controls="dropdown-basic">
                                                                        <span class="row mx-0 nav-font14" [ngClass]="!bulkActionAssessmentQuestion ? 'grey3' : 'grey4'" >
                                                                            <div class="text-truncate selected-bulk-assessment-q">{{bulkActionAssessmentQuestion ?? 'Select question'}}</div>
                                                                            <div class="d-inline ml-auto">
                                                                                <i class="fas grey4 nav-font16 ml-1 mt-1" [class.fa-chevron-down]="!bulkActionsAssessmentDropdown.isOpen" [class.fa-chevron-up]="bulkActionsAssessmentDropdown.isOpen"></i>
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                    <ul id="dropdown-assessment" *dropdownMenu class="questionPickerDropdown dropdown-menu dropdown-menu-right py-0 nav-font14" container="body" role="menu" aria-labelledby="button-basic">
                                                                        <div class="d-block text-right px-2"></div>
                                                                        <ng-container *ngFor="let openEnd of openEnds | keyvalue">
                                                                            <li role="menuitem"  class="expandablecursor-pointer" [class.cursor-pointer]="oneRecordSelected" (click)="bulkActionAssessmentQuestion = openEnd.value.id; bulkActionsAssessmentDropdown.toggle(true)">
                                                                                <a class="dropdown-item d-flex align-items-center text-truncate px-2 ">
                                                                                    {{ openEnd.value.id }}
                                                                                </a>
                                                                            </li>
                                                                        </ng-container>
                                                                        <li role="menuitem" class="expandable cursor-pointer" [class.cursor-pointer]="oneRecordSelected" (click)="bulkActionAssessmentQuestion = 'All questions'; bulkActionsAssessmentDropdown.toggle(true)">
                                                                            <a class="dropdown-item d-flex align-items-center text-truncate px-2" >
                                                                                All questions
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div [class.disabled]="!bulkActionAssessmentQuestion">
                                                                <div class="font-weight-normal w-100 py-1" [class.cursor-pointer]="oneRecordSelected && bulkActionAssessmentQuestion" (click)="applyBulkAction('assessment', 'low');">
                                                                    <i class="fal fa-square-check nav-success pr-2"></i>
                                                                    <span>Low risk</span>
                                                                </div>
                                                                <hr class="p-0 my-0 mx-1">
                                                                <div class="font-weight-normal w-100 py-1" [class.cursor-pointer]="oneRecordSelected && bulkActionAssessmentQuestion" (click)="applyBulkAction('assessment', 'medium');">
                                                                    <i class="fal fa-triangle-exclamation mediumRiskColour pr-2"></i>
                                                                    <span>Medium risk</span>
                                                                </div>
                                                                <hr class="p-0 my-0 mx-1">
                                                                <div class="font-weight-normal w-100 py-1" [class.cursor-pointer]="oneRecordSelected && bulkActionAssessmentQuestion" (click)="applyBulkAction('assessment', 'high');">
                                                                    <i class="fal fa-octagon-xmark nav-error pr-2"></i>
                                                                    <span>High risk</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
    
                                            </div>
                                        </div>
    
                                        <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default mx-3"></span>
                                        <div class="d-inline cursor-pointer" 
                                            [class.disabledBtn]="qcFlowDetails.settings.enhanceWithAI && aiConditionExists"
                                            [tooltip]="qcFlowDetails.settings.enhanceWithAI && aiConditionExists ? 'You cannot remove AI because a QC Rule is using it' : null"
                                            placement="bottom"
                                            (click)="enhanceWithAI(!qcFlowDetails.settings.enhanceWithAI)" >
                                            <i class="nav-font16 padding2 fal fa-lightbulb-on mr-1"></i>
                                            {{ qcFlowDetails?.settings.enhanceWithAI ? 'Remove AI' : 'Enhance with AI'}}
                                        </div>
                                        <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default mx-3"></span>
                                        <div class="d-inline cursor-pointer qc-rules-button" (click)="openQCRules()">
                                            <i class="font-weight-normal nav-font16 padding-right2 fak fa-rule mr-1 "></i>
                                            <div *ngIf="qcFlowDetails.settings.rules.length > 0" class="filterNumber cursor-pointer">
                                                <span class="">
                                                  {{qcFlowDetails.settings.rules.length}}
                                                </span>
                                            </div>
                                            QC Rules
                                        </div>
                                        <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default mx-3"></span>
                                        <div class="d-inline cursor-pointer" 
                                            [class.disabledBtn]="changeIdx > -1"
                                            [tooltip]="changeIdx > -1 ? 'Please save changes before you upload a new file' : null"
                                            placement="bottom"
                                            (click)="openFileSettings(true)">
                                            <i class="font-weight-normal nav-font14 padding-right2 fas fa-plus mr-1"></i>
                                            Add Data
                                        </div>
                                        <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default mx-3"></span>
                                        <div class="d-inline cursor-pointer" (click)="downloadAnalysis()">
                                            <i class="font-weight-normal nav-font14 padding-right2 fak fa-download mr-1"></i>
                                            Download
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div class="card-body p-0 pb-2 pt-1" [class.mb-4]="dataLoading">
                            @if (dataLoading) {
                                <div class="my-5 loadingInFlow">
                                    <div class="text-center my-2">
                                        <span class="loading-qc"></span>
                                    </div>
                                    <div class="nav-font18 grey4 text-center">
                                        Processing, please wait..
                                    </div>
                                </div>
                            }
    
                                <div class="card-body nav-font14 dataCleaning py-0 pb-1" [style.visibility]="!dataLoading && qcFlowStage === 'data-cleaning' ? 'visible' : 'hidden'">
                                    <div class="row d-flex justify-content-center">
                                        <div>
                                            
                                        </div>
                                        <div class="col-12 grey6 px-0 dataCleaningTableContainer" [ngClass]="fullscreen ? 'fullscreenHeight' : ''" [class.border-bottom]="!overflowingX" [class.overflowingY-scroll]="overflowingY" [class.overflowingX-scroll]="overflowingX" [style.--scrollbar-margin-left]="getScrollbarMarginLeft()" [class.loading-bg]="pending || processing" (scroll)="syncScroll($event);" [ngStyle]="{'overflow': (processing || pending) ? 'hidden' : 'auto'}">
                                            <table class="dataCleaningTable w-100" >
                                                <thead class="" [class.no-pointer-events]="processing || pending">
                                                    <tr class="">
                                                        <th [attr.colspan]="flags.length ==0 ? 2 : expandFlags ? (3+flags.length) : 3"
                                                            class="stickyHeader nav-font14 font-weight-bold grey6 border-bottom flags-seperator-right"
                                                            [class.border-left]="overflowingX"
                                                            [style.left]="0"
                                                            >
                                                            Respondent Level
                                                        </th>
                                                        <th class="stickyHeader nav-font14 font-weight-bold"
                                                            [style.left]="(questionLevelLeft+(expandFlags ? flags.length * 90 : 0)) +'px'">
                                                            Question Level
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <!-- main respondent column headers-->
                                                        <ng-container *ngFor="let col of respondentLevelColumns; let i = index">
                                                            <th width="{{col.width}}"
                                                                [style]="'min-width:' +col.width+'px; max-width:'+col.width +'px'"
                                                                class="{{col.class}} font-weight-bold "
                                                                [style.left]="col.left + 'px'"
                                                                [class.flags-seperator-right]="(col.value === 'total-flags' && !expandFlags) || (col.value == 'qc-status' && flags.length == 0)"
                                                                [class.border-left]="i == 0 && overflowingX">
                                                                <ng-container *ngIf="i == 0">
                                                                    <div class="d-inline">
                                                                        <span class="nav-font10 grey5 font-weight-normal">All</span>
                                                                        <div class="or-checkbox d-inline px-2 pr-3 mr-1">
                                                                            <input type="checkbox" [name]="'allOR1s'" [id]="'allOR1s'" [checked]="selectAllRespondents" (change)="selectAllRecords($event.target.checked)">
                                                                            <label [for]="'allOR1s'"></label>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container *ngIf="col.value === 'total-flags'">
                                                                    <i class="fal cursor-pointer nav-font16 mr-2 grey4"
                                                                    [class.fa-circle-plus]="!expandFlags"
                                                                    [class.fa-circle-minus]="expandFlags"
                                                                    (click)="expandFlags = !expandFlags; checkOverflow()"></i>
                                                                </ng-container>
                                                                {{col.label}}
                                                                <ng-container *ngIf="i == 0">
                                                                    <span class="" [ngClass]="filteredRecords.length == 0 ? 'grey1' : 'grey4'">({{filteredRowCount}})</span>
                                                                </ng-container>
                                                                <div class=" nav-font14 d-inline ml-1">
                                                                    <ng-container *ngIf="col.hasFilter">
                                                                        <app-qc-flow-column-filter [filters]="[]" type="{{col.value}}" [fullscreen]="fullscreen" (filterChange)="applyFilters(col.value, $event, null)"></app-qc-flow-column-filter>
                                                                    </ng-container>
                                                                    <i class="far fa-solid fa-sort grey4 text-center cursor-pointer" (click)="sortTable(col.value)"></i>
                                                                </div>
                                                            </th>
                                                        </ng-container>
    
                                                        <!-- expanded flag col headers -->
                                                        <ng-container *ngIf="expandFlags">
                                                            <ng-container *ngFor="let flag of flags; let f = index ">
                                                                <th width="90" style="min-width:90px; max-width:90px"
                                                                    class="sticky-cell text-truncate font-weight-bold header-seperator"
                                                                    [style.left]="(questionLevelLeft + 90*f) + 'px'"
                                                                    [class.flags-seperator-right]="f+1 == flags.length">
                                                                    <div class="row mx-0">
                                                                        <div class="text-truncate flag-text" [tooltip]="flag.id" container="body" placement="top" containerClass="flag-text-tooltip">{{flag.id}}</div>
                                                                        <div class="">
                                                                            <i class="far fa-solid nav-font14 fa-sort grey4 text-center cursor-pointer ml-1" (click)="sortTable('flag', flag.id)"></i>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </ng-container>
                                                        </ng-container>
    
                                                        <!-- open-end col headers -->
                                                        <ng-container *ngFor="let openEnd of openEnds; let o = index ">
                                                            <ng-container *ngFor="let col of questionLevelColumns; let c = index">
                                                                <ng-container *ngIf="c == 0 || openEnd.expand">
                                                                    <ng-container *ngIf="col.value !== 'my-assessment' || qcFlowDetails.settings.showMyAssessment">
                                                                        <th width="{{col.width}}" [style]="'min-width:'+col.width+'px; max-width:'+col.width+'px;'"
                                                                            class="{{col.class}} font-weight-bold header-seperator border-top"
                                                                            [class.openEndEvenBg]="(o+1) % 2 == 0"
                                                                            [style.left]="c == 0 ? (90*(o+1)) + 'px' : null"
                                                                            [class.border-left]="o > 0 || c > 0"
                                                                            [class.border-right]="!overflowingX && o == openEnds.length-1 && ((c == 0 && !openEnd.expand) || ((c == questionLevelColumns.length-1 || (c == questionLevelColumns.length-2 && !qcFlowDetails.settings.showMyAssessment)) && openEnd.expand))">
                                                                            <div class="row mx-0">
                                                                                <ng-container *ngIf="col.value === 'open-end'">
                                                                                    <i class="fal fa-circle-plus nav-font16 cursor-pointer mr-2 grey4 margin-top-3"
                                                                                        [class.fa-circle-plus]="!openEnd.expand"
                                                                                        [class.fa-circle-minus]="openEnd.expand"
                                                                                        (click)="openEnd.expand = !openEnd.expand; checkOverflow()">
                                                                                    </i>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="c == 0">
                                                                                    <div class="text-truncate question-text" [tooltip]="openEnd.text" placement="bottom" container="body" containerClass="question-text-tooltip" >{{openEnd.id}}</div>
                                                                                </ng-container>
                                                                                <ng-container *ngIf="c > 0">
                                                                                    <div class="">{{col.label}}</div>
                                                                                </ng-container>
    
                                                                                <div class=" nav-font14 ml-1">
                                                                                    <ng-container *ngIf="col.hasFilter">
                                                                                        <app-qc-flow-column-filter [filters]="[]" type="{{col.value}}" [fullscreen]="fullscreen" (filterChange)="applyFilters(col.value, $event, openEnd.id)"></app-qc-flow-column-filter>
                                                                                    </ng-container>
                                                                                    <i class="far fa-solid fa-sort grey4 text-center cursor-pointer " (click)="sortTable(col.value, openEnd.id)"></i>
                                                                                </div>
                                                                            </div>
                                                                        </th>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                        <th *ngIf="!overflowingX" [class.loading-bg]="pending || processing" class="filler-column border-top"></th>
                                                    </tr>
                                                </thead>
                                                <ng-container *ngIf="!processing && !pending ">
                                                    <tbody>
                                                        @for (respondent of filteredRecords | paginate : {
                                                                itemsPerPage: rowsPerPageForPagination,
                                                                currentPage: currentPage,
                                                              }; track respondent.id; let x = $index) {

                                                          <tr [style.display]="respondent.showRow ? 'block' : 'none'">
                                                                <!-- main respondent column cells -->
                                                                <ng-container *ngFor="let col of respondentLevelColumns; let c = index">
                                                                    <td width="{{col.width}}"
                                                                        [style]="'min-width:'+col.width+'px; max-width:'+col.width+'px;'"
                                                                        [class.rejectedRespondent]="respondent.decision.type === 'Reject'"
                                                                        [class.highlightChangedItems]="highlightChangedItems[respondent.respondentId]"
                                                                        [class.highlighted]="respondent.highlighted"
                                                                        class="sticky-cell"
                                                                        [class.px-1]="col.value === 'respondent-id'"
                                                                        [class.px-2]="col.value !== 'respondent-id'"
                                                                        [class.border-bottom]="x < filteredRecords.length-1 || !overflowingY"
                                                                        [class.border-left]="c == 0 && overflowingX"
                                                                        [class.nav-secondary]="col.value === 'respondent-id'"
                                                                        [class.text-truncate]="col.value === 'respondent-id'"
                                                                        [class.cursor-pointer]="col.value === 'respondent-id'"
                                                                        [class.font-weight-bold]="col.value === 'respondent-id' && respondent.highlighted"
                                                                        [class.flags-seperator-right]="(col.value === 'total-flags' && !expandFlags) || (col.value == 'qc-status' && flags.length == 0)"
                                                                        [style.left]="col.left+'px'">
                                                                        @if (col.value === 'respondent-id') {
                                                                            <div class="d-inline">
                                                                                <i class="fa-regular fa-highlighter-line" [class.visible]="respondent.highlighted" [ngClass]="respondent.highlighted ? 'grey6' : 'grey4'" (click)="respondent.highlighted = !respondent.highlighted"></i>
                                                                                <div class="or-checkbox d-inline pr-3 paddingLeft6 mr-2 ml-0">
                                                                                    <input [(ngModel)]="respondent.selected" type="checkbox" (change)="selectRecord(respondent)" [name]="'select-'+respondent.respondentId" [id]="'select-'+respondent.respondentId">
                                                                                    <label [for]="'select-'+respondent.respondentId"></label>
                                                                                </div>
                                                                            </div>
                                                                        }                                                                            
                                                                        <ng-container *ngIf="col.value === 'respondent-id'"><span (click)="openRespondent(respondent)">{{respondent.respondentId}}</span></ng-container>
    
                                                                        <ng-container *ngIf="col.value === 'qc-status'">
                                                                            <ng-container *ngTemplateOutlet="qcStatusDropdown; context: { respondent: respondent, container: 'body' }"></ng-container>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="col.value === 'total-flags'">{{respondent.qcFlagCount}}</ng-container>
                                                                    </td>
                                                                </ng-container>
    
                                                                <!-- expanded flag col cells -->
                                                                <ng-container *ngIf="expandFlags">
                                                                    <ng-container *ngFor="let flag of flags; let i = index">
                                                                        <td width="90"
                                                                            [style]="'min-width:90px; max-width:90px;'"
                                                                            [class.flags-seperator-right]="i+1 == flags.length"
                                                                            [class.rejectedRespondent]="respondent.decision.type === 'Reject'"
                                                                            [class.highlighted]="respondent.highlighted"
                                                                            class="sticky-cell"
                                                                            [class.border-bottom]="x < filteredRecords.length-1 || !overflowingY"
                                                                            [style.left]="(questionLevelLeft + 90*i) + 'px'">{{respondent.flags[flag.id]?.answer}}
                                                                        </td>
                                                                    </ng-container>
                                                                </ng-container>
    
                                                                <!-- open-end col cells -->
                                                                <ng-container *ngFor="let openEnd of openEnds; let o = index">
                                                                    <ng-container *ngFor="let col of questionLevelColumns; let c = index">
                                                                        <ng-container *ngIf="col.value === 'open-end' || openEnd.expand">
                                                                            <ng-container *ngIf="col.value !== 'my-assessment' || qcFlowDetails.settings.showMyAssessment">
                                                                                <td width="{{col.width}}"
                                                                                    [style]="'min-width:'+col.width+'px;'"
                                                                                    [class.openEndEvenBg]="(o+1) % 2 == 0"
                                                                                    [class.border-left]="o > 0 || c > 0"
                                                                                    [class.highlighted]="respondent.highlighted"
                                                                                    [class.border-right]="!overflowingX && 
                                                                                        o == openEnds.length-1 &&
                                                                                        ((col.value === 'open-end' && !openEnd.expand) ||
                                                                                        (qcFlowDetails.settings.showMyAssessment && col.value === 'my-assessment' && openEnd.expand) ||
                                                                                        (!qcFlowDetails.settings.showMyAssessment && col.value === 'ai-advice' && openEnd.expand))"
                                                                                    [class.py-0]="col.value === 'my-assessment'"
                                                                                    [class.border-bottom]="x < filteredRecords.length-1 || !overflowingY">
                                                                                    <div class="{{col.value}}" placement="left" (click)="$event.stopPropagation()" [adaptivePosition]="false" 
                                                                                        [tooltip]="col.value === 'ai-advice' && qcFlowDetails.settings.enhanceWithAI && respondent.openEnds[openEnd.id].analysis?.tooltip != null ? aiAdviceTooltip : (col.value === 'rid-score' && respondent.openEnds[openEnd.id]?.responseIdApi?.label ? ridSummaryTooltip : (col.value === 'open-end')? responseTooltip : '')" 
                                                                                        [container]="'body'"
                                                                                        containerClass="custom-qc-tooltip {{col.value}}"  [class.text-truncate]="col.value === 'open-end' && !wrapText"
                                                                                        [style]="'width:'+ (col.width - 19)+'px;'">
                                                                                        <ng-template #aiAdviceTooltip> <div [innerHtml]="respondent.openEnds[openEnd.id].analysis.tooltip" ></div></ng-template>
                                                                                        <ng-template #ridSummaryTooltip> <div [innerHtml]="respondent.openEnds[openEnd.id].responseIdApi.tooltip" ></div></ng-template>
                                                                                        <ng-template #responseTooltip> <div [innerHtml]="respondent.openEnds[openEnd.id].tooltip" ></div></ng-template>
                                                                                        <ng-container *ngIf="col.value === 'open-end'">"{{respondent.openEnds[openEnd.id]?.answer}}"</ng-container>
                                                                                        <ng-container *ngIf="col.value === 'rid-score'">
                                                                                            <div class="icon-container-in-table d-inline-block">
                                                                                                <i class="nav-font14 {{respondent.openEnds[openEnd.id]?.responseIdApi.icon}}"></i>
                                                                                            </div>
                                                                                            {{respondent.openEnds[openEnd.id]?.responseIdApi.label}}
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.value === 'ai-advice' && qcFlowDetails.settings.enhanceWithAI">
                                                                                            <div class="icon-container-in-table d-inline-block">
                                                                                                <i class="nav-font14 {{respondent.openEnds[openEnd.id].analysis.riskLevelIcon}}"></i>
                                                                                            </div>
                                                                                            {{respondent.openEnds[openEnd.id].analysis.riskLevelLabel}}
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="col.value === 'my-assessment'">
                                                                                            <div class="nav-font14 text-center">
                                                                                                <span class="assessmentContainer fal fa-square-check nav-success cursor-pointer m-1"
                                                                                                (click)="myAssessmentChange(respondent, openEnd, 'low')"
                                                                                                    [class.active]="respondent.openEnds[openEnd.id].myAssessment === 'low'">
                                                                                                </span>
                                                                                                <span class="assessmentContainer fal fa-triangle-exclamation mediumRiskColour cursor-pointer m-1"
                                                                                                (click)="myAssessmentChange(respondent, openEnd, 'medium')"
                                                                                                    [class.active]="respondent.openEnds[openEnd.id].myAssessment === 'medium'">
                                                                                                </span>
                                                                                                <span class="assessmentContainer far fa-octagon-xmark nav-error cursor-pointer m-1"
                                                                                                    (click)="myAssessmentChange(respondent, openEnd, 'high')"
                                                                                                    [class.active]="respondent.openEnds[openEnd.id].myAssessment === 'high'">
                                                                                                </span>
                                                                                            </div>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </td>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <td *ngIf="!overflowingX" [class.loading-bg]="pending || processing" class="filler-column"></td>
                                                            </tr>
                                                        }
                                                        <tr *ngIf="!overflowingY && overflowingX" id="filler-row" [style.--last-row-height]="getLastRowHeight()">
                                                            <td class="filler-column border-left sticky-cell" [style.--last-row-height]="getLastRowHeight()"></td>
                                                        </tr>
                                                    </tbody>
                                                </ng-container>
                                            </table>
                                            <ng-container *ngIf="pending || processing || dataLoading">
                                                <div class="my-5 loadingInFlow">
                                                    <div class="text-center my-2">
                                                        <span class="loading-qc"></span>
                                                    </div>
                                                    <div class="nav-font18 grey4 text-center">
                                                        <ng-container *ngIf="pending || processing">
                                                            Loading data {{ percentComplete | percent}} complete                                                    
                                                        </ng-container>
    
                                                        <ng-container *ngIf="dataLoading">
                                                            Preparing data, please wait..
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer pb-0 px-0 mr-3" [class.no-pointer-events]="pending || processing">
                                    <div class="row d-flex align-items-center mx-0">
                                        <div class="d-flex pagination-container pl-1 ml-2" [style.visibility]="qcFlowRecords?.length > 100 && qcFlowStage != 'summary' ? 'visible' : 'hidden'">
                                            <div class="pagination-controls-container">
                                                <pagination-controls
                                                    class="qcflow-pagination"
                                                    previousLabel=""
                                                    nextLabel=""
                                                    [itemsPerPage]="rowsPerPageForPagination"
                                                    autoHide="false"
                                                    responsive="true"
                                                    maxSize="7"
                                                    (pageChange)="onPageChange($event)">
                                              </pagination-controls>
                                            </div>
                                            <div class="">
                                                <div class="btn-group paginationDropdown cursor-default" [style.z-index]="paginationDropdown.isOpen ? 9999 : 1" [class.isOpen]="paginationDropdown.isOpen" dropdown dropdownToggle container="body" #paginationDropdown="bs-dropdown" aria-controls="dropdown-dropup">
                                                    <div>{{rowsPerPage}} per page </div>
                                                    <span class="ml-auto fas grey4 nav-font8 cursor-default" [class.fa-chevron-up]="paginationDropdown.isOpen" [class.fa-chevron-down]="!paginationDropdown.isOpen"></span>
                                                    <div *dropdownMenu class="paginationDropdownMenu" role="menu" aria-labelledby="button-dropup">
                                                        <div class="dropdown-item cursor-pointer mb-1" (click)="changeRowsPerPage(200)">200 per page</div>
                                                        <div class="dropdown-item cursor-pointer mb-1" (click)="changeRowsPerPage(175)">175 per page</div>
                                                        <div class="dropdown-item cursor-pointer mb-1" (click)="changeRowsPerPage(150)">150 per page</div>
                                                        <div class="dropdown-item cursor-pointer mb-1" (click)="changeRowsPerPage(125)">125 per page</div>
                                                        <div class="dropdown-item cursor-pointer mb-1" (click)="changeRowsPerPage(100)">100 per page</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="qc-scorecard d-flex p-1 pl-2 ml-auto mr-auto">
                                            <div class="nav-font14 font-weight-bold grey5">QC Scorecard</div>
                                            <div class="col pl-2 pr-0 d-flex align-items-center grey5 nav-font12">
                                                <span class="ml-2">Approved <span class="grey6 font-weight-bolder ml-1 mr-2">{{qcFlowDetails?.status.acceptedCount}}</span></span>
                                                <span class="far grey1 fa-thin fa-lg fa-pipe nav-font20 cursor-default"></span>
                                                <span class="ml-2"> Pending <span class="grey6 font-weight-bolder ml-1 mr-2">{{qcFlowDetails?.status.respondentProcessedCount - (qcFlowDetails?.status.acceptedCount + qcFlowDetails?.status.rejectedCount)}}</span> </span>
                                                <span class="far grey1 fa-thin fa-lg fa-pipe nav-font20 cursor-default"></span>
                                                <span class="ml-2"> Rejected <span class="grey6 font-weight-bolder ml-1 mr-2">{{qcFlowDetails?.status.rejectedCount}}</span> </span>
                                                <span class="far grey1 fa-thin fa-lg fa-pipe nav-font20 cursor-default"></span>
                                                <span class="ml-2">Acceptance rate  <span class="grey6 font-weight-bolder ml-1 mr-2">{{(qcFlowDetails?.status.acceptedCount/(qcFlowDetails?.status.acceptedCount + qcFlowDetails?.status.rejectedCount) || 0) | percent}}</span></span>
                                            </div>
                                        </div>
                                        
                                        <div class="text-right align-items-center mr-3">
                                          <span class="nav-error nav-font12 cursor-pointer font-weight-bold mr-2"
                                                (click)="openResetRecordsDialog()">Reset data</span>
                                          <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold pb-2 pt-1" [class.disabledBtnPrimary]="processing || pending || (isClient && sampleOnlyORProject)" (click)="confirmReconcile($event)" tooltip="Click to update OR1 statuses in this project" placement="bottom">Reconcile IDs</button>
                                          <button class="btn btn-sm nav-btn-accent shadow-none px-3 pb-2 pt-1" (click)="saveAllChanges()" [class.disabledBtnPrimary]="changeIdx <0" placement="bottom">Save</button>
                                        </div>
                                    </div> 
                                </div>
                        </div>
    
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    
    <!-- respondent view modal -->
    <ng-template #modalRespondentView>
    <div class="modal-header">
        <h3 class="font-weight-bold nav-secondary">
            Respondent View
        </h3>

        <div class="ml-auto nav-font14 grey4">
            <div class="row d-flex align-items-center mx-0 my-assessment-toggle">
                <span class="mx-2">My Assessment</span>
                <div class="d-inline">
                    <or-toggle-button class="light" size="small"
                        [(ngModel)]="currentRespondent.showMyAssessment"></or-toggle-button>
                </div>
            </div>
        </div>
        <div class="text-right px-0 ml-3">
            <i class="fas fa-close nav-font20 grey4" (click)="openModalRef.hide()"></i>
        </div>
    </div>
    <div class="modal-body pt-0">
        <div class="col-3 d-flex align-items-center px-0 mb-3">
            <div class="input-group copy-search">
                <div class="input-group-prepend height24">
                    <div class="input-group-text border-right-0">
                        <i class="fas fa-search cursor-default nav-font14"></i>
                    </div>
                </div>
                <input [(ngModel)]="respondentViewFilterText" (ngModelChange)="updateRespondentViewTable()"
                    type="search" class="form-control form-control-sm height24" placeholder="Search">
            </div>
        </div>
        <div class="card-body nav-font14 respondentView py-0 pb-1 px-3">
            <div class="row">
                <div class="grey6 px-0 respondentViewTableContainer d-flex"
                    (scroll)="syncScrollRespondentView($event);">
                    <table class="respondentViewTable respondentLevel flags-seperator-right border-top">
                        <thead class=" respondentLevelHead border-left">
                            <tr class="">
                                <th [attr.colspan]="currentRespondent.expandFlags ? (3+flags.length) : 3"
                                    class="stickyHeader nav-font14 font-weight-bold grey6 border-bottom"
                                    [style.left]="0">
                                    Respondent Level
                                </th>
                            </tr>
                                <tr class="">
                                    <!-- main respondent column headers-->
                                    <ng-container *ngFor="let col of respondentLevelColumns; let i = index">
                                        <th [width]="col.value !== 'respondent-id' ? col.respondentViewWidth : null"
                                            [style]="col.value !== 'respondent-id' ? 'min-width:'+col.respondentViewWidth+'px; max-width:'+col.respondentViewWidth+'px;' : 'min-width:115px'"
                                            [class.flags-seperator-left]="col.value === 'total-flags'"
                                            [class.border-left]="i > 0 && i < respondentLevelColumns.length-1"
                                            class="px-2 font-weight-bold header-seperator">
                                            <ng-container *ngIf="col.value === 'total-flags'">
                                                <i class="fal cursor-pointer nav-font16 mr-2 grey4"
                                                [class.fa-circle-plus]="!currentRespondent.expandFlags"
                                                [class.fa-circle-minus]="currentRespondent.expandFlags"
                                                (click)="currentRespondent.expandFlags = !currentRespondent.expandFlags; checkOverflowRespondentView()"></i>
                                            </ng-container>
                                            {{col.label}}
                                        </th>
                                    </ng-container>
    
                                        <!-- expanded flag col headers -->
                                    <ng-container *ngIf="currentRespondent.expandFlags">
                                        <ng-container *ngFor="let flag of flags; let f = index ">
                                            <th width="90" style="min-width:90px; max-width:90px"
                                                class=" font-weight-bold border-left header-seperator">
                                                <div class="text-truncate" [tooltip]="flag.id" container="body" placement="top" containerClass="flag-text-tooltip">
                                                    {{flag.id}}
                                                </div>
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <!-- main respondent column cells -->
                                    <ng-container *ngFor="let col of respondentLevelColumns; let c = index">
                                        <td [class.rejectedRespondent]="currentRespondent.decision.type === 'Reject'"
                                            class="border-bottom px-2"
                                            [class.nav-secondary]="col.value === 'respondent-id'"
                                            [class.flags-seperator-left]="col.value === 'total-flags'"
                                            [class.border-left]="c > 0 && c < respondentLevelColumns.length-1">
                                            <ng-container *ngIf="col.value === 'respondent-id'">{{currentRespondent.respondentId}}</ng-container>
    
                                            <ng-container *ngIf="col.value === 'qc-status'">
                                                <ng-container *ngTemplateOutlet="qcStatusDropdown; context: { respondent: currentRespondent, container: '.respondentView' }"></ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="col.value === 'total-flags'">{{currentRespondent.qcFlagCount}}</ng-container>
                                        </td>
    
                                    </ng-container>
                                    <!-- expanded flag col cells -->
                                    <ng-container *ngIf="currentRespondent.expandFlags">
                                        <ng-container *ngFor="let flag of flags; let f = index">
                                            <td [class.rejectedRespondent]="currentRespondent.decision.type === 'Reject'"
                                                class="border-left">{{currentRespondent.flags[flag.id]?.answer}}
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                            </thead>
                    </table>
                    <table class="respondentViewTable questionLevel" [class.border-right]="!respondentViewOverflowingY"
                        [class.respondent-view-overflowing-y]="respondentViewOverflowingY">
                        <thead class=" border-top border-bottom questionLevelHead w-100"
                            [class.border-right]="respondentViewOverflowingY">
                            <tr class="">
                                <th [attr.colspan]="currentRespondent.showMyAssessment ? 4 : 3"
                                    [width]="getObjectLength(currentRespondent.filteredOpenEnds) > 0 ? '' : 727"
                                    class="stickyHeader nav-font14 font-weight-bold">
                                    Question Level
                                </th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <ng-container *ngFor="let openEnd of openEnds;">
                                <tr *ngIf="getNextIndexByKey(openEnd.id, currentRespondent.filteredOpenEnds); let nextIndex"
                                    class="header-seperator">
                                    <ng-container
                                        *ngIf="currentRespondent.filteredOpenEnds[openEnd.id] as filteredOpenEnd">

                                        <!-- open-end col headers -->
                                        <ng-container *ngFor="let col of questionLevelColumns; let c = index">
                                            <ng-container
                                                *ngIf="col.value !== 'my-assessment' || currentRespondent.showMyAssessment">
                                                <th width="{{col.respondentViewWidth}}"
                                                    [style]="'min-width:'+col.respondentViewWidth+'px; max-width:'+col.respondentViewWidth+'px;'"
                                                    class="{{col.class}} font-weight-bold" [class.border-left]="c > 0"
                                                    [class.padding-top9]="nextIndex-1 == 0"
                                                    [class.border-top]="nextIndex-1 > 0 && getObjectByIndex(nextIndex-2, currentRespondent.filteredOpenEnds).expand"
                                                    [class.openEndEvenBg]="nextIndex % 2 == 0"
                                                    [class.paddingTop10]="nextIndex-1 == 1 && !filteredOpenEnd.expand"
                                                    [class.padding-bottom9]="nextIndex-1 == 1 && !filteredOpenEnd.expand"
                                                    [class.header-seperator]="nextIndex-1 == 0"
                                                    [class.border-bottom]=" ((nextIndex-1 > 0 && filteredOpenEnd.expand) || (getObjectByIndex(nextIndex, currentRespondent.filteredOpenEnds) == null && !filteredOpenEnd.expand && !respondentViewOverflowingX ))">
                                                    <div class="row mx-0">
                                                        <ng-container *ngIf="col.value === 'open-end'">
                                                            <i class="fal fa-circle-plus nav-font16 cursor-pointer mr-2 grey4 margin-top-3"
                                                                [class.fa-circle-plus]="!filteredOpenEnd.expand"
                                                                [class.fa-circle-minus]="filteredOpenEnd.expand"
                                                                (click)="filteredOpenEnd.expand = !filteredOpenEnd.expand; checkOverflowRespondentView();">
                                                            </i>
                                                        </ng-container>
                                                        <ng-container *ngIf="c == 0">
                                                            <div class="d-inline question-text text-truncate"
                                                                [tooltip]="openEnd.text" adaptivePosition="false"
                                                                placement="right" container="body"
                                                                containerClass="question-text-tooltip">
                                                                {{filteredOpenEnd.id}}</div>
                                                        </ng-container>
                                                        <ng-container *ngIf="c > 0">
                                                            <div class="">{{col.label}}</div>
                                                        </ng-container>
                                                    </div>
                                                </th>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                                <ng-container *ngIf="currentRespondent.filteredOpenEnds[openEnd.id] as filteredOpenEnd">
                                    <ng-container
                                        *ngIf="getNextIndexByKey(filteredOpenEnd.id, currentRespondent.filteredOpenEnds); let nextIndex">
                                        <tr *ngIf="filteredOpenEnd && filteredOpenEnd.expand">
                                            <!-- open-end col cells -->
                                            <ng-container *ngFor="let col of questionLevelColumns; let c = index">
                                                <ng-container
                                                    *ngIf="col.value === 'open-end' || currentRespondent.filteredOpenEnds[openEnd.id].expand">
                                                    <ng-container
                                                        *ngIf="col.value !== 'my-assessment' || currentRespondent.showMyAssessment">
                                                        <td width="{{col.respondentViewWidth}}"
                                                            [style]="'min-width:'+col.respondentViewWidth+'px;max-width:'+col.respondentViewWidth+'px'"
                                                            [class.openEndEvenBg]="nextIndex % 2 == 0"
                                                            [class.border-bottom]="!respondentViewOverflowingX && getObjectByIndex(nextIndex, currentRespondent.filteredOpenEnds) == null"
                                                            [class.border-left]="c > 0"
                                                            [class.py-0]="col.value === 'my-assessment'"
                                                            [class.padding-top9]="col.value !== 'my-assessment'"
                                                            [class.paddingBottom9]="col.value !== 'my-assessment'">
                                                            <div class="{{col.value}}" placement="left"
                                                                (click)="$event.stopPropagation()"
                                                                [adaptivePosition]="false"
                                                                [tooltip]="col.value === 'ai-advice' && qcFlowDetails.settings.enhanceWithAI ? aiAdviceTooltip : ''"
                                                                [container]="'body'" containerClass="ai-advice-tooltip">
                                                                <ng-template #aiAdviceTooltip>
                                                                    <div
                                                                        [innerHtml]="currentRespondent.filteredOpenEnds[openEnd.id].analysis.tooltip">
                                                                    </div>
                                                                </ng-template>
                                                                <ng-container
                                                                    *ngIf="col.value === 'open-end'">"{{currentRespondent.filteredOpenEnds[openEnd.id]?.answer}}"</ng-container>
                                                                <ng-container *ngIf="col.value === 'rid-score'">
                                                                    <div class="icon-container-in-table d-inline-block">
                                                                        <i
                                                                            class="nav-font14 {{currentRespondent.filteredOpenEnds[openEnd.id].responseIdApi.icon}}"></i>
                                                                    </div>
                                                                    {{currentRespondent.filteredOpenEnds[openEnd.id]?.responseIdApi?.label}}
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="col.value === 'ai-advice' && qcFlowDetails.settings.enhanceWithAI">
                                                                    <div class="icon-container-in-table d-inline-block">
                                                                        <i
                                                                            class="nav-font14 {{currentRespondent.filteredOpenEnds[openEnd.id].analysis.riskLevelIcon}}"></i>
                                                                    </div>
                                                                    {{currentRespondent.filteredOpenEnds[openEnd.id].analysis.riskLevelLabel}}
                                                                </ng-container>
                                                                <ng-container *ngIf="col.value === 'my-assessment'">
                                                                    <div class="nav-font14 text-center">
                                                                        <span
                                                                            class="assessmentContainer fal fa-square-check nav-success cursor-pointer m-1"
                                                                            (click)="myAssessmentChange(currentRespondent, openEnd, 'low')"
                                                                            [class.active]="currentRespondent.filteredOpenEnds[openEnd.id].myAssessment === 'low'">
                                                                        </span>
                                                                        <span
                                                                            class="assessmentContainer fal fa-triangle-exclamation mediumRiskColour cursor-pointer m-1"
                                                                            (click)="myAssessmentChange(currentRespondent, openEnd, 'medium')"
                                                                            [class.active]="currentRespondent.filteredOpenEnds[openEnd.id].myAssessment === 'medium'">
                                                                        </span>
                                                                        <span
                                                                            class="assessmentContainer far fa-octagon-xmark nav-error cursor-pointer m-1"
                                                                            (click)="myAssessmentChange(currentRespondent, openEnd, 'high')"
                                                                            [class.active]="currentRespondent.filteredOpenEnds[openEnd.id].myAssessment === 'high'">
                                                                        </span>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </ng-container>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row d-flex w-100 align-items-center">
            <div class="col text-right px-0">
                <button class="btn btn-sm nav-btn-accent shadow-none px-2 pb-1" (click)="openModalRef.hide()">Close
                    view</button>
            </div>
        </div>
    </div>
</ng-template>


<!-- QC Status picker -->
<ng-template #qcStatusDropdown let-respondent="respondent" let-container="container">
    <div class="btn-group" dropdown #dropdown="bs-dropdown" [container]="container" class="qcStatusDropdown">
        <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex">
            <div class="userDecisionContainer cursor-pointer d-flex align-items-center grey"
                [ngClass]="dropdown.isOpen ? 'open' : 'closed'">
                <div class="icon-container d-inline-block">
                    <i *ngIf="respondent.decision.type === 'Accept'" class="fak fa-message-success nav-success"></i>
                    <i *ngIf="respondent.decision.type === 'Pending'" class="fak fa-clock grey4"></i>
                    <i *ngIf="respondent.decision.type === 'Reject'" class="fak fa-message-error nav-error"></i>
                </div>
                {{respondent.decision.type}}
                <i class="far grey4 float-right nav-font16 ml-auto" [class.fa-angle-up]="dropdown.isOpen"
                    [class.fa-angle-down]="!dropdown.isOpen">
                </i>
            </div>
        </div>
        <div id="dropdownQCStatus" *dropdownMenu
            class="userDecisionDropdown dropdown-menu dropdown-menu-right py-0 nav-font14" role="menu"
            aria-labelledby="button-basic" (click)="$event.stopPropagation()">
            <div class="" [ngStyle]="">
                <ul class=" py-0 px-0 my-0 noShadow">
                    <li class="expandable grey4 nav-font14 d-flex align-items-center px-0"
                        (click)="decisionChange(respondent, 'Accept', 'User'); dropdown.toggle(true)">
                        <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-1">
                            <div class="icon-container d-inline-block">
                                <i class="fak fa-message-success nav-success mt-1 font-weight-normal"></i>
                            </div>
                            <span>Accept</span>

                        </div>
                    </li>
                    <hr class="p-0 my-0 mx-1">
                    <li class="expandable grey4 nav-font14 d-flex align-items-center px-0"
                        (click)="decisionChange(respondent, 'Pending', 'User'); dropdown.toggle(true)">
                        <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-1">
                            <div class="icon-container d-inline-block">
                                <i class="fak fa-clock grey4 mr-2"></i>
                            </div>
                            <span>Pending</span>
                        </div>
                    </li>
                    <hr class="p-0 my-0 mx-1">
                    <li class="expandable grey4 nav-font14 d-flex align-items-center px-0"
                        (click)="decisionChange(respondent, 'Reject', 'User'); dropdown.toggle(true)">
                        <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-1">
                            <div class="icon-container d-inline-block">
                                <i class="fak fa-message-error nav-error mr-2"></i>
                            </div>
                            <span>Reject</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</ng-template>

<!-- reconcile modal confirm -->
<ng-template #modalReconcile>
    <div class="modal-header pt-2 pr-2">
        <div class="col pt-3 px-0">
            <div class="row d-flex align-items-center mx-0">
                <i class="fak fa-user-check grey4 nav-font20 mx-2 "></i>
                <span class="font-weight-bold nav-secondary nav-font16">Reconcile OR1s</span>
            </div>
        </div>
        <div class="col-2 text-right px-0">
            <i class="far fa-close grey4 nav-font18 p-1 pr-2" (click)="openModalRef.hide()"></i>
        </div>
    </div>
    <div class="modal-body pt-0 ml-2">
        <h5 class="grey5 d-block mb-4 mr-4 pb-3">
            Ready to reconcile Respondent IDs? This will update your respondent data in this NavigatOR project, please
            be patient as it could take up to 15 minutes to update.
        </h5>
        <div class="font-weight-bold mb-3 grey5">QC Scorecard Summary</div>
        <div class="row nav-font14">
            <div class="col-6 responseIdChart px-0">
                <highcharts-chart id="respondentOverviewChart" [Highcharts]="highcharts"
                    [options]="highchartsOptionsRespondentOverview" style="width: 100%; height: 100%; display: block;">
                </highcharts-chart>
            </div>

            <div class="col grey5 nav-font14 mt-2 px-0">
                <div *ngIf="qcFlowDetails?.status.acceptedCount > 0" class="">
                    <i class="fas fa-square nav-success nav-font16 mr-2"></i>
                    <span>Accepted Respondents ({{qcFlowDetails?.status.acceptedCount}})</span>
                </div>
                <div *ngIf="(qcFlowDetails?.status.respondentProcessedCount - (qcFlowDetails?.status.acceptedCount + qcFlowDetails?.status.rejectedCount)) > 0"
                    class="">
                    <i class="fas fa-square grey2 nav-font16 mr-2"></i>
                    <span>Pending Respondents ({{qcFlowDetails?.status.respondentProcessedCount -
                        (qcFlowDetails?.status.acceptedCount + qcFlowDetails?.status.rejectedCount)}})
                    </span>
                </div>
                <div *ngIf="qcFlowDetails?.status.rejectedCount > 0" class="">
                    <i class="fas fa-square nav-error nav-font16 mr-2"></i>
                    <span>Rejected Respondents ({{qcFlowDetails?.status.rejectedCount}})</span>
                </div>
                <div class="font-weight-bold my-3 ml-4">
                    {{(qcFlowDetails?.status.acceptedCount/(qcFlowDetails?.status.acceptedCount +
                    qcFlowDetails?.status.rejectedCount) || 0) | percent}} Acceptance Rate </div>
            </div>
        </div>
        @if (usedDecipher) {
          <h5 class="grey5 mt-2 mb-0 mr-4">
            This reconciliation process will also automatically update the survey with the new confirmed status in Decipher.
          </h5>
        }
    </div>
    <div class="modal-footer pt-0">
        <div class="row d-flex w-100 align-items-center">
            <div class="col text-right px-0">
                <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold"
                    (click)="openModalRef.hide()">Cancel</button>
                <button class="btn btn-sm nav-btn-accent shadow-none pb-1" (click)="saveAllChanges(true)">Reconcile
                    IDs</button>
            </div>
        </div>
    </div>
</ng-template>

<!-- reconcile modal confirm -->
<ng-template #modalQCRules>
    <div class="modal-header pt-2 pr-2">
        <div class="col pt-3 px-0">
            <div class="row d-flex align-items-center mx-0">
                <i class="fak fa-rule grey4 nav-font20 mx-2 "></i>
                <span class="font-weight-bold nav-secondary nav-font16">QC Rules</span>
            </div>
        </div>
        <div class="col-2 text-right px-0">
            <i class="far fa-close grey4 nav-font18 p-1 pr-2" (click)="cancel()"></i>
        </div>
    </div>
    <div class="modal-body pt-0 ml-2">
        <div class="row nav-font14 align-items-center">
            <div class="col">
                <span class="grey5 nav-font14 d-block ml-4">
                    To create a rule, click ‘Add rule to’ and select the QC Status you are creating the rule for. Once
                    you have added your rule(s), click save.
                </span>
            </div>
            <div class="col-3 d-flex justify-content-end">
                <div class="btn-group" dropdown #dropdownAddRule="bs-dropdown" class="qcAddRuleDropdown">
                    <div dropdownToggle controls="dropdown-basic" class="btn btn-sm nav-btn-accent shadow-none px-1">
                        <div class="userDecisionContainer cursor-pointer d-flex align-items-center"
                            [ngClass]="dropdownAddRule.isOpen ? 'open' : 'closed'">
                            <span class="border-right pr-3 pl-2 font-weight-bold">
                                <i class="far fa-plus text-white font-weight-normal mr-2 padding-right2"></i>Add rule to
                            </span>
                            <i class="far nav-font16 mt-1 fa-angle-down pr-1 pl-2"
                                [class.fa-angle-up]="dropdownAddRule.isOpen" [class.mt-1]="!dropdownAddRule.isOpen"
                                [class.fa-angle-down]="!dropdownAddRule.isOpen">
                            </i>
                        </div>
                    </div>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right py-0 nav-font14" role="menu"
                        aria-labelledby="button-basic" (click)="$event.stopPropagation()">
                        <div class="" [ngStyle]="">
                            <ul class="px-1 py-0 my-0">
                                <li class="grey5 nav-font14 d-flex align-items-center my-1 py-1 px-1"
                                    (click)="addQcRule('Accept'); dropdownAddRule.toggle(true)">
                                    <div class="dropdown-list-item cursor-pointer font-weight-normal pl-1">
                                        <span>Accept QC Status</span>
                                    </div>
                                </li>
                                <hr class="p-0 my-0 mx-1">
                                <li class="grey5 nav-font14 d-flex align-items-center my-1 py-1 px-1"
                                    (click)="addQcRule('Reject'); dropdownAddRule.toggle(true)">
                                    <div class="dropdown-list-item cursor-pointer font-weight-normal pl-1">
                                        <span>Reject QC Status</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col grey3 nav-font14 mt-3 ml-4" *ngIf="qcFlowRules?.length === 0">
                No rules available. Click ‘Add rule to’ and select a status to begin.
            </div>
            <div class="col mt-3">
                <div class="scrollbar qc-rules overflow-y overflow-x-hidden">
                    <ng-container *ngFor="let rule of qcFlowRules; let idx = index">
                        <div class="rule-item mb-3">
                            <div>
                                <i class="fak fa-trash text-danger cursor-pointer nav-font16"
                                    (click)="deleteQcRule(rule, idx)"></i>
                            </div>
                            <div class="card nav-font14 grey5 font-weight-bold">
                                <div class="card-body">
                                    <div class="row mb-4">
                                        <div class="col-6 d-flex align-items-center">
                                            <span class="text-nowrap">Rule name</span>
                                            <input type="text"
                                                class="form-control form-control-nav form-control-xs ml-3 w-50"
                                                placeholder="Type name for this rule" [(ngModel)]="rule.name">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col rule-grid">
                                            <div class="d-flex">
                                                <span class="text-nowrap mr-3 margin-top-5">Set respondent QC status
                                                    to</span>
                                                <div class="btn-group dropdown my-1 status" dropdown
                                                    #statusRuleDropdown="bs-dropdown" container="body"
                                                    [style.z-index]="1050">
                                                    <div class="d-block text-right"></div>
                                                    <div class="select-container"
                                                        [class.cursor-pointer]="oneRecordSelected"
                                                        [class.open]="statusRuleDropdown.isOpen" id="button-basic"
                                                        dropdownToggle controls="dropdown-basic">
                                                        <span class="row mx-0 nav-font14 cursor-pointer"
                                                            [ngClass]="!rule.status ? 'grey3' : 'grey5'">
                                                            <div class="text-truncate font-weight-normal">{{rule.status
                                                                ?? 'Select status'}}</div>
                                                            <div class="d-inline ml-auto">
                                                                <i class="fas grey5 nav-font14 ml-1 mt-1"
                                                                    [class.fa-chevron-down]="!statusRuleDropdown.isOpen"
                                                                    [class.fa-chevron-up]="statusRuleDropdown.isOpen"></i>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <ul id="dropdown-assessment" *dropdownMenu
                                                        class="select-dropdown status-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14"
                                                        role="menu" aria-labelledby="button-basic"
                                                        [style.z-index]="1050">
                                                        <div class="d-block text-right px-2"></div>
                                                        <li role="menuitem" class="expandable margin-x-2"
                                                            [class.cursor-pointer]="oneRecordSelected"
                                                            (click)="rule.status = 'Accept'; changeQcRuleValue(rule); statusRuleDropdown.toggle(true)">
                                                            <a
                                                                class="dropdown-item d-flex align-items-center text-truncate padding-x-6 my-1">
                                                                Accept
                                                            </a>
                                                        </li>
                                                        <li role="menuitem" class="expandable margin-x-2"
                                                            [class.cursor-pointer]="oneRecordSelected"
                                                            (click)="rule.status = 'Reject'; changeQcRuleValue(rule); statusRuleDropdown.toggle(true)">
                                                            <a
                                                                class="dropdown-item d-flex align-items-center padding-x-6 my-1">
                                                                Reject
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <ng-container *ngFor="let check of rule?.checks; let idxCheck = index">
                                                <ng-container *ngIf="idxCheck > 0">
                                                    <div class="text-right">
                                                        <i class="fak fa-trash text-danger cursor-pointer mr-3 nav-font16 font-weight-normal"
                                                            (click)="deleteQcRuleCheck(rule, idxCheck)"></i>
                                                        <div *ngIf="idxCheck === 1"
                                                            class="btn-group dropdown my-1 logic " dropdown
                                                            #logicRuleDropdown="bs-dropdown" container="body">
                                                            <div class="select-container logic-first"
                                                                [class.cursor-pointer]="oneRecordSelected"
                                                                [class.open]="logicRuleDropdown.isOpen"
                                                                id="button-basic" dropdownToggle
                                                                controls="dropdown-basic">
                                                                <span class="row mx-0 nav-font14 grey5 cursor-pointer">
                                                                    <div class="text-truncate font-weight-normal">
                                                                        {{rule.logic}}</div>
                                                                    <div class="d-inline ml-auto">
                                                                        <i class="fas grey5 nav-font14 ml-1 mt-1"
                                                                            [class.fa-chevron-down]="!logicRuleDropdown.isOpen"
                                                                            [class.fa-chevron-up]="logicRuleDropdown.isOpen"></i>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            <ul id="dropdown-assessment" *dropdownMenu
                                                                class="select-dropdown logic-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14"
                                                                role="menu" aria-labelledby="button-basic">
                                                                <div class="d-block text-right px-2"></div>
                                                                <li role="menuitem" class="expandable margin-x-2"
                                                                    [class.cursor-pointer]="oneRecordSelected"
                                                                    (click)="rule.logic = 'And'; changeQcRuleValue(rule); logicRuleDropdown.toggle(true)">
                                                                    <a
                                                                        class="dropdown-item d-flex align-items-center text-truncate padding-x-6 my-1">
                                                                        And
                                                                    </a>
                                                                </li>
                                                                <li role="menuitem" class="expandable margin-x-2"
                                                                    [class.cursor-pointer]="oneRecordSelected"
                                                                    (click)="rule.logic = 'Or'; changeQcRuleValue(rule); logicRuleDropdown.toggle(true)">
                                                                    <a
                                                                        class="dropdown-item d-flex align-items-center text-truncate padding-x-6 my-1">
                                                                        Or
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div class="form-control form-control-xs form-control-nav logic logic-readonly-bg margin-top-5 d-inline-block text-left"
                                                            *ngIf="idxCheck > 1">
                                                            {{rule.logic}}
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="mb-3"
                                                    [ngClass]="check?.metric == 'NonAnswer' || check?.metric == 'DupeAnswer' || check?.metric == 'DupeRespondent' || check?.metric == 'Blank' ? 'long-rule' : 'short-rule'"
                                                    [class.rid]="check?.metric === 'RID'">
                                                    <div class="d-flex">
                                                        <span class="mx-2 margin-top-5">if</span>
                                                        <div class="btn-group dropdown my-1 subject" container="body"
                                                            dropdown #subjectRuleDropdown="bs-dropdown">
                                                            <div class="select-container"
                                                                [class.cursor-pointer]="oneRecordSelected"
                                                                [class.open]="subjectRuleDropdown.isOpen"
                                                                id="button-basic" dropdownToggle
                                                                controls="dropdown-basic">
                                                                <span class="row mx-0 nav-font14 cursor-pointer"
                                                                    [ngClass]="!check.metric ? 'grey3' : 'grey5'">
                                                                    <div class="text-truncate font-weight-normal mr-3">
                                                                        {{ getQcRuleSubjectValue(check.metric) }}</div>
                                                                    <div class="d-inline ml-auto">
                                                                        <i class="fas grey5 nav-font14 mt-1 chevron-absolute"
                                                                            [class.fa-chevron-down]="!subjectRuleDropdown.isOpen"
                                                                            [class.fa-chevron-up]="subjectRuleDropdown.isOpen"></i>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            <ul id="dropdown-assessment" *dropdownMenu
                                                                class="select-dropdown subject-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14"
                                                                role="menu" aria-labelledby="button-basic">
                                                                <div class="d-block text-right px-2"></div>
                                                                <ng-container
                                                                    *ngFor="let subject of qcRuleSubjectArray">
                                                                    <li *ngIf="subject.key != 'AI' || qcFlowDetails.settings.enhanceWithAI"
                                                                        role="menuitem" class="expandable margin-x-2"
                                                                        [class.cursor-pointer]="oneRecordSelected"
                                                                        (click)="check.metric = subject.key; changeQcRuleMetric(rule, check); subjectRuleDropdown.toggle(true)">
                                                                        <a
                                                                            class="dropdown-item d-flex align-items-center text-truncate padding-x-6 my-1">
                                                                            {{subject.value}}
                                                                        </a>
                                                                    </li>
                                                                </ng-container>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                    <div class="d-flex">

                                                        <ng-container
                                                            *ngIf="check?.metric === 'AI' || check?.metric === 'My' || check?.metric === 'RID'">
                                                            <span class="mx-3 margin-top-5">is</span>
                                                            <div class="btn-group dropdown my-1 risk" dropdown
                                                                #riskRuleDropdown="bs-dropdown" container="body">
                                                                <div class="select-container"
                                                                    [class.cursor-pointer]="oneRecordSelected"
                                                                    [class.open]="riskRuleDropdown.isOpen"
                                                                    id="button-basic" dropdownToggle
                                                                    controls="dropdown-basic">
                                                                    <span class="row mx-0 nav-font14 cursor-pointer"
                                                                        [ngClass]="!check.value ? 'grey3' : 'grey5'">
                                                                        <ng-container *ngIf="check.value">
                                                                            <div class="icon-container d-inline-block">
                                                                                <i *ngIf="check.value === 'high'"
                                                                                    class="fal fa-octagon-xmark nav-error nav-font14 pr-2"></i>
                                                                                <i *ngIf="check.value === 'medium'"
                                                                                    class="fal fa-triangle-exclamation mediumRiskColour nav-font14 pr-2"></i>
                                                                                <i *ngIf="check.value === 'low'"
                                                                                    class="fal fa-square-check nav-success margin-left-1 nav-font14 pr-2"></i>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="check?.metric !== 'RID'">
                                                                            <div
                                                                                class="text-truncate font-weight-normal">
                                                                                {{qcRuleRiskDictionary[check.value] ??
                                                                                'Select risk'}}</div>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="check?.metric === 'RID'">
                                                                            <div
                                                                                class="text-truncate font-weight-normal">
                                                                                {{qcRuleRIDDictionary[check.value] ??
                                                                                'Select risk'}}</div>
                                                                        </ng-container>
                                                                        <div class="d-inline ml-auto">
                                                                            <i class="fas grey5 nav-font14 ml-1 mt-1"
                                                                                [class.fa-chevron-down]="!riskRuleDropdown.isOpen"
                                                                                [class.fa-chevron-up]="riskRuleDropdown.isOpen"></i>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <ul id="dropdown-assessment" *dropdownMenu
                                                                    class="select-dropdown risk-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14"
                                                                    role="menu" aria-labelledby="button-basic">
                                                                    <div class="d-block text-right px-2"></div>
                                                                    <ng-container *ngIf="check?.metric !== 'RID'">
                                                                        <ng-container
                                                                            *ngFor="let risk of qcRuleRiskDictionary | keyvalue: asIsOrder">
                                                                            <li *ngIf="risk.key !== 'non answer' || check?.metric === 'AI'"
                                                                                role="menuitem"
                                                                                class="expandable margin-x-2"
                                                                                [class.cursor-pointer]="oneRecordSelected"
                                                                                (click)="check.value = risk.key; changeQcRuleValue(rule, idxCheck, check); riskRuleDropdown.toggle(true)">
                                                                                <a
                                                                                    class="dropdown-item d-flex align-items-center text-truncate padding-x-6 my-1">
                                                                                    <div
                                                                                        class="icon-container d-inline-block">
                                                                                        <i *ngIf="risk.key === 'low'"
                                                                                            class="fal fa-square-check nav-success margin-left-1 nav-font14 pr-2"></i>
                                                                                        <i *ngIf="risk.key === 'medium'"
                                                                                            class="fal fa-triangle-exclamation mediumRiskColour nav-font14 pr-2"></i>
                                                                                        <i *ngIf="risk.key === 'high'"
                                                                                            class="fal fa-octagon-xmark nav-error nav-font14 pr-2"></i>
                                                                                    </div>
                                                                                    {{risk.value}}
                                                                                </a>
                                                                            </li>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="check?.metric === 'RID'">
                                                                        <ng-container
                                                                            *ngFor="let risk of qcRuleRIDDictionary | keyvalue: asIsOrder">
                                                                            <li *ngIf="risk.key !== 'non answer' || check?.metric === 'AI'"
                                                                                role="menuitem"
                                                                                class="expandable margin-x-2"
                                                                                [class.cursor-pointer]="oneRecordSelected"
                                                                                (click)="check.value = risk.key; changeQcRuleValue(rule, idxCheck, check); riskRuleDropdown.toggle(true)">
                                                                                <a
                                                                                    class="dropdown-item d-flex align-items-center text-truncate padding-x-6 my-1">
                                                                                    <div
                                                                                        class="icon-container d-inline-block">
                                                                                        <i *ngIf="risk.key === 'low'"
                                                                                            class="fal fa-square-check nav-success margin-left-1 nav-font14 pr-2"></i>
                                                                                        <i *ngIf="risk.key === 'medium'"
                                                                                            class="fal fa-triangle-exclamation mediumRiskColour nav-font14 pr-2"></i>
                                                                                        <i *ngIf="risk.key === 'high'"
                                                                                            class="fal fa-octagon-xmark nav-error nav-font14 pr-2"></i>
                                                                                    </div>
                                                                                    {{risk.value}}
                                                                                </a>
                                                                            </li>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ul>
                                                            </div>
                                                        </ng-container>

                                                        <ng-container *ngIf="check?.metric === 'Flag'">
                                                            <span class="mx-3 margin-top-5">has</span>
                                                            <div class="btn-group dropdown my-1 value" dropdown
                                                                #flagValueRuleDropdown="bs-dropdown" container="body">
                                                                <div class="select-container"
                                                                    [class.cursor-pointer]="oneRecordSelected"
                                                                    [class.open]="flagValueRuleDropdown.isOpen"
                                                                    id="button-basic" dropdownToggle
                                                                    controls="dropdown-basic">
                                                                    <span class="row mx-0 nav-font14 cursor-pointer"
                                                                        [ngClass]="!check.value ? 'grey3' : 'grey5'">
                                                                        <div class="text-truncate font-weight-normal">
                                                                            {{qcRuleFlagValueDictionary[check.value] ??
                                                                            'Select status'}}</div>
                                                                        <div class="d-inline ml-auto">
                                                                            <i class="fas grey5 nav-font14 ml-1 mt-1"
                                                                                [class.fa-chevron-down]="!flagValueRuleDropdown.isOpen"
                                                                                [class.fa-chevron-up]="flagValueRuleDropdown.isOpen"></i>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <ul id="dropdown-assessment" *dropdownMenu
                                                                    class="select-dropdown value-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14"
                                                                    role="menu" aria-labelledby="button-basic">
                                                                    <div class="d-block text-right px-2"></div>
                                                                    <ng-container
                                                                        *ngFor="let option of qcRuleFlagValueDictionary | keyvalue">
                                                                        <li role="menuitem"
                                                                            class="expandable margin-x-2"
                                                                            [class.cursor-pointer]="oneRecordSelected"
                                                                            (click)="check.value = option.key; changeQcRuleValue(rule); flagValueRuleDropdown.toggle(true)">
                                                                            <a
                                                                                class="dropdown-item d-flex align-items-center text-truncate padding-x-6 my-1">
                                                                                {{option.value}}
                                                                            </a>
                                                                        </li>
                                                                    </ng-container>
                                                                </ul>
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="check?.metric === 'DupeRespondent' ||check?.metric === 'DupeAnswer' || check?.metric === 'Blank'  || check?.metric === 'NonAnswer'">
                                                            <span class="mx-2 margin-top-5">Count is</span>
                                                            <div class="btn-group dropdown my-1 risk" dropdown
                                                                #ruleOperatorDropdown="bs-dropdown" container="body">
                                                                <div class="select-container"
                                                                    [class.cursor-pointer]="oneRecordSelected"
                                                                    [class.open]="ruleOperatorDropdown.isOpen"
                                                                    id="button-basic" dropdownToggle
                                                                    controls="dropdown-basic">
                                                                    <span class="row mx-0 nav-font14 cursor-pointer"
                                                                        [ngClass]="!check.operator ? 'grey3' : 'grey5'">
                                                                        <div class="text-truncate font-weight-normal">
                                                                            {{qcRuleOperatorDictionary[check.operator]
                                                                            ?? 'Select'}}</div>

                                                                        <div class="d-inline ml-auto">
                                                                            <i class="fas grey5 nav-font14 ml-1 mt-1 chevron-absolute"
                                                                                [class.fa-chevron-down]="!ruleOperatorDropdown.isOpen"
                                                                                [class.fa-chevron-up]="ruleOperatorDropdown.isOpen"></i>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <ul id="dropdown-assessment" *dropdownMenu
                                                                    class="select-dropdown risk-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14"
                                                                    role="menu" aria-labelledby="button-basic">
                                                                    <div class="d-block text-right px-2"></div>
                                                                    <ng-container
                                                                        *ngFor="let operator of qcRuleOperatorDictionary | keyvalue: asIsOrder">
                                                                        <li role="menuitem"
                                                                            class="expandable margin-x-2"
                                                                            [class.cursor-pointer]="oneRecordSelected"
                                                                            (click)="check.operator = operator.key; changeQcRuleValue(rule, idxCheck, check); ruleOperatorDropdown.toggle(true)">
                                                                            <a
                                                                                class="dropdown-item d-flex align-items-center text-truncate padding-x-6 my-1">
                                                                                {{operator.value}}
                                                                            </a>
                                                                        </li>
                                                                    </ng-container>
                                                                </ul>
                                                            </div>
                                                        </ng-container>

                                                    </div>
                                                    <div class="d-flex">

                                                        <ng-container
                                                            *ngIf="check?.metric === 'DupeAnswer' || check?.metric === 'Blank'  || check?.metric === 'NonAnswer'">
                                                            <input type="number" style="width:60px;" min="0"
                                                                [max]="openEnds.length" [value]="check.value"
                                                                (input)="check.value=$event.target.value + ''; changeQcRuleValue(rule, idxCheck, check)"
                                                                class="mt-1 form-control form-control-nav form-control-xs">
                                                            <span class="mx-3 margin-top-5">across their OE
                                                                questions</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="check?.metric === 'DupeRespondent'">
                                                            <input type="number" min="0" style="width:60px;"
                                                                [value]="check.value"
                                                                (input)="check.value=$event.target.value + ''; changeQcRuleValue(rule, idxCheck, check)"
                                                                class="mt-1 form-control form-control-nav form-control-xs">
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="check.metric && (check.metric !== 'DupeAnswer' && check.metric !== 'Blank'  && check.metric !== 'NonAnswer')">
                                                            <span class="mx-2 margin-top-5">for</span>
                                                            <div class="btn-group dropdown my-1 question" dropdown
                                                                #questionRuleDropdown="bs-dropdown" container="body">
                                                                <div class="select-container w-100"
                                                                    [class.cursor-pointer]="oneRecordSelected"
                                                                    [class.open]="questionRuleDropdown.isOpen"
                                                                    id="button-basic" dropdownToggle
                                                                    controls="dropdown-basic">
                                                                    <span class="row mx-0 nav-font14 cursor-pointer"
                                                                        [ngClass]="!check.questionId ? 'grey3' : 'grey5'">
                                                                        <ng-container *ngIf="check?.metric === 'Flag'">
                                                                            <div
                                                                                class="text-truncate font-weight-normal w-87">
                                                                                {{qcRuleFlagsDictionary[check.questionId]
                                                                                ?? 'Select flag(s)'}}</div>

                                                                        </ng-container>
                                                                        <ng-container *ngIf="check?.metric !== 'Flag'">
                                                                            <div
                                                                                class="text-truncate font-weight-normal w-87">
                                                                                {{qcRuleOEDictionary[check.questionId]
                                                                                ?? 'Select question(s)'}}</div>
                                                                        </ng-container>
                                                                        <div class="d-inline ml-auto">
                                                                            <i class="fas grey5 nav-font14 ml-1 mt-1"
                                                                                [class.fa-chevron-down]="!questionRuleDropdown.isOpen"
                                                                                [class.fa-chevron-up]="questionRuleDropdown.isOpen"></i>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                                <ul id="dropdown-assessment" *dropdownMenu
                                                                    class="select-dropdown question-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14"
                                                                    role="menu" aria-labelledby="button-basic">
                                                                    <div class="d-block text-right px-2"></div>
                                                                    <ng-container *ngIf="check?.metric === 'Flag'">
                                                                        <ng-container
                                                                            *ngFor="let item of check.dropdownFlags">
                                                                            <li role="menuitem"
                                                                                class="expandable margin-x-2"
                                                                                [class.cursor-pointer]="oneRecordSelected"
                                                                                [class.disabled-option]="checkIfRuleAlreadyExists(rule.checks, idxCheck, check.metric, check.value, item.value)"
                                                                                (click)="check.questionId = item.value; changeQcRuleQuestion(rule, check); questionRuleDropdown.toggle(true)">
                                                                                <a
                                                                                    class="dropdown-item d-flex align-items-center text-truncate padding-x-6 my-1">
                                                                                    {{item.text}}
                                                                                </a>
                                                                            </li>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="check?.metric !== 'Flag'">
                                                                        <ng-container
                                                                            *ngFor="let item of check.dropdownQuestions">
                                                                            <li role="menuitem"
                                                                                class="expandable margin-x-2"
                                                                                [class.cursor-pointer]="oneRecordSelected"
                                                                                [class.disabled-option]="checkIfRuleAlreadyExists(rule.checks, idxCheck, check.metric, check.value, item.value)"
                                                                                (click)="check.questionId = item.value; changeQcRuleQuestion(rule, check); questionRuleDropdown.toggle(true)">
                                                                                <a
                                                                                    class="dropdown-item d-flex align-items-center text-truncate padding-x-6 my-1">
                                                                                    {{item.text}}
                                                                                </a>
                                                                            </li>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ul>
                                                            </div>
                                                        </ng-container>

                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col d-flex justify-content-end">
                                            <span class="nav-font14 font-weight-bold "
                                                [ngClass]="!rule?.canAddCondition ? 'grey2' : 'nav-accent-blue cursor-pointer'"
                                                [attr.disabled]="!rule?.canAddCondition ? true : null"
                                                (click)="rule?.canAddCondition ? addQcRuleCheck(rule) : null">
                                                <i class="fak fa-plus fa-lg mr-1"></i> Add condition
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row d-flex w-100 align-items-center">
            <ng-container>
                <div class="col pl-2 d-flex align-items-center text-nowrap grey4 nav-font14">
                    <div class="nav-font16 font-weight-bold grey5">Projected QC Scorecard with Rules</div>
                    <span class="ml-4">Accepted
                        <span class="text-dark font-weight-bolder ml-1 mr-2">{{postRuleTotals.accepts.total}}
                            <ng-container *ngIf="postRuleTotals.accepts.diff != 0">
                                <span class="ml-1 font-weight-normal"
                                    [ngClass]="getDiffClass(postRuleTotals.accepts.diffDisplay)">({{postRuleTotals.accepts.diffDisplay}})</span>
                            </ng-container>
                        </span>
                    </span>
                    <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default"></span>
                    <span class="ml-2"> Pending
                        <span class="text-dark font-weight-bolder ml-1 mr-2">{{postRuleTotals.pending.total}}
                            <ng-container *ngIf="postRuleTotals.pending.diff != 0">
                                <span class="ml-1 font-weight-normal"
                                    [ngClass]="getDiffClass(postRuleTotals.pending.diffDisplay)">({{postRuleTotals.pending.diffDisplay}})</span>
                            </ng-container>
                        </span>
                    </span>
                    <span class="far grey1 fa-thin fa-lg fa-pipe cursor-default"></span>
                    <span class="ml-2"> Rejected
                        <span class="text-dark font-weight-bolder ml-1 mr-2">{{postRuleTotals.rejects.total}}
                            <ng-container *ngIf="postRuleTotals.rejects.diff != 0">
                                <span class="ml-1 font-weight-normal"
                                    [ngClass]="getDiffClass(postRuleTotals.rejects.diffDisplay)">({{postRuleTotals.rejects.diffDisplay}})</span>
                            </ng-container>
                        </span>
                    </span>
                </div>
            </ng-container>
            <div class="text-right px-0 d-flex justify-content-end"
                [ngClass]="{'col-3': qcFlowRules?.length > 0, 'col': qcFlowRules?.length === 0}">
                <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold"
                    (click)="cancel()">Cancel</button>
                <button class="btn btn-sm shadow-none pb-1"
                    [ngClass]="!canSaveRules ? 'nav-btn-disabled' : 'nav-btn-accent'"
                    [attr.disabled]="!canSaveRules ? true : null" (click)="saveQCFlowRules()">Save</button>
            </div>
        </div>
    </div>
</ng-template>

<!-- dynamic modal confirm -->
<ng-template #modalConfirm>
    <div class="modal-header nav-error pt-2 pr-2" *ngIf="modalConfirmData != null">
        <div class="col pt-2 px-0">
            <div class="row d-flex align-items-center mx-0">
                <i class="{{modalConfirmData.icon}} mx-2"></i>
                <span class="font-weight-bold nav-font16">{{modalConfirmData?.title}}</span>
            </div>
        </div>
        <div class="col-2 text-right px-0">
            <i class="far fa-close grey4 nav-font18 p-1 pr-2" (click)="cancelDialog()"></i>
        </div>
    </div>
    <div class="modal-body pt-0">
        <h5 class="grey5 d-block mb-3 mx-2">
            {{modalConfirmData?.message}}
        </h5>
    </div>
    <div class="modal-footer">
        <div class="row d-flex w-100 align-items-center">
            <div class="col text-right px-0">
                <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold"
                    (click)="modalConfirmData?.cancelCallback()">{{modalConfirmData?.cancelBtnText ??
                    'Cancel'}}</button>
                <button class="btn btn-sm nav-btn-accent shadow-none"
                    (click)="handleModalConfirm(modalConfirmData.mode)">{{modalConfirmData?.confirmBtnText ??
                    'Confirm'}}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #modalFileError>
    <div class="modal-header nav-error pt-2 pr-2">
        <div class="col pt-2 px-0">
            <div class="row d-flex align-items-center mx-0">
                <i class="fal fa-circle-xmark nav-font20 mx-2"></i>
                <span class="font-weight-bold nav-font16">Import Error</span>
            </div>
        </div>
        <div class="col-2 text-right px-0">
            <i class="far fa-close grey4 nav-font18 p-1 pr-2" (click)="retryFileImport()"></i>
        </div>
    </div>
    <div class="modal-body pt-0">
        <h5 class="grey5 d-block mb-0 mx-2">
            Incomplete or mismatched file structure.
            The uploaded file must have the same structure as the previous file.
            The following variables are missing or don't match:

            <ul class="mt-3 pl-4">
                <ng-container *ngFor="let col of wrongColumns">
                    <li>
                        {{col}}
                    </li>
                </ng-container>
            </ul>

            Please ensure all required fields are present and match the previous file structure before importing again.
        </h5>
    </div>
    <div class="modal-footer">
        <div class="row d-flex w-100 align-items-center">
            <div class="col text-right px-0">
                <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold"
                    (click)="openModalRef.hide(); cancelQCFlow();">Cancel</button>
                <button class="btn btn-sm nav-btn-accent shadow-none pb-1" (click)="retryFileImport()">Retry
                    Import</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #modalProcessError>
    <div class="modal-header nav-error pt-2 pr-2">
        <div class="col pt-2 px-0">
            <div class="row d-flex align-items-center mx-0">
                <i class="fal fa-circle-xmark nav-font20 mx-2"></i>
                <span class="font-weight-bold nav-font16">File Processing Error</span>
            </div>
        </div>
        <div class="col-2 text-right px-0">
            <i class="far fa-close grey4 nav-font18 p-1 pr-2" (click)="cancelDialog()"></i>
        </div>
    </div>
    <div class="modal-body pt-0">
        <h5 class="grey5 d-block mb-0 mx-2">
            <p>An error occurred while processing the file.</p>

            <p>Please try importing it again. If the error continues, reach out to our support team by clicking on the
                blue question mark icon located in the bottom left corner, then select 'Contact Support'.</p>

        </h5>
    </div>
    <div class="modal-footer">
        <div class="row d-flex w-100 align-items-center">
            <div class="col text-right px-0">
                <button class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold"
                    (click)="cancelLastFile();">Cancel</button>
                <button class="btn btn-sm nav-btn-accent shadow-none pb-1" (click)="retryQcFlow()">Retry Import</button>
            </div>
        </div>
    </div>
</ng-template>