@if (ready) {
  <div class="audience m-3">
    <div class="audience-header">
      <div class="form-group">
        <label>Name</label>
        <input [(ngModel)]="audience.name" type="text" [ngClass]="getInputStyle(audience.name)" class="h4" placeholder="Project Name">
      </div>
      <div class="form-group">
        <label>Quota</label>
        <input [(ngModel)]="audience.quota" type="number" [ngClass]="getInputStyle(audience.quota)" class="medium-input" placeholder="Overall Quota">
      </div>
      <div class="form-group">
        <label>IR</label>
        <input [(ngModel)]="audience.ir" type="number" [ngClass]="getInputStyle(audience.ir)" class="medium-input" placeholder="Incidence">
      </div>
      <div class="form-group">
        <label>LOI</label>
        <input [(ngModel)]="audience.loi" type="number" [ngClass]="getInputStyle(audience.loi)" class="medium-input" placeholder="LOI">
      </div>
      <div class="form-group">
        <label>In Field</label>
        <input [(ngModel)]="audience.inField" type="number" [ngClass]="getInputStyle(audience.inField)" class="medium-input" placeholder="In Field">
      </div>
      <div class="form-group">
        <label>Country</label>
        <select [(ngModel)]="audience.country"  [ngClass]="getInputStyle(audience.country)" (change)="updateRegions()">
          @for (option of countries; track option) {
            <option>{{option.name}}</option>
          }
        </select>
      </div>
      <div>
        <div class="btn btn-success btn-sm" (click)="save()">SAVE</div>
      </div>
    </div>
    <div class="audience-groups">
      @for (group of audience.group; track group; let groupIdx = $index) {
        <div class="mb-2">
          <div class="d-flex">
            <div>
              <i class="far fa-times-circle mr-2" (click)="removeGroup(groupIdx)"></i>
            </div>
            <div>
              <input [ngClass]="getInputStyle(group.name)" [(ngModel)]="group.name" class="h6 text-info" type="text" placeholder="Group Name">
            </div>
            <div>
              <input [ngClass]="getInputStyle(group.quota)" class="primary text-info" [(ngModel)]="group.quota" type="text" placeholder="Quota">
            </div>
          </div>
          @if (group.target.length > 0) {
            <div class="audience-targets border mb-2 p-2">
              @for (target of group.target; track target; let targetIdx = $index) {
                @if (target.interlock != true || target.lockedItems != null) {
                  <div class="ml-2 mr-2" style="width:25px">
                    @if (targetIdx != 0) {
                      <i class="fas fa-lock-open" (click)="interlock(group, target, targetIdx)"></i>
                    }
                    <div style="width:2px;height:90%;" class="bg-info"></div>
                  </div>
                }
                @if (target.lockedItems != null) {
                  <div  [class]="'audience-target-interlock-' + target.lockedItems.length">
                    <div>
                      <table class="fixed-width">
                        <thead>
                          <th width="1px"></th>
                          <th width="160px">{{ target.category.name }}</th>
                          @for (lockedtarget of target.lockedItems; track lockedtarget; let lockIdx = $index) {
                            <th width="20px"><i class="fas fa-lock mr-2 ml-2"  (click)="removeInterlock(group, target, lockIdx, targetIdx+lockIdx+1)"></i></th>
                            <th width="160px" width="160px">{{ lockedtarget.category.name }}</th>
                          }
                          <th width="30px">N</th>
                          <th width="30px">%</th>
                        </thead>
                        <tbody>
                          @for (row of target.interlockData; track row) {
                            <tr>
                              @for (item of row; track item) {
                                <td></td>
                                <td>{{ item.value }} {{ item.value2 }}</td>
                              }
                              <td>
                                <input [ngClass]="getInputStyle(row.n)" type="number" [(ngModel)]="row.n" class="small-input" (change)="handleQuotaChange(group, target, row, 'n')">
                              </td>
                              <td>
                                <input [ngClass]="getInputStyle(row.percent)" type="number" [(ngModel)]="row.percent"  class="small-input" (change)="handleQuotaChange(group, target, row, '%')">
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
                @if (target.interlock != true) {
                  <div  class="audience-target">
                    <div class="h6">
                      {{ target.category.name }}
                      @if (target.category.description) {
                        <i class="fak nav-font16 fa-message-info grey3 m-2" [title]="target.category.description"></i>
                      }
                    </div>
                    <div class="toolbar">
                      <i class="far fa-times-circle mr-2" (click)="removeTarget(group, targetIdx)"></i>
                      <i class="m-2 fas fa-percent" title="Distribute Evenly" (click)="distribute(group, target, 'equal')"></i>
                      <i class="m-2 fas fa-users" title="Distribute Using Census Data" (click)="distribute(group, target, 'census')"></i>
                      <i class="m-2 far fa-plus-square" title="Add All Options" (click)="addAll(group, target)"></i>
                      <i class="m-2 fas fa-screwdriver" title="Auto Fix numbers" (click)="autoFix(group, target)"></i>
                    </div>
                    <table class="fixed-width">
                      <thead>
                        <th width="5px"></th>
                        <th width="160px">Value</th>
                        <th width="30px">N</th>
                        <th width="30px">%</th>
                      </thead>
                      <tbody>
                        @for (quota of target.quotas; track quota; let quotaIdx = $index) {
                          <tr>
                            <td>
                              @if (! (quota.value == null)) {
                                <i class="far fa-times-circle mr-2 delete-btn" (click)="removeQuota(target, quotaIdx)"></i>
                              }
                            </td>
                            <td>
                              @if (datapoints[target.category.name] == null) {
                                <div>
                                  @if (target.category.type == 'Age') {
                                    <div class="row">
                                      <div>
                                        <input [ngClass]="getInputStyle(quota.value)" type="text"  class="small-input center" [(ngModel)]="quota.value" (change)="handleQuotaValueChange(target, quotaIdx)">
                                      </div>
                                      <div>
                                        <i class="fas fa-arrow-right"></i>
                                      </div>
                                      <div>
                                        <input [ngClass]="getInputStyle(quota.value2)" type="text"  class="small-input center" [(ngModel)]="quota.value2" (change)="handleQuotaValueChange(target, quotaIdx)">
                                      </div>
                                    </div>
                                  }
                                  @if (target.category.type == null) {
                                    <div>
                                      <input [ngClass]="getInputStyle(quota.value)" type="text" [(ngModel)]="quota.value" (change)="handleQuotaValueChange(target, quotaIdx)">
                                    </div>
                                  }
                                </div>
                              }
                              @if (datapoints[target.category.name] != null) {
                                <div>
                                  <select [(ngModel)]="quota.value" [ngClass]="getInputStyle(quota.value)"  (change)="handleQuotaValueChange(target, quotaIdx)">
                                    @for (option of datapoints[target.category.name]; track option) {
                                      <option>{{option.name}}</option>
                                    }
                                  </select>
                                </div>
                              }
                            </td>
                            <td>
                              <input [ngClass]="getInputStyle(quota.n)" type="number" [(ngModel)]="quota.n" class="small-input" (change)="handleQuotaChange(group, target, quota, 'n')">
                            </td>
                            <td>
                              <input [ngClass]="getInputStyle(quota.percent)" type="number" [(ngModel)]="quota.percent"  class="small-input" (change)="handleQuotaChange(group, target, quota, '%')">
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                    @if (target.quotas.length == 1) {
                      <div>
                        <div class="btn btn-outline-success btn-sm  mr-3" (click)="loadCensus(target, group)">Load Census Info</div>
                      </div>
                    }
                    @if (target.errors && target.errors.length >0) {
                      <div class="alert alert-warning" role="alert">
                        {{ target.errors[0] }}
                      </div>
                    }
                  </div>
                }
              }
              <div class="ml-2 mr-2" style="width:5px">
                <div style="width:2px;height:100%;" class="bg-info"></div>
              </div>
              <div class="existing-audience-target">
                <ng-template *ngTemplateOutlet="tplCategoryPicker; context: {$implicit: group}"></ng-template>
              </div>
            </div>
          }
          @if (group.target.length == 0) {
            <div  class="new-audience-target">
              <ng-template *ngTemplateOutlet="tplCategoryPicker; context: {$implicit: group}"></ng-template>
            </div>
          }
        </div>
      }
      <div class="btn btn-outline-success btn-sm  mr-3" (click)="addGroup()">Add Group</div>
    </div>
  </div>
}


<ng-template #tplCategoryPicker let-group>
  <div class="category-picker">
    <div class="d-flex">
      <div><input type="text" [(ngModel)]="group.filter" placeholder="filter..."></div>
      <div class="ml-5"> Frequent Items:</div>
      @for (category of frequentCategories; track category) {
        <a (click)="addTarget(group, category)" class="badge badge-pill badge-success m-1 p-1 text-white">{{ category.name }}</a>
      }
    </div>
    <div class="category-picker-body">
      @if (audience.country != '') {
        <div>
          <div class="h6">Location</div>
          <div>
            @for (region of regions; track region) {
              @if (group.filter == '' || region.name.toLowerCase().indexOf(group.filter.toLowerCase()) >=0 ) {
                <a (click)="addTarget(group, region)" class="badge  badge-primary m-1 p-1 text-white">{{ region.name }}</a>
              }
            }
          </div>
        </div>
      }
      @if (audience.country != '') {
        <div>
          <div class="h6">Categories</div>
          <div>
            @for (category of categories; track category) {
              @if (group.filter == '' || category.name.toLowerCase().indexOf(group.filter.toLowerCase()) >=0 ) {
                <a (click)="addTarget(group, category)" class="badge badge-secondary m-1 p-1 text-white">{{ category.name }}</a>
              }
            }
          </div>
        </div>
      }
      @if (audience.group.length > 1 && group.target.length == 0) {
        <div >
          <div class="h6">Clone from Project</div>
          @if (audience.group.length >= 2) {
            <div>
              @for (othergroup of audience.group; track othergroup; let i = $index) {
                @if (i != audience.group.length -1) {
                  <div class="m-2 p-2" (click)="cloneGroup(group, othergroup)">
                    <div class="badge-light m-1 p-3 h6">{{ othergroup.name == '' ? 'Group'+ (i+1) : othergroup.name }}</div>
                  </div>
                }
              }
            </div>
          }
        </div>
      }
      @if (group.target.length == 0) {
        <div >
          <div class="h6">Load from Template</div>
          <div>
            @for (template of templates; track template) {
              <div class="" (click)="loadTemplate(group)">
                <div class="badge-light m-1 p-1">
                  <div class="h6">{{ template.name }}</div>
                  <div>{{ template.description }}</div>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>
