import { Component, OnInit, Input, ViewChild, TemplateRef, HostListener, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { AlertifyService, ProjectService, AuthService, QuotaService, ProjectQuota, QuotaGroup, Quota, DropdownQuota, UserService, QuotaVendorLimit, DropdownService, UtilsService, ProjectDataService } from 'core';
import { v4 as uuidv4 } from 'uuid';
import {  forkJoin } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DndDropEvent } from 'ngx-drag-drop';
import { callbackify } from 'util';

@Component({
  selector: 'app-project-quota-setup',
  templateUrl: './project-quota-setup.component.html',
  styleUrls: ['./project-quota-setup.component.scss']
})
export class ProjectQuotaSetupComponent implements AfterViewChecked, OnInit {

  @ViewChild('modal') 
  modalRefConfirm: BsModalRef;
  openModalRef: any;
  modalType = ""; // templateClone, templateImport, confirm
  modalData: {title: string, message: string};
  latestReasonModalClosed: string = null;

  @ViewChild('quotaConfig')
  quotaConfig: TemplateRef<any>;
  openModalRefQuotaConfig: any;

  @ViewChild('dropdownDemographic', {static: false}) dropdownDemographic;
  listenerFn: () => void;
  propagateChange: (_: any) => {};

  @Input() project;
  @Input() projectPerformance;

  isVendorview: boolean;
  instanceId: string;
  metrics = {testStarts: 0, testCompletes: 0, liveStarts: 0, liveCompletes : 0}

  //quota settings
  quotaConfigEditDetails: any;
  newQuotaGroups = [];
  refreshParticipants = false;

  // quota questions
  questions;
  questionIDs = [];
  addedQuestions = [];
  nonNestedGroups = [];

  // hhi and age options
  hhiMin = 15000;
  hhiMax = 500000;
  hhiList = this.populateHHIList();
  ageMin = 12;
  ageMax = 99;
  ageList = this.populateAgeList();

  // quota card
  projectQuota: ProjectQuota = null;
  quotaGroupTotals = {};
  deletedQGOptions = []
  newQuota = null;

  // vendor card
  vendors;
  vendorCardGridTemplate = null;
  vendorCardGridMinWidth = '200px';
  vendorCardOverflowX = false;
  vendorCardOverflowY = false;

  // cloning quota from other project
  quotaTemplateId: string;
  quotaTemplateSearch: string;
  quotaTemplates = [];
  actions = [];
  projectList = [];
  projectIds = [];

  // improting quota from file
  importFileName;
  importFileAllocationType = 'percent'
  loadingImport = false;
  s3buckPath =  '';
  
  quotaCantBeDeleted = false;
  quotaCantBeChanged = false;

  constructor(
    private alertify: AlertifyService,
    private dropdownService: DropdownService,
    public auth: AuthService,
    private quotaService: QuotaService,
    private modalService: BsModalService,
    private utils: UtilsService,
    private projectDataService: ProjectDataService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.s3buckPath = "quota/" + this.project.id;
    window.scroll(0, 0);
    this.getQuota();

    this.projectDataService.getVendorViewSidebar.subscribe(data => {
      if (data) {
        this.isVendorview = data != '';
      }
    });
  }

  ngAfterViewChecked() {
    this.checkVendorCardOverflow();
  }

  leaveSetupMode(){
    this.getQuota();
    this.newQuotaGroups = [];
  }

  getQuota() {
    this.refreshParticipants = false;
    forkJoin([this.quotaService.GetQuota(this.project.id), this.quotaService.ListQuestions()]).subscribe((data) => {
      
      this.vendors = this.project.projectSegments[0].projectSurveyPartners
      .filter(x => x.isDeleted == false)
      .sort((a, b) => a.partnerName.localeCompare(b.partnerName));

      this.processData(this.projectPerformance);

      this.questions = {};
      this.questionIDs = [null];

      data[1].forEach(q => {
        if (q.type !== 'Division') {
          this.questions[q.id] = q;
          this.questionIDs.push(q.id);
        }
      })

      if (data[0] != null) {
        this.processQuota(data[0]);
      }

      this.updateAddedQuotaGroupList();
      if (this.projectQuota.limitMode == null) this.projectQuota.limitMode = 'start'

    },
      error => {
        this.alertify.error('There was an error getting quota details');
      },);
  }

  processQuota(projectQuota: ProjectQuota) {

    this.projectQuota = projectQuota;
    if (this.projectQuota?.quotaGroups.length > 0) {
      this.sort(this.projectQuota.quotaGroups, 'order')

      this.projectQuota.quotaGroups.forEach(qg => {
        var qIds = qg.questionId.split(',');
        qg.nameArray = [];
        qIds.forEach(qId => {
          qg.nameArray.push(this.questions[qId].type);
        });
  
        if (qg.name == 'Income') { // filter out deleted Income quotas except for pnta
          qg.quotas = qg.quotas.filter(x => !x.isDeleted || x.targetGroup.minHHI == -1);
        }
  
        qg.quotas.filter(x=> !x.isDeleted).forEach(q => {
          q.groupId = qg.id;

          // handle the nested pnta quotas
          if (qg.nameArray.length > 1) {
            var pntaCount = 0;
            var index = 0;
            qg.nameArray.forEach(nestedGroup => {
              var value = null;
              if (nestedGroup === 'HouseholdIncome') {
                value = q.targetGroup.minHHI == -1 ? 'pnta' : q.targetGroup.minHHI;
              }
              else if (nestedGroup === 'Age') {
                value = q.targetGroup.minAge == -1 ? 'pnta' : q.targetGroup.minAge;
              }
              else {
                value = q.targetGroup[nestedGroup.toLocaleLowerCase()];
              }
              if (value === 'pnta') pntaCount++;
              index++;
            });
            if (pntaCount >= 1 && pntaCount < qg.nameArray.length ) {
              q.hideQuota  = true;
            }
            else if (pntaCount === qg.nameArray.length) {
              q.isPNTA = true;
            }
          }
  
          // set the order of options to be the same as setup in admin console
          var quotaValue = q.targetGroup[qg.name.toLocaleLowerCase()];
          if (qg.name == 'Gender' || qg.name == 'Ethnicity' || qg.name == 'Hispanic') {
            q.order = this.questions[qg.questionId].options.find(x => x.value === quotaValue)?.order;
          }
          else if (qg.name === 'Region') {
            q.order = this.questions[qg.questionId].defaults.find(x => x.value === quotaValue)?.order;
          }
  
          q.limitN = Math.ceil(this.project.fullLaunchQuota * q.limit / 100);
          q.limitPercent = q.limit;
          this.addVendorsToQuota(q);
          this.calculateGroupTotals(q.groupId);
        });
  
        if (qg.questionId.length <= 36) {
          this.handleMissingQuotas(qg);
        }
        else {
          qg.questionIdArray = qg.questionId.split(',')
        }
  
        this.sortGroup(qg);
      });
    }
    this.checkForZeroQuotas();
    this.getVendorCardGridTemplate();

  }

  processData(projectPerformance) {
    var data = projectPerformance.filter(x => x.vendorId === '00000000-0000-0000-0000-000000000000');
    if (data.length > 0) {
      let metrics = {testStarts: 0, testCompletes: 0, liveStarts: 0, liveCompletes : 0};
      data.forEach(element => {
        if (element.vendorSurveyState === 'Test') {
          metrics.testCompletes += element.complete
          metrics.testStarts += element.starts
        }
        else if (element.vendorSurveyState === 'Full Launch' || element.vendorSurveyState === 'Soft Launch') {
          metrics.liveCompletes += element.complete
          metrics.liveStarts += element.starts
        }
      });
      this.metrics = metrics;
    }

    if (this.project.id == "d583bf3a-ab7e-441f-b1a1-07c96bb3e2e6") {
      //Dummy data for demo
      this.metrics.liveStarts = 23513;
      this.metrics.liveCompletes = 4000;
    }

    this.quotaCantBeDeleted = this.metrics.liveStarts > 0;
    this.quotaCantBeChanged = this.metrics.liveStarts > 0 && this.project.projectStatus !== 'Paused'
  }

  getVendorCardGridTemplate() {
    var numVendors = this.vendors.filter(x => !x.isDeleted).length;
    if (numVendors > 0) {
      this.vendorCardGridTemplate = `265px repeat(auto-fill, minmax(249px, calc((100% - 265px)/${numVendors})))`
      this.vendorCardGridMinWidth = (265 + numVendors * 252 ) + 'px';
    }
    else {
      this.vendorCardGridTemplate = `265px`
    }
  }

  calculateGroupTotals(groupId) {
    var group = this.projectQuota.quotaGroups.find(x => x.id === groupId);
    const totalN = group.quotas.filter(x => x.isDeleted == false).reduce((a, b) => a + (Number(b.limitN) || 0), 0);
    const totalPercent = group.quotas.filter(x => x.isDeleted == false).reduce((a, b) => a + (Number(b.limitPercent) || 0), 0);

    this.quotaGroupTotals[group.id] = { n: totalN, percent: totalPercent};
  }

  sortGroup(group) {
    if (group.name === 'Income') this.sort(group.quotas, 'minHHI');
    else if (group.name === 'Age') this.sort(group.quotas, 'minAge');
    else this.sort(group.quotas, 'order');
  }

  addBackQuota(group, quota) {
    var quotaToSave = null;
    var array = 'options';
    if (group.name === 'Region') array = 'defaults';

    if (quota.id == null) {
      group.deletedOptions.forEach((element,index)=>{
        if(element.label==quota.name) group.deletedOptions.splice(index,1);
     });
      const question = this.questions[group.questionId];
      const newQuota: Quota = this.blankQuota()
      
      if (group.name != 'Income') {
        newQuota.targetGroup[group.name.toLowerCase()] = quota.name;
      }     
      else if (quota.name === 'pnta') newQuota.targetGroup.minHHI = -1;

      newQuota.order = question[array].find(x => x.value === quota.name).order || 999;

      const defaultItem = question.defaults.find(e => e.value === quota.name);
      if (defaultItem != null) {
        newQuota.limitPercent = defaultItem.percent;
        newQuota.limitN = Math.ceil((this.project.fullLaunchQuota * (defaultItem.percent / 100)));
        newQuota.limit = newQuota.limitPercent;
      }
      newQuota.groupId = group.id;
      this.addVendorsToQuota(newQuota);
      group.quotas.push(newQuota);
      quotaToSave = newQuota;
    }
    else {
      quotaToSave = group.quotas.find(e => e.id === quota.id);
      quotaToSave.groupId = group.id;
      quotaToSave.isDeleted = false;

      // if limitPercent isnt set but limitN is, set limitPercent
      if (!quotaToSave.limitPercent && quotaToSave.limitN != null) {
        quotaToSave.limitPercent = Math.ceil(quotaToSave.limitN * 100 / this.project.fullLaunchQuota);
      }
      else if (!quotaToSave.limitN && quotaToSave.limitPercent != null) {
        quotaToSave.limitN = Math.ceil(this.project.fullLaunchQuota * (quotaToSave.limitPercent / 100));
      }
      else if (!quotaToSave.limitN && !quotaToSave.limitPercent ) {
        quotaToSave.limitN = 0;
        quotaToSave.limitPercent = 0;
      }

      group.deletedOptions.forEach((element,index)=>{
        if(element.id==quotaToSave.id) group.deletedOptions.splice(index,1);
      });
      if (group.name != 'Income') {
        quotaToSave.order = this.questions[group.questionId][array].find(x => x.value === quotaToSave.targetGroup[group.name.toLowerCase()]).order     
      }
    }
    this.sort(group.quotas, 'order');
    this.refreshParticipants = true;
    this.saveQuota(quotaToSave);
  }

  addBackPNTA(group) {
    var quota = null
    if (group.name === 'Income') quota = group.quotas.find(e => e.targetGroup.minHHI === -1);
    else quota = group.quotas.find(e => e.targetGroup[group.name.toLowerCase()] === 'pnta');
     
    if (!quota) {
      quota = {type: group.name.toLowerCase(), name: 'pnta', id: null};
    }

    quota.groupId = group.id;
    quota.order = 9999999;
    this.addBackQuota(group, quota);
  }

  addBlankQuota(group, type) {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    const quota = this.blankQuota();
    if (type === 'HouseholdIncome' || type === 'Age') {
      this.newQuota = quota;
    }
    quota.groupId = group.id;

    this.addVendorsToQuota(quota);
    group.quotas.push(quota);
    this.checkForZeroQuotas(group);
  }

  quotaLimitChange(quota, limitType, event) {
    var val = Number(event.target.value);

    if (!isNaN(val)) {
      if (limitType === '%') {
        var limitPercent = val
        var limitN = Math.ceil(this.project.fullLaunchQuota * (limitPercent / 100));
      }
      else {
        var limitN = val;
        var limitPercent = Math.ceil(limitN * 100 / this.project.fullLaunchQuota);
      }
    }
    if (limitPercent !== quota.limit) { 
      if (limitPercent == 0) {
        var group = this.projectQuota.quotaGroups.find(e => e.id === quota.groupId);
        group.hasZeroQuota = true;
      }
      else if (quota.limit == 0) {
        this.checkForZeroQuotas(group);
      }
      if (this.project.projectStatus !== 'Paused' && this.metrics.liveStarts > 0) {
        this.alertify.error("Project must be in Paused status in order to make changes")
        if (limitType === 'n') {
          quota.limitN =  this.project.fullLaunchQuota > 0 ? Math.ceil(quota.limitPercent * this.project.fullLaunchQuota / 100) : 0;
        }
        else {
          quota.limitPercent = quota.limit
        }
        return 0;
      }
      
  
      if (this.project.fullLaunchQuota === 0) {
        this.alertify.error("Cannot update quota limit as overall project completes allocation is 0")
        return 0; 
      }
    
      if (this.newQuota?.id !== quota.id) {
        quota.limit = limitPercent;
        quota.limitPercent = limitPercent;
        quota.limitN = limitN;
        this.saveQuota(quota);
      }
      else {
        this.removeNewQuotaWithBlanksIfExists();
      }
    }
  }

  checkForZeroQuotas(group: QuotaGroup = null) {
    if (group) {
      if (group.quotas.filter(x=> !x.isDeleted && !x.hideQuota).some(x => x.limit == 0)) {
        group.hasZeroQuota = true;
      }
      else {
        group.hasZeroQuota = false;
      }
    }
    else {
      this.projectQuota.quotaGroups.forEach(group => {
        if (group.quotas.filter(x=> !x.isDeleted && !x.hideQuota).some(x => x.limit == 0)) {
          group.hasZeroQuota = true;
        }
        else {
          group.hasZeroQuota = false;
        }
    });

    this.vendors.forEach(vendor => {
      var foundAVendorZero = false;
      this.projectQuota.quotaGroups.forEach(group => {
        group.quotas.filter(x => !x.isDeleted && !x.hideQuota).forEach(q => {
          if (q.vendorLimits.some(x => x.vendorId == vendor.partnerId && x.limit == 0)) {
            foundAVendorZero = true;
          }
        });
      });
      vendor.hasZeroQuota = foundAVendorZero;
    });
  }
}


  minMaxChanged(quota, type, groupType, newQuota, newVal) {
    if (this.project.projectStatus !== 'Paused' && this.metrics.liveStarts > 0) {
      this.alertify.error("Project must be in Paused status in order to make changes")
      return null;
    }

    if (groupType === 'age') {
      if (type === 'min') {
        quota.targetGroup.minAge = newVal
        if (quota.targetGroup.minAge > -1 && !quota.targetGroup.maxAge) return;
      }
      else if (type === 'max') {
        quota.targetGroup.maxAge = newVal
        if (!quota.targetGroup.minAge) return;
      }
    }

    if (groupType === 'income') {
      if (type === 'min') {
        quota.targetGroup.minHHI = newVal
        if (!quota.targetGroup.maxHHI) return;
      }
      else if (type === 'max') {
        quota.targetGroup.maxHHI = newVal
        if (!quota.targetGroup.minHHI) return;
      }
    }

    this.refreshParticipants = true;
    if (!newQuota) {
      this.saveQuota(quota)
    }
  }

  vendorLimitChange(quota, vendorQuota, limitType, event) {

    var val = Number(event.target.value);
    var vendor = this.vendors.find(x => x.partnerId === vendorQuota.vendorId);

    if (!isNaN(val)) {
      if (limitType === '%') {
        var limitPercent = val
        var limitN = Math.ceil(vendor.partnerFullQuota * (limitPercent / 100));
      }
      else {
        var limitN = val;
        var limitPercent = Math.ceil(limitN * 100 / vendor.partnerFullQuota);
      }
    }

    if (limitPercent !== vendorQuota.limit) { 
      if (limitPercent == 0) {
        vendor.hasZeroQuota = true;
      }

      if (this.project.projectStatus !== 'Paused' && this.metrics.liveStarts > 0) {
        this.alertify.error("Project must be in Paused status in order to make changes")
        if (limitType === 'n') {
          vendorQuota.limitN = vendor.partnerFullQuota > 0 ? Math.ceil(vendor.partnerFullQuota * (vendorQuota.limitPercent / 100)) : 0
        }
        else {
          vendorQuota.limitPercent = vendorQuota.limit;
        }
        return 0;
      }
  
      if (vendor.fullLaunchQuota === 0) {
        this.alertify.error("Cannot update vendor limit as vendor completes allocation is 0")
        return 0; 
      }

      vendorQuota.limit = limitPercent;
      vendorQuota.limitPercent = limitPercent;
      vendorQuota.limitN = limitN;
      this.quotaService.SaveSingleVendorQuota(this.project.id, quota.id, vendorQuota).subscribe(data => {
        if (vendorQuota.limit != 0) {
          this.checkForZeroQuotas();
        }
        this.alertify.success('Quota updated');
        this.projectQuota.distributeBy = 'custom';
      }, error => {
        this.alertify.error('Unable to save quota');
      });
    }
  }

  handleMissingQuotas(group) {

    if (group.isDeleted == false && group.questionId.length <= 36) {
      group.deletedOptions = [];
      var preferNotToAnswerEnabled = this.questions[group.questionId]?.preferNotToAnswer; // enabled in admin

      // add deleted quotas to deletedOptions
      group.quotas.forEach(q => {
        if (q.isDeleted) {
          var deletedQ: DropdownQuota = {type: "", name: "", id: ""};
          if (group.name != 'Income') {
            deletedQ.name = q.targetGroup[group.name.toLowerCase()];
          }
          else if (q.targetGroup.minHHI == -1) {
            deletedQ.name = 'pnta';
          }
          if (deletedQ.name !== 'pnta' || preferNotToAnswerEnabled) {
            deletedQ.type = group.name.toLowerCase()
            deletedQ.id = q.id
            group.deletedOptions.push(deletedQ)
          } 
        }
      });

      // add in quota options that exist in the question (admin) but not in the quotas
      if (group.name !== 'Age') {
        var array = 'options';
        if (group.name === 'Region') array = 'defaults';
        this.questions[group.questionId][array].forEach(option => {
          var optionExistsInQuotas = null;
          if (group.name !== 'Income' || option.value === 'pnta') {

            if (group.name === 'Income') optionExistsInQuotas = group.quotas.find(q => q.targetGroup['minHHI'] == -1);
            else optionExistsInQuotas = group.quotas.find(q => q.targetGroup[group.name.toLowerCase()] === option.value);

            if (!optionExistsInQuotas) {
              var deletedQ: DropdownQuota = {type: group.name.toLowerCase(), name: option.value, id: null};
              deletedQ.type = group.name.toLowerCase()
              deletedQ.name = option.value
              group.deletedOptions.push(deletedQ)
            }
          }
        });
      }
    }
  }

  createQuotaGroup(questionId) {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    const question = this.questions[questionId];
    var array = ['HouseholdIncome', 'Age', 'Region'].includes(question.type) ? 'defaults' : 'options'
    var groupName = question.type == 'HouseholdIncome' ? 'Income' : question.type;

    if (!question) {
      this.alertify.error('This Category has no questions configured');
      return;
    }
    var quotaGroup: QuotaGroup = this.blankQuotaGroup(groupName, question.id);

    if (question.type === 'HouseholdIncome' || question.type === 'Age') {
      this.sort(question[array], 'min')

      if (question.type === 'HouseholdIncome' && question.preferNotToAnswer) {
        if (question.options.find(x => x.value === 'pnta')) {
          question.defaults.push({value: 'pnta', percent: 0});
        }
      }
    }
    else this.sort(question[array], 'order')

    question[array].forEach(option => {
      const quota: Quota = this.blankQuota();
      quota.order = option.order;

      if (question.type === 'HouseholdIncome') {
        if (option.value === 'pnta') {
          quota.targetGroup.minHHI = -1;
        }
        else {
          quota.targetGroup.minHHI = option.min;
          quota.targetGroup.maxHHI = option.max;
        }
      } else if (question.type === 'Age') {
        quota.targetGroup.minAge = option.min;
        quota.targetGroup.maxAge = option.max;
      }
      else quota.targetGroup[question.type.toLowerCase()] = option.value;

      const defaultItem = array === 'defaults' ? option : question.defaults.find(e => e.value === option.value);

      // distribute limit values
      if (this.quotaConfigEditDetails.distributeBy === 'census' && defaultItem != null) {
        quota.limitPercent = this.project.fullLaunchQuota == 0 ? 0 : defaultItem.percent;
        quota.limitN = this.project.fullLaunchQuota == 0 ? 0 : Math.ceil((this.project.fullLaunchQuota * (defaultItem.percent / 100)));
        quota.limit = quota.limitPercent
      }
      else if (this.quotaConfigEditDetails.distributeBy === 'equal') {
        quota.limitPercent = Math.ceil(100 / question[array].length);
        quota.limitN = Math.ceil((this.project.fullLaunchQuota * (quota.limitPercent / 100)));
        quota.limit = quota.limitPercent;
      }
      else { // if 'custom'
        quota.limitPercent = 0;
        quota.limitN = 0;
        quota.limit = 0;
      }

      this.addVendorsToQuota(quota);
      quotaGroup.quotas.push(quota);
    });

    return quotaGroup;
  }

  blankQuota() {
    const quota: Quota = {
      id: uuidv4(),
      isDeleted: false,
      limit: 0,
      starts: 0,
      completes: 0,
      limitPercent: 0,
      limitN: 0,
      order:999,
      targetGroup: {
        id: uuidv4(),
        gender: null,
        ethnicity: null,
        hispanic: null,
        minHHI: null,
        maxHHI: null,
        minAge: null,
        maxAge: null,
        region: null,
      },
      vendorLimits: []
    };

    return quota;
  }

  blankQuotaGroup(groupName, questionId) {
    const quotaGroup: QuotaGroup = {
      id: uuidv4(),
      isDeleted: false,
      order: this.projectQuota.quotaGroups.length,
      name: groupName,
      questionId: questionId,
      quotas: [],
      completes: 0,
      starts: 0,
      deletedOptions: [],
    };

    return quotaGroup;
  }

  removeQuotaGroup(id) {
    var index = this.projectQuota.quotaGroups.findIndex(e => e.id === id);
    this.projectQuota.quotaGroups[index].isDeleted = true;
    this.saveQuota();
    this.sort(this.projectQuota.quotaGroups, 'order')
  }

  updateAddedQuotaGroupList() {
    var addedQuestions: string[] = [];
    var nonNestedGroups: QuotaGroup[] = [];

    this.projectQuota.quotaGroups.forEach(e => {
      var questionIdArray = e.questionId.split(',');
      if (questionIdArray.length == 1) {
        nonNestedGroups.push(e);
      }
      questionIdArray.forEach(qId => {
        if (this.questions[qId]) {
          addedQuestions.push(this.questions[qId]?.type);
        }   
      });
    });
    this.addedQuestions = addedQuestions;
    this.nonNestedGroups = nonNestedGroups;
  }

  removeQuota(group, id) {
    group.quotas.filter(e => e.id === id).forEach(q => {
      q.isDeleted = true
      this.checkForZeroQuotas(group);
      this.saveQuota(JSON.parse(JSON.stringify((q))));
    });
  }


  changeVendorQuotaSetting(val) {
    this.projectQuota.enableVendorLimits = val;
    if (this.projectQuota.enableVendorLimits) {
      this.projectQuota.quotaGroups.forEach(qg => {
        qg.quotas.filter(e => e.isDeleted === false).forEach(q => {
          this.addVendorsToQuota(q);
        });
      });
    }
    this.saveQuota();
  }

  addVendorsToQuota(quota) {
    const vendorLimits = [];

    this.vendors.forEach(vendor => {
      if (!vendor.partnerFullQuota || !this.project.fullLaunchQuota) {
        vendor.totalFullQuotaPercent = 0;
      }
      else {
        vendor.totalFullQuotaPercent = this.project.fullLaunchQuota > 0 && vendor.partnerFullQuota > 0 ? Math.ceil(vendor.partnerFullQuota * 100 / this.project.fullLaunchQuota) : 0;
      }

      // For every vendor on this project we add the vendor limits if specified
      const existing = quota.vendorLimits.find(v => v.vendorId === vendor.partnerId);
      
      if (existing) {
        existing.limitN = Math.ceil(vendor.partnerFullQuota * existing.limit / 100);
        existing.limitPercent = existing.limit;
        vendorLimits.push(existing);

      } else {

        const qv: QuotaVendorLimit = {
          id: uuidv4(),
          vendorId: vendor.partnerId,
          vendorName: vendor.partnerName,
          limitPercent: quota.limit,
          limitN: 0,
          limit: 0,
          completes: 0,
          starts: 0
        };
        
        qv.limit = qv.limitPercent;
        qv.limitN = Math.ceil(vendor.partnerFullQuota * qv.limitPercent / 100);
        vendorLimits.push(qv);
      }
    });

    quota.vendorLimits = vendorLimits;
  }


  saveQuota(inlineSaveQuota = null) {
    if (this.newQuotaGroups.length > 0) {
     this.projectQuota.quotaGroups.concat(this.newQuotaGroups);
     this.updateAddedQuotaGroupList();
    }

    //we do not save the dummy project we use for demo
    if(this.project.id == "d583bf3a-ab7e-441f-b1a1-07c96bb3e2e6")
      return;

    if (!inlineSaveQuota) { // saved through quota config modal

      var projectQuota = JSON.parse(JSON.stringify(this.projectQuota));
      projectQuota.quotaGroups = projectQuota.quotaGroups.concat(this.newQuotaGroups);

      var x = 0;

      projectQuota.quotaGroups.forEach(qg => {
        qg.order = x;
        x++;

      qg.quotas.forEach(q => {
        if (q.id === this.newQuota?.id) {
          this.newQuota = null;
        }

        q.limit = q.limitPercent || 0;
        q.vendorLimits.forEach(qv => {
          qv.limit = qv.limitPercent;
        });

        q.targetGroup.minAge = (q.targetGroup.minAge == null) ? null : Number(q.targetGroup.minAge);
        q.targetGroup.maxAge = (q.targetGroup.minAge == null) ? null : Number(q.targetGroup.maxAge);
        q.targetGroup.minHHI = (q.targetGroup.minHHI == null) ? null : Number(q.targetGroup.minHHI);
        q.targetGroup.maxHHI = (q.targetGroup.maxHHI == null) ? null : Number(q.targetGroup.maxHHI);
      });
    });
      if (this.quotaConfigEditDetails) {
        projectQuota.limitCheck = this.quotaConfigEditDetails.limitCheck;
        projectQuota.limitMode = this.quotaConfigEditDetails.limitMode;
        projectQuota.enableVendorLimits = this.quotaConfigEditDetails.enableVendorLimits;
        projectQuota.distributeBy = this.quotaConfigEditDetails.distributeBy;
        projectQuota.distributionFlexibility = this.quotaConfigEditDetails.distributionFlexibility;
      }
      this.quotaService.SaveQuota(this.project.id, projectQuota).subscribe(data => {
      }, error => {
        this.alertify.error('Failed to update Quota');
        }, () => {
          if (this.newQuotaGroups.length > 0 ) this.alertify.success('Quota added');
          else this.alertify.success('Quota updated');

          this.projectQuota.quotaGroups = projectQuota.quotaGroups;
          this.newQuotaGroups = [];
          if (this.openModalRefQuotaConfig) this.openModalRefQuotaConfig.hide()
          this.getQuota();
      });

      this.updateAddedQuotaGroupList();
    }
    // if inline save
    else {
      var q = inlineSaveQuota;

      if (q.id === this.newQuota?.id) {
        this.newQuota = null;
      }
        q.limit = q.limitPercent || 0;
        q.vendorLimits.forEach(qv => {
          qv.limit = qv.limitPercent;
        });

        q.targetGroup.minAge = (q.targetGroup.minAge == null) ? null : Number(q.targetGroup.minAge);
        q.targetGroup.maxAge = (q.targetGroup.maxAge == null) ? null : Number(q.targetGroup.maxAge);
        q.targetGroup.minHHI = (q.targetGroup.minHHI == null) ? null : Number(q.targetGroup.minHHI);
        q.targetGroup.maxHHI = (q.targetGroup.maxHHI == null) ? null : Number(q.targetGroup.maxHHI);
        this.quotaService.SaveSingleQuota(this.project.id, inlineSaveQuota, this.refreshParticipants).subscribe(data => {
        if (data) {
          this.alertify.success('Quota updated');
          this.projectQuota.distributeBy = 'custom';

          this.calculateGroupTotals(q.groupId);
          this.checkForZeroQuotas();
          this.handleMissingQuotas(this.projectQuota.quotaGroups.find(e => e.id === q.groupId));
          this.sortGroup(this.projectQuota.quotaGroups.find(e => e.id === q.groupId));
          this.refreshParticipants = false;
        }
        else {
          this.alertify.error('Failed to update Quota');
          this.getQuota();
        }
      }, (error)=> {
        this.alertify.error('Failed to update Quota');
        this.getQuota();
      });
    }
  }

  handleAction(event) {
    if (event.type == "import") {
      this.closeModal(null);
      this.updateProject(event.row)
    }
  }

  updateProject(value) {
    this.quotaTemplateId = value.value
    this.cloneQuota();
  }

  // get project list to import a quota
  getProjectList = (filter) => {
    this.quotaTemplates = [
      { label: 'ID', id: 'code', sortable: false }, { label: 'Name', id: 'name', sortable: false }, { label: 'Client', id: 'client', sortable: true }, { label: 'End Date', id: 'closeDate', sortable: true }, {label: 'Import', type: 'actions-inline'}
    ];
    this.actions = [ {label: 'Import', type: 'import', icon: 'file-import'}];
    // this.dropdownService.projects(filter, '', 'survey');
    var list = this.dropdownService.getQuotaTemplates(filter, this.project.id, 'survey')
    list.forEach(element => {
      this.projectList = element
    });
    if (filter == '' || filter == null) {
      this.projectList = [];
    }
    return list
  }

  cloneQuota() {

    this.quotaService.CloneQuotaFromProject(this.project.id, this.quotaTemplateId).subscribe((data) => {
      this.alertify.success('Quota updated');
      this.getQuota();
    }, error => {
      this.alertify.error('There was an error cloning quota');
    });
  }

  openModal(modal, type) {

    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;
    this.modalType = type;

    if (type == 'templateClone' && this.project.fullLaunchQuota > 0) {
      this.modalData = {title: 'Clone Quota', message: null}
      this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class:'modal-lg nav-modal-style modal-quota-clone' });
    }
    else if (type == 'templateImport' && this.project.fullLaunchQuota > 0) {
      this.importFileName = null;
      this.importFileAllocationType = 'percent';
      this.modalData = {title: 'Import Quota', message: null}
      this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class:'modal-lg nav-modal-style modal-quota-import' });
    }
    else if (type.includes('confirm')) {
      if (type == 'confirm-add') {
        this.modalData = {title: 'Add Quota Group', message: 'Adding a new question after data collection has begun will result in missing data that cannot be backfilled. Are you sure you want to continue?'};    
      }
      this.openModalRef = this.modalService.show(modal, {keyboard: false, ignoreBackdropClick: true, class:'nav-modal-style' });
      this.openModalRef.onHidden.subscribe(() => {
        if (this.latestReasonModalClosed === 'continue') {
          this.openModalQuotaConfig('add');
        }
    });
    }
  }


  closeModal(reason: string = null) {
    this.openModalRef.hide();
    this.latestReasonModalClosed = reason;
  }

  sort(array, sortBy) {
    var A;
    var B
    if (array) {
      array.sort((a, b) => {

        if (sortBy === 'order')
        {
          A = a.order;
          B = b.order;
        }
        else if (sortBy === 'min')
        {
          A = a.min
          B = b.min
        }
        else if (sortBy === 'minAge')
        {
          A = a.targetGroup.minAge
          B = b.targetGroup.minAge
        }
        else if (sortBy === 'minHHI')
        {
          A = a.targetGroup.minHHI
          B = b.targetGroup.minHHI
          if (A == -1) A =999999999999
          if (B == -1) B =999999999999
        }
  
        let comparison = 0;
        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        return comparison;
      });
    }
  }

  importFileUploaded(data) {
    this.importFileName = data?.origFile?.name;
  }

  importQuotaFromFile() {
    if (this.importFileName) {
      this.loadingImport = true;
      this.quotaService.ImportQuotaFromFile(this.project.id, this.importFileName, this.importFileAllocationType).subscribe((data) => {
        this.loadingImport = false;
        this.openModalRef.hide();
        this.alertify.success("Quota succefully imported from file")
        this.getQuota();
      }, error => {
        this.alertify.error("Unable to import quota from file - " + error);
        this.loadingImport = false;
      }) 
    }
  }

  downloadQuotaTemplate() {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    let csvContent = 'Quota Label,Attribute,Allocation\n';

    let data = [
      { label: 'Age', attribute: '18-24', allocation: 100 },
      { label: '\n', attribute: '25-34', allocation: 100 },
      { label: '\n', attribute: '35-44', allocation: 100 },
      { label: '\n', attribute: '45-54', allocation: 100 },
      { label: '\n', attribute: '55-64', allocation: 100 },
      { label: '\n', attribute: '65+', allocation: 100 },
      { label: 'Gender', attribute: 'Male', allocation: 300 },
      { label: '\n', attribute: 'Female', allocation: 300 },
      { label: 'Region', attribute: 'Northeast', allocation: 150 },
      { label: '\n', attribute: 'Midwest', allocation: 150 },
      { label: '\n', attribute: 'South', allocation: 150 },
      { label: '\n', attribute: 'West', allocation: 150 },
      { label: 'Income', attribute: '0-24999', allocation: 75 },
      { label: '\n', attribute: '25000-49999', allocation: 75 },
      { label: '\n', attribute: '50000-99999', allocation: 75 },
      { label: '\n', attribute: '100000-149999', allocation: 75 },
      { label: '\n', attribute: '150000-249999', allocation: 75 },
      { label: '\n', attribute: '250000-349999', allocation: 75 },
      { label: '\n', attribute: '350000-449999', allocation: 75 },
      { label: '\n', attribute: '450000+', allocation: 75 },
      { label: 'Ethnicity', attribute: 'White/Caucasian', allocation: 125 },
      { label: '\n', attribute: 'Black or African American', allocation: 125 },
      { label: '\n', attribute: 'Asian or Pacific Islander', allocation: 125 },
      { label: '\n', attribute: 'Native American Indian or Alaska Native', allocation: 125 },
      { label: '\n', attribute: 'Other/Prefer to Self-Describe', allocation: 125 },
      { label: 'Hispanic', attribute: 'Yes, Hispanic', allocation: 300 },
      { label: '\n', attribute: 'No, Not Hispanic', allocation: 300 },
    ];
    
    data.forEach((row) => {
      csvContent += `"${row.label}","${row.attribute}","${row.allocation}"\n`;
    });

    this.utils.generateXLSX(csvContent, 'quota-template.csv');
  }

  // not used in quota 2.0 but keeping for now
  downloadQuota() {
    if (this.auth.isClient() || this.auth.isVendor() || this.isVendorview) return;

    let csvContent = '';
   
    // Colum headers
    csvContent = 'Quota Label,Attribute,Allocation\n'

    this.projectQuota?.quotaGroups.forEach( (qg: QuotaGroup) => {
      csvContent += qg.name + ',';

      var i = 0;
      qg.quotas.filter(x => !x.isDeleted).forEach((quota: Quota) => {
        const targetGroup = quota.targetGroup;
        
        if (qg.name != 'Income' && qg.name != 'Age') {
          var value = targetGroup[qg.name.toLowerCase()];
          if (value != 'pnta') {
            if (i > 0) csvContent += ',' // skip quota label if not first row
            csvContent += `"${targetGroup[qg.name.toLowerCase()]}"`
            csvContent += ', ' + quota.limitN + '\n'
          }
        }
        else {
          if (qg.name == 'Income') {
            if ((targetGroup.minHHI || targetGroup.maxHHI) && targetGroup.minHHI != -1 ) {
              if (i > 0) csvContent += ',' // skip quota label if not first row
              csvContent += !targetGroup.minHHI ? 0 : targetGroup.minHHI
              if (targetGroup.maxHHI.toString()) {
                csvContent += targetGroup.maxHHI > 150000 ? '+' : '-' + targetGroup.maxHHI;
              }
              csvContent += ', ' + quota.limitN + '\n'
            }
          }
          else if (targetGroup.minAge.toString()) {
            if (i > 0) csvContent += ',' // skip quota label if not first row
            csvContent += (targetGroup.minAge + (targetGroup.maxAge.toString() ? '-' + targetGroup.maxAge : ''));
            csvContent += ', ' + quota.limitN + '\n'
          }
        }
        i++;
      })
      
    });

    this.utils.generateXLSX(csvContent, this.project.projectCode+'-quota-allocation.csv');
  }

  removeNewQuotaWithBlanksIfExists() {
    var quota = null;
    var group = null;
    // find quota thats new in all quota groups
    this.projectQuota.quotaGroups.forEach(qg => {
      if (qg.quotas) {
        quota = qg.quotas.find(q => q.id === this.newQuota.id);
        if (quota) {
          group = qg;
          var blankQuota = false;
          var dontSaveYet = false;
          if (group.name === 'Income' || group.name === 'Age') {
            if (group.name === 'Income') {
              if (!quota.targetGroup.minHHI && !quota.targetGroup.maxHHI && (!quota.limitN || quota.limitN == 0) && (!quota.limitPercent || quota.limitPercent == 0)) {
                blankQuota = true;
              }
              if (!blankQuota && (quota.targetGroup.minHHI != -1 && (!quota.targetGroup.minHHI || !quota.targetGroup.maxHHI))) {
                dontSaveYet = true;
              }
            }
            else if (group.name === 'Age') {
              if (!quota.targetGroup.minAge && !quota.targetGroup.maxAge && (!quota.limitN || quota.limitN == 0) && (!quota.limitPercent || quota.limitPercent == 0)) {
                blankQuota = true;
              }
              if (!blankQuota && (!quota.targetGroup.minAge || !quota.targetGroup.maxAge)) {
                dontSaveYet = true;
              }
            }
            if (blankQuota && quota.id === this.newQuota.id) {
              group.quotas = group.quotas.filter(e => e.id != quota.id);
            }
    
            if (!blankQuota) {
              if (!dontSaveYet) {
                this.refreshParticipants = true;
                this.saveQuota(quota);
              }
            }
            else {
              group.quotas = group.quotas.filter(e => e.id != quota.id);
              this.newQuota = false;
              this.checkForZeroQuotas(group);
            }
          }
        }
      }
    });
  }

  openModalQuotaConfig(mode) {
    if (this.projectQuota) {
      this.quotaConfigEditDetails = {
        mode: mode,
        step: 1,
        quotasNotAdded: [],
        quotasAdded: [],
        selectAllQuotas: false,
        atLeastOneQuotaSelected: false,
        atLeastTwoQuotasSelected: false,
        atLeastThreeSelected: false,
        limitMode: this.projectQuota.limitMode,
        limitCheck: (this.projectQuota.limitCheck == 'tracking only' ? 'tracking' : this.projectQuota.limitCheck) ?? 'tracking',
        distributeBy: this.projectQuota.distributeBy,
        applyFlexibility: this.projectQuota.distributionFlexibility != null,
        distributionFlexibility: this.projectQuota.distributionFlexibility,
        enableVendorLimits: this.projectQuota.enableVendorLimits,
      }
    }
    

    if (mode !== 'edit') {
      this.questionIDs.forEach(id => {
        if (id != null) {
          if (!this.addedQuestions.includes(this.questions[id].type)) {
            this.quotaConfigEditDetails.quotasNotAdded.push(
              {questionId: id, value: this.questions[id].type, selected: false}
            )
          }
          else  {
            var existingGroup = this.projectQuota.quotaGroups.find(x => x.questionId.includes(id));
            if (existingGroup.questionId.length <= 36) {
              this.quotaConfigEditDetails.quotasAdded.push(
                {groupId: existingGroup.id, value: this.questions[id].type, selected: false}
              )
            }
          }
        }
      });
    }
    this.openModalRefQuotaConfig = this.modalService.show(this.quotaConfig, {keyboard: false, ignoreBackdropClick: true, class: 'nav-modal-style modal-quotas-config'});
  }

  selectAllQuotasInConfig(event) {
    var val = event.target.checked;
    this.quotaConfigEditDetails.quotasNotAdded.forEach(quotaType => {
      quotaType.selected = val;
    });
    if (val == true && this.quotaConfigEditDetails.quotasNotAdded.length > 0) this.quotaConfigEditDetails.atLeastOneQuotaSelected = true;
    else (this.quotaConfigEditDetails.atLeastOneQuotaSelected = false);
  }

  quotaSelected() {
    var numSelected = 0;
    this.quotaConfigEditDetails.quotasNotAdded.forEach(quotaType => {
      if (quotaType.selected) numSelected++;
    });
    this.quotaConfigEditDetails.atLeastOneQuotaSelected = numSelected > 0;
    this.quotaConfigEditDetails.selectAllQuotas = numSelected == this.quotaConfigEditDetails.quotasNotAdded.length;
  }

  quotaSelectedForNest() {
    var numSelected = 0;
    this.quotaConfigEditDetails.quotasAdded.forEach(quotaType => {
      if (quotaType.selected) numSelected++;
    });
    this.quotaConfigEditDetails.atLeastTwoQuotasSelected = numSelected > 1;
    this.quotaConfigEditDetails.atLeastThreeSelected = numSelected > 2;
  }
  
  nextInQuotaConfigModal() {
    var newQuotaGroups = [];
    if (this.quotaConfigEditDetails.mode === 'add') {
      if (this.quotaConfigEditDetails.step == 1) {
        this.quotaConfigEditDetails.step = 2;
      }
      else if (this.quotaConfigEditDetails.step == 2) {
        this.quotaConfigEditDetails.quotasNotAdded.filter(x => x.selected).forEach(newGroup => {
          var qg = this.createQuotaGroup(newGroup.questionId);
          newQuotaGroups.push(qg);
        });

        this.newQuotaGroups = newQuotaGroups;
        this.saveQuota();
      }
    }
    else if (this.quotaConfigEditDetails.mode === 'edit') {
      this.newQuotaGroups = newQuotaGroups;
      this.saveQuota();
    }
    else if (this.quotaConfigEditDetails.mode === 'nest') {
      var quotasToNest = this.quotaConfigEditDetails.quotasAdded.filter(x => x.selected)?.map(x => x.groupId);
      if (quotasToNest.length > 1) {
        this.nestQuotas(quotasToNest);
      };
    }
  }

  nestQuotas(quotaIds: Array<string>) {
    this.quotaService.NestQuota(this.project.id, quotaIds).subscribe(data => {
      this.alertify.success('Quota nested');
      if (this.openModalRefQuotaConfig) this.openModalRefQuotaConfig.hide()
      this.getQuota();
    }, error => {
      this.alertify.error('Failed to nest quotas');
    });
  }

  undoNest(group: QuotaGroup) {
    this.quotaService.UndoNest(this.project.id, group.id).subscribe(data => {
      this.alertify.success('Quota unested');
      this.getQuota();
    }, error => {
      this.alertify.error('Failed to nest quotas');
    });
  }

  checkVendorCardOverflow() {
    var el = document.querySelector('.vendor-quota-container');
    if (el) {
      if (el.scrollWidth > el.clientWidth) {
        this.vendorCardOverflowX = true;
        
      }
      else {
        this.vendorCardOverflowX = false;
      }

      if (el.scrollHeight > el.clientHeight) {
        this.vendorCardOverflowY = true;
      }
      else {
        this.vendorCardOverflowY = false;
      }
      this.changeDetectorRef.detectChanges();

    }
  }

  populateHHIList() {
    var hhiList = [];
    for (var n = this.hhiMin; n < 100000; n += 5000) hhiList.push(n);
    for (var n = 100000; n <= this.hhiMax; n += 50000) hhiList.push(n);

    return hhiList;
  }

  populateAgeList() {
    var ageList = [];
    for (var n = this.ageMin; n <= this.ageMax; n += 1) ageList.push(n);
    return ageList;
  }

  onDrop(event: DndDropEvent) {
    var oldIndex = this.quotaConfigEditDetails.quotasAdded.findIndex(item => item.groupId.toLowerCase() === event.data.groupId.toLowerCase());
    let movedTask;

    if (this.quotaConfigEditDetails.quotasAdded && event.dropEffect === 'move') {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = this.quotaConfigEditDetails.quotasAdded.length;
      }

      [movedTask] = this.quotaConfigEditDetails.quotasAdded.splice(oldIndex, 1);
      // Add to new list at the specified index
      this.quotaConfigEditDetails.quotasAdded.splice(index, 0, movedTask);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkVendorCardOverflow()
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {

    const clickedElement = event.target as HTMLElement;
    if (clickedElement.classList.contains('clickable')) {
      return;
    }
    else {
      if (this.projectQuota?.quotaGroups && this.newQuota) {
        // check if there is a quota in a quota group that has new Quota flag to true
        this.removeNewQuotaWithBlanksIfExists();
      }
    }
  }
}
