<div class="card border-0 m-0 w-auto">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <i class="fak fa-filter-users mr-2 nav-secondary fa-xl"></i>
        <h4 class="nav-secondary font-weight-bold mb-0 d-inline">Manager View</h4>
      </div>
      <div class="col text-right">
        <span class="nav-font14 mb-0 font-weight-normal grey4"><strong>({{totalSelected}})</strong> Selected</span>
      </div>
    </div>
  </div>
  <div class="card-body pt-0">
    <div class="row mb-2">
      <div class="col grey5 small">To filter this screen, select the users you would like to view.</div>
    </div>
    <div class="row">
      <div class="col">
        <div class="column-picker-container pb-2 nav-font14">
          <div class="d-flex justify-content-center m-0">
            <div class="input-group copy-search">
              <div class="input-group-prepend height24">
                <div class="input-group-text border-right-0">
                  <i class="fas fa-search cursor-default"></i>
                </div>
              </div>
              <input [(ngModel)]="filterText" type="search" class="form-control form-control-sm height24" (ngModelChange)="searchUser()" placeholder="Search user">
            </div>
          </div>
          <div class="px-3">
            <div class="or-checkbox d-inline">
              <input type="checkbox" name="open-end-select-all" id="open-end-select-all" (change)="selectAll($event)">
              <label for="open-end-select-all"></label>
            </div>
            <div class="grey5 d-inline px-2 ml-3">Select all <span class="grey4 mx-1">({{filteredUsers.length}})</span></div>
          </div>
          <hr class="my-2">
          <div class="column-list-container overflow-y scrollbar px-3 py-1" [ngStyle]="{'grid-template-columns': 'repeat(' + totalColumns + ', 1fr)'}">
            @for (user of filteredUsers; track user; let idx = $index) {
              <div class="user-item pb-2 d-flex align-items-top">
                <div class="or-checkbox d-inline">
                  <input type="checkbox" [(ngModel)]="user.selected" [name]="idx+'-open-end-selected'" [id]="idx+'-open-end-selected'" (change)="select(user, $event)" >
                  <label [for]="idx+'-open-end-selected'"></label>
                </div>
                <div class="grey5 d-inline px-2 columnText">{{user?.name}}</div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
