import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ProjectService, ProjectFilters, SettingsService, AlertifyService, ThemeService, AuthService, FavoritesService, UtilsService, Project, ProjectDataService, ScheduleService } from 'core';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ExportFilesService } from 'projects/core/src/services/exportFiles.service';
import { formatDate } from '@angular/common';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FilterPipe } from 'projects/core/src/helpers/filter.pipe';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  providers: [ FilterPipe ]
})
export class ProjectsComponent implements OnInit {
  projectIdList: any[] = [];
  numberOfProjectsToLoad = 100;
  fulllist: any[];
  projects: any[];
  allProjects: any[];
  allProjectsLoaded = true;
  type: string;
  loading = true;
  favorites: any;
  rowActions = [
    {label: 'Open', type: 'open'},
    {label: 'Download', type: 'download'},
    {label: 'Clone', type: 'clone'},
    {label: 'Delete', type: 'delete'}
  ]; // TO DO: MAY NEED ACTION PERMISSIONS PER ROW
  views = [];
  currentView: any;
  showList = false;
  showCalendar = false;
  showCard = false;
  showCardGroup = false;
  showGantt = false;
  ganttHighchartOptions = {};
  ganttHighcharts = null;
  settings: any;
  layoutListSettings: any = {};
  darkTheme = false;
  filters: ProjectFilters;
  statusCounts = [];
  listUrls: any;
  redirectUrls: any;
  mode = 'card';
  projectGroups = [];
  projectCardGroups = [];
  clickroute = '/projects';
  projectTypeId = '';
  activeFilters: string[] = [];
  selectedProject: Project;
  arrayOfIds: string[]
  allTasks;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  openModalRef: any;

  constructor(
    private projectService: ProjectService,
    private settingsService: SettingsService,
    private alertify: AlertifyService,
    private scheduleService: ScheduleService,
    private favoriteService: FavoritesService,
    private exportFilesService: ExportFilesService,
    private themeService: ThemeService,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private filterPipe: FilterPipe,
    private projectDataService: ProjectDataService,
    private utilsService: UtilsService) {

    const url = this.router.url.toLowerCase();
    this.type = (url === '/bids') ? 'bid' : 'survey';
    if (this.type === 'bid')  {
      this.clickroute = '/bids';
    }

    this.route.params.subscribe(routeParams => {
      this.projectTypeId = routeParams.id;
      this.load();
    });
  }
  ngOnInit() {
   // this.projectTypeId = this.route.snapshot.params.id;
   // this.load();
  }

  load() {
    this.projectService.GetProjectListViews(null, true).subscribe(data => {
      this.views = data.filter(v => (v.locationType=='default' || v.locationType==null));
      this.filters = {} as ProjectFilters;
      this.settings = this.settingsService.getProjectsViewSettings(this.projectTypeId, this.views);

      if (this.views[this.settings.view] == null) {
        this.switchView(0);
      } else {
        this.switchView(this.settings.view);
      }
    }, error => {
    });
  }

  public openModal(template: any, type: string) {
    if (type === 'URL') this.openUrlModal();

    this.openModalRef = this.modalService.show(template, {ignoreBackdropClick: true, keyboard: false, class: 'projects modal-lg' });
  }

  openUrlModal() {
    this.projectIdList = [];
    this.projects.forEach((element) => {
      this.projectIdList.push(element.id);
    });

    this.projectService.GetBulkProjectUrls(this.projectIdList).subscribe(data => {
      this.listUrls = data;
    });
    this.settingsService.getSurveyLinkUrls().subscribe( urls => {
      this.redirectUrls = urls;
    });

  /* totalString = totalString.concat('\n' + 'CompleteRedirectUrl  = ' + urls.completeRedirectURL + '\n' +
                                              'TerminateRedirectUrl = ' + urls.terminateRedirectURL + '\n' +
                                              'OverQuotaRedirectUrl = ' + urls.overQuotaRedirectURL + '\n' +
                                              'QCRedirectUrl        = ' + urls.qcRedirectURL);*/
  }

  copyRedirectUrls() {
    const totalString = 'Redirects\n\n' + 'Complete  = ' + this.redirectUrls.completeRedirectURL + '\n' +
    'Terminate = ' + this.redirectUrls.terminateRedirectURL + '\n' +
    'Overquota = ' + this.redirectUrls.overQuotaRedirectURL + '\n' +
    'QC        = ' + this.redirectUrls.qcRedirectURL;

    this.utilsService.copyTextToBuffer(totalString);
    this.alertify.success('Redirect URLs are copied');
  }
  copyAllUrls() {
    const numberOfVendors = this.listUrls.length;
    let totalString = 'Redirects\n\n' + 'Complete  = ' + this.redirectUrls.completeRedirectURL + '\n' +
    'Terminate = ' + this.redirectUrls.terminateRedirectURL + '\n' +
    'Overquota = ' + this.redirectUrls.overQuotaRedirectURL + '\n' +
    'QC        = ' + this.redirectUrls.qcRedirectURL;

    let vendor;
    let projectName;
    let url;

    for (let i = 0; i < numberOfVendors; i++) {
        vendor = this.listUrls[i].vendor;
        const numberOfUrls = this.listUrls[i].urls.length;
        totalString = totalString.concat('\n\n' + vendor + '\n\n');

        for (let x = 0; x < numberOfUrls; x++) {
          projectName = this.listUrls[i].urls[x].projectName;
          url = this.listUrls[i].urls[x].surveyEntryUrl;
          totalString = totalString.concat(projectName.concat(' = ' + url + '\n'));
        }
      }
    this.utilsService.copyTextToBuffer(totalString);
    this.alertify.success('All URLs are copied');
  }

  copyProjectsUrls(item) {
    const numberOfUrls = item.urls.length;
    let totalString = '';
    const vendor = item.vendor;
    let projectName;
    let url;

    totalString = totalString.concat(vendor + '\n\n');
    for (let i = 0; i < numberOfUrls; i++) {
      projectName = item.urls[i].projectName;
      url = item.urls[i].surveyEntryUrl;
      totalString = totalString.concat(projectName.concat('\n' + url + '\n\n'));
    }

    this.utilsService.copyTextToBuffer(totalString);
    this.alertify.success('Project URLs copied');
  }

  bulkDLParticipantExport() {
    let limit = 10;
    const projects = this.filterPipe.transform(this.projects, this.settings.filterText, this.currentView.filterCols);
    this.projectIdList = [];
    projects.forEach((element) => {
      this.projectIdList.push(element.id);
    });

    if (this.projectIdList.length > limit) {
      this.alertify.error('A maximum of ' + limit + ' projects is allowed. Please filter the list as required.');
      return;
    }
    this.alertify.message('Your file will download once ready');
    const filename = "Participant-" + formatDate(new Date(), 'MM-dd-yyyy HH_mm_ss', 'en').toString() + '.xlsx';

    if(this.auth.isVendor()){
      this.exportFilesService.downloadVendorParticipantData(this.projectIdList, filename, this.auth.getUser().vendorId).subscribe(url => {
        window.location.assign(url);
        this.projectIdList = [];
      }, error => {
        this.alertify.error(error);
      }, () => {
      });
    }
    else {
      this.exportFilesService.downloadParticipantData(this.projectIdList, filename, true).subscribe(url => {
        window.location.assign(url);
        this.projectIdList = [];
      }, error => {
        this.alertify.error(error);
      }, () => {
      });
    }



  }

  filterStatus(status) {
    // If status is All clear array, otherwise add or remove status from array
    if (status === 'All') {
      this.activeFilters = [];
    } else if (this.activeFilters.indexOf(status) < 0) {
      this.activeFilters.push(status);
    } else {
      const index = this.activeFilters.indexOf(status);
      this.activeFilters.splice(index, 1);
    }

    this.settings.views[this.currentView.id].status = (status === 'All') ? '' : this.activeFilters;
    this.updateProjectList();
  }
  setGroupBy(group) {
    this.settings.views[this.currentView.id].groupBy = group;
    this.updateProjectList();
  }
  updateProjectList() {
    let projects;
    if (this.settings.views[this.currentView.id].myFavorites || this.settings.views[this.currentView.id].myProjects) {
      projects = this.fulllist.filter(
        e => (!this.settings.views[this.currentView.id].myFavorites || this.favorites[e.id] === true)
            && (!this.settings.views[this.currentView.id].myProjects || e.myProject === true)
      );
    } else {
      projects = this.fulllist;
    }

    projects = this.filterPipe.transform(projects, this.settings.filterText, this.currentView.filterCols);

    this.updateStatusCounts(projects);

    const status = (this.settings.views[this.currentView.id].status == null
      || this.statusCounts.find(e => this.settings.views[this.currentView.id].status.includes(e.status)) == null)
      ? '' : this.settings.views[this.currentView.id].status;
    if (status !== '') {
      // Handle refresh statusbeing held but active filter array is empty. Just show all
      if (this.activeFilters.length > 0) {
        // Check for project status match in filter array
        projects = projects.filter(e => (this.activeFilters.includes(e.projectStatus)));
      }
    }

    //show user groups only when 'card' mode
    const projectGroups = [];
    if ( this.settings.views[this.currentView.id].groupBy === 'status') {
      this.statusCounts.forEach( s => {
        if ( s.count > 0 && s.status != 'All') {
          projectGroups.push({ title: s.status, open: true, projects: projects.filter(e => e.projectStatus === s.status)});
        }
      });
    } else if ( this.settings.views[this.currentView.id].groupBy === 'client') {
      const values = projects.map(p => p.clientName).sort();
      let currentValue = '';
      values.forEach( v => {
        if (v === currentValue) { return true; } else { currentValue = v; }

        const tempProjects = projects.filter(e => e.clientName === v);
        if ( tempProjects.length > 0) {
          projectGroups.push({ title: v, open: true, projects: tempProjects });
        }
      });
    } else if ( this.settings.views[this.currentView.id].groupBy === 'projectManager') {
      const values = projects.map(p => p.projectManager).sort();
      let currentValue = '';
      values.forEach( v => {
        if (v === currentValue) { return true; } else { currentValue = v; }

        const tempProjects = projects.filter(e => e.projectManager === v);
        if ( tempProjects.length > 0) {
          if(v == '' || v == null) v = "Unassigned";
          projectGroups.push({ title: v, open: true, projects: tempProjects });
        }
      });
    } else if ( this.settings.views[this.currentView.id].groupBy === 'accountOwner') {
      const values = projects.flatMap(p => (p.accountOwner == null || p.accountOwner.length == 0) ? null: p.accountOwner.map(owner => owner.name)).sort();
      let currentValue = '';
      values.forEach( v => {
        if (v === currentValue) { return true; } else { currentValue = v; }
        const tempProjects = (v == null)? projects.filter(item => item.accountOwner.length == 0) : projects.filter(item => item.accountOwner.some(owner => owner.name === v));
        if ( tempProjects.length > 0) {
          if(v == '' || v == null) v = "Unassigned";
          projectGroups.push({ title: v, open: true, projects: tempProjects });
        }
      });
    }

    //show grouped cards
    let projectCardGroups = [];
    projectCardGroups = projects.reduce((groupsArray, project) => {
      const prefix = project.bidNumber;
      const existingGroup = groupsArray.find(group => group.prefix === prefix);

      if (existingGroup) {
        existingGroup.projects.push(project);
      } else {
        groupsArray.push({
          prefix,
          projects: [project]
        });
      }
      return groupsArray;
    }, []);

    const groupedProjectsArray = projectCardGroups.map(item => {
      if (item.projects.length > 1) {

        let longName = this.utilsService.longestCommonSubstring(item.projects.map(p => p.projectName), 8);
        if (longName == '')
          longName = item.projects[0].projectName;
        let summary = {
          prefix: item.prefix,
          bidNumber: item.projects[0]?.bidNumber,
          clientId: item.projects[0]?.clientId,
          clientName: item.projects[0]?.clientName,
          type: 'summary',
          groupName: longName,
          totalTasks: 3,
          totalTasksCompleted: 2,
          totalProjects: item.projects.length,
          projectsInTrouble: 0,
          users: []
        };
        let summaryUsers = [];
        item.projects.map(p => {
          if (p.projectManager)
          summaryUsers.push({ name: p.projectManager, type: 'pm' });

          p?.secondPm?.map(e => summaryUsers.push({ name: e, type: 'secondPm' }));
          p?.accountOwner?.map(e => summaryUsers.push({ name: e.name, type: 'accountOwner' }));

          if (p.health >= 10)
            summary.projectsInTrouble += 1;
        });
        let uniqueNames = {};
        summary.users = summaryUsers.filter(function(user) {
          let key = user.name + user.type;
          if (!uniqueNames[key]) {
            uniqueNames[key] = true;
            return true;
          }
          return false;
        });;

        item.projects.sort((a, b) => {
          const projectCodeA = a.projectCode;
          const projectCodeB = b.projectCode;
          return projectCodeA.localeCompare(projectCodeB);
        });
        item.projects.unshift(summary);
        item.currentPage = item.projects.slice(0,1);
      }

      return item;
    });

    this.projectCardGroups = groupedProjectsArray;

    this.allProjects = projects.map(item => {
      if (item.programmer != null) {
        const programmerString = typeof item.programmer === 'string' ? item.programmer : String(item.programmer);
        item.programmer = programmerString.split(',').map(e => ({name: e.trim(), type: 'programmer'}));
      }
      item.secondaryPm = item.secondPm.map(e => ({ name: e, type: 'secondPm' }));
      return item;
    });

    this.arrayOfIds = this.allProjects.map(obj => obj.id);
    this.projects = projects.slice(0, this.numberOfProjectsToLoad);

    if (this.mode == 'calendar') {
      this.GetAllProjectsTasks()
    }
    if (this.allProjects.length > this.numberOfProjectsToLoad) {
      this.allProjectsLoaded = false;
    }

    this.projectGroups = projectGroups;
    this.saveViewSettings();
  }
  getFilteredGroups(group){
    if(this.settings.views[this.currentView.id].groupBy == 'status')
      return this.projectCardGroups.filter(e=> e.projects.find( x=> x.projectStatus == group.title));
    if(this.settings.views[this.currentView.id].groupBy == 'client')
      return this.projectCardGroups.filter(e=> e.projects.find( x=> x.clientName == group.title));
    else if(this.settings.views[this.currentView.id].groupBy == 'projectManager'){
      var res =
      (group.title == "Unassigned")?
       this.projectCardGroups.filter(e=> e.projects.find( x=> x.type != 'summary' && (x.projectManager == null || x.projectManager == ""))) :
       this.projectCardGroups.filter(e=> e.projects.find( x=> x.projectManager == group.title));

       return res;
    }
    else if(this.settings.views[this.currentView.id].groupBy == 'accountOwner')
    {
      var res =
        (group.title == "Unassigned")?
         this.projectCardGroups.filter(e=> e.projects.find( x=> x.accountOwner && x.accountOwner.length ==0)) :
         this.projectCardGroups.filter(e=> e.projects && e.projects.find( x=> x.accountOwner && x.accountOwner.some(owner => owner.name === group.title)));

         return res;
    }
  }
  pageGroupChanged(action, group) {
    const pageSize = 1; // Number of projects per page
    let currentPage = group.projects.findIndex(item => item.id === group.currentPage[0].id);

    if (action === 'previous') {
        currentPage = currentPage - 1;
        if(currentPage < 0) currentPage = group.projects.length -1;
    } else if (action === 'next') {
        currentPage = currentPage + 1;
        if(currentPage >= group.projects.length) currentPage = 0;
    }
    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;

    group.currentPage = group.projects.slice(startIndex, endIndex);
  }

  switchView(view) {
    if (this.mode == 'calendar') {
      this.GetAllProjectsTasks
    }
    this.settings.view = view;
    this.saveViewSettings();
    this.loading = true;

    this.currentView = this.views[view];
    this.settings.views[this.currentView.id].status === ""
      ? this.activeFilters = []
      : this.activeFilters = this.settings.views[this.currentView.id].status;
    this.showList = this.currentView.viewModes.includes('list');
    this.showCalendar = this.currentView.viewModes.includes('calendar');
    this.showCardGroup = this.currentView.viewModes.includes('card');
    this.showCard = this.currentView.viewModes.includes('card');
    this.layoutListSettings = this.settingsService.getProjectsListSettings(this.currentView.id);
    const filterCols = [];

    this.currentView.tableColumns.forEach(col => {
      col.id = col.field;

      if (col.textFilterable) { filterCols.push(col.id); }
    });
    this.currentView.filterCols = filterCols;

    this.mode = (this.settings.views[this.currentView.id].mode == null || this.settings.views[this.currentView.id].mode === '') ? this.currentView.viewModes[0] : this.settings.views[this.currentView.id].mode;

    const sortCol = this.currentView.tableColumns.filter(
      (e: any) => e.id === this.layoutListSettings.sortBy?.id
    );
    this.layoutListSettings.sortBy = sortCol.length === 1 ? sortCol[0] : null;

    this.filters = {
      status : this.currentView.statusFilter,
      clientID : [],
      partnerID : [],
      freeText : '',
      isMine : false,
      archivedOnly: this.currentView.showArchivedProjectsOnlyButton,
      type: this.currentView.projectTypeId,
    };

    this.rowActions = this.rowActions.filter(e => e.type !== 'favorite' )
    if(this.currentView.showFavouriteButton) {
      //this.rowActions.push({label: 'Toggle Favorite', type: 'favorite'});
      var index = this.rowActions.map(x => { return x.type; } ).indexOf('delete');
      this.rowActions.splice(index--, 0, {label: 'Toggle Favorite', type: 'favorite'});
    }

    forkJoin([
      this.projectService.GetProjects(this.filters),
      this.favoriteService.getProjectFavorites()])
      .subscribe((responseList) => {
        this.fulllist = this.projectService.EnrichProjects(responseList[0]);
        this.updateStatusCounts(this.fulllist);
        this.loading = false;

        this.favorites = {};
        responseList[1].forEach(e => this.favorites[e.projectId] = true);

        this.updateProjectList();
      }, error => {
        this.alertify.error('There was an error getting project details');
    });
  }
  updateStatusCounts (list) {
    const statusCounts = {};
    let statusList = [];
    list.forEach(e => {
      if(this.auth.isVendor()) {
        //Vendor users will use the vendor status for filtering etc.
        e.projectStatus = e.vendorStatus;
      }

      if (statusCounts[e.projectStatus] == null) {
        statusCounts[e.projectStatus] = 1;
        statusList.push(e.projectStatus);
      } else {
        statusCounts[e.projectStatus]++;
      }
    });
    statusList = (this.currentView.statusFilter.length === 0) ? statusList : this.currentView.statusFilter;

    this.statusCounts = (statusList.length === 1) ? [] : [{ status: 'All', count: list.length }];
    statusList.forEach(e => {
      this.statusCounts.push({status: e, count: statusCounts[e]});
    });
  }
  switchMode(mode) {
    this.settings.views[this.currentView.id].mode = mode;
    this.mode = mode;

    if (this.mode == 'calendar') {
      this.GetAllProjectsTasks()
    }
    this.saveViewSettings();
  }
  saveProjectsListSetting(settings) {
    this.settingsService.setProjectsListSetting(this.currentView.id, settings);

  }
  saveViewSettings() {
    this.settingsService.setProjectsViewSetting(this.settings, this.projectTypeId);
  }

  saveProjectChange(details) {
    const projectId = details.item.id;

    this.projectService.UpdateProjectValue(projectId, details.col.id, details.newValue).subscribe( data => {
      this.alertify.success('Field Updated Successfully');
    } , error => {
      this.alertify.error('There was an issue updating this value');
    });
  }

  handleAction(action) {
    if (action.type === 'open') {
      this.openProject(action.row);
    } else if (action.type === 'download') {
      this.downloadProject(action.row);
    } else if (action.type === 'favorite') {
      this.favoriteProject(action.row);
    } else if (action.type === 'delete') {
      this.deleteProject(action.row);
    } else if (action.type === 'clone') {
      this.cloneProject({ type: 'clone', project: action.row})
    }
  }

  cloneProject(data) {
    this.projectDataService.setActionNavBar(data);
  }

  openProject(project) { this.router.navigateByUrl('/projects/' + project.id); }

  downloadProject(project) {
    const filename = "Participant-" +project.projectCode+ "-" + formatDate(new Date(), 'MM-dd-yyyy HH_mm_ss', 'en').toString() + '.xlsx';

    this.alertify.message('Your file will download once ready');
    let projectList = [project.id];

    if (this.auth.isVendor()) {
      this.exportFilesService.downloadVendorParticipantData(projectList, filename, this.auth.getUser().vendorId).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => { });
    } else {
      this.exportFilesService.downloadParticipantData(projectList, filename, true).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
    }
  }

  favoriteProject(project) {
    var exists = this.favorites[project.id];
    if (exists) {
      this.favoriteService.removeProjectFavorite(project.id).subscribe(data => {
        this.FavoriteChange(false, project);
        this.alertify.success('Project Removed from Favorites');
      }, error => { console.log(error); });
    } else {
      this.favoriteService.setProjectFavorite(project.id).subscribe(data => {
        this.FavoriteChange(true, project);
        this.alertify.success('Project Added to Favorites');
      }, error => { console.log(error); });
    }
  }

  deleteProject(project) {
    this.selectedProject = project;
    this.openModalRef = this.modalService.show(this.modalRefConfirm);
  }
  confirmDelete() {
    this.projectService.DeleteProject(this.selectedProject.id).subscribe(data => {
    }, error => {
       this.alertify.error('Unable to delete project');
       this.openModalRef.hide();
    }, () => {
      this.alertify.success(this.selectedProject.projectName + ' deleted successfully');
      this.openModalRef.hide();
      this.load();
    });
  }

  openProjectFromCalendar(project) { this.router.navigateByUrl('/projects/' + project.eventid + "/schedule"); }

  changeTheme(evt) {
    // this.darkTheme = !this.darkTheme;
    if (this.darkTheme) this.themeService.toggleDark();
    else this.themeService.toggleLight();
  }

  FavoriteChange(value, project) {
    this.favorites[project.id] = value;
    this.favoriteService.getProjectFavorites(true).subscribe(data => {
    }, error => {
    });
  }

  loadAllProjects() {
    this.projects = this.allProjects;
    this.allProjectsLoaded = true;
  }

  hasSettings() { return this.currentView.showCopyUrls || this.currentView.showDownload ? true : false; }

  GetAllProjectsTasks() {
    this.scheduleService.GetAllProjectsTasks(this.arrayOfIds).subscribe(data => {
      this.allTasks = [];

      data.forEach(task => {
        if (task.startDate != null && task.dueDate != null) {
          this.allTasks.push(task);
        }
      });
    }, error => {
      this.alertify.error('Unable to get projects tasks' + error);
    });
  }
}
