@if (auth.loggedIn()) {
 
    @if (!surveyTester) {
      <div class="wrapper">
        @if (auth.loggedIn()) {
          <app-nav-bar [scrollX]="scrollX"></app-nav-bar>
        }
        @if (instanceService.siteSections.length>0) {
          <div  class="content">
            <router-outlet></router-outlet>
          </div>
        }
    </div>
    }
    @if (surveyTester) {
      <div  class="content">
        <router-outlet></router-outlet>
      </div>
    }
  
}

@if (!auth.loggedIn()) {
  <router-outlet></router-outlet>
}
