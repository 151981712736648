@if (!loading) {
  <div>
    <!-- Container for full projects -->
    <div class="container-fluid">
      <div class="card my-2">
        <div class="card-header d-flex align-items-center">
          <ul class="nav nav-setup flex-nowrap">
            <li class="nav-item position-relative " (click)="showStep(1)">
              <h4 class="nav-link grey4" [class.active]="step == 1" href="#">Project Details</h4>
            </li>
            @if (enabledSteps >= 2) {
              <li class="nav-item position-relative" (click)="showStep(2)">
                <h4 class="nav-link grey4" [class.active]="step == 2" href="#">Vendors</h4>
              </li>
            }
            @if (enabledSteps >= 3) {
              <li class="nav-item position-relative" (click)="showStep(3)">
                <h4 class="nav-link grey4"  [class.active]="step == 3" href="#">Quality Settings</h4>
              </li>
            }
            @if (enabledSteps >= 4 && litemode == false) {
              <li class="nav-item position-relative" (click)="showStep(4)">
                <h4 class="nav-link grey4"  [class.active]="step == 4" href="#">Survey Settings</h4>
              </li>
            }
            @if (enabledSteps >= 5 && litemode == false) {
              <li class="nav-item position-relative" (click)="showStep(5)">
                <h4 class="nav-link grey4"  [class.active]="step == 5" href="#">Options</h4>
              </li>
            }
          </ul>
        </div>
        <div class="card-body">
          <form #step1Form="ngForm" novalidate [hidden]="step != 1">
            @if (mode === 'new') {
              <div class="alert alert-info" role="alert">
                <h4 class="alert-heading">Start your project setup here!</h4>
                <p>Fill in the inputs below and press the Next button to move onto the next step.</p>
              </div>
            }
            <div class="row">
              <div class="form-group col-12 col-md-4">
                <label for="_projectName" class="required">Project Name</label>
                <input [(ngModel)]="_project.projectName" (input)="formChanged($event)" class="form-control" name="projectName" required [ngClass]="showCssValidField(1, 'projectName')">
              </div>
              <div class="form-group col-12 col-md-4">
                <label for="category" class="required">Category</label>
                <select [(ngModel)]="_project.category" name="category" (change)="handleCategoryChange()" class="form-control" required [ngClass]="showCssValidField(1, 'category')">
                  <option value=""></option>
                  <option value="Consumer">Consumer</option>
                  <option value="B2B">B2B</option>
                  <option value="Healthcare">Healthcare</option>
                </select>
              </div>
              @if (litemode == false) {
                <div class="form-group col-12 col-md-2">
                  <label>Project Start Date</label>
                  <div class="input-group mb-3">
                    <input type="text" name="startDate" autocomplete="off"
                      readonly
                      [(ngModel)]="_project.projectStartDate"
                      bsDatepicker
                      #dp="bsDatepicker"
                      [bsValue]="_project.projectStartDate"
                      (input)="formChanged($event)"
                      (bsValueChange)="projectDetailsDateChanged($event, 'projectstartdate')"
                      [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                      class="form-control date d-inline bg-white"
                      [ngClass]="showCssValidField(1, 'startDate')">
                      <div class="input-group-append">
                        <button class="btn btn-light border" type="button" (click)="dp.toggle()"><i class="far fa-calendar-alt"></i></button>
                      </div>
                    </div>
                  </div>
                }
                @if (litemode == false) {
                  <div class="form-group col-12 col-md-2">
                    <label class="required">Project End Date</label>
                    <div class="input-group mb-3">
                      <input type="text" name="projectEndDate" autocomplete="off"
                        readonly
                        [(ngModel)]="_project.projectEndDate"
                        bsDatepicker
                        #dp="bsDatepicker"
                        [bsValue]="_project.projectEndDate"
                        (input)="formChanged($event)"
                        (bsValueChange)="projectDetailsDateChanged($event, 'projectenddate')"
                        [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                        class="form-control date d-inline bg-white"
                        required
                        [ngClass]="showCssValidField(1, 'projectEndDate')">
                        <div class="input-group-append">
                          <button class="btn btn-light border" type="button" (click)="dp.toggle()"><i class="far fa-calendar-alt"></i></button>
                        </div>
                      </div>
                    </div>
                  }
                  @if (litemode == false) {
                    <div class="form-group col-12 col-md-2">
                      <label class="required">Field Start Date</label>
                      <div class="input-group mb-3">
                        <input type="text" name="fieldStartDate" autocomplete="off"
                          readonly
                          [(ngModel)]="_project.fieldStartDate"
                          bsDatepicker
                          #dp="bsDatepicker"
                          [bsValue]="_project.fieldStartDate"
                          (input)="formChanged($event)"
                          (bsValueChange)="projectDetailsDateChanged($event, 'fieldstartdate')"
                          [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                          class="form-control date d-inline bg-white"
                          >
                          <div class="input-group-append">
                            <button class="btn btn-light border" type="button" (click)="dp.toggle()"><i class="far fa-calendar-alt"></i></button>
                          </div>
                        </div>
                      </div>
                    }
                    @if (litemode == false) {
                      <div class="form-group col-12 col-md-2">
                        <label class="required">Field End Date</label>
                        <div class="input-group mb-3">
                          <input type="text" name="fieldEndDate" autocomplete="off"
                            readonly
                            [(ngModel)]="_project.fieldEndDate"
                            bsDatepicker
                            #dp="bsDatepicker"
                            [bsValue]="_project.fieldEndDate"
                            (input)="formChanged($event)"
                            (bsValueChange)="projectDetailsDateChanged($event, 'fieldenddate')"
                            [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                            class="form-control date d-inline bg-white"
                            >
                            <div class="input-group-append">
                              <button class="btn btn-light border" type="button" (click)="dp.toggle()"><i class="far fa-calendar-alt"></i></button>
                            </div>
                          </div>
                        </div>
                      }
                      @if (litemode == true) {
                        <div  class="form-group col-12 col-md-4">
                          <div class="form-group">
                            <label>Status</label>
                            <div class="col-4 px-0 or-dropdown-status">
                              <or-dropdown-status [skipPartnerUpdate]="mode !== 'edit'" [status]="_project.projectStatus" (changed)="_project.projectStatus = $event;formChanged($event);"  [projectId]="_project.id" [autosave]="false" (updatePartners)="updatePartnerStatus($event, null)" ngDefaultControl></or-dropdown-status>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    <div class="row">
                      <div class="form-group col-12 col-md-4">
                        <label for="_projectclientName" class="required">Client</label>
                        <select (change)='handleClientChange($event.target.value); formChanged($event)' [(ngModel)]="_project.clientId" name="clientId" class="form-control" required [ngClass]="showCssValidField(1, 'clientId')">
                          <option></option>
                          @for (client of clients; track client) {
                            <option [value]="client.value"> {{ client.name }}</option>
                          }
                        </select>
                      </div>
                      <div class="form-group col-12 col-md-4">
                        <label for="_projectClientContact" class="">Client Contact @if (clientLoading) {
                          <div class="loading"></div>
                        }</label>
                        @if (_project.clientId) {
                          <a (click)="addNewContact(_project.clientId, 'client')" class="px-3">
                            <i class="fa fa-plus or-secondary cursor-pointer"></i>
                          </a>
                        }
                        <select [(ngModel)]="_project.clientContactId" (change)='formChanged($event)'  name="clientContactId" class="form-control {{ addContact[_project.clientId] ? 'd-none' : '' }}" [ngClass]="showCssValidField(1, 'clientContactId')">
                          <option></option>
                          @for (contact of clientContacts; track contact) {
                            <option [value]="contact.id">{{contact.firstName}} {{contact.lastName}}</option>
                          }
                        </select>
                        @if (addContact[_project.clientId]) {
                          <div class="overlay-contact">
                            <or-contact-card [contact]="newContact[_project.clientId]" mode="new" (new)="saveNewContact(_project.clientId, 'client')" (cancelled)='cancelNewContact(_project.clientId)'></or-contact-card>
                          </div>
                        }
                      </div>
                      @if (litemode == false) {
                        <div class="form-group col-12 col-md-4">
                          <label for="accountOwner" class="required">Account Owner @if (clientLoading) {
                            <div class="loading"></div>
                          }</label>
                          <or-type-ahead required [(ngModel)]="accountOwner" name="accountOwner" [labelField]="'name'" [valueField]="'id'" [dataCallback]="getUserList" [ngClass]="showCssValidField(1, 'accountOwner')"
                            (valueChange)="updateAccountOwner($event)"
                            placeholder="Type to search..."
                          [selectedValues]="accountOwner"></or-type-ahead>
                        </div>
                      }
                    </div>
                    <div class="row">
                      <div class="form-group col-12 col-md-4">
                        <label for="bidCountry">Country  <span data-html="true" tooltip="This field is used specifically to allow ‘tagging’ of this study so that a user can review all studies with this country ‘tagged’.  This does NOT provide any other capabilities or filtering of traffic in any way.  If you want to filter your traffic on a specific country, please go to ‘Quality Settings’ and enter that country’s 2-digit ISO code in the ‘Geo-Restriction’ field.">
                          <i class="fak nav-font16 fa-message-info grey3"></i>
                        </span></label>
                        <or-type-ahead
                          [selectedValues]=" countries "
                          (valueChange)="setCountries($event)"
                          [labelField]="'Country'" [multi]="true" [valueField]="'value'" [labelField]="'name'" [showLoading]="false" class="mr-2 w-100"
                          [dataCallback]="getCountryList"
                          placeholder="Type to Find"
                          >
                        </or-type-ahead>
                      </div>
                      <div class="form-group col-12 col-md-4">
                        <label>Tags</label>
                        @if (!saving) {
                          <or-input-multiple placeholder="Type and press Enter"  (input)="formChanged($event)"  [(selectedValuesString)]="tags"></or-input-multiple>
                        }
                      </div>
                      @if (litemode == false) {
                        <div class="form-group col-12 col-md-4">
                          <label class="required">Bid Number</label>
                          <input (input)="formChanged($event)" [(ngModel)]="_project.bidNumber" class="form-control" name="bidNumber" required [ngClass]="showCssValidField(1, 'bidNumber')">
                        </div>
                      }
                    </div>
                    <div class="row">
                      <div class="form-group col-12 col-md-4">
                        <label for="_projectManager" class="required">Project Manager</label>
                        <or-type-ahead required [(ngModel)]="projectManager" name="projectManager" (input)="formChanged($event)"  [labelField]="'name'" [valueField]="'id'" [dataCallback]="getUserList"
                        placeholder="Type to search..." (valueChange)="updatePm($event)" [selectedValues]="projectManager" [ngClass]="showCssValidField(1, 'projectManager')"></or-type-ahead>
                      </div>
                      <div class="form-group col-12 col-md-4">
                        <label for="_projectCategory">Secondary Project Manager</label>
                        <or-type-ahead  [labelField]="'name'" [multi]="true" [valueField]="'id'" (input)="formChanged($event)"  [dataCallback]="getUserList" placeholder="Type to search..." (valueChange)="updateTeam($event)" [selectedValues]="projectTeam"></or-type-ahead>
                      </div>
                      @if (projectType?.showFSSO) {
                        <div class="form-group col-12 col-md-4">
                          <label for="category" >Project Type</label>
                          <select [(ngModel)]="_project.type" name="type" (change)="handleTypeChange()" class="form-control" [ngClass]="showCssValidField(1, 'type')">
                            <option value=""></option>
                            <option value="FullService">Full Service</option>
                            <option value="SampleOnly">Sample Only</option>
                          </select>
                        </div>
                      }
                    </div>
                    @for (segment of _project.projectSegments; track segment; let segmentIdx = $index) {
                      <div class="row">
                        <div class="col-sm-12 col-lg-6">
                          <div class="form-group">
                            <label>Live URL</label>
                            <input [(ngModel)]="segment.surveyLink" (input)="formChanged($event)"  name="surveyLink" class="form-control">
                            @if (segment.surveyLink !='' && segment.surveyLink?.indexOf('#or1#') == -1) {
                              <div class="alert alert-warning mt-1" role="alert">You must have a variable set to #or1# so that you can track the unique IDs for this project</div>
                            }
                          </div>
                        </div>
                        <div class="col-sm-12 col-lg-6">
                          <div class="form-group">
                            <label>Test URL</label>
                            <input [(ngModel)]="segment.testSurveyLink" (input)="formChanged($event)"  name="testSurveyLink" class="form-control">
                            @if (segment.testSurveyLink !='' && segment.testSurveyLink?.indexOf('#or1#') == -1) {
                              <div class="alert alert-warning mt-1" role="alert">You must have a variable set to #or1# so that you can track the unique IDs for this project</div>
                            }
                          </div>
                        </div>
                      </div>
                    }
                  </form>
                  <div class="w-100 p-3" [hidden]="step != 2">
                    DEPRECATED
                  </div>
                  @if (step == 3) {
                    @if (mode === 'new') {
                      <div class="alert alert-info" role="alert">
                        <h4 class="alert-heading">Survey Quality Setup</h4>
                        <p>Configure the quality tools as required.</p>
                      </div>
                    }
                    @if (_project.projectStatus == 'Test') {
                      <div class="row">
                        <div class="alert alert-danger p-2 mx-3 w-100" role="alert">
                          This project is currently in 'Test' mode.  Deduplication and CleanID is disabled.
                        </div>
                      </div>
                    }
                    <div class="row">
                      <div class="col-lg-12 col-xl-7">
                        <div class="card mb-3">
                          <div class="card-header d-flex align-items-center bg-white border-bottom-0">
                            <span data-html="true" tooltip="Enable De-duplication to block repeat participants and devices.">
                              <i class="fak nav-font16 fa-message-info grey3"></i>
                            </span>
                            <span class="h6 or-primary mb-0 ml-2 w-100">De-Duplication</span>
                            <div class="d-flex justify-content-end w-100">
                              <or-toggle-button  class="light" size="small" (change)="formChanged($event)"  [(ngModel)]="_project.isDeDupeParticipantEnabled" name="isDeDupeParticipantEnabled"></or-toggle-button>
                            </div>
                          </div>
                          <div class="p-4">
                            <div class="qualitysettings">
                              <div>
                                <span data-html="true" tooltip="Duplication will only record dupes but will not block participants.">
                                  <i class="fak nav-font16 fa-message-info grey3"></i>
                                </span>
                              </div>
                              <div>
                                Tracking only
                              </div>
                              <div>
                                <or-toggle-button msgON="On" msgOFF="Off"  (change)="formChanged($event)"  size="small" [(ngModel)]="_project.isDupeTrackingOnly" name="isDupeTrackingOnly"></or-toggle-button>
                              </div>
                            </div>
                            <div class="mt-2">
                              <or-dedupe-status-setup [projectId]="_project.id" [dedupeList]="_project.projectDedupeStatus" (listChange)="_project.projectDedupeStatus = $event; formChanged($event)" ></or-dedupe-status-setup>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-xl-5 col-md-8">
                        <div class="card mb-4">
                          <div class="card-header d-flex align-items-center bg-white border-bottom-0">
                            <span data-html="true" tooltip="Enable Fraud Detection">
                              <i class="fak nav-font16 fa-message-info grey3"></i>
                            </span>
                            <span class="h6 or-primary mb-0 ml-2">CleanID</span>
                            @if (litemode == false) {
                              <div class="d-flex justify-content-end w-100">
                                <or-toggle-button class="light" size="small" [isChecked]="_project.isFraudDetectionEnabled" (changed)="_project.isFraudDetectionEnabled = $event; formChanged($event);" ></or-toggle-button>
                              </div>
                            }
                          </div>
                          <div class="p-4">
                            <div class="qualitysettings">
                              <div>
                                <span data-html="true" tooltip="Select the CleanID version to use for this project">
                                  <i class="fak nav-font16 fa-message-info grey3"></i>
                                </span>
                              </div>
                              <div>
                                Threshold
                              </div>
                              <div>
                                <select [(ngModel)]="_project.fraudDetectionThreshold"  (change)="formChanged($event)"  name="fraudDetectionThreshold" class="form-control form-control-sm">
                                  <option [ngValue]="1000">No Threshold</option>
                                  <option [ngValue]="30">Conservative (30)</option>
                                  <option [ngValue]="25">Moderate (25)</option>
                                  <option [ngValue]="20">Aggressive (20)</option>
                                </select>
                              </div>
                              <div>
                                <span data-html="true" tooltip="Allow mobile devices to enter this Survey.">
                                  <i class="fak nav-font16 fa-message-info grey3"></i>
                                </span>
                              </div>
                              <div>
                                Allow Mobile
                              </div>
                              <div>
                                <or-toggle-button msgON="On" msgOFF="Off" size="small"  (input)="formChanged($event)"  [(ngModel)]="_project.allowMobile" name="allowMobile"></or-toggle-button>
                              </div>
                              <div>
                                <span data-html="true" tooltip="Instead of running CleanID when the page opens the participant will be required to press continue first.">
                                  <i class="fak nav-font16 fa-message-info grey3"></i>
                                </span>
                              </div>
                              <div>
                                E-mail Security Page
                              </div>
                              <div>
                                <or-toggle-button msgON="On" msgOFF="Off" size="small" (input)="formChanged($event)"  [(ngModel)]="_project.showLandingPage" name="showLandingPage"></or-toggle-button>
                              </div>
                              <div>
                                <span data-html="true" tooltip="This field will filter all traffic via the ISO “Alpha-2 code” 2 letter country code that the device is located in.  When specified, the code will be validated against the postal database and a check against the device's IP address based country (IP Country Code) is performed.  We suggest you verify the code before entering by referencing: https://www.iban.com/country-codes .  Please note that you must enter a valid code for the geo-restriction.  An invalid code will result in all traffic being filtered out of your study.">
                                  <i class="fak nav-font16 fa-message-info grey3"></i>
                                </span>
                              </div>
                              <div>
                                Geo Restriction (Optional)
                              </div>
                              <div>
                                <input type="text" maxlength="2" [(ngModel)]="_project.fraudDetectionGeoCountry" name="fraudDetectionGeoCountry" class="form-control">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  @if (step == 4) {
                    @if (mode !== 'edit') {
                      <div class="alert alert-info" role="alert">
                        <h4 class="alert-heading"></h4>
                        <p>Set up the survey details. These are optional now and you can always come back later to update.</p>
                      </div>
                    }
                    <div class="row">
                      <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                          <label>Completes</label>
                          <input type="text" mask="99999" (input)="formChanged($event)"  [patterns]="integerPattern" (ngModelChange)="_project.fullLaunchQuota = $event === '' ? 0 : $event" [ngModel]="_project.fullLaunchQuota" name="fullLaunchQuota" (change)="updateSoftQuota(project.fullLaunchQuota, project, 'softLaunchQuota')" id="_projectQuota" class="form-control">
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                          <label>Soft Launch</label>
                          <input type="text" mask="99999" (input)="formChanged($event)"  [patterns]="integerPattern" (ngModelChange)="_project.softLaunchQuota = $event === '' ? 0 : $event" [ngModel]="_project.softLaunchQuota" name="softLaunchQuota" class="form-control">
                        </div>
                      </div>
                      <div class="col-xs-12 col-md-3">
                        <div class="form-group">
                          <label>NAV Allocation</label>
                          <or-toggle-button class="light" (input)="formChanged($event)"  size="small" [(ngModel)]="quotaMode"></or-toggle-button>
                        </div>
                      </div>
                      <!-- <div *ngIf="mode === 'edit'" class="col-xs-12 col-md-3">
                      <div class="form-group">
                        <label>Status</label>
                        <div class="col-4 px-0 or-dropdown-status">
                          <or-dropdown-status [status]="_project.projectStatus" (changed)="_project.projectStatus = $event;formChanged($event);"  [projectId]="_project.id" [autosave]="false" (updatePartners)="updatePartnerStatus($event, null)" ngDefaultControl></or-dropdown-status>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  @for (segment of _project.projectSegments; track segment; let segmentIdx = $index) {
                    @if (_project.projectSegments.length > 1) {
                      <div class="row">
                        <!-- we will only see this when we have more than one segment - we will also need segment name-->
                        <div class="form-group">
                          <label>Status</label>
                          <div class="col-6 px-0 or-dropdown-status">
                            <or-dropdown-status [values]="['Test', 'Soft Launch', 'Full Launch', 'Closed']" [status]="segment.segmentStatus" (changed)="segment.segmentStatus = $event;formChanged($event); " [autosave]="false" ngDefaultControl  (updatePartners)="updatePartnerStatus($event, segment)"></or-dropdown-status>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Quota</label>
                          <input type="text" [(ngModel)]="segment.fullSegmentQuota" name="fullSegmentQuota" (change)="updateSoftQuota(segment.fullSegmentQuota, segment, 'softSegmentQuota');formChanged($event);" class="form-control">
                        </div>
                        <div class="form-group">
                          <label>Soft Launch</label>
                          <input type="text"  (input)="formChanged($event)"  [(ngModel)]="segment.softSegmentQuota" name="softSegmentQuota" class="form-control">
                        </div>
                      </div>
                    }
                    <div class="row">
                      <div class="form-group col-3">
                        <label>Sell CPI</label>
                        <input type="text" (input)="formChanged($event)"  currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.', align: 'left' }" [(ngModel)]="segment.sellCPI" name="sellCPI" class="form-control">
                      </div>
                      <div class="form-group col-3">
                        <label>LOI</label>
                        <input type="text" (input)="formChanged($event)"  mask="99999" [patterns]="integerPattern" (ngModelChange)="segment.projectLOI = $event === '' ? 0 : $event" [ngModel]="segment.projectLOI" name="_projectLOI" class="form-control">
                      </div>
                      <div class="form-group col-3">
                        <label>IR %</label>
                        <input type="text" (input)="formChanged($event)"  suffix="%" mask="percent" (ngModelChange)="segment.projectIR = $event === '' ? 0 : $event" [ngModel]="segment.projectIR" name="_projectIR" class="form-control">
                      </div>
                    </div>
                  }
                }
                @if (step == 5) {
                  <div class="row">
                    <div class="col-lg-7 col-xl-8 mb-3">
                      <div class="card">
                        <div class="card-header d-flex align-items-center bg-white border-bottom-0">
                          <span class="h6 or-primary mb-0 w-100">Alert Settings</span>
                        </div>
                        <div class="p-4">
                          <div class="row align-items-baseline">
                            <div class="form-group col">
                              <label >NAV OQ's</label>
                              <input type="number" (input)="formChanged($event)"  [(ngModel)]="_project.alertNavOQ" name="alertNavOQ" class="form-control">
                            </div>
                            <div class="form-group col">
                              <label>DOR</label>
                              <input type="number" (input)="formChanged($event)" [(ngModel)]="_project.alertDropoutRate" name="alertDropoutRate" class="form-control">
                            </div>
                            <div class="form-group col">
                              <label>QC</label>
                              <input type="number" (input)="formChanged($event)" [(ngModel)]="_project.alertQCRate" name="alertQCRate" class="form-control">
                            </div>
                            <div class="form-group col">
                              <label>Pace</label>
                              <input type="number" (input)="formChanged($event)"  [(ngModel)]="_project.alertPaceMetric" name="alertPaceMetric" class="form-control">
                            </div>
                            <div class="form-group col">
                              <label>Days Remaining</label>
                              <input type="number" (input)="formChanged($event)" [(ngModel)]="_project.alertDeadlineRemainingDays" name="alertDeadlineRemainingDays" class="form-control">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div class="card-footer justify-content-end d-flex">
                @if (mode === 'new') {
                  @if (enabledSteps >= 2 && (step < 3 || (litemode == false && step < 5 ))) {
                    <div #spinner class="btn text-sm-center nav-btn-accent bg-white mr-1" (click)="saveProject()">Create Project</div>
                  }
                  @if (step > 1 ) {
                    <div class="btn text-sm-center nav-btn-accent bg-white mr-1" (click)="showStep(step - 1)">Back</div>
                  }
                  @if ((litemode== false && step < 5) || (litemode == true && step < 3)) {
                    <div class="btn text-sm-center nav-btn-accent text-white mr-1" (click)="showStep(step + 1)">Next</div>
                  }
                  @if (step == 5 || (litemode == true && step == 3) ) {
                    <or-spinner-button  label="Create Project" #spinner customClass="btn-secondary nav-btn-accent text-white mx-1" (callback)="saveProject()"></or-spinner-button>
                  }
                }
                @if (mode === 'edit') {
                  <or-spinner-button label="Save Changes" #spinner customClass="btn-secondary nav-btn-accent text-white mx-1" (callback)="saveProject()"></or-spinner-button>
                }
              </div>
            </div>
          </div>
        </div>
      }




