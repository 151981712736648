import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResolverService {

  baseUrl = environment.apiEndpoint + '/resolver/';

  constructor(private http: HttpClient) {}

  GetProjectCode(projectId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'projects/' + projectId,  { headers: { cache: '1' } });
  }

  GetProjectSummaryCode(bidNumber: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'projects/summary/' + bidNumber,  { headers: { cache: '1' } });
  }
}
