<div class="card border-0 my-2 mx-0 w-auto">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col-6">
        <h4 class="nav-secondary font-weight-bold mb-0 d-inline">Current Projects</h4>
      </div>
      <div class="col-6">
        <h4 class="nav-secondary font-weight-bold mb-0 d-inline">Tasks</h4>
      </div>
    </div>
  </div>

  <div class="card-body pt-2">
    <div class="row" style="height: 250px;">
      <div class="col-4">
        @if (highchartOptionsCurrentProjects) {
          <highcharts-chart
            id="projectsChart"
            [Highcharts]="highcharts"
            [options]="highchartOptionsCurrentProjects"
            style="width: 100%; height: 100%; display: block;">
          </highcharts-chart>
        }
      </div>
      <div class="col-2 mt-3" style="border-right: 2px solid #f5f5f5">
        <!-- <div class="row mb-3">
        <svg width="24" height="24">
          <rect width="24" height="24" rx="3" ry="3" [style.fill]="stageColorsList[0]" />
        </svg>
        <span class="nav-font-14">Status 1</span>
      </div>
      <div class="row mb-3">
        <svg width="24" height="24">
          <rect width="24" height="24" rx="3" ry="3" [style.fill]="stageColorsList[1]" />
        </svg>
        <span class="nav-font-14">Status 2</span>
      </div>
      <div class="row mb-3">
        <svg width="24" height="24">
          <rect width="24" height="24" rx="3" ry="3" [style.fill]="stageColorsList[2]" />
        </svg>
        <span class="nav-font-14">Status 3</span>
      </div> -->
      @for (item of stageColors; track item) {
        @if (this.stageCount[item[0]]) {
          <div class="row mb-3">
            <svg width="24" height="24">
              <rect width="24" height="24" rx="3" ry="3" [style.fill]="item[1]" />
            </svg>
            <span class="nav-font-14" (click)="legendClick(item[0])">{{ item[0] }}</span>
          </div>
        }
      }
    </div>
    <div class="col-4">
      @if (highchartOptionsTasks) {
        <highcharts-chart
          id="tasksChart"
          [Highcharts]="highcharts"
          [options]="highchartOptionsTasks"
          style="width: 100%; height: 100%; display: block;">
        </highcharts-chart>
      }
    </div>
    @if (totalTasks > 0) {
      <div class="col-2 mt-3">
        <ul>
          <div class="row mb-3">
            <svg width="24" height="24">
              <rect width="24" height="24" rx="3" ry="3" style="fill:#55BA2E;" />
            </svg>
            <span class="nav-font-14" (click)="legendClick('Open')">Open</span>
          </div>
          <div class="row mb-3">
            <svg width="24" height="24">
              <rect width="24" height="24" rx="3" ry="3" style="fill:#C72F1E;" />
            </svg>
            <span class="nav-font-14" (click)="legendClick('Overdue')">Overdue</span>
          </div>
        </ul>
      </div>
    }
  </div>
</div>
</div>
