import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AlertifyService, AuthService, RulesService, UserService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-project-alerts',
  templateUrl: './project-alerts.component.html',
  styleUrls: ['./project-alerts.component.scss']
})
export class ProjectAlertsComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.getRules();
    this.getProjectTeam();
  }

  @Input() public set bidNumber(data) {
    if (data != null) {
      this._bidNumber = data;
      //this.getRulesSummary();
    }
  };

  _project: any;
  _bidNumber = '';
  addMode = false;
  editMode = false;
  viewMode = 'pending';
  rules: any[] = null;
  isVendorview: boolean = false;
  fullList: any[] = [];
  users: any[];
  editRule: any = {};
  rollbackRule;
  emailTemplateSaved = '';
  filtersApplied = [];
  viewEmail = false;
  editEmail = false;
  userChangedEmail = false;
  projectUrl = window.location.origin + '/projects/';
  isClientview: boolean = false;
  filterValues: any = [];
  isClient: boolean = false;
  _tasks: any[] = [];

  editorConfig = {
    startupFocus: true,
    toolbar: {
      language: 'en',
      items: [
        'bold',
        'italic',
        'underline',
        'link',
        'bulletedList',
        'numberedList'
      ]
    }
  };

  @ViewChild('modalEditEmail') modalRefEditEmail: any;

  openModalRef: BsModalRef;

  @Output() onclose = new EventEmitter();
  @Output() callback = new EventEmitter();

  constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private userService: UserService,
    private modalService: BsModalService,
    private ruleService: RulesService
  ) { }

  ngOnInit() {
  }

  getProjectTeam() {
    this._project.projectTeam = this._project.projectTeam.map(e => ({
      name: e.displayName,
      projectRole: e.projectRole,
      userId: e.userId,
      email: e.email,
      slackUsername: e.slackUsername
    }));

    this._project.projectTeam.push({
      name: this.auth.getUser().name,
      projectRole: 'Me',
      userId: this.auth.getUser().id,
      email: this.auth.getUser().email,
      slackUsername: this.auth.getUser().social?.slackUsername
    });
  }

  getRules() {
    forkJoin([this.ruleService.GetRules(this._project.id), this.userService.GetUsers()]).subscribe((responseList) => {
      this.rules = responseList[0];
      this.users = responseList[1];
    }, error => {
      this.alertify.error('There was a problem getting Alerts.');
    }, () => {
      this.rules = this.rules.map(item => {
        item.isMine = item.createdBy === this.auth.getUser().id ? true : false;
        item.processed = item.ruleActions[0].processedTime !== null;
        item.triggered = item.ruleActions[0].triggered === true;
        item.name = item.ruleType == 'task' ? 'Task Alert' : item.name;
        item.userChangedEmail = item.emailTemplate ? true : false;

        if (!item.userChangedEmail && item?.ruleType !== 'task' && item?.ruleActions[0].alertMethod === 'Email') {
          this.handleEmailTemplate(item);
        }

        if (item.emailTemplate) {
          item.emailTemplate = item.emailTemplate.replaceAll(/<br>/g, '').replaceAll(/<p>/g, '').replaceAll(/<\/p>\s*/g, '');
        }

        const createdBy = this.users.filter(e => e.id === item.createdBy)
        const creationLabel = item?.isEdited ? 'edited' : 'created';
        const createdByName = (createdBy == null || createdBy.length == 0) ? '' : createdBy[0].firstName;
        item.lastUpdatedText = item.ruleType == 'scheduled' ? `Timed alert ${creationLabel} by ${createdByName} on` : (item.ruleType == 'live' ? `Live alert ${creationLabel} by ${createdByName} on` : `Task alert ${creationLabel} by ${createdByName} on`);

        return item;
      });

      this.rules = this.rules.sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime());
      this.fullList = this.rules;
      //this.filterList();
    });
  }

  addRule() {
    this.editRule = {};
    this.editRule.id = '';
    this.editRule.name = 'Alert ' + (this.fullList.length + 1).toString();
    this.editRule.projectId = this._project.id;
    this.editRule.createdByName = this.auth.getUser().name;
    this.editRule.sortKey = 0;
    this.editRule.scopeType = 'Select metric type';
    this.editRule.ruleType = 'live';  // 'live' | 'scheduled' | 'task'
    this.editRule.scopeId = this._project.id;
    this.editRule.rules = [{ metricType: 'Select metric', operator: null, sortKey: 0 }];
    this.editRule.ruleActions = [{ action: 'Alert', alertMethod: 'Select method', alertRecipientType: 'Select user', sortKey: 0 }];
    this.editRule.canSave = false;
    this.editRule.editing = true;
    this.addMode = true;
    this.editMode = false;
    this.callback.emit({ editing: true });
  }

  confirm(rule) {
    this.editRule = rule;

    if (!this.editRule) return;
    if (!this.editRule.scopeType || this.editRule.scopeType == undefined) {
      this.alertify.error('Please select a Scope for the Alert');
      return;
    }
    if (this.editRule.ruleType === 'task') {

    } else { // Original path before introduction of Task scope
      if (!this.editRule.rules[0].metricType || this.editRule.rules[0].metricType == undefined) {
        this.alertify.error('Please select a Metric for the Alert');
        return;
      }
      if (this.editRule.rules[0].value == undefined) this.editRule.rules[0].value = 0;
      if (this.editRule.rules[0].value == null) {
        this.alertify.error('Please provide a Metric Number for the Alert');
        return;
      } else {
        if (this.editRule.rules[0].value > 100) {
          let OK = true;
          switch (this.editRule.rules[0].metricType) {
            case 'IR':
            case 'DOR':
            case 'CID Blocks':
            case 'QCs':
            case 'Dupes':
              this.alertify.error('Please provide a Metric Percentage between 1 and 100!');
              OK = false;
              break;
          }
          if (!OK) return;
        }
      }
    }
    if (this.editRule.ruleType == 'scheduled' && (!this.editRule.ruleActions[0].scheduledTime || this.editRule.ruleActions[0].scheduledTime == null)) {
      this.alertify.error('Please provide a Date and Time for the Alert');
      return;
    }
    if (!this.editRule.rules[0].operator || this.editRule.rules[0].operator == undefined) {
      this.alertify.error('Please select an Operator for the Alert');
      return;
    }
    if (!this.editRule.ruleActions[0].alertMethod || this.editRule.ruleActions[0].alertMethod == undefined) {
      this.alertify.error('Please select an Alert Method for the Alert');
      return;
    }
    if (!this.editRule.ruleActions[0].alertRecipientType || this.editRule.ruleActions[0].alertRecipientType == 'Someone' || this.editRule.ruleActions[0].alertRecipientType == 'Select user') {
      this.alertify.error('Please select an Alert Recipient for the Alert');
      return;
    }

    if (!this.userChangedEmail) {
      this.editRule.emailTemplate = null;
    }

    // Add Rule
    if (!this.editRule.id) {
      this.ruleService.AddRule(this.editRule).subscribe(() => {
        this.editRule = {};
        this.getRules();
      }, error => {
        console.error(error);
        this.alertify.error('Unable to save Project alert');
      }, () => {
        this.viewEmail = false;
        this.editEmail = false;
        this.addMode = false;
        this.editMode = false;
        this.userChangedEmail = false;
        this.callback.emit({ editing: false });
        this.alertify.success('Project alert saved');
      });
    } else {
      this.ruleService.EditRule(this.editRule).subscribe(() => {
        this.editRule = {};
        this.getRules();
      }, error => {
        console.error(error);
        this.alertify.error('Unable to edit Alert');
      }, () => {
        this.viewEmail = false;
        this.editEmail = false;
        this.editMode = false;
        this.userChangedEmail = false;
        this.callback.emit({ editing: false });
        this.alertify.success('Project alert saved');
      });
    }
  }

  setChangesEvent(rule, event) {

    let ruleChanged = event;

    if (ruleChanged.condition === 'scope') {
      rule.scopeType = ruleChanged.newValue.label;
      rule.scopeId = ruleChanged.newValue.value;
    }
    if (ruleChanged.condition === 'metric') {
      rule.rules[0].metricType = ruleChanged.newValue;
    }
    if (ruleChanged.condition === 'metric-number') {
      rule.rules[0].value = parseInt(ruleChanged.newValue.value);
    }
    if (ruleChanged.condition === 'scheduled-date') {
      rule.ruleActions[0].scheduledTime = ruleChanged.newValue.value;
      console.log(rule.ruleActions[0].scheduledTime);
    }
    if (ruleChanged.condition === 'operator') {
      rule.rules[0].operator = ruleChanged.newValue.value;

      switch (rule.rules[0].operator) {
        case '=':
          rule.ruleType = 'live';
          break;
        case '>':
        case '<':
          rule.ruleType = 'scheduled';
          break;
      }
    }
    if (ruleChanged.condition === 'alertMethod') {
      rule.ruleActions[0].alertMethod = ruleChanged.newValue;
    }
    if (ruleChanged.condition === 'alertRecipient') {
      rule.ruleActions[0].alertRecipientType = ruleChanged.newValue;
    }

    if (!this.userChangedEmail && rule?.ruleType !== 'task' && rule.ruleActions[0].alertMethod === 'Email')
      this.handleEmailTemplate(rule);

    this.checkCanSave(rule);
  }

  checkCanSave(rule) {
    rule.canSave = true;

    if (!this.editMode && rule?.id) return;

    if (!rule?.name || rule?.name == undefined) {
      rule.canSave = false;
      return;
    }
    if (rule?.ruleType == 'scheduled' && (!rule?.ruleActions[0].scheduledTime || rule?.ruleActions[0].scheduledTime == null)) {
      rule.canSave = false;
      return;
    }
    if (!rule?.rules[0].operator || rule?.rules[0].operator == undefined) {
      rule.canSave = false;
      return;
    }
    if (!rule?.rules[0]?.value || rule?.rules[0]?.value == 0) {
      rule.canSave = false;
      return;
    }
    if (!rule?.ruleActions[0].alertMethod || rule?.ruleActions[0].alertMethod == undefined) {
      rule.canSave = false;
      return;
    }
    if (!rule?.ruleActions[0].alertRecipientType || rule?.ruleActions[0].alertRecipientType == 'Someone' || rule?.ruleActions[0].alertRecipientType == 'Select user') {
      rule.canSave = false;
      return;
    }

    rule.canSave = (rule.canAddRecipient || rule.canAddRecipient === undefined) ? true : false;
  }

  handleEmailTemplate(item) {
    const rule = item?.rules[0];

    let body: string;
    let stringOperator = rule.operator;

    if (stringOperator === "<") {
      stringOperator = "below";
    } else if (stringOperator === ">") {
      stringOperator = "above";
    }

    let metricType = rule.metricType?.toLowerCase() || '[metric]';

    const vendorName = this._project?.projectSegments[0].projectSurveyPartners.find(x => x.partnerId.toLowerCase() === item?.scopeId?.toLowerCase())?.partnerName;

    if (item?.ruleActions[0].alertRecipientType.split(',').some(x => x.toLowerCase() === 'vendor')) {
      body = `Hello ${vendorName}, On Project <a href='${this.projectUrl + item?.projectId} '>${this._project.projectCode} - ${this._project.projectName}</a>, your goal of ${metricType} is ${stringOperator} ${rule.value || 0}. Please contact ${this._project.pm} at ${this._project.pmEmail} referencing ${this._project.projectCode} for any questions`;
    } else {
      body = `On Project <a href='${this.projectUrl + item?.projectId} '>${this._project.projectCode} - ${this._project.projectName}</a>, your goal of ${metricType} is ${stringOperator} ${rule.value || 0}`;
    }

    if (vendorName && !item?.ruleActions[0].alertRecipientType.split(',').some(x => x.toLowerCase() === 'vendor')) {
      body += ` for ${vendorName}.`;
    }

    item.emailTemplate = body;
    //this.emailTemplateSaved = body;
  }

  saveEditEmail() {
    if (!this.editRule.emailTemplate) {
      this.handleEmailTemplate(this.editRule);
    }
    this.emailTemplateSaved = this.editRule.emailTemplate;
    this.userChangedEmail = true;
    this.openModalRef.hide();
    this.editEmail = false;
  }

  cancelEditEmail() {
    this.editRule.emailTemplate = this.emailTemplateSaved;
    this.openModalRef.hide();
    this.editEmail = false;
  }

  viewEmailMode(rule) {
    if (!this.userChangedEmail)
      this.handleEmailTemplate(rule);

    //this.viewEmail = !this.viewEmail;
    this.editRule = rule;
    this.emailTemplateSaved = rule.emailTemplate;
    this.openModalRef = this.modalService.show(this.modalRefEditEmail, { ignoreBackdropClick: true, keyboard: false, class: 'nav-modal-style edit-notification-modal' });
  }

  cancelAlert(rule) {
    this.ruleService.DeleteRule(rule.id).subscribe(() => {
      this.getRules();
    }, error => {
      this.alertify.error('Unable to delete Project alert');
      this.cancel();
    }, () => {
      this.alertify.success('Project alert deleted');
      this.cancel();
    });
  }

  cancel() {
    this.editRule = {};
    this.addMode = false;
    this.editMode = false;
    this.viewEmail = false;
    this.editEmail = false;
    this.userChangedEmail = false;
    this.emailTemplateSaved = '';
    this.callback.emit({ editing: false });
  }

  cancelEdit(rule) {
    this.rules[this.rules.findIndex(x => x.id === rule.id)] = this.rollbackRule;
    rule.editing = false;
    this.editMode = false;
    // if (!this.editRule.id) { // Cancel Add Action
    //   if (!this.editRule.copy) {
    //     this.editRule = {} as RuleHeader;
    //     this.addMode = false;
    //   }
    //   else
    //     this.rules.splice(this.editRule.index, 1); // Cancel Copy Action
    // } else {
    //   rule = JSON.parse(JSON.stringify(this.rollbackRule));
    //   this.editMode = false;
    // }
  }

  edit(rule) {
    this.rollbackRule = JSON.parse(JSON.stringify(rule));
    rule.editing = true;
    this.editMode = true;
    this.userChangedEmail = rule?.emailTemplate ? true : false;
    this.callback.emit({ editing: true });
  }

  copy(rule) {
    this.editRule = { ...rule };
    this.editRule.id = '';
    this.editRule.name = rule.ruleType == 'task' ? 'Task Alert' : `${rule?.scopeType} ${rule?.rules[0].metricType}`;
    this.editRule.createdByName = this.auth.getUser().name;
    this.editRule.editing = true;
    this.editRule.copy = true;
    this.editMode = true;
    this.emailTemplateSaved = this.editRule.emailTemplate;
    this.userChangedEmail = this.editRule?.emailTemplate ? true : false;
    this.callback.emit({ editing: true });
  }

  closePage(){
    this.onclose.emit(null);
  }

}
