import { Component, OnInit, Input } from '@angular/core';
import { s } from '@fullcalendar/core/internal-common';
import { ProjectService, AuthService, ProjectDataService } from 'core';
declare var google: any;

@Component({
  selector: 'app-project-card-survey-performance-table',
  templateUrl: './project-card-survey-performance-table.component.html',
  styleUrls: ['./project-card-survey-performance-table.component.scss']
})
export class ProjectCardSurveyPerformanceTableComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
  }

  @Input() mode = 'reduced';
  @Input() public set respondents(data) {
    this.processData(data);
  }
  @Input() viewStyle = "";

  _project: any;
  data: any;
  partners: Array<number>;
  states: Array<string>;
  dates: Array<any>;
  summary: any;
  tableData: any[];
  isVendorview: boolean = false;
  isClientview: boolean = false;
  viewByText = 'Date';
  viewByList = [];
  tableMode = 'inDate';
  loading = true;

  perfCardViewSettings = {
    showPreSurveyBreakdown: false,
    showInSurveyBreakdown: true,
    showKPIBreakdown: false,
    sortOrder: 'desc'
  };

  constructor(private projectService: ProjectService, private projectDataService: ProjectDataService, public auth: AuthService) { }

  ngOnInit() {
    this.projectDataService.getClientViewSidebar.subscribe(data => {
      if (data) {
        this.isClientview = data != '';
      }
    });
    this.perfCardViewSettings = this.projectService.getPerformanceCardView();
    this.sortDates();
    this.setTableData(this.tableMode);

    if (this.auth.isInternal() && !this.isClientview) {
      this.viewByList = [
        { id: 'inDate', name: 'Date' },
        { id: 'partner', name: 'Vendor' },
        { id: 'status', name: 'Launch Status' }
      ];
    } else {  // Client or Vendor
      this.viewByList = [
        { id: 'inDate', name: 'Date' },
        { id: 'status', name: 'Launch Status' }
      ];
    }
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.projectDataService.getVendorViewSidebar.subscribe(data => {
        if (data) {
          this.isVendorview = data != '';
        }
      });

    }, 500 * 1);
  }

  handlePerfCardViewSettings(type) {
    this.perfCardViewSettings.showPreSurveyBreakdown = (type === 'PreSurvey') ? !this.perfCardViewSettings.showPreSurveyBreakdown : this.perfCardViewSettings.showPreSurveyBreakdown;
    this.perfCardViewSettings.showInSurveyBreakdown = (type === 'InSurvey') ? !this.perfCardViewSettings.showInSurveyBreakdown : this.perfCardViewSettings.showInSurveyBreakdown;
    this.perfCardViewSettings.showKPIBreakdown = (type === 'KPISurvey') ? !this.perfCardViewSettings.showKPIBreakdown : this.perfCardViewSettings.showKPIBreakdown;
    this.projectService.setPerformanceCardView(this.perfCardViewSettings);
  }

  processData(data) {
    const handledpartners = {};
    const vendorList = [];
    const handledDates = {};
    const dateList = [];
    const handledStates = {};
    const stateList = [];
    let summary = { total: 0, starts: 0, complete: 0, navTerminate: 0, clientOverquota: 0, limitOverquota: 0, overquota: 0, clientTerminate: 0, clientQc: 0, autoQc: 0,cid: 0, mobileBlock: 0, vid: 0, dropoff: 0, dupe: 0, loi: 0 };

    data.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    data.forEach(e => {
      summary = this.addSummaryValues(summary, e, false);
      if (e.partner != null && e.vendor != null) {
        if (!handledpartners[e.partnerId]) {
          handledpartners[e.partnerId] = true;
          vendorList.push(e.partner);
        }
      }

      if (!handledDates[e.date]) {
        handledDates[e.date] = true;
        dateList.push(e.date.toString());
      }
      if (e.vendorSurveyState != null) {
        if (!handledStates[e.vendorSurveyState] && e.vendorSurveyState != null) {
          handledStates[e.vendorSurveyState] = true
          stateList.push(e.vendorSurveyState)
        }
      }
    });
    this.updateCalcs(summary);
    summary.loi = this.medianLOI(data);
    this.summary = summary;
    this.partners = vendorList;
    this.dates = dateList;
    this.sortDates();
    this.states = stateList;
    this.data = data;
    this.setTableData(this.tableMode);
    this.loading = false;
  }

  setTableData(mode) {
    const open = this.tableData == null ? [] : this.tableData.filter(e => e.open);
    this.viewByText = this.viewByList.find(x => x.id == mode)?.name;

    this.tableData = [];
    this.tableMode = mode;
    const lists = (mode === 'inDate') ? [this.dates, this.partners] : (mode === 'partner' ? [this.partners, this.dates] : [this.states, this.dates]);
    for (const topVal of lists[0]) {
      const topData = (mode === 'inDate') ?
        this.data.filter(e => e.date.toString() === topVal && e.vendorSurveyState == null) :
        (mode === 'partner' ? this.data.filter(e => e.partner === topVal && e.vendorSurveyState == null) : this.data.filter(e => e.vendorSurveyState === topVal && e.partner == null));
      let summary: any = { label: topVal, total: 0, starts: 0, complete: 0, navTerminate: 0, clientOverquota: 0, limitOverquota: 0, overquota: 0, clientTerminate: 0, clientQc: 0, autoQc: 0, cid: 0, mobileBlock: 0, vid: 0, dropoff: 0, dupe: 0, loi: 0, sub: [] };
      topData.forEach(e => {
        summary = this.addSummaryValues(summary, e, true);
      });
      summary.loi = this.medianLOI(topData);
      this.updateCalcs(summary);

      for (const sub of lists[1]) {
        const subData = (mode === 'inDate') ? topData.find(e => e.partner === sub && e.vendorSurveyState == null) :
          (mode === 'partner' ? topData.find(e => e.date.toString() === sub && e.vendorSurveyState == null) : topData.find(e => e.date.toString() === sub && e.vendorSurveyState != null));
        if (subData != null) {
          subData.label = sub;
          this.updateCalcs(subData);
          subData.loi = this.medianLOI([subData]);
          summary.sub.push(subData);
        }
      }
      if (open.find(e => e.label === summary.label) != null) {
        summary.open = true;
      }
      this.tableData.push(summary);
    }
  }

  addSummaryValues(summary, e, sub) {
    if (sub == false && e.partner != null) {
      summary.starts += e.starts;
      summary.complete += e.complete;
      summary.clientOverquota += e.clientOverquota;
      summary.limitOverquota += e.limitOverquota;
      summary.overquota += e.overquota;
      summary.navTerminate += e.navTerminate;
      summary.clientTerminate += e.clientTerminate;
      summary.clientQc += e.clientQc;
      summary.autoQc += e.autoQc ?? 0;
      summary.cid += e.cid;
      summary.mobileBlock += e.mobileBlock ?? 0;
      summary.dropoff += e.dropoff;
      summary.dupe += e.dupe;
    }
    else if (sub) {
      summary.starts += e.starts;
      summary.complete += e.complete;
      summary.clientOverquota += e.clientOverquota;
      summary.limitOverquota += e.limitOverquota;
      summary.overquota += e.overquota;
      summary.navTerminate += e.navTerminate;
      summary.clientTerminate += e.clientTerminate;
      summary.clientQc += e.clientQc
      summary.autoQc += e.autoQc ?? 0;
      summary.cid += e.cid;
      summary.mobileBlock += e.mobileBlock ?? 0;
      summary.dropoff += e.dropoff;
      summary.dupe += e.dupe;
    }
    return summary;
  }

  updateCalcs(data) {
    data.total = data.starts + data.cid + data.dupe + data.overquota + data.mobileBlock + data.navTerminate;
    data.qcRate = data.clientQc === 0 ? 0 : ((data.clientQc) / (data.complete + data.clientQc));
    data.autoQcRate = data.autoQc === 0 ? 0 : ((data.autoQc) / (data.complete + data.autoQc));
    data.ir = data.complete === 0 ? 0 : (data.complete) / (data.complete + data.clientTerminate);
  }

  medianLOI(data) {
    let lois = [];
    data.forEach(e => {
      if (e.completeLois != null) {
        lois = lois.concat(e.completeLois.map(f => f.loi));
      }
    });
    const LOIs = lois.sort((a, b) => a - b);
    if (LOIs.length === 0) { return 0; }

    const mid = Math.floor(LOIs.length / 2);
    const loi = LOIs.length % 2 !== 0 ? LOIs[mid] : (LOIs[mid - 1] + LOIs[mid]) / 2;
    return Math.round(loi / 1000);
  }

  showCellValue(val, percent) {
    if (val === null) {
      return isNaN(percent) || percent === 0 ? '-' : Math.round(percent * 100) + '%';
    } else if (percent == null) {
      return val === 0 ? '-' : val;
    }
    else {
      return val === 0 ? '-' : Math.round(percent * 100) + '%' + ' (' + val + ')';
    }
  }

  sortTable() {
    this.perfCardViewSettings.sortOrder = this.perfCardViewSettings.sortOrder === 'asc' ? 'desc' : 'asc';
    this.handlePerfCardViewSettings(null);
    this.sortDates();
    this.setTableData(this.tableMode);
  }
  sortDates() {
    if (this.perfCardViewSettings.sortOrder === 'desc' || this.perfCardViewSettings.sortOrder == null)
      this.dates = this.dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    else
      this.dates = this.dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }
  getPreColumnCount() {
    let count = 0;
    if (this.perfCardViewSettings.showPreSurveyBreakdown) {
      count = 3;
      if (this.summary.cid > 0) count++;
      if (this.summary.mobileBlock > 0) count++;
      if (this.summary.navTerminate > 0) count++;
    }
    return count;
  }
}
