@if (info) {
  <div class="p-3 mt-2 h-100">
    <h3 class="w-100 nav-primary text-center mb-1">Survey Info</h3>
    @if (isLive && this._settings.showTestWarningMessage==true) {
      <div class="card w-100 my-3">
        <div class="card-body testing-session-warning">
          <div class="w-100">
            <div class="d-flex justify-content-end align-content-center">
              <i class="far fa-close grey4 nav-font18" (click)="hideWarning()"></i>
            </div>
          </div>
          <div class="d-inline d-flex nav-error">
            <i class="fak fa-warning fa-md mr-2 nav-font14"></i>
            <h5 class=" my-0 mr-2 mb-1 pr-3">
              This testing session is occurring while you are in full launch mode. Testing data may end up in the live dataset.
            </h5>
          </div>
        </div>
      </div>
    }
    <div class="card w-100 my-3" [style.max-height]="'calc(100% - ' + heightToRemove + ')'">
      <div class="card-body">
        <h5 class="nav-secondary font-weight-bold mb-3">{{info.projects[0]?.projectCode}} Overview</h5>
        <div class="w-100 d-flex mb-2">
          <span class="grey5 font-weight-bold mr-2 text-nowrap">NavigatOR Code:</span>
          <span class="grey5">{{info.projects[0]?.projectCode}}</span>
        </div>
        <div class="w-100 d-flex mb-2">
          <span class="grey5 font-weight-bold mr-2 text-nowrap">Navigator Link:</span>
          <a href="{{info.projects[0]?.projectUrl}}" target="_blank" class="text-truncate"><i class="fas fa-xs fa-link text-secondary"></i>
          <small class="nav-accent-blue ml-2 font-weight-bold">{{info.projects[0]?.projectUrl}}</small>
        </a>
      </div>
      <div class="w-100 d-flex mb-2">
        <span class="grey5 font-weight-bold mr-2 text-nowrap">Survey Link:</span>
        <a href="{{info.surveyLink}}" target="_blank" class="text-truncate"><i class="fas fa-xs fa-link text-secondary"></i>
        <small class="nav-accent-blue ml-2 font-weight-bold">{{info.surveyLink}}</small>
      </a>
    </div>
    <div class="w-100 d-flex mb-2">
      <span class="grey5 font-weight-bold mr-2 text-nowrap">Project Status:</span>
      <span class="grey5">{{info.projects[0]?.projectStatus}}</span>
    </div>
    <hr class="px-3 nav-border1">
    <h5 class="nav-secondary font-weight-bold mb-3">Team Overview</h5>
    <div class="w-100 d-flex mb-2">
      <span class="grey5 font-weight-bold mr-2 text-nowrap">My Name:</span>
      <span class="grey5">{{this.auth.getUser().name}}</span>
    </div>
    <div class="w-100 d-flex mb-2">
      <span class="grey5 font-weight-bold mr-2 text-nowrap">My Role:</span>
      @if (this.auth.getUser().id === info.programmer?.userId) {
        <small class="text-truncate grey5">Programmer</small>
      }
      @if (this.auth.getUser().id !== info.programmer?.userId) {
        <small class="text-truncate grey5">Reviewer</small>
      }
    </div>
    <div class="w-100 d-flex mb-2">
      <span class="grey5 font-weight-bold mr-2 text-nowrap">Programmer:</span>
      <span class="grey5">{{info.programmers}}</span>
    </div>
    <div class="w-100 d-flex mb-2">
      <span class="grey5 font-weight-bold mr-2 text-nowrap">Reviewers:</span>
      <span class="grey5">{{info.reviewers}}</span>
    </div>
    <hr class="px-3 nav-border1">
    <h5 class="nav-secondary font-weight-bold mb-3">Survey Preferences</h5>
    <div class="row mb-2">
      <div class="col-4 d-flex align-items-center">
        <span class="grey5 font-weight-bold">Emulate</span>
        <span tooltip="Mirror the survey's experience on a device" container="body" placement="bottom" class="ml-2"><i class="fak fa-info grey4 font-weight-normal"></i></span>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <div class="toolbar-emulate">
          <span tooltip="Off" container="body" class="d-flex align-items-center justify-content-center px-3 cursor-pointer" (click)="desktopEmulation()">
            <i class="fak fa-none grey4 fa-md"></i>
          </span>
          <span tooltip="Desktop" container="body" class="d-flex align-items-center justify-content-center px-3 cursor-pointer" (click)="desktopEmulation()">
            <i class="fak fa-desktop grey4 fa-md"></i>
          </span>
          <span tooltip="Tablet" container="body" class="d-flex align-items-center justify-content-center px-3 cursor-pointer" (click)="tabletEmulation()">
            <i class="fak fa-ipad grey4 fa-md"></i>
          </span>
          <span tooltip="Phone" container="body" class="d-flex align-items-center justify-content-center px-3 cursor-pointer" (click)="phoneEmulation()">
            <i class="fak fa-iphone grey4 fa-md"></i>
          </span>
        </div>
      </div>
    </div>
    @if (this.client !== 'Other') {
      <div class="row">
        <div class="col">
          <span class="grey5 font-weight-bold">Show question number on survey</span>
        </div>
        <div class="col-3 d-flex justify-content-end align-items-center">
          <or-toggle-button class="light" [isChecked]="this._settings.showQuestion" size="xsmall" (changed)="sendMessageExtension('or_surveytester_showQuestion', $event)"></or-toggle-button>
        </div>
      </div>
    }
    @if (this.client !== 'Other') {
      <div class="row">
        <div class="col">
          <span class="grey5 font-weight-bold">Show Platform tools</span>
          <span tooltip="You may need to be logged on that platform to see full functionality" container="body" placement="bottom" class="ml-2"><i class="fak fa-info grey4 font-weight-normal"></i></span>
        </div>
        <div class="col-3 d-flex justify-content-end align-items-center">
          <or-toggle-button class="light" [isChecked]="this._settings.toggleDecipherToolbar" size="xsmall" (changed)="sendMessageExtension('or_surveytester_toggleDecipherToolbar', $event)"></or-toggle-button>
        </div>
      </div>
    }
  </div>
</div>
@if (info?.status == 'finalized') {
  <div class="col mt-4 text-center">
    <h4 class="st-primary">Survey testing for {{info.projects[0]?.projectCode}} was finalized by {{info?.finalizedBy}} on {{info?.lastUpdated | date: 'MMM d, y HH:mm' }}</h4>
  </div>
}
</div>
}
