import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertifyService, JumpAuthService, JumpDataFileFilter, JumpDataFileGenerationRequestBody, JumpDataFileInfo, JumpDataFileReport, JumpDataFileReportList, JumpDataFilesService, JumpDataFileStatus, OrDropdownOption, Project, SettingsService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { concatMap, forkJoin, interval, mergeMap, startWith, switchMap, takeWhile } from 'rxjs';


@Component({
  selector: 'app-project-folder',
  templateUrl: './project-folder.component.html',
  styleUrls: ['./project-folder.component.scss']
})
export class ProjectFolderComponent implements OnInit {

  @Input() project: Project;
  @ViewChild('modal') modalRef: any;
  openModalRef: BsModalRef;
  openModalRefContact: BsModalRef;
  openModalRefRateCard: BsModalRef;

  private _dataFiles: JumpDataFileReportList = { reports: [], reportCurrentlyGenerating: null };
  get dataFiles() {
    return this._dataFiles;
  }
  set dataFiles(value: JumpDataFileReportList) {
    this._dataFiles = value;
    this.completedDataFiles = this.dataFiles.reports.filter(report => report.status == JumpDataFileStatus.Completed);
    this.generatingReport = this.dataFiles.reports.filter(report => report.status == JumpDataFileStatus.Initialized || report.status == JumpDataFileStatus.Generating).map(report => report.report_file_id.toLowerCase());
  }

  completedDataFiles: JumpDataFileReport[] = [];
  dataFileInfo: JumpDataFileInfo = {
    filters: [],
    start_date: '',
    types: [],
    languages: [],
    purgeDate: ''
  };

  dataGenRequestBody: JumpDataFileGenerationRequestBody = {
    format: '',
    reportid: '',
    filter: '',
    language: '',
    starttime: '',
    endtime: ''
  };

  dataGenStartDate: Date = null;
  dataGenStartTime = '00:00:00';
  dataGenEndDate: Date = null;
  dataGenEndTime = '23:59:59';
  generatingReport: string[] = [];

  reportTypeDropdownValues: OrDropdownOption[] = [];
  filterDropdownValues: OrDropdownOption[] = [];
  languageDropdownValues: OrDropdownOption[] = [];

  private jst: string;
  private jpt: string;
  isJibunu: boolean = false;
  datafileLoading: boolean = false;
  jibunuLinkAuthenticated: boolean = false;

  percentageGen: number = 0;

  constructor(
    public modalService: BsModalService,
    private jumpDataFileService: JumpDataFilesService,
    private jumpAuthService: JumpAuthService,
    private alertify: AlertifyService
  ) {
  }

  ngOnInit() {
    this.jumpAuth();
  }

  jumpAuth() {
    this.isJibunu = this.project.projectSegments[0].surveyPlatForm == "Jibunu";
    if (!this.isJibunu)
      return;
    const path = this.project.projectSegments[0].testSurveyLink;
    this.datafileLoading = true;
    this.jumpAuthService.getJumpToken({ "path": path }).subscribe({
      next: (response) => {
        this.jst = response.jst;
        this.jpt = response.jpt;
        this.loadData();
      },
      error: (error) => {
        this.datafileLoading = false;
        this.jibunuLinkAuthenticated = false;
        console.error(error);
      }
    });

  }
  loadData() {
    forkJoin({
      reportList: this.jumpDataFileService.listDataFiles(this.jst, this.jpt),
      dataFileInfo: this.jumpDataFileService.getDataFileInfo(this.jst, this.jpt)
    }).subscribe({
      next: (results) => {
        this.dataFiles = results.reportList;
        this.dataFileInfo = results.dataFileInfo;
        this.setReportTypeDropdownValues();
        this.setFilterDropdownValues();
        this.setLanguageDropdownValues();
        this.datafileLoading = false;
        this.jibunuLinkAuthenticated = true;
        if (this.generatingReport.length > 0) {
          this.startPollingGenerationStatus();
        }
      },
      error: (error) => {
        console.error(error);
        this.datafileLoading = false;
        this.jibunuLinkAuthenticated = false;
      }
    })
  }

  setReportTypeDropdownValues() {
    this.reportTypeDropdownValues = this.dataFileInfo.types.map(type => {
      return {
        "name": type.text,
        "value": type.value
      }
    });
  }
  setFilterDropdownValues() {
    this.filterDropdownValues = this.dataFileInfo.filters.map(filter => {
      return {
        "name": filter.text,
        "value": filter.value
      }
    });
  }
  setLanguageDropdownValues() {
    this.languageDropdownValues = this.dataFileInfo.languages.map(language => {
      return language["name"] = {
        "name": language.text,
        "value": language.value
      }
    });
  }

  updateDataGenRequestBody(attribute: keyof JumpDataFileGenerationRequestBody, value: string) {
    this.dataGenRequestBody = {
      ...this.dataGenRequestBody,
      [attribute]: value
    };
  }
  prepareGenerateDataFile() {
    if (this.generatingReport.length == 0) {
      this.addingNewDataFile();
      this.openModalRef = this.modalService.show(this.modalRef as any, { ignoreBackdropClick: true, keyboard: false, class: 'nav-modal-new-vendor nav-modal-style' });
    }
  }

  generateDataFile() {
    this.updateRequestBody();
    this.jumpDataFileService.generateDataFile(this.jst, this.jpt, this.dataGenRequestBody).subscribe({
      next: (response) => {
        try {
          this.percentageGen = 0;
          this.generatingReport.push(response.reportFileId);
          this.closeModal();
          this.startPollingGenerationStatus();
        }
        catch (error) {
          this.alertify.error(`Data file generation failed at ${this.percentageGen}%. Please try again or contact admin.`);
        }
      },
      error: (error) => {
        console.error(error);
        this.alertify.error(`Data file generation failed at ${this.percentageGen}%. Please try again or contact admin.`);
        this.closeModal();
      }
    });
  }

  startPollingGenerationStatus() {
    if (this.generatingReport.length == 0)
      return;

    interval(5000)
      .pipe(
        startWith(0),
        switchMap(() => this.jumpDataFileService.listDataFiles(this.jst, this.jpt, this.generatingReport)),
        takeWhile(response => this.generatingReport.length > 0, false) // Stop when report is done generating
      )
      .subscribe(response => {
        if (this.generatingReport.length > 0) {
          // since we only allow one report to be generated at a time, we can safely assume that the first report in the list is the one being generated
          response.reports.forEach(report => {
            if (report.status != JumpDataFileStatus.Initialized && report.status != JumpDataFileStatus.Generating) {
              this.generatingReport = [];
              this.dataFiles.reports.push(report);
            }
            else {
              this.percentageGen = report.respondent_count == 0 ? 100 : Math.floor(report.finished_Respondent_Count / report.respondent_count * 100);
            }
          });
        }
      });
  }

  dataFileIsGenerating(file: JumpDataFileReport): boolean {
    return this.generatingReport.includes(file.report_file_id.toLowerCase());
  }

  updateRequestBody() {
    if (this.dataGenRequestBody.format === '') {
      throw new Error('Report type is required');
    }
    else {
      this.dataGenRequestBody.reportid = this.dataGenRequestBody.format;
    }
    if (this.dataGenStartDate === null) {
      // use file start time 
      this.updateDataGenRequestBody('starttime', this.dataFileInfo.start_date);
    }
    else {
      const startTime = this.dataGenStartDate.toLocaleDateString() + ' ' + this.dataGenStartTime;
      const isoDateStr = new Date(startTime).toISOString();
      this.updateDataGenRequestBody('starttime', isoDateStr);
    }

    if (this.dataGenEndDate === null) {
      this.updateDataGenRequestBody('endtime', new Date().toISOString());
    }
    else {
      const endTime = this.dataGenEndDate.toLocaleDateString() + ' ' + this.dataGenEndTime;
      const isoDateStr = new Date(endTime).toISOString();
      this.updateDataGenRequestBody('endtime', isoDateStr);
    }

    if (this.dataGenRequestBody.language == "0") {
      delete this.dataGenRequestBody.language;
    }
  }

  addingNewDataFile() {
    this.dataGenRequestBody.format = this.dataFileInfo.types.filter(item => item.text.toLowerCase() == "csv")[0].value;
    this.dataGenRequestBody.filter = this.dataFileInfo.filters[2].value;
    this.dataGenRequestBody.language = this.dataFileInfo.languages[0].value;
    this.dataGenStartDate = new Date(this.dataFileInfo.start_date);
    this.dataGenEndDate = new Date();
  }

  closeModal() {
    this.openModalRef.hide();
    this.openModalRef = null;
    this.dataGenRequestBody = {
      format: '',
      reportid: '',
      filter: '',
      language: '',
      starttime: '',
      endtime: ''
    };
  }

  formatDateFull(date: Date, timeZone: 'UTC' | null = null): string {
    const options: Intl.DateTimeFormatOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      ...timeZone ? { timeZone } : {}
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  formatDateShort(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  getFileGenDateInUTC(file: JumpDataFileReport): string {
    return this.formatDateFull(new Date(file.last_access_date), 'UTC');
  }

  getFileGenDateInLocalTime(file: JumpDataFileReport): string {
    return this.formatDateFull(new Date(file.last_access_date));
  }

  downloadDataFile(file: JumpDataFileReport, fileType: 'data' | 'layout' = 'data') {
    const reportId = file.report_file_id;
    this.jumpDataFileService.downloadDataFile(this.jst, this.jpt, fileType, reportId).subscribe(
      {
        next: (response) => {
          const contentDisposition = response.headers.get('Content-Disposition');
          let fileName = 'datafile'; // Default filename
          if (contentDisposition) {
            const matches = contentDisposition.match(/filename="(.+?)"/);
            if (matches?.length) {
              fileName = matches[1];
            }
          }
          const blob = new Blob([response.body], { type: 'application/zip' });

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      }
    );
  }

  changeReportType(event: JumpDataFileFilter) {
    console.log(event);
    this.updateDataGenRequestBody('format', event.value);
    console.log(this.dataGenRequestBody);
  }
  changeLanguage(event: JumpDataFileFilter) {
    console.log(event);
    this.updateDataGenRequestBody("language", event.value);
    console.log(this.dataGenRequestBody);
  }
  changeFilter(event: JumpDataFileFilter) {
    console.log(event);
    this.updateDataGenRequestBody("filter", event.value);
    console.log(this.dataGenRequestBody);
  }

  updateDatetime(attrName: "dataGenStartDate" | "dataGenEndDate", data) {
    this[attrName] = data;
  }



}
