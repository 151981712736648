@if (!loading) {
  @if (noClosureData) {
    <div class="container-fluid">
      @if (!vendorPricing.emailSent) {
        <div class="alert alert-warning d-inline-block mt-5 py-2" role="alert">
          <h3>This project could not find any closing data</h3>
        </div>
      }
    </div>
  }
  @if (!isClosed) {
    <div class="container-fluid">
      <div class="alert alert-warning d-inline-block mt-5 py-2" role="alert">
        <h3>This project is not closed so pricing cannot be confirmed at this time</h3>
      </div>
    </div>
  }
  @if (!noClosureData && isClosed) {
    <div class="container-fluid">
      @if (!isDisabled) {
        <div class="alert alert-warning d-inline-block py-2" role="alert">
          @if (vendorPricing.deadlineMessage != null && vendorPricing.deadlineMessage != '') {
            Please review your final IDs attached and confirm costs below by <strong>{{vendorPricing.deadlineMessage}}</strong><br />
          }
          Please reference <strong>{{vendorPricing.projectCode}}</strong> in your invoice to <u>financeteam&#64;opinionroute.com</u>
        </div>
      }
      <div class="card-body scrollbar overflow-y py-0 px-3">
        <div class="row">
          <button type="button" class="btn or-primary-bg text-white btn-sm mb-3 ml-auto mr-1" (click)="downloadIDs()">
            <i class="fas fa-download fa-sm cursor-pointer mr-2 opacity-70"></i>
            <span class="small">Download Complete IDs</span>
          </button>
          <button type="button" class="btn or-primary-bg text-white btn-sm mb-3 button-spacing" (click)="downloadQCs()">
            <i class="fas fa-download fa-sm cursor-pointer mr-2 opacity-70"></i>
            <span class="small">Download QCs</span>
          </button>
        </div>
        <div class="row border">
          <div class="col px-0">
            <table class="table mb-0">
              @if (totalCompletes > 0) {
                <thead class="thead-light">
                  <tr class="table-secondary">
                    <td colspan="2">Segment</td>
                    <td>Completes</td>
                    <td>CPI</td>
                    <td>Line Total</td>
                  </tr>
                </thead>
              }
              <tbody>
                @if (totalCompletes > 0) {
                  @for (pricing of vendorPricing?.pricing; track pricing) {
                    <tr class="table-light">
                      <td colspan="2">{{ pricing.segment }}</td>
                      <td class="text-center">{{ pricing.completes }}</td>
                      <td class="text-center">{{ pricing.buyCpi }}</td>
                      <td class="text-center">{{ pricing.total }}</td>
                    </tr>
                  }
                }
                @if (vendorPricing.expenses?.length > 0) {
                  @if (totalCompletes > 0) {
                    <tr class="table-secondary h6">
                      <td colspan="4" class="font-weight-normal text-left pl-5 py-2">Sub Total</td>
                      <td class="font-weight-normal py-2">{{ totalFee | currency}}</td>
                    </tr>
                  }
                  @if (totalCompletes > 0) {
                    <tr><td></td></tr>
                  }
                  <tr class="table-secondary">
                    <td>Description</td>
                    <td>Product</td>
                    <td>Qty</td>
                    <td>Unit Price</td>
                    <td>Line Total</td>
                  </tr>
                  @for (expense of vendorPricing.expenses; let idx=$index; track expense) {
                    @if (expense.isDeleted != true) {
                      <tr>
                        <td>{{ expense.description }}</td>
                        <td>{{ expense.product }}</td>
                        <td class="text-center">{{ expense.completes }}</td>
                        <td class="text-center">{{ expense.expenseCpi }}</td>
                        <td class="text-center">{{ expense.amount }}</td>
                      </tr>
                    }
                  }
                  @if (totalCompletes > 0) {
                    <tr class="table-secondary h6">
                      <td colspan="4" class="font-weight-normal text-left pl-5 py-2">Sub Total</td>
                      <td class="font-weight-normal py-2">{{ totalExpenses | currency }}</td>
                    </tr>
                  }
                }
                <tr class="text-white h5 border text-center table-primary">
                  <td class="text-left pl-5" colspan="4">Total</td>
                  <td>{{ totalFee + totalExpenses | currency}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      @if (!vendorPricing.emailSent) {
        <div class="alert alert-warning d-inline-block mt-5 py-2" role="alert">
          These details are not currently finalized for approval.
        </div>
      }
      @if (!isDisabled && vendorPricing.emailSent) {
        <div class="alert alert-warning d-inline-block mt-5 py-2" role="alert">
          Please <strong>accept</strong>, <strong>dispute</strong> or <strong>forward</strong> the final costs and IDs provided.
        </div>
      }
      <!-- nav tabs -->
      @if (!isDisabled && vendorPricing.emailSent) {
        <ul class="nav nav-tabs mt-2">
          <li class="nav-item">
            <a class="nav-link or-primary cursor-pointer" [class.active]="tab == 'Accept'" (click)="changeTab('Accept')">Accept </a>
          </li>
          <li class="nav-item">
            <a class="nav-link or-primary cursor-pointer" [class.active]="tab == 'Dispute'" (click)="changeTab('Dispute')">Dispute </a>
          </li>
        </ul>
      }
      @if (!isDisabled && vendorPricing.emailSent) {
        <div class="tab-content bg-light">
          <!-- Accept -->
          @if (tab == 'Accept') {
            <div class="tab-pane active border border-top-0 p-4">
              <div class="quickview-wrapper">
                <div class="row">
                  <div class="col ckeditor">
                    <or-wysiwyg (messageEvent)="receiveMessage($event)" [data]="newDecision.comment" [isDisabled]="isDisabled"></or-wysiwyg>
                  </div>
                </div>
                <div class="row mt-2 px-2">
                  <div class="col">
                    @if (!isDisabled) {
                      <or-upload smallMsg="Csv and Excel files only" mode="button" acceptTypes=".xlsx, .xls, .csv, .pdf" [instanceId]="instanceId" includeFileName=true S3Folder="external/{{externalId}}" directToS3=true [(ngModel)]="newDecision.attachment"></or-upload>
                    }
                  </div>
                  <div class="col pr-2 text-right">
                    @if (!isDisabled) {
                      <button type="button" (click)="addDecision('Accepted')" class="btn btn-sm btn-success opacity-90">Submit</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          <!-- Dispute -->
          @if (tab == 'Dispute') {
            <div class="tab-pane active border border-top-0 p-4">
              <div class="quickview-wrapper">
                <div class="align-items-center mb-1">
                  <small class="text-danger">(*) Comment is mandatory</small>
                </div>
                <div class="row">
                  <div class="col ckeditor">
                    <or-wysiwyg (messageEvent)="receiveMessage($event)" [data]="newDecision.comment" [isDisabled]="isDisabled"></or-wysiwyg>
                  </div>
                </div>
                <div class="row mt-2 px-2">
                  <div class="col">
                    @if (!isDisabled) {
                      <or-upload smallMsg="Csv and Excel files only" mode="button" acceptTypes=".xlsx, .xls, .csv, .pdf" [instanceId]="instanceId" includeFileName=true S3Folder="external/{{externalId}}" directToS3=true [(ngModel)]="newDecision.attachment"></or-upload>
                    }
                  </div>
                  <div class="col pr-2 text-right">
                    @if (!isDisabled) {
                      <button type="button" (click)="addDecision('Disputed')" class="btn btn-sm btn-danger opacity-90">Submit</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
      @if (decisions?.length > 0) {
        <div class="d-flex align-items-center mt-5 mb-1" >
          <div class="h5 opacity-70"><i class="fas fa-comments"></i> Comments</div>
        </div>
      }
      <div class="scrollbar overflow-y overflow-x-hidden">
        <ul class="nav">
          @for (decision of decisions; let noteIdx = $index; track decision) {
            <li class="my-2 w-100">
              <div class="note mb-1 w-100 border or-secondary"
                  [ngClass]="{
                    'note-success': decision.decision == 'Accepted' || decision.decision == 'Auto-Accepted',
                    'note-confirmed': decision.decision == 'Confirmed',
                    'note-danger': decision.decision == 'Disputed',
                    'note-info': decision.decision == 'Pending',
                    'note-reset': decision.decision == 'Reset'
                  }">
                <div class="mb-2">
                  @if (decision.email) {
                    <small class="mt-1 or-title">{{ decision.email }}</small>
                  }
                  <span class="note-date float-right mt-1">{{ decision.lastUpdated | date: 'MMM d, y HH:mm' }}</span>
                </div>
                @if (decision.comment == '' && decision.decision == 'Accepted') {
                  <p><strong>Accepted</strong> with no comment!</p>
                }
                @if (decision.comment != '') {
                  <p [innerHTML]="decision.comment"></p>
                }
                @if (decision.attachment !== null) {
                  <a class="nav-link p-0 small"  [href]="decision.attachment" target="_blank">
                    <button type="button" class="btn border-0 w-100 shadow-none px-0 text-left">
                      <i class="fas fa-download fa-sm cursor-pointer mr-2 opacity-70"></i>
                      <span class="small text-info">{{decision.fileName | lowercase}}</span>
                    </button>
                  </a>
                }
              </div>
            </li>
          }
        </ul>
      </div>
      <div class="text-muted text-center text-white my-3">
        <small>OpinionRoute ©2024</small>
      </div>
    </div>
  }
}
