import { Component, EventEmitter, AfterViewInit, OnInit, Output, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService, AlertifyService, ProjectService, ProjectDataService, UtilsService, UserService, AlertMessageService, InstanceService, DropdownService, environment, SupportAccessRequest } from 'core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, map, tap, forkJoin, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FilterPipe } from 'projects/core/src/helpers/filter.pipe';
import { PendoService } from 'projects/core/src/services/pendo.service';
import moment from 'moment';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  providers: [FilterPipe]
})
export class NavBarComponent implements OnInit, AfterViewInit, OnChanges {

  currentSection;
  isSuperAdmin = false;
  isProjectEnable = false;
  isORInstance = false;

  showHelp = false;
  logoSrc = '';

  impersonateFilterText = '';
  impersonateUsers: any[] = [];

  _newProject: any = {};
  projectCreateStep = 1;
  projectTypes = [];
  projectType = null;
  createType = 'quick';
  clients = [];
  currentUser = [];
  creatingProject = false;
  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };
  allowMobileConfirm: boolean = false;

  matches = [];
  projectToClone = null;
  cloneProjectClient = null;
  asyncSearch: string = '';
  dataSource: Observable<any>;
  allCopyOptionsSelected = false;

  defaultSectionUrl = '';
  navbarDropdownOpen = null;
  scrollValue = 0;

  supportExpiresOn: string = '';
  supportStatus: string = null;
  supportRequest: SupportAccessRequest;
  dontShowSupportDialog = false;

  @Input() isClientView = false;
  @Input() isVendorView = false;
  @Input() scrollX: number = 0;  // Receive scroll position from parent (document)

  @Output() valueChange = new EventEmitter();

  @ViewChild('modal') modalRef: any;
  @ViewChild('modalSuccess') modalRefConfirm: any;
  @ViewChild('modalSupportAccessOn') modalRefSupportAccessOn: any;
  @ViewChild('modalSupportAccessNew') modalRefSupportAccessNew: any;

  openModalRef: BsModalRef;

  @Output() onSave = new EventEmitter();

  constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private projectService: ProjectService,
    private projectDataService: ProjectDataService,
    public router: Router,
    private userService: UserService,
    private dropdownService: DropdownService,
    public instanceService: InstanceService,
    private utils: UtilsService,
    private alertService: AlertMessageService,
    private pendoService: PendoService,
    private location: Location,
    private modalService: BsModalService) {

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        let section = this.instanceService.GetCurrentSection();
        this.currentSection = section?.siteLabel;
      });
    }

  ngOnInit() {

    this.logoSrc = (this.auth.getUser().logo !== null) ? this.auth.getUser().logo : 'assets/imgs/navOR-logo.png';

    this.alertService.connectWebSocket();
    this.isSuperAdmin = this.auth.isSuperAdmin();
    this.isORInstance = this.auth.isORInstance();

    // Subscribe to open new support modal dialog
    this.alertService.newMessageReceived.subscribe(data => {
      if (data.action == 'support' && data.data === this.auth.getUser().id) {
        this.openModalSupportNew();
      }
    });

    this.getSiteNavigation();
    this.initNavBarEventListeners();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scrollX) {
      this.updateScrollPosition(changes.scrollX.currentValue);
    }
  }

  updateScrollPosition(scrollX: number) {
    const navbarContent = document.getElementById('navbar');
    if (navbarContent) {
      navbarContent.scrollLeft = scrollX;  // Apply scroll position to navbar
    }
  }

  ngAfterViewInit() {

    setTimeout(() => {
      this.projectDataService.getClientViewSidebar.subscribe(data => {
        if (data) {
          this.isClientView = data != '';
        }
      });
      this.projectDataService.getVendorViewSidebar.subscribe(data => {
        if (data) {
          this.isVendorView = data != '';
        }
      });

      this.getSupportRequest();

    }, 200 * 1);

    setTimeout(() => {
      if (window['pendo'] != null) {
        const user = this.auth.getUser();
        const sectionNames = [...new Set(this.instanceService.siteSections.filter(e => e.type != null).map(e => e.type))];
        if (user != null) {
          this.pendoService.init(user.id, user.email, user.firstName + ' ' + user.lastName, user.instanceId, user.instanceName, user.roles, sectionNames);
        }
      }
    }, 500 * 1);
  }

  getSupportRequest() {
    this.userService.GetSupportRequest(this.auth.getUser().id).subscribe(data => {
      if (data != null) {
        this.supportRequest = data;

        if (this.supportRequest.accessExpiresTime) {
         let expiresTime = moment.utc(this.supportRequest.accessExpiresTime).utcOffset(this.supportRequest.accessExpiresTimeZone);
         this.supportExpiresOn = `${expiresTime.format('hh:mm A')} on ${expiresTime.format('MM/DD/YYYY')}`;
        }

        if (this.supportRequest.isEnabled) {
          if (this.supportRequest.status === "Approved") {
            if (localStorage.getItem('supportOn') == null || localStorage.getItem('supportOn') != this.supportRequest.id) {
              this.supportStatus = 'on';
            }
          }
          if (this.supportRequest.status !== "Approved") {
            if (localStorage.getItem('supportRequest') == null || localStorage.getItem('supportRequest') != this.supportRequest.id) {
              localStorage.setItem('supportRequest', this.supportRequest.id);
              this.supportStatus = 'new';
            }
           }
        }
      }

      // Support request dialogs
      if (this.supportStatus == 'new') {
        this.openModalSupportNew();
      }

      if (this.supportStatus == 'on') {
        var currentPath = this.location.path();
        if(!currentPath.includes('/account/support'))
          this.openModalSupportOn();
      }

    });
  }
  closeSupportModal(){
    if(this.dontShowSupportDialog){
      localStorage.setItem('supportOn', this.supportRequest.id);
    }
    this.openModalRef.hide();
  }
  getSiteNavigation() {
    this.instanceService.getUserSections().subscribe(data => {
      if (data.length === 0) {
        this.alertify.message('Site Sections have not been setup');
      } else {

        this.instanceService.SetupSiteSections(data);

        this.isProjectEnable = (this.auth.getUser().access?.projectEnable && this.instanceService.siteSections?.some(x => x.type === 'ProjectList'));
        this.defaultSectionUrl = this.instanceService.siteSections[0].url;

        let section = this.instanceService.GetCurrentSection();
        this.currentSection = section?.siteLabel;

        let path = this.location.path();
        if (path == null || path == '') {
          this.router.navigate([this.defaultSectionUrl]);
        } else if (path.includes('?returnUrl')) {
          const arrReturnUrl = decodeURIComponent(this.location.path()).replace('?', '').split('=');
          if (arrReturnUrl.length > 0 && arrReturnUrl[1] !== null) {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([arrReturnUrl[1]]);
            });
          }
        }

      }
    }, error => {
      this.alertify.error('Unable to get Sections');
    }, () => {

    });
  }

  goToFirstSection() {
    if (this.router.url.includes(this.defaultSectionUrl)) {
      this.router.navigate(['/']).then(() => {
        this.router.navigate([this.defaultSectionUrl]);
      });
    } else {
      this.router.navigate([this.defaultSectionUrl]);
    }
  }

  initNavBarEventListeners() {
    //subscribe to any action within navbar (from anywhere)
    this.projectDataService.getActionNavBar.subscribe(data => {
      if (!data) return;

      const type = data?.type;
      // clone project from project list
      if (type == 'clone') {
        this.openCreateProjectDialog('inlineClone', data?.project);
      }
      else if (type == 'addToFamily') {
        if (data?.familyConfig) {
          this.openCreateProjectDialog('addToFamily');
          this.createType = 'clone';
          this._newProject.bidNumber = data.familyConfig.bidNumber;
          this.asyncSearch = data.familyConfig.familyCode;
          this.getMatches();
        }
      }
    });
  }
  globalSearch = (filter) => {
    return this.projectService.GlobalSearch(filter)
      .pipe(
        tap(results => {
          const uniqueProjectCodes = [];
          const handledCodes = {};
          // Add existing codes to the set if unqiue
          results.filter(e => e.bidNumber != '').forEach(e => {
            let summary = { ...e };
            if (handledCodes[summary.bidNumber] != true) {
              var projects = results.filter(e => e.bidNumber == summary.bidNumber);
              var name = this.utils.longestCommonSubstring(projects?.map(p => p?.projectName), null);

              handledCodes[summary.bidNumber] = true;
              summary.projectName = 'Summary: ' + name;
              summary.projectCode = summary.bidNumber;
              summary.type = "summary";
              uniqueProjectCodes.push(summary);
            }
          }
          );

          // Filter and add new codes to the set
          Array.prototype.unshift.apply(results, uniqueProjectCodes);

        }),
        map(results => results)
      );
  }
  selectedSearch(value) {
    if (value.type == 'summary') {
      const navigateUrl = '/projects/summary/' + value?.bidNumber;
      this.router.navigate([navigateUrl]);
    }
    else {
      const projectId = value?.id;
      const navigateUrl = '/projects/' + projectId;
      this.router.navigate([navigateUrl]);
    }
  }

  openModalSupportOn() {
    this.openModalRef = this.modalService.show(this.modalRefSupportAccessOn, { ignoreBackdropClick: true, keyboard: false, class: 'nav-modal-style' });
  }

  openModalSupportNew() {
    this.openModalRef = this.modalService.show(this.modalRefSupportAccessNew, { ignoreBackdropClick: true, keyboard: false, class: 'nav-modal-style' });
  }

  openImpersonate(modalTemplate) {
    this.loadImpersonateUsers();
    this.openModalRef = this.modalService.show(modalTemplate, { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg' });
  }

  loadImpersonateUsers() {
    if (this.isSuperAdmin) {
      this.impersonateUsers = [{ email: 'Loading... Please wait while we load the list of users', value: '' }];
      this.userService.getImpersonationUsers().subscribe(data => {
        data.forEach(e => {
          if (e.clientName != null && e.clientName !== '') {
            e.userType = 'Client';
            e.companyName = e.clientName
          } else if (e.vendorName != null && e.vendorName !== '') {
            e.userType = 'Vendor';
            e.companyName = e.vendorName
          } else e.userType = 'Internal';
        });
        this.impersonateUsers = data;
      }, error => { }, () => { });
    }
  }

  impersonate(row) {
    this.auth.impersonate(row.email, row.instanceId).subscribe(next => {
    }, error => {
      this.alertify.error(error);
    }, () => {
      this.alertify.success('Your are now impersonating');
      window.location.reload();
    });
  }

  goToAdminConsole(event: Event) {
    event.preventDefault();
    const url = environment.adminSiteUrl + '?token=' + this.auth.getToken();
    window.open(url, '_blank');
  }

  logout() {
    this.auth.logOut();
    this.router.navigate(['/login']);
    this.alertify.message('You are now logged out');
  }

  // PROJECT CREATION
  openCreateProjectDialog(type = "", sourceProject = null) {

    //Set default values
    this.projectCreateStep = 1;
    this._newProject = {};
    this._newProject.userId = this.auth.getUser().id;
    this.currentUser = [this.auth.getUser()];

    this.createType = 'quick';
    this.asyncSearch = '';
    this.projectType = null;
    this.cloneProjectClient = null;
    this.allCopyOptionsSelected = false;
    this.selectAllCopyOptions(false)


    forkJoin([
      this.projectService.GetProjectTypes(null, false),
      this.dropdownService.getClients()
    ]).subscribe((responseList) => {
      this.projectTypes = responseList[0].sort((a, b) => a.name.localeCompare(b.name));
      this.clients = responseList[1];

      if (this.projectTypes.length === 1) {
        this.projectType = this.projectTypes[0];
      };

      if (type == 'inlineClone' && sourceProject != null) {
        this.projectCreateStep = 2;
        this.createType = 'clone';
        this.selectProjectToClone(sourceProject);
        this.asyncSearch = sourceProject?.projectCode;
      }
      this.getMatches(true);
      this.openModalRef = this.modalService.show(this.modalRef as any, { ignoreBackdropClick: true, keyboard: false, class: 'project-setup-step1 nav-modal-style' });

    }, error => {
      this.projectCreateStep = 0;
      this.alertify.error('There was a problem getting project details.');
    });

    //if we are in an existing project then we set the clone search to the project name
    if (this.router.url.includes('projects') && type != 'addToFamily') {
      let urlDetails = this.router.url.split('/');
      if (urlDetails.length > 2 && urlDetails[2] != 'summary'&& urlDetails[2] != 'list') {
        this.projectService.GetProject(urlDetails[2], '').subscribe(data => {
          this.asyncSearch = data.projectName;
          this.getMatches(true);
        }, error => {
        });
      }
    }
  }
  createNewProject() {
    if (this.creatingProject == true)
      return;

    this.creatingProject = true;

    if (this.createType == 'clone') {
      if (!this._newProject.clientId && this.cloneProjectClient != null) {
        this._newProject.clientId = this.cloneProjectClient[0].value
      }
    }
    else {
      this._newProject.cloneProjectid = null;
    }

    if (this._newProject.projectStartDate_dt != null) {
      this._newProject.projectStartDate_dt.setHours(12);
      this._newProject.projectStartDate = new Date(this._newProject.projectStartDate_dt.getUTCFullYear(), this._newProject.projectStartDate_dt.getUTCMonth(), this._newProject.projectStartDate_dt.getUTCDate(), 12).toISOString()
    }

    this.projectService.CreateProject(this._newProject).subscribe(data => {
      if (data) {
        this._newProject = data;
        this.router.navigateByUrl('/projects/' + data.id + '/schedule');
      } else {
        this.alertify.error('There was a problem saving this project.');
      }
    }, error => {
      this.creatingProject = false;
      this.alertify.error('There was a problem saving this project.');
    }, () => {
      this.creatingProject = false;
      this.closeCreateProjectDialog();

      setTimeout(() => {
        this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'nav-modal-style' });
      }, 100);

    });
  }

  closeCreateProjectDialog() {
    this.openModalRef.hide();
    this.openModalRef = null;
  }

  selectProjectType(type, createType) {
    this.projectType = null;
    this.createType = createType;

    this.projectTypes.map(item => {
      item.selected = false;
    });

    if (type !== null) {
      type.selected = true;
      this.projectType = type;
    }
  }

  createProjectNext() {

    if (this.createType == 'quick') {
      if (!this._newProject.projectName) {
        this.alertify.error('Please fill in all required fields');
        return;
      }
      // validation for OR instance
      if (this.isORInstance) {

        if (this.projectCreateStep === 1 && !this._newProject.clientId) {
          this.alertify.error('Please fill in all required fields');
          return;
        } else if (
          this.projectCreateStep === 2 &&
          (
            !this._newProject.projectStartDate ||
            !this._newProject.projectEndDate ||
            !this._newProject.fullLaunchQuota || this._newProject.fullLaunchQuota <= 0 ||
            !this._newProject.projectLOI || this._newProject.projectLOI <= 0 ||
            !this._newProject.projectIR || this._newProject.projectIR <= 0
          )
        ) {
          this.alertify.error('Please fill in all required fields');
          return;
        }
      }
    }

    if (this.createType == 'quick')
      this._newProject.projectTypeId = this.projectType.id;

    if (this.projectCreateStep === 1) {
      this.projectCreateStep = 2;

      const modalClass = (this.createType == 'quick') ? 'project-setup-quick-step2' : 'project-setup-clone-step2';
      this.openModalRef?.setClass(`${modalClass} nav-modal-style`);
    }
    else
      this.createNewProject();
  }
  createProjectBack() {
    this.projectCreateStep = 1;
    this.openModalRef?.setClass('project-setup-step1 nav-modal-style');
  }

  getClients = () => {
    return of(this.clients);
  }
  assignClient(evt) {
    if (evt.length > 0) {
      this._newProject.clientId = evt[0].value;
    } else
      this._newProject.clientId = null;
  }
  getUserList = (filter) => { return this.userService.GetUsers(); }
  assignPM(pm) {
    this._newProject.userId = null;
    if (pm?.length > 0) {
      this._newProject.userId = pm[0]?.id;
    }
  }
  updateAllowMobile(value) {
    //call service to update the project field allowMobile
    this.allowMobileConfirm = true;
    this.projectService.UpdateProjectValue(this._newProject.id, 'allowMobile', value).subscribe(data => {
    }, error => {
      this.alertify.error('There was a problem updating the allow mobile setting.');
    });

  }
  goToSettings() {
    this.router.navigateByUrl('/projects/' + this._newProject.id + '/settings');
    this.closeCreateProjectDialog();
  }

  //CLONING
  getMatches(preSelected = false) {
    this.copySearch(this.asyncSearch).subscribe((items: any) => {
      this.matches = items
      if (!preSelected) this.projectToClone = null
    });
  }
  copySearch = (filter) => { return this.projectService.CopySearch(filter); }

  selectProjectToClone(project, event = null) {
    if (project != null) {
      this.projectToClone = project;
      this._newProject.cloneProjectid = this.projectToClone.id;
      this._newProject.projectName = this.projectToClone.projectName + ' - Copy';
      this._newProject.projectTypeId = this.projectToClone.projectTypeId;
      const client = this.clients.filter(x => x.name == this.projectToClone.clientName);
      this.cloneProjectClient = (client != null && client.length > 0) ? [{ name: this.projectToClone.clientName, value: client[0].value }] : null;
    }
  }
  // check if all clone options are selected
  checkIfAllSelected(checked) {
    if (this.allCopyOptionsSelected && !checked) this.allCopyOptionsSelected = false
    else if (checked &&
      (!this.projectToClone.hasSchedule || this._newProject.copySchedule) &&
      (!this.projectToClone.hasQuotasSetup || this._newProject.copyQuotas) &&
      this._newProject.copySurveyLinks &&
      this._newProject.copyVendors &&
      this._newProject.copyTeam &&
      this._newProject.copyDedupeProjects &&
      this._newProject.includeSourceProjectInDedupe
    ) {
      this.allCopyOptionsSelected = true
    }
  }

  // select all clone options
  selectAllCopyOptions(checked) {
    if (!checked || this.projectToClone?.hasSchedule) this._newProject.copySchedule = checked;
    if (!checked || this.projectToClone?.hasQuotasSetup) this._newProject.copyQuotas = checked;
    this._newProject.copySurveyLinks = checked;
    this._newProject.copyVendors = checked;
    this._newProject.copyTeam = checked;
    this._newProject.copyDedupeProjects = checked;
    this._newProject.includeSourceProjectInDedupe = checked;
  }
  cloneProject() {
    this.allowMobileConfirm = false;
    if (this.projectCreateStep == 1) {

      if (!this._newProject.cloneProjectid) {
        this.alertify.error('Please search and select a existing project to copy');
        return;
      }
      this.projectCreateStep = 2;
    } else if (this.projectCreateStep == 2) {
      this.createNewProject();
    }
  }

  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event: MouseEvent) {
  //   const clickedElement = event.target as HTMLElement;
  //   if (clickedElement.classList.contains('hasDropdown')) return;
  //   if (this.navbarDropdownOpen) {
  //     this.navbarDropdownOpen.hide();
  //     this.navbarDropdownOpen = null;
  //   }
  // }
}



