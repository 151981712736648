<div class="container-fluid mb-5 nav-content">
  <!-- BREADCRUMBS -->
  <div class="row">
    <div class="col-12 breadcrumb mr-auto bg-transparent">
      <div class="d-flex align-items-center">
        <div class="grey4 d-flex align-items-center">
          <app-pagetitle></app-pagetitle>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col d-flex align-items-center">
      @if (isVendorAdmin || isAdmin) {
        <button class="btn nav-btn-accent shadow-none font-weight-bold mr-3 nav-font14" (click)="teamAdd()">Add Team Member</button>
      }
      <div class="input-group copy-search">
        <div class="input-group-prepend">
          <div class="input-group-text border-right-0">
            <i class="fas fa-search cursor-default"></i>
          </div>
        </div>
        <input [(ngModel)]="filterText" type="search" class="form-control form-control-sm" (ngModelChange)="updateList()"
          placeholder="Search ...">
        </div>
      </div>
    </div>

    <div class="scrollbar overflow-x" width="100%">
      <table class="table team table-hover table-light nav-font14 familyTable mb-0">
        <thead class="grey5">
          <tr>
            <th width="260" (click)="SortBy('name')"><span class="ml-3">Name </span><i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="200"(click)="SortBy('companyTitle')">Company Title <i class="fas fa-sm or-secondary fa-sort "></i></th>
            <th width="200" (click)="SortBy('team')">Team <i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="200">Phone Number</th>
            <th width="150" (click)="SortBy('email')">E-mail <i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="150">Last Active</th>
            @if (isVendorAdmin || isAdmin) {
              <th width="72" class=" text-center">Options </th>
            }
          </tr>
        </thead>
        <tbody class="bg-white grey5">
          @for (member of team; track member) {
            <ng-container class="row">
              <tr>
                <td>
                  <div class="d-flex align-items-center ml-3">
                    <or-profile-image showOnHover="true" [name]="member.name" [url]="member.image" showInitials="true"></or-profile-image>
                    <span class="p-0 text-truncate ml-3"> {{ member.name }}</span>
                  </div>
                </td>
                <td>
                  <span class="p-0 text-truncate grey5">{{ member.companyTitle }}</span>
                </td>
                <td>
                  <span class="p-0 text-truncate grey5">{{ member.team }}</span>
                </td>
                <td>
                  <span class="p-0 text-truncate grey5">{{ member.fullPhone }}</span>
                </td>
                <td>
                  <span class="p-0 text-truncate grey5">{{ member.email }}</span>
                </td>
                <td>
                  <span class="p-0 text-truncate grey5">{{ member.lastLogin | date: 'MMM d, y' }}</span>
                </td>
                @if (isVendorAdmin || isAdmin) {
                  <td class=" text-center">
                    <div class="btn-group btn-group-sm" dropdown container="body">
                      <button dropdownToggle type="button" class="btn btn-outline-none" controls="dropdown-basic">
                        <i class="fak fa-lg fa-ellipsis-vertical grey4"></i>
                      </button>
                      <div *dropdownMenu class="dropdown-menu dropdown-menu-right cursor-pointer" role="menu" aria-labelledby="button-basic">
                        <a class="dropdown-item" role="menuitem" (click)="teamEdit(member)">Edit User</a>
                        <a class="dropdown-item" role="menuitem" (click)="teamDelete(member)">Delete User</a>
                      </div>
                    </div>
                  </td>
                }
              </tr>
            </ng-container>
          }
        </tbody>
      </table>
    </div>

    @if (showLoadMore) {
      <button class="nav-btn-primary shadow-none w-100 mb-3 mt-3" (click)="showMore()">Load all</button>
    }

  </div>


  <ng-template #modalTeam>
    <div class="modal-header">
      <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">{{ model?.id ? 'Edit Team Member' : 'Add Team Member' }}</h2>
      <div class="d-flex justify-content-end">
        <i class="fas fa-close grey5" (click)="closeModal()"></i>
      </div>
    </div>
    <div class="modal-body nav-font14 pb-3">
      <form [formGroup]="teamForm" novalidate>
        <div class="row align-items-center mb-4">
          <div class="col-4 d-flex justify-content-center pr-5">
            <img [src]="model?.image || '../../../assets/imgs/user.png'" height="64px" width="64px" class="rounded-circle" alt="">
          </div>
          <div class="col upload">
            <or-upload
              mainMsg="Select profile photo to upload"
              smallMsg="or drag and drop .JPEG or .PNG here"
              [includeProgressBar]=false
              acceptImages=true
              showFileName=false
              directToS3=true
              showFileIcon=false
              class="upload"
              (uploadCompleted)="uploadCallback($event)">
            </or-upload>
          </div>
        </div>
        <div class="row align-items-center mb-4">
          <div class="col-4 grey5">
            <label class="required">First name</label>
          </div>
          <div class="col">
            <input type="text" class="form-control form-control-sm form-control-nav" placeholder="First name" formControlName="firstName" id="firstName"
              [ngClass]="submitted && utils.showCssValidField(teamForm, 'firstName')" />
            </div>
          </div>
          <div class="row align-items-center mb-4">
            <div class="col-4 grey5">
              <label class="required">Last name</label>
            </div>
            <div class="col">
              <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Last name" formControlName="lastName" id="lastName"
                [ngClass]="submitted && utils.showCssValidField(teamForm, 'lastName')" />
              </div>
            </div>
            <div class="row align-items-center mb-4">
              <div class="col-4 grey5">
                <label class="required">E-mail</label>
              </div>
              <div class="col-8">
                <input type="text" #input (keyup.enter)="input.blur()" class="form-control form-control-sm form-control-nav" (focus)="emailFocus=true" (blur)="onBlurEmail()"  placeholder="E-mail" formControlName="newemail" id="newemail"
                  [ngClass]="submitted && !model?.id && utils.showCssValidField(teamForm, 'newemail')" />
                </div>
                @if (model?.id && (emailFocus || teamForm.get('newemail').value != teamForm.get('email').value)) {
                  <div class="col-4"></div>
                  <div class="col-8">
                    <div  class="alert alert-warning mx-0 px-1 mt-2">
                      Changing email will change login credentials
                    </div>
                  </div>
                }
              </div>
              <div class="row align-items-center mb-4">
                <div class="col-4 grey5">Phone number</div>
                <div class="col">
                  <input type="text" class="form-control form-control-sm form-control-nav" placeholder="(333) 333-4444" formControlName="phone" id="phone"
                    [ngClass]="submitted && utils.showCssValidField(teamForm, 'phone')" />
                  </div>
                </div>
                <div class="row align-items-center mb-4">
                  @if (isAdmin || isVendorAdmin) {
                    <div class="col-4 grey5"><label class="required">User role</label></div>
                    <div class="col">
                      <div class="btn-group w-100" dropdown #dropdownRole="bs-dropdown">
                        <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container py-0" [ngClass]="dropdownRole.isOpen ? 'open' : 'closed'">
                          <span class="nav-font14 cursor-default" [class.grey2]="role == null || role == ''">{{role == null || role == '' ? 'Select role' : role}}</span>
                          <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                            <i class="far grey4 float-right nav-font16 ml-auto"
                              [class.fa-angle-up]="dropdownRole.isOpen"
                              [class.mt-1]="!dropdownRole.isOpen"
                              [class.fa-angle-down]="!dropdownRole.isOpen">
                            </i>
                          </div>
                        </div>
                        <div id="dropdownRIDCol" *dropdownMenu  class="rid-col-picker-dropdown dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100" role="menu" aria-labelledby="button-basic" >
                          <div class="menu-container">
                            <ul class=" py-0 px-0 my-0 noShadow">
                              <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="roleSelected('Admin')">
                                <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                  <span>Admin</span>
                                </div>
                              </li>
                              <hr class="p-0 my-0 mx-1">
                              <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="roleSelected('Member')">
                                <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                  <span>Regular User</span>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                @if (isAdmin && !isVendorAdmin) {
                  @for (item of selectedTitleRoles; track item; let idx = $index) {
                    <div class="row align-items-center mt-3">
                      <div class=" grey5" [ngClass]="idx == 0 ? 'col-4' : 'col-3'">
                        @if (idx === 0) {
                          <label  class="required pb-2">Company title</label>
                          <i class="fak fa-info grey4 fa-md ml-3 pl-1" placement="bottom" container="body" containerClass="tooltip-role" tooltip="Select the role(s) that best fits your needs. Your role determines the features available to you within the system."></i>
                        }
                      </div>
                      @if (idx > 0) {
                        <div class="col-1 text-right px-0 pb-1 ml-2">
                          <i class="fak fa-trash nav-error cursor-pointer" (click)="removeTitleRole(idx)" [style.visibility]="(idx>0 || item.id != null) ? 'visible' : 'hidden'"></i>
                        </div>
                      }
                      <div class="col select-team-role pl-2" [class.ml-2]="idx == 0" style="z-index:5+idx">
                        <div class="">
                          <div class="col px-0 team-roles">
                            <or-dropdown-list
                              noSelectionText="Select title"
                              createEnabled="true"
                              showWhenClosed="true"
                              container=""
                              createText="Create new title"
                              [values]="titleRoles"
                              [value]="item.id"
                              (selected)="applyRole($event, item)"
                              (created)="createRole($event,'Title')">
                            </or-dropdown-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <div class="row align-items-center mt-2 mb-4">
                    <div class="col-4"></div>
                    <div class="col">
                      <span class="nav-font14 font-weight-bold" [ngClass]="selectedTitleRoles[selectedTitleRoles.length-1].id != null ? 'nav-accent-blue cursor-pointer': 'grey2 cursor-default'" (click)="selectedTitleRoles[selectedTitleRoles.length-1].id != null ? addDropdownTitleRole() : null">
                        <i class="far fa-plus mr-2"></i> Add Title</span>
                      </div>
                    </div>
                    @for (item of selectedTeamRoles; track item; let idx = $index) {
                      <div class="row align-items-center mt-3">
                        <div class="grey5" [ngClass]="idx == 0 ? 'col-4' : 'col-3'">
                          @if (idx === 0) {
                            <label class="">Team</label>
                            <i class="fak fa-info grey4 fa-md ml-2" placement="bottom" container="body" containerClass="tooltip-role"  tooltip="Select the role(s) that best fits your needs. Your role determines the features available to you within the system."></i>
                          }
                        </div>
                        @if (idx > 0) {
                          <div class="col-1 text-right px-0 pb-1 ml-2">
                            <i class="fak fa-trash nav-error cursor-pointer" (click)="removeTeamRole(idx)" [style.visibility]="(idx>0 || item.id != null) ? 'visible' : 'hidden'"></i>
                          </div>
                        }
                        <div class="col select-team-role pl-2" [class.ml-2]="idx == 0" style="z-index:4+idx">
                          <div class="">
                            <div class="col px-0 team-roles">
                              <or-dropdown-list
                                noSelectionText="Select team"
                                createEnabled="true"
                                showWhenClosed="true"
                                container=""
                                createText="Create new team"
                                [values]="teamRoles"
                                [value]="item.id"
                                (selected)="applyRole($event, item)"
                                (created)="createRole($event,'Team')">
                              </or-dropdown-list>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    <div class="row align-items-center mt-2 mb-4">
                      <div class="col-4"></div>
                      <div class="col">
                        <span class="nav-font14 font-weight-bold" [ngClass]="selectedTeamRoles[selectedTeamRoles.length-1].id != null ? 'nav-accent-blue cursor-pointer' : 'grey2 cursor-default'" (click)="selectedTeamRoles[selectedTeamRoles.length-1].id != null ? addDropdownTeamRole() : null">
                          <i class="far fa-plus mr-2"></i> Add Team</span>
                        </div>
                      </div>
                    }
                    <div class="col d-flex align-items-center pr-0 mt-4 pl-0">
                      @if (showImpersonateButton()) {
                        <div >
                          <div class="d-flex">
                            <div class="or-checkbox">
                              <input type="checkbox" name="ableToImpersonate" formControlName="ableToImpersonate" id="ableToImpersonate">
                              <label for="ableToImpersonate"></label>
                            </div>
                            <div class="grey5 nav-font14 text-nowrap ml-3 pl-2" >Able to Impersonate</div>
                          </div>
                        </div>
                      }
                      <div class="ml-auto">
                        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" (click)="closeModal()">Cancel</button>
                        <button type="button"
                          [disabled]="!teamForm.valid"
                          [ngClass]="teamForm.valid ? 'nav-btn-accent' : 'nav-btn-disabled'"
                          class="btn shadow-none font-weight-bold nav-font14"
                        (click)="teamSave()">{{ model?.id ? 'Save' : 'Add Member' }}</button>
                      </div>
                    </div>
                  </form>
                </div>
              </ng-template>

              <ng-template #modalConfirm>
                <div class="modal-header">
                  <div class="row d-flex w-100 align-items-center">
                    <div class="col">
                      <h3 class="font-weight-bold nav-secondary">
                        <i class="fak fa-message-warning grey4 fa-lg ml-2"></i>
                        Warning: Deleting Team Member
                      </h3>
                    </div>
                    <div class="col-2 text-right px-0">
                      <i class="fas fa-close grey4" (click)="closeModal()"></i>
                    </div>
                  </div>
                </div>
                <div class="modal-body pt-0">
                  <h5 class="grey5 d-block my-3">
                    Are you sure you want to delete the team member <span class="font-weight-bold grey5">{{model?.name}}?</span> This action is irreversible, and the client will be permanently lost.
                  </h5>
                </div>
                <div class="modal-footer">
                  <div class="row d-flex w-100 align-items-center">
                    <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
                      <input type="checkbox" name="notAskAgainDeleteMember" (change)="this.utils.createDialogList($event.target.checked, 'team')" id="notAskAgainDeleteMember">
                      <label for="notAskAgainDeleteMember"></label>
                      <small class="pl-4 grey4">Don't ask again</small>
                    </div>
                    <div class="col text-right px-0">
                      <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
                      <button class="btn btn-sm nav-btn-accent shadow-none" (click)="confirmDelete()">Delete</button>
                    </div>
                  </div>
                </div>
              </ng-template>


