<div class="container-fluid overflow-auto">
  <div class="mx-0 my-3 row">
    <h3 class="or-secondary font-weight-light">Reconciliation</h3>
    <div class="col p-0 d-flex align-items-center justify-content-end">
      <div class="btn-group" role="group">
        <button type="button" class="btn btn-sm btn-secondary mr-1" (click)="openModal(modalConfirm)"><i class="fas fa-undo"></i> Rollback All</button>

        <a class="btn btn-sm btn-secondary mr-1" href="assets/ReconciliationTemplate.xlsx">
          <i class="fas fa-download fa-sm mr-1"></i> Download template
        </a>
        <!-- <or-spinner-button label="Upload Reconciliation" #spinner customClass="btn-secondary mr-1 rounded-0 btn-sm" showSpinnerOnCallback=true icon="fas fa-upload" (callback)="fileInput1.click()"></or-spinner-button> -->
        <!-- <input style="display: none" type="file" multiple (change)="recUpload($event.target.files)" onclick="this.value = null" #fileInput1> -->
      </div>
    </div>
    <div class="col-2 d-flex align-items-center">
      <div class="float-right">
        <input [(ngModel)]="filterText" type="search" class="form-control" placeholder="Type to filter...">
      </div>
      <div class="btn btn-sm btn-outline-secondary ml-2" (click)="closePage()"><em class="fa fa-times"></em></div>
    </div>
  </div>
  <div class="mx-0 my-3 row">
    <div class="col p-1">
      <div class="alert alert-success" role="alert">
        <span class="display-6 font-weight-normal">Completes: {{ summary?.total }} </span>
      </div>
    </div>
    <div class="col p-1">
      <div class="alert alert-primary" role="alert">
        <span class="display-6 font-weight-normal">Matched: {{ summary?.matched }} </span>
      </div>
    </div>
    <div class="col p-1">
      <div class="alert alert-warning" role="alert">
        <span class="display-6 font-weight-normal">Non-Client Completes: {{ summary?.clientOnly }} </span>
      </div>
    </div>
    <!--<div class="col p-1">
      <div class="alert alert-info" role="alert">
        <span class="display-6 font-weight-normal">Revenue: $33,000 <i class="fas fa-download fa-sm float-right opacity-80 mt-1"></i></span>
      </div>
    </div>-->
  </div>

  <or-list
    [items]="reconciliation | filter : filterText : filterColumns"
    [layout]="layout"
    [actions]="rowActions"
    rowid="id"
    clickroute="/vendors">
  </or-list>

</div>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Rollback</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to rollback all Reconciliations for this Project?</p>
    <!-- <button type="button" class="btn btn-outline-secondary m-2" (click)="rollbackAll()">Yes</button> -->
    <or-spinner-button label="Yes" customClass="btn btn-outline-secondary m-2" (callback)="rollbackAll()"></or-spinner-button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>
