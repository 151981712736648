import { inject, Injectable, } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { ResolverService } from '../../../../core/src/services/resolver.service';
import { Title } from '@angular/platform-browser';
@Injectable({ providedIn: 'root' })


export class CustomTitleResolver extends TitleStrategy {

  constructor(private titleService: Title, private resolverService: ResolverService) {
    super();
  }
  
  override updateTitle(snapshot: RouterStateSnapshot): void {
    var title = 'OpinionRoute - Navigator';
    const route = this.getDeepestChild(snapshot.root);

    if (route?.routeConfig?.path.startsWith('projects/:id')) {
      var projectId = route?.paramMap?.get("id");
      if (projectId) {
        this.resolverService.GetProjectCode(projectId).subscribe((result) => {
          var projectCode = result?.projectCode;
          if (projectCode) {
            title = projectCode + ' - OpinionRoute';
            this.titleService.setTitle(title);
            return;
          }
          else {
            this.titleService.setTitle(title);
          }
        }, error => {
            this.titleService.setTitle(title);
        });
      }
      else {
        this.titleService.setTitle(title);
      }
    }
    else if (route?.routeConfig?.path.startsWith('projects/summary/:bidNumber')) {
      var bidNumber = route?.paramMap?.get("bidNumber");
      if (bidNumber) {
        this.resolverService.GetProjectSummaryCode(bidNumber).subscribe((result) => {
          var bidNumber = result?.bidNumber;
          if (bidNumber) {
            title = bidNumber + ' - OpinionRoute';
            this.titleService.setTitle(title);
            return;
          }
          else {
            this.titleService.setTitle(title);
          }
        }, error => {
            this.titleService.setTitle(title);
        });
      }
      else {
        this.titleService.setTitle(title);
      }
    }
    else {
      this.titleService.setTitle(title);
    }
    
  }

  getDeepestChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
};
