<div class="card">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-calendar fa-lg mr-2 grey4 font-weight-normal nav-font18"></i>Dates</span>
    <i class="fas fa-arrows-maximize fa-md grey4 font-weight-normal float-right cursor-pointer nav-font16" (click)="openSettings('dates')"></i>
  </div>
  <div class="card-body nav-font14 pt-2">
    <div class="row grey4 pb-3">
      <div class="col-5"></div>
      <div class="col pl-0">Date range</div>
    </div>
    <div class="row align-items-start">
      <div class="col">
        <div class="row" [ngClass]="(_project.projectStartDate || _project.projectEndDate) ? 'pb-3' : 'pb-3'">
          <div class="col-5 grey5 nav-font14 font-weight-bold">
            <div class="row">
              <div class="col">Project dates</div>
            </div>
          </div>
          <div class="col nav-font14 pl-0">
            <div class="row">
              <div class="col" [ngClass]="{'px-0 d-flex justify-content-center': (_project.projectStartDate && _project.projectEndDate)}">
                @if (_project.projectStartDate || _project.projectEndDate) {
                  <span class="d-block cursor-pointer grey5">{{_project.projectStartDate | date: 'MMM d, yyyy'}} - {{_project.projectEndDate | date: 'MMM d, yyyy'}}</span>
                }
                @if (!_project.projectStartDate && !_project.projectEndDate) {
                  <span class="d-block cursor-pointer nav-link-accent font-weight-bold" (click)="openSettings('dates')">
                    <i class="fas fa-plus font-weight-normal mr-1"></i>Add dates
                  </span>
                } @else {
                  @if (!_project.projectStartDate) {
                    <span class="d-block cursor-pointer nav-link-accent font-weight-bold" (click)="openSettings('dates')">
                      <i class="fas fa-plus font-weight-normal mr-1"></i>Add start date
                    </span>
                  }
                  @if (!_project.projectEndDate) {
                    <span class="d-block cursor-pointer nav-link-accent font-weight-bold" (click)="openSettings('dates')">
                      <i class="fas fa-plus font-weight-normal mr-1"></i>Add end date
                    </span>
                  }
                }
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5 grey5 nav-font14 font-weight-bold">
            <div class="row">
              <div class="col">Field dates</div>
            </div>
          </div>
          <div class="col nav-font14 pl-0">
            <div class="row">
              <div class="col" [ngClass]="{'px-0 d-flex justify-content-center': (_project.fieldStartDate && _project.fieldEndDate)}">
                @if (_project.fieldStartDate || _project.fieldEndDate) {
                  <span class="d-block cursor-pointer grey5">{{_project.fieldStartDate | date: 'MMM d, yyyy'}} - {{_project.fieldEndDate | date: 'MMM d, yyyy'}}</span>
                }
                @if (!_project.fieldStartDate && !_project.fieldEndDate) {
                  <span class="d-block cursor-pointer nav-link-accent font-weight-bold" (click)="openSettings('dates')">
                    <i class="fas fa-plus font-weight-normal mr-1"></i>Add dates
                  </span>
                } @else {
                  @if (!_project.fieldStartDate) {
                    <span class="d-block cursor-pointer nav-link-accent font-weight-bold" (click)="openSettings('dates')">
                      <i class="fas fa-plus font-weight-normal mr-1"></i>Add start date
                    </span>
                  }
                  @if (!_project.fieldEndDate) {
                    <span class="d-block cursor-pointer nav-link-accent font-weight-bold" (click)="openSettings('dates')">
                      <i class="fas fa-plus font-weight-normal mr-1"></i>Add end date
                    </span>
                  }
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
