import { AfterViewInit, Component, OnInit, Pipe, PipeTransform, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client, ClientContact, ClientService, ClientContactService, ProjectService, ProjectFilters, AlertifyService, UtilsService, LookupService, AuthService } from 'core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { UnsavedchangesGuardService } from '../../../_guards/unsavedchanges.guard.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { GroupNote } from 'projects/core/src/models/group-note';
import { HighlightPipe } from 'projects/core/src/helpers/highlight.pipe';
import { CompareTextPipe } from 'projects/core/src/helpers/compareText.pipe';
import { forkJoin, take } from 'rxjs';
import { LookupTable } from 'projects/core/src/models/lookupTable';
import { } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';



@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
  providers: [HighlightPipe, CompareTextPipe]

})
export class ClientDetailsComponent implements OnInit, AfterViewInit {

  client: Client = {
    id: '',
    name: '',
    instanceId: '',
    externalId: '',
    salesTerm: '',
    salesTermValue: 0,
    internalAccountOwnerId: null,
    notes: null,
    allNotes: [],
    address: {
      address1: '',
      address2: '',
      city: '',
      country: ''
    },
  };

  contactForm: FormGroup;
  clientContacts: any = [];
  allClientContacts: any = [];
  submitted = false;
  notAskAgainDeleteContact = false;
  mode = 'view';
  editClient: Client = {
    id: '',
    name: '',
    instanceId: '',
    externalId: '',
    salesTerm: '',
    salesTermValue: 0,
    internalAccountOwnerId: null,
    address: {
      address1: '',
      address2: '',
      city: '',
      country: ''
    }
  };
  newContact: ClientContact;
  emailFocus = false;

  model: any;
  filters: ProjectFilters;
  filterText = '';
  filterContacts = '';
  numToLoad = 50;
  showLoadMore = false;
  allProjects: any[] = [];
  projects: any[] = null;
  clientPreference;
  filterValues: any = [];
  tab = 'projects';

  filter = {
    orderBy: '',
    orderDirection: ''
  };

  // notes
  newNote: GroupNote = null;
  editingNote: GroupNote = null;
  noteHistory: GroupNote[] = null;
  showDifferences: boolean = false;
  noteToDelete: GroupNote = null;
  tagOptions = {};
  subjectOptions = {};
  subjects = [];
  noteFilterValues = [];
  filterNotes = '';

  @ViewChild('modalContact')
  modalRef: TemplateRef<any>;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  @ViewChild('modalNoteHistory')
  modalRefNoteHistory: TemplateRef<any>;

  @ViewChild('modalNoteConfirm')
  modalRefNoteConfirm: TemplateRef<any>;

  @ViewChild('clientTabs', { static: false }) clientTabs: TabsetComponent;

  openModalRef: any;

  rowActions = [
    { label: 'Open', type: 'open' }
  ];

  columns: any = [
    { label: 'ID', id: 'projectCode', sortable: true },
    { label: 'Name', id: 'projectName', sortable: true, selectable: true },
    { label: 'Project Manager', id: 'projectManager', sortable: true, filterable: true },
    { label: 'Status', id: 'projectStatus', sortable: true, filterable: true, type: 'status' },
    { label: 'QC', id: 'clientQC', sortable: true, type: 'number' },
    { label: 'QC Rate', id: 'actualQCRate', sortable: true, type: 'number' },
    { label: 'Time Left', id: 'projectEndDate', sortable: true, type: 'date', autoSave: true },
    { label: '', id: 'actions', type: 'actions-dropdown' }
  ];

  constructor(
    private clientContactService: ClientContactService,
    private lookupService: LookupService,
    private clientService: ClientService,
    private projectService: ProjectService,
    private modalService: BsModalService,
    private activateRoute: ActivatedRoute,
    public utils: UtilsService,
    private router: Router,
    private alertify: AlertifyService,
    public auth: AuthService,
    private unsavedChangesService: UnsavedchangesGuardService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.getClient();
    this.getClientContacts();
    this.loadProjectListViews();
  }

  ngAfterViewInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe({
      next: params => {
        this.selectTab(params['tab']);
      }
    });
  }

  loadProjectListViews() {
    this.projectService.GetProjectListViews(null, true).subscribe(data => {
      const listViews = data.filter(v => v.locationType == 'client');

      if (listViews == null || listViews.length == 0) {
        this.columns = [
          { label: 'ID', id: 'projectCode', sortable: true },
          { label: 'Name', id: 'projectName', sortable: true, selectable: true },
          { label: 'Project Manager', id: 'projectManager', sortable: true, filterable: true },
          { label: 'Status', id: 'projectStatus', sortable: true, filterable: true, type: 'status' },
          { label: 'QC', id: 'clientQC', sortable: true, type: 'number' },
          { label: 'QC Rate', id: 'actualQCRate', sortable: true, type: 'number' },
          { label: 'Time Left', id: 'projectEndDate', sortable: true, type: 'date', autoSave: true },
          { label: '', id: 'actions', type: 'actions-dropdown' }
        ];
      } else {
        this.columns = listViews[0].tableColumns;
        this.columns.forEach(col => {
          col.id = col.field;
        });
      }
      this.getProjects();
    }, error => {
    });
  }

  updateContacts() {
    const filter = this.filterContacts.toLowerCase();
    if (filter === '') {
      this.clientContacts = this.allClientContacts;
    }
    else {
      this.clientContacts = this.allClientContacts.filter(e =>
        e.name.toLowerCase().indexOf(filter) >= 0 ||
        e.title.toLowerCase().indexOf(filter) >= 0 ||
        e.fullPhone.toLowerCase().indexOf(filter) >= 0 ||
        e.email.toLowerCase().indexOf(filter) >= 0);
    }
  }

  updateProjects() {
    const filter = this.filterText.toLowerCase();
    if (filter === '') {
      this.projects = this.allProjects;
    }
    else {
      this.projects = this.allProjects.filter(e => e.projectName.toLowerCase().indexOf(filter) >= 0);
    }
    this.showLoadMore = (this.projects.length > this.numToLoad);
    this.projects = this.projects.slice(0, this.numToLoad);
  }

  selectTab(id: string) {
    if (this.clientTabs) {
      for (let i = 0; i < this.clientTabs.tabs.length; i++) {
        if (this.clientTabs.tabs[i].id === id) {
          this.clientTabs.tabs[i].active = true
          break
        }
        this.clientTabs.tabs[0].active = true
      }
    }
  }

  getClient() {
    this.clientService.GetClient(this.activateRoute.snapshot.params.id, true).subscribe(data => {
      this.client = data;
      this.editClient = this.client;
      this.client.notes = null;
      this.client.allNotes = [];
    }, error => {
      this.alertify.error('Unable to get client');
    }, () => {
      let queryParams = this.activateRoute.snapshot.queryParams;
      if (queryParams && queryParams.preference) {
        this.tab = 'preferences';
      }
      if (!this.client.clientPreference) {
        this.enablePreferenceEdit();
      }
      this.getClientNotes();

    });
  }


  getClientContacts() {
    this.clientContactService.GetClientContacts(this.activateRoute.snapshot.params.id).subscribe(data => {
      data.forEach(contact => {
        contact.mode = 'view';
      });

      this.allClientContacts = data.map(item => {
        let contact: any = { ...item };
        if (contact.lastLogin === '0001-01-01T00:00:00') {
          contact.lastLogin = '';
        }
        if (!contact.blockLogon) {
          contact.portalAccess = 'Yes';
        } else {
          contact.portalAccess = '';
        }

        contact.name = item.firstName + ' ' + item.lastName;
        if (!contact.title) contact.title = '';
        if (!contact.fullPhone) contact.fullPhone = '';
        return contact;
      });
      this.updateContacts();
    }, error => {
      this.alertify.error('Unable to get contacts');
    });
  }
  getProjects() {
    this.filters = {
      status: [],
      clientID: [this.activateRoute.snapshot.params.id],
      partnerID: [],
      freeText: '',
      isMine: false,
      archivedOnly: false,
      type: ''
    }

    this.projectService.GetProjects(this.filters).subscribe(data => {
      this.projects = this.projectService.EnrichProjects(data);
      this.allProjects = this.projects;
    }, error => {
      this.alertify.error('Unable to get projects');
    });
  }

  enablePreferenceEdit() {
    this.clientPreference = this.client.clientPreference;
    this.mode = 'edit';
  }

  saveClient() {
    this.client.clientPreference = this.clientPreference;
    this.clientService.EditClient(this.client).subscribe(data => {
    }, error => {
      this.alertify.error('Unable to update client');
      this.getClient();
    }, () => {
      this.alertify.success('Client updated successfully');
    });
    this.mode = 'view';
  }
  cancelClient() {
    this.mode = 'view';
  }

  handleAction(action) {
    if (action.type === 'open') {
      this.router.navigateByUrl('/projects/' + action.row.id);
    }
  }

  createContactForm() {
    this.contactForm = new FormGroup({
      id: new FormControl(null),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      companyPosition: new FormControl(''),
      profilePhoto: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      newemail: new FormControl(''),
      clientId: new FormControl(this.client.id),
      billing: new FormControl(false),
    });
  }

  contactAdd() {
    this.model = {};
    this.createContactForm();
    this.unsavedChangesService.register(
      this.contactForm,
      (form) => form.dirty
    );
    this.openModalRef = this.modalService.show(this.modalRef, { class: 'nav-modal-style' });
  }

  contactEdit(contact) {
    this.model = contact;
    this.bindContactForm();
    this.openModalRef = this.modalService.show(this.modalRef, { class: 'nav-modal-style' });
  }

  contactDelete(contact) {
    this.model = contact;
    this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'nav-modal-style' });
  }

  uploadCallback(event) {
    this.model.profilePhoto = event.file;
    this.contactForm.controls['profilePhoto'].setValue(event.file);
  }

  closeModal() {
    this.unsavedChangesService.deregister([this.contactForm]);
    this.openModalRef.hide()
  }

  bindContactForm() {
    if (this.model) {
      this.contactForm = new FormGroup({
        id: new FormControl(this.model.id),
        firstName: new FormControl(this.model.firstName),
        lastName: new FormControl(this.model.lastName),
        companyPosition: new FormControl(this.model.title),
        phone: new FormControl(this.model.fullPhone),
        email: new FormControl({ value: this.model.email.trim(), disabled: !(this.auth.isAdmin()) }),
        newemail: new FormControl(this.model.email.trim()),
        profilePhoto: new FormControl(this.model.profilePhoto),
        clientId: new FormControl(this.client.id),
        billing: new FormControl(this.model.billing),
      });
    }
  }

  confirmDelete() {
    //if (this.notAskAgainDeleteContact) localStorage.setItem('keepDeleteContactWarning', '1');
    this.clientContactService.DeleteClientContact(this.model).subscribe(data => {
      if (data) {
        this.alertify.success('Contact deleted successfully');
        this.model = {};
        this.closeModal();
        this.getClientContacts();
      }
    }, error => {
      this.alertify.error('Unable to delete contact');
    });
  }

  setBilling(event) {
    this.contactForm.controls['billing'].setValue(JSON.parse(event.target.value));
  }
  onBlurEmail() {
    this.emailFocus = false;
    const email = this.contactForm.get('email');
    const trimmedEmail = email?.value.trim();  // Trim the value
    email?.setValue(trimmedEmail);
    const newemail = this.contactForm.get('newemail');
    const trimmednewemail = newemail?.value.trim();  // Trim the value
    newemail?.setValue(trimmednewemail);
  }
  contactSave() {
    this.submitted = true;
    if (!this.contactForm.valid) {
      return;
    }
    if (!this.contactForm.value.id) {
      this.contactForm.controls['id'].setValue(uuidv4());
      this.clientContactService.AddClientContact(this.contactForm.value).subscribe(data => {
        if (data) {
          this.alertify.success('Contact added successfully');
          this.model = {};
          this.closeModal();
          this.getClientContacts();
          this.createContactForm();
        }
      }, error => {
        this.alertify.error('Unable to add contact');
      });

    } else {
      this.clientContactService.EditClientContact(this.contactForm.value).subscribe(data => {
        if (data) {
          this.alertify.success('Contact edited successfully');
          this.model = {};
          this.closeModal();
          this.getClientContacts();
          this.createContactForm();
        }
      }, error => {
        if (error == 'EMAIL_EXISTS') {
          this.alertify.error('User already exists please try again');
        }
        else {
          this.alertify.error('Unable to edit contact');
        }
      });
    }
  }

  SortBy(field) {
    if (field == this.filter.orderBy) {
      this.filter.orderDirection = (this.filter.orderDirection == 'ASC') ? 'DESC' : 'ASC'
    } else {
      this.filter.orderBy = field;
      this.filter.orderDirection = 'ASC';
    }
    this.sort(this.clientContacts, this.filter.orderBy, this.filter.orderDirection);
  }

  sort(array, type, direction) {
    array.sort((a, b) => {
      var A;
      var B;
      if (type == 'firstName') {
        A = a.firstName;
        B = b.firstName;
      }

      if (type == 'lastName') {
        A = a.lastName;
        B = b.lastName;
      }
      else if (type == 'phone') {
        A = a.phone;
        B = b.phone;
      }
      else if (type == 'email') {
        A = a.email;
        B = b.email;
      }

      let comparison = 0;
      if (direction === 'ASC') {
        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        return comparison;
      }
      else {
        if (A < B) {
          comparison = 1;
        } else if (A > B) {
          comparison = -1;
        }
        return comparison;
      }

    });
    return array;
  }

  getClientNotes() {

    forkJoin([
      this.clientService.GetClientNotes(this.activateRoute.snapshot.params.id),
      this.lookupService.GetLookupTable('client-note-tag'),
      this.lookupService.GetLookupTable('client-note-subject')
    ]).subscribe(data => {
      this.noteFilterValues = [];
      var tagFilters = [];
      var subjectFilters = [];
      var memberFilters = [];
      // set up tag dictionary
      if (data[1].length > 0) {
        data[1].forEach(tag => {
          this.tagOptions[tag.id] = tag.value;
        });
      }

      if (data[2].length > 0) {
        var subjects = [];
        this.subjectOptions = {};
        data[2].forEach(subject => {
          this.subjectOptions[subject.id] = subject.value;
          subjects.push({ id: subject.id, name: subject.value })
        })
        this.subjects = subjects;
      }

      // set up notes
      this.client.allNotes = [];
      if (data[0].length > 0) {
        var latest = data[0].filter(x => x.isLatest)
        latest.sort(function (a, b) {
          return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
        });

        latest.forEach(note => {
          note.tagIds = (note.tags.map(tag => tag)).join(',');
          note.createdOn = new Date(note.createdOn);
          if (note.originalNoteId) {
            note.previousVersions = data[0].filter(x => x.id != note.id && (x.originalNoteId == note.originalNoteId || x.id == note.originalNoteId))
            note.previousVersions.sort(function (a, b) {
              return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
            });

            note.previousVersions.forEach(prevNote => {
              prevNote.tagIds = (prevNote.tags.map(tag => tag)).join(',');
            });
          }

          if (note.subject.trim() != '' && !subjectFilters.find(x => x.group === 'Subjects' && x.value === note.subject)) {
            subjectFilters.push({ label: this.subjectOptions[note.subject], value: note.subject, group: 'Subjects', selected: false, });
          }
          subjectFilters.sort((a, b) => a.label.localeCompare(b.label));

          if (note.body != '' && note.body != null) {
            note.hasExpand = true;
          }
          note.expand = false;

          this.client.allNotes.push(note);

        });

        this.noteFilterValues = subjectFilters

      }
      this.client.notes = this.client.allNotes;
      this.multiLineOverflows()

    }, error => {
      this.alertify.error('Unable to get client Notes');
    }, () => {

    })
  }

  updateNotes(notes) {
    const filter = this.filterNotes.toLowerCase();
    if (filter === '') {
      this.client.notes = notes;
    }
    else {
      this.client.notes = notes.filter(e => e.body.toLowerCase().indexOf(filter) >= 0 || e.title.toLowerCase().indexOf(filter) >= 0);
    }
  }

  addNote() {
    this.newNote = {
      id: null,
      subject: '',
      title: '',
      body: '',
      tags: [],
      isLatest: true,
      showTags: false,
      tagIds: ''
    }
  }
  editNote(note: GroupNote) {
    this.editingNote = {
      id: note.id,
      subject: note.subject,
      title: note.title,
      body: note.body,
      tags: note.tags,
      isLatest: note.isLatest,
      showTags: note.tags.length > 0,
      tagIds: (note.tags.map(tag => tag)).join(',')
    };
  }

  openDeleteNoteConfirm(note) {
    this.noteToDelete = note;
    this.openModalRef = this.modalService.show(this.modalRefNoteConfirm, { class: 'nav-modal-style modal-dialog-centered' });
  }

  deleteNote() {
    this.clientService.DeleteClientNote(this.client.id, this.noteToDelete).subscribe(data => {

    }, error => {
      this.alertify.error('Unable to delete note');
    }, () => {
      this.alertify.success('Note deleted successfully');
      this.noteToDelete = null;
      this.closeModal();
      this.getClientNotes();
    });
  }

  cancelNewNote() {
    this.newNote = null;
  }
  cancelEditNote() {
    this.editingNote = null;
  }

  saveNote() {
    this.clientService.SaveClientNote(this.client.id, this.editingNote).subscribe(result => {
      this.editingNote = null;
      this.getClientNotes();
    }, error => {
      this.alertify.error('Unable to save Note');
    }, () => {
      this.alertify.success('Client Note saved successfully');
    })
  }

  openNoteHistory(note) {
    if (note.previousVersions.length > 0) {
      this.showDifferences = false;
      this.noteHistory = [note].concat(note.previousVersions)
      this.openModalRef = this.modalService.show(this.modalRefNoteHistory, { class: 'nav-modal-style modal-xl modal-dialog-scrollable' });
    }
  }

  addTags(note, tags) {
    var t = [];
    tags.forEach(tag => {
      t.push(tag.id);
    });
    note.tags = t
  }

  applySubject(item, note) {
    if (item != null) {
      note.subject = item.id;
    }
  }

  createSubject(subject) {
    var newSubject: LookupTable = {
      id: uuidv4(),
      type: 'client-note-subject',
      value: subject,
      suggest: true
    }

    this.lookupService.InsertLookupValue('client-note-subject', newSubject).subscribe((data) => {
    }, error => {
      this.alertify.error("Couldn't create a new subject")
    }, () => {
      this.subjects.push({ id: newSubject.id, name: newSubject.value });
      this.subjects = [...this.subjects] // triggers change in child component
    })
  }

  createNewNote() {
    this.clientService.CreateClientNote(this.client.id, this.newNote).subscribe(result => {
      this.newNote = null;
      this.getClientNotes();
    }, error => {
      this.alertify.error('Unable to create Note');
    }, () => {
      this.alertify.success('Client Note created successfully');
    });
  }

  applyFilterNotes() {
    this.client.notes = this.client.allNotes;

    const subjects = this.noteFilterValues.filter(x => x.selected).map(x => x.value);

    if (subjects.length > 0) {
      this.client.notes = this.client.notes.filter(x => subjects.includes(x.subject))
    }

    this.updateNotes(this.client.notes);
  }

  exportNoteHistory(notes) {
    let csvContent = '';
    var noteWithMostTags = notes.reduce((max, obj) => (obj.tags.length > max.tags.length ? obj : max), notes[0]);
    var tagColumns = '';
    var i = 1;
    noteWithMostTags.tags.forEach(tag => {
      tagColumns += ('Tag ' + i.toString() + ',')
      i++;
    });
    // Colum headers
    csvContent = 'Date,User,Title,Subject,Body,' + tagColumns + '\n\r'

    // Data
    const items = notes;
    items.forEach((row: any) => {

      csvContent += new Date(row.createdOn) + ','
      csvContent += row.createdByName + ',';

      csvContent += ((row.title ?? '') + ',')
      csvContent += ((this.subjectOptions[row.subject] ?? '') + ',')
      csvContent += this.formatHTMLForCSV(row.body) + ','
      for (let key in this.tagOptions) {
        if (row.tags.includes(key)) csvContent += (this.tagOptions[key] + ',')
      }
      csvContent += '\n';
    });

    this.utils.generateXLSX(csvContent, this.client.name + '-note-history.csv');
  }

  exportNotes(notes) {
    let csvContent = '';
    var noteWithMostTags = notes.reduce((max, obj) => (obj.tags.length > max.tags.length ? obj : max), notes[0]);
    var tagColumns = '';
    var i = 1;
    noteWithMostTags.tags.forEach(tag => {
      tagColumns += ('Tag ' + i.toString() + ',')
      i++;
    });
    // Colum headers
    csvContent = 'Date,User,Last Updated,Updated By,Title,Subject,Body,' + tagColumns + '\n\r'

    // Data
    const items = notes;
    items.forEach((row: any) => {
      if (row.previousVersions?.length > 0) {
        var firstVersion = row.previousVersions.find(x => !x.originalNoteId);
        csvContent += new Date(firstVersion.createdOn) + ',';
        csvContent += firstVersion.createdByName + ',';
      }
      csvContent += new Date(row.createdOn) + ','
      csvContent += row.createdByName + ',';

      if (!row.previousVersions) {
        csvContent += ',,'
      }
      csvContent += ((row.title ?? '') + ',')
      csvContent += ((this.subjectOptions[row.subject] ?? '') + ',')
      csvContent += this.formatHTMLForCSV(row.body) + ','

      for (let key in this.tagOptions) {
        if (row.tags.includes(key)) csvContent += (this.tagOptions[key] + ',')
      }

      csvContent += '\n';
    });

    this.utils.generateXLSX(csvContent, this.client.name + '-notes.csv');
  }

  private formatHTMLForCSV(htmlText: string): string {
    // Remove line breaks and tabs
    const formattedText = htmlText.replace(/\n/g, ' ').replace(/\r/g, ' ').replace(/\t/g, ' ');

    // Remove double quotes and escape any existing double quotes with double double quotes
    const escapedText = formattedText.replace(/"/g, '""');
    const temp = document.createElement('div');
    temp.innerHTML = escapedText;
    var result = temp.textContent || temp.innerText || ''

    return `"${result}"`; // Wrap the text in double quotes to preserve spaces and special characters in the CSV
  }

  multiLineOverflows() {

    setTimeout(() => {
      const elements = document.getElementsByClassName('note-body');

      for (let i = 0; i < elements.length; i++) {
        var el = elements.item(i)
        var noteId = el.getAttribute('id')

        if (el.scrollHeight != 0 && el.clientHeight != 0) {
          if (el.scrollHeight <= el.clientHeight) {
            var note = this.client.notes.find(x => x.id === noteId);
            note.hasExpand = false;
          }
        }
      };
    }, 100);
  }
}
