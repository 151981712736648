<div class="modal-header text-white">
  <p class="h5 position-absolute"><em class="fas fa-cog"></em> Widget Configuration</p>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <input type="hidden" id="id">
  <div class="">
    <div class="h6">
      {{widget.title}}
    </div>
    <hr class="m-0 p-1">
    <div class=" m-2 p-2">
      @for (set of editSettings | keyvalue; track set) {
        <div class="row m-2">
          <div class="col form-group">
            <label>{{set.value.label}}</label>
            @if (set.value.type == 'toggle') {
              <or-toggle-button [(ngModel)]="set.value.value" ></or-toggle-button>
            }
            @if (set.value.type == 'text') {
              <input  style="width:20%" class="text-right form-control form-control-sm" [(ngModel)]="set.value.value">
            }
            @if (set.value.type == 'dropdown') {
              <div class="w-50">
                <select class="custom-select" [(ngModel)]="set.value.value">
                  @for (item of dateRangeOptions | keyvalue; track item) {
                    <option value={{item.value.value}}>{{item.value.label}}</option>
                  }
                </select>
              </div>
            }
          </div>
        </div>
      }
    </div>
    <div class=" text-right">
      <button type="submit" class="btn btn-sm btn-outline-secondary mx-2" (click)="cancel()"> Cancel</button>
      <button type="submit" class="btn btn-sm nav-btn-accent text-white" (click)="saveWidgetConfig()"><em class="fa fa-save"></em> Save</button>
    </div>
  </div>
</div>
