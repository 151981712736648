<div class="nav-content">
    <!-- BREADCRUMBS -->
    <div class="row">
        <div class="col-12 breadcrumb mr-auto bg-transparent mb-3 py-0">
            <div class="d-flex align-items-center">
            <div class="nav-secondary d-flex align-items-center">
                <app-pagetitle></app-pagetitle>
            </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div>
            <iframe #myIframe frameborder="0" width="100%" height="8000" allowtransparency></iframe>
        </div>
    </div>
</div>