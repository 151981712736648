<div class="nav-content container col-6" style="margin-top:200px">
  <div class="alert alert-info" role="alert">
    Loading help documents please wait.
  </div>
  @if (samlData != null) {
    <div>
      <form [action]="samlData.destination" method="post" id="supportSSOForm">
        <div><input type="hidden" name="SAMLResponse" [value]="samlData.response"/><input type="hidden" name="RelayState" [value]="samlData.relay"/></div>
      </form>
    </div>
  }
</div>


