import { Component, Input, OnInit, TemplateRef, ViewChild,Output, EventEmitter } from '@angular/core';
import { AlertifyService, AuthService, Project, ReconciliationService,ProjectService } from 'core';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UploaderService } from 'projects/core/src/services/uploader.service';

@Component({
  selector: 'app-project-card-reconciliation',
  templateUrl: './project-card-reconciliation.component.html',
  styleUrls: ['./project-card-reconciliation.component.scss']
})

export class ProjectCardReconciliationComponent implements OnInit {
  @Output() onChange = new EventEmitter();
  @Input() public set project(data) {
    this._project = data;
    this.s3buckPath = "reconciliation/" + this._project.id;
    this.instanceId = this.auth.getUser().instanceId;
  }

  @Input() mode = 'reduced';
  @ViewChild('modalConfirm') modalRefConfirm: TemplateRef<any>;
  @ViewChild('modalReconciliationError') modalRefReconError:  TemplateRef<any>;
    

  _project: Project;
  reconciliation: any = [];
  openModalRef: any;
  openModalReconError: any;
  actionsOpen = false;
  canImport = false;
  s3buckPath: string;
  instanceId = '';
  showTable = true;
  errorList: any = []; 

  exampleData = [["1/02/23", "filenameforfile.csv", "KA", "641/641", "0"]];

  layout = [
    { label: 'Date', id: 'lastUpdatedFormatted', sortable: true },
    { label: 'File Name', id: 'fileName', selectable: true},
    { label: 'User', id: 'userName', type: 'users' },
    { label: 'Records', id: 'records' },
    { label: 'Errors', id: 'failed' }
  ];

  constructor(
    public auth: AuthService,
    private projectService: ProjectService,
    private reconciliationService: ReconciliationService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    public uploader: UploaderService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

 
  recUpload(files) {
    var fileToUpload = files[0];
    for (var i = 0; i < this.reconciliation.length; i++) {
      if (fileToUpload.name == this.reconciliation[i]["fileName"]) {
        const splitted = fileToUpload.name.split(".")
        const newFileName = splitted[0] + "-1" + "." + splitted[1];
        fileToUpload = new File([fileToUpload], newFileName, {type: fileToUpload.type});
      }
    }
    const formData = this.reconciliationService.uploadLink(fileToUpload, this._project.id);
    this.reconciliationService.setReconciliaton(formData).subscribe(data => {  
      this.errorList = data;
      if (this.errorList != null && this.errorList.length > 0) {          
        this.openModalRecon(this.modalRefReconError);

      } else {
        this.loadData();
        this.alertify.success('Reconciliation Uploaded');
        this.onChange.emit();
      }
    }, error => {
       
    });
  }

  download(item) {
    if (item.fileName !== "Rollback") {
      this.uploader.generatePresignedDownloadUrl('', this.s3buckPath, false, item.fileName, this.instanceId).subscribe(response => {
        window.open(response.uploadUrl);
      }, error => console.log(error));
    }
  }

  loadData() {
    this.reconciliationService.GetReconciliationFiles(this._project.id).subscribe(data => {
      this.canImport = data.canImport;
      this.reconciliation = data.history;
      for (var i=0; i < this.reconciliation.length; i++) {
        this.reconciliation[i]["userName"] = [{name: data[i]["userName"]}],
        this.reconciliation[i]["lastUpdatedFormatted"] = (moment(data[i]["lastUpdated"])).format("MM/DD/YY");
        this.reconciliation[i]["records"] = `${data[i]["total"]-data[i]["matched"]}/${data[i]["total"]}`;
      }
      
    },
    error => {
      this.alertify.error('Unable to get reconciliation history');
    });
  }

  reconcilationByDecipherAPI() {
    this.reconciliationService.reconcilationByDecipherAPI(this._project.id).subscribe(
      data => {
        this.errorList = data;
        if (this.errorList != null && this.errorList.length > 0) {          
          this.openModalRecon(this.modalRefReconError);  
        } else {
          this.loadData();
          this.alertify.success('Reconciliation done successfully by Decipher API');
          this.onChange.emit();
        }       
      },
      error => {      
        this.alertify.error(error);
      },
      () => {
        this.loadData();      
        this.onChange.emit();
      }
    );
  }
  
  rollbackAll() {
    this.reconciliationService.RollbackAll(this._project.id).subscribe(
      data => {
        this.alertify.success('Project Rolled Back succesfully');
      },
      error => {
        this.alertify.error('Unable to rollback for this project');
      },
      () => {
        this.loadData();
        this.openModalRef.hide();
        this.onChange.emit();
      }
    );
  }
  openModalRecon(template: any, classes: string = 'modal-md nav-modal nav-modal-style') {
    this.openModalReconError = this.modalService.show(template, {ignoreBackdropClick: true, keyboard: false, class: classes });
  }
  openModal(template: any, classes: string = 'modal-sm nav-modal nav-modal-style') {
    this.openModalRef = this.modalService.show(template, {ignoreBackdropClick: true, keyboard: false, class: classes });
  }
}
