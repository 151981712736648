import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-project-homepage-survey-links-vendors',
  templateUrl: './project-homepage-survey-links-vendors.component.html',
  styleUrls: ['./project-homepage-survey-links-vendors.component.scss']
})
export class ProjectHomepageSurveyLinksVendorsComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.processData();
  }

  @Output() callback = new EventEmitter();

  _project:any = {};
  showAllVendors = false;

  summary: any = {
    totalLinks: 0,
    totalVendors: 0,
    vendors: []
  }

  constructor() { }

  ngOnInit() {

  }

  processData() {
    this.summary.vendors = this._project?.projectSegments[0].projectSurveyPartners.filter((vendor) => !vendor.isDeleted);
    this.summary.totalVendors = this.summary.vendors.length;

    this.summary.vendors.forEach(vendor => {
      const vendorLinks = ['completeRedirectURL', 'terminateRedirectURL', 'overQuotaRedirectURL', 'qcRedirectURL'].filter(key => !!vendor[key]);
      vendor.totalLinks = vendorLinks.length;
    });

    const links = ['surveyLink', 'testSurveyLink'].filter(key => !!this._project?.projectSegments[0]?.[key]);
    this.summary.totalLinks = links.length;
    console.log(this.summary);
  }

  getTooltip() {
    if (this.summary.totalLinks === 2) {
      return 'Survey live and test link added';
    }
    if (this.summary.totalLinks > 0) {
      const message = !this._project?.projectSegments[0]?.surveyLink ? 'Survey live link not added' : 'Survey test link not added';
      return message;
    } else {
      return 'Survey live and test link not added';
    }
  }

  getTooltipVendor(vendor) {
    if (vendor.totalLinks === 4) {
      return 'All links added';
    }
    if (vendor.totalLinks > 0) {
      const message = (
        !vendor.completeRedirectURL ? 'Complete redirect link not added' :
        !vendor.terminateRedirectURL ? 'Terminate redirect link not added' :
        !vendor.overQuotaRedirectURL ? 'Over quota redirect link not added' : 'QC redirect link not added'
      );
      return message;
    } else {
      return 'No redirect links added';
    }
  }

  get getVendors() {
    return this.showAllVendors ? this.summary.vendors : this.summary.vendors.slice(0, 5);
  }

  toggleView() {
    this.showAllVendors = !this.showAllVendors;
  }

  openLinksVendors() {
    this.callback.emit({section: 'links-and-vendors', type: null});
  }

}
