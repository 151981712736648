import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { AlertifyService, ProjectService, Project, ReconciliationService, UtilsService, DropdownService, AuthService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClipboardService } from 'ngx-clipboard';
import { ClosureService } from 'projects/core/src/services/closure.service';
import { ExportFilesService } from 'projects/core/src/services/exportFiles.service';
import { ProjectCloseVendorEmailComponent } from './../project-close-vendor-email/project-close-vendor-email.component';
import { formatDate } from '@angular/common';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-project-close',
  templateUrl: './project-close.component.html',
  styleUrls: ['./project-close.component.scss'],
  providers: [ProjectCloseVendorEmailComponent]
})
export class ProjectCloseComponent implements OnInit {

  @Input() project: Project;
  @Input({ required: true }) closureType: string;

  closureDetails: any;
  reconcileData: any;
  showStep = 0;
  preview: any;
  formData: FormData;
  currentQuestion = 0;
  vendorSatisfaction: any;
  selectAll = 0;
  showNew = true;
  showAll = false;
  loading = false;
  fileUploaded = false;
  maxInvoiceDate: Date;
  confirmInvoiceClosure = false;
  closureComplete = false;
  chosenVendorReset: any;
  chosenVendorResetIndex: number;
  resetReason: string = "";
  pmConfirmCogs = false;
  origFileName = "";
  sampleTotal = 0;
  sampleMargin = 0;
  serviceTotal = 0;
  vendorMessage: string = '';
  segmentOptions = []
  productOptions = [
    'Services:CATI',
    'Services:Coding',
    'Services:Crosstabs',
    'Services:Data Append',
    'Services:Data Processing',
    'Services:Incentive',
    'Services:List',
    'Services:Mobile Diary',
    'Services:Online Bulletin Board Recruit',
    'Services:Other',
    'Services:Programming and Hosting',
    'Services:Project Management Fee',
    'Services:Recruit',
    'Services:Sample',
    'Services:Transcriptions',
    'Services:Translation',
    'Services:Video Hosting',
    'Technology:CleanID',
    'Technology:ResponseID',
    'Technology:ValidID'
  ];
  categoryOptions = ['Services:Consumer', 'Services:B2B', 'Services:Healthcare', 'NA'];
  statusOptions = ['', 'QC', 'Complete', 'Terminate', 'Dropoff', 'OverQuota'];

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;
  openModalRef: any;

  @ViewChild('modalReset')
  modalRefReset: TemplateRef<any>;
  modalOpenConfirmRef: any;

  @ViewChild('modalZeroValueConfirm')
  modalRefZeroValueConfirm: TemplateRef<any>;
  modalOpenZeroValueConfirmRef: any;

  @ViewChild('modalReconciliationError') modalRefReconError: TemplateRef<any>;
  @ViewChild('modalResetVendorPricing')
  modalRefResetVendorPricing: TemplateRef<any>;
  modalOpenResetVendorPricingRef: any;

  bsModalRef: BsModalRef;

  constructor(
    private reconciliationService: ReconciliationService,
    private alertify: AlertifyService,
    private exportFilesService: ExportFilesService,
    private closureService: ClosureService,
    private projectService: ProjectService,
    private dropdownService: DropdownService,
    private modalService: BsModalService,
    private utilService: UtilsService,
    public auth: AuthService,
    private clipboardService: ClipboardService) { }

  ngOnInit() {
    this.maxInvoiceDate = new Date();
    this.maxInvoiceDate.setDate(this.maxInvoiceDate.getDate() + 30);
    this.getClosureDetails();
  }

  getClosureDetails() {
    if (this.closureType === 'close' && this.project.clientId == null)
      return;

    this.closureService.GetClosureDetails(this.project.id).subscribe(data => {
      this.processDetails(data);
    }, error => {
    });
  }
  closeProject() {
    this.projectService.UpdateProjectStatus(this.project.id, 'Closed').subscribe(data => {
      this.project.projectStatus = 'Closed';
    }, error => {
      this.alertify.error('There was an issue updating this status');
    });
  }
  toggleShow() {
    this.showAll = !this.showAll;
    this.showNew = !this.showNew;
  }
  getStepStyle(step) {
    if (this.closureDetails == null) { return 'inactive-bg'; }

    if (step === 1) {
      return this.closureDetails.step1CompletedBy === null ? 'active-bg' : 'complete-bg';
    } else if (step === 2) {
      return this.closureDetails.step2CompletedBy === null ? (this.closureDetails.step1CompletedBy === null ? 'inactive-bg' : 'active-bg') : 'complete-bg';
    } else if (step === 3) {
      return this.closureDetails.step3CompletedBy === null ? (this.closureDetails.step2CompletedBy === null ? 'inactive-bg' : 'active-bg') : 'complete-bg';
    } else if (step === 4) {
      return this.closureDetails.step4CompletedBy === null ? (this.closureDetails.step3CompletedBy === null ? 'inactive-bg' : 'active-bg') : 'complete-bg';
    } else if (step === 5) {
      return this.closureDetails.step5CompletedBy === null ? (this.closureDetails.step4CompletedBy === null ? 'inactive-bg' : 'active-bg') : 'complete-bg';
    } else if (step === 7) {
      return this.closureDetails.step7CompletedBy === null ? (this.closureDetails.step5CompletedBy === null ? 'inactive-bg' : 'active-bg') : 'complete-bg';
    }
  }
  processDetails(data) {
    let closureDetails = data;
    if (closureDetails.step1CompletedBy != null) this.fileUploaded = true;

    // TO DO: Business logic around when this can be filled in, if before finance then we need to get vendor list
    const satisfactionDetails = [];
    if (closureDetails.vendors != null) {
      let vendors = this.checkVendorIsDeleted(closureDetails.vendors);
      closureDetails.vendorSatisfaction.questions.forEach(question => {
        question.answers = [];
        vendors.forEach(vendor => {
          let answer = closureDetails.vendorSatisfaction.answers
            .find(e => e.vendorId === vendor.id && e.vendorSatisfactionQuestionId === question.vendorSatisfactionQuestionId);
          if (answer == null) {
            answer = { comment: '', answer1: false, answer2: false, answer3: false };
          }
          question.answers.push({
            vendor: vendor.vendorName,
            vendorId: vendor.id,
            answer1: answer.answer1,
            answer2: answer.answer2,
            answer3: answer.answer3,
            comment: answer.comment
          });
        });
        satisfactionDetails.push(question);
      });
    }

    this.vendorSatisfaction = satisfactionDetails;

    if (closureDetails.client != null) {
      if (closureDetails.client.pricing != null) {
        closureDetails.client.pricing.forEach(p => {
          if (p.total === '') {
            p.total = 0;
          }
          if (p.category == null) { p.category = 'Services:' + this.project.category; }
          if (p.product == null) { p.product = 'Services:Sample'; }
          if (p.description == null) { p.description = p.segment; }
          p.unitPrice = p.sellCpi;
          p.type = 'pricing';
        });

        closureDetails.client.pricing.sort((a, b) => (a.product + a.segment > b.product + b.segment) ? 1 : ((b.product + b.segment > a.product + a.segment) ? -1 : 0));
      }
      if (closureDetails.client.expenses != null) {
        closureDetails.client.expenses.forEach(p => {
          if (p.category == null) { p.category = this.project.category; }
          if (p.product == null) { p.product = ''; }
          p.total = p.amount;
          p.unitPrice = p.expenseCpi;
          p.type = 'expense';
        });
      }
    }

    if (closureDetails.vendors != null) {
      closureDetails.vendors.forEach(v => {
        v.expenses.forEach(p => {
          p.vendor = v.vendorName;
          if (p.category == null) { p.category = this.project.category; }
          if (p.product == null) { p.product = ''; }
          p.total = p.amount;
          p.unitPrice = p.expenseCpi;
          p.type = 'expense';
        });
        v.pricing.sort((a, b) => (a.segment > b.segment) ? 1 : ((b.segment > a.segment) ? -1 : 0));
        v.isAccepted = v.latestDecisionStatus == 'Accepted'
          || v.latestDecisionStatus == 'Auto-Accepted'
          || v.latestDecisionStatus == 'Confirmed';
      });
    }

    if (closureDetails.invoiceDate != null && typeof closureDetails.invoiceDate === 'string') {
      closureDetails.invoiceDate = new Date(closureDetails.invoiceDate);
    }

    if (closureDetails.billingDate != null && typeof closureDetails.billingDate === 'string') {
      closureDetails.billingDate = new Date(closureDetails.billingDate);
    }

    // Default Invoice and Billing Number
    // TO DO: This is quite specific to OpinionRoute so may need way to configure this
    if (closureDetails.invoiceNum == null || closureDetails.invoiceNum === '') {
      closureDetails.invoiceNum = this.project.bidNumber + 'I-1';
    }
    if (closureDetails.billingNum == null || closureDetails.billingNum === '') {
      closureDetails.billingNum = 'PB_' + this.project.bidNumber;
    }

    // If invoiced mark true
    this.closureComplete = (this.project.projectStatus === 'Invoiced' || closureDetails.step7CompletedBy != null);
    // Mark false even if invoiced to allow for a confirm
    if (closureDetails.step4CompletedBy === null) this.closureComplete = false; // Vendor Pricing
    if (closureDetails.step5CompletedBy === null) this.closureComplete = false; // Margin

    if (this.closureType === 'close-external' && closureDetails.step4CompletedBy !== null) this.closureComplete = true; // Vendor Pricing when External Closure

    this.closureDetails = closureDetails;
  }
  modelMessage = 'Confirm all projects to be closed'
  clientUpload(evt) {
    if (evt?.status == 'Complete') {
      const uploadedFile = evt.file;
      this.loading = true;
      if (uploadedFile !== undefined && uploadedFile !== null && uploadedFile !== '') {
        const fileName = uploadedFile.substring(uploadedFile.lastIndexOf('/') + 1);
        this.origFileName = evt.origFile.name;
        this.closureService.clientFileChecks(fileName, this.closureDetails.id, this.project.id).subscribe(data => {
          this.preview = data;
          if (data.validationErrorMessages?.length > 0) {
            this.errorList = data.validationErrorMessages;
            this.openModalRecon(this.modalRefReconError);
            this.loading = false;
          } else {
            if (data.invoicedParticipantsExcel?.length > 0) {
              this.modelMessage = 'Invalid Ids';
            }
            this.loading = false;
            this.openModalRef = this.modalService.show(this.modalRefConfirm);
          }
        }, error => {
          this.loading = false;
        });
      } else {
        this.alertify.error("File Failed to Upload");
        this.loading = false;
      }
    }
  }
  errorList: any = [];
  openModalReconError: any;
  openModalRecon(template: any, classes: string = 'modal-md nav-modal nav-modal-style') {
    this.openModalReconError = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: classes });
  }
  resetClosure() { this.modalOpenConfirmRef = this.modalService.show(this.modalRefReset); }
  callClosureReset() {
    // Clear out existing closure details
    this.closureService.ResetClosure(this.closureDetails.id).subscribe(s => {
      this.project.projectStatus = 'Closed';
      this.fileUploaded = false;
      this.modalOpenConfirmRef.hide();

      // Call init service again to recreate closure details
      this.getClosureDetails();
    }, error => {
    });
  }
  clientCompletesUploaded() {
    // If there are any warnings present we need to present them now.
    this.preview.fileName = this.origFileName;
    this.closureService.clientFileConfirm(this.closureDetails.id, this.project.id, this.preview).subscribe((data) => {
      this.closureDetails.step1CompletedBy = true; //just temp so we show correct step info
      this.openStep(2);
    }, error => {
      this.alertify.error(error);
    });
    /*this.closureService.closeStep(1, this.closureDetails.id, this.project.id).subscribe((data) => {
      this.closureDetails.step1CompletedBy = true; //just temp so we show correct step info
      this.openStep(2);
    }, error => {
      this.alertify.error(error);
    });*/
    this.openModalRef.hide();
  }
  addSegments(segs: any) {
    segs.forEach(x => {
      if (!this.segmentOptions.includes(x)) {
        this.segmentOptions.push(x);
      }
    });
  }
  openStep(step) {
    this.showStep = step;

    if (this.showStep === 2 && this.closureDetails.step1CompletedBy != null) {
      const projectList = this.closureDetails.projectList.split(',');
      this.closureService.displayRecData(projectList).subscribe(data => {
        this.reconcileData = data;
        this.addSegments(data.segments);
      }, error => {
        this.alertify.error(error);
      });
    }
  }
  saveClientCompletes(closeStep) {
    this.closureService.saveClientCompleteData(this.reconcileData.navCompletes, this.project.id, this.closureDetails.id, closeStep).subscribe(e => {
      if (closeStep) {
        this.closureService.closeStep(2, this.closureDetails.id, this.project.id).subscribe((data) => {
          this.processDetails(data);
          this.alertify.success('Reconciliation has been saved and confirmed');

          // if external closure, go to step 4 (Vendor Pricing)
          (this.closureType === 'close') ? this.openStep(3) : this.openStep(4);

        }, error1 => {
          this.alertify.error(error1);
        });
      } else {
        this.processDetails(e);
        this.alertify.success('Reconciliation has been saved');
      }
    }, error => {
      this.alertify.error(error);
    });
  }

  // STEP 1:
  closeOutNow() {
    // Close steps 1 & 2
    this.closureService.closeZeroCompletes(this.closureDetails.id, this.project.id, this.closureDetails.clientId).subscribe(data => {
      this.closureDetails.step1CompletedBy = data.step1CompletedBy;
      this.closureDetails.step1CompletedDate = data.step1CompletedDate;
      this.closureDetails.step2CompletedBy = data.step2CompletedBy;
      this.closureDetails.step2CompletedDate = data.step2CompletedDate;
      // Get the closure details data again to refresh info
      this.closureService.GetClosureDetails(this.project.id).subscribe(data => {
        this.processDetails(data);
      }, error => {
      });
    });
  }

  // STEP 3:
  addClientExpense() {
    this.closureDetails.client.expenses.push({
      id: uuidv4(),
      name: '',
      description: '',
      completes: 0,
      expenseCpi: 0,
      amount: 0,
      isDeleted: false,
      category: 'Services:' + this.project.category
    });
  }
  removeClientExpense(idx) { this.closureDetails.client.expenses[idx].isDeleted = true; }
  get clientPricing(): any {
    let fee = 0;
    let completes = 0;
    let expense = 0;

    if (this.closureDetails.client !== null) {
      this.closureDetails.client.pricing.forEach(e => {
        fee += (e.completes * e.sellCpi);
        completes += e.completes;
      });

      this.closureDetails.client.expenses.forEach(e => {
        if (e.completes > 0 && e.expenseCpi > 0) {
          e.amount = (e.completes * e.expenseCpi);
        }
        expense += e.amount;
      });
    }
    return { completes, fee, expense, total: fee + expense };
  }

  isInvalid(value) {
    if (value == null) return true;
    if (typeof value == 'string') {
      return (value.replace(/\s/g, '') == '');
    } else if (typeof value == 'number') {
      return (value < 1);
    }
  }

  saveClientFinance(closeStep) {
    if (closeStep) {
      if (this.closureDetails.client.po == null || this.closureDetails.client.po.replace(/\s/g, '') == '') {
        this.alertify.error('Client PO Number is mandatory');
        return;
      }

      if (this.closureDetails.client.expenses.length > 0) {
        var noDesc = this.closureDetails.client.expenses.find(x => x.description.replace(/\s/g, '') == '' && !x.isDeleted);
        if (noDesc) {
          this.alertify.error('Expenses Description are mandatory');
          return;
        }
      }
    }

    this.closureService.saveClientFinanceData(this.closureDetails, this.project.id, closeStep).subscribe(e => {
      if (closeStep) {
        this.closureService.closeStep(3, this.closureDetails.id, this.project.id).subscribe((data) => {
          this.processDetails(data);
          this.alertify.success('Client finance has been saved and confirmed');
          this.openStep(4);
        }, error1 => {
          this.alertify.error(error1);
        });
      } else {
        this.processDetails(e);
        this.alertify.success('Client finance has been saved');
      }
    }, error => {
      this.alertify.error(error);
    });
  }

  // STEP 4:
  @ViewChild('step4VendorPricing') step4VendorPricing!: ElementRef;
  // @ViewChild('venderStatus') venderStatus!: ElementRef;
  getPartnerList = (filter) => { return this.dropdownService.getPartners(); }
  addPartnerTypeahead(value) {
    if (value != null && value.length > 0) {
      const selectedId = value[0].value.toLowerCase();
      const selectedName = value[0].name;
      const existing = this.closureDetails.vendors.find(v => v.partnerId === selectedId);
      if (existing != undefined && existing != null) {
        if (!existing.isDeleted) {
          this.alertify.error(selectedName + ' is already added to this project');
          return;
        } else {
          this.closureDetails.vendors.forEach(vendor => {
            if (vendor.partnerId === selectedId) vendor.isDeleted = false;
          });
          return;
        }
      }

      this.closureDetails.vendors.unshift({
        expenses: [],
        pricing: [],
        id: uuidv4(),
        isAccepted: false,
        latestDecisionStatus: null,
        latestDecisionUpdated: null,
        partnerId: selectedId,
        vendorName: selectedName,
        isDeleted: false,
        po: ''
      });
    }
  }
  removeVendor(vendor) {
    //remove the vendor with this id but only if they have no completes
    this.closureDetails.vendors = this.closureDetails.vendors.filter(e => {
      if (e.partnerId === vendor.partnerId &&
        (e.pricing.length === 0 || e.pricing[0].completes === 0)) {
        e.isDeleted = true;
        return true;
      } else {
        return true;
      }
    });
  }
  resetVendor(vendor, index, ddlStatus = null) {

    if (ddlStatus !== null && ddlStatus !== '') {
      vendor.status = ddlStatus.value;
    }
    this.chosenVendorReset = vendor;
    this.chosenVendorResetIndex = index;
    if (vendor.latestDecisionStatus === 'Reset') {
      this.resetConfirmVendor();
    }
    else {
      this.modalOpenResetVendorPricingRef = this.modalService.show(this.modalRefResetVendorPricing, { animated: true, keyboard: false, backdrop: 'static' });
    }
  }

  @ViewChildren('venderStatus') venderStatus!: QueryList<ElementRef>;
  resetConfirmVendor() {
    const status = this.chosenVendorReset.status;
    let vendor = this.chosenVendorReset;
    var reason = this.resetReason;

    if (status === "Reset") {
      if (reason === undefined || reason === null || reason === "" || reason.length < 1) {
        this.alertify.warning("Must enter a reason to reset the Vendor Pricing!");
        return;
      }
    }
    if (status === "Accepted") vendor.isAccepted = true;
    else if (status === "Auto-Accepted") vendor.isAccepted = true;
    else if (status === "Confirmed") vendor.isAccepted = true;
    else if (status === "Pending") vendor.isAccepted = false;
    else if (status === "Reset") vendor.isAccepted = false;
    else {
      this.alertify.error("There was an error trying to set status to '" + status + "'");
      return;
    }
    vendor.status = status;
    vendor.latestDecisionStatus = status;


    if (status === "Confirmed") {
      this.confirmVendor(this.chosenVendorReset, this.chosenVendorResetIndex);
    } else if ((status === "Reset") && (reason.length > 1)) {
      this.modalOpenResetVendorPricingRef.hide();
      this.closureService.updateVendorStatus(vendor, this.project.id, reason).subscribe(data => {
        if (!data) {
          this.alertify.error('Vendor ' + vendor.vendorName + ' was unable to be reset');
          this.venderStatus.get(this.chosenVendorResetIndex).nativeElement.selectedIndex = 0;
        } else this.alertify.success('Vendor ' + vendor.vendorName + ' has been reset');
      }, error => {
        this.alertify.error(error);
      }, () => {
        if (this.step4VendorPricing?.nativeElement?.classList?.contains('complete-bg')) {
          this.step4VendorPricing?.nativeElement?.classList?.remove('complete-bg');
          this.step4VendorPricing?.nativeElement?.classList?.toggle('active-bg');

          if (this.closureDetails?.step7CompletedBy != null) {
            if (this.step5FinalMargin?.nativeElement?.classList?.contains('complete-bg')) {
              this.step5FinalMargin?.nativeElement?.classList?.remove('complete-bg');
              this.step5FinalMargin?.nativeElement?.classList?.toggle('active-bg');
            }
          }
        }
        this.getClosureDetails();
      });
    } else if ((status !== "Reset") && (reason.length < 1)) {
      this.closureService.sendResetEmail(vendor, this.project.id).subscribe(data => {
        if (!data) {
          this.alertify.error('Vendor ' + vendor.vendorName + ' was unable to be send email');
          this.venderStatus.get(this.chosenVendorResetIndex).nativeElement.selectedIndex = 0;
        } else {
          this.alertify.success('Vendor ' + vendor.vendorName + ' Email has been sent');
          this.venderStatus.get(this.chosenVendorResetIndex).nativeElement.value = "Pending";
        }
      }, error => {
        vendor.latestDecisionStatus = 'Reset';
        this.venderStatus.get(this.chosenVendorResetIndex).nativeElement.value = vendor.latestDecisionStatus;
        this.alertify.error(error);
      });
    }
    this.closureComplete = false;
    this.resetReason = "";
  }
  cancelResetVendor() {
    this.venderStatus.get(this.chosenVendorResetIndex).nativeElement.selectedIndex = 0;
    this.modalOpenResetVendorPricingRef.hide();
  }
  confirmVendor(vendor, index, ddlStatus = null) {
    if (ddlStatus !== null && ddlStatus !== '') {
      vendor.status = ddlStatus.value;
    }

    this.chosenVendorReset = vendor;
    this.chosenVendorResetIndex = index;
    this.closureService.confirmVendorStatus(vendor, this.project.id).subscribe(data => {
      if (!data) {
        this.alertify.error('Vendor ' + vendor.vendorName + ' was unable to be confirmed');
        this.venderStatus.get(this.chosenVendorResetIndex).nativeElement.selectedIndex = 0;
      } else {
        this.alertify.success('Vendor ' + vendor.vendorName + ' has been confirmed');
      }
    }, error => {
      this.alertify.error(error);
    }, () => {
      if (this.step4VendorPricing?.nativeElement?.classList?.contains('complete-bg')) {
        this.step4VendorPricing?.nativeElement?.classList?.remove('complete-bg');
        this.step4VendorPricing?.nativeElement?.classList?.toggle('active-bg');
      }
      this.getClosureDetails();
    });
  }
  addVendorExpense(vendor) {
    vendor.expenses.push({
      description: '',
      amount: 0,
      completes: 0,
      expenseCpi: 0,
      isDeleted: false,
      category: 'Services:' + this.project.category
    });
  }
  changeLineTotal(expense) {
    expense.completes = (expense.completes === 0 || expense.completes == null) ? 1 : expense.completes;
    expense.expenseCpi = (expense.expenseCpi === 0 || expense.expenseCpi == null) ? expense.amount : expense.amount / expense.completes;
  }

  removeVendorExpense(vendor, idx) { vendor.expenses[idx].isDeleted = true; }


  getVendorServiceDetails() {
    let vendors: any[] = [];
    this.serviceTotal = 0;
    this.closureDetails.vendors.forEach(vendor => {
      vendor.expenses.forEach(e => {
        if (!vendor.isDeleted) {
          vendors.push({
            vendorName: vendor.vendorName,
            category: e.category,
            product: e.product,
            total: e.total
          });
          this.serviceTotal += e.total;
        }
      });
    });
    return vendors;
  }



  get vendorDetails(): any {
    const details: any = {};
    let totalSample = 0;
    let totalExpense = 0;
    let vendors: any[] = [];
    this.sampleTotal = 0;
    let sampleClient = 0;

    if (this.closureDetails.vendors != null) {
      this.closureDetails.vendors.forEach(vendor => {
        let moneyOut = 0;
        let moneyIn = 0;
        let completes = 0;
        vendor.pricing.forEach(e => {
          moneyOut += (e.completes * e.buyCpi);
          moneyIn += (e.completes * e.sellCpi);
          completes += e.completes;
        });

        let expense = 0;
        vendor.expenses.forEach(e => {
          if (e.completes > 0 && e.expenseCpi > 0) {
            e.amount = (e.completes * e.expenseCpi);
          }
          expense += e.amount;
        });

        totalSample += moneyOut;
        totalExpense += expense;
        details[vendor.vendorName] = {
          completes,
          fee: moneyOut,
          expense,
          total: moneyOut,
          margin: moneyIn === 0 ? 0 : (moneyIn - moneyOut) / moneyIn
        };

        if (!vendor.isDeleted) {
          sampleClient += moneyIn;
        }


        // Array of vendors to update after ApprovedMarginReview
        vendors.push({
          partnerId: vendor.partnerId,
          clientTotalApproved: moneyIn,
          vendorTotalApproved: moneyOut + expense,
          marginApproved: moneyIn === 0 ? 0 : (moneyIn - moneyOut - expense) / moneyIn
        });
      });
    }
    const total = totalSample + totalExpense;
    const margin = this.clientPricing.total === 0 ? 0 : (this.clientPricing.total - total) / this.clientPricing.total;
    details.total = { fee: totalSample, expense: totalExpense, total, margin };
    details.vendorsApprovedMargin = vendors;
    this.sampleTotal = totalSample;
    this.sampleMargin = sampleClient === 0 ? 0 : (sampleClient - totalSample) / sampleClient;

    return details;
  }
  isZeroConfirm: boolean = false;
  confirmZeroValue() {
    this.modalOpenZeroValueConfirmRef.hide();
    this.isZeroConfirm = true;
    this.saveVendorFinance(true);
  }
  notConfirmZeroValue() {
    this.isZeroConfirm = false;
    this.modalOpenZeroValueConfirmRef.hide();
  }



  saveVendorFinance(closeStep) {
    let message = '';
    if (!this.isZeroConfirm) {
      let zeroValueVendors = [];
      for (let index = 0; index < this.closureDetails.vendors.length; index++) {
        let vendor = this.closureDetails.vendors[index];
        let zeroValue = vendor.pricing.filter(price => price.buyCpi == 0);
        if (!vendor.isDeleted && zeroValue.length > 0) {
          if (vendor.expenses.length > 0) {
            vendor
            let zeroexpence = vendor.expenses.filter(expence => expence.amount * expence.expenseCpi == 0);
            if (zeroexpence.length > 0) {
              zeroValueVendors.push(vendor);
            }
          } else {
            zeroValueVendors.push(vendor);
          }
        }
      }

      for (let index = 0; index < zeroValueVendors.length; index++) {
        const element = zeroValueVendors[index];
        message = message + element.vendorName + ', ';
      }
      message = message.substring(0, message.length - 2);
      this.vendorMessage = message + ' has costs of $0 entered. Continue?';
    }
    if (message !== '' && closeStep) {
      this.modalOpenZeroValueConfirmRef = this.modalService.show(this.modalRefZeroValueConfirm, { animated: true, keyboard: false, backdrop: 'static', class: 'rounded-top-radius' });
      return;
    }

    let nOK: any;
    nOK = this.closureDetails.vendors
      .find(vendor => vendor.expenses
        .find(expense => expense.isDeleted === false && (expense.completes === null || expense.completes === 0)));
    if (nOK) {
      this.alertify.error('Qty field must be at least 1');
      return;
    }

    nOK = this.closureDetails.vendors
      .find(vendor => vendor.expenses
        .find(expense => expense.isDeleted === false && (expense.product === null || expense.product === '')));
    if (nOK) {
      this.alertify.error('Product must be selected');
      return;
    }

    let nonAcceptedVendors = this.closureDetails.vendors.filter(item =>
      item.latestDecisionStatus !== 'Accepted' &&
      item.latestDecisionStatus !== 'Auto-Accepted' &&
      item.latestDecisionStatus !== 'Confirmed' &&
      !item.isDeleted);

    if (nonAcceptedVendors.length > 0 && closeStep) {
      this.alertify.warning("All vendors must be accepted!");
      return;
    }
    this.closureService.saveVendorFinanceData(this.closureDetails, this.project.id, closeStep).subscribe(e => {
      if (closeStep) {
        this.closureService.closeStep(4, this.closureDetails.id, this.project.id).subscribe((data) => {
          // May need to set the Step Complete by value
          this.processDetails(data);
          this.alertify.success('Vendor finance has been saved and confirmed');

          (this.closureType === 'close') ? this.openStep(5) : this.openStep(1);

        }, error1 => {
          this.alertify.error(error1);
        });
      } else {
        this.processDetails(e);
        this.alertify.success('Vendor finance has been saved');
      }
    }, error => {
      this.alertify.error(error);
    });
  }
  downloadPartnerCompletes() {
    this.alertify.message('Your file will download once ready');
    const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '.zip';
    this.exportFilesService.downloadPartnerCompletes(this.closureDetails.id, filename, this.project.projectCode).subscribe(url => {
      window.location.assign(url);
    }, error => {
      this.alertify.error(error);
    }, () => {
    });
  }

  ExportClientCost() {
    let csvContent = 'Segment,Product,Category,Completes,CPI,Line Total';
    this.closureDetails.client.pricing.forEach(q => {
      let dataCSV = '\n' + q.segment + ',' + q.product + ',' + q.category + ',' + q.completes + ',' + q.sellCpi + ',' + q.total;
      csvContent += dataCSV;
      let subtot = '\n' + 'Sub Total' + ',' + '' + ',' + '' + ',' + '' + ',' + '' + ',' + this.clientPricing.fee;
      csvContent += subtot;
    });
    let csvCExpense = '\n' + '\n' + 'Description,Product,Category,Qty,Unit Cost,Line Total';
    csvContent += csvCExpense;
    this.closureDetails.client.expenses.forEach(q1 => {
      let dataExpenseCSV = '\n' + q1.description + ',' + q1.product + ',' + q1.category + ',' + q1.completes + ',' + q1.unitPrice + ',' + q1.total;
      csvContent += dataExpenseCSV;
    });
    let subtot = '\n' + 'Sub Total' + ',' + '' + ',' + '' + ',' + '' + ',' + '' + ',' + this.clientPricing.expense;
    csvContent += subtot;
    let tot = '\n\n' + 'Total' + ',' + '' + ',' + '' + ',' + '' + ',' + '' + ',' + this.clientPricing.total;
    csvContent += tot;
    this.utilService.generateXLSX(csvContent, 'ClientCost.csv');
  }

  openModalVendor() {
    const initialState = {
      data: {
        project: this.project,
        vendors: this.closureDetails.vendors
      }
    };
    this.bsModalRef = this.modalService.show(ProjectCloseVendorEmailComponent, { ignoreBackdropClick: true, keyboard: false, initialState, class: 'modal-md' });
    this.bsModalRef.content.saveevent.subscribe((data) => {
      this.getClosureDetails();
    });
  }
  checkVendorIsDeleted(vendors) { return vendors.filter(x => x.isDeleted === undefined || !x.isDeleted); }


  // Step 5:
  @ViewChild('step5FinalMargin') step5FinalMargin!: ElementRef;
  confirmMargin() {
    let anyPendingVendor = this.closureDetails.vendors
      .filter(x => x.isAccepted === false && !x.isDeleted).length > 0;

    if (anyPendingVendor) {
      this.alertify.error('All Vendors Pricing must be accepted before confirming Final Margin');
      return;
    }
    if (this.pmConfirmCogs) {
      forkJoin([
        this.closureService.closeStep5PmConfirmCogs(5, this.closureDetails.id, this.project.id, this.pmConfirmCogs),
        this.closureService.UpdateMarginReview(this.closureDetails.id, this.project.id, this.clientPricing.total, this.vendorDetails.total.total, this.vendorDetails.vendorsApprovedMargin),

      ]).subscribe((data) => {
        if (data != null && data.length > 0) {
          this.alertify.success('Margin has been confirmed');
          this.processDetails(data[0]);
          this.showStep = this.showStep + 2;
        }
        this.closureService.closeStep(6, this.closureDetails.id, this.project.id)

      }, error1 => { this.alertify.error(error1); });
    } else {
      forkJoin([
        this.closureService.closeStep(5, this.closureDetails.id, this.project.id),
        this.closureService.UpdateMarginReview(this.closureDetails.id, this.project.id, this.clientPricing.total, this.vendorDetails.total.total, this.vendorDetails.vendorsApprovedMargin),

      ]).subscribe((data) => {
        if (data != null && data.length > 0) {
          this.alertify.success('Margin has been confirmed');
          this.processDetails(data[0]);
          this.showStep = this.showStep + 2;
        }
        this.closureService.closeStep(6, this.closureDetails.id, this.project.id);
      }, error1 => { this.alertify.error(error1); });
    }
  }
  pmConfirmCheckbox(selected: boolean) { this.pmConfirmCogs = selected; }

  // Step 6:
  updateAllAnswers(selected, answers, value) {
    if (!selected) { value = 0; }
    this.selectAll = value;
    answers.forEach(answer => { this.setSurveyAnswerVals(answer, value); });
  }
  changeValue(answer, value, checked) {
    if (!checked) { value = 0; }

    this.setSurveyAnswerVals(answer, value);
    this.selectAll = 0;
  }
  setSurveyAnswerVals(answer, value) {
    switch (value) {
      case 1: answer.answer1 = true; answer.answer2 = answer.answer3 = false; break;
      case 2: answer.answer2 = true; answer.answer1 = answer.answer3 = false; break;
      case 3: answer.answer3 = true; answer.answer1 = answer.answer2 = false; break;
      default: answer.answer1 = answer.answer2 = answer.answer3 = false;
    }
  }
  saveVendorSatisfaction(closeStep) {
    let allAnswered = true;
    const questions = [];
    const answers = [];
    this.vendorSatisfaction.forEach(q => {
      questions.push({ vendorSatisfactionQuestionId: q.vendorSatisfactionQuestionId, comment: q.comment ?? '' });
      q.answers.forEach(a => {
        answers.push({
          vendorSatisfactionQuestionId: q.vendorSatisfactionQuestionId,
          vendorId: a.vendorId,
          answer1: a.answer1,
          answer2: a.answer2,
          answer3: a.answer3,
          comment: a.comment
        });
        if (a.answer1 === false && a.answer2 === false && a.answer3 === false) { allAnswered = false; }
      });
    });
    const satisfactionDetails = {
      questions,
      answers
    };

    if (closeStep && !allAnswered) {
      this.alertify.error('Please Answer all questions');
      return;
    }
    this.closureService.saveVendorSatisfaction(this.closureDetails.id, satisfactionDetails).subscribe(res => {
      if (closeStep) {
        this.closureService.closeStep(6, this.closureDetails.id, this.project.id).subscribe((data) => {
          this.processDetails(data);
          this.alertify.success('Vendor Satisfaction has been saved and step completed');
          this.openStep(7);
        }, error1 => { this.alertify.error(error1); });
      } else { this.alertify.success('Vendor Satisfaction has been saved'); }
    }, error => { this.alertify.error(error); });
  }

  // STEP 7: Invoice
  saveInvoiceDetails(closeStep) {
    if (closeStep && this.closureDetails.invoiceDate == null) { this.closureDetails.invoiceDate = new Date(); }

    this.confirmInvoiceClosure = false;

    if (this.closureDetails.invoiceDate != null) { this.closureDetails.invoiceDate.setHours(12, 0, 0, 0); }

    const invoiceData = {
      invoiceNum: this.closureDetails.invoiceNum,
      invoiceDate: this.closureDetails.invoiceDate,
      billingNum: this.closureDetails.billingNum,
      billingDate: this.closureDetails.billingDate,
    };

    this.closureService.saveInvoiceData(invoiceData, this.closureDetails.id).subscribe(e => {
      if (closeStep) {
        this.closureService.closeStep(7, this.closureDetails.id, this.project.id).subscribe((data) => {
          this.project.projectStatus = 'Invoiced';
          this.processDetails(data);
          this.alertify.success('Client invoice has been saved and confirmed');
          this.openStep(0);
        }, error1 => { this.alertify.error(error1); });
      } else {
        // this.processDetails(e);
        this.alertify.success('Client invoice has been saved');
      }
    }, error => { this.alertify.error(error); });
  }
  updateBillingDate(date) {
    if (date === this.closureDetails.billingDate) return;

    this.closureDetails.billingDate = date;

    if (this.closureDetails.billingDate != null) { this.closureDetails.billingDate.setHours(12, 0, 0, 0); }

    this.closureService.saveBillingDate(date, this.closureDetails.id).subscribe(e => {
      this.alertify.success('Billing Date has been saved');
    }, error => { this.alertify.error(error); });
  }
  downloadInvoice() {
    this.alertify.message('Your file will download once ready.');
    const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '-Invoice.xlsx';
    this.exportFilesService.downloadInvoice(this.project.id, filename).subscribe(url => {
      window.location.assign(url);
    }, error => { this.alertify.error(error); });
  }
  downloadBilling() {
    this.alertify.message('Your file will download once ready.');
    const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '-Billing.xlsx';
    this.exportFilesService.downloadBilling(this.project.id, filename).subscribe(url => {
      window.location.assign(url);
    }, error => { this.alertify.error(error); });
  }
  downloadCompletes() {
    const filename = 'ClientCompletes.csv';
    let csvContent = '';
    this.reconcileData.clientCompletes.details.forEach(c => {
      if (this.showAll || !c.matched) { csvContent += c.participantId.toString() + ',' + c.segment.toString() + '\n'; }
    });
    this.utilService.generateXLSX(csvContent, filename);
  }
  downloadNavCompletes() {
    const filename = 'NavCompletes.csv';
    let csvContent = '';
    this.reconcileData.navCompletes.details.forEach(c => {
      csvContent += c.participantId.toString() + '\n';
    });
    this.utilService.generateXLSX(csvContent, filename);
  }
  copyClientCompletes() {
    let copyClient = [];
    this.reconcileData.clientCompletes.details.forEach(c => {
      if (this.showAll || !c.matched) { copyClient.push(c.participantId.toString()); }
    });
    this.clipboardService.copy(copyClient.join('\r\n'));
    this.alertify.success('Client Completes copied to clipboard.');
  }
  copyNavCompletes() {
    let copyNav = [];

    this.reconcileData.navCompletes.details.forEach(c => { copyNav.push(c.participantId.toString()); });
    this.clipboardService.copy(copyNav.join('\r\n'));
    this.alertify.success('Nav Completes copied to clipboard.');
  }
  updateAllStatus(stat: any) {
    this.reconcileData.navCompletes.details.forEach(c => {
      c.exitStatus = stat;
      if (stat == 'Complete' && this.segmentOptions.length == 1) { c.segment = this.segmentOptions[0]; }
    });
  }
  revertToClosed() {
    if (!this.auth.isSuperAdmin()) return;

    this.projectService.revertProjectStatusToClosedSuperAdmin(this.project.id).subscribe(data => {
      this.project.projectStatus = 'Closed';
      this.alertify.success('Project status has been reverted to Closed!');
    }, error => {
      this.alertify.error('There was an issue updating this status');
    });
  }
}
