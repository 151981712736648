import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, SupportService, AlertifyService, AuthService, User } from 'core';

@Component({
  selector: 'or-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  providers: [MessageService]
})

export class HelpComponent implements OnInit {

  @Output()
  closeHelp = new EventEmitter();

  sections = [{
    type: 'support', title: 'Contact Support', description: 'Report a bug or security issue to NavigatOR support team',
    instructions: 'To report a bug or security issue to the support team, fill the form below and click ‘submit’.', icon: 'fak fa-sos'
  },
  {
    type: 'ideas', title: 'Ideas and Suggestions', description: 'We love new ideas and opportunities to make work better',
    instructions: 'We love new ideas and opportunities to make work better, fill the form below and click ‘submit’.', icon: 'far fa-duotone fa-lightbulb'
  },
  { type: 'docs', title: 'Knowledge Base', description: 'View our Knowledge Base and Documentation for all OpinionRoute products', url: 'https://nav2.navigatorsurveys.com/onlinehelp', icon: 'far fa-solid fa-duotone fa-book' }];

  currentSection: any = null;
  canSubmitIdea: boolean = false;
  ideaSubmitted: boolean = false;
  fileName: string = null;

  constructor(public auth: AuthService,
    public alertify: AlertifyService,
    private supportService: SupportService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {

  }

  navigateToLink(url) {
    this.router.navigateByUrl(url);
  }


  checkIfCanSubmitIdea(subject, idea) {
    if (subject == null || subject.trim() === '' || idea == null || idea.trim() === '') {
      this.canSubmitIdea = false;
    }
    else {
      this.canSubmitIdea = true;
    }
  }


  goBack() {
    this.currentSection = null;
    this.fileName = null;
    this.canSubmitIdea = false;
  }

  cancelIdea(subject, idea, fileInput) {
    this.clearIdeaForm(subject, idea, fileInput);
    this.cancel();
  }

  clearIdeaForm(subject, idea, fileInput) {
    subject.value = '';
    idea.value = '';
    this.removeFile(fileInput);
  }

  cancel() {
    this.currentSection = null;
    this.canSubmitIdea = false;
    this.closeHelp.emit();
  }

  updateFile(file) {
    this.fileName = file.files[0]?.name;
  }

  removeFile(file) {
    file.value = '';
    this.fileName = null;
  }

  submitIdea(subject, body, fileInput) {
    var file = null;
    const formData = new FormData();
    if (fileInput.files.length > 0) {
      file = fileInput.files[0] as File;
      formData.append('file', file, this.fileName);
    }

    this.supportService.createIdea(formData, subject.value, body.value).subscribe(data => {
      let success = data;
      if (success) {
        this.alertify.success('Idea submitted successfully');
        this.clearIdeaForm(subject, body, fileInput);
        this.checkIfCanSubmitIdea(subject.value, body.value);
        this.closeHelp.emit();
      }
    }, error => {
      this.alertify.error('Unable to submit idea');
    });
  }
}
