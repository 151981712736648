import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-alerts',
  templateUrl: './project-alerts.component.html',
  styleUrls: ['./project-alerts.component.scss']
})
export class ProjectAlertsComponent implements OnInit {

  @Input() public set project(data) {

  }

  _project: any;

  @Output() onclose = new EventEmitter();

  constructor(private route: ActivatedRoute) {

   }


  ngOnInit() {
  }

  closePage(){
    this.onclose.emit(null);
  }

}
