import { AlertifyService } from 'core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExportFilesService } from 'projects/core/src/services/exportFiles.service';
import { ClosureService } from 'projects/core/src/services/closure.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-project-homepage-invoice',
  templateUrl: './project-homepage-invoice.component.html',
  styleUrls: ['./project-homepage-invoice.component.scss']
})
export class ProjectHomepageInvoiceComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.getClosureDetails();
  }

  @Output() callback = new EventEmitter();

  _project:any = {};
  closureDetails: any;
  closureComplete = false;

  constructor(private closureService: ClosureService,
    private alertify: AlertifyService,
    private exportFilesService: ExportFilesService) { }

  ngOnInit() {
  }

  getClosureDetails() {
    this.closureService.GetClosureDetails(this._project.id).subscribe(data => {
      this.processDetails(data);
    }, error => {
    });
  }

  processDetails(data) {
    let closureDetails = data;

    if (closureDetails.invoiceDate != null && typeof closureDetails.invoiceDate === 'string') {
      closureDetails.invoiceDate = new Date(closureDetails.invoiceDate);
    }
    if (closureDetails.billingDate != null && typeof closureDetails.billingDate === 'string') {
      closureDetails.billingDate = new Date(closureDetails.billingDate);
    }
    // Default Invoice and Billing Number
    // TO DO: This is quite specific to OpinionRoute so may need way to configure this
    if (closureDetails.invoiceNum == null || closureDetails.invoiceNum === '') {
      closureDetails.invoiceNum = this._project.bidNumber + 'I-1';
    }
    if (closureDetails.billingNum == null || closureDetails.billingNum === '') {
      closureDetails.billingNum = 'PB_' + this._project.bidNumber;
    }

    // If invoiced mark true
    this.closureComplete = (this._project.projectStatus === 'Invoiced' || closureDetails.step7CompletedBy != null);

    // Mark false even if invoiced to allow for a confirm
    if (closureDetails.step4CompletedBy === null) this.closureComplete = false; // Vendor Pricing
    if (closureDetails.step5CompletedBy === null) this.closureComplete = false; // Margin

    this.closureDetails = closureDetails;
    console.log(this.closureDetails);
  }

  downloadInvoice() {
    this.alertify.message('Your file will download once ready.');
    const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this._project.projectCode + '-Invoice.xlsx';
    this.exportFilesService.downloadInvoice(this._project.id, filename).subscribe(url => {
      window.location.assign(url);
    }, error => { this.alertify.error(error); });
  }

  openInvoice() {
    this.callback.emit({section: 'close', type: null});
  }

}
