<nav class="mini-remote btn-group filter-buttons fixed-bottom mb-5" [class.isLogged]="isLogged">
  <button type="button" class="btn text-white p-3" title="Display decipher question id" (click)="sendMessageExtension('showQuestion')">
    <i class="fas fa-question fa-xl cursor-pointer"></i>
  </button>
  @if (isLogged) {
    <button type="button" class="btn text-white p-3" title="Spell Check" (click)="sendMessageExtension('spellCheck')">
      <i class="fas fa-spell-check fa-xl cursor-pointer"></i>
    </button>
    <button type="button" class="btn text-white p-3" title="Edit" (click)="sendMessageExtension('showEdit')">
      <i class="fas fa-edit fa-xl cursor-pointer"></i>
    </button>
  }
</nav>


