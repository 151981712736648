<div class="card idsuite my-2">
  @if (!clientMode) {
    <div class="card-header" >
      <div class="row">
        <div class="col-10 p-0">
          <div class="d-flex">
            <div class="sectionbtn nav-item">
              <a class="nav-link or-primary cursor-pointer mb-2" [class.font-weight-bold]="section == 'CleanID'"
              (click)="loadSection('CleanID')">Project</a>
              @if (section == 'CleanID') {
                <a class="btn nav-btn-accent btn-sm mr-2 shadow-none ml-3"
                  href="https://nav2.navigatorsurveys.com/onlinehelp?page=en/kb/articles/cleanid-card" id="help_link"
                  target="_new"><i class="fa-regular fa-book-open"></i>&nbsp;&nbsp;View
                Knowledge Base</a>
              }
              @if (section == 'ResponseID') {
                <a class="btn nav-btn-accent btn-sm mr-2 shadow-none ml-3"
                  href="https://nav2.navigatorsurveys.com/onlinehelp?page=en/kb/articles/quality-tab-project-responseid"
                  id="help_link" target="_new"><i class="fa-regular fa-book-open"></i>&nbsp;&nbsp;View
                Knowledge Base</a>
              }
            </div>
            <div class="sectionbtn nav-item ml-4">
              <a class="nav-link or-primary cursor-pointer mb-2" [class.font-weight-bold]="section == 'ResponseID'"
              (click)="loadSection('ResponseID')">ResponseID</a>
            </div>
          </div>
        </div>
        <div class="col-2 p-0">
          @if (section == 'CleanID') {
            <div class=" or-secondary mt-1">
              <i (click)="openSendEmail(modalEmail)" class="fas fa-envelope fa-lg cursor-pointer opacity-90"
              containerClass="cleanid-report-tooltip" tooltip="Email CleanID Report" placement="top"></i>
              <i (click)="!reportLoading && previewReport(modalPreview)"
                class="fas fa-eye fa-lg cursor-pointer ml-2 opacity-90" [class.disabled]="reportLoading"
              containerClass="cleanid-report-tooltip" tooltip="Preview CleanID Report" placement="top"></i>
            </div>
          }
        </div>
      </div>
    </div>
  }

  <div class="card-body py-2">
    <div class="row">
      <iframe #myIframe frameborder="0" width="100%" height="2000" allowtransparency></iframe>
    </div>
  </div>
</div>
<!-- MODAL PREVIEW -->
<ng-template #modalPreview>
  <div class="modal-header">
    <p class="h5">CleanID Report Preview</p>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="openModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <iframe [srcdoc]="cleanIdPreviewReport" width="100%" height="750px" sandbox></iframe>
  </div>
</ng-template>

<!-- MODAL SEND EMAIL -->
<ng-template #modalEmail>
  <div class="modal-header">
    <p class="h5">Send CleanID Report</p>
  </div>
  <div class="modal-body">
    <form #sendForm="ngForm" novalidate>
      <div class="mx-5">
        <label for="name" class="required">To:</label>
        <input type="text" class="form-control w-100" [(ngModel)]="emailTo" name="emailTo" required
          [ngClass]="{'is-invalid': submitted && sendForm.controls['emailTo'].invalid }" />
        </div>
      </form>
      <div class="w-100 text-right mt-3 pr-5">
        <div class="btn btn-sm btn-light mr-2" (click)="closeSendEmail()" type="button">Cancel</div>
        <button class="btn btn-sm nav-btn-accent text-white" type="submit" (click)="sendReport(sendForm)">Send</button>
      </div>
    </div>
  </ng-template>