import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import * as moment from 'moment-timezone';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertifyService, User, DropdownService, UserService, AuthService, UtilsService, CountriesService, SupportAccessRequest } from 'core';
import { map } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {
  model: User;
  editForm: FormGroup;
  states: any[];
  country: any;
  countryList: any;
  submitted = false;
  callingCode: any;
  countries: any;
  selectedManagers: any = null;
  section;
  supportEnable: boolean
  supportRequest: SupportAccessRequest;
  denyButtonDisabled: boolean = false;
  saveButton: string = "Allow";
  accessExpiresDate = null;
  lastUpdatedTime = null;
  accessExpiresTimeTemp = 0;
  cancelButton: string = "Deny";
  timezones = [];

  @ViewChild('modalSupport')
  modalRefSupport: TemplateRef<any>;
  openModalRef: any;

  constructor(
    private alertify: AlertifyService, private modalService: BsModalService,
    private router: Router,
    private dd: DropdownService,
    public user: UserService,
    public auth: AuthService,
    private route: ActivatedRoute,
    private countriesService: CountriesService,
    public utils: UtilsService) { }

  ngAfterViewInit(): void {
    this.route.paramMap.subscribe(params => {
      const tab = params.get('section') || 'settings';
      this.section = tab;
    });
  }

  ngOnInit() {

    this.listAllTimeZones();
    this.model = this.auth.getUser();
    // this.states = this.dd.getStates();
    this.getSupportRequest();
    if (this.model.address.country) {
      this.countryList = [{ name: this.model.address.country, value: this.model.address.country }]
    } else {
      this.countryList = ""
    }

    if (this.model.managerId) {
      this.selectedManagers = [{ name: this.model.managerName, id: this.model.managerId }]
    }

    this.countriesService.getCountries().subscribe(data => {
      this.countries = data;
      let country = data.find(x => x.callingCode === this.model.callingCode);
      if (country) {
        if (country.callingCode == '001') {
          country.display = 'USA/Canada (+1)';
        }
        this.callingCode = [{ name: country?.display, value: country?.iso3 }];
      }
      else {
        this.callingCode = [];
      }
    }, error => {
      this.alertify.error('Unable to get countries');
    });

    this.editForm = new FormGroup({
      id: new FormControl(this.model.id, [Validators.required]),
      firstName: new FormControl(this.model.firstName, [Validators.required]),
      lastName: new FormControl(this.model.lastName, [Validators.required]),
      callingCode: new FormControl(this.model.callingCode),
      phone: new FormControl(this.model.phone),
      address1: new FormControl(this.model.address.address1),
      address2: new FormControl(this.model.address.address2),
      city: new FormControl(this.model.address.city),
      title: new FormControl(this.model.title),
      managerId: new FormControl(this.model.managerId),
      country: new FormControl(this.model.address.country),
      state: new FormControl(this.model.address.state),
      image: new FormControl(this.model.image),
      zip: new FormControl(this.model.address.zip),
      facebook: new FormControl(this.model.social.facebook),
      linkedIn: new FormControl(this.model.social.linkedIn),
      twitter: new FormControl(this.model.social.twitter),
      skype: new FormControl(this.model.social.skype),
      slackUsername: new FormControl(this.model.social.slackUsername)
    });
  }

  toggleChange(event) {
    this.supportEnable = event;
    if (!event && this.supportRequest != null) {
      this.supportRequest.isEnabled = false;
      this.user.saveSupportRequest(this.supportRequest).subscribe(data => {
        this.getSupportRequest();
        this.alertify.success('Support access removed successfully');
      }, error => {
        this.alertify.error(error);
      });
    } else {
      this.allowbuttonDisabled = true;
      this.saveButton = "Allow";
      this.cancelButton = "Deny";
      this.alertify.success('Support access enabled');
    }
  }
  denybtn: boolean = false;
  getSupportRequest() {
    this.user.GetSupportRequest(this.model.id).subscribe(data => {
      if (data != null) {
        this.supportRequest = data;
        this.allowbuttonDisabled = true;

        if (this.supportRequest.lastUpdatedTime != null) {
          this.lastUpdatedTime = new Date(moment.utc(this.supportRequest.lastUpdatedTime).local().format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
        }
        if (this.supportRequest.requestSent != null) {
          this.supportRequest.requestSent = new Date(moment.utc(this.supportRequest.requestSent).local().format('YYYY-MM-DDTHH:mm:ss.SSSZ'))
        }
        if (this.supportRequest.accessExpiresTime != null) {
          let expiresTime = moment.utc(this.supportRequest.accessExpiresTime).utcOffset(this.supportRequest.accessExpiresTimeZone);
          this.accessExpiresDate = new Date(expiresTime.format('YYYY-MM-DD'));
          this.accessExpiresTimeTemp = parseInt(expiresTime.format('HH'));
        }
        this.supportRequest.isEnabled = true;
        this.denybtn = true;
        this.denyButtonDisabled = false;
        this.supportEnable = false;
        if (this.supportRequest.status == "Approved") {
          this.supportEnable = true;
          this.denyButtonDisabled = false;
          this.saveButton = "Update";
          this.cancelButton = "Remove access";
        }
      } else {
        this.denybtn = false;
        this.supportRequest = {
          status: null,
          isEnabled: null,
          accessExpiresTime: null,
          requestSent: new Date(),
          requestSentFrom: null,
          accessExpiresTimeZone: null
        };
        this.accessExpiresTimeTemp = 0;
      }

      // Set the default timezone to the user's timezone
      if (!this.supportRequest.accessExpiresTimeZone) {
        this.supportRequest.accessExpiresTimeZone = moment().format('Z');
      }
    });
  }
  allowbuttonDisabled: boolean = true;
  checkCanSave(enable = false) {
    if (this.supportRequest.accessExpiresTimeZone != null && this.accessExpiresDate != null && this.accessExpiresDate.toString() !== 'Invalid Date' && this.supportRequest.accessExpiresTimeZone !== null) {
      //check if values have changed
        let expiresTime = moment.utc(this.supportRequest.accessExpiresTime).utcOffset(this.supportRequest.accessExpiresTimeZone);
        this.allowbuttonDisabled = (enable) ? false : (this.accessExpiresDate.toISOString().split('T')[0] == expiresTime.format('YYYY-MM-DD'));
    }
  }

  openModelSupport() {
    this.openModalRef = this.modalService.show(this.modalRefSupport, { class: 'nav-modal-style' });
  }

  sendSupportAcessEmail(email) {
    this.model.email = email.trim();
    this.user.sendSupportEmail(this.model).subscribe(data => {
      this.alertify.success('Support request sent successfully');
      this.openModalRef.hide()
    }, error => {
      this.alertify.error(error);
    });
  }
  denyAccessRequest() {
    this.supportEnable = false;
    if (this.cancelButton == "Deny") {
      this.supportRequest.isEnabled = false;
      this.supportRequest.status = "Deny"
    } else if (this.cancelButton == "Remove access") {
      this.supportRequest.isEnabled = false;
    }
    this.user.saveSupportRequest(this.supportRequest).subscribe(data => {
      window.location.reload();
      this.alertify.success('Support access removed successfully');
    }, error => {
      this.alertify.error(error);
    });

  }

  saveAccessRequest() {
    if (this.supportRequest.requestSentTo == null) {
      this.supportRequest.requestSentTo = this.auth.getUser().id;
    }

    if (this.saveButton == "Allow") {
      this.supportRequest.status = "Approved"
    }
    if (this.accessExpiresDate == null) {
      this.alertify.error('Please select a date for access to expire');
      return;
    }
    let hr = this.accessExpiresTimeTemp;
    let expiresTime = moment.utc({ year: this.accessExpiresDate.getFullYear(), month: this.accessExpiresDate.getMonth(), day: this.accessExpiresDate.getDate(), hour: hr, minute: 0, second: 0 });

    expiresTime = this.adjustTimeByTzOffset(expiresTime, this.supportRequest.accessExpiresTimeZone);
    this.supportRequest.accessExpiresTime = expiresTime.toISOString();

    this.supportRequest.isEnabled = true

    this.user.saveSupportRequest(this.supportRequest).subscribe(data => {
      if (this.saveButton == "Allow") {
        this.supportRequest.status = "Approved"
        this.saveButton = "Update";
        this.cancelButton = "Remove access";
      }
      this.alertify.success('Support request updated successfully');
      //this.getSupportRequest();
      window.location.reload();
    }, error => {
      this.alertify.error(error);
    });
  }
  adjustTimeByTzOffset(time, offset) {
    let offsetHr = parseInt(offset.charAt(1) + offset.charAt(2));
    let offsetMin = parseInt(offset.charAt(4) + offset.charAt(5));
    if (offset.charAt(0) == '+') {
      time = time.add(offsetHr * -1, 'hours');
      time = time.add(offsetMin * -1, 'minutes');
    }
    else {
      time = time.add(offsetHr, 'hours');
      time = time.add(offsetMin, 'minutes');
    }
    return time;
  }

  uploadCallback(event) {
    this.model.image = event.file;
    this.editForm.controls['image'].setValue(event.file);
  }

  cancel() {
    this.alertify.success('Changes reverted to last save');
    this.router.navigate(['/home']);
  }

  switchSection(section) {
    this.router.navigate([`/account/${section}`]);
  }

  edit() {
    this.submitted = true;

    if (!this.editForm.valid) {
      return;
    }

    if (this.editForm.controls['phone'].value?.length > 0) {
      if (this.editForm.controls['callingCode'].value === undefined || this.editForm.controls['callingCode'].value === null || this.editForm.controls['callingCode'].value?.length <= 0) {
        this.alertify.error("Please select a country calling code");
        return;
      } else {
        this.editForm.controls['phone'].setValue(+this.editForm.controls['phone'].value + "");
      }
    } else {
      this.editForm.controls['phone'].setValue(null);
    }
    if (this.editForm.controls['callingCode'].value?.length > 0) {
      if (this.editForm.controls['phone'].value === undefined || this.editForm.controls['phone'].value === null || this.editForm.controls['phone'].value?.length <= 0) {
        this.alertify.error("Please add a phone number");
        return;
      }
    } else {
      this.editForm.controls['callingCode'].setValue(null);
    }

    this.user.EditAccount(this.editForm.value).subscribe(data => {
      this.auth.setUser(data);
      this.user.GetUsers(true).subscribe(e => { }); //refresh the user list that might be cached
    }, error => {
      this.alertify.error('Unable to update ' + this.model.name);
    },
      () => {
        this.alertify.success('User settings saved');
        //this.router.navigate(['/home']);
      });
  }

  getCountryCode = (filter) => {
    return this.dd.getFullCountries();
  }

  getCountryList = (filter) => {
    return this.dd.getCountries();
  }

  setCountry(value) {
    if (value.length > 0) {
      this.editForm.controls['country'].setValue(value[0].value);
    } else {
      this.editForm.controls['country'].setValue("");
    }
  }

  setManager(value) {
    if (value.length > 0) {
      this.editForm.controls['managerId'].setValue(value[0].id);
    } else {
      this.editForm.controls['managerId'].setValue(null);
    }
  }

  getUserList = () => {
    var users = this.user.GetUsers().pipe(
      map(objects => objects.filter(obj => obj.id !== this.model.id))
    );
    return users;
  }

  setCallingCode(selected) {
    if (selected.length > 0) {
      let country = this.countries.find(x => x.iso3 === selected[0].value);
      this.editForm.controls['callingCode'].setValue(country.callingCode);
    }
    else {
      this.editForm.controls['callingCode'].setValue("");
    }
  }



  listAllTimeZones() {

    const topTimeZones = [
      'Pacific/Midway',        // UTC-11:00
      'Pacific/Honolulu',      // UTC-10:00
      'America/Anchorage',     // UTC-09:00
      'America/Los_Angeles',   // UTC-08:00
      'America/Denver',        // UTC-07:00
      'America/Chicago',       // UTC-06:00
      'America/New_York',      // UTC-05:00
      'America/Sao_Paulo',     // UTC-03:00
      'Atlantic/Azores',       // UTC-01:00
      'Europe/London',         // UTC+00:00
      'Europe/Berlin',         // UTC+01:00
      'Europe/Athens',         // UTC+02:00
      'Europe/Moscow',         // UTC+03:00
      'Asia/Tehran',           // UTC+03:30
      'Asia/Dubai',            // UTC+04:00
      'Asia/Kolkata',          // UTC+05:30
      'Asia/Bangkok',          // UTC+07:00
      'Asia/Singapore',        // UTC+08:00
      'Asia/Tokyo',            // UTC+09:00
      'Australia/Sydney',      // UTC+10:00
      'Pacific/Auckland',      // UTC+12:00
      'Pacific/Tongatapu',     // UTC+13:00
      'America/Argentina/Buenos_Aires', // UTC-03:00
      'America/Mexico_City'    // UTC-06:00
    ];

    const results = topTimeZones.map(zone => {
      const now = moment.tz(zone);
      const offset = now.utcOffset() / 60;
      const sign = offset >= 0 ? '+' : '-';
      const absOffset = Math.abs(offset);
      const whole = Math.floor(absOffset);
      const fraction = absOffset % 1;
      const minutes = fraction * 60;
      return {
        timeZoneName: zone,
        offset: `${sign}${whole < 10 ? '0' : ''}${whole}:${minutes < 10 ? '0' : ''}${minutes}`
      };
    });

    this.timezones = results;
  }

}
