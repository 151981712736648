<div class="card my-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="nav-secondary font-weight-bold mb-0 d-inline">Finance</h3>
      </div>
      <div class="col text-right">
        <div class="" dropdown #dropdown="bs-dropdown">
          <span id="reconciliationDropdown" dropdownToggle type="button"aria-controls="dropdown-basic">
            <!-- <i class="fak fa-ellipsis-vertical fa-md cursor-pointer grey4"></i> -->
          </span>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right grey4 cursor-pointer px-1" role="menu">
            <a class="dropdown-item nav-font14" href="assets/ReconciliationTemplate.xlsx">Action 1</a>
            <hr class="mx-2 my-0">
            <a class="dropdown-item nav-font14">Action 2</a>
          </ul>
        </div>
      </div>
    </div>
  </div>

  @if (mode == 'reduced') {
    <div class="card-body px-3">
      <div class="row grey4 nav-font14 mb-2">
        <div class="col">
          <div class="nav-font14 font-weight-normal">Projected Project Revenue</div>
        </div>
        <div class="col text-right">
          <div class="nav-font18 grey6 font-weight-normal">{{ totals.expectedProjectValue | currency }}</div>
        </div>
      </div>
      <div class="row grey4 nav-font14 mb-2">
        <div class="col">
          <div class="nav-font14 font-weight-normal">Current Project Revenue</div>
        </div>
        <div class="col text-right">
          <div class="nav-font18 grey6 font-weight-normal">{{ totals.currentProjectValue | currency }}</div>
        </div>
      </div>
      <div class="row grey4 nav-font14 mb-2 d-flex align-items-center">
        <div class="col">
          <div class="nav-font14 font-weight-normal">Client CPI</div>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          @if (segment.editMode == null || segment.editMode.sellCPI == null) {
            <div
              class="nav-font18 font-weight-normal editable d-inline-block grey6"
              (click)="enableEdit(segment, 'sellCPI')">
              {{ totals.clientCPI | currency }}
            </div>
          }
          @if (segment.editMode != null  && segment.editMode.sellCPI != null) {
            <input (focus)="$event.target.select()" type="number" class="form-control form-control-sm d-inline" [(ngModel)]="segment.editMode.sellCPI.value">
            <div class="d-inline-flex actions">
              <button (click)="cancelProjectSegmentCPI(segment)" type="button" class="btn nav-btn-primary btn-xs shadow-none mx-1">
                <i class="fas fa-times"></i>
              </button>
              <button (click)="saveProjectSegmentCPI(segment)" type="button" class="btn nav-btn-accent btn-xs shadow-none id-finance-client-cpi">
                <i class="fas fa-check"></i>
              </button>
            </div>
          }
        </div>
      </div>
      <div class="row grey4 nav-font14 mb-2 d-flex align-items-center">
        <div class="col">
          <div class="nav-font14 font-weight-normal">Current Gross Margin</div>
        </div>
        <div class="col or-secondary text-right">
          <div class="nav-font18 grey6 font-weight-normal">{{ totals.currentGM | percent:'1.1-1' }}</div>
        </div>
      </div>
      <div class="row pt-3">
        <div class="col px-0 px-0">
          <div class="col vendor-costs py-2">
            <span class="nav-secondary nav-font16">Vendor Costs</span>
          </div>
          <table class="table mb-0">
            <thead class="thead-light">
              <tr class="table-primary nav-font16">
                <td class="pl-3" style="width:40%">Vendor name</td>
                <td class="text-center" style="width:30%">CPI</td>
                <td class="text-center" style="width:30%">Current Total</td>
              </tr>
            </thead>
          </table>
          <div class="partners scrollbar overflow-y">
            <table class="table mb-0">
              <tbody>
                @for (partner of segment.projectSurveyPartners; track partner) {
                  @if (partner.show) {
                    <tr class="table-light grey6 nav-font16">
                      <td class="pl-3 py-2" style="width:40%">
                        <small [tooltip]="partner.partnerName" container="body" class="nav-accent-blue" placement="right"> {{ partner.partnerName }}</small>
                      </td>
                      <td class="text-center py-2" style="width:30%">
                        <div class="d-flex align-items-center justify-content-center">
                          @if (partner.editMode == null || partner.editMode.buyCPI == null) {
                            <small
                              class="editable"
                              (click)="enableEdit(partner, 'buyCPI')">
                              {{ partner.buyCPI | currency }}
                            </small>
                          }
                          @if (partner.editMode != null && partner.editMode.buyCPI != null) {
                            <input
                              type="number"
                              (focus)="$event.target.select()"
                              class="form-control form-control-sm d-inline"
                              [(ngModel)]="partner.editMode.buyCPI.value">
                              <div class="d-inline-flex actions">
                                <button (click)="cancelProjectPartnerCPI(partner)" type="button" class="btn nav-btn-primary btn-xs shadow-none mx-1">
                                  <i class="fas fa-times"></i>
                                </button>
                                <button (click)="saveProjectPartnerCPI(partner)" type="button" class="id-finance-vendor-cpi btn nav-btn-accent btn-xs shadow-none">
                                  <i class="fas fa-check"></i>
                                </button>
                              </div>
                            }
                          </div>
                        </td>
                        <td class="text-center py-2" style="width:30%">{{ partner.currentTotal | currency }}</td>
                      </tr>
                    }
                  }
                  <tr>
                    <td class="border-0 py-0 pt-2" colspan="3">
                      <hr class="w-100 my-0">
                    </td>
                  </tr>
                  <tr class="nav-font16">
                    <td class="pl-3 py-2 grey4 border-0" style="width:40%">
                      Total
                    </td>
                    <td class="text-center py-2 grey6 border-0" style="width:30%">
                      {{ totals.partnersAvgCPI | currency }}
                    </td>
                    <td class="text-center py-2 grey6 border-0" style="width:30%">
                      {{ totals.partnersTotal | currency }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
