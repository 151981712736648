<div class="card my-2 w-auto">
  <div class="card-header">
    <div class="row align-items-center ">
      <div class="col">
        <h3 class="nav-secondary font-weight-bold mb-0 d-inline">Reconciliation</h3>
      </div>
      <div class="col text-right">
        <div class="" dropdown #dropdown="bs-dropdown">
          <span id="reconciliationDropdown" dropdownToggle type="button"aria-controls="dropdown-basic">
            @if (showTable) {
              <i class="fak fa-ellipsis-vertical fa-md cursor-pointer grey4" [class.actions-mode]="actionsOpen" (click)="actionsOpen = !actionsOpen"></i>
            }
          </span>

          <!-- dropdown actions -->
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right grey4 cursor-pointer px-1" role="menu" (mouseleave)="dropdown.hide(); actionsOpen = !actionsOpen">
            <a class="dropdown-item nav-font14" href="assets/ReconciliationTemplate.xlsx">Download template</a>
            <hr class="mx-2 my-0">
            <a class="dropdown-item nav-font14" (click)="openModal(modalConfirm)">Rollback all</a>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body pt-2 text-center" [ngClass]="{'px-0 pb-0': reconciliation?.length > 0}">
    @if (!showTable) {
      <div class="row text-center">
        <div class="col my-5">
          <div class="d-flex d-flex justify-content-center">
            <div class="col-8 id-reconciliation-upload">
              <or-upload
                mainMsg="Select a CSV file to upload"
                smallMsg="or drag and drop the file here to begin"
                publicBucket=true
                [includeProgressBar]=true
                acceptTypes=".csv,.xlsx"
                [includeFileName]="true"
                [instanceId]="auth.getUser().instanceId"
                [S3Folder]="s3buckPath"
                directToS3=true
                (change)="recUpload($event.target.files)">
              </or-upload>
            </div>
          </div>
          <div>
            <span class="my-3 grey4 d-block">or</span>
            <a class="btn nav-btn-accent shadow-none" href="assets/ReconciliationTemplate.xlsx">Download template here</a>
          </div>
        </div>
      </div>
    }

    @if (showTable) {
      <!-- <div class="grid h-100">
        <div>
          <div class="overflow-y scrollbar">
            @if (reconciliation?.length > 0) {
              <or-list [items]="reconciliation" [layout]="layout" (selected)="download($event)"></or-list>
            }
            @if (reconciliation?.length == 0) {
              <div class="p-3 text-center">
                <span class="nav-font14 mb-0 font-weight-normal grey4">No Reconciliation Data.</span>
              </div>
            }
          </div>
        </div>
        <div>
          @if (auth.isInternal()) {
            <div class="nav-border1 p-2 h-100 border-bottom-0 id-reconciliation-upload">
              <or-upload
                mainMsg="Select file to upload"
                smallMsg="or drag and drop"
                publicBucket=true
                [includeProgressBar]=true
                acceptTypes=".csv,.xlsx"
                [includeFileName]="true"
                [instanceId]="auth.getUser().instanceId"
                [S3Folder]="s3buckPath"
                directToS3=true
                (change)="recUpload($event.target.files)">
              </or-upload>
            </div>
          }
        </div>
      </div> -->


      @if (reconciliation?.length == 0) {
        <div class="row mt-4 mb-3">
          <div class="col">
            <span class="font-weight-bold grey5 nav-font16">
              @if(canImport) {To reconcile Respondent IDs, import a file or import data from Decipher.}
              @else{To reconcile Respondent IDs, import a file.}
              </span>
          </div>
        </div>
        <div class="row mb-4 justify-content-center">
          <div class="col-8 colForUpload">
              <or-upload
                isCsv=true
                mode="qc-flow"
                [fileCleared]=uploadedFile
                showFileIcon=false
                mainMsg="Click to import file"
                smallMsg="or drag and drop file here"
                (change)="recUpload($event.target.files)"
                publicBucket=true
                [includeProgressBar]=true
                acceptTypes=".csv,.xlsx"
                [instanceId]="auth.getUser().instanceId"
                [S3Folder]="s3buckPath"
                directToS3=true
                [includeFileName]="true"
                [(ngModel)]="uploadedFile">
              </or-upload>
          </div>
        </div>
        @if(canImport) {
          <div class="row mb-4">
            <div class="col d-flex align-items-center justify-content-center">
              <span class="border-top" style="width: 40px; border-color: #F1F4FA;"></span>
              <span class="grey4 px-3">or</span>
              <span class="border-top" style="width: 40px; border-color: #F1F4FA;"></span>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <button class="btn btn-sm nav-btn-primary px-2 shadow-none font-weight-bold nav-font14"  (click)="reconcilationByDecipherAPI()">
                <i class="fak fa-upload grey4 pr-2 nav-font16 font-weight-normal"></i>Click here to import from Decipher
              </button>
            </div>
          </div>
        }
      }

      @if (reconciliation?.length > 0) {
        <div class="grid h-100">
          <div>
            <div class="overflow-y scrollbar">
              <or-list [items]="reconciliation" [layout]="layout" (selected)="download($event)"></or-list>
            </div>
          </div>
          <div>
            @if (auth.isInternal()) {
              <div class="nav-border1 p-2 h-100 border-bottom-0 border-right-0 id-reconciliation-upload">
                <div class="row mt-2 mb-4">
                  <div class="col-8">
                    <span class="font-weight-bold grey5 nav-font16"><i class="fak fa-lg fa-upload grey4 pr-2 font-weight-normal"></i>Import File</span>
                  </div>
                </div>
                <div class="row mb-4 justify-content-center">
                  <div class="col">
                      <or-upload
                        isCsv=true
                        mode="qc-flow"
                        [fileCleared]=uploadedFile
                        showFileIcon=false
                        mainMsg="Click to import file"
                        smallMsg="or drag and drop file here"
                        (change)="recUpload($event.target.files)"
                        publicBucket=true
                        [includeProgressBar]=true
                        acceptTypes=".csv,.xlsx"
                        [instanceId]="auth.getUser().instanceId"
                        [S3Folder]="s3buckPath"
                        directToS3=true
                        [includeFileName]="true"
                        [(ngModel)]="uploadedFile">
                      </or-upload>
                  </div>
                </div>
                @if(canImport) {
                <div class="row mb-4">
                  <div class="col d-flex align-items-center justify-content-center">
                    <span class="border-top" style="width: 40px; border-color: #F1F4FA;"></span>
                    <span class="grey4 px-3">or</span>
                    <span class="border-top" style="width: 40px; border-color: #F1F4FA;"></span>
                  </div>
                </div>
                
                <div class="row">
                  <div class="col">
                    <button class="btn btn-sm nav-btn-primary px-2 shadow-none font-weight-bold nav-font14"  (click)="reconcilationByDecipherAPI()">
                      Import from Decipher
                    </button>
                  </div>
                </div>
                }

              </div>
            }
          </div>
        </div>
      }

    }
  </div>
</div>

<ng-template #modalConfirm>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="openModalRef.hide()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0 text-center">
    <h3 class="font-weight-bold grey5">Are you sure you want to rollback all Reconciliations for this Project?</h3>
    <h5 class="grey5 text-center d-block my-3">
      <i class="fak fa-info grey4" (click)="openModalRef.hide()"></i>
      This action cannot be undone.
    </h5>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100" >
      <div class="col text-right pr-0">
        <button class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="openModalRef.hide()">Cancel</button>
        <or-spinner-button label="Confirm" customClass="btn btn-sm nav-btn-accent shadow-none" (callback)="rollbackAll()"></or-spinner-button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalReconciliationError>
  <div class="modal-header">
    <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18"><i
    class="fak fa-trash font-weight-normal grey4 mr-2"></i>Reconciliation Error</h2>
    <div class="d-flex justify-content-end">
      <i class="fas fa-close grey4" (click)="openModalReconError.hide()"></i>
    </div>
  </div>

  <div class="modal-body grey4">
    <div class="row mt-2 nav-font16">
      <div class="col d-flex align-items-center">The following issues were found in the file.</div>
    </div>
    <div class="col reassign-tasks scrollbar overflow-y overflow-x-hidden">
      <div class="row my-3 mt-4 align-items-center justify-content-center">
        <div class="col-3">
          <p class="">Row:</p>
        </div>
        <div class="col-3">
          <p class="">Error:</p>
        </div>
        <div class="col text-left">
          <p class="">Value</p>
        </div>
      </div>
      @for (error of errorList.slice(0, 10); track error; let idxerror = $index) {
        <div class="row nav-font14">
          <div class="col-3">
            <p class="">{{error.code}}</p>
          </div>
          <div class="col-3">
            <p class="">{{error.type}}</p>
          </div>
          <div class="col text-left">
            <p class="">{{error.message}}</p>
          </div>
        </div>
      }
      @if (this.errorList.length > 10) {
        <div class="row nav-font14">
          <div class="col text-left">
            <p class="">+{{errorList.length-10}} more</p>
          </div>
        </div>
      }
    </div>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100" >
      <div class="col text-right pr-0">
        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="openModalReconError.hide()">Close</button>
      </div>
    </div>
  </div>
</ng-template>
