import { Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { AlertifyService, PartnerContactService, Project, ProjectService, UtilsService, ReportsService } from 'core';
import { ExportFilesService } from 'projects/core/src/services/exportFiles.service';
import {formatDate} from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
@Component({
  selector: 'app-project-card-vendor',
  templateUrl: './project-card-vendor.component.html',
  styleUrls: ['./project-card-vendor.component.scss']
})
export class ProjectCardVendorComponent implements OnInit {

  @ViewChild('vendorauditmodal')
  vendorAuditModalRef: TemplateRef<any>;
  vendorAuditModalOpenRef;
  vendorAuditItems = [];
  vendorAuditColumns = [
    {label: 'Date', id: 'auditDateTime', sortable: true, type: 'datetime'},
    {label: 'User', id: 'user', sortable: true, filterable: true},
    {label: 'Old Value', id: 'oldValue', sortable: true},
    {label: 'New Value', id: 'newValue', sortable: true}
  ];
  @Input() project: Project;
  @Input()  public set respondents(data) {
    this.processData(data);
  }
  auditLog = [];
  vendors = [];
  daysPercentagePast = 50;
  constructor(private alertify: AlertifyService, private projectService: ProjectService, private utils: UtilsService,
     private partnerContactService: PartnerContactService,   private exportFilesService: ExportFilesService,
     private modalService: BsModalService,
     private reportService: ReportsService) { }

  ngOnInit(): void {
    this.vendors = this.project.projectSegments[0].projectSurveyPartners;
    this.vendors.forEach( vendor =>{

      if(this.project.projectStatus == 'Closed' || this.project.projectStatus == 'Invoiced')
        vendor.partnerStatus = this.project.projectStatus;
      else if (vendor.partnerStatus == 'Closed')
        vendor.partnerStatus = 'Closed';
      else if (this.project.projectStatus == 'Paused' || this.project.projectStatus == 'Test')
        vendor.partnerStatus = this.project.projectStatus;
      else if (vendor.partnerStatus == 'Test')
        vendor.partnerStatus = 'Test';
       else if (this.project.projectStatus == 'Full Launch' && vendor.partnerStatus == 'Full Launch')
        vendor.partnerStatus = 'Full Launch';
      else
        vendor.partnerStatus = 'Soft Launch';

      vendor.partnerPms = this.project.projectTeam.filter(e => e.projectRole == 'Vendor PM' && e.partnerId.toLowerCase() == vendor.partnerId.toLowerCase()).map( e => ({ name: e.displayName, id: e.userId}));
      if(vendor.partnerPms == null){

        vendor.partnerPms.push({
          id: vendor.partnerContactId,
          name: vendor.partnerContactName
        })
      }
    })

    this.reportService.AuditReportData(this.project.id, null, null, null, null).subscribe( data => {
      const offset = new Date().getTimezoneOffset();
      data.forEach(e => {
        const d = new Date(e.auditDateTime);
        e.auditDateTime = d.setMinutes(d.getMinutes() - offset);
      });
      this.auditLog = data;
    });

    const daysLeft = (this.project.totalDaysLeft < 0 ) ? 0 : this.project.totalDaysLeft;
    const totalDays = this.project.totalDays;
    this.daysPercentagePast = 100 - Math.round(daysLeft * 100 / totalDays);
  }
  processData(data){

    this.vendors.forEach( vendor =>{
      vendor.complete = data.filter(e => e.vendorId.toLowerCase() == vendor.partnerId.toLowerCase()).reduce((previousValue, currentValue) => previousValue + currentValue.complete, 0);
    })
  }
  progressStyle(status, daysPercentagePast, percentComplete): string {
    if(status === 'Paused' || status === 'Closed' || status === 'Invoiced') {
      return ' or-darkgray-bg';
    }
    else if ( daysPercentagePast > percentComplete) {
      return 'bg-danger';
    }
    return '';
  }
  downloadIdFile(vendor){
      this.alertify.message('The download will start when ready.');
    const projectList = [ this.project.id];
    let filename = 'Download-' + this.project.projectName + '-' + formatDate(new Date(), 'MM-dd-yyyy HH:mm:ss', 'en').toString() + '.xlsx';
    this.exportFilesService.downloadVendorParticipantData(projectList, filename,vendor.partnerId).subscribe(url => {
      window.location.assign(url);
    }, error => {
      this.alertify.error('There was a problem downloading this file');
   },
   () => {
      // All Good
   });

  }
  showVendorCPIHistory(vendor) {
    this.vendorAuditItems = this.auditLog.filter( e => e.column == 'BuyCPI - ' + vendor.partnerName);
    this.vendorAuditModalOpenRef = this.modalService.show(this.vendorAuditModalRef, {class: 'modal-lg' });
  }
  showVendorStatusHistory(vendor) {
    this.vendorAuditItems = this.auditLog.filter( e => e.column == 'PartnerStatus - ' + vendor.partnerName);
    this.vendorAuditModalOpenRef = this.modalService.show(this.vendorAuditModalRef, {class: 'modal-lg' });
  }
  copyEntryUrl(vendor){
    this.utils.copyTextToClipboard(vendor.surveyEntryUrl);
    this.alertify.success('Entry Url Copied');
  }

  copyRedirectUrls(vendor){
    const copyString = '\n' + 'Complete RedirectUrl  = ' + vendor.completeRedirectURL + '\n' +
    'Terminate RedirectUrl = ' + vendor.terminateRedirectURL + '\n' +
    'Overquota RedirectUrl = ' + vendor.overQuotaRedirectURL + '\n' +
    'QC Redirect Url        = ' + vendor.qcRedirectURL;

    this.utils.copyTextToClipboard(copyString);
    this.alertify.success('Redirect URLs copied');

  }
  cancelSaveProjectId(vendor){
    vendor.partnerProjectNumberEdit = vendor.partnerProjectNumber;
    vendor.editProjectIdMode = false;
  }
  saveProjectId(vendor){
    this.projectService.SavePartnerProjectNumber(this.project.id, vendor.partnerId, vendor.partnerProjectNumberEdit).subscribe({
      next: (data) => {

        vendor.partnerProjectNumber = vendor.partnerProjectNumberEdit;
        vendor.editProjectIdMode = false;

        this.alertify.success('Project Number updated successfully');
      },
      error: (e) => this.alertify.error('There was an error saving the Project Number')
    })
  }
  enableEditVendorPm(vendor){

    if(vendor.contacts == null || vendor.contacts.length == 0){
      vendor.contacts = [];
      this.partnerContactService.GetPartnerContacts(vendor.partnerId).subscribe( data => {
        vendor.contacts= data;
      });
    }
    vendor.partnerPmsEdit = vendor.partnerPms;
    vendor.editPmMode = true;
  }

  cancelVendorPM(vendor){
    vendor.partnerPmsEdit = vendor.partnerPms;
    vendor.editPmMode = false;
  }
  updateVendorPM(event, vendor){
    vendor.partnerPmsEdit = event;
  }
  saveVendorPM(vendor){
    var newVendorPms = vendor.partnerPmsEdit.map( e => e.id);
    this.projectService.SavePartnerProjectPms(this.project.id, vendor.partnerId, newVendorPms).subscribe({
      next: (data) => {
        vendor.partnerPms = vendor.partnerPmsEdit;
        vendor.editPmMode = false;
        this.alertify.success('PM updated successfully');
      },
      error: (e) => this.alertify.error('There was an error saving the PM')
    })
  }
  enableTestURL(vendor){
    vendor.entryfields = vendor.surveyEntryUrl.split('?')[1].replace('s2=','').split('=').map( e => e.replace('&',''));
    vendor.entryfields.pop();
    vendor.testparams = {};
    vendor.testid = '';
    vendor.testMode = true;
  }
  openTestUrl(vendor){
    let url = vendor.surveyEntryUrl + vendor.testid;
    for(let p in vendor.testparams)
    {
      url = url.replace(p+'=', p+'='+vendor.testparams[p]);
    }
    url = url + '&ortesttoken=' + this.project.id;

    window.open(url, "_new");
  }
  cancelTestURL(vendor){
    vendor.testMode = false;
  }
  enableEditRedirects(vendor){
    vendor.completeRedirectURLEdit = vendor.completeRedirectURL;
    vendor.terminateRedirectURLEdit = vendor.terminateRedirectURL;
    vendor.overQuotaRedirectURLEdit = vendor.overQuotaRedirectURL;
    vendor.qcRedirectURLEdit = vendor.qcRedirectURL;

    vendor.editRedirectsMode = true;
  }

  cancelEditRedirects(vendor){
    vendor.editRedirectsMode = false;
  }
  saveRedirects(vendor){

    const newLinks = {
      entryURL : '',
      completeRedirectURL: vendor.completeRedirectURLEdit,
      terminateRedirectURL: vendor.terminateRedirectURLEdit,
      overQuotaRedirectURL : vendor.overQuotaRedirectURLEdit,
      qcRedirectURL: vendor.qcRedirectURLEdit
    };
    var entry = vendor.surveyEntryUrl.split('?')[0];
    newLinks.entryURL = entry + '?' + this.projectService.generateEntryUrlParams(entry,newLinks);

    this.projectService.SavePartnerProjectRedirects(this.project.id, vendor.partnerId, newLinks).subscribe({
      next: (data) => {
        vendor.surveyEntryUrl = newLinks.entryURL;
        vendor.completeRedirectURL = newLinks.completeRedirectURL;
        vendor.terminateRedirectURL = newLinks.terminateRedirectURL;
        vendor.overQuotaRedirectURL = newLinks.overQuotaRedirectURL;
        vendor.qcRedirectURL = newLinks.qcRedirectURL;

        vendor.editRedirectsMode = false;

        this.alertify.success('URLs updated successfully');
      },
      error: (e) => this.alertify.error('There was an error saving the URLs')
    })

  }

}
