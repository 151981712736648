<div class="nav-content">
    <!-- BREADCRUMBS -->
    <div class="row">
        <div class="col-12 breadcrumb mr-auto bg-transparent mb-3 py-0">
            <div class="d-flex align-items-center">
            <div class="nav-secondary d-flex align-items-center">
                <app-pagetitle></app-pagetitle>
            </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <a class="btn nav-btn-accent btn-sm mr-2 mb-2 shadow-none"
                    href="https://nav2.navigatorsurveys.com/onlinehelp?page=en/kb/articles/dashboards-client" id="help_link"
                    target="_new"><i class="fa-regular fa-book-open"></i>&nbsp;&nbsp;View Knowledge Base</a>
            </div>
        </div>
        <div>
            <iframe #myIframe frameborder="0" width="100%" height="7000" allowtransparency></iframe>
        </div>
    </div>
</div>