@if (auth.isInternal() || auth.isClient()) {
  <div class="d-flex">
    <div class="sidebar fixed-top">
      <div class="first-item d-flex align-items-center cursor-pointer text-center text-truncate px-1 border-bottom">
        <span class="nav-neutral nav-font16 py-3 pl-3 text-truncate">Summary menu</span>
      </div>
      <div class="slideout-menu submenu p-2 grey5 border-bottom">
        <div class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1 mb-1" [class.active]="currentSideSection === 'info'" (click)="showSideSection('info')">
          <i class="fak fa-info icon grey4"></i>
          <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center">
            Project Info @if (sidebarSectionOpen && currentSideSection === 'info') {
            <i class="fak fa-left-arrow fa-rotate-180 font-weight-normal ml-1 nav-font16 nav-secondary"></i>
          }
        </span>
      </div>
    <div class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1 mb-1" [class.active]="currentSideSection === 'notes'" (click)="showSideSection('notes')">
      <i class="fak fa-notes icon grey4"></i>
      <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center">
        Notes @if (sidebarSectionOpen && currentSideSection === 'notes') {
        <i class="fak fa-left-arrow fa-rotate-180 font-weight-normal ml-1 nav-font16 nav-secondary"></i>
      }
    </span>
  </div>
  <div class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1 mb-1" [class.active]="currentSideSection === 'project-alerts'" (click)="showSideSection('project-alerts')">
    <i class="fak fa-project-alerts icon grey4"></i>
    <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center">
      Alerts @if (sidebarSectionOpen && currentSideSection === 'project-alerts') {
      <i class="fak fa-left-arrow fa-rotate-180 font-weight-normal ml-1 nav-font16 nav-secondary"></i>
    }
  </span>
</div>
<div class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1 mb-1" [class.active]="currentSideSection === 'downloads'" (click)="showSideSection('downloads')">
  <i class="fas fa-file-arrow-down icon grey4"></i>
  <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center">
    Data Exports @if (sidebarSectionOpen && currentSideSection === 'downloads') {
    <i class="fak fa-left-arrow fa-rotate-180 font-weight-normal ml-1 nav-font16 nav-secondary"></i>
  }
</span>
</div>
</div>
</div>
<div class="project-family-body w-100 position-relative">
  <!-- BREADCRUMBS -->
  <div class="col-12 m-0 py-0 breadcrumb mr-auto">
    <div class="row">
      <div class="col-12 m-0 p-0 mr-auto d-flex align-items-center">
        <div class="grey4 d-flex align-items-center">
          <app-pagetitle subPageOpen="true"></app-pagetitle>
          <span class="nav-font14 font-weight-bold nav-secondary text-uppercase cursor-pointer">{{ currentFamily.familyCode }}</span>
        </div>
      </div>
      <div class="col-12 p-0 client-name">
        @if (currentFamily?.familyClient) {
          <a class="nav-font-16 nav-accent-blue font-weight-bold mr-2 cursor-pointer" href="#" [routerLink]="['/clients', currentFamily?.familyClientId]">{{currentFamily?.familyClient}}</a>
        }
        @if (currentFamily?.familyClientPo) {
          <span class="nav-font-16 grey4">({{currentFamily?.familyClientPo}})</span>
        }
      </div>
    </div>
  </div>
  <!-- Body -->
  <div class="summary-detail-container">
    <div class="project-sidebar fixed-top" [class.sidebar-project-open]="sidebarSectionOpen == true">
      <div class="col text-right mt-2">
        <i class="fas fa-close fa-lg cursor-pointer grey4 ml-3" (click)="closeSideBar()"></i>
      </div>
      <div>
        @if (currentSideSection == 'info') {
          <app-project-info [bidNumber]="currentFamily.bidNumber" [projectType]="projectType" (onChange)="handleSettingsChange()"></app-project-info>
        }
        @if (currentSideSection == 'notes') {
          <app-notes [bidNumber]="currentFamily.bidNumber" class="app-notes {{ cardNotes.expanded ? 'expanded' : '' }}"></app-notes>
        }
        @if (currentSideSection == 'my-tasks') {
          <ng-container class="taskover">
            <app-mytask [bidNumber]="currentFamily.bidNumber" (callback)="callbackTasks($event)" [refresh]="refreshTasks"></app-mytask>
          </ng-container>
        }
        @if (currentSideSection == 'project-alerts') {
          <app-rules #rules [bidNumber]="currentFamily.bidNumber" (callback)="callbackRules($event)"></app-rules>
        }
        @if (currentSideSection == 'downloads') {
          <app-project-download [data]="{ bidNumber: currentFamily.bidNumber }"></app-project-download>
        }
      </div>
    </div>
    <div class="mt-4">
      @if (loading) {
        <div class="col-6 d-flex align-items-center justify-content-end mt-4">
          <div class="loading loading-lg" ></div>
        </div>
      }
      <!-- multi project dashboard -->
      <div class="mb-4">
        <div class="header row mx-0 mb-3">
          <h3 class="grey5 font-weight-bold nav-font18">Project Summary Page</h3>
          <div class="ml-auto grey4 mr-2">
            <span class="font-weight-bold nav-font16 grey5 mr-3">CleanID Report</span>
            <i (click)="openSendEmail(modalEmail)" class="fas fa-envelope fa-lg cursor-pointer opacity-90"
            containerClass="cleanid-report-tooltip"  tooltip="Email CleanID Report" placement="top"></i>
            <i (click)="!reportLoading && previewReport(modalPreview)"
              class="fas fa-eye fa-lg cursor-pointer ml-2 opacity-90" [class.disabled]="reportLoading"
            containerClass="cleanid-report-tooltip" tooltip="Preview CleanID Report" placement="top"></i>
          </div>
        </div>
        <div class=" w-auto dashboard-grid">
          <!-- Pie Charts -->
          <div class="card border-0 my-2 w-auto current-projects">
            <div class="card-header pt-3">
              <div class="row align-items-center">
                <div class="col-6">
                  <h4 class="grey4 nav-font-16 font-weight-bold mb-0 d-inline">Current Projects</h4>
                </div>
                @if (!this.auth.isClient()) {
                  <div class="col-6">
                    <h4 class="grey4 nav-font-16 font-weight-bold mb-0 d-inline ml-3">Tasks</h4>
                  </div>
                }
              </div>
            </div>
            <div class="card-body pt-2">
              <div class="row h-100">
                <div class="col">
                  @if (highchartOptionsCurrentProjects) {
                    <highcharts-chart
                      id="projectsChart"
                      [Highcharts]="highcharts"
                      [options]="highchartOptionsCurrentProjects"
                      style="width: 100%; height: 100%; display: block;">
                    </highcharts-chart>
                  }
                </div>
                @if (!this.auth.isClient()) {
                  <div class="v-line"></div>
                }
                <div class="col mt-3">
                  @for (item of stageColors; track item) {
                    @if (this.stageCount[item[0]]) {
                      <div class="row mb-3">
                        <svg width="24" height="24">
                          <rect width="24" height="24" rx="3" ry="3" [style.fill]="item[1]" />
                        </svg>
                        <span class="nav-font-14" (click)="legendClick(item[0])">{{ item[0] }}</span>
                      </div>
                    }
                  }
                </div>
                @if (!this.auth.isClient()) {
                  <div class="col-4">
                    @if (highchartOptionsTasks) {
                      <highcharts-chart
                        id="tasksChart"
                        [Highcharts]="highcharts"
                        [options]="highchartOptionsTasks"
                        style="width: 100%; height: 100%; display: block;">
                      </highcharts-chart>
                    }
                  </div>
                  @if (totalTasks > 0) {
                    <div class="col-2 mt-3">
                      <ul>
                        <div class="row mb-3">
                          <svg width="24" height="24">
                            <rect width="24" height="24" rx="3" ry="3" style="fill:#55BA2E;" />
                          </svg>
                          <span class="nav-font-14" (click)="legendClick('Open')">Open</span>
                        </div>
                        <div class="row mb-3">
                          <svg width="24" height="24">
                            <rect width="24" height="24" rx="3" ry="3" style="fill:#C72F1E;" />
                          </svg>
                          <span class="nav-font-14" (click)="legendClick('Overdue')">Overdue</span>
                        </div>
                      </ul>
                    </div>
                  }
                }
              </div>
            </div>
          </div>
          <!-- Projects In Trouble -->
          <div class="card border-0 my-2 w-auto" style="min-height: 223px">
            <div class="card-body">
              <div class="row nav-font14 text-center d-flex justify-content-center">
                <div class="col-12">
                  <span class="d-block grey5 mb-2 font-weight-bold">{{multiProjectTasks?.projectsInTrouble ?? 0}}</span>
                  <span class="d-block grey4 textOutline">Projects in Trouble</span>
                </div>
                <hr class="my-2 mx-5 hrTable">
              </div>
              <div class="row nav-font14 text-center d-flex justify-content-center">
                <div class="col-12" (click)="goToTaskList('overdue')">
                  <span class="d-block grey5 mb-2 font-weight-bold nav-error">{{multiProjectTasks?.tasksOverdue ?? 0}}</span>
                  <span  class="d-block grey4 textOutline">Tasks Overdue</span>
                </div>
                <hr class="my-2 mx-5 hrTable">
              </div>
              <div class="row nav-font14 text-center d-flex justify-content-center">
                <div class="col-12" (click)="goToTaskList('todo')">
                  <span class="d-block grey5 mb-2 font-weight-bold">{{multiProjectTasks?.tasksToDo ?? 0}}</span>
                  <span class="d-block grey4 textOutline">Tasks to Do</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Team Members -->
          <div class="card border-0 my-2 w-auto team-members">
            <div class="card-header pt-3">
              <div class="row">
                <div class="col">
                  <h4 class="grey4 nav-font-16 font-weight-bold mb-0 d-inline">Team members</h4>
                </div>
                <div class="col-3 grey2 text-nowrap nav-font-12 text-right">({{teamMemberTotal}})</div>
              </div>
            </div>
            <div class="card-body pt-2 scrollbar overflow-y">
              <div class="row h-100 px-2">
                <div class="col mb-3 pb-3 team-border text-left">
                  <div class="row">
                    <div class="col pl-0">
                      <h5 class="grey3 nav-font-12 ml-3">Programmers</h5>
                    </div>
                  </div>
                  <div class="grid-members">
                    @for (programmer of teamMembers?.programmers; track programmer) {
                      <div class="mt-2">
                        <span container="body" [tooltip]="programmer.displayName" placement="bottom">
                          <or-profile-image [name]="programmer.displayName" [url]="programmer.image" showInitials="true" size="medium"></or-profile-image>
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <div class="col mb-3 pb-3 team-border text-left">
                  <div class="row">
                    <div class="col pl-0">
                      <h5 class="grey3 nav-font-12 ml-3">Project managers</h5>
                    </div>
                  </div>
                  <div class="grid-members">
                    @for (projectManager of teamMembers?.projectManagers; track projectManager) {
                      <div class="mt-2">
                        <span container="body" [tooltip]="projectManager.displayName" placement="bottom">
                          <or-profile-image [name]="projectManager.displayName" [url]="projectManager.image" showInitials="true" size="medium"></or-profile-image>
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <div class="col mb-3 pb-3 text-left">
                  <div class="row">
                    <div class="col pl-0">
                      <h5 class="grey3 nav-font-12 ml-3">Account managers</h5>
                    </div>
                  </div>
                  <div class="grid-members">
                    @for (sale of teamMembers?.sales; track sale) {
                      <div class="mt-2">
                        <span container="body" [tooltip]="sale.displayName" placement="bottom">
                          <or-profile-image [name]="sale.displayName" [url]="sale.image" showInitials="true" size="medium"></or-profile-image>
                        </span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- project card view loaded -->
    @if (!loading && !projectListLoading) {
      <div>
        <div class="header col d-flex px-0">
          <h3 class="grey5 font-weight-bold nav-font18"> Projects
            <span class="grey3 ">({{relatedProjects?.length}})</span>
          </h3>
          <div class="d-inline ml-auto d-flex align-items-center">
            <span class="grey5 mr-2">Overall pace</span>
            <div class="overall-pace-container px-2">
              <div class=" nav-font14 d-flex">
                <div class=" progress-bar-col w-100" style="position: relative">
                  <or-progress-guage [value]="(totalCompletesCount * 100) / totalAllocationCount" mode="parent" [expected]="100" [inDanger]="liveProjectCount > 0 && behindPaceCount == liveProjectCount" bgDanger="nav-error"></or-progress-guage>
                </div>
                <div class="text-right text-nowrap pl-2">
                  <span class="grey5">{{totalCompletesCount}}/{{totalAllocationCount}} n</span>
                </div>
              </div>
            </div>
          </div>
          <div class=" mr-3">
            @if (!this.auth.isClient()) {
              <span class="btn btn-sm nav-btn-accent shadow-none mb-1 px-2 mx-3" (click)="addProjectToFamily()"  tooltip="Click here to create a new project!" placement="left">Add Project</span>
              <div class="btn-group ml-auto" dropdown>
                <span id="projectImportExportDropdown" dropdownToggle type="button" class="btn-lg p-2" aria-controls="dropdown-basic">
                  <i class="fak fa-ellipsis-vertical grey4 ml-1"></i>
                </span>
                <ul
                  id="dropdown-basic"
                  *dropdownMenu
                  class="dropdown-menu dropdown-menu-right"
                  role="menu"
                  aria-labelledby="projectImportExportDropdown"
                  style="width: 250px;">
                  <li role="menuitem" class="p-2">
                    <or-action-button
                      label="Participant Export"
                      description="Download participants across projects"
                      icon="fak fa-download"
                      (selected)="bulkDLParticipantExport()">
                    </or-action-button>
                  </li>
                  <li role="menuitem" class="p-2">
                    <or-action-button
                      label="Full"
                      description="This export will provide all fields available (General, Device, CleanID, ResponseID, Quotas, Passthrough Variables)"
                      icon="fak fa-copy"
                      (selected)="downloadFulldata()">
                    </or-action-button>
                  </li>
                  <li role="menuitem" class="p-2">
                    <or-action-button
                      label="Copy Entry Urls"
                      description="Get URL's for projects in list"
                      icon="fak fa-copy"
                      (selected)="openModal(modalUrls)">
                    </or-action-button>
                  </li>
                </ul>
              </div>
            }
          </div>
        </div>
        <div>
          <div class="row mx-0">
            <div class="col">
              <div class="row family-project-grid my-3 grey4">
                {{sortRelatedList()}}
                @for (project of relatedProjects; track project) {
                  <div class="card-cell">
                    @if (currentView.cardStyle == 'survey') {
                      <app-project-card-survey
                        [project]="project"
                        [actions]="rowActions"
                        [isFavorite]="favorites[project.id]"
                        (favoriteChange)="FavoriteChange($event, project)"
                        (action)="handleAction($event)">
                      </app-project-card-survey>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    <!-- project card view loading -->
    @if (!loading && projectListLoading) {
      <div>
        <div class="header row ml-4 mr-1">
          <h3 class="grey5 font-weight-bold nav-font18"> Projects
            <span class="grey3 ">({{relatedProjects?.length}})</span>
          </h3>
        </div>
        <div>
          <div class="row mx-4">
            @if (loading) {
              <div class="col-6 d-flex align-items-center justify-content-end mt-4">
                <div class="loading loading-lg" ></div>
              </div>
            }
          </div>
        </div>
      </div>
    }
    <!-- performance overview -->
    <div class="mb-4">
      <div>
        <app-project-family-survey-performance-table [respondents]="respondents" [familyName]="currentFamily?.familyName"></app-project-family-survey-performance-table>
      </div>
    </div>
    <!-- multi project tasks -->
    @if (!this.auth.isClient()) {
      <div class="mb-4 tasks">
        <div class="header row mx-0 mb-3">
          <h3 class="grey5 nav-font18 font-weight-bold">Tasks
            <span class="grey3">&nbsp;({{totalTaskCount}})</span>
          </h3>
        </div>
        <app-schedule [project]="null" [bidNumber]="currentFamily?.bidNumber" (callback)="callbackTasks($event)" [refresh]="refreshTasks"></app-schedule>
      </div>
    }
  </div>
</div>
</div>
}

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete '{{this.selectedProject.projectName}}'?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>

<!-- MODAL COPY ENTRY URLS -->
<ng-template #modalUrls>
  <div class="modal-header">
    <p class="h4 mr-3 mb-0">Entry Urls</p>
    <button type="button" class="btn btn-sm btn-light" (click)="copyAllUrls()"><i class="far fa-copy"></i> Copy All Urls</button>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="openModalRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card border-secondary mb-3" >
      <div class="card-body text-secondary">
        <h5 class="display-6 mb-3">
          Redirects
          <i class="far fa-copy float-right" (click)="copyRedirectUrls()"></i>
        </h5>
        <p class="card-text" >
          <small class="d-block font-weight-bold">Complete</small>
          <small class="d-block">{{ redirectUrls.completeRedirectURL }}</small>
        </p>
        <p class="card-text" >
          <small class="d-block font-weight-bold">Terminate</small>
          <small class="d-block">{{ redirectUrls.terminateRedirectURL }}</small>
        </p>
        <p class="card-text" >
          <small class="d-block font-weight-bold">Overquota</small>
          <small class="d-block">{{ redirectUrls.overQuotaRedirectURL }}</small>
        </p>
        <p class="card-text" >
          <small class="d-block font-weight-bold">QC</small>
          <small class="d-block">{{ redirectUrls.qcRedirectURL }}</small>
        </p>
      </div>
    </div>

    @for (item of listUrls; track item) {
      <div class="card border-secondary mb-3">
        <div class="card-body text-secondary">
          <h5 class="display-6 mb-3">
            {{ item.vendor }}
            <i class="far fa-copy float-right" (click)="copyProjectsUrls(item)"></i>
          </h5>
          @for (url of item.urls; track url) {
            <p class="card-text">
              <small class="d-block font-weight-bold">{{ url.projectName }}</small>
              <small class="d-block">{{ url.surveyEntryUrl }}</small>
            </p>
          }
        </div>
      </div>
    }
  </div>
</ng-template>

<!-- MODAL PREVIEW -->
<ng-template #modalPreview>
  <div class="modal-header">
    <p class="h5">CleanID Report Preview</p>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="openModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <iframe [srcdoc]="cleanIdPreviewReport" width="100%" height="750px" sandbox></iframe>
  </div>
</ng-template>

<!-- MODAL SEND EMAIL -->
<ng-template #modalEmail>
  <div class="modal-header">
    <p class="h5">Send CleanID Report</p>
  </div>
  <div class="modal-body">
    <form #sendForm="ngForm" novalidate>
      <div class="mx-5">
        <label for="name" class="required">To:</label>
        <input type="text" class="form-control w-100" [(ngModel)]="emailTo" name="emailTo" required
          [ngClass]="{'is-invalid': submitted && sendForm.controls['emailTo'].invalid }" />
        </div>
      </form>
      <div class="w-100 text-right mt-3 pr-5">
        <div class="btn btn-sm btn-light mr-2" (click)="closeSendEmail()" type="button">Cancel</div>
        <button class="btn btn-sm nav-btn-accent text-white" type="submit" (click)="sendReport(sendForm)">Send</button>
      </div>
    </div>
  </ng-template>
