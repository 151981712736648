import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, AlertifyService, SurveyTesterService, SurveyTesterComments, SurveyTesterSessionActivity, SurveyTesterProject } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SurveyTesterActivityComponent } from './survey-tester-activity/survey-tester-activity.component';
import writeXlsxFile from "write-excel-file";
import * as moment from 'moment';
import { UploaderService } from 'projects/core/src/services/uploader.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'survey-tester',
  templateUrl: './survey-tester.component.html',
  styleUrls: ['./survey-tester.component.scss']
})
export class SurveyTesterComponent implements OnInit, AfterViewInit {

  selectedMenu = '';
  statusFilter = ['open'];
  loading = true;
  tool = 'decipher';
  isLoggedDecipher = false;
  showQuestion = false;
  showAnswerme = false;
  sidebarOpen = false;
  surveyUrl = '';
  surveyCode = '';
  surveyId = '';
  newComment = { commentType: '', questionId: '', answerId: '' } as SurveyTesterComments;
  defaultCommentType = '';
  activity: SurveyTesterSessionActivity = null;
  replayActivity: any = null;
  suveyProject = {} as SurveyTesterProject;
  questionId = '';
  questionCode = '';
  pageQuestions = [];
  answerId = '';
  userSessionId = ''
  sessionFilterId = ''
  sessionFilterName = ''
  _loadType = ''
  surveyInfo;
  surveyStatus = '';
  answers = [];
  isLive = false;
  extensionVersion = '';
  commentLength = 0;
  installingExtension = false;
  device = 'desktop';
  desktopWidth = '';
  playMode = false;
  client = null;
  comments;
  confirmitFormData = null;

  files:any = [];
  s3buckPath: string = 'survey-tester';
  uploadedFile;


  settings = {
    showQuestion: false,
    showAnswerme: false,
    showTestWarningMessage: true,
    toggleDecipherToolbar: true
  }

  editorConfig = {
    placeholder: "Please be as specific as possible.",
    toolbar: {
      language: 'en',
      items: [
        'bold',
        'italic',
        'underline',
        'link'
      ]
    }
  };

  @ViewChild(SurveyTesterActivityComponent) testerActivity;
  @ViewChild('myIframe') myIframe;

  openModalRef: any;

  @ViewChild('modal') modalRef: BsModalRef;
  @ViewChild('modalConfirmFinalize') modalRefFinalize: BsModalRef;
  @ViewChild('modalConfirmReopen') modalRefReopen: BsModalRef;
  @ViewChild('modalDownloadExtension') modalRefDownload: BsModalRef;
  @ViewChild('modalConfirmitRestore') modalRefConfirmitRestore: BsModalRef;

  constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private st_service: SurveyTesterService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      let url = params.url;
      this.client = params.client;
      this.addExtensionListeners();
      this.checkForExtension();

      var a = document.createElement('a');
      a.href = url;
      if (!(a.host && a.host != window.location.host)) {
        this.alertify.error('Url is invalid.');
        this.logout();
      }
      let decodedUrl = decodeURIComponent(url).replace('#', '');
      this.surveyUrl = decodedUrl;
      let sUrl=url
      if (sUrl && sUrl.length > 0) {
        sUrl= sUrl.slice(0, -1);  // Removes the last character
      }

      this.suveyProject = this.st_service.getSurveyProject(sUrl, this.client);

      this.st_service.getSurveyId(this.suveyProject).subscribe((data) => {
        this.surveyId = data.id;
        this.saveUserSessions();
        this.getProjectInfo();
      }, error => {
        this.alertify.error('There was an error getting surveyId');
      });
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.loadSurveyUrl();
    }, 1 * 500);
  }

  getProjectInfo() {
    this.st_service.getProjectInfo(this.surveyId).subscribe((data) => {
      this.surveyInfo = data;
      this.surveyStatus = data.status;
      const status = ['Soft Launch', 'Full Launch'];
      this.isLive = data?.projects.some(x => status.includes(x.projectStatus));
      this.getComments();
    },
      error => {

      });

    let confirmitFormData = localStorage.getItem('confirmitFormData_'+this.surveyId);
    this.confirmitFormData = confirmitFormData == null ? null : JSON.parse(confirmitFormData);

  }

  loadSurveyUrl() {
    const iframe = this.myIframe.nativeElement;
    iframe.id = "OrIframe";
    if (iframe != null && iframe.hasAttribute('src')) {
      iframe.attributes.src.value = this.surveyUrl + `&client=${this.client}`;
    }
    var style = window.getComputedStyle(iframe, null);
    this.desktopWidth = style.getPropertyValue("width");
  }

  addExtensionListeners() {
    window.addEventListener('message', (event) => {
      if (event.data.type && event.data.type === 'or_surveytester_pageload') {
        this.send_message("or_surveytester_init", this.client);
        if(this.confirmitFormData != null)
        {
          this.openModal(this.modalRefConfirmitRestore, 'modal-md st-modal-style');
        }
      }
      else if (event.data.type && event.data.type === 'or_surveytester_pagedata') {
        const pageDetails = event.data.payload;
        if (pageDetails != null && pageDetails.questions != null && pageDetails.questions.length > 0) {
          this.isLoggedDecipher = pageDetails.isLoggedDecipher;
          this.pageQuestions = pageDetails.questions;
          this.answers = pageDetails.questions[0].answers;
          this.sendDataToExtension();
        }
      }
      else if (event.data.type && event.data.type === 'or_surveytester_version') {

        if (this.extensionVersion == '') {
          this.extensionVersion = event.data.payload || '';
          if (this.openModalRef) {
            this.closeModal();
          }
        }
      } else if (event.data.type && event.data.type === 'or_surveytester_addComment') {
        if (this.openModalRef != null) {
          this.closeModal();
        }
        let data = event.data.payload;
        this.newComment.questionId = data.questionId;
        this.changeQuestion();
        this.newComment.answerId = data.answerId;
        this.newComment.questionText = data.questionText;
        this.newComment.answerText = data.answerText;
        this.newComment.surveyTesterProjectId = this.surveyId;
        this.newComment.status = 'open';
        this.openSection('comments');
        this.openModal(this.modalRef);
      } else if (event.data.type && event.data.type === 'or_surveytester_viewHistory') {
        let data = event.data.payload;
        this.questionId = data.questionId;
        this.answerId = data.answerId;
        this.openSection('comments');
      } else if (event.data.type && event.data.type === 'or_surveytester_saveActivity') {
        let data = event.data.payload;
        data.forEach(e => {
          const activity: SurveyTesterSessionActivity = {
            surveyId: this.surveyId,
            sessionId: this.userSessionId,
            answers: JSON.stringify(e.answers),
            questionID: e.questionId,
            questionText: e.questionText,
            questionType: e.answerType,
            selectedAnswerText: '',
            respondAnswerId: JSON.stringify(e.respondAnswerId)
          };
          if (e.answerType == 'textarea') {
            activity.selectedAnswerText = e.respondAnswerId[0];
          }
          else {
            let selectedAnswerText = [];
            e.respondAnswerId?.forEach(answerId => {
              selectedAnswerText.push(e.answers?.find(e => e.answerId == answerId)?.answerText);
            });
            activity.selectedAnswerText = selectedAnswerText.join(', ');
          }
          this.saveUserActivity(activity);
        });

      }
      else if (event.data.type && event.data.type === 'or_surveytester_confirmitFormData') {
        let data = event.data.payload;
        localStorage.setItem('confirmitFormData_'+this.surveyId, JSON.stringify(data));
      }
    });


  }
  checkForExtension() {

    const checkVersionInterval = setInterval(() => {
      if (this.extensionVersion != '')
        clearInterval(checkVersionInterval);
      (window as any).self.postMessage({ type: "or_surveytester_get_version", data: null }, "*");
    }, 200);

    // Check extension installation
    setTimeout(() => {
      if (this.extensionVersion == '') {
        this.openModal(this.modalRefDownload, 'modal-md st-modal-style');
      }
      clearInterval(checkVersionInterval);
    }, 5000);
  }

  sendDataToExtension() {
    if (this.comments == null)
      return;

    var res = {
      showQuestion: this.showQuestion,
      showAnswerme: this.showAnswerme,
      answered: [],
      comments: [],
      replayActivity: this.replayActivity
    };

    this.pageQuestions.forEach(q => {
      let questionComments = this.comments.filter(x => x.questionId == q.questionId);
      res.comments.push({
        commentCounts: questionComments.length,
        questionId: q.questionId,
      });

      if (this.replayActivity != null) {

        var question = this.replayActivity.filter(e => e.questionID == q.questionId);
        if (question != null && question.length > 0 && question[0].chosenAnswers != null) {
          let chosen = {
            answers: [],
            questionId: q.questionId,
          };
          question.forEach(x => {
            x.chosenAnswers.forEach(a => {
              chosen.answers.push(a.answerId);
            });
          });

          res.answered.push(chosen);
        }
      }

    });

    this.send_message("or_surveytester_updatePageDetails", res);

    if (!this.settings.toggleDecipherToolbar)
       this.send_message('or_surveytester_toggleDecipherToolbar', false);
  }

  closeMenu() {
    if (this.selectedMenu) return;
    this.selectedMenu = '';
    this.sidebarOpen = false;
    this.statusFilter = ['open'];
  }

  toggleMenu() {
    if (this.sidebarOpen === true) {
      this.closeSections()
    } else {
      this.sidebarOpen = true
      this.openSection('info')
    }
  }

  openSection(type) {
    if (this.selectedMenu !== type) {
      this.selectedMenu = type;
      this.sidebarOpen = true;
    }
  }
  closeSections() {
    this.selectedMenu = '';
    this.sidebarOpen = false;
    this.statusFilter = ['open'];
  }

  send_message(type, data) {
    var msg = {
      type: type,
      data: data,
      client: this.client
    };
    this.myIframe.nativeElement.contentWindow.postMessage(msg, "*");
  }

  confirm(type) {
    switch (type) {
      case 'finalize':
        this.openModal(this.modalRefFinalize);
        break;
      case 'reopen':
        this.openModal(this.modalRefReopen);
        break;
    }
  }

  finalize() {
    this.st_service.finalizeSurvey(this.surveyId).subscribe(data => {
      this.alertify.success('Survey testing has been finalized.');
      this.getProjectInfo();
      if (this.openModalRef)
        this.closeModal();

    }, error => {
      this.alertify.error('There was a problem finalizing survey.');
    });
  }

  reopen() {
    this.st_service.reopenSurvey(this.surveyId).subscribe(data => {
      this.alertify.success('Survey testing has been reopened.');
      this.getProjectInfo();
      if (this.openModalRef)
        this.closeModal();

    }, error => {
      this.alertify.error('There was a problem reopening survey.');
    });
  }
  refreshPage() {
    location.reload();
  }
  getExtension() {
    window.open('https://chrome.google.com/webstore/detail/opinionroute-survey-teste/pdfhildcbagemnohebanpbggnjddplhd', '_new');
    this.installingExtension = true;
  }

  openModal(template: any, classes: string = 'modal-sm st-modal st-modal-style') {
    this.openModalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false, class: classes });
  }

  closeModal() {
    this.newComment = { commentType: this.defaultCommentType, questionId: '', answerId: '' } as SurveyTesterComments;
    this.commentLength = 0;
    this.files = [];
    this.statusFilter = ['open'];
    this.openModalRef.hide();
    this.openModalRef = null;
  }

  turnoffSpinner() {
    setTimeout(() => {
      this.loading = false;
    }, 100);
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('comments');
    this.router.navigate(['']);
    this.alertify.error('You are now logged out');
  }

  callbackFromInfoComponent($evt) {
    const evt = $evt || null;
    if (evt.type == 'or_surveytester_showQuestion') {
      this.showQuestion = evt.data;
      this.settings.showQuestion = this.showQuestion;
      this.send_message(evt.type, this.showQuestion);
    }
    if (evt.type == 'or_surveytester_toggleDecipherToolbar') {
      this.showAnswerme = evt.data;
      this.settings.showAnswerme = this.showAnswerme;
      this.send_message(evt.type, this.showAnswerme);
      this.settings.toggleDecipherToolbar = evt.data;
      this.toggleDecipherToolbar();
    } else if (evt.type == 'or_surveytester_hideWarning') {
      this.settings.showTestWarningMessage = evt.data;
      this.send_message(evt.type, evt.data);
    }
  };

  toggleDecipherToolbar() {
    if (this.settings.toggleDecipherToolbar) {
      this.send_message('or_surveytester_toggleDecipherToolbar', true);
    } else {
      this.send_message('or_surveytester_toggleDecipherToolbar', false);
    }
  }

  emulateDevice(device) {
    const iframe = this.myIframe.nativeElement;
    this.device = device;

    var width = device == "desktop" ? this.desktopWidth : device == "tablet" ? "731px" : "378px";
    iframe.style.width = width;

    var paddingLeft = device == "desktop" ? 'auto' : device == "tablet" ? '600px' : '750px';
    iframe.parentNode.setAttribute('style', 'padding-left: ' + paddingLeft + ';');

    iframe.parentNode.style.left = this.sidebarOpen && this.selectedMenu !== '' ? '230px' : '80px';
  }

  /**** Comments ****/
  getComments() {
    this.loading = true;
    this.st_service.getComments(this.surveyId, true).subscribe((data) => {
      this.comments = data;
      this.loading = false;
      this.sendDataToExtension();
    },
      error => {
        this.alertify.error('There was an error getting comments');
      });
  }

  callbackFromCommentsComponent($evt) {
    const evt = $evt || null;
    if (evt?.type == 'or_surveytester_jump') {
      const q = evt?.question.questionId.replace('question_','');
      const url = this.surveyUrl.split('?');
      if (this.client == 'decipher') {
        this.surveyUrl = `${url[0]}?start-at=${q}`;
      }
    } else if (evt?.type == 'or_surveytester_clearFilters') {
      this.questionId = '';
      this.answerId = '';
      this.sessionFilterId = '';
      this.sessionFilterName = '';
    }
    else if (evt?.type == 'or_surveytester_refresh') {
      this.getComments();
    }

  }
  addComment() {
    this.newComment.questionId = '';
    this.newComment.answerId = '';
    this.newComment.surveyTesterProjectId = this.surveyId;
    this.newComment.status = 'open';
    this.openModal(this.modalRef);
  }
  changeQuestion() {
    if (this.newComment.questionId == '') {
      this.newComment.answerId = '';
      this.answers = [];
    }
    else {
      var newQ = this.pageQuestions.find(e => e.questionId == this.newComment.questionId)
      this.answers = (newQ == null) ? [] : newQ.answers;
    }
  }

  saveComment() {
    if (!this.newComment.commentType) {
      this.alertify.error('Comment Type is mandatory');
      return;
    }

    if (this.commentLength == 0) {
      this.alertify.error('Please type in a comment.');
      return;
    }

    if (this.client !== 'Other') {
      if (this.newComment.questionId) {
        const q = this.pageQuestions.find(e => e.questionId == this.newComment.questionId);
        this.newComment.questionText = q.questionText;
      }
      if (this.newComment.answerId) {
        this.newComment.answerText = this.answers.find(x => x.answerId == this.newComment.answerId).answerText;
      }
    }

    if (this.files?.length > 0) {
      this.newComment.attachments = this.files;
    }

    this.defaultCommentType = this.newComment.commentType;

    this.st_service.saveComment(this.newComment, this.userSessionId).subscribe(data => {
      this.alertify.success('Comment Saved.');
      this.newComment = { commentType: this.defaultCommentType, questionId: '', answerId: '' } as SurveyTesterComments;
      this.commentLength = 0;
      this.files = [];
      this.getProjectInfo();

      if (this.openModalRef)
        this.closeModal();

    }, error => {
      this.alertify.error('There was a problem saving comment.');
    });
  }

  wysiwygMessage($event) {
    this.newComment.commentText = $event;
  }

  getCommentCount($event) {
    this.commentLength = $event;
  }
  getSurveyTesterCommentsByQuestionId(surveyId: string, questionId: string, answerId: string, answersc: any, callback) {
    this.st_service.getSurveyTesterCommentsByQuestionId(surveyId, questionId, answerId).subscribe((data) => {
      let openComments = data.filter(x => x.status == 'open' && x.answerId == '');
      let anopencomment: any = [];
      for (var i = 0; i < answersc.length; i++) {
        let openansComments = data.filter(x => x.status == 'open' && x.answerId == answersc[i].answerId);
        let ansComments = data.filter(x => x.answerId == answersc[i].answerId);
        var ansmsg = {
          answerId: answersc[i].answerId,
          openAnsComments: ansComments.length,
          ansComments: openansComments.length
        };
        anopencomment.push(ansmsg);
      }
      var msg = {
        comments: data.length,
        openComments: openComments.length,
        answersComment: anopencomment
      };
      callback(msg);
    },
      error => {
        this.alertify.error('There was an error getting comments');
      });
  }

  /**** Activity/Session  */
  saveUserSessions() {
    this.st_service.saveSession(this.surveyId).subscribe((data) => {
      let userSession = data;
      this.userSessionId = userSession.id;
    }, error => {
      this.alertify.error('There was a problem saving this project.');
    });
  }

  saveUserActivity(activity) {
    this.st_service.saveActivity(activity).subscribe((data) => {
    }, error => {
      this.alertify.error('There was a problem saving this project.');
    });
  }

  stopReplay() {
    this.playMode = false;
    this._loadType = 'or_surveytester_replayStopped';
    this.replayActivity = null;
    this.sendDataToExtension();
  }

  callbackFromActivityComponent($evt) {

    const evt = $evt || null;
    if (evt.type === 'or_surveytester_showComments') {
      this.sessionFilterId = evt?.data.id;
      this.sessionFilterName = `Session ${evt?.data.counter}`;
      this.openSection('comments');
    }
    else if (evt.type === 'or_surveytester_replayStarted') {
      this.replayActivity = null;
      this.st_service.getSessionActivities(evt.session.id).subscribe((activity) => {
        activity.forEach(item => {

          item.timeStamp = new Date(item.timeStamp);
          item.answers = JSON.parse(item.answers);
          item.respondAnswerId = JSON.parse(item.respondAnswerId);
          item = this.addAnswerText(item);
        });

        activity = activity.sort((a, b) => (a.timeStamp - b.timeStamp));
        this.replayActivity = activity;
        this.playMode = true;
        this._loadType = 'or_surveytester_replayStarted';
        this.sendDataToExtension();

        if (activity.length == 0) {
          this.alertify.error("Replay not Availble");
        }
      });
    }
    else if (evt.type === 'or_surveytester_replayStopped') {
      this.stopReplay();
    }
  }

  addAnswerText(item) {
    var chosenAnswers = []
    var listOfRepsonses = item.respondAnswerId
    if (listOfRepsonses) {
      listOfRepsonses.forEach(id => {
        item.answers.forEach(answer => {
          if (answer.answerId == id) {
            chosenAnswers.push({ answerId: id, answerText: answer.answerText, answerType: answer.answerType })
          }
        });
      });
    }
    item.chosenAnswers = chosenAnswers;
    return item;
  }

  export() {
    const now = new Date();
    let newFileName = '';
    if (this.surveyInfo.projects.length > 0) {
      newFileName = this.surveyInfo.projects[0].projectCode + '-' + this.surveyInfo.projects[0].projectName + ' comments ' + now.toLocaleDateString() + '.xlsx'
    } else {
      newFileName = 'ExportComments.xlsx'
    }
    this.st_service.exportComments(this.surveyId, true).subscribe(data => {
      // Reverse order of replies
      for (var row in data) {
        if (data[row]["replies"]) {
          data[row]["replies"] = data[row]["replies"].reverse();
        }
      }

      // Filter out unwanted columns
      var dataToExport = data.map(row => ({
        "Submitted Date": (moment(row.createdDate)).format("YYYY/MM/DD HH:mm:ss"),
        "Submitted By": row.createdBy,
        "Question ID": row.questionId,
        "Answer": row.answerText,
        "Comment": row.commentText,
        "Status": row.status,
      }));

      // Add replies and addressed columns
      var numOfReplies = 0;

      for (var row in data) {
        var status = data[row]["status"];

        if (status === "pending") {
          status = "sentForApproval";
          dataToExport[row]["Closed By"] = "";
          dataToExport[row]["Closed Date"] = "";
        }
        if (status === "open") {
          dataToExport[row]["Addressed By"] = "";
          dataToExport[row]["Addressed Date"] = "";
          dataToExport[row]["Closed By"] = "";
          dataToExport[row]["Closed Date"] = "";
        } else {
          dataToExport[row]["Addressed By"] = data[row][status + "By"];
          dataToExport[row]["Addressed Date"] = (moment(data[row][status + "Date"])).format("YYYY/MM/DD HH:mm:ss");
        }
        if (status === "cancelled" || status === "approved") {
          dataToExport[row]["Closed By"] = data[row]["closedBy"];
          dataToExport[row]["Closed Date"] = (moment(data[row]["closedDate"])).format("YYYY/MM/DD HH:mm:ss");
        }
        var replyIndex = 0;
        while (data[row]["replies"][replyIndex]) {
          dataToExport[row]["Reply " + (replyIndex + 1) + " - Author"] = data[row]["replies"][replyIndex]["createdBy"];
          dataToExport[row]["Reply " + (replyIndex + 1) + " - Date"] = (moment(data[row]["replies"][replyIndex]["createdTime"])).format("YYYY/MM/DD HH:mm:ss");
          dataToExport[row]["Reply " + (replyIndex + 1) + " - Text"] = data[row]["replies"][replyIndex]["replyText"];
          replyIndex++;
        }
        if (replyIndex > numOfReplies) numOfReplies = replyIndex;
      }

      // Format data
      var getInitials = function (author) {
        var names = author.split(/[ ']/);
        var initials = '';
        for (var i = 0; i < names.length; i++) {
          if (names[i].length > 0 && names[i] !== '') {
            initials += names[i][0].toUpperCase();
          }
        }
        return initials;
      }

      for (var row in dataToExport) {
        var id = dataToExport[row]["Question ID"];
        var qtext = dataToExport[row]["Answer"]
        var comment = dataToExport[row]["Comment"];
        var submittedBy = dataToExport[row]["Submitted By"];
        var addressedBy = dataToExport[row]["Addressed By"];
        var closedBy = dataToExport[row]["Closed By"];

        if (id) dataToExport[row]["Question ID"] = id.replace("question_text_q", "");
        if (qtext) dataToExport[row]["Answer"] = qtext
        if (comment) dataToExport[row]["Comment"] = comment.replace(/<\/?p>/g, "");
        if (submittedBy) dataToExport[row]["Submitted By"] = submittedBy
        if (addressedBy) dataToExport[row]["Addressed By"] = addressedBy;
        if (closedBy) dataToExport[row]["Closed By"] = closedBy;

        for (var replyIndex = 0; replyIndex < numOfReplies; replyIndex++) {
          var replyAuthor = dataToExport[row]["Reply " + (replyIndex + 1) + " - Author"];
          var replyText = dataToExport[row]["Reply " + (replyIndex + 1) + " - Text"];

          if (replyAuthor) dataToExport[row]["Reply " + (replyIndex + 1) + " - Author"] = replyAuthor;
          if (replyText) dataToExport[row]["Reply " + (replyIndex + 1) + " - Text"] = replyText.replace(/<\/?p>/g, "");
        }
      }

      var firstRow = [
        { value: 'Submitted Date', fontWeight: 'bold' },
        { value: 'Submitted By', fontWeight: 'bold' },
        { value: 'Question ID', fontWeight: 'bold' },
        { value: 'Answer', fontWeight: 'bold' },
        { value: 'Comment', fontWeight: 'bold' },
        { value: 'Status', fontWeight: 'bold' },
        { value: 'Addressed By', fontWeight: 'bold' },
        { value: 'Addressed Date', fontWeight: 'bold' },
        { value: 'Closed By', fontWeight: 'bold' },
        { value: 'Closed Date', fontWeight: 'bold' }
      ];

      for (var replyIndex = 1; replyIndex < numOfReplies + 1; replyIndex++) {
        firstRow.push({ value: "Reply " + (replyIndex) + " - Author", fontWeight: 'bold' });
        firstRow.push({ value: "Reply " + (replyIndex) + " - Date", fontWeight: 'bold' });
        firstRow.push({ value: "Reply " + (replyIndex) + " - Text", fontWeight: 'bold' });
      }

      var finalData: any = [firstRow];

      for (var row in dataToExport) {
        var rowData = [
          { value: dataToExport[row]['Submitted Date'], alignVertical: "center" },
          { value: dataToExport[row]['Submitted By'], alignVertical: "center" },
          { value: dataToExport[row]['Question ID'], alignVertical: "center" },
          { value: dataToExport[row]['Answer'], alignVertical: "center" },
          { value: dataToExport[row]['Comment'], alignVertical: "center", wrap: true },
          { value: dataToExport[row]['Status'], alignVertical: "center" },
          { value: dataToExport[row]['Addressed By'], alignVertical: "center" },
          { value: dataToExport[row]['Addressed Date'], alignVertical: "center" },
          { value: dataToExport[row]['Closed By'], alignVertical: "center" },
          { value: dataToExport[row]['Closed Date'], alignVertical: "center" }
        ];

        for (var replyIndex = 1; replyIndex < numOfReplies + 1; replyIndex++) {
          rowData.push({ value: dataToExport[row]["Reply " + (replyIndex) + " - Author"], alignVertical: "center" });
          rowData.push({ value: dataToExport[row]["Reply " + (replyIndex) + " - Date"], alignVertical: "center" });
          rowData.push({ value: dataToExport[row]["Reply " + (replyIndex) + " - Text"], alignVertical: "center", wrap: true });
        }

        finalData.push(rowData);
      }

      // Set width for columns
      var columns = [{ width: 14 }, { width: 12 }, { width: 11 }, { width: 55 }, { width: 11 }, { width: 12 }, { width: 14 }];
      for (var replyIndex = 1; replyIndex < numOfReplies + 1; replyIndex++) {
        columns.push({ width: 14 }, { width: 12 }, { width: 35 })
      }

      writeXlsxFile(finalData, { columns, sheet: 'Comments', stickyRowsCount: 1, fileName: newFileName });
    });
  }
  confirmitRestore () {
    this.closeModal();
    this.send_message("or_surveytester_confirmitRestore", this.confirmitFormData);
    this.confirmitFormData = null;
  }
  confirmitDoNotRestore () {
    this.closeModal();
    this.confirmitFormData = null;
  }

  attach(event) {
    if (!event) return;
    const key = event.file.split('/').slice(3).join('/');
    this.files.push({name: event.origFile.name, key: key});
  }

  removeAttachment(key) {
    this.files.splice(this.files.findIndex(x => x.key === key), 1);
  }

}
