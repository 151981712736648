<div class="container-fluid mb-5 nav-content">
  <!-- BREADCRUMBS -->
  <div class="row">
    <div class="col-12 breadcrumb mr-auto bg-transparent">
      <div class="d-flex align-items-center">
        <div class="grey4 d-flex align-items-center">
          <app-pagetitle></app-pagetitle>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3 mx-5 alerts-container">
    <div class="col">
      <div class="row mb-3">
        <div class="col alerts-header d-flex align-items-center">
          <span class="nav-secondary nav-font18 font-weight-bold mr-3">
            <i class="fak fa-global-alerts mr-2 grey4 font-weight-normal nav-font20"></i>System Alerts
          </span>
          <!-- Dropdown Add -->
          <div class="btn shadow-none nav-btn-accent alerts-add-btn px-2 py-0 btn-group position-relative mr-3" dropdown dropdownToggle #dropdownAdd="bs-dropdown"
            id="button-basic"
            controls="dropdown-basic"
            [class.active]="dropdownAdd.isOpen"
            [class.disabledBtn]="addMode || editMode"
            placement="bottom"
            containerClass="disabled-btn-tooltip">
            <span class="nav-font14 font-weight-bold"><i class="far fa-plus pr-2 text-white"></i>Create System Alert</span>
            <i class="px-1 ml-1 fa fa-light fa-pipe"></i>
            <i class="fas ml-1 font-weight-normal nav-font16" [class.fa-chevron-down]="!dropdownAdd.isOpen" [class.fa-chevron-up]="dropdownAdd.isOpen" ></i>
            <div *dropdownMenu class="dropdown-menu dropdown-menu-right nav-font14 position-absolute" role="menu" aria-labelledby="button-basic">
              <div class="option mx-2 px-2 mb-1 py-1" (click)="addAlert('metrics')">Quality Metrics</div>
              <hr class="mx-2 my-0">
              <div class="option mx-2 px-2 my-1 py-1" (click)="addAlert('tasks')">Tasks</div>
            </div>
          </div>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <div class="d-flex align-items-center justify-content-end">
            <i class="fak fa-system-alerts-bell grey4 mr-2 nav-font20"></i>
            <span class="nav-font16 grey5">Snooze alerts</span>
            <or-toggle-button id="snoozeAlerts" [(ngModel)]="snoozeAlerts" size="small" (changed)="snooze($event)" class="light mr-3 ml-2"></or-toggle-button>
          </div>
          <span class="nav-font14 font-weight-bold border-left pl-3"
                (click)="alertsHistory?.length > 0 ? openHistory() : null"
                [tooltip]="!alertsHistory ? 'No activities in history to view' : ''"
                placement="bottom"
                [ngClass]="alertsHistory?.length > 0 ? 'nav-accent-blue cursor-pointer' : 'grey2'">
            <i class="mr-2 far fa-eye" [ngClass]="alertsHistory?.length > 0 ? 'grey4 cursor-pointer' : 'grey2 cursor-default'"></i>View alert history
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span class="d-block grey5 nav-font16 font-weight-bold">To create an alert, click 'Create System Alert.' To delete an alert, click the trash icon.</span>
          @if (addMode || editMode) {
            <span class="d-block grey5 nav-font16">After setting up your alert(s), click 'Save'. Notifications will be sent to all selected recipients via your preferred method</span>
          }
        </div>
      </div>

      <!-- add alert -->
      <div class="mt-4">
        @if (addMode) {
          <div class="grid-alert mb-3">
            <div class="d-flex align-items-start justify-content-center">
              <i class="fak fa-trash nav-error cursor-pointer pt-3" (click)="cancel()"></i>
            </div>
            <div class="card mr-3">
              <div class="card-body">
                <div class="row">
                  <div class="col d-flex align-items-center">
                    <span class="nav-font14 grey5 mr-3 font-weight-bold text-nowrap">Name this alert:</span>
                    <input type="text" class="form-control form-control-sm form-control-nav w-25" placeholder="Alert name" maxlength="50" [(ngModel)]="editRule.name">
                  </div>
                  <div class="col d-flex align-items-center justify-content-end">
                    <span class="grey5 nav-font14">
                      {{ getAlertTypeTitle(editRule) }}
                    </span>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col d-flex align-items-start">
                    <span class="nav-font14 grey5 mr-3 font-weight-bold pt-1">{{ editRule?.ruleType === 'tasks' ? 'If a task is' : 'When' }}</span>
                    <or-system-alerts-manager [surveySettings]="instanceSettings" [rule]="editRule" (conditionChanged)="setChangesEvent(editRule, $event)"></or-system-alerts-manager>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col d-flex align-items-center">
                    <!-- @if (editRule?.ruleType === 'metrics' && editRule?.ruleActions[0].alertMethod === 'Email') {
                      <span class="grey2 nav-font14 cursor-pointer" [ngClass]="editRule?.canSave ? 'nav-accent-blue' : 'grey2'" (click)="editRule?.canSave ? viewEmailMode(editRule) : null">
                        <i class="fak fa-edit mr-2"></i>Edit notification
                      </span>
                    } -->
                  </div>
                  <div class="col d-flex align-items-center">
                    <div class="px-3 or-checkbox d-flex justify-content-end invisible">
                      <input type="checkbox" name="archive" id="archive">
                      <label for="archive"></label>
                      <small class="pl-4 grey5">Archive this alert after notification is complete</small>
                    </div>
                    <button type="button" class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="cancel()">Cancel</button>
                    <button type="submit" class="btn btn-sm shadow-none pb-1"
                        [disabled]="!editRule?.canSave"
                        [ngClass]="!editRule?.canSave ? 'nav-btn-disabled' : 'nav-btn-accent'"
                        (click)="confirm(editRule)">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <!-- alerts list -->
      @for (rule of rules; track rule; let idx = $index) {
        <div class="grid-alert mb-3 edit" [class.noFocus]="(!rule?.editing && editMode) || addMode">
          <div class="d-flex align-items-start justify-content-center">
            @if (rule.isMine) {
              <i class="fak fa-trash nav-error cursor-pointer pt-3" (click)="cancelAlert(rule)"></i>
            }
          </div>
          <div class="card mr-3">
            <div class="card-body">
              <div class="row">
                <div class="col d-flex align-items-center">
                  <div class="grey5 nav-font14">
                    @if (rule?.editing) {
                      <span class="mr-2 font-weight-bold text-nowrap">Name this alert: </span>
                    }@else {
                      <span class="mr-2 font-weight-normal text-nowrap">Name: </span>
                      <span class="font-weight-bold text-nowrap">{{ rule.name }}</span>
                    }
                  </div>
                  @if (rule?.editing) {
                    <input type="text" class="form-control form-control-sm form-control-nav w-25" placeholder="Alert name" maxlength="50" [(ngModel)]="rule.name">
                  }
                </div>
                @if (!rule?.editing && !editMode) {
                  <div class="col d-flex align-items-center justify-content-end">
                    @if (!rule.isExpired && !rule.processed) {
                      <span class="nav-font14 grey4 font-weight-normal text-nowrap mr-2 invisible">
                        <i class="fak fa-clock pr-2"></i>Alert will be archived after notification is complete
                      </span>
                    }

                    <span class="grey5 nav-font14 border-right pr-2">
                      {{ getAlertTypeTitle(rule) }}
                    </span>

                    @if (rule.isMine) {
                      <span class="nav-font14 cursor-pointer nav-accent-blue font-weight-normal text-nowrap pl-2" (click)="edit(rule)">
                        <i class="fak fa-edit pr-2 clickable"></i>Edit
                      </span>
                    }
                  </div>
                }
              </div>
              <div class="row pt-3">
                <div class="col d-flex align-items-center">
                  @if (rule?.editing) {
                    <span class="nav-font14 grey5 mr-3 font-weight-bold">{{ rule?.ruleType === 'tasks' ? 'If a task is' : 'When' }}</span>
                    <or-system-alerts-manager [surveySettings]="instanceSettings" [rule]="rule" (conditionChanged)="setChangesEvent(rule, $event)"></or-system-alerts-manager>
                  } @else {
                    <span class="nav-font14 grey5 mr-2 font-weight-normal">Alert:</span>
                    <span class="nav-font14 grey5 mr-1 font-weight-bold">{{ rule?.ruleType === 'tasks' ? 'If a task is' : 'When' }}</span>
                    <or-system-alerts-manager [surveySettings]="instanceSettings" [rule]="rule" (conditionChanged)="setChangesEvent(rule, $event)"></or-system-alerts-manager>
                  }
                </div>
              </div>
              @if (!rule?.editing && rule?.ruleActions[0].alertMethod === 'Email' && rule?.emailTemplate) {
                <div class="row pt-3">
                  <div class="col d-flex align-items-center">
                    <span class="nav-font14 grey5 mr-3 font-weight-normal">Notification:</span>
                    '<span class="word-wrap-break nav-font14" [innerHTML]="rule?.emailTemplate"></span>'
                  </div>
                </div>
              }
            </div>
            @if (rule?.editing) {
              <div class="card-footer pt-0">
                <div class="row">
                  <div class="col d-flex align-items-center">
                    @if (editRule?.ruleType === 'metrics' && rule?.ruleActions[0].alertMethod === 'Email') {
                      <span class="grey2 nav-font14 cursor-pointer" [ngClass]="rule?.canSave ? 'nav-accent-blue' : 'grey2'" (click)="rule?.canSave ? viewEmailMode(rule) : null">
                        <i class="fak fa-edit mr-1"></i>Edit notification
                      </span>
                    }
                  </div>
                  <div class="col d-flex align-items-center">
                    <div class="px-3 or-checkbox d-flex justify-content-end invisible">
                      <input type="checkbox" name="archive" id="archive">
                      <label for="archive"></label>
                      <small class="pl-4 grey5">Archive this alert after notification is complete</small>
                    </div>
                    <button type="button" class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold clickable" (click)="cancelEdit(rule)">Cancel</button>
                    <button type="submit" class="btn shadow-none font-weight-bold nav-font14 nav-btn-accent pb-1 clickable"
                          [disabled]="!rule?.canSave"
                          [ngClass]="!rule?.canSave ? 'nav-btn-disabled' : 'nav-btn-accent'"
                          (click)="confirm(rule)">Save
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }

    </div>
  </div>
</div>


<!-- modals -->
<ng-template #modalEditEmail>
  <div class="modal-header">
    <div class="d-flex w-100 align-items-center">
      <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font24">Edit notification</h2>
    </div>
    <div class="d-flex justify-content-end editable">
      <i class="fas fa-close grey4 editable" (click)="cancelEditEmail()"></i>
    </div>
  </div>
  <div class="modal-body nav-font14 grey5 pr-3">
    <div class="row align-items-start mb-2">
      <div class="col grey5">
        <span class="d-block font-weight-bold">Edit the notification message for this alert, then click 'Save'.</span>
      </div>
    </div>
    <div class="row">
      <div class="col mr-2">
        <div class="ckeditor position-relative nav-font14">
          <or-wysiwyg (messageEvent)="editRule.emailTemplate = $event" [config]="editorConfig" [data]="editRule?.emailTemplate"></or-wysiwyg>
        </div>
        <div class="save-note position-absolute">
          <button (click)="cancelEditEmail()" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14">Cancel</button>
          <button (click)="saveEditEmail()" class="btn shadow-none font-weight-bold nav-font14 nav-btn-accent">Save</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalAlertHistory>
  <div class="modal-header">
    <div class="d-flex w-100 align-items-center">
      <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font24">Alert History</h2>
    </div>
    <div class="d-flex justify-content-end editable">
      <i class="fas fa-close grey4 editable" (click)="closeAlertHistory()"></i>
    </div>
  </div>
  <div class="modal-body nav-font14 grey5 pr-3 pb-0">
    <div class="row align-items-start mb-2">
      <div class="col grey5">
        <span class="d-block font-weight-bold">Review history to see when alerts were generated, their triggers, and how they were delivered.</span>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-11 pr-0 d-flex align-items-center">
        <div class="input-group global-search">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fas fa-search grey4 cursor-default"></i>
            </div>
          </div>
          <input [(ngModel)]="filterText" type="search" class="form-control form-control-sm border-right-0" (ngModelChange)="search()" placeholder="Search">
        </div>
      </div>
      <div class="col px-0">

        <!-- filters -->
      <div class="filter-container btn-group btn-group-sm" dropdown #dropdown="bs-dropdown" [insideClick]="true">
        <button type="button" dropdownToggle class="btn btn-outline-none" controls="dropdown-basic">
          <i class="fak fa-filter fa-xs grey4 cursor-pointer" [class.filter-mode]="dropdown.isOpen"></i>
        </button>
        <div *ngIf="filters.length > 0" class="filterNumber">
          <span class="">{{filters.length}}</span>
        </div>
        <div style="width:260px" class="dropdown-menu dropdown-filters dropdown-menu-right show p-3" *dropdownMenu>
          <div class="row">
            <div class="col d-flex">
              <h5 class="nav-secondary mb-0 font-weight-bold nav-font14">Filter by</h5>
              <i class="fas fa-close grey4 ml-auto" (click)="dropdown.hide()"></i>
            </div>
          </div>
          <div class="row mt-1 mb-3">
            <div class="col nav-font12 nav-accent-blue">
              <span (click)="ClearAllFilters();" class="cursor-pointer">Reset Filters</span>
            </div>
          </div>
          <div class="filter-section mt-2 border-bottom pb-2">
            <div class="col px-0">
              <span class="grey4 nav-font14">Team Members</span>
              <i class="fas fa-angle-down float-right grey4" [class.fa-rotate-180]="this.isUserExpanded" (click)="this.isUserExpanded = !this.isUserExpanded;"></i>
            </div>
            <div *ngIf="isUserExpanded" class="filter-content mt-2">
              <div class="col px-0 mb-3">
                <input type="search" [(ngModel)]="userFilter" placeholder="Search users" autocomplete="off" class="form-control form-control-sm form-control-nav">
              </div>
              <div class="scrollbar overflow-y overflow-x-hidden nav-font14 grey5" style="height:200px;">
                <div *ngFor="let user of users; let i = index">
                  <div
                    *ngIf="user.firstName.toLowerCase().indexOf(userFilter.toLowerCase()) >= 0  || user.lastName.toLowerCase().indexOf(userFilter.toLowerCase()) >= 0">
                    <div class="or-checkbox d-flex justify-content-start align-items-center px-1">
                      <span container="body">
                        <input type="checkbox" [id]="'selectUser'+user.id"
                          (change)="user.filter = $event.target.checked; UpdateFilter('user', $event.target.checked, user.id);"
                          [checked]="user.filter">
                        <label [for]="'selectUser'+user.id"></label>
                      </span>
                      <span class="grey5 pl-4 nav-font14">{{ user.firstName }} {{ i == 0 ? user.lastName :
                        user.lastName }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="filter-section mt-2">
            <div class="col px-0">
              <span class="grey4 nav-font14">Alert Type</span>
              <i class="fas fa-angle-down float-right grey4" [class.fa-rotate-180]="this.isAlertTypeExpanded" (click)="this.isAlertTypeExpanded = !this.isAlertTypeExpanded;"></i>
            </div>
            <div *ngIf="isAlertTypeExpanded" class="filter-content mt-2">
              <div class="or-checkbox d-flex justify-content-start align-items-center px-1">
                <span container="body">
                  <input type="checkbox" id="metrics" [(ngModel)]="metrics" (change)="UpdateFilter('metrics', $event.target.checked)">
                  <label for="metrics" class="grey5 nav-font14"></label>
                </span>
                <span class="grey5 pl-4 nav-font14">Metrics</span>
              </div>
              <div class="or-checkbox d-flex justify-content-start align-items-center px-1">
                <span container="body">
                  <input type="checkbox" id="tasks" [(ngModel)]="tasks" (change)="UpdateFilter('tasks', $event.target.checked)">
                  <label for="tasks" class="grey5 nav-font14"></label>
                </span>
                <span class="grey5 pl-4 nav-font14">Tasks</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
    </div>
    <div class="row">
      <div class="col mr-2">
        <div class="alertHistory scrollbar overflow-y position-relative nav-font14">
          <div class="inner-container">
            @for (alert of alertsHistory; track group; let idx = $index) {
              <div class="alertHistoryItem w-100 px-3 py-2">
                <div class="row">
                  <div class="col">
                    <span class="nav-font14 font-weight-bold grey5">
                      {{ alert.ruleHeader.ruleType | titlecase }} Alert &nbsp;&nbsp;'{{alert.alertName}}' created by {{alert.createdByName}} was delivered:
                    </span>
                  </div>
                  <div class="col-3 text-right pl-0">
                    <span class="nav-font12 grey4">
                      {{alert.timestamp | date: 'MM/dd/yyyy h:mm a'}}
                    </span>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col d-flex align-items-start">
                    <or-system-alerts-manager [surveySettings]="instanceSettings" [rule]="alert.ruleHeader" alertHistory=true></or-system-alerts-manager>
                  </div>
                </div>
                <div class="row">
                  <div class="col d-flex align-items-start">
                    <span class="nav-font14 grey5">Recipient:</span>
                    <div class="nav-font14 grey5 w-100 d-inline-block ml-2">
                      <span class="w-100 d-inline-block mb-2">{{alert.recipientEmail}}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col d-flex align-items-start">
                    <span class="nav-font14 grey5">Notification:</span>
                    <div class="nav-font14 grey5 w-100 d-inline-block ml-2">
                      <span class="w-100 d-inline-block mb-3" [innerHTML]="'\'' + alert?.message + '\''"></span>
                    </div>
                  </div>
                </div>
              </div>
              @if (idx !== alertsHistory.length - 1) {
                <hr class="my-2 mx-2">
              }
            }
            @if (showLoadMore) {
              <button class="nav-btn-primary shadow-none w-100 mt-3" (click)="showMore()">Load all</button>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer mb-0">
    <div class="row align-items-center w-100">
      <div class="col pl-2">
        <span class="grey4 nav-font14 font-weight-bold">({{alertsHistory?.length || 0}}) Activities</span>
      </div>
      <div class="col pr-2 d-flex justify-content-end">
        <button (click)="closeAlertHistory()" class="btn btn-sm px-2 shadow-none font-weight-bold nav-font14 nav-btn-accent">Close</button>
      </div>
    </div>
  </div>
</ng-template>


