<div class="container-fluid">
  <div class="row mb-4">
    <div class="col">
      <span class="nav-secondary nav-font18 mt-2 font-weight-bold mr-3">
        <i class="fak fa-project-alerts mr-2 grey4 font-weight-normal nav-font20"></i>Project Alerts
      </span>
      <button class="btn btn-sm nav-btn-accent shadow-none font-weight-bold nav-font14 text-white px-2">
        <i class="far fa-plus mr-2 font-weight-normal"></i>Create Alert
      </button>
    </div>
    <div class="col d-flex align-items-center justify-content-end">
      <span class="nav-accent-blue nav-font14 font-weight-bold cursor-pointer">
        <i class="mr-2 cursor-pointer grey4 far fa-eye"></i>View alert history
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <span class="grey5 nav-font16">To create a project alert, click 'Create Alert. To delete an alert, click the trash icon.</span>
    </div>
  </div>
</div>
