<div class="card bidcard">
  <div class="card-header">
    <div class="row">
      <div class="col-4 font-weight-light">
        <h5 class="d-inline">
          <a [routerLink]="['/projects', project.id]" class="font-weight-bold or-primary-link text-white">{{ project.projectCode }}</a>
        </h5>
      </div>
      <div class="col-4 or-profile-image">
        @if (project.projectManager) {
          <div class="d-flex justify-content-center">
            <span tooltip="{{ project.projectManager }}">
              <or-profile-image [name]="project.projectManager" size="small" showInitials="true"></or-profile-image>
            </span>
          </div>
        }
      </div>

    </div>
  </div>
  <div class="card-body py-1">
    <div class="row align-items-center mt-2">
      <div class="col-10">
        <div class="d-block text-truncate">
          <a class="h4 text-truncate or-primary-link" [routerLink]="['/projects', project.id]" tooltip="{{ project.projectName }}">{{ project.projectName }}</a>
        </div>
      </div>
      <div class="col text-right">
        <i (click)="toggleFavorite()" class="far fa-star fa-lg cursor-pointer opacity-90" [ngClass]="{'active': isFavorite}"></i>
      </div>
    </div>
    <div class="row py-2">
      <div class="col-11">
        <div class="d-block text-truncate">
          <span class="h5 font-weight-light text-truncate or-secondary-link dotted-underline" [routerLink]="['/clients', project.clientId]" tooltip="{{ project.clientName }}">{{ project.clientName }}</span>
        </div>
      </div>
    </div>
  </div>
  BID

</div>

