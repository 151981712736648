<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h3 class="grey5 nav-font18 mt-2">Survey Entry Links
        @if (project.surveySecurityKey != null && project.surveySecurityKey != '') {
          <span  class="ml-2 nav-secondary">(Survey to NavigatOR link is secure)</span>
        }
      </h3>
    </div>
    <div class="col d-flex align-items-center justify-content-end">
      <span type="button" class="shadow-none grey4 nav-font14 font-weight-normal" (click)="copyRedirectUrls()">
        <i class="mr-2 cursor-pointer far fa-copy"></i>Copy OR Redirect links
      </span>
    </div>
  </div>
  <div class="card shadow-none border">
    <div class="card-body">
      <div class="row nav-font14 grey4 pb-3">
        <div class="col d-flex align-items-center">
          <span class="mr-2 font-weight-bold grey6">
            Live URL <i class="ml-4 cursor-pointer far fa-copy" [class.grey3]="!project.projectSegments[0].surveyLink" (click)="copyUrl(project.projectSegments[0].surveyLink)" tooltip="Copy Link"></i>
          </span>
          <span class="nav-black2 w-75">
            @if (editLinkMode?.liveUrl) {
              <input [(ngModel)]="project.projectSegments[0].surveyLink"
                placeholder="Enter live URL here"
                type="text"
                name="surveyLink"
                #inputLiveUrl
                (keypress)="onKeyPress($event)"
                (blur)="saveLinks()"
                class="form-control form-control-sm form-control-nav">
                @if (project.projectSegments[0].surveyLink !='' && project.projectSegments[0].surveyLink?.indexOf('#or1#') == -1) {
                  <div class="alert alert-warning mt-1" role="alert">You must have a variable set to #or1# so that you can track the unique IDs for this project</div>
                }
              }
              @if (!editLinkMode?.liveUrl) {
                <div class="form-control-sm form-control-nav border-0 link" [class.border]="!project.projectSegments[0].surveyLink" (click)="enableEditLinks('liveUrl')">
                  {{project.projectSegments[0].surveyLink}}
                  @if (!project.projectSegments[0].surveyLink) {
                    <span class="grey3">Enter live URL here</span>
                  }
                </div>
              }
            </span>
          </div>
        </div>
        <div class="row nav-font14 grey4">
          <div class="col d-flex align-items-center">
            <span class="mr-2 font-weight-bold grey6">
              Test URL <i class="ml-4 cursor-pointer far fa-copy" [class.grey3]="!project.projectSegments[0].testSurveyLink" (click)="copyUrl(project.projectSegments[0].testSurveyLink)" tooltip="Copy Link"></i>
            </span>
            <span class="nav-black2 w-75">
              @if (editLinkMode?.testUrl) {
                <input [(ngModel)]="project.projectSegments[0].testSurveyLink"
                  placeholder="Enter test URL here"
                  type="text"
                  name="testSurveyLink"
                  #inputTestUrl
                  (keypress)="onKeyPress($event)"
                  (blur)="saveLinks()"
                  class="form-control form-control-sm form-control-nav">
                  @if (project.projectSegments[0].testSurveyLink !='' && project.projectSegments[0].testSurveyLink?.indexOf('#or1#') == -1) {
                    <div class="alert alert-warning mt-1" role="alert">You must have a variable set to #or1# so that you can track the unique IDs for this project</div>
                  }
                }
                @if (!editLinkMode?.testUrl) {
                  <div class="form-control-sm form-control-nav border-0 link" [class.border]="!project.projectSegments[0].testSurveyLink" (click)="enableEditLinks('testUrl')">
                    {{project.projectSegments[0].testSurveyLink}}
                    @if (!project.projectSegments[0].testSurveyLink) {
                      <span class="grey3">Enter test URL here</span>
                    }
                  </div>
                }
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <h3 class="grey5 nav-font18 mt-4">Vendors ({{ totalVendors }})</h3>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <span type="button" class="btn btn-sm nav-btn-accent shadow-none font-weight-bold" (click)="addVendor()">Add Vendor</span>
        </div>
      </div>

      <!-- Existing Vendors -->
      @for (vendor of projectVendors; track vendor; let idx = $index) {
        @if (!vendor.isDeleted) {
          <div class="card mb-4 vendors shadow-none border">
            <div class="card-body pt-2">
              <div class="row mt-1">
                <div class="ml-3 nav-font16 nav-accent-blue font-weight-bold nav-link pl-0" (click)="openPartner(vendor)">
                  <span>{{vendor.partnerName}}</span>
                </div>
                <div class="col d-flex align-items-center justify-content-end">
                  @if (!vendor?.hasTraffic) {
                    <div class="cursor-pointer nav-error mr-2 px-2" [class.border-right]="isORInstance" (click)="containsVendorName(vendor.partnerName) ? openDeleteVendorModal(vendor) : deleteVendor(vendor)">
                      <i class="fak fa-trash fa-md mr-2"></i>
                      <span class="nav-font14">Delete</span>
                    </div>
                  }
                  @if (isORInstance) {
                    <div class="cursor-pointer grey4"  tooltip="The vendor view displays what a vendor sees in the 'Vendor Portal' " placement="left" (click)="openVendorView(vendor)">
                      <i class="fak fa-frame-view fa-xl mr-1"></i>
                      <span class="nav-font14 font-weight-bold id-vendor-open-view">Open Vendor's View</span>
                    </div>
                  }
                </div>
              </div>
              @if (vendor.partnerStatus == 'Test') {
                <div class="alert alert-danger px-1 p-0 d-inline nav-font12" role="alert">
                  This vendor is in 'Test' mode, Deduplication and CleanID is disabled.
                </div>
              }
              <div class="row mt-3">
                <div class="col-12 col-xxl-3 col-md-4 nav-font14">
                  <div class="row mb-4">
                    <div class="col-12 col-md-4 grey6 font-weight-bold">Information</div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-12 col-md-4 grey4">Project number</div>
                    <div class="col nav-black2">
                      @if (vendor?.edit?.partnerProjectNumber) {
                        <input type="text" #partnerProjectNumber (keypress)="onKeyPressVendors($event, vendor)" name="partnerProjectNumber" (blur)="onBlur('partnerProjectNumber', vendor, idx)" [(ngModel)]="vendor.partnerProjectNumber" class="form-control form-control-sm form-control-nav">
                      }
                      @if (!vendor?.edit?.partnerProjectNumber) {
                        <div class="form-control-sm form-control-nav border-0 link" [class.border]="!vendor.partnerProjectNumber" (click)="enableVendorField('partnerProjectNumber', vendor)">
                          {{ vendor.partnerProjectNumber }}
                          @if (!vendor.partnerProjectNumber) {
                            <span class="grey3">Enter Project number</span>
                          }
                        </div>
                      }
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-12 col-md-4 grey4">Vendor status</div>
                    <div class="col nav-black2 or-dropdown-status id-vendor-status">
                      <or-dropdown-status [values]="['Test', 'Soft Launch', 'Full Launch', 'Paused', 'Closed']"  [status]="vendor.partnerStatus" (updateProject)="projectStatusUpdated($event)" (changed)="vendor.partnerStatus = $event;" [partnerId]="vendor.partnerId"  [projectId]="project.id" [surveyLink]="project?.projectSegments[0]?.surveyLink" [autosave]="true"></or-dropdown-status>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-12 col-md-4 grey4">Vendor contact</div>
                    <div class="col nav-black2">
                      @if (vendor?.edit?.partnerPms) {
                        <or-type-ahead  [labelField]="'name'" [multi]="true" [valueField]="'id'"
                          [selectedValues]="vendor.partnerPms === null ? [] : vendor.partnerPms"
                          (valueChange)="updateVendorPM('partnerPms', $event, vendor)" [data]="vendor.contacts"
                        placeholder="Type to search..."></or-type-ahead>
                        <span (click)="addNewContact(vendor, addContact)" class="cursor-pointer nav-accent-blue d-block m-1">Add new contact (+)</span>
                      }
                      @if (!vendor?.edit?.partnerPms) {
                        <div class="form-control-sm form-control-nav border-0 link" [class.border]="!vendor.partnerPms || vendor.partnerPms.length === 0" (click)="enableVendorField('partnerPms', vendor)">
                          {{ getPartneContactNames(vendor) }}
                          @if (!vendor.partnerPms || vendor.partnerPms.length === 0) {
                            <span class="grey3">Type to search..</span>
                          }
                        </div>
                      }

                    </div>
                  </div>
                  @if (vendor.linkSecurity) {
                    <div class="row mb-4">
                      <div class="col-12 col-md-4 grey4">Link security</div>
                      <div class="col nav-black2">
                        <span class="badge badge-pill badge-light font-weight-normal">{{ vendor.linkSecurity }}</span>
                      </div>
                    </div>
                  }
                  <div class="row mb-4">
                    <div class="col-12 col-md-4 grey4">Allocation</div>
                    <div class="col nav-black2">
                      @if (vendor?.edit?.partnerFullQuota) {
                        <input type="text" #partnerFullQuota (keypress)="handleUpdateSoftLaunch('partnerFullQuota', $event, vendor)" name="fullSegmentQuota"
                          (blur)="handleUpdateSoftLaunch('partnerFullQuota', null, vendor)" [(ngModel)]="vendor.partnerFullQuota" class="form-control form-control-sm form-control-nav w-50">
                        }
                        @if (!vendor?.edit?.partnerFullQuota) {
                          <div class="form-control-sm form-control-nav border-0 link w-50" [class.border]="!vendor.partnerFullQuota" (click)="enableVendorField('partnerFullQuota', vendor)">
                            {{ vendor.partnerFullQuota }}
                          </div>
                        }
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-12 col-md-4 grey4">Soft Launch</div>
                      <div class="col nav-black2">
                        @if (vendor?.edit?.partnerSoftQuota) {
                          <input type="text" #partnerSoftQuota
                            (keypress)="onKeyPressVendors($event, vendor)"
                            name="softSegmentQuota"
                            (blur)="onBlur('partnerSoftQuota', vendor, idx)"
                            [(ngModel)]="vendor.partnerSoftQuota"
                            class="form-control form-control-sm form-control-nav w-50">
                          }
                          @if (!vendor?.edit?.partnerSoftQuota) {
                            <div class="form-control-sm form-control-nav border-0 link w-50" (click)="enableVendorField('partnerSoftQuota', vendor)">
                              {{ vendor.partnerSoftQuota }}
                            </div>
                          }
                        </div>
                      </div>

                      @if (vendor?.rateCards && vendor?.rateCards.length > 0) {
                        <div class="row mb-4">
                          <div class="col-12 col-md-4 grey4">CPI Source</div>
                          <div class="col-6 nav-black2 text-right">
                            <div class="btn-group w-100" dropdown #ddRateCard="bs-dropdown">
                              <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container py-0" [ngClass]="ddRateCard.isOpen ? 'open' : 'closed'">
                                <span class="nav-font14 cursor-default">{{vendor?.partnerRateCardName || 'Manual'}}</span>
                                <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                                  <i class="far grey4 float-right nav-font16 ml-auto"
                                    [class.fa-angle-up]="ddRateCard.isOpen"
                                    [class.fa-angle-down]="!ddRateCard.isOpen">
                                  </i>
                                </div>
                              </div>
                              <div *dropdownMenu class="dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100">
                                <div class="menu-container">
                                  <ul class="py-0 px-0 my-0 noShadow">
                                    <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="rateCardSelected(vendor, null)">
                                      <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                        <span>Manual</span>
                                      </div>
                                    </li>
                                    @for (rateCard of vendor.rateCards; track rateCard; let idx = $index) {
                                      <hr class="p-0 my-0 mx-1">
                                      <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="rateCardSelected(vendor, rateCard)">
                                        <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                          <span>{{ rateCard.name }}</span>
                                        </div>
                                      </li>
                                    }
                                  </ul>
                                </div>
                              </div>
                            </div>
                            @if (vendor?.partnerRateCardId) {
                              <span class="d-block nav-link-accent nav-font14 pt-1 pr-2" (click)="viewRateCard(vendor)">View rate card</span>
                            }
                          </div>
                        </div>
                        @if (vendor?.partnerRateCardId && vendor?.partnerRateCardId !== 'Manual') {
                          <div class="row mb-4">
                            <div class="col-12 col-md-4 grey4">CPI</div>
                            <div class="col nav-black2">
                              <div class="row">
                                <div class="col nav-black2 pr-0">
                                  <span class="d-block grey4">Expected</span>
                                  <span class="d-block nav-black2 pl-2">{{ vendor.expectedCPI | currency }}</span>
                                </div>
                                <div class="col nav-black2 pl-0">
                                  <span class="d-block grey4 d-flex align-items-center">Current <i class="fas fa-xs fa-arrows-rotate grey3 ml-2" (click)="updateVendorCPI(vendor, true)"></i></span>
                                  <span class="d-block nav-black2 pl-2">{{ vendor.buyCPI | currency }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        } @else {
                          <div class="row mb-4">
                            <div class="col-12 col-md-4 grey4">CPI</div>
                            <div class="col nav-black2">
                              @if (vendor?.edit?.buyCPI) {
                                <input type="text" #buyCPI
                                  (keypress)="onKeyPressVendors($event, vendor)"
                                  name="softSegmentQuota"
                                  currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.', align: 'left' }"
                                  (blur)="onBlur('buyCPI', vendor, idx)"
                                  [(ngModel)]="vendor.buyCPI"
                                  class="form-control form-control-sm form-control-nav w-50">
                                }
                                @if (!vendor?.edit?.buyCPI) {
                                  <div class="form-control-sm form-control-nav border-0 link w-50 id-vendor-cpi" [class.border]="!vendor.buyCPI" (click)="enableVendorField('buyCPI', vendor)">
                                    {{ vendor.buyCPI | currency }}
                                  </div>
                                }
                            </div>
                          </div>
                        }

                      }
                      @else {
                        <div class="row mb-4">
                          <div class="col-12 col-md-4 grey4">CPI</div>
                          <div class="col nav-black2">
                            @if (vendor?.edit?.buyCPI) {
                              <input type="text" #buyCPI
                                (keypress)="onKeyPressVendors($event, vendor)"
                                name="softSegmentQuota"
                                currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.', align: 'left' }"
                                (blur)="onBlur('buyCPI', vendor, idx)"
                                [(ngModel)]="vendor.buyCPI"
                                class="form-control form-control-sm form-control-nav w-50">
                              }
                              @if (!vendor?.edit?.buyCPI) {
                                <div class="form-control-sm form-control-nav border-0 link w-50 id-vendor-cpi" [class.border]="!vendor.buyCPI" (click)="enableVendorField('buyCPI', vendor)">
                                  {{ vendor.buyCPI | currency }}
                                </div>
                              }
                          </div>
                        </div>
                      }

                      </div>
                      <div class="col nav-font14">
                        <div class="row mb-4">
                          <div class="col-12 d-flex col-md-4 grey6 font-weight-bold">
                            Vendor Link
                          </div>
                        </div>
                        <div class="row mb-4">
                          <div class="col-12 col-md-2 grey4">Entry</div>
                          <div class="col nav-black2 position-relative link-redirect">
                            <div class="form-control-sm form-control-nav border-0 link-entry">
                              {{ getEntryUrl(vendor) }}
                            </div>
                            <div class="copyRedirect position-absolute" tooltip="Copy to clipboard" (click)="copyEntryUrl(vendor)">
                              <i class="far fa-copy cursor-pointer grey4"></i>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-4">
                          <div class="col-12 d-flex col-md-4 grey6 font-weight-bold">
                            Redirect Links
                            @if (showLinkSecurity(vendor)) {
                              <span class="ml-2 nav-secondary" tooltip="Link security hashing is enabled. Edit vendor to disable.">(Link security enabled)</span>
                            }
                          </div>
                        </div>
                        <div class="row mb-4">
                          <div class="col-12 col-md-2 grey4">Complete</div>
                          <div class="col nav-black2 position-relative link-redirect">
                            <textarea autosize [minRows]="2"
                              #completeRedirectURL
                              (keypress)="onKeyPressVendors($event, vendor)"
                              (mouseleave)="checkFocus('completeRedirectURL', idx)"
                              (click)="enableVendorField('completeRedirectURL', vendor)"
                              (blur)="onBlur('completeRedirectURL', vendor, idx)"
                              [placeholder]="'Enter Complete URL'"
                              [(ngModel)]="vendor.completeRedirectURL" class="form-control form-control-nav" [class.border-0]="vendor.completeRedirectURL">
                            </textarea>
                            <div class="copyRedirect position-absolute" tooltip="Copy to clipboard" (click)="copyRedirects(vendor, 'completeRedirectURL')">
                              <i class="far fa-copy cursor-pointer grey4"></i>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-4">
                          <div class="col-12 col-md-2 grey4">Term</div>
                          <div class="col nav-black2 position-relative link-redirect">
                            <textarea autosize [minRows]="2"
                              #terminateRedirectURL
                              (keypress)="onKeyPressVendors($event, vendor)"
                              (mouseleave)="checkFocus('terminateRedirectURL', idx)"
                              (click)="enableVendorField('terminateRedirectURL', vendor)"
                              (blur)="onBlur('terminateRedirectURL', vendor, idx)"
                              [placeholder]="'Enter Term URL'"
                              [(ngModel)]="vendor.terminateRedirectURL" class="form-control form-control-nav" [class.border-0]="vendor.terminateRedirectURL">
                            </textarea>
                            <div class="copyRedirect position-absolute" tooltip="Copy to clipboard" (click)="copyRedirects(vendor, 'terminateRedirectURL')">
                              <i class="far fa-copy cursor-pointer grey4"></i>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-4">
                          <div class="col-12 col-md-2 grey4">Overquota</div>
                          <div class="col nav-black2 position-relative link-redirect">
                            <textarea autosize [minRows]="2"
                              #overQuotaRedirectURL
                              (keypress)="onKeyPressVendors($event, vendor)"
                              (mouseleave)="checkFocus('overQuotaRedirectURL', idx)"
                              (click)="enableVendorField('overQuotaRedirectURL', vendor)"
                              (blur)="onBlur('overQuotaRedirectURL', vendor, idx)"
                              [placeholder]="'Enter Overquota URL'"
                              [(ngModel)]="vendor.overQuotaRedirectURL" class="form-control form-control-nav" [class.border-0]="vendor.overQuotaRedirectURL">
                            </textarea>
                            <div class="copyRedirect position-absolute" tooltip="Copy to clipboard" (click)="copyRedirects(vendor, 'overQuotaRedirectURL')">
                              <i class="far fa-copy cursor-pointer grey4"></i>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-1">
                          <div class="col-12 col-md-2 grey4">Quality Control (Auto QC)</div>
                          <div class="col nav-black2 position-relative link-redirect">
                            <textarea autosize [minRows]="2"
                              #qcRedirectURL
                              (keypress)="onKeyPressVendors($event, vendor)"
                              (mouseleave)="checkFocus('qcRedirectURL', idx)"
                              (click)="enableVendorField('qcRedirectURL', vendor)"
                              (blur)="onBlur('qcRedirectURL', vendor, idx)"
                              [placeholder]="'Enter Quality Control (Auto QC) URL'"
                              [(ngModel)]="vendor.qcRedirectURL" class="form-control form-control-nav" [class.border-0]="vendor.qcRedirectURL">
                            </textarea>
                            <div class="copyRedirect position-absolute" tooltip="Copy to clipboard" (click)="copyRedirects(vendor, 'qcRedirectURL')">
                              <i class="far fa-copy cursor-pointer grey4"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            }
          </div>

          <ng-template #addContact>
            <div class="modal-header d-flex align-items-center justify-content-center">
              <div class="header-grid w-100 position-relative">
                <div class="d-flex justify-content-left align-content-center mt-2 px-2">
                  <h3 class="nav-secondary mb-0 font-weight-bold">Add New Contact</h3>
                </div>
                <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
                  <i class="fas fa-close grey4" (click)="cancelNewContact()"></i>
                </div>
              </div>
            </div>
            <div class="modal-body px-4 py-0">
              <div class="row my-4">
                <div class="col-4 grey4 nav-font14">
                  Contact First Name
                </div>
                <div class="col-8">
                  <input [(ngModel)]="contactFirstName" class="form-control form-control-sm form-control-nav" placeholder="First Name">
                </div>
              </div>
              <div class="row my-4">
                <div class="col-4 grey4 nav-font14">
                  Contact Last Name
                </div>
                <div class="col-8">
                  <input [(ngModel)]="contactLastName" class="form-control form-control-sm form-control-nav" placeholder="Last Name">
                </div>
              </div>
              <div class="row my-4">
                <div class="col-4 grey4 nav-font14">
                  Company Position
                </div>
                <div class="col-8">
                  <input [(ngModel)]="companyPosition" class="form-control form-control-sm form-control-nav" placeholder="Position">
                </div>
              </div>

              <div class="row my-4">
                <div class="col-4 grey4 nav-font14">
                  Phone number
                </div>
                <div class="col-8">
                  <input [(ngModel)]="phoneNumber" class="form-control form-control-sm form-control-nav" placeholder="(333) 333-4444">
                </div>
              </div>
              <div class="row my-4">
                <div class="col-4 grey4 nav-font14">
                  E-mail
                </div>
                <div class="col-8">
                  <input [(ngModel)]="email" class="form-control form-control-sm form-control-nav" placeholder="E-mail">
                </div>
              </div>
            </div>
            <div class="modal-footer pt-0 pb-3" style="border-top: none;">
              <div class="row d-flex w-100">
                <div class="col"></div>
                <div class="col-8 text-right px-3">
                  <button type="button" (click)="cancelNewContact()" class="btn btn-sm nav-btn-primary mx-2 shadow-none">Cancel</button>
                  <button type="submit" (click)="saveNewContact()"  class="btn btn-sm nav-btn-accent shadow-none">Add Contact</button>
                </div>
              </div>
            </div>
          </ng-template>

          <!-- Modal Add new Vendor -->
          <ng-template #modal>
            <div class="card shadow-none border-0">
              <div class="card-body pt-2">
                <div class="row mt-2 mb-4">
                  <div class="col-5">
                    <span class="nav-font18 nav-secondary font-weight-bold">Add Vendor</span>
                  </div>
                  <div class="col d-flex align-items-center justify-content-end">
                    <i class="fas fa-close cursor-pointer grey4" (click)="closeModal()"></i>
                  </div>
                </div>

                <div class="scrollbar overflow-y overflow-x-hidden pr-2">
                  @for (newVendor of newVendors; track newVendor; let idx = $index) {
                    @if (newVendors.length > 1) {
                      @if (idx > 0) {
                        <hr class="my-2 nav-border3">
                      }
                      <div class="row mt-0 mb-2">
                        <div class="col d-flex align-items-center justify-content-end">
                          <div class="cursor-pointer nav-error mr-2 px-2" (click)="deleteNewVendor(idx)">
                            <i class="fak fa-trash fa-md mr-2"></i>
                            <span class="nav-font14">Delete</span>
                          </div>
                        </div>
                      </div>
                    }
                    <div class="row">
                      <div class="col-5">
                        <span class="nav-font14 grey6 font-weight-bold">Vendor Information</span>
                      </div>
                      <div class="col ml-3">
                        <span class="nav-font14 grey6 font-weight-bold">Redirect Links</span>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-5 nav-font14">
                        <div class="row mb-3">
                          <div class="col-12 col-md-4 grey4"><label class="required">Vendor</label></div>
                          <div class="col pl-0">
                            <or-type-ahead
                              [labelField]="'name'"
                              [valueField]="'value'"
                              placeholder="Type to search for a vendor"
                              [selectedValues]="newVendor.partnerId == null ? []: [{value: newVendor.partnerId, name: newVendor.partnerName }]"
                              dropdownMode=true
                              class="form-control-sm form-control-nav w-100 px-0" [dataCallback]="getVendors" (valueChange)="setNewVendor($event, idx)">
                            </or-type-ahead>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-12 col-md-4 grey4">Project number</div>
                          <div class="col nav-black2 pl-0">
                            <input type="text" [(ngModel)]="newVendor.partnerProjectNumber" placeholder="Project number" name="softSegmentQuota" class="form-control form-control-sm form-control-nav">
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-12 col-md-4 grey4">Vendor status</div>
                          <div class="col nav-black2 or-dropdown-status pl-0">
                            <or-dropdown-status [skipProjectUpdate]="true" container="body" [partnerId]="newVendor.partnerId" [values]="['Test', 'Soft Launch', 'Full Launch', 'Paused', 'Closed']" [status]="newVendor.partnerStatus" (changed)="newVendor.partnerStatus = $event; " [surveyLink]="project?.projectSegments[0]?.surveyLink" [autosave]="false"></or-dropdown-status>
                          </div>
                        </div>
                        @if (newVendor.partnerId != null) {
                          <div class="row mb-3">
                            <div class="col-12 col-md-4 grey4">Vendor contact</div>
                            <div class="col nav-black2 pl-0">
                              <or-type-ahead
                                [labelField]="'name'"
                                [multi]="true"
                                [valueField]="'id'"
                                [selectedValues]="newVendor.partnerPms === null ? [] : newVendor.partnerPms"
                                (valueChange)="newVendor.partnerPms = $event"
                                [data]="newVendor.contacts" placeholder="Type to search...">
                              </or-type-ahead>
                              @if (!newVendor.validatedSample) {
                                <span (click)="addNewContact(newVendor, addContact)" class="cursor-pointer nav-accent-blue d-block m-1">Add new contact (+)</span>
                              }
                            </div>
                          </div>
                        }
                        <div class="row mb-3">
                          <div class="col-12 col-md-4 grey4"><label class="mr-2" [class.required]="isORInstance">Allocation</label></div>
                          <div class="col nav-black2 pl-0">
                            <input type="text" [(ngModel)]="newVendor.partnerFullQuota" (change)="autoUpdateSoftLaunch(newVendor)" name="fullSegmentQuota" class="form-control form-control-sm form-control-nav w-50">
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-12 col-md-4 grey4">Soft Launch</div>
                          <div class="col nav-black2 pl-0">
                            <input type="text"  [(ngModel)]="newVendor.partnerSoftQuota" name="softSegmentQuota" class="form-control form-control-sm form-control-nav w-50">
                          </div>
                        </div>

                        @if (newVendor?.rateCards && newVendor?.rateCards.length > 0) {
                          <div class="row mb-3">
                            <div class="col-12 col-md-4 grey4">CPI Source</div>
                            <div class="col-5 nav-black2 pl-0 text-right">
                              <div class="btn-group w-100" dropdown #ddRateCard="bs-dropdown">
                                <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex button-container py-0" [ngClass]="ddRateCard.isOpen ? 'open' : 'closed'">
                                  <span class="nav-font14 cursor-default">{{newVendor?.partnerRateCardName || 'Manual'}}</span>
                                  <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                                    <i class="far grey4 float-right nav-font16 ml-auto"
                                      [class.fa-angle-up]="ddRateCard.isOpen"
                                      [class.mt-1]="!ddRateCard.isOpen"
                                      [class.fa-angle-down]="!ddRateCard.isOpen">
                                    </i>
                                  </div>
                                </div>
                                <div *dropdownMenu class="dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100">
                                  <div class="menu-container">
                                    <ul class="py-0 px-0 my-0 noShadow">
                                      <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="rateCardSelected(newVendor, null, false)">
                                        <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                          <span>Manual</span>
                                        </div>
                                      </li>
                                      @for (rateCard of newVendor.rateCards; track rateCard; let idx = $index) {
                                        <hr class="p-0 my-0 mx-1">
                                        <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0" (click)="rateCardSelected(newVendor, rateCard, false)">
                                          <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                            <span>{{ rateCard.name }}</span>
                                          </div>
                                        </li>
                                      }
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              @if (newVendor?.partnerRateCardId) {
                                <span class="d-block nav-link-accent nav-font14 pt-1 pr-2" (click)="viewRateCard(newVendor)">View rate card</span>
                              }
                            </div>
                          </div>
                          @if (newVendor?.partnerRateCardId && newVendor?.partnerRateCardId !== 'Manual') {
                            <div class="row mb-4">
                              <div class="col-12 col-md-4 grey4"><label class="mr-2" [class.required]="isORInstance">CPI</label></div>
                              <div class="col nav-black2">
                                <div class="row">
                                  <div class="col nav-black2 px-0">
                                    <span class="d-block grey4">Expected</span>
                                    <span class="d-block nav-black2 pl-2">{{ newVendor.expectedCPI | currency }}</span>
                                  </div>
                                  <div class="col nav-black2 pl-0">
                                    <span class="d-block grey4 d-flex align-items-center">Current <i class="fas fa-xs fa-arrows-rotate grey3 ml-2" (click)="updateVendorCPI(newVendor, true)"></i></span>
                                    <span class="d-block nav-black2 pl-2">{{ newVendor.buyCPI | currency }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          } @else {
                            <div class="row mb-4">
                              <div class="col-12 col-md-4 grey4"><label class="mr-2" [class.required]="isORInstance">CPI</label></div>
                              <div class="col nav-black2 pl-0">
                                <input type="text" currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.', align: 'left' }" [(ngModel)]="newVendor.buyCPI" class="form-control form-control-sm form-control-nav w-50">
                              </div>
                            </div>
                          }

                        }
                        @else {
                          <div class="row mb-4">
                            <div class="col-12 col-md-4 grey4"><label class="mr-2" [class.required]="isORInstance">CPI</label></div>
                            <div class="col nav-black2 pl-0">
                              <input type="text" currencyMask [options]="{ prefix: '$ ', thousands: ',', decimal: '.', align: 'left' }" [(ngModel)]="newVendor.buyCPI" class="form-control form-control-sm form-control-nav w-50">
                            </div>
                          </div>
                        }
                      </div>
                      <div class="col nav-font14 ml-3">
                        <div class="row mb-3">
                          <div class="col-12 col-md-3 grey4">Complete</div>
                          <div class="col nav-black2 pl-0">
                            <input [(ngModel)]="newVendor.completeRedirectURL" placeholder="Enter Complete URL" class="form-control form-control-sm form-control-nav">
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-12 col-md-3 grey4">Term</div>
                          <div class="col nav-black2 pl-0">
                            <input [(ngModel)]="newVendor.terminateRedirectURL" placeholder="Enter Term URL" class="form-control form-control-sm form-control-nav">
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-12 col-md-3 grey4">Overquota</div>
                          <div class="col nav-black2 pl-0">
                            <input [(ngModel)]="newVendor.overQuotaRedirectURL" placeholder="Enter Overquota URL" class="form-control form-control-sm form-control-nav">
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-12 col-md-3 grey4">Quality Control</div>
                          <div class="col nav-black2 pl-0">
                            <input [(ngModel)]="newVendor.qcRedirectURL" placeholder="Enter Quality Control URL" class="form-control form-control-sm form-control-nav">
                          </div>
                        </div>
                      </div>
                    </div>
                    @if (idx === newVendors.length-1) {
                      <div class="row mt-2">
                        <div class="col d-flex align-items-center">
                          <span (click)="addEmptyVendor()" class="cursor-pointer font-weight-bold nav-accent-blue d-block nav-font14">
                            <i class="fas fa-sm fa-plus grey4 mr-1 font-weight-normal"></i>Add another vendor
                          </span>
                        </div>
                        <div class="col d-flex align-items-center justify-content-end">
                          <button type="button" class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
                          <button type="button" class="btn btn-sm shadow-none" [ngClass]="totalVendorsSelected < newVendors?.length ? 'nav-btn-disabled' : 'nav-btn-accent'" [attr.disabled]="totalVendorsSelected < newVendors?.length ? true : null" (click)="confirmSave()">Save</button>
                        </div>
                      </div>
                    }
                  }
                </div>

              </div>
            </div>
          </ng-template>

          <ng-template #modalDeleteVendor>
            <div class="modal-header">
              <div class="row d-flex w-100 align-items-center">
                <div class="col">
                  <h3 class="font-weight-bold nav-secondary">
                    <i class="fak fa-message-warning grey4 fa-lg ml-2"></i>
                    Warning: Deleting Vendor
                  </h3>
                </div>
                <div class="col-2 text-right px-0">
                  <i class="fas fa-close grey4" (click)="closeDeleteVendorModal()"></i>
                </div>
              </div>
            </div>
            <div class="modal-body pt-0">
              <h5 class="grey5 d-block my-3">
                There is live traffic for this vendor, are you sure you want to delete this vendor?
              </h5>
            </div>
            <div class="modal-footer">
              <div class="row d-flex w-100 align-items-center">
                <div class="col text-right px-0">
                  <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="closeDeleteVendorModal()">No</button>
                  <button class="btn btn-sm nav-btn-accent shadow-none" (click)="deleteVendorModal()">Yes</button>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template #modalRateCPICard>
            <or-vendor-edit-cpi [openModalRef]="openModalRefRateCard" [model]="model"></or-vendor-edit-cpi>
          </ng-template>
