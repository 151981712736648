<div class="card help-component">
  <div class="">
    <div class="row mx-0">
      <div class="header-title nav-secondary nav-font18 font-weight-bold mt-2" [ngClass]="currentSection ? 'mb-3' : 'mb-4'">
        @if (currentSection) {
          <i class="font-weight-bold nav-font16 mr-2 px-2 py-1 fas fa-chevron-left" (click)="goBack()"></i>
          <i class="font-weight-normal nav-font24 mr-2 {{currentSection.icon}}"></i>
        }
        {{currentSection ? currentSection.title : 'Help Center'}}
      </div>
      <i (click)="cancel()" class="far fa-close grey4 ml-auto nav-font18"></i>
    </div>
    @if (!currentSection) {
      @for (section of sections; track section; let isLast = $last) {
        @if (section.url) {
          <a href="{{section.url}}"  target="_new">
            <ng-container *ngTemplateOutlet="sectionDetails; context: {section:section}"></ng-container>
          </a>
        }
        @if (!section.url) {
          <ng-container *ngTemplateOutlet="sectionDetails; context: {section:section}"></ng-container>
        }
        @if (!isLast) {
          <hr class="between-sections">
        }
      }
    }
    @if (currentSection) {
      <div class="mr-2 content" [style.margin-left]="currentSection.type != 'support' ? '35px' : '12px'">
        @if (currentSection.type != 'ideas' || !ideaSubmitted) {
          <div class="row mx-0" [style.margin-left]="currentSection.type == 'support' ? '22px !important' : null" [class.mb-3]="currentSection.type != 'support'">
            <div class="nav-font14 grey5 font-weight-normal">{{currentSection.instructions}}</div>
          </div>
          @if (currentSection.type == 'support') {
            <or-zoho-desk [width]="'340'" [height]="'395'" ></or-zoho-desk>
          }
          @if (currentSection.type != 'support') {
            @if (currentSection.type == 'ideas') {
              @if (!ideaSubmitted) {
                <div class="form-group">
                  <label for="ideaSubject" class="grey5 nav-font14 ">Subject</label>
                  <input #subjectInput type="text" class="form-control form-control-nav form-control-sm nav-font14 px-2 h25" [style.height]="'25px !important'"
                    id="ideaTitle" placeholder="Subject or title" (keyup)="checkIfCanSubmitIdea(subjectInput.value, ideaInput.value)">
                  </div>
                  <div class="form-group mb-4">
                    <label for="ideaDescription" class="grey5 nav-font14 ">Idea or suggestion</label>
                    <textarea #ideaInput class="form-control form-control-nav form-control-sm nav-font14 px-2 h100" [style.min-height]="'100px !important'"
                    [style.resize]="'none'" id="ideaDescription"  placeholder="Type" (keyup)="checkIfCanSubmitIdea(subjectInput.value, ideaInput.value)"></textarea>
                  </div>
                  <div class="row mx-0 d-flex align-items-center">
                    <i class="fak fa-paper-clip cursor-pointer" (click)="fileInput.click()" [ngStyle]="{'color': '#545454', 'font-size': '28px'}"></i>
                    <input [style.display]="'none'" #fileInput type="file" accept="image/*" multiple="false" (change)="updateFile(fileInput)"/>
                    @if (fileName) {
                      <div class="nav-font12 file-name-container text-truncate d-inline col" [tooltip]="fileName" containerClass="file-name-tooltip" placement="top" container="body">
                        <div class="row mx-0 d-flex align-items-center">
                          <div class="file-name col px-0">
                            {{fileName}}
                          </div>
                          <i class="file-remove far fa-close" (click)="removeFile(fileInput)"></i>
                        </div>
                      </div>
                    }
                    <div class="ml-auto">
                      <button class="btn btn-sm nav-btn-primary mx-1 shadow-none font-weight-bold" (click)="cancelIdea(subjectInput, ideaInput, fileInput)">Cancel</button>
                      <button type="submit" class="btn nav-btn-accent shadow-none font-weight-bold nav-font14" [disabled]="!canSubmitIdea" (click)="submitIdea(subjectInput, ideaInput, fileInput)">Submit</button>
                    </div>
                  </div>
                }
              }
            }
          }
        </div>
      }
    </div>
  </div>

  <ng-template #sectionDetails let-section="section">
    <div class="cursor-pointer" (click)="section.url ? null : currentSection=section">
      <div class="row section-title mx-0 mb-2">
        <div class="d-flex align-items-center nav-font18 grey5 font-weight-bold">
          <i class="font-weight-normal nav-font24 mr-2 {{section.icon}}"></i>
          {{section.title}}
        </div>
      </div>
      <div class="row mx-0">
        <div class="nav-font14 grey5 font-weight-normal">{{section.description}}</div>
      </div>
    </div>
  </ng-template>