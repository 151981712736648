
<div class="container-fluid px-2">
  <div class="row mb-3">
    <div class="col">
      <span class="nav-secondary nav-font18 mt-2 font-weight-bold mr-3">
        <i class="fak fa-project-alerts mr-2 grey4 font-weight-normal nav-font20"></i>Project Alerts
      </span>
      <button class="btn btn-sm nav-btn-accent shadow-none font-weight-bold nav-font14 text-white px-2 add" [class.nav-btn-disabled]="addMode || editMode" (click)="addRule()">
        <i class="far fa-plus mr-2 font-weight-normal"></i>Create Alert
      </button>
    </div>
    <div class="col d-flex align-items-center justify-content-end">
      <span class="nav-accent-blue nav-font14 font-weight-bold cursor-pointer invisible">
        <i class="mr-2 cursor-pointer grey4 far fa-eye"></i>View alert history
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <span class="d-block grey5 nav-font16 font-weight-bold">To create a project alert, click 'Create Alert'. To delete an alert, click the trash icon.</span>
      @if (addMode || editMode) {
        <span class="d-block grey5 nav-font16">After setting up your alert(s), click 'Save'. Notifications will be sent to all selected recipients via your preferred method</span>
      }
    </div>
  </div>
  <div class="mt-4">

    @if (addMode) {
      <div class="grid-alert mb-3">
        <div class="d-flex align-items-start justify-content-center">
          <i class="fak fa-trash nav-error cursor-pointer pt-3" (click)="cancel()"></i>
        </div>
        <div class="card mr-3">
          <div class="card-body">
            <div class="row">
              <div class="col d-flex align-items-center">
                <span class="nav-font14 grey5 mr-3 font-weight-bold text-nowrap">Name this alert:</span>
                <input type="text" class="form-control form-control-sm form-control-nav w-25" placeholder="Alert name" maxlength="50" [(ngModel)]="editRule.name">
              </div>
            </div>
            <div class="row mt-3">
              <div class="col d-flex align-items-start">
                <span class="nav-font14 grey5 mr-3 font-weight-bold pt-1">Notify</span>
                <or-alerts-manager [project]="_project" [tasks]="_tasks" [rule]="editRule" (conditionChanged)="setChangesEvent(editRule, $event)"></or-alerts-manager>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col d-flex align-items-center">
                @if (editRule?.ruleType !== 'task' && editRule?.ruleActions[0].alertMethod === 'Email') {
                  <span class="grey2 nav-font14 cursor-pointer" [ngClass]="editRule?.canSave ? 'nav-accent-blue' : 'grey2'" (click)="editRule?.canSave ? viewEmailMode(editRule) : null">
                    <i class="fak fa-edit mr-2"></i>Edit notification
                  </span>
                }
              </div>
              <div class="col d-flex align-items-center">
                <div class="px-3 or-checkbox d-flex justify-content-end invisible">
                  <input type="checkbox" name="archive" id="archive">
                  <label for="archive"></label>
                  <small class="pl-4 grey5">Archive this alert after notification is complete</small>
                </div>
                <button type="button" class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="cancel()">Cancel</button>
                <button type="submit" class="btn btn-sm shadow-none pb-1"
                    [disabled]="!editRule?.canSave"
                    [ngClass]="!editRule?.canSave ? 'nav-btn-disabled' : 'nav-btn-accent'"
                    (click)="confirm(editRule)">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>

  @for (rule of rules; track rule; let idx = $index) {
    <div class="grid-alert mb-3 edit" [class.noFocus]="(!rule?.editing && editMode) || addMode">
      <div class="d-flex align-items-start justify-content-center">
        @if (rule.isMine && !rule.isExpired) {
          <i class="fak fa-trash nav-error cursor-pointer pt-3" (click)="cancelAlert(rule)"></i>
        }
      </div>
      <div class="card mr-3">
        <div class="card-body">
          <div class="row">
            <div class="col d-flex align-items-center">
              <div class="grey5 nav-font14">
                <span class="mr-2 font-weight-bold text-nowrap">Name this alert: </span>
                @if (!rule?.editing) {
                  <span class="font-weight-bold text-nowrap">{{ rule.name }}</span>
                }
              </div>
              @if (rule?.editing) {
                <input type="text" class="form-control form-control-sm form-control-nav w-25" placeholder="Alert name" maxlength="50" [(ngModel)]="rule.name">
              }
            </div>
            @if (!rule?.editing && !editMode) {
              <div class="col d-flex align-items-center justify-content-end">
                @if (!rule.isExpired && !rule.processed) {
                  <span class="nav-font14 grey4 font-weight-normal text-nowrap mr-2 invisible">
                    <i class="fak fa-clock pr-2"></i>Alert will be archived after notification is complete
                  </span>
                }
                @if (rule.isMine && !rule.isExpired) {
                  <span class="nav-font14 cursor-pointer nav-accent-blue font-weight-normal text-nowrap pl-2" (click)="edit(rule)">
                    <i class="fak fa-edit pr-2 clickable"></i>Edit
                  </span>
                }
              </div>
            }
          </div>
          <div class="row pt-3">
            <div class="col d-flex align-items-center">
              @if (rule?.editing) {
                <span class="nav-font14 grey5 mr-3 font-weight-bold">Notify</span>
                <or-alerts-manager [project]="_project" [tasks]="_tasks" [rule]="rule" (conditionChanged)="setChangesEvent(rule, $event)"></or-alerts-manager>
              } @else {
                <span class="nav-font14 grey5 mr-2 font-weight-normal">Alert:</span>
                <span class="nav-font14 grey5 mr-1 font-weight-bold">Notify</span>
                <or-alerts-manager [project]="_project" [tasks]="_tasks" [rule]="rule" (conditionChanged)="setChangesEvent(rule, $event)"></or-alerts-manager>
              }
            </div>
          </div>
          <div class="row pt-3">
            <div class="col d-flex align-items-center">
              @if (rule?.editing) {
                <!-- <span class="nav-font14 grey5 mr-3 font-weight-bold">Notify</span>
                <or-alerts-manager [project]="_project" [tasks]="_tasks" [rule]="rule" (conditionChanged)="setChangesEvent($event)"></or-alerts-manager> -->
              } @else {
                @if (rule?.ruleActions[0].alertMethod === 'Email') {
                  <span class="nav-font14 grey5 mr-3 font-weight-normal">Notification:</span>
                  '<span class="word-wrap-break nav-font14" [innerHTML]="rule?.emailTemplate"></span>'
                }
              }
            </div>
          </div>
        </div>
        @if (rule?.editing) {
          <div class="card-footer pt-0">
            <div class="row">
              <div class="col d-flex align-items-center">
                @if (rule?.ruleType !== 'task' && rule?.ruleActions[0].alertMethod === 'Email') {
                  <span class="grey2 nav-font14 cursor-pointer" [ngClass]="rule?.canSave ? 'nav-accent-blue' : 'grey2'" (click)="rule?.canSave ? viewEmailMode(rule) : null">
                    <i class="fak fa-edit mr-1"></i>Edit notification
                  </span>
                }
              </div>
              <div class="col d-flex align-items-center">
                <div class="px-3 or-checkbox d-flex justify-content-end invisible">
                  <input type="checkbox" name="archive" id="archive">
                  <label for="archive"></label>
                  <small class="pl-4 grey5">Archive this alert after notification is complete</small>
                </div>
                <button type="button" class="btn btn-sm nav-btn-primary mx-2 shadow-none font-weight-bold clickable" (click)="cancelEdit(rule)">Cancel</button>
                <button type="submit" class="btn shadow-none font-weight-bold nav-font14 nav-btn-accent pb-1 clickable"
                      [disabled]="!rule?.canSave"
                      [ngClass]="!rule?.canSave ? 'nav-btn-disabled' : 'nav-btn-accent'"
                      (click)="confirm(rule)">Save
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>

<ng-template #modalEditEmail>
  <div class="modal-header">
    <div class="d-flex w-100 align-items-center">
      <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font24">Edit notification</h2>
    </div>
    <div class="d-flex justify-content-end editable">
      <i class="fas fa-close grey4 editable" (click)="cancelEditEmail()"></i>
    </div>
  </div>
  <div class="modal-body nav-font14 grey5 pr-3">
    <div class="row align-items-start mb-2">
      <div class="col grey5">
        <span class="d-block font-weight-bold">Edit the notification message for this alert, then click 'Save'.</span>
      </div>
    </div>
    <div class="row">
      <div class="col mr-2">
        <div class="ckeditor position-relative nav-font14">
          <or-wysiwyg (messageEvent)="editRule.emailTemplate = $event" [config]="editorConfig" [data]="editRule?.emailTemplate"></or-wysiwyg>
        </div>
        <div class="save-note position-absolute">
          <button (click)="cancelEditEmail()" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14">Cancel</button>
          <button (click)="saveEditEmail()" class="btn shadow-none font-weight-bold nav-font14 nav-btn-accent">Save</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
