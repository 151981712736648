import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'or-project-stages',
  templateUrl: './project-stages.component.html',
  styleUrls: ['./project-stages.component.scss']
})
export class ProjectStagesComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.currentStage = this._project?.currentStage;
    this.currentStageColor = this._project?.currentStageColor;
  }

  @Input() public set stages(values) {
    this._stages = values.sort((x, y) => x.sortOrder - y.sortOrder);
  };

  currentStage: string;
  currentStageColor: string;

  @Output() stageSelected= new EventEmitter();

  _project:any = {};
  _stages = [];

  constructor() { }

  ngOnInit() {
    this.updateStageStatus();
  }

  updateStageStatus() {
    let currentSortOrder = this._stages.find(stage => stage.name === this.currentStage)?.sortOrder;
    if (currentSortOrder !== undefined) {
      this._stages.forEach(stage => {
        stage.isCompleted = stage.sortOrder <= currentSortOrder;
      });
    }
  }

  selectStage(stage) {
    this.stageSelected.emit(stage);
  }

}
