import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UserService, AuthService, AlertifyService, SystemAlertsService, SettingsService, User, AutomationHistoryService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  providers: []
})
export class AlertsComponent implements OnInit {

  rules: any[] = null;
  users: any[];
  alertsHistory: any[] = null;
  alertsHistoryFullList: any[] = null;
  allTeam: any[] = [];
  fullList: any[] = [];
  loggedUser: User;

  instanceId = '';
  instanceSettings: any = {};
  newRole: any = {};
  addMode = false;
  editMode = false;
  editRule: any = {};
  rollbackRule;
  emailTemplateSaved = '';
  userChangedEmail = false;
  viewEmail = false;
  editEmail = false;
  snoozeAlerts: boolean;

  isAlertTypeExpanded: boolean = true;
  isUserExpanded: boolean = true;

  filterList: any[] = [];
  filters: any = [];
  showFilter = false;
  metrics: boolean = false;
  tasks: boolean = false;
  userFilter = '';
  numToLoad = 100;
  showLoadMore = false;

  filter = {
    userId: [],
    typeList: [],
    textFilter: ''
  };

  filterText = '';

  editorConfig = {
    startupFocus: true,
    toolbar: {
      language: 'en',
      items: [
        'bold',
        'italic',
        'underline',
        'link',
        'bulletedList',
        'numberedList'
      ]
    }
  };

  @ViewChild('modalEditEmail') modalRefEditEmail: any;
  @ViewChild('modalAlertHistory') modalRefAlertHistory: any;

  @Output() callback = new EventEmitter();

  openModalRef: BsModalRef;

  constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private userService: UserService,
    private modalService: BsModalService,
    private alertsService: SystemAlertsService,
    private settingsService: SettingsService,
    private automationHistory: AutomationHistoryService
  ) {
  };

  ngOnInit() {
    this.instanceId = this.auth.getUser().instanceId;
    this.loggedUser = this.auth.getUser();
    this.snoozeAlerts = this.loggedUser?.snoozeSystemAlerts || false;

    this.getInstanceSettings();
    this.getAlerts();
  }

  getInstanceSettings() {
    this.settingsService.getSurveySettings(this.instanceId).subscribe(result => {
      this.instanceSettings = result;
    },
    error => console.error(error));
  }

  getAlerts() {
    forkJoin([this.alertsService.GetAlerts(), this.userService.GetUsers()]).subscribe((responseList) => {
      this.rules = responseList[0];
      this.users = responseList[1];
    }, error => {
      this.alertify.error('There was a problem getting system alerts.');
    }, () => {
      // this.rules = this.rules.filter(x => !x.isExpired && x.ruleActions.some(x => !x.triggered));
      this.rules = this.rules.map(item => {
        item.isMine = item.createdBy === this.auth.getUser().id ? true : false;
        item.processed = item.ruleActions[0].processedTime !== null;
        item.triggered = item.ruleActions[0].triggered === true;
        item.name = item.ruleType == 'task' ? 'Task Alert' : item.name;
        item.userChangedEmail = item.emailTemplate ? true : false;

        if (item.emailTemplate) {
          item.emailTemplate = item.emailTemplate.replaceAll(/<br>/g, '').replaceAll(/<p>/g, '').replaceAll(/<\/p>\s*/g, '');
        }

        return item;
      });

      this.rules = this.rules.sort((a, b) => new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime());
      this.fullList = this.rules;
      this.getHistory();
    });
  }

  getHistory() {
    this.automationHistory.GetHistory(30, this.filter).subscribe(result => {
      this.alertsHistory = result;
    }, error => {
      this.alertify.error('There was a problem getting system alerts history.');
    },() => {
      // handle alerts history data
      this.alertsHistory = this.alertsHistory?.map(item => {
        item.createdBy = item.ruleHeader.createdBy;
        item.createdByName = (!item.createdBy) ? '' : this.users.filter(e => e.id === item.createdBy)[0].name;
        item.message = item.message.replace(/<https?:\/\/([^|]+)\|([^>]+)>/g, '<a href="https://$1" target="_blank">$2</a>');
        const recipientName = this.users.filter(e => e.id === item.recipientId)[0].name;
        item.recipientEmail = `${recipientName} <${item.recipientEmail}>`;

        return item;
      });

      this.alertsHistoryFullList = this.alertsHistory;
      this.updateList();
    });
  }

  showMore() {
    this.numToLoad += 10000;
    this.updateList();
  }

  updateList() {
    let filteredList = this.filterText === '' ?
      this.alertsHistoryFullList :
      this.alertsHistoryFullList.filter(e =>
        e.alertName.toLowerCase().indexOf(this.filterText.toLowerCase()) >= 0 ||
        e.message.toLowerCase().indexOf(this.filterText.toLowerCase()) >= 0
      );

    this.showLoadMore = (filteredList.length > this.numToLoad);
    this.alertsHistory = filteredList.slice(0, this.numToLoad);
  }

  closeAlertHistory() {
    this.numToLoad = 100;
    this.filterText = '';

    this.updateList();
    this.openModalRef.hide();
  }

  search() {
    this.numToLoad = 100;
    this.updateList();
  }

  UpdateFilter(mode, isChecked, user: string = '') {

    let filters = [];

    if (mode === 'metrics') {
      this.metrics = isChecked;
      this.users.forEach(e => e.filter = false);
    }
    else if (mode === 'tasks') {
      this.tasks = isChecked;
      this.users.forEach(e => e.filter = false);
    }
    else if (mode === 'user') {
      if (isChecked == false) {
        this.users.forEach(e => {
          if (e.id == user) e.filter = false;
        });
      }
    }

    if (this.metrics || this.tasks || this.users.some(e => e.filter)) filters.push(1);

    this.filters = filters;
    this.ApplyFilter();
    this.getHistory();
  }

  ClearAllFilters() {
    this.users.map(e => e.filter = false);
    this.metrics = false;
    this.tasks = false;
    this.showFilter = false;
    this.ApplyFilter();
    this.getHistory();
  }

  ApplyFilter() {
    let userIds = this.users.filter(e => e.filter);

    this.filter = {
      userId: userIds.map(e => e.id),
      typeList: [],
      textFilter: ''
    };

    if (this.metrics) {
      this.filter.typeList.push('metrics');
    }
    if (this.tasks) {
      this.filter.typeList.push('tasks');
    }
  }

  openHistory() {
    this.openModalRef = this.modalService.show(this.modalRefAlertHistory, { ignoreBackdropClick: true, keyboard: false, class: 'nav-modal-style alert-history-modal' });
  }

  getAlertTypeTitle(alert) {
    switch (alert.ruleType) {
      case 'metrics':
        return 'Metrics Alert';
      case 'tasks':
        return 'Tasks Alert';
      case 'timeline':
        return 'Timeline Alert';
      case 'project-status':
        return 'Project Status Alert';
      case 'costs':
        return 'Costs Alert';
      case 'reconciliation':
        return 'Reconciliation Alert';
      default:
        return 'Metrics Alert';
    }
  }

  addAlert(type) {
    this.editRule = {};
    this.editRule.id = '';
    this.editRule.name = 'Above threshold';
    this.editRule.projectId = null;
    this.editRule.isSystemAlert = true;
    this.editRule.createdByName = this.auth.getUser().name;
    this.editRule.sortKey = 0;
    this.editRule.scopeType = 'Select group';
    this.editRule.ruleType = type;  // metrics, tasks, timeline, project-status, costs, reconciliation
    this.editRule.rules = [{ metricType: 'Select metric', operator: null, sortKey: 0 }];
    this.editRule.ruleActions = [{ action: 'Alert', alertMethod: 'Select method', alertRecipientType: 'Me', sortKey: 0 }];
    //this.editRule.canSave = false;
    this.editRule.editing = true;
    this.addMode = true;
    this.editMode = false;
    this.callback.emit({ editing: true });
  }

  cancelEdit(rule) {
    this.rules[this.rules.findIndex(x => x.id === rule.id)] = this.rollbackRule;
    rule.editing = false;
    this.editMode = false;
  }

  saveEditEmail() {
    this.emailTemplateSaved = this.editRule.emailTemplate;
    this.userChangedEmail = true;
    this.openModalRef.hide();
    this.editEmail = false;
  }

  cancelEditEmail() {
    this.editRule.emailTemplate = this.emailTemplateSaved;
    this.openModalRef.hide();
    this.editEmail = false;
  }

  viewEmailMode(rule) {
    this.editRule = rule;
    this.emailTemplateSaved = rule.emailTemplate;
    this.openModalRef = this.modalService.show(this.modalRefEditEmail, { ignoreBackdropClick: true, keyboard: false, class: 'nav-modal-style edit-notification-modal' });
  }

  confirm(rule) {
    this.editRule = rule;

    if (!this.editRule) return;

    if (this.editRule?.scopeType == 'Select group') {
      this.editRule.scopeType = null;
    }

    if (this.editRule.ruleType === 'metrics') {

      if (!this.editRule.rules[0].metricType || this.editRule.rules[0].metricType == undefined) {
        this.alertify.error('Please select a Metric for the Alert');
        return;
      }
      if (this.editRule.rules[0].value == undefined) this.editRule.rules[0].value = 0;
      if (this.editRule.rules[0].value == null) {
        this.alertify.error('Please provide a Metric Number for the Alert');
        return;
      } else {
        if (this.editRule.rules[0].value > 100) {
          let OK = true;
          switch (this.editRule.rules[0].metricType) {
            case 'LOI':
            case 'IR':
            case 'Manual QCs':
            case 'DAR':
            case 'DOR':
            case 'CID Dupes':
            case 'CID Fraud':
              this.alertify.error('Please provide a Metric Percentage between 1 and 100!');
              OK = false;
              break;
          }
          if (!OK) return;
        }
      }

      if (!this.editRule.rules[0].operator || this.editRule.rules[0].operator == undefined) {
        this.alertify.error('Please select an Operator for the Alert');
        return;
      }
    }

    if (!this.editRule.ruleActions[0].alertMethod || this.editRule.ruleActions[0].alertMethod == undefined) {
      this.alertify.error('Please select an Alert Method for the Alert');
      return;
    }

    if (!this.userChangedEmail) {
      this.editRule.emailTemplate = null;
    }

    // Add Rule
    if (!this.editRule.id) {
      this.alertsService.AddAlert(this.editRule).subscribe(() => {
        this.editRule = {};
        this.getAlerts();
      }, error => {
        console.error(error);
        this.alertify.error('Unable to save Alert');
      }, () => {
        this.viewEmail = false;
        this.editEmail = false;
        this.addMode = false;
        this.editMode = false;
        this.userChangedEmail = false;
        this.callback.emit({ editing: false });
        this.alertify.success('Alert saved');
      });
    } else {
      this.alertsService.EditAlert(this.editRule).subscribe(() => {
        this.editRule = {};
        this.getAlerts();
      }, error => {
        console.error(error);
        this.alertify.error('Unable to edit Alert');
      }, () => {
        this.viewEmail = false;
        this.editEmail = false;
        this.editMode = false;
        this.userChangedEmail = false;
        this.callback.emit({ editing: false });
        this.alertify.success('Alert saved');
      });
    }
  }

  setChangesEvent(rule, event) {

    let ruleChanged = event;

    if (ruleChanged.condition === 'scope') {
      rule.scopeType = ruleChanged.newValue.type;
      rule.scopeId = null;
    }
    if (ruleChanged.condition === 'metric') {
      rule.rules[0].metricType = ruleChanged.newValue.type;
    }
    if (ruleChanged.condition === 'metric-number') {
      rule.rules[0].value = parseInt(ruleChanged.newValue.value);
    }
    if (ruleChanged.condition === 'operator') {
      rule.rules[0].operator = ruleChanged.newValue.value;
    }
    if (ruleChanged.condition === 'alertMethod') {
      rule.ruleActions[0].alertMethod = ruleChanged.newValue;
    }

    this.checkCanSave(rule);
  }

  cancelAlert(rule) {
    this.alertsService.DeleteAlert(rule.id).subscribe(() => {
      this.getAlerts();
    }, error => {
      this.alertify.error('Unable to delete Alert');
      this.cancel();
    }, () => {
      this.alertify.success('Alert deleted');
      this.cancel();
    });
  }

  edit(rule) {
    this.rollbackRule = JSON.parse(JSON.stringify(rule));
    rule.editing = true;
    this.editMode = true;
    this.userChangedEmail = rule?.emailTemplate ? true : false;
    this.checkCanSave(rule);
    this.callback.emit({ editing: true });
  }

  checkCanSave(rule) {
    rule.canSave = true;

    if (!this.editMode && rule?.id) return;

    if (!rule?.name || rule?.name == undefined) {
      rule.canSave = false;
      return;
    }

    if (rule?.ruleType == 'metrics') {
      if (!rule?.rules[0].operator || rule?.rules[0].operator == 'Select') {
        rule.canSave = false;
        return;
      }
      if (!rule?.rules[0]?.value || rule?.rules[0].value < 0 || rule?.rules[0].value > 100) {
        rule.canSave = false;
        return;
      }
    }

    if (rule?.ruleType == 'tasks') {
      if (!rule?.rules[0].metricType || rule?.rules[0].metricType == 'Select') {
        rule.canSave = false;
        return;
      }
      if (rule?.rules[0].metricType == 'Assigned to me' && rule?.scopeType == 'Select group') {
        rule.canSave = false;
        return;
      }
    }

    if (!rule?.ruleActions[0].alertMethod || rule?.ruleActions[0].alertMethod == 'Select method') {
      rule.canSave = false;
      return;
    }
    if (!rule?.ruleActions[0].alertRecipientType) {
      rule.canSave = false;
      return;
    }
  }

  cancel() {
    this.editRule = {};
    this.addMode = false;
    this.editMode = false;
    this.viewEmail = false;
    this.editEmail = false;
    this.userChangedEmail = false;
    this.emailTemplateSaved = '';
    this.callback.emit({ editing: false });
  }

  snooze(event) {
    this.snoozeAlerts = event;

    this.userService.SnoozeSystemAlerts(this.loggedUser?.id, event).subscribe(data => {
      this.loggedUser.snoozeSystemAlerts = event;
      this.auth.setUser(this.loggedUser);
      this.alertify.success(`Snooze system alerts ${ event ? 'ON' : 'OFF' }`);
      this.userService.GetUsers(true).subscribe(e => { }); //refresh the user list that might be cached
    }, error => {
      this.alertify.error('Unable to snooze system alerts');
    });
  }

}

