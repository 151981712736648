import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService, UserService } from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';

@Component({
  selector: 'app-widget-filter-users',
  templateUrl: './widget-filter-users.component.html',
  styleUrls: ['./widget-filter-users.component.scss']
})
export class WidgetFilterUsersComponent implements OnInit {

  @Input() totalColumns = 1;

  totalSelected = 0;
  filterText = '';
  filteredUsers = [];
  selectedUsers = [];
  users = [];

  @Output() onChange = new EventEmitter();
  @Output() dropdownClosed = new EventEmitter();

  constructor(
    public dashboardService: DashboardService,
    public auth: AuthService,
    public userService: UserService) { }

  ngOnInit(): void {
    var allSelected = true;
    this.userService.GetUsers().subscribe(data => {
      const me = data.find(e => e.id == this.auth.getUser().id);
      var users = data.filter(e => e.id != this.auth.getUser().id);
      this.users = users;
      this.users.unshift(me);
      this.users.sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
      this.filteredUsers = [...users];
      var userIds = localStorage.getItem('dashboard');     
      if (userIds != null) {
        let localuser = userIds.split(',').map(item => item.trim());
        for (let i = 0; i < this.filteredUsers.length; i++) {
          if (localuser.includes(this.users[i].id)) {
            this.filteredUsers[i].selected = true;
          } else {
            this.users[i].selected = false;
            allSelected = false;
          }
        }
      } else {
        for (let i = 0; i < this.filteredUsers.length; i++) {
          this.filteredUsers[i].selected = false;
        }

      }
      this.totalSelected = this.filteredUsers.filter(user => user.selected).length;
      this.selectedUsers = this.filteredUsers.filter(user => user.selected);
    });  
  }

  selectAll(evt: Event) {
    const isChecked = (evt.target as HTMLInputElement).checked;
    this.filteredUsers.forEach(user => user.selected = isChecked);
    this.handleChange();
  }

  select(user: any, evt: Event) {
    const isChecked = (evt.target as HTMLInputElement).checked;
    user.selected = isChecked;
    this.handleChange();
  }

  handleChange() {
    this.selectedUsers = this.filteredUsers.filter(user => user.selected);
    this.totalSelected = this.selectedUsers.length;
    this.onChange.emit(this.selectedUsers);
  }

  onOpenChange(isOpen) {
    if (!isOpen) {
      this.dropdownClosed.emit();
    }
  }
}
