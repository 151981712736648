
<div class="card mt-5" style="max-width: 500px; margin:0 auto">
  <div class="card-header or-secondary-bg text-white">
    <h5>Sign In</h5>
  </div>
  <div class="card-body">
    <form #loginForm="ngForm" (ngSubmit)="login();" class="form-signin text-center">
      <div>
        <img alt="OpinionRoute NavigatOR" class="mb-5 w-100" src="/assets/imgs/OpinionRoute.jpg" />
      </div>
      <label for="inputEmail" class="sr-only">Email address</label>
      <input type="email" id="inputEmail" class="form-control" name="model.email" placeholder="Email" [(ngModel)]="model.email" required="" autofocus="">
      <label for="inputPassword" class="sr-only">Password</label>
      <input type="password" id="inputPassword" class="form-control" name="model.password" placeholder="Password"  [(ngModel)]="model.password"  required="">

      @if (availableUsers.length > 0) {
        <label for="workspace">Workspace</label>
        <select class="form-control form-control-sm inline mr-3" name="model.instance" [(ngModel)]="model.instance"  id="instance">
          <option></option>
          @for (user of availableUsers; track user) {
            <option [value]="user.instanceId">{{user.instanceName}}</option>
          }
        </select>
      }
      <p class="text-right"><a  [routerLink]="['/resetpassword']"><small class="or-secondary">Forgot password?</small></a></p>
      <button class="btn btn-lg btn-secondary btn-block mt-3" [disabled]="!loginForm.valid" id="signIn" type="submit">Sign In</button>
    </form>
  </div>
  <div class="card-footer text-center position-relative or-secondary-bg text-white">
    <small>© 2025 OpinionRoute, LLC. | All Rights Reserved.</small>
  </div>
</div>

<div class="alert alert-light mt-5 text-center" role="alert" style="max-width: 430px; margin:0 auto">
  <p class="m-0 font-weight-bold">Not using the NavigatOR yet to level up your work?</p>
  <p>Our team will get you started with a tailored demo!</p>
  <a class="btn btn-sm nav-btn-primary shadow-none font-weight-bold" href="https://www.opinionroute.com/sign-up-for-navigator/" target="_blank">Create an account to get started</a>
</div>
