import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertifyService, SurveyTesterProject, SurveyTesterService, SurveyTesterUser } from 'core';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-project-homepage-survey-testing',
  templateUrl: './project-homepage-survey-testing.component.html',
  styleUrls: ['./project-homepage-survey-testing.component.scss']
})
export class ProjectHomepageSurveyTestingComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.getProjectInfo();
  }

  @Output() callback = new EventEmitter();

  _project:any = {};
  summary = { totalOpen: 0, totalResolved: 0, totalClosed: 0, totalErrors: 0, totalChanges: 0, totalOther: 0 };
  surveyId = '';
  surveyUrl = '';
  surveyUrlDecode = '';
  selectedPlatform = '';
  suveyProject = {} as SurveyTesterProject;

  constructor(private alertify: AlertifyService, private service: SurveyTesterService) { }

  ngOnInit() {

  }

  getProjectInfo() {
    let url = this._project.projectSegments[0]?.testSurveyLink || '';
    this.selectedPlatform = this._project.projectSegments[0]?.surveyPlatForm;
    this.surveyUrlDecode = url;
    this.surveyUrl = encodeURIComponent(url);
    this.suveyProject = this.service.getSurveyProject(url, this.selectedPlatform);

    this.service.getSurveyId(this.suveyProject).subscribe((data) => {
      this.surveyId = data.id;
      this.getSessions();
    }, error => {
      this.alertify.error('There was an error getting survey tester id');
    });
  }

  getSessions() {
    this.service.getProjectSessions(this.surveyId).subscribe((data) => {
      let sessions = data;
      this.summary.totalOpen = sessions.reduce((prev, elm) => prev + elm.commentCreatedCount, 0);
      this.summary.totalResolved = sessions.reduce((prev, elm) => prev + elm.commentPendingCount, 0);
      this.summary.totalClosed = sessions.reduce((prev, elm) => prev + elm.commentApprovedCount + elm.commentCancelledCount, 0);
      this.summary.totalChanges = sessions.reduce((prev, elm) => prev + elm.totalClientChange, 0);
      this.summary.totalOther = sessions.reduce((prev, elm) => prev + elm.totalOtherChange, 0);
      this.summary.totalErrors = sessions.reduce((prev, elm) => prev + elm.totalProgramError, 0);
    }, error => {
      this.alertify.error('There was an error getting project info');
    });
  }

  openSettings(type) {
    this.callback.emit({section: 'programming', type: null});
  }

}
