import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { AlertifyService, ScheduleService } from 'core';
import Highcharts, { chart } from 'highcharts';

@Component({
  selector: 'app-project-homepage-schedule',
  templateUrl: './project-homepage-schedule.component.html',
  styleUrls: ['./project-homepage-schedule.component.scss']
})
export class ProjectHomepageScheduleComponent implements OnInit {

  @Input() stage;

  @Input() public set project(data) {
    this._project = data;
    this.getProjectTasks();
  }

  @Output() callback = new EventEmitter();

  _project:any = {};
  hasTasks = false;
  taskCount = [];
  totalTasks = 0;
  allTasks = [];

  highcharts = Highcharts;
  highchartsOptionsTasksOverview: Highcharts.Options;

  constructor(private renderer: Renderer2,
    private alertify: AlertifyService,
    private scheduleService: ScheduleService,) { }

  ngOnInit() {

  }

  getProjectTasks() {
    this.scheduleService.GetProjectSchedule(this._project.id, null).subscribe(stages => {
      if (!stages) return;
      this.allTasks = stages.find(x => x.stageName === this.stage.name)?.projectTasks;
      this.hasTasks = (this.allTasks?.length > 0);
      this.prepareData();
      if (this.hasTasks) this.drawChart();
    }, error => {
      this.alertify.error('Unable to get project tasks');
    });
  }

  prepareData() {
    const allTasks = this.allTasks;
    const openTasks = allTasks?.filter(task => task.status !== "Complete");

    this.taskCount["complete"] = allTasks?.reduce((count, task) => task.status === "Complete" ? count + 1 : count, 0);

    const todayUtc = new Date();
    todayUtc.setUTCHours(0, 0, 0, 0);

    this.taskCount["pending"] = openTasks?.filter(task => {
      let dueDate = task.dueDate ? new Date(task.dueDate) : null;
      if (dueDate) dueDate.setUTCHours(0, 0, 0, 0);
      return task.status === "Pending" && (dueDate === null || dueDate >= todayUtc);
    }).length;

    this.taskCount["overdue"] = openTasks?.filter(task => {
      let dueDate = task.dueDate ? new Date(task.dueDate) : null;
      if (dueDate) dueDate.setUTCHours(0, 0, 0, 0);
      return (dueDate != null && dueDate < todayUtc);
    }).length;

    this.totalTasks = (this.taskCount["pending"] || 0) + (this.taskCount["overdue"] || 0) + (this.taskCount["complete"] || 0);

  }

  drawChart() {
    let chartData = [];

    chartData.push({ name: 'Complete', y: this.taskCount["complete"], className: 'completeSlice' });
    chartData.push({ name: 'Pending', y: this.taskCount["pending"], className: 'pendingSlice' });
    chartData.push({ name: 'Overdue', y: this.taskCount["overdue"], className: 'overdueSlice' });

    const total = this.totalTasks;

    this.highchartsOptionsTasksOverview = {
      credits: {
        enabled: false
      },
      chart: {
        animation: false,
        plotBorderWidth: null,
        plotBackgroundColor: null,
        plotShadow: false,
        margin: [0, 0, 0, 0],
        events: {
          render: function () {
          },
          load: this.onOverviewChartLoad.bind(this),
        },
      },

      title: {
        text: `<div class="text-center"><span class="grey5 nav-font18 font-weight-bold">${total}</span><div class="nav-font12 font-weight-normal grey4 d-block">Tasks</div></div>`,
        align: 'center',
        useHTML: true,
        verticalAlign: 'middle',
      },
      plotOptions: {
        pie: {
          states: {
            inactive: {
              enabled: false,
            },
          },
          shadow: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          borderRadius: '0px',
          showInLegend: false,
          point: {
            events: {
              legendItemClick: function () {
                return false;
              }
            }
          },
          slicedOffset: 5
        },
        series: {
          states: {
            hover: {
              animation: false,
              enabled: false,
            }
          },
          point: {
            events: {
              mouseOver: function (this, e) {
                let newRadius = this.shapeArgs.r + 4;
                this.graphic.animate({
                  r: newRadius,
                  innerR: '35px',
                }, {
                  duration: 300
                });

                this.graphic.shadow({
                  color: 'rgba(1, 59, 92, 0.16)', // Shadow color
                  offsetX: 4, // Shadow offset X
                  offsetY: 4, // Shadow offset Y
                  width: 10 // Shadow width
                });
              },
              mouseOut: function (this,) {
                let newRadius = this.shapeArgs.r;
                this.graphic.animate({
                  r: newRadius,
                  innerR: '35px'
                }, {
                  duration: 300
                });
                this.graphic.shadow(false);
              },
            },
          },
          events: {
            mouseOut: function (this) {
              this.chart.title.attr({
                text: `<div class="text-center"><span class="grey5 nav-font18 font-weight-bold">${total}</span><div class="nav-font12 font-weight-normal grey4 d-block">Tasks</div></div>`
              })
              this.update({
                type: 'pie',
                size: '90%'
              })
            },
          }
        },
      },
      series: [{
        type: 'pie',
        innerSize: '70px',
        data: chartData,
        size: '90%'
      }],

      tooltip: {
        animation: false,
        enabled: true,
        borderRadius: 0,
        borderWidth: 0,
        shadow: false,
        shared: true,
        useHTML: false,
        format: null,
        style: {
          opacity: 0
        },
        formatter: function (this, e) {
          e.chart.title.attr({
            y: 65,
            animation: false,
              text: `<div class="text-center"><span class="grey5 nav-font18 font-weight-bold">${this.y}</span><div class="nav-font12 font-weight-normal grey4 d-block">${this.key}<div>Tasks</div></div></div>`
          });
          e.chart.tooltip.hide();
          return null
        }
      }
    };
  }

  onOverviewChartLoad(event) {
    let pendingSlice = document.getElementsByClassName('pendingSlice');
    let overdueSlice = document.getElementsByClassName('overdueSlice');
    let completeSlice = document.getElementsByClassName('completeSlice');
    for (let i = 0; i < completeSlice.length; i++) {
      this.renderer.setStyle(completeSlice[i], 'fill', '#4CCD96');
      this.renderer.setStyle(completeSlice[i], 'fill-opacity', 1);
    }
    for (let i = 0; i < pendingSlice.length; i++) {
      this.renderer.setStyle(pendingSlice[i], 'fill', '#FDDA89');
      this.renderer.setStyle(pendingSlice[i], 'fill-opacity', 1);
    }
    for (let i = 0; i < overdueSlice.length; i++) {
      this.renderer.setStyle(overdueSlice[i], 'fill', '#D02325CC');
      this.renderer.setStyle(overdueSlice[i], 'fill-opacity', 1);
    }
  }

  openSettings(type) {
    this.callback.emit({section: 'settings', type: type});
  }

  openSchedule() {
    this.callback.emit({section: 'schedule', type: null});
  }

}
