<div class="modal-header text-white">
  @if (_mode != 'add-widget') {
    <p class="h4 position-absolute"><em class="fas fa-grip-horizontal"></em> {{ _mode | titlecase }} Dashboard</p>
  }
  @if (_mode == 'add-widget') {
    <p class="h4 position-absolute"><em class="fas fa-grip-horizontal"></em> Add Widgets</p>
  }
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <input type="hidden" id="id">
  <div class="card">
    <div class="card-body">
      <div>
        @if (_mode === 'new') {
          <div class="alert alert-info text-center">
            <div class="h5">Create your customizable dashboard here.</div>
            <small>Give your dashboard a name and select which widgets you want to include to get started. You can change any of these settings after you create your dashboard</small>
          </div>
        }
        @if (_mode === 'rename') {
          <div class="alert alert-info text-center">
            <small>Change the dashboard name. You can remove widgets directly from the dashboard page.</small>
          </div>
        }
        <div>
          @if (_mode !== 'add-widget') {
            <div class="form-row mb-4">
              <div class="col-6">
                <input type="text" class="form-control" placeholder="Dashboard Name" [(ngModel)]="editDashName">
              </div>
            </div>
          }

          @if (_mode === 'new' || _mode === 'add-widget') {
            <div class="display-6 or-primary"> Select Widgets:</div>
            <div class="mt-4">
              @for (item of availableWidgets; track item) {
                <div class="h6 m-2 font-weight-light"> {{item.groupName}} </div>
                <div class="gridtemplate">
                  @for (widget of item.widgets; track widget) {
                    <div class="btn btn-sm btn-light d-inline-flex shadow w-100 p-2" [class.selectedwidget]="widget.selected" (click)="widget.selected = !widget.selected">
                      <div class="d-flex justify-content-center ml-3">
                        <i  class="align-self-center or-title opacity-70 pr-2 fa-2xl" [class]="widget.icon"></i>
                      </div>
                      <div class="text-center w-75">
                        <h6>{{ widget.title }}</h6>
                        <span>{{ widget.description }}</span>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          }

        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button type="submit" class="btn btn-sm btn-outline-secondary mx-2" (click)="bsModalRef.hide()"> Cancel</button>
      <button type="submit" class="btn btn-sm nav-btn-accent text-white" (click)="saveDashboard()"><em class="fa fa-save"></em> Save</button>
    </div>
  </div>
</div>
