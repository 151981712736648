@if (ready) {
  <div class=" d-flex">
    <div class="sidebar fixed-top">
      <div class="first-item d-flex align-items-center cursor-pointer text-center text-truncate px-1 border-bottom">
        <span class="nav-neutral nav-font16 py-3 pl-3 text-truncate">Project menu</span>
      </div>
      <div class="slideout-menu submenu p-2 grey5 border-bottom">
        <!-- <div  *ngIf="auth.isInternal() && !isClientView"  class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1 mb-1" (click)="openProjectFamily(project.bidNumber)">
        <i class="fak fa-projects icon grey4"></i>
        <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center">Summary</span>
      </div> -->
      <div class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1 mb-1" [class.active]="currentSideSection === 'info'" (click)="showSideSection('info')">
        <i class="fak fa-info icon grey4"></i>
        <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center">
          Project Info @if (sidebarSectionOpen && currentSideSection === 'info') {
          <i class="fak fa-left-arrow fa-rotate-180 font-weight-normal ml-1 nav-font16 nav-secondary"></i>
        }
      </span>
    </div>

  @if (auth.isInternal() && !isClientView && !isVendorView) {
    <div  class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1 mb-1" [class.active]="currentSideSection === 'notes'" (click)="showSideSection('notes')">
      <i class="fak fa-notes icon grey4"></i>
      <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center">
        Notes @if (sidebarSectionOpen && currentSideSection === 'notes') {
        <i class="fak fa-left-arrow fa-rotate-180 font-weight-normal ml-1 nav-font16 nav-secondary"></i>
      }
    </span>
  </div>
}
@if (auth.isInternal() && !isClientView && !isVendorView) {
  <div   class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1 mb-1" [class.active]="currentSideSection === 'downloads'" (click)="showSideSection('downloads')">
    <i class="fas fa-file-arrow-down icon grey4"></i>
    <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center">
      Data Exports @if (sidebarSectionOpen && currentSideSection === 'downloads') {
      <i class="fak fa-left-arrow fa-rotate-180 font-weight-normal ml-1 nav-font16 nav-secondary"></i>
    }
  </span>
</div>
}
</div>
<div class="slideout-menu subsection p-2 grey5 border-bottom">
  @for (group of groupedSections; track group) {
    <div class="position-relative my-2">
      <span class="h-100 position-absolute ml-2 rounded"
            [ngStyle]="{
              'border-left': (group.color !== project.currentStageColor ? '4px' : '7px') + ' solid ' + (group.color !== 'NoColor' ? group.color : 'transparent'),
              'left': (group.color !== project.currentStageColor ? '' : '-1px')
            }">
      </span>
      @for (section of group.sections; track section) {
        @if (section.type !== 'homepage') {
          <div (click)="switchSectionSidebar(section)"
          (contextmenu)="openNewWindow(section.url)"
            class="menu-item-child hover cursor-pointer text-truncate ml-3 py-1"
            [ngClass]="{'active': section?.title == sections[currentSection]?.title && subsection == ''}">
            <span class="nav-font14 menu-item-child-text text-truncate pl-1">
              {{ section.title }}
            </span>
          </div>
        }
      }
    </div>
  }
</div>
@if (auth.isInternal() && vendorViewId == '' && !isClientView) {
  <div class="slideout-menu misc p-2 grey5">
    <div (click)="showSubsection('alerts')" (contextmenu)="openNewWindow('/projects/'+project.id+'/alerts')"
        [style.right]="'5px'"
        [ngClass]="{'active':  subsection == 'alerts'}"
        class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1 pb-1 position-relative"
        [class.active]="currentSideSection === 'alerts'">
      <i class="fak fa-project-alerts icon grey4"></i>
      <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center pt-1">Alerts</span>
    </div>
    <div (click)="showSubsection('settings')" (contextmenu)="openNewWindow('/projects/'+project.id+'/settings')"
        [ngClass]="{'active':  subsection == 'settings'}"
        class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1">
      <i class="grey4 nav-font16" [ngClass]="subsection == 'settings' ? 'fa-solid fa-gear': 'fak fa-settings'"></i>
      <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center">Project Settings</span>
    </div>
    <div (click)="showSubsection('audit')" (contextmenu)="openNewWindow('/projects/'+project.id+'/audit')"
        [ngClass]="{'active':  subsection == 'audit'}"
        class="menu-item-child hover d-flex align-items-center cursor-pointer text-center text-truncate px-1">
      <i class="fak fa-change-log grey4"></i>
      <span class="nav-font14 menu-item-child-text text-truncate d-flex align-items-center">History Log</span>
    </div>
  </div>
}
</div>
<div class="project-body w-100 position-relative">
  <!-- BREADCRUMBS -->
  <div class="col-12 m-0 p-0 breadcrumb mr-auto">
    <div class="h-100" [ngClass]="{'d-flex align-items-center': projectType.stages.length <= 6, 'mt-3': projectType.stages.length > 6 }">
      <div class="grey4 d-flex align-items-center">
        <app-pagetitle subPageOpen="true"></app-pagetitle>
        <div class="">
          <span class="nav-font14 font-weight-bold nav-secondary text-uppercase cursor-pointer"
          (click)="openProjectFamily(project.bidNumber)">{{ this.familyCode }}</span>
          <span class="nav-font14 nav-secondary font-weight-bold ml-1 mr-2">/</span>
        </div>
        <!-- dropdown related projects -->
        <div class="d-inline cursor-pointer position-relative related-projects-button" dropdown #dropdown="bs-dropdown" container="body">
          <div class="cursor-pointer d-inline nav-font14" (click)="dropdown.toggle(true);">
            <span class="font-weight-bold nav-secondary mr-2">{{ (auth.isInternal())?
            shortenedProjectCode(project?.projectCode) : project?.projectCode }}</span>
            <span tooltip="{{project?.projectName}}" container="body" class="font-weight-bold nav-secondary">{{
            shortenedProjectName(project?.projectName)}}</span>
          </div>
          <ul *dropdownMenu class="dropdown-related-projects mx-2 position-absolute py-0" role="menu"
            (mouseleave)="dropdown.hide();" [class.open]="dropdown.isOpen">
            <div class="scrollbar overflow-y h-75">
              @for (related of relatedProjects; track related) {
                <div class="dropdown-item cursor-pointer border-bottom px-3"
                  (click)="dropdown.hide(); openRelated(related)">
                  <span class="font-weight-bold grey4 mr-2">{{related?.code}}</span>
                  <span class="font-weight-normal grey4">{{related?.projectNameDisplay}}</span>
                </div>
              }
            </div>
            @if (auth.isInternal()) {
              <div class="dropdown-item cursor-pointer px-3"
                (click)="dropdown.hide(); addProjectToFamily()">
                <span class="font-weight-bold nav-accent-blue">
                  <i class="fas fa-plus fa-md nav-accent-blue mr-2"></i>Add project
                </span>
              </div>
            }
          </ul>
          <i class="fas grey5 nav-font14 mx-2" (click)="dropdown.toggle(true);"
          [class.open]="dropdown.isOpen" [class.fa-chevron-down]="!dropdown.isOpen" [class.fa-chevron-up]="dropdown.isOpen"></i>
        </div>
      </div>

      @if (projectType.stages.length > 6 && (auth.isInternal() || auth.isClient())) {
        <div class="project-stages mt-3 mr-1">
          <or-project-stages
              [project]="project"
              [stages]="projectType.stages"
              (stageSelected)="openStage($event)">
          </or-project-stages>
        </div>
      }

    </div>

    <div class="items d-flex mt-2 pr-4 align-items-center ml-auto">
      @if (auth.isVendor() || auth.isInternal() || auth.isClient()) {
        <ul class="nav alerts d-flex align-items-center ml-auto mt-n2">
          @if (projectType.stages.length <= 6 && (auth.isInternal() || auth.isClient())) {
            <li class="project-stages mt-2 mr-1">
              <or-project-stages
                  [currentStage]="project?.currentStage"
                  [stages]="projectType.stages"
                  (stageSelected)="openStage($event)">
              </or-project-stages> 
             <!-- <or-project-stage [stage]="project?.currentStage"
                [color]="project?.currentStageColor"
                [percentComplete]="(project?.stageCompleteTaskCount / project?.stageTotalTaskCount)*100">
            </or-project-stage>-->
            </li>
          }
          @if (!(auth.isClient() || isClientView || isVendorView)) {
            @if (projectType.showDownload) {
              <li class="nav-item ml-3 cursor-pointer" tooltip="Download Participant details" placement="left" container="body">
                <i class="fak fa-download fa-lg grey4" (click)="download('participant')"></i>
              </li>
            }
          }
        </ul>
      }
    </div>
  </div>
  <!-- Client Name -->
  <!-- <div class="col-12 m-0 lient-name">
  <a *ngIf="project?.clientName" class="nav-font-16 nav-accent-blue font-weight-bold mr-2 cursor-pointer" href="#"
  [routerLink]="['/clients', project?.clientId]">{{project?.clientName}}</a>
  <span *ngIf="project?.clientPo" class="nav-font-16 grey4">({{project?.clientPo}})</span>
</div>
<hr> -->
<div class="project-details-container" [ngStyle]="{'margin-top': projectType.stages.length <= 6 ? '70px' : '110px'}">
  <div class="project-sidebar fixed-top" [class.sidebar-project-open]="sidebarSectionOpen == true">
    <div class="col text-right mt-2">
      <i class="fas fa-close fa-lg cursor-pointer grey4 ml-3" (click)="closeSideBar()"></i>
    </div>
    <div>
      @if (currentSideSection == 'info') {
        <app-project-info [project]="project" [projectType]="projectType"
        (onChange)="handleSettingsChange()"></app-project-info>
      }
      @if (currentSideSection == 'notes') {
        <app-notes class="app-notes {{ cardNotes.expanded ? 'expanded' : '' }}" [project]="project"></app-notes>
      }
      @if (currentSideSection == 'my-tasks') {
        <ng-container class="taskover">
          <app-mytask [project]="project" (callback)="callbackMyTasks($event)"
          [refresh]="refreshMyTasks"></app-mytask>
        </ng-container>
      }
      @if (currentSideSection == 'project-alerts') {
        <app-rules #rules [project]="project" (callback)="callbackRules($event)"></app-rules>
      }
      @if (currentSideSection == 'downloads') {
        <app-project-download [data]="{ project: project, projectType: projectType }"></app-project-download>
      }
    </div>
  </div>
  <div class="surveydetail">
    @if (subsection == 'audit') {
      <ng-container class="subsection">
        <app-project-audit></app-project-audit>
      </ng-container>
    }
    @if (subsection == 'settings') {
      <app-project-settings [project]="project" (onChange)="handleSettingsChange()" [projectType]="projectType" [section]="settingsSection"></app-project-settings>
    }
    @if (subsection == 'alerts') {
      <app-project-alerts [project]="project"></app-project-alerts>
    }
    @if (subsection == '') {
      @if (sections[currentSection].type == 'setup') {
        @if (!(cardNotes.open  && cardNotes.expanded)) {
          <app-project-setup #setup [project]="project" (onSave)="handleSave($event)" [step]="openSetupTab"
          ></app-project-setup>
        }
      }
      @if (sections[currentSection].type == 'setup-lite') {
        @if (!(cardNotes.open  && cardNotes.expanded)) {
          <app-project-setup #setup [project]="project" (onSave)="handleSave($event)" [step]="openSetupTab"
          [litemode]="true"></app-project-setup>
        }
      }
      @if (sections[currentSection].type == 'finance') {
        <div>
          <div class="h4">Finance Coming soon</div>
        </div>
      }
      @if (sections[currentSection].type == 'vendor-finance') {
        <div>
          <app-project-vendor-finance [project]="project"></app-project-vendor-finance>
        </div>
      }
      @if (sections[currentSection].type == 'programming') {
        <app-project-programming [project]="project"></app-project-programming>
      }
      @if (sections[currentSection].type == 'links-and-vendors') {
        <app-project-links-and-vendors (projectStatusChange)="project.projectStatus =  $event"
          (onChange)="refreshProject()" [project]="project"
        [respondents]="projectPerformance"></app-project-links-and-vendors>
      }
      @if (sections[currentSection].type == 'schedule' && project!=null) {
        <app-schedule [project]="project" [projectType]="projectType" (callback)="callbackTasks($event)"
        [refresh]="refreshTasks"></app-schedule>
      }
      @if (sections[currentSection].type == 'close' || sections[currentSection].type == 'close-external') {
        <app-project-close [project]="project" [closureType]="sections[currentSection].type"></app-project-close>
      }
      @if (sections[currentSection].type == 'quotas') {
        <app-project-quota-setup [project]="project"  [projectPerformance]="projectPerformance"></app-project-quota-setup>
      }
      @if (sections[currentSection].type == 'id-suite') {
        <app-project-id-suite [project]="project" [isClient]="isClientView || auth.isClient()" (onChange)="refreshPerformance()">
      </app-project-id-suite>
      }
      @if (sections[currentSection].type == 'homepage') {
        <app-project-homepage [data]="{project: project, respondents: projectRespondents}" [stage]="selectedStage" (callback)="homepageCallback($event)"></app-project-homepage>
      }
      @if (project != null && (sections[currentSection].type == null || sections[currentSection].type == 'card')) {
        <div
          class="paddingForProjectHeading project-container container-fluid {{ cardNotes.open ? 'notes-open ' + (cardNotes.expanded ? 'expanded' : '') : '' }}">
          @if (!cardNotes.expanded) {
            <div class="project-grid" [class.project-grid]="!cardInFullscreen">
              @for (card of sections[currentSection].cards; track card) {
                @if (card.cardType == 'reconciliation') {
                  <app-project-card-reconciliation class="expanded"
                    [project]="project" (onChange)="refreshPerformance()" [mode]="card.mode">
                  </app-project-card-reconciliation>
                }
                @if (card.cardType == 'project-info') {
                  <app-project-card-info class="" [project]="project"
                    (cardresize)="cardResize($event, card)" [mode]="card.mode"
                    [ngClass]="{'reduced' : card.mode=='reduced', 'expanded' : card.mode=='expanded'}">
                  </app-project-card-info>
                }
                @if (card.cardType == 'project-related') {
                  <app-project-card-related class="" [project]="project"
                    class="reduced">
                  </app-project-card-related>
                }
                @if (card.cardType == 'survey-finance') {
                  <app-project-card-finance class="reduced"
                  [project]="project" [respondents]="projectRespondents"></app-project-card-finance>
                }
                @if (card.cardType == 'project-documents') {
                  <app-project-card-documents class="reduced"
                    [project]="project">
                  </app-project-card-documents>
                }
                @if (card.cardType == 'project-assets') {
                  <app-project-card-assets class="reduced"
                    [project]="project">
                  </app-project-card-assets>
                }
                @if (card.cardType == 'survey-field-plan') {
                  <app-project-card-field-plan
                    [details]="{data:projectRespondents, project:project}" (openSetup)="openSetup(2)"
                    (cardresize)="cardResize($event, card)" (redirectToSection)="redirectToSection($event)"
                    [mode]="card.mode"
                    [ngClass]="{'reduced' : card.mode=='reduced', 'settings-card-expanded' : card.mode=='expanded'}">
                  </app-project-card-field-plan>
                }
                @if (card.cardType == 'survey-performance-chart') {
                  <app-project-card-survey-performance-chart
                    [respondents]="projectRespondents" [project]="project" class="performance-card-reduced">
                  </app-project-card-survey-performance-chart>
                }
                @if (card.cardType == 'survey-performance-table') {
                  <app-project-card-survey-performance-table
                    [respondents]="projectPerformance" [project]="project" class="performance-card-expanded">
                  </app-project-card-survey-performance-table>
                }
                @if (card.cardType == 'survey-cleanid') {
                  <app-project-card-cleanid [respondents]="projectRespondents"
                    [project]="project" (cardresize)="cardResize($event, card)" [mode]="card.mode"
                    (onChange)="handleIdSuiteChange($event)"
                    [ngClass]="{'reduced' : card.mode=='reduced', 'expanded' : card.mode=='expanded'}">
                  </app-project-card-cleanid>
                }
                @if (card.cardType == 'survey-dedupe') {
                  <app-project-card-dedupe [respondents]="" [project]="project"
                    class="reduced" (cardresize)="cardResize($event, card)" [mode]="card.mode">
                  </app-project-card-dedupe>
                }
                @if (card.cardType == 'project-team') {
                  <app-project-card-team [project]="project"
                    [title]="card.title" class="reduced">
                  </app-project-card-team>
                }
                @if (card.cardType == 'response-id') {
                  <app-project-card-response-id (cardresize)="cardResize($event, card)" [mode]="card.mode"
                    [ngClass]="{'reduced' : card.mode=='reduced', 'expanded' : card.mode=='expanded', 'd-flex': projectType.name == 'ResponseID', 'justify-content-center': projectType.name == 'ResponseID'}"
                    [project]="project" [projectType]="projectType">
                  </app-project-card-response-id>
                }
                @if (card.cardType == 'idsuite') {
                  <app-project-card-idsuite-settings [project]="project"
                  class="expanded"></app-project-card-idsuite-settings>
                }
                @if (card.cardType == 'idsuite-client') {
                  <app-project-card-idsuite-settings [clientMode]="true"
                  [project]="project" class="expanded"></app-project-card-idsuite-settings>
                }
                @if (card.cardType == 'vendor') {
                  <app-project-card-vendor class="expanded"
                    [respondents]="projectRespondents" [project]="project">
                  </app-project-card-vendor>
                }
              }
            </div>
          }
        </div>
      }
    }
  </div>
</div>
</div>
</div>
}
