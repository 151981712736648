<div class="card" [class.dataExists]="qcFlowDetails">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="far fa-sharp fa-light fa-badge-check cursor-default fa-lg mr-2 grey4 font-weight-normal"></i>Data Cleaning</span>
    <i class="fas fa-arrows-maximize fa-md grey4 font-weight-normal float-right cursor-pointer" (click)="openDataCleaning()"></i>
  </div>
  <div class="card-body nav-font14 pt-2">
    @if (qcFlowDetails) {
      <div class="row pb-3 mx-0">
        <div class="title nav-secondary nav-font14">
         QC Scorecard
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center responseIdChart px-0">
          <highcharts-chart id="respondentOverviewChart" [Highcharts]="highcharts"
              [options]="highchartsOptionsRespondentOverview" style="width: 200px; height: 100%; display: block;">
          </highcharts-chart>
        </div>
      </div>
      <div class="row w-100 mx-0 justify-content-center">
        <div class="key">
          <div class="">
            <i class="fas fa-square accepted nav-success nav-font14 mr-2"></i>
            <span>Accepted Respondents ({{qcFlowDetails?.status.acceptedCount}})</span>
          </div>
          <div
            class="">
            <i class="fas fa-square pending grey2 nav-font14 mr-2"></i>
            <span>Pending Respondents ({{qcFlowDetails?.status.respondentProcessedCount -
                (qcFlowDetails?.status.acceptedCount + qcFlowDetails?.status.rejectedCount)}})
            </span>
          </div>
          <div class="">
              <i class="fas fa-square rejected nav-font14 mr-2"></i>
              <span>Rejected Respondents ({{qcFlowDetails?.status.rejectedCount}})</span>
          </div>
        </div>
        
        <div class="font-weight-bold grey5 nav-font14 mt-3">
            {{(qcFlowDetails?.status.acceptedCount/(qcFlowDetails?.status.acceptedCount +
            qcFlowDetails?.status.rejectedCount) || 0) | percent}} Acceptance Rate 
        </div>
      </div>
    }
    @else {
      <div class="no-data-available">
        <div class="mb-3 nav-font16 grey5">
          No data available. Click the ‘Import data’ button to begin.
        </div>
        <div class="nav-accent-blue nav-font16 font-weight-bold font-underline cursor-pointer" (click)="openDataCleaning()"><u>Click here to import data</u></div>
      </div>
    }

  </div>
</div>
