import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { CleanIdExcel } from '../models/cleanIdExcel';

@Injectable({
  providedIn: 'root'
})
export class ExportFilesService {

  baseUrl = environment.apiEndpoint + '/fileexport';
  tzoffset = new Date().getTimezoneOffset() * -1;

  constructor(private http: HttpClient) { }

  downloadParticipantData(listOfIds: Array<string>, filename: string, hasLimit: boolean) {
    return this.http.post(this.baseUrl + '/participant?filename=' + filename + '&tzoffset=' + this.tzoffset + '&hasLimit=' + hasLimit, listOfIds, {responseType: 'text'});
  } 
  downloadParticipantDataSummary(bidNumber: string, filename: string, isClosure: boolean){
    return this.http.post(this.baseUrl + '/participant/' + bidNumber + '/summary?filename=' + filename + '&tzoffset=' + this.tzoffset + '&isClosure=' + isClosure, {}, {responseType: 'text'});
  } 
  downloadQCFlowAnalysis(projectId: string, filename: string) {
    return this.http.post(this.baseUrl + '/qcFlow/' + projectId + '?filename='+ filename, {}, {responseType: 'text'});
  }
  downloadVendorParticipantData(listOfIds: Array<string>, filename, vendorId) {
    filename = filename.replace('&','%26');
    return this.http.post(this.baseUrl + '/vendorParticipant?filename=' + filename + '&tzoffset=' + this.tzoffset+'&vendorId='+vendorId, listOfIds, {responseType: 'text'});
  }
  downloadVendorParticipantDataSummary(bidNumber: string, filename, vendorId) {
    return this.http.post(this.baseUrl + '/vendorParticipant/' + bidNumber + '/summary?filename=' + filename + '&tzoffset=' + this.tzoffset+'&vendorId='+ vendorId, {}, {responseType: 'text'});
  }
  downloadDuplicateData(listOfIds: Array<string>, filename) {
    return this.http.post(this.baseUrl + '/dupes?filename=' + filename + '&tzoffset=' + this.tzoffset, listOfIds, {responseType: 'text'});
  }
  downloadDuplicateDataSummary(bidNumber: string, filename) {
    return this.http.post(this.baseUrl + '/summary/' + bidNumber +'/dupes?filename=' + filename + '&tzoffset=' + this.tzoffset, {}, {responseType: 'text'});
  }
  downloadPartnerParticipants(id: uuidv4, filename) {
    return this.http.get(this.baseUrl + '/' + id + '/participants?filename=' + filename + '&tzoffset=' + this.tzoffset, {responseType: 'text'});
  }
  downloadPartnerParticipantsSummary(bidNumber: string, filename) {
    return this.http.get(this.baseUrl + '/summary/' + bidNumber + '/participants?filename=' + filename + '&tzoffset=' + this.tzoffset, {responseType: 'text'});
  }
  downloadParticipantsByPartner(projectId: uuidv4, partnerId: uuidv4, filename: string) {
    return this.http.get(this.baseUrl + '/' + projectId + '/participants/' + partnerId +'?filename=' + filename + '&tzoffset=' + this.tzoffset, {responseType: 'text'});
  }
  downloadParticipantsByPartnerSummary(bidNumber: string, partnerId: uuidv4, filename: string) {
    return this.http.get(this.baseUrl + '/summary/' + bidNumber + '/participants/' + partnerId +'?filename=' + filename + '&tzoffset=' + this.tzoffset, {responseType: 'text'});
  }
  downloadPartnerCompletes(closureId: uuidv4, filename, projectcode) {
    return this.http.get(this.baseUrl + '/' + closureId + '/completes?filename=' + filename + '&projectcode=' + projectcode + '&tzoffset=' + this.tzoffset, {responseType: 'text'});
  }
  downloadPartnerCompletesSummary(bidNumber: string, filename) {
    return this.http.get(this.baseUrl + '/summary/' + bidNumber + '/completes?filename=' + filename + '&tzoffset=' + this.tzoffset, {responseType: 'text'});
  }
  downloadInvoice(id: uuidv4, filename) {
    return this.http.get(this.baseUrl + '/' + id + '/invoice?filename=' + filename, {responseType: 'text'});
  }
  downloadBilling(id: uuidv4, filename) {
    return this.http.get(this.baseUrl + '/' + id + '/billing?filename=' + filename, {responseType: 'text'});
  }
  downloadCleanIDExcel(cleanIdExcel: CleanIdExcel): any {
    return this.http.post(this.baseUrl + '/downloadcleanidexcel', cleanIdExcel, {responseType: 'blob'});
  }
  downloadCleanID(projectId: uuidv4, filename:string,isReportLocation:boolean) {
    return this.http.get(this.baseUrl + '/CleanId?eventId=' + projectId+'&filename=' + filename+'&location=' + isReportLocation, {responseType: 'text'});
  }
  downloadCleanIDSummary(bidNumber: string, filename: string, isReportLocation: boolean) {
    return this.http.get(this.baseUrl + '/summary/' + bidNumber + '/CleanId?filename=' + filename + '&location=' + isReportLocation, {responseType: 'text'});
  }
  downloadResponseIdRaw(projectid, filename) {
    return this.http.get(this.baseUrl + '/ResponseIdRaw?projectid=' + projectid + '&filename=' + filename, {responseType: 'text'});  
  }
  downloadResponseIdSummary(projectid, filename) {
    return this.http.get(this.baseUrl + '/ResponseIdSummary?projectid=' + projectid + '&filename=' + filename, {responseType: 'text'});  
  }
  fullReport(projectIds: Array<string>,filename,bidNumber) {    
    return this.http.post(this.baseUrl + '/fullreport/?filename=' + filename + '&bidNumber='+bidNumber+'&tzoffset=' + this.tzoffset,projectIds, { responseType: 'text'});
  }
}
