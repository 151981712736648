<div class="card border-0 m-0 w-auto h-100">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h4 class="nav-secondary font-weight-bold mb-0 d-inline">Upcoming Projects</h4>
      </div>
      <div class="col text-right">
        <span class="nav-font14 mb-0 font-weight-normal grey4">({{projects.length}})</span>
      </div>
    </div>
  </div>

  <div class="card-body pt-2">
    <div>
      @if (projects.length === 0) {
        <div class="grey4 small">No upcoming projects.</div>
      }
      @if (projects.length > 0) {
        @for (upcoming of projects; track upcoming; let idx = $index) {
          @if (idx > 0) {
            <hr>
            }
            <div class="project mb-2 py-3 px-2">
              <div class="row mb-1">
                <div class="col-7">
                  <div class="d-block text-truncate">
                    <a [routerLink]="['/projects', upcoming.id]" class="nav-font14 font-weight-bold nav-accent-blue cursor-pointer">{{ upcoming.projectCode }}</a>
                  </div>
                </div>
                <div class="col text-right">
                  <span class="nav-font14 mb-0 font-weight-normal grey3">{{ upcoming.projectStartDate | date: 'MMM d'  }}</span>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col">
                  <span [routerLink]="['/projects', upcoming.id]" class="nav-font14 text-truncate grey4">{{ upcoming.projectName }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <span class="nav-font14 text-truncate grey4">{{ upcoming.clientName }}</span>
                </div>
              </div>
            </div>
          }
        }
      </div>
    </div>
  </div>
