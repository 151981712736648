<div class="nav-content">
  <!-- BREADCRUMBS -->
  <div class="row">
    <div class="col-12 breadcrumb mr-auto bg-transparent mb-0 py-0">
      <div class="d-flex align-items-center">
        <div class="nav-secondary d-flex align-items-center">
          <app-pagetitle></app-pagetitle>
        </div>
      </div>
    </div>
  </div>
  @if (currentView != null) {
    <div class="container-fluid mt-3">
      <div class="mr-4">
        <div class="row">
          <div class="col">
            @if (views.length ==  1) {
              <div class="float-left h5 or-secondary m-1 mr-3">{{ currentView.name }}</div>
            }
            @if (views.length > 1) {
              <div class="btn-group mr-3 mb-1 id-projectlist-listview-dropdown" dropdown>
                <button dropdownToggle type="button" class="bg-transparent btn-outline-secondary border-0 px-0">
                  <span class="mr-2 nav-font24 grey6 font-weight-bold">{{ currentView.name }}</span>
                  <i class="fak fa-down-arrow fa-xs grey4"></i>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" style="width: 300px;">
                  @for (view of views; track view; let i = $index) {
                    <li role="menuitem" class="p-2">
                      <or-action-button [label]="view.name" [description]="view.description" [icon]="view.icon + ' mt-2'"
                      (selected)="switchView(i)"></or-action-button>
                    </li>
                  }
                </ul>
              </div>
            }
            @if (statusCounts.length > 1) {
              <div class="btn-group flex-sm-wrap" role="group" id="statusButtons">
                @for (item of statusCounts; track item) {
                  <button class="btn shadow-sm btn-outline-secondary px-1 mb-1"  [ngClass]="'id-projectlist-status-' + item.status.toLowerCase().replace(' ', '')"
                    [class.active]="activeFilters.includes(item.status)" [attr.data-status]="item.status"
                    (click)="filterStatus(item.status)">
                    <div class="d-flex">
                      <div class="mr-2">
                        <i class="fa-status" [ngClass]="item.status === 'All' ? 'fas' : 'fak'"></i>
                      </div>
                      <div class="d-flex align-items-center justify-content-center vl">
                        <div class="d-flex align-items-center">
                          <span class="font-weight-bold mx-2">{{ item.count || 0 }}</span>
                          <p class="small mr-2 m-0 status">{{ item.status }}</p>
                        </div>
                      </div>
                    </div>
                  </button>
                }
              </div>
            }
          </div>
        </div>
        <div class="row mt-2 mb-4">
          <div class="col d-flex align-items-center">
            @if (currentView.showFilterText) {
              <div>
                <div class="input-group project-search">
                  <div class="input-group-prepend">
                    <div class="input-group-text border-right-0">
                      <i class="fas fa-search cursor-default"></i>
                    </div>
                  </div>
                  <input [(ngModel)]="settings.filterText" type="search" class="form-control form-control-sm"
                    (ngModelChange)="updateProjectList()" [placeholder]="currentView.filterTextLabel">
                  </div>
                </div>
              }
              @if (currentView.viewModes.length > 1) {
                <div>
                  <span class="nav-font14 grey5 ml-3 mr-2">View</span>
                  <or-view-toggle (valueChange)='switchMode($event)' [showList]="showList" [showCard]="showCard"
                    [showGantt]="showGantt" [showCalendar]="showCalendar" [showCardGroup]="showCardGroup"
                  [active]="mode"></or-view-toggle>
                </div>
              }
              <ul class="list-group list-group-horizontal ml-3">
                @if (currentView.showFavouriteButton) {
                  <li class="id-projectlist-favorites list-group-item border-0 p-1 bg-transparent">
                    <or-toggle-button id="myFavorites" (changed)="updateProjectList()"
                      [(ngModel)]="settings.views[currentView.id].myFavorites" icon="fa-star"
                    iconTooltip="My Favorites"></or-toggle-button>
                  </li>
                }
                @if (auth.isInternal() && currentView.showMyProjects) {
                  <li
                    class="id-projectlist-myprojects list-group-item border-0 p-1 bg-transparent">
                    <or-toggle-button id="myProjects" (changed)="updateProjectList()"
                      [(ngModel)]="settings.views[currentView.id].myProjects" icon="fa-user"
                    iconTooltip="My Projects"></or-toggle-button>
                  </li>
                }
              </ul>
              @if (hasSettings() && this.auth.isVendor()) {
                <div class="btn-group ml-auto" dropdown>
                  <span id="projectImportExportDropdown" dropdownToggle type="button" class="btn-lg p-2"
                    aria-controls="dropdown-basic">
                    <i class="fak fa-ellipsis-vertical grey4 ml-1"></i>
                  </span>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                    aria-labelledby="projectImportExportDropdown" style="width: 250px;">
                    @if (currentView.showDownload) {
                      <li role="menuitem" class="p-2">
                        <or-action-button label="Participant Export" description="Download participants across projects"
                        icon="fak fa-download" (selected)="bulkDLParticipantExport()"></or-action-button>
                      </li>
                    }
                  </ul>
                </div>
              }
              @if (hasSettings() && this.auth.isInternal()) {
                <div class="btn-group ml-auto" dropdown>
                  <span id="projectImportExportDropdown" dropdownToggle type="button" class="btn-lg p-2"
                    aria-controls="dropdown-basic">
                    <i class="fak fa-ellipsis-vertical grey4 ml-1"></i>
                  </span>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                    aria-labelledby="projectImportExportDropdown" style="width: 250px;">
                    @if (currentView.showDownload) {
                      <li role="menuitem" class="p-2">
                        <or-action-button label="Participant Export" description="Download participants across projects"
                        icon="fak fa-download" (selected)="bulkDLParticipantExport()"></or-action-button>
                      </li>
                    }
                    @if (currentView.showCopyUrls) {
                      <li role="menuitem" class="p-2">
                        <or-action-button label="Copy Entry Urls" description="Get URL's for projects in list"
                        icon="fak fa-copy" (selected)="openModal(modalUrls, 'URL')"></or-action-button>
                      </li>
                    }
                    @if (mode == 'card' || mode == 'cardGroup') {
                      @if (settings.views[currentView.id].groupBy != 'status') {
                        <li role="menuitem" class="p-2">
                          <or-action-button label="Group by Status" description="Group Projects by Status" icon="fak fa-groups"
                          (selected)="setGroupBy('status')"></or-action-button>
                        </li>
                      }
                      @if (settings.views[currentView.id].groupBy != 'client') {
                        <li role="menuitem" class="p-2">
                          <or-action-button label="Group by Client" description="Group Projects by Client" icon="fak fa-groups"
                          (selected)="setGroupBy('client')"></or-action-button>
                        </li>
                      }
                      @if (settings.views[currentView.id].groupBy != 'projectManager') {
                        <li role="menuitem" class="p-2">
                          <or-action-button label="Group by PM" description="Group Projects by PM" icon="fak fa-groups"
                          (selected)="setGroupBy('projectManager')"></or-action-button>
                        </li>
                      }
                      @if (settings.views[currentView.id].groupBy != 'accountOwner') {
                        <li role="menuitem" class="p-2">
                          <or-action-button label="Group by Account Owner" description="Group Projects by Account Owner"
                          icon="fak fa-groups" (selected)="setGroupBy('accountOwner')"></or-action-button>
                        </li>
                      }
                      @if (settings.views[currentView.id].groupBy != '') {
                        <li role="menuitem" class="p-2">
                          <or-action-button label="Remove Grouping" description="Remove Project Groups" icon="fak fa-none"
                          (selected)="setGroupBy('')"></or-action-button>
                        </li>
                      }
                    }
                    <!-- <a class="dropdown-item" (click)="bulkDLParticipantExport()">Bulk Participant DL</a> -->
                    <!--<li class="list-group-item border-0">
                    <or-toggle-button (changed)="changeTheme($event)" [(ngModel)]="darkTheme" icon="fa-grin-alt fa-lg" iconTooltip="Dark Theme"></or-toggle-button>
                  </li>-->
                </ul>
              </div>
            }
          </div>
        </div>
      </div>
      @if (loading == true) {
        <div>
          <div class="loading"></div>
        </div>
      }
      @if (loading == false && projects.length == 0) {
        <div>
          <div>No projects available</div>
        </div>
      }
      @if (loading == false && mode == 'card') {
        @if (projectGroups.length > 1) {
          @for (group of projectGroups; track group) {
            <div class="row mb-3 stage py-3 mx-2">
              <div class="col">
                <div class="row">
                  <div class="col d-flex align-items-center">
                    <div class="col px-0">
                      <div class="d-flex align-items-center">
                        <span class="nav-black1 font-weight-bold nav-font16 mr-3">{{ group.title }}</span>
                        <span class="nav-black1 font-weight-bold nav-font16">
                          ({{ group.projects.length }} {{ group.projects.length == 1 ? 'Project' : 'Projects' }})
                        </span>
                      </div>
                    </div>
                    <div class="col px-0 grey5 d-flex align-items-center justify-content-end">
                      <i class="far fa-lg fa-angle-down grey4 ml-3" [class.fa-angle-up]="group.open"
                      [class.fa-angle-down]="!group.open" (click)="group.open = !group.open"></i>
                    </div>
                  </div>
                </div>
                @if (group.open) {
                  <div class="row projectcards my-3 grey4 nav-font14 font-weight-bold">
                    @for (project of group.projects; track project) {
                      <div class="card-cell">
                        @if (currentView.cardStyle == 'survey') {
                          <app-project-card-survey [project]="project"
                            [actions]="rowActions" [isFavorite]="favorites[project.id]"
                            (favoriteChange)="FavoriteChange($event, project)" (action)="handleAction($event)">
                          </app-project-card-survey>
                        }
                        @if (currentView.cardStyle == 'idsuite') {
                          <app-project-card-idsuite [project]="project"
                            [actions]="rowActions" [isFavorite]="favorites[project.id]"
                            (favoriteChange)="FavoriteChange($event, project)" (action)="handleAction($event)">
                          </app-project-card-idsuite>
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          }
        }
        @if (projectGroups.length <= 1 && projects.length > 0) {
          <div class="projectcards mt-3 mb-3">
            @for (project of projects; track project) {
              <div class="card-cell">
                @if (currentView.cardStyle == 'survey') {
                  <app-project-card-survey [project]="project"
                    [actions]="rowActions" [isFavorite]="favorites[project.id]"
                    (favoriteChange)="FavoriteChange($event, project)" (action)="handleAction($event)">
                  </app-project-card-survey>
                }
                @if (currentView.cardStyle == 'idsuite') {
                  <app-project-card-idsuite [project]="project"
                    [actions]="rowActions" [isFavorite]="favorites[project.id]"
                    (favoriteChange)="FavoriteChange($event, project)" (action)="handleAction($event)">
                  </app-project-card-idsuite>
                }
                @if (currentView.cardStyle == 'bid') {
                  <app-project-card-bid [project]="project"
                    [isFavorite]="favorites[project.id]" (favoriteChange)="FavoriteChange($event, project)"
                    (action)="handleAction($event)">
                  </app-project-card-bid>
                }
              </div>
            }
          </div>
        }
        @if (!this.allProjectsLoaded) {
          <button class="nav-btn-primary shadow-none w-100 mb-3 mt-3"
          (click)="loadAllProjects()">Load all</button>
        }
      }
      @if (loading == false && mode == 'cardGroup' && projectCardGroups.length > 0) {
        @if (projectGroups.length > 1) {
          @for (group of projectGroups; track group) {
            <div class="row mb-3 stage py-3 mx-2">
              <div class="col">
                <div class="row">
                  <div class="col d-flex align-items-center">
                    <div class="col px-0">
                      <div class="d-flex align-items-center">
                        <span class="nav-black1 font-weight-bold nav-font16 mr-3">{{ group.title }}</span>
                        <span class="nav-black1 font-weight-bold nav-font16">
                          ({{ group.projects.length }} {{ group.projects.length == 1 ? 'Project' : 'Projects' }})
                        </span>
                      </div>
                    </div>
                    <div class="col px-0 grey5 d-flex align-items-center justify-content-end">
                      <i class="far fa-lg fa-angle-down grey4 ml-3" [class.fa-angle-up]="group.open"
                      [class.fa-angle-down]="!group.open" (click)="group.open = !group.open"></i>
                    </div>
                  </div>
                </div>
                @if (group.open) {
                  <div class="row projectcards my-3 grey4 nav-font14 font-weight-bold">
                    @for (group of getFilteredGroups(group); track group) {
                      @if (group.projects.length == 1) {
                        <div class="card-cell">
                          @if (currentView.cardStyle == 'survey') {
                            <app-project-card-survey [project]="group.projects[0]"
                              [actions]="rowActions" [isFavorite]="favorites[group.projects[0].id]"
                              (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                            </app-project-card-survey>
                          }
                          @if (currentView.cardStyle == 'idsuite') {
                            <app-project-card-idsuite [project]="group.projects[0]"
                              [actions]="rowActions" [isFavorite]="favorites[group.projects[0].id]"
                              (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                            </app-project-card-idsuite>
                          }
                          @if (currentView.cardStyle == 'bid') {
                            <app-project-card-bid [project]="group.projects[0]"
                              [isFavorite]="favorites[group.projects[0].id]"
                              (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                            </app-project-card-bid>
                          }
                        </div>
                      }
                      @if (group.projects.length > 1) {
                        @for (currentProject of group.currentPage; track currentProject) {
                          <div class="card-cell group">
                            @if (currentView.cardStyle == 'survey') {
                              @if (currentProject?.type !== 'summary') {
                                <app-project-card-survey [project]="currentProject"
                                  [actions]="rowActions" [isGroup]="true" [isFavorite]="favorites[currentProject.id]"
                                  (favoriteChange)="FavoriteChange($event, currentProject)" (action)="handleAction($event)">
                                </app-project-card-survey>
                              }
                              @if (currentProject?.type === 'summary') {
                                <app-project-card-survey-summary [summary]="currentProject"
                                  [isGroup]="true" (action)="handleAction($event)">
                                </app-project-card-survey-summary>
                              }
                            }
                            @if (currentView.cardStyle == 'idsuite') {
                              <app-project-card-idsuite [project]="currentProject"
                                [actions]="rowActions" [isFavorite]="favorites[currentProject.id]"
                                (favoriteChange)="FavoriteChange($event, currentProject)" (action)="handleAction($event)">
                              </app-project-card-idsuite>
                            }
                            @if (currentView.cardStyle == 'bid') {
                              <app-project-card-bid [project]="currentProject"
                                [isFavorite]="favorites[currentProject.id]" (favoriteChange)="FavoriteChange($event, currentProject)"
                                (action)="handleAction($event)">
                              </app-project-card-bid>
                            }
                            <ng-template *ngTemplateOutlet="tplPagination; context:{ $implicit: group}"></ng-template>
                          </div>
                        }
                      }
                    }
                  </div>
                }
              </div>
            </div>
          }
        }
        @if (projectGroups.length <= 1) {
          <div class="projectcards mt-3 mb-3">
            @for (group of projectCardGroups; track group) {
              @if (group.projects.length == 1) {
                <div class="card-cell">
                  @if (currentView.cardStyle == 'survey') {
                    <app-project-card-survey [project]="group.projects[0]"
                      [actions]="rowActions" [isFavorite]="favorites[group.projects[0].id]"
                      (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                    </app-project-card-survey>
                  }
                  @if (currentView.cardStyle == 'idsuite') {
                    <app-project-card-idsuite [project]="group.projects[0]"
                      [actions]="rowActions" [isFavorite]="favorites[group.projects[0].id]"
                      (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                    </app-project-card-idsuite>
                  }
                  @if (currentView.cardStyle == 'bid') {
                    <app-project-card-bid [project]="group.projects[0]"
                      [isFavorite]="favorites[group.projects[0].id]"
                      (favoriteChange)="FavoriteChange($event, group.projects[0])" (action)="handleAction($event)">
                    </app-project-card-bid>
                  }
                </div>
              }
              @if (group.projects.length > 1) {
                @for (currentProject of group.currentPage; track currentProject) {
                  <div class="card-cell group">
                    @if (currentView.cardStyle == 'survey') {
                      @if (currentProject?.type !== 'summary') {
                        <app-project-card-survey [project]="currentProject"
                          [actions]="rowActions" [isGroup]="true" [isFavorite]="favorites[currentProject.id]"
                          (favoriteChange)="FavoriteChange($event, currentProject)" (action)="handleAction($event)">
                        </app-project-card-survey>
                      }
                      @if (currentProject?.type === 'summary') {
                        <app-project-card-survey-summary [summary]="currentProject"
                          [isGroup]="true" (action)="handleAction($event)">
                        </app-project-card-survey-summary>
                      }
                    }
                    @if (currentView.cardStyle == 'idsuite') {
                      <app-project-card-idsuite [project]="currentProject"
                        [actions]="rowActions" [isFavorite]="favorites[currentProject.id]"
                        (favoriteChange)="FavoriteChange($event, currentProject)" (action)="handleAction($event)">
                      </app-project-card-idsuite>
                    }
                    @if (currentView.cardStyle == 'bid') {
                      <app-project-card-bid [project]="currentProject"
                        [isFavorite]="favorites[currentProject.id]" (favoriteChange)="FavoriteChange($event, currentProject)"
                        (action)="handleAction($event)">
                      </app-project-card-bid>
                    }
                    <ng-template *ngTemplateOutlet="tplPagination; context:{ $implicit: group}"></ng-template>
                  </div>
                }
              }
            }
          </div>
        }
        @if (!this.allProjectsLoaded) {
          <button class="nav-btn-primary shadow-none w-100 mb-3 mt-3"
          (click)="loadAllProjects()">Load all</button>
        }
      }
      @if (loading == false && mode == 'list' && allProjects.length > 0) {
        <div class="mr-3 mt-3">
          <or-list [items]="allProjects" [loadcount]="100" [layout]="currentView.tableColumns" [actions]="rowActions"
            (action)="handleAction($event)" [listsettings]="layoutListSettings" (changed)="saveProjectChange($event)"
            (settingsupdate)="saveProjectsListSetting($event)" rowid="id" [clickroute]="clickroute">
          </or-list>
        </div>
      }
      @if (loading == false && mode == 'calendar' && projects.length > 0) {
        <div class="container-fluid mb-4">
          <or-calendar [data]="allTasks" idField="id" dateField="projectStartDate" titleField="projectName"
            subtitleField="clientName" profileField="projectManager" footerField="projectStatus"
          (selected)="openProjectFromCalendar($event)"></or-calendar>
        </div>
      }
      @if (loading == false && mode == 'gantt' && projects.length > 0) {
        <div class="mr-3 mt-3">
          <div class="container-fluid mb-4">
            @if (ganttHighchartOptions != null) {
              <or-project-gantt-view [projectList]="projects"></or-project-gantt-view>
            }
          </div>
        </div>
      }
    </div>
  }
</div>

<!-- pagination -->
<ng-template #tplPagination let-group>
  <div class="pagination position-relative">
    <div class="row w-100 position-absolute">
      <div class="col left-arrow d-flex justify-content-start">
        <i class="fa-duotone fa-xl cursor-pointer fa-circle-chevron-left" (click)="pageGroupChanged('previous', group)"
          style="--fa-primary-color: #ffffff; --fa-secondary-color: #b5b8bd; --fa-secondary-opacity: 0.5;">
        </i>
      </div>
      <div class="col right-arrow d-flex justify-content-end">
        <i class="fa-duotone fa-xl cursor-pointer fa-circle-chevron-right" (click)="pageGroupChanged('next', group)"
          style="--fa-primary-color: #ffffff; --fa-secondary-color: #b5b8bd; --fa-secondary-opacity: 0.5;">
        </i>
      </div>
    </div>
  </div>
</ng-template>


<!-- MODAL COPY ENTRY URLS -->
<ng-template #modalUrls>
  <div class="modal-header">
    <p class="h4 mr-3 mb-0">
      Entry Urls
    </p>
    <button type="button" class="btn btn-sm btn-light" (click)="copyAllUrls()"><i class="far fa-copy"></i> Copy All
  Urls</button>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="openModalRef.hide();">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="card border-secondary mb-3">
    <div class="card-body text-secondary">
      <h5 class="display-6 mb-3">
        Redirects
        <i class="far fa-copy float-right" (click)="copyRedirectUrls()"></i>
      </h5>
      <p class="card-text">
        <small class="d-block font-weight-bold">Complete</small>
        <small class="d-block">{{ redirectUrls.completeRedirectURL }}</small>
      </p>
      <p class="card-text">
        <small class="d-block font-weight-bold">Terminate</small>
        <small class="d-block">{{ redirectUrls.terminateRedirectURL }}</small>
      </p>
      <p class="card-text">
        <small class="d-block font-weight-bold">Overquota</small>
        <small class="d-block">{{ redirectUrls.overQuotaRedirectURL }}</small>
      </p>
      <p class="card-text">
        <small class="d-block font-weight-bold">QC</small>
        <small class="d-block">{{ redirectUrls.qcRedirectURL }}</small>
      </p>
    </div>
  </div>

  @for (item of listUrls; track item) {
    <div class="card border-secondary mb-3">
      <div class="card-body text-secondary">
        <h5 class="display-6 mb-3">
          {{ item.vendor }}
          <i class="far fa-copy float-right" (click)="copyProjectsUrls(item)"></i>
        </h5>
        @for (url of item.urls; track url) {
          <p class="card-text">
            <small class="d-block font-weight-bold">{{ url.projectName }}</small>
            <small class="d-block">{{ url.surveyEntryUrl }}</small>
          </p>
        }
      </div>
    </div>
  }
</div>
</ng-template>

<!-- MODAL CONFIRM DELETE -->
<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete '{{this.selectedProject.projectName}}'?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>