<div class="card m-1">
  <div class="card-header my-3 px-0">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="grey5 font-weight-bold">Performance Overview</h3>
      </div>
      @if (!this.auth.isClient()) {
        @if (data?.length > 0) {
          <div class="col-2 d-flex grey4 nav-font14 align-items-center justify-content-end">
            <span class="mr-2 text-nowrap">View by</span>
            <div class="viewby" dropdown #dropdown="bs-dropdown" [class.open]="dropdown.isOpen">
              <div dropdownToggle class="cursor-pointer w-100 d-flex align-items-center justify-content-end">
                <span class="grey5 pl-1">{{viewByText}}</span>
                <i class="fak fa-down-arrow fa-xs ml-auto mt-1" [class.fa-rotate-180]="dropdown.isOpen"></i>
              </div>
              <ul *dropdownMenu class="dropdown-menu nav-font14 py-0" role="menu">
                @for (item of viewByList; track item) {
                  <li role="menuitem" class="mx-1 cursor-pointer" (click)="setTableData(item.id)">
                    <a class="dropdown-item px-2 border-bottom">{{item.name}}</a>
                  </li>
                }
              </ul>
            </div>
          </div>
        }
      }
    </div>
  </div>
  @if (data?.length == 0 && !loading) {
    <div class="card-body performanceBody px-0">
      <div class="or-secondary">
        No Participant Data
      </div>
    </div>
  }
  @if (loading) {
    <div class="card-body performanceBody px-0">
      <div class="row mx-4">
        @if (loading) {
          <div class="col-6 d-flex align-items-center justify-content-end mt-4">
            <div class="loading loading-lg" ></div>
          </div>
        }
      </div>
    </div>
  }

  @if (data?.length > 0) {
    <div class="card-body pt-0 performanceBody px-0">
      <div class="overflow-hidden">
        <table class="table mb-0 position-relative" style="width: max-content;" #scrollTable>
          <thead class="thead-light">
            <tr class="nav-font12 font-weight-bold nav-black1 text-center">
              <td class="nav-bg-snow-white position-sticky heading" style="left:0; z-index: 2;"></td>
              <td class="purple heading border-left" [attr.colspan]="getPreColumnCount()">
                <div class="row">
                  <div class="col-1 d-flex justify-content-start align-items-center">
                    <span class="d-flex justify-content-start align-items-center"  (click)="handlePerfCardViewSettings('PreSurvey')">
                      @if (!perfCardViewSettings.showPreSurveyBreakdown) {
                        <i class="fas fa-plus-circle cursor-pointer grey4 fa-lg nav-font18"></i>
                      }
                      @if (perfCardViewSettings.showPreSurveyBreakdown) {
                        <i class="fas fa-minus-circle cursor-pointer grey4 fa-lg nav-font18"></i>
                      }
                    </span>
                  </div>
                  <div class="col" [ngClass]="!perfCardViewSettings.showPreSurveyBreakdown ? 'text-left' : 'text-center'">
                    <span  class="col">Pre-Survey</span>
                  </div>
                </div>
              </td>
              <td class="nav-bg-snow-white heading border-left" [attr.colspan]="perfCardViewSettings.showInSurveyBreakdown ? 5 : null">
                <div class="row">
                  <div class="col-1 d-flex justify-content-start align-items-center">
                    <span class="d-flex justify-content-start align-items-center"  (click)="handlePerfCardViewSettings('InSurvey')">
                      @if (!perfCardViewSettings.showInSurveyBreakdown) {
                        <i class="fas fa-plus-circle cursor-pointer grey4 fa-lg nav-font18"></i>
                      }
                      @if (perfCardViewSettings.showInSurveyBreakdown) {
                        <i class="fas fa-minus-circle cursor-pointer grey4 fa-lg nav-font18"></i>
                      }
                    </span>
                  </div>
                  <div class="col" [ngClass]="!perfCardViewSettings.showInSurveyBreakdown ? 'text-left' : 'text-center'">
                    <span  class="col">In Survey</span>
                  </div>
                </div>
              </td>
              <td class="completes heading border-left">
                <span class="ml-2">Completes</span>
                <i class="fak fa-info ml-1 font-weight-normal nav-font14" container="body" tooltip="An interview that is completed in totality and included in the final datafile for a research study."
                placement="top"></i>
              </td>
              <td class="green first-col border-left heading" [attr.colspan]="perfCardViewSettings.showKPIBreakdown ? 5 : 2">
                <div class="row">
                  <div class="col-1 d-flex justify-content-start align-items-center">
                    <span class="d-flex justify-content-start align-items-center"  (click)="handlePerfCardViewSettings('KPISurvey')">
                      @if (!perfCardViewSettings.showKPIBreakdown) {
                        <i class="fas fa-plus-circle cursor-pointer grey4 fa-lg nav-font18"></i>
                      }
                      @if (perfCardViewSettings.showKPIBreakdown) {
                        <i class="fas fa-minus-circle cursor-pointer grey4 fa-lg nav-font18"></i>
                      }
                    </span>
                  </div>
                  <div class="col" [ngClass]="!perfCardViewSettings.showKPIBreakdown ? 'text-left' : 'text-center'">
                    <span  class="col">KPIs</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="nav-font14 font-weight-bold grey5 nav-bg-snow-white text-right">
              <td class="text-left first-row position-sticky nav-bg-snow-white" style="left:0; z-index: 2;">{{ tableMode === 'partners' ? 'Vendor' : ( tableMode === 'inDate' ? 'Date' : 'Project' )}} <i class="fas fa-sort fa-sm cursor-pointer grey4 ml-2" (click)="sortTable()"></i></td>
              @if (!perfCardViewSettings.showPreSurveyBreakdown) {
                <td class="purple border-left">
                  <div class="row">
                    <div class="col text-right">
                      Nav Entry
                      <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="The recognition of a respondent attempting to enter an online survey.
                      This transaction is not considered a survey start until CleanID evaluation is cleared"
                      placement="top"></i>
                    </div>
                  </div>
                </td>
              }
              @if (perfCardViewSettings.showPreSurveyBreakdown) {
                <td class="purple first-col text-nowrap border-left">
                  <div class="row">
                    <div class="col text-right">
                      Nav Entry
                      <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="The recognition of a respondent attempting to enter an online survey.
                      This transaction is not considered a survey start until CleanID evaluation is cleared"
                      placement="top"></i>
                    </div>
                  </div>
                </td>
                @if (summary.cid > 0) {
                  <td class="purple">
                    CID-Fraud
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="CleanID Fraud: The number of respondents that CleanID has blocked from
                  entering the survey for surpassing the CleanID threshold set for the survey"
                    placement="top"></i>
                  </td>
                }
                <td class="purple">
                  CID-Dupes
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="CleanID Dupe: The identification of a duplicate participant in the online survey or data file;
                  indicative that the same person attempted to participate in the same survey more than once with a status determined in setup."
                  placement="top"></i>
                </td>
                <td class="purple last-col">
                  Nav OQ
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="A Nav entry that is stopped because the Overall Quota or Partner Allocation in the Nav is completed."
                  placement="top"></i>
                </td>
                @if (summary.navTerminate > 0) {
                  <td class="purple last-col">
                    Nav Term
                    <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="A Nav entry that was stopped because the respondent did not fill a quota set on the Quotas tab."
                    placement="top"></i>
                  </td>
                }
                @if (summary.mobileBlock > 0) {
                  <td class="purple last-col">
                    Mobile Block
                    <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="When the study doesn't allow mobile via the settings."
                    placement="top"></i>
                  </td>
                }
                @if (summary.limitOverquota > 0) {
                  <td class="purple last-col">Limit OQ</td>
                }
              }
              @if (!perfCardViewSettings.showInSurveyBreakdown) {
                <td class="bg-snow-white border-left">
                  <div class="row">
                    <div class="col text-right">
                      Starts
                      <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="The recognition of a respondent successfully accessing the online survey after
                      passing through Navigator's CleanID evaluation and Quota checks."
                      placement="top"></i>
                    </div>
                  </div>
                </td>
              }
              @if (perfCardViewSettings.showInSurveyBreakdown) {
                <td class="first-col border-left">
                  <div class="row">
                    <div class="col text-right">
                      Starts
                      <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="The recognition of a respondent successfully accessing the online survey after
                        passing through Navigator's CleanID evaluation and Quota checks."
                      placement="top"></i>
                    </div>
                  </div>
                </td>
                <td>
                  Terminates
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="An interview that is stopped at some point in the online survey because the respondent does not qualify."
                  placement="top"></i>
                </td>
                <td class="text-nowrap">
                  Overquotas
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="An interview that is stopped at some point in the online survey because the respondent's quota cell is completed."
                  placement="top"></i>
                </td>
                <td class="last-col">
                  Auto QCs
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="This occurs when a respondent triggers survey traps or displays behavior that leads to automatic termination for quality control."
                  placement="top"></i>
                </td>
                <td class="last-col">
                  Manual QCs
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="This occurs when a respondent's survey responses are evaluated by researchers as poor quality, without triggering in-survey or fraudulent evaluations."
                  placement="top"></i>
                </td>
              }
              <td class="completes first-row border-left">Completes</td>
              @if (!perfCardViewSettings.showKPIBreakdown) {
                <td class="green first-col border-left">
                  <div class="row">
                    <div class="col text-right">
                      IR
                      <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="Incidence Rate = Completes/(Completes + Terminates).
                      The percentage of completes as a qualification rate (Ex. A survey has 10 completes and 5 terminates would have a 67% IR)"
                      placement="top"></i>
                    </div>
                  </div>
                </td>
                <td class="green last-col">
                  LOI (min)
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="Length of Interview = The median number of minutes calculated from the respondents
                  who complete the online survey."
                  placement="top"></i>
                </td>
              }
              @if (perfCardViewSettings.showKPIBreakdown) {
                <td class="green first-col border-left">
                  <div class="row">
                    <div class="col text-right">
                      IR
                      <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="Incidence Rate = Completes/(Completes + Terminates).
                      The percentage of completes as a qualification rate (Ex. A survey has 10 completes and 5 terminates would have a 67% IR)"
                      placement="top"></i>
                    </div>
                  </div>
                </td>
                <td class="green">
                  LOI (min)
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="Length of Interview = The median number of minutes calculated from the respondents
                  who complete the online survey."
                  placement="top"></i>
                </td>
                <td class="green">
                  DOR
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="Drop Out Rate = ((Starts-(Completes+Terminates+Overquotas+QCs))/Starts)
                  (The percentage of the number of respondents who start an online survey, but do not complete it,
                  divided by the total number of respondents who started the survey)
                  (Ex. A survey has 100 starts of which 40 were completed/terminated/OQ/QC'd will have a 60% Dropout Rate)"
                  placement="top"></i>
                </td>
                <td class="green text-nowrap">
                  Conv. Rate
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="Conversion Rate = (Completes/Starts).
                  The percentage of survey starts which result in a 'complete' status within the survey.
                  (Ex. A survey has 10 completes and 50 starts would have a 20% Conv Rate)"
                  placement="top"></i>
                </td>
                <td class="green  last-col">
                  DAR
                  <i class="fak fa-info ml-1 font-weight-normal" container="body" tooltip="Data Acceptance Rate = (Completes/(QCs+Completes)).
                  The percentage of the number of respondents that started the online survey and completed and did not get rejected because of quality
                  (Ex. A survey has 10 completes while also having 5 other completes that were reconciled (QC'd) will have a 67% Data Acceptance Rate)"
                  placement="top"></i>
                </td>
              }
            </tr>
          </thead>
          @if (data.length == 0) {
            <tbody>
              <tr>
                <td class="nav-font14 text-center">
                  No data available
                </td>
              </tr>
            </tbody>
          }
          @if (data.length > 0) {
            <tbody class="text-right bg-white nav-font14">
              <tr class="nav-font16 font-weight-bold grey5 text-right">
                <td class="text-left position-sticky bg-white" style="left:0">Overall</td>
                @if (!perfCardViewSettings.showPreSurveyBreakdown) {
                  <td class="purple first-col border-left">{{ showCellValue(summary.total, null) }}</td>
                }
                @if (perfCardViewSettings.showPreSurveyBreakdown) {
                  <td class="purple first-col">{{ showCellValue(summary.total, null) }}</td>
                  @if (summary.cid > 0) {
                    <td class="purple"> {{ showCellValue(summary.cid, summary.cid / (summary.total)) }} </td>
                  }
                  <td class="purple">{{ showCellValue(summary.dupe, summary.dupe / (summary.total)) }}</td>
                  <td class="purple">{{ showCellValue(summary.overquota, null) }}</td>
                  @if (summary.navTerminate > 0) {
                    <td class="purple">{{ showCellValue(summary.navTerminate, null) }}</td>
                  }
                  @if (summary.mobileBlock > 0) {
                    <td class="purple">{{ showCellValue(summary.mobileBlock, null) }}</td>
                  }
                  @if (summary.limitOverquota > 0) {
                    <td class="purple">{{ showCellValue(summary.limitOverquota, null) }}</td>
                  }
                }
                @if (!perfCardViewSettings.showInSurveyBreakdown) {
                  <td class="nav-bg-snow-white border-left">{{ showCellValue(summary.starts, null) }}</td>
                }
                @if (perfCardViewSettings.showInSurveyBreakdown) {
                  <td class="nav-bg-snow-white border-left">{{ showCellValue(summary.starts, null) }}</td>
                  <td class="nav-bg-snow-white">{{ showCellValue(summary.clientTerminate, null)  }}</td>
                  <td class="nav-bg-snow-white">{{ showCellValue(summary.clientOverquota, null) }}</td>
                  <td class="nav-bg-snow-white">{{ showCellValue(summary.autoQc, summary.autoQcRate) }} </td>
                  <td class="nav-bg-snow-white">{{ showCellValue(summary.clientQc, summary.qcRate) }} </td>
                }
                <td class="completes border-left">{{ showCellValue(summary.complete, null) }}</td>
                @if (!perfCardViewSettings.showKPIBreakdown) {
                  <td class="green first-col border-left">{{ summary.ir | percent }}</td>
                  <td class="green  last-col">{{ summary.loi | minuteSeconds }}</td>
                }
                @if (perfCardViewSettings.showKPIBreakdown) {
                  <td class="green first-col border-left">{{ summary.ir | percent }}</td>
                  <td class="green  last-col">{{ summary.loi | minuteSeconds }}</td>
                  <td class="green">{{ showCellValue(summary.dropoff, summary.dropoff /summary.starts) }}</td>
                  <td class="green">{{ showCellValue(null, summary.complete /summary.starts) }}</td>
                  <td class="green">{{ showCellValue(null, summary.complete / (summary.clientQc + summary.complete)) }}</td>
                }
              </tr>
            </tbody>
          }
        </table>
      </div>
      <div class="scrollbar" (scroll)="scrollTable.style.left = '-'+ $event.target.scrollLeft +'px'">
        <table class="table mb-0">
          <tbody class="text-right bg-white nav-font14">
            @for (row of tableData; track row; let idx = $index) {
              <tr class="table-light" [ngClass]="{'row-open row-open-first': row.open}">
                @if (this.auth.isInternal()) {
                  <td class="cursor-pointer text-left position-sticky bg-white" style="left:0" [ngClass]="{'bg-white': !row.open, 'nav-bg-snow-white': row.open}" (click)="row.open = !row.open">
                    <span class="nav-font14 nav-accent-blue font-weight-bold text-truncate d-block" container="body" placement="bottom" tooltip="{{ tableMode === 'projects' ? row.label : '' }}">
                      @if (!this.auth.isClient()) {
                        <i class="fas {{ !row.open ? 'fa-plus-circle grey4 fa-lg' : 'fa-minus-circle grey4 fa-lg' }} cursor-pointer mr-2"></i>
                      }
                      {{ tableMode === 'projects' ? shortenProjectName(row.label) : (tableMode === 'partners') ? row.label : row.label | date: 'MM/dd' }}
                    </span>
                  </td>
                }
                @if (!this.auth.isInternal()) {
                  <td class="nav-font14 nav-accent-blue font-weight-bold"> {{ row.label }}</td>
                }
                @if (!perfCardViewSettings.showPreSurveyBreakdown) {
                  <td class="purple border-left">{{ showCellValue(row.total, null) }}</td>
                }
                @if (perfCardViewSettings.showPreSurveyBreakdown) {
                  <td class="purple">{{ showCellValue(row.total, null) }}</td>
                  @if (summary.cid > 0) {
                    <td class="purple"> {{ showCellValue(row.cid , row.cid / row.total) }}</td>
                  }
                  <td class="purple"> {{ showCellValue(row.dupe , row.dupe / row.total) }}</td>
                  <td class="purple">{{ showCellValue(row.overquota, null) }}</td>
                  @if (summary.navTerminate > 0) {
                    <td class="purple">{{ showCellValue(row.navTerminate, null) }}</td>
                  }
                  @if (summary.mobileBlock > 0) {
                    <td class="purple">{{ showCellValue(row.mobileBlock, null) }}</td>
                  }
                  @if (summary.limitOverquota > 0) {
                    <td class="purple">{{ showCellValue(row.limitOverquota, null) }}</td>
                  }
                }
                @if (!perfCardViewSettings.showInSurveyBreakdown) {
                  <td class="nav-bg-snow-white border-left">{{ showCellValue(row.starts, null) }}</td>
                }
                @if (perfCardViewSettings.showInSurveyBreakdown) {
                  <td class="nav-bg-snow-white border-left">{{ showCellValue(row.starts, null) }}</td>
                  <td class="nav-bg-snow-white">{{ showCellValue(row.clientTerminate, null) }}</td>
                  <td class="nav-bg-snow-white">{{ showCellValue(row.clientOverquota, null) }}</td>
                  <td class="nav-bg-snow-white">{{ showCellValue(row.autoQc, row.autoQcRate) }} </td>
                  <td class="nav-bg-snow-white">{{ showCellValue(row.clientQc, row.qcRate) }} </td>
                }
                <td class="completes border-left">{{ showCellValue(row.complete, null) }}</td>
                @if (!perfCardViewSettings.showKPIBreakdown) {
                  <td class="green border-left">{{ row.ir | percent }}</td>
                  <td class="green">{{ row.loi | minuteSeconds }}</td>
                }
                @if (perfCardViewSettings.showKPIBreakdown) {
                  <td class="green border-left">{{ row.ir | percent }}</td>
                  <td class="green">{{ row.loi | minuteSeconds }}</td>
                  <td class="green">{{ showCellValue(row.dropoff, row.dropoff /row.starts) }}</td>
                  <td class="green">{{ showCellValue(null, row.complete /row.starts) }}</td>
                  <td class="green">{{ showCellValue(null, row.complete / (row.clientQc + row.complete)) }}</td>
                }
              </tr>
              @if (row.open) {
                @for (sub of row.sub; track sub; let idx2 = $index) {
                  <tr class="text-right row-open" [class.row-open-last]="row.sub?.length - 1 == idx2">
                    <td class="text-left nav-bg-snow-white position-sticky bg-white text-truncate" style="left:0">
                      <span class="nav-font14 grey4 font-weight-bold pl-4"  container="body" placement="bottom" tooltip="{{ tableMode === 'projects' ? '' : sub.label }}">
                        {{ tableMode === 'projects' ? sub.label : shortenProjectName(sub.label) }}
                      </span>
                    </td>
                    @if (!perfCardViewSettings.showPreSurveyBreakdown) {
                      <td class="purple border-left">{{ showCellValue(sub.total, null) }}</td>
                    }
                    @if (perfCardViewSettings.showPreSurveyBreakdown) {
                      <td class="purple">{{ showCellValue(sub.total, null) }}</td>
                      @if (summary.cid > 0) {
                        <td class="purple"> {{ showCellValue(sub.cid, sub.cid / sub.total) }}</td>
                      }
                      <td class="purple"> {{ showCellValue(sub.dupe, sub.dupe / sub.total) }}</td>
                      <td class="purple">{{ showCellValue(sub.overquota, null) }}</td>
                      @if (summary.navTerminate > 0) {
                        <td class="purple">{{ showCellValue(sub.navTerminate, null) }}</td>
                      }
                      @if (summary.mobileBlock > 0) {
                        <td class="purple">{{ showCellValue(sub.mobileBlock, null) }}</td>
                      }
                      @if (summary.limitOverquota > 0) {
                        <td class="purple">{{ showCellValue(sub.limitOverquota, null) }}</td>
                      }
                    }
                    @if (!perfCardViewSettings.showInSurveyBreakdown) {
                      <td class="nav-bg-snow-white border-left">{{ showCellValue(sub.starts, null) }}</td>
                    }
                    @if (perfCardViewSettings.showInSurveyBreakdown) {
                      <td class="nav-bg-snow-white border-left">{{ showCellValue(sub.starts, null) }}</td>
                      <td class="nav-bg-snow-white">{{ showCellValue(sub.clientTerminate, null) }}</td>
                      <td class="nav-bg-snow-white">{{ showCellValue(sub.clientOverquota, null) }}</td>
                      <td class="nav-bg-snow-white">{{ showCellValue(sub.autoQc, sub.autoQcRate) }} </td>
                      <td class="nav-bg-snow-white">{{ showCellValue(sub.clientQc, sub.qcRate) }} </td>
                    }
                    <td class="completes border-left">{{ showCellValue(sub.complete, null) }}</td>
                    @if (!perfCardViewSettings.showKPIBreakdown) {
                      <td class="green border-left">{{ sub.ir | percent }}</td>
                      <td class="green">{{ sub.loi | minuteSeconds }}</td>
                    }
                    @if (perfCardViewSettings.showKPIBreakdown) {
                      <td class="green border-left">{{ sub.ir | percent }}</td>
                      <td class="green">{{ sub.loi | minuteSeconds }}</td>
                      <td class="green">{{ showCellValue(sub.dropoff, sub.dropoff /sub.starts) }}</td>
                      <td class="green">{{ showCellValue(null, sub.complete /sub.starts) }}</td>
                      <td class="green">{{ showCellValue(null, sub.complete / (sub.clientQc + sub.complete)) }}</td>
                    }
                  </tr>
                }
              }
            }
          </tbody>
        </table>
      </div>
    </div>
  }
</div>
