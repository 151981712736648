<div class="card my-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="nav-secondary font-weight-bold">Project Folder</h3>
      </div>
      <div class="col text-right">
        <!-- <or-spinner-button
        label="Link"
        #spinnerLink
        customClass="btn-outline-light btn-sm opacity-90"
        showSpinnerOnCallback=true
        icon="fas fa-link"
        (callback)="openModal(modalAddLink, null)">
      </or-spinner-button> -->

      <button
        type="button"
        class="btn btn-outline-light btn-sm opacity-90 mr-2"
        (click)="openModal(modalAddLink, null)">
        <i class="fas fa-link mr-1"></i>
        <span>Link</span>
      </button>

      <or-spinner-button
        label="File"
        #spinnerFile
        customClass="btn-outline-light btn-sm opacity-90"
        showSpinnerOnCallback=true
        icon="fas fa-upload"
        (callback)="fileInput.click()">
      </or-spinner-button>
      <input #fileInput type="file" (change)="uploadFile($event)" onclick="this.value = null" style="display:none;"/>
    </div>
  </div>
</div>
<div class="card-body scrollbar overflow-y">
  <!-- Files -->
  @if (assets_Files.length > 0) {
    <div class="row">
      <ul class="navs-icon list-unstyled p-2 px-4">
        <li class="mb-2">
          <i class="fas fa-folder-open or-secondary opacity-90 cursor-auto"></i> Files
        </li>
        @for (link of assets_Files; track link) {
          <li class="pl-4 p-2 d-flex align-items-center">
            <small class="font-weight-normal ml-1 cursor-pointer mr-2" (click)="downloadAsset(link)">
              <i class="far fa-file fa-sm or-secondary opacity-90 cursor-auto"></i>
              {{link.fileName}}
            </small>
            <i class="fas fa-times text-danger" (click)="openModal(modalConfirm, link)"></i>
          </li>
        }
      </ul>
    </div>
  }
  <!-- Links -->
  @if (assets_Links.length > 0) {
    <div class="row">
      <ul class="navs-icon list-unstyled p-2 px-4">
        <li class="mb-2">
          <i class="fas fa-link or-secondary opacity-90 cursor-auto"></i> Links
        </li>
        @for (link of assets_Links; track link) {
          <li class="pl-4 p-2 d-flex align-items-center">
            <small class="font-weight-normal ml-1 cursor-pointer mr-2" (click)="openLink(link)">
              <i class="fa fa-link fa-sm or-secondary opacity-90 cursor-auto"></i>
              {{link.fileName}}
            </small>
            <i class="fas fa-times text-danger" (click)="openModal(modalConfirm, link)"></i>
          </li>
        }
      </ul>
    </div>
  }
  @if (assets.length === 0) {
    <div class="row">
      <div class="col or-secondary small">No documents available</div>
    </div>
  }
</div>
</div>

<ng-template #modalAddLink>
  <div class="modal-header">
    <p class="h5">Add Link</p>
  </div>
  <div class="modal-body">
    <form [formGroup]="addLinkForm" (ngSubmit)="addLink()" novalidate>
      <div class="row">
        <div class="col">
          <label for="name" class="required">Name</label>
          <input type="text" class="form-control" formControlName="name" [id]="'name'">
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="link" class="required">Link</label>
          <input type="text" class="form-control" formControlName="link" [id]="'link'">
        </div>
      </div>
      <div class="row">
        <div class="col text-right pt-3">
          <button type="button" class="btn btn-secondary mx-2" (click)="openModalRef.hide()">Cancel</button>
          <button class="btn nav-btn-accent text-white" type="submit">Save</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>
