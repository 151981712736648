import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, } from '@angular/forms';
import { UserService, AuthService, AlertifyService, DropdownService, CountriesService, PartnerContactService, RoleService, UtilsService, } from 'core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { v4 as uuidv4 } from 'uuid';
import { UnsavedchangesGuardService } from '../../_guards/unsavedchanges.guard.service';
import { concatMap, forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  providers: [UserService]
})
export class TeamComponent implements OnInit {

  instanceId = '';
  team: any = [];
  newRole: any = {};
  allTeam: any[] = [];
  numToLoad = 50;
  showLoadMore = false;
  isAdmin = false;
  isSuperAdmin = false;
  isVendorAdmin = false;
  filterColumns = ['name'];
  filterText = '';
  model: any;
  teamForm: FormGroup;
  role: string = null;
  submitted = false;
  titleRoles: any[] = [];
  teamRoles: any[] = [];
  selectedRoles: any[] = [];
  selectedTitleRoles: any[] = [];
  selectedTeamRoles: any[] = [];
  emailFocus = false;

  @ViewChild('modalTeam')
  modalRef: TemplateRef<any>;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  openModalRef: any;

  filter = {
    orderBy: '',
    orderDirection: ''
  };

  constructor(
    private userService: UserService,
    private alertify: AlertifyService,
    private auth: AuthService,
    private modalService: BsModalService,
    public utils: UtilsService,
    public roleService: RoleService,
    private unsavedChangesService: UnsavedchangesGuardService,
    private partnerContactService: PartnerContactService
  ) {
  };

  ngOnInit() {
    this.instanceId = this.auth.getUser().instanceId;
    this.isVendorAdmin = this.auth.isVendorAdmin();
    this.isAdmin = this.auth.isAdmin();
    this.isSuperAdmin = this.auth.getUser().superAdmin;
    this.getUsers(false);
    this.createTeamForm();
    this.addDropdownTitleRole();
    this.addDropdownTeamRole();
    this.roleService.GetInstanceRoles(this.instanceId).subscribe(data => {
      this.titleRoles = data.filter(x => x.type !== null && x.type === 'Title').map(x => ({ id: x.id, name: x.name }));
      this.teamRoles = data.filter(x => x.type !== null && x.type === 'Team').map(x => ({ id: x.id, name: x.name }));
    }, error => {
      this.alertify.error('Unable to get roles');
    });
  }

  onBlurEmail() {
    this.emailFocus = false;
    const email = this.teamForm.get('email');
    const trimmedEmail = email?.value.trim();  // Trim the value
    email?.setValue(trimmedEmail);

    const newemail = this.teamForm.get('newemail');
    const trimmednewemail = newemail?.value.trim();  // Trim the value
    newemail?.setValue(trimmednewemail);
  }
  showImpersonateButton() {
    //this button has been disabled for now
    return false;

    var email = this.teamForm.get('email').value;
    const domain = email.substring(email.lastIndexOf('@') + 1);
    if (email !== '' && domain === 'opinionroute.com') {
      return this.auth.getUser().superAdmin;
    } else {
      return false;
    }
  }
  getUsers(refresh) {
    this.userService.GetUsers(refresh).subscribe(data => {
      let team = data.map(item => {
        if (item.lastLogin != null && item.lastLogin.toString() != '0001-01-01T00:00:00') {
          const utcDate = new Date(item.lastLogin);
          const timezoneOffsetMs = utcDate.getTimezoneOffset() * 60000;
          item.lastLogin = new Date(utcDate.getTime() - timezoneOffsetMs);
        } else
          item.lastLogin = null;

        return item;
      });

      this.allTeam = team.sort((a, b) => { return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1 });
      this.updateList();
    },
      error => {
        this.alertify.error('Unable to get Team');
      });
  }

  applyRole(event, newRole) {
    if (event != null) {
      newRole.id = event.id;
      newRole.name = event.name;
    }
    this.instanceRolesValidator();
  }
  instanceRolesValidator() {

    if (this.teamForm && !this.isVendorAdmin) {
      if (this.selectedTitleRoles == null || this.selectedTitleRoles.length == 0 || this.selectedTitleRoles?.filter(x => x.id === null).length > 0)
        this.teamForm.get('instanceRoles').setErrors({ 'invalid': true });

      else {
        this.teamForm.get('instanceRoles').setErrors(null);
      }
    }

  }

  removeTitleRole(idx) {
    this.selectedTitleRoles.splice(idx, 1);
    if (this.selectedTitleRoles.length === 0) this.addDropdownTitleRole();
    this.instanceRolesValidator();
  }
  removeTeamRole(idx) {
    this.selectedTeamRoles.splice(idx, 1);
    if (this.selectedTeamRoles.length === 0) this.addDropdownTeamRole();
  }
  addDropdownTitleRole() {
    this.selectedTitleRoles.push({
      instanceId: this.instanceId,
      id: null,
      name: ''
    });
  }
  addDropdownTeamRole() {
    this.selectedTeamRoles.push({
      instanceId: this.instanceId,
      id: null,
      name: ''
    });
  }
  createRole(role, type) {
    var newRole = {
      instanceId: this.instanceId,
      name: role,
      type: type
    }

    this.roleService.AddRole(newRole).subscribe(data => {
      if (type === 'Title') {
        this.titleRoles.push({ id: data.id, name: data.name });
        this.titleRoles = [...this.titleRoles]
      }
      if (type === 'Team') {
        this.teamRoles.push({ id: data.id, name: data.name });
        this.teamRoles = [...this.teamRoles]
        // triggers change in child component
      }
    }, error => {
      this.alertify.error('Unable to add Role');
    });
  }

  teamEdit(member) {

    this.model = member;
    this.roleService.GetUserRolesForInstance(member.id).subscribe(roles => {
      this.selectedTitleRoles = [];
      this.selectedTeamRoles = [];
      if (roles && roles.length > 0) {
        roles.map((item) => {
          if (item.type === 'Title') this.selectedTitleRoles.push({ id: item.id, name: item.name });
          if (item.type === 'Team') this.selectedTeamRoles.push({ id: item.id, name: item.name });
        });
      }
      if (this.selectedTitleRoles.length === 0) {
        this.addDropdownTitleRole();
      }
      if (this.selectedTeamRoles.length === 0) {
        this.addDropdownTeamRole();
      }
    }, error => {
      this.alertify.error('Unable to get roles');
    }, () => {
      this.bindForm();
      this.openModal();
    });
  }

  createTeamForm() {

    this.teamForm = new FormGroup({
      id: new FormControl(null),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email]),
      newemail: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl(''),
      ableToImpersonate: new FormControl(false),
      profilePhoto: new FormControl(''),
      role: new FormControl('', [Validators.required]),
      instanceRoles: new FormArray([]),
      instanceId: new FormControl(this.instanceId)
    });

    this.role = 'Regular User';
    this.teamForm.controls['role'].setValue('Member');
  }

  teamAdd() {
    this.model = { callingCode: '001' };
    this.createTeamForm();
    this.openModal();
  }

  openModal() {
    this.unsavedChangesService.register(
      this.teamForm,
      (form) => form.dirty
    );
    this.openModalRef = this.modalService.show(this.modalRef, { class: 'nav-modal-style modal-md modal-team' });

    setTimeout(() => {
      this.instanceRolesValidator();
    }, 300);
  }

  closeModal() {
    this.unsavedChangesService.deregister([this.teamForm]);
    this.selectedRoles = [];
    this.selectedTitleRoles = [];
    this.selectedTeamRoles = [];
    this.addDropdownTitleRole();
    this.addDropdownTeamRole();
    this.openModalRef.hide()
  }

  bindForm() {
    if (this.model) {
      let role = 'Member';
      if (this.model.roles.indexOf('Admin') > -1) {
        role = "Admin";
      } else if (this.model.roles.includes('Client')) {
        role = 'Client';
      } else if (this.model.roles.includes('VendorAdmin')) {
        role = 'VendorAdmin';
      } else if (this.model.roles.includes('Vendor')) {
        role = 'Vendor';
      }
      this.teamForm = new FormGroup({
        id: new FormControl(this.model.id),
        ableToImpersonate: new FormControl(this.model.ableToImpersonate),
        firstName: new FormControl(this.model.firstName, [Validators.required]),
        lastName: new FormControl(this.model.lastName, [Validators.required]),
        email: new FormControl(this.model.email.trim()),
        newemail: new FormControl({ value: this.model.email.trim(), disabled: !(this.auth.isAdmin()) }),
        profilePhoto: new FormControl(this.model.image),
        role: new FormControl(role, [Validators.required]),
        phone: new FormControl(this.model.phone),
        instanceId: new FormControl(this.instanceId),
        instanceRoles: new FormControl([]),
      });
    }

    this.role = null;
    if (this.model.roles.includes('Member')) this.roleSelected('Member');
    if (this.model.roles.includes('Admin')) this.roleSelected('Admin');
    if (this.model.roles.includes('Vendor')) this.teamForm.controls['role'].setValue('Member');
    if (this.model.roles.includes('VendorAdmin')) this.teamForm.controls['role'].setValue('Admin');


  }

  uploadCallback(event) {
    this.model.image = event.file;
    this.teamForm.controls['profilePhoto'].setValue(event.file);
  }

  teamDelete(team) {
    this.model = team;
    var dayDifference = this.utils.getDialogItems('team');    
    if (dayDifference == -1) {     
      this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'nav-modal-style' });
    }else{      
      this.userService.DeleteUser(team.id).subscribe(data => {
        this.alertify.success('Team member deleted successfully');
        this.model = {};     
        this.getUsers(true);
      }, error => {
        this.alertify.error('Unable to delete team member');
      });
    }   
  }
 
  confirmDelete() {
    this.userService.DeleteUser(this.model.id).subscribe(data => {
      this.alertify.success('Team member deleted successfully');
      this.model = {};
      this.getUsers(true);
      this.closeModal();    
    }, error => {
      this.alertify.error('Unable to delete team member');
    });
  }

  refreshData() {
    this.model = {};
    this.selectedRoles = [];
    this.selectedTitleRoles = [];
    this.selectedTeamRoles = [];
    this.addDropdownTitleRole();
    this.addDropdownTeamRole();
    this.getUsers(true);
    this.closeModal();
  }

  teamUserSave() {

    if (this.selectedTitleRoles.length > 0) {
      this.selectedTitleRoles.forEach(item => {
        if (item.id !== null) {
          this.teamForm.value.instanceRoles.push(item.id);
        }
      });
    }
    if (this.selectedTeamRoles.length > 0) {
      this.selectedTeamRoles.forEach(item => {
        if (item.id !== null) {
          this.teamForm.value.instanceRoles.push(item.id);
        }
      });
    }

    if (!this.teamForm.value.id) {
      this.userService.Register(this.teamForm.value).subscribe(data => {
        this.model = data;
        this.refreshData();
      }, error => {
        this.alertify.error(error);
      }, () => {
        this.alertify.success('User registered successfully');
      });
    } else {
      this.teamForm.value.email = this.model.email;


      this.userService.EditUser(this.teamForm.value).subscribe(data => {
        this.model = data;
        this.refreshData();
      }, error => {
        if (error == 'EMAIL_EXISTS') {
          this.alertify.error('User already exists please try again');
        }
        else {
          this.alertify.error('Unable to update team member');
        }
      }, () => {
        this.alertify.success('User updated successfully');
      });
    }
  }

  teamVendorUserSave() {
    if (this.isVendorAdmin && this.teamForm.value.role == "Admin") {
      this.teamForm.value.role = "VendorAdmin";
    } else if (this.isVendorAdmin && this.teamForm.value.role == "Member") {
      this.teamForm.value.role = "Vendor";
    }
    if (!this.teamForm.value.id) {
      this.teamForm.value.id = uuidv4();
      this.partnerContactService.AddPartnerContact(this.teamForm.value)
        .pipe(
          concatMap((addedMember) => {
            if (this.selectedRoles.length === 0) {
              return of(null);
            };
            const roleObservables = this.selectedRoles.filter(x => x.id !== null).map((item) => {
              const roleUser = { instanceRoleId: item.id, userId: addedMember.id };
              return this.roleService.AddUser(roleUser);
            });

            if (roleObservables.length === 0) {
              return of(null);
            };

            return forkJoin(roleObservables);
          })
        )
        .subscribe({
          next: (data) => {
            this.alertify.success('Team member added successfully');
          },
          error: (e) => {
            this.alertify.error('Unable to add team member');
          },
          complete: () => {
            this.refreshData();
          }
        });
    }
    else {
      this.partnerContactService.EditPartnerContact(this.teamForm.value)
        .pipe(
          concatMap((firstResult) => {
            return this.roleService.RemoveUserRoles(firstResult.id) // using concatMap to execute calls in order.
              .pipe(
                concatMap(() => {
                  return of(firstResult);
                })
              );
          }),
          concatMap((updatedMember) => {

            if (this.selectedRoles.length === 0) {
              return of(null);
            };

            const roleObservables = this.selectedRoles.filter(x => x.id !== null).map((item) => {
              const roleUser = { instanceRoleId: item.id, userId: updatedMember.id };
              return this.roleService.AddUser(roleUser);
            });

            if (roleObservables.length === 0) {
              return of(null);
            };

            return forkJoin(roleObservables);
          })
        )
        .subscribe({
          next: (data) => {
            this.alertify.success('Team member updated successfully');
          },
          error: (e) => {
            this.alertify.error('Unable to update team member');
          },
          complete: () => {
            this.refreshData();
          }
        });
    }
  }

  teamSave() {
    this.submitted = true;
    if (!this.teamForm.valid) {
      return;
    }
    if(this.teamForm.value.email == null || this.teamForm.value.email == '')
      this.teamForm.value.email = this.teamForm.value.newemail;

    if (this.isVendorAdmin) {
      this.teamVendorUserSave();
    } else {
      this.teamUserSave();
    }
    this.getUsers(true);
  }

  showMore() {
    this.numToLoad += 10000;
    this.updateList();
  }

  updateList() {
    const filter = this.filterText.toLowerCase();
    if (filter === '') {
      this.team = this.allTeam;
    }
    else {
      this.team = this.allTeam.filter(e => e.name.toLowerCase().indexOf(filter) >= 0);
    }
    this.showLoadMore = (this.team.length > this.numToLoad);
    this.team = this.team.slice(0, this.numToLoad);
  }

  SortBy(field) {

    if (field == this.filter.orderBy) {
      this.filter.orderDirection = (this.filter.orderDirection == 'ASC') ? 'DESC' : 'ASC'
    } else {
      this.filter.orderBy = field;
      this.filter.orderDirection = 'ASC';
    }
    this.sort(this.team, this.filter.orderBy, this.filter.orderDirection);
  }

  sort(array, type, direction) {
    array.sort((a, b) => {
      var A;
      var B;

      if (type == 'name') {
        A = a.name;
        B = b.name;
      }
      else if (type == 'email') {
        A = a.email;
        B = b.email;
      }
      else if (type == 'companyTitle') {
        A = a.companyTitle;
        B = b.companyTitle;
      }
      else if (type == 'team') {
        A = a.team;
        B = b.team;
      }
      let comparison = 0;
      if (direction === 'ASC') {
        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        return comparison;
      }
      else {
        if (A < B) {
          comparison = 1;
        } else if (A > B) {
          comparison = -1;
        }
        return comparison;
      }

    });
    return array;
  }

  roleSelected(newRole) {
    if (newRole != null) {
      this.teamForm.controls['role'].setValue(newRole);
      this.role = newRole == 'Member' ? 'Regular User' : newRole;
      // set in form also
    }
  }

}

