import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertifyService, SurveyTesterProject, SurveyTesterService, SurveyTesterUser } from 'core';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-project-homepage-survey-testing',
  templateUrl: './project-homepage-survey-testing.component.html',
  styleUrls: ['./project-homepage-survey-testing.component.scss']
})
export class ProjectHomepageSurveyTestingComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.getProjectInfo();
  }

  @Output() callback = new EventEmitter();

  _project:any = {};
  summary = { totalPending: 0, totalOpened: 0, totalResolved: 0, totalClosed: 0, hasNewReplies: false };
  surveyId = '';
  surveyUrl = '';
  surveyUrlDecode = '';
  selectedPlatform = '';
  suveyProject = {} as SurveyTesterProject;

  constructor(private alertify: AlertifyService, private service: SurveyTesterService) { }

  ngOnInit() {

  }

  getProjectInfo() {
    let url = this._project.projectSegments[0]?.testSurveyLink || '';
    this.selectedPlatform = this._project.projectSegments[0]?.surveyPlatForm;
    this.surveyUrlDecode = url;
    this.surveyUrl = encodeURIComponent(url);
    this.suveyProject = this.service.getSurveyProject(url, this.selectedPlatform);

    this.service.getSurveyId(this.suveyProject).subscribe((data) => {
      this.surveyId = data.id;
      this.getComments();
    }, error => {
      this.alertify.error('There was an error getting survey tester id');
    });
  }

  getComments() {
    this.service.getProjectHomepageComments(this.surveyId).subscribe((data) => {
      let comments = data;
      const totalOpen = comments.filter(comment => comment.status === 'open')?.length;
      const totalPending = comments.filter(comment => comment.status === 'pending')?.length;
      const totalClosed = comments.filter(comment => comment.status === 'approved' || comment.status === 'cancelled')?.length;

      this.summary.totalPending = (totalOpen + totalPending);
      this.summary.totalOpened = comments.length;
      this.summary.totalResolved = (totalPending + totalClosed);
      this.summary.totalClosed = totalClosed;

      const totalNewReplies = comments.reduce((count, comment) => count + (comment.replies?.filter(reply => reply.isNew).length || 0), 0);
      this.summary.hasNewReplies = totalNewReplies > 0;


    }, error => {
      this.alertify.error('There was an error getting project info');
    });
  }

  openProgramming() {
    this.callback.emit({section: 'programming', type: null});
  }

}
