import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'core';

@Component({
  selector: 'app-project-homepage-field-management',
  templateUrl: './project-homepage-field-management.component.html',
  styleUrls: ['./project-homepage-field-management.component.scss']
})
export class ProjectHomepageFieldManagementComponent implements OnInit {

  @Input() public set data(data) {
    this._project = data?.project;
    this.calculateFieldDates();
    this.processData(data?.respondents);
  }

  @Output() callback = new EventEmitter();

  _data: any;
  _project:any = {};
  daysPercentagePast = 0;
  fullLaunchQuota = 0;
  complete: number;
  actualIR = 0;
  medianLoi = 0;
  qcRate = 0;
  loading = true;
  vendorViewId: string = '';
  clientViewId: string = '';

  constructor(public auth: AuthService) { }

  ngOnInit() {

  }

  processData(data) {
    let complete = 0;
    let clientTerminate = 0;
    let dropoff = 0;
    let starts = 0;
    let clientQc = 0;
    const verdorList = [];
    const handledpartners = {};
    let lois = [];
    data.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    let sDate;
    let eDate;

    data.forEach(e => {
      if (e.partner != null) {
        if (e.completeLois != null) {
          lois = lois.concat(e.completeLois.map(f => f.loi));
        }
        // if (this.isClientview) {
        //   e.partnerId = this.auth.getInstance();
        //   e.partner = 'OpinionRoute';
        // }
        if (e.complete > 0) {
          eDate = e.date;
          if (sDate == null) { sDate = e.date; }
        }
        starts += e.starts;
        complete += e.complete;
        clientTerminate += e.clientTerminate;
        clientQc += e.clientQc;
        dropoff += e.dropoff;
        if (!handledpartners[e.partnerId]) {
          handledpartners[e.partnerId] = true;
          const partnerName = e.partner.length <= 30 ? e.partner : e.partner.slice(0, 30);
          verdorList.push(partnerName);
        }
      }
    });

    this.complete = complete;
    //this.partners = verdorList;
    this.actualIR = complete === 0 ? 0 : (complete) / (complete + clientTerminate);
    this.qcRate = clientQc === 0 ? 0 : ((clientQc) / (complete + clientQc));

    const LOIs = lois.sort((a, b) => a - b);
    if (LOIs.length === 0) {
      this.medianLoi = 0;
    } else {
      const mid = Math.floor(LOIs.length / 2);
      const loi = LOIs.length % 2 !== 0 ? LOIs[mid] : (LOIs[mid - 1] + LOIs[mid]) / 2;
      this.medianLoi = Math.round(loi / 1000);
    }

    this._data = data;
    this.loading = false;
  }

  calculateFieldDates() {
    const daysLeft = (this._project.totalDaysLeft < 0) ? 0 : this._project.totalDaysLeft;
    const totalDays = this._project.totalDays;
    this.daysPercentagePast = 100 - Math.round(daysLeft * 100 / totalDays);
    if (this.daysPercentagePast < 0) this.daysPercentagePast = 0;

    if (this.auth.getUser().roles.indexOf("Vendor") >= 0) {
      //Vendor user - we have only returned the one vendor from server so we can reference this one
      this.fullLaunchQuota = this._project.projectSegments[0]?.projectSurveyPartners[0]?.partnerFullQuota;
    }
    else if (this.vendorViewId != '') {
      this.fullLaunchQuota = this._project.projectSegments[0].projectSurveyPartners.find(e => e.partnerId == this.vendorViewId)?.partnerFullQuota;
    }
    else {
      this.fullLaunchQuota = this._project.fullLaunchQuota;
    }
  }

  get ProgressStyle(): string {
    if (this._project.projectStatus === 'Paused' || this._project.projectStatus === 'Closed' || this._project.projectStatus === 'Invoiced') {
      return ' or-darkgray-bg';
    }
    else if (this.daysPercentagePast > (this.complete * 100 / this.fullLaunchQuota)) {
      return 'nav-bg-error';
    }
    return '';
  }

  openSettings(type) {
    this.callback.emit({section: 'card', type: null});
  }

}
