<div class="card">
  <div class="card-header border-bottom">
    <app-widget-title [widget]="widget" (remove)="remove()" (update)="updateTitle($event)"></app-widget-title>
  </div>
  <div class="card-body">
    <!-- Load report if one was selected -->
    @if (selectedReport !== null) {
      @if (loadingData) {
        <div class="loading"></div>
        <div>Please wait while the report is loading</div>
      }
      @if (!loadingData) {
        <div class="scrollbar overflow-y">
          <button type="button" class="btn btn-xs btn-secondary text-white mx-1 float-right" (click)="exportReport()">Export Report</button>
          <div class="table-responsive">
            <or-list [items]="tableData | filter : filterText : reportTableFilterColumns" [enableExport]=true [layout]="reportTableColumns"></or-list>
          </div>
        </div>
      }
    }

    <!-- List of reports if one wasnt selected -->
    @if (savedReports.length > 0 && selectedReport === null) {
      <div class="scrollbar overflow-y">
        <h5> Select a Report to load into this Widget</h5>
        <div class="gridtemplate">
          @for (item of savedReports; track item) {
            <div class="btn btn-sm btn-light d-inline-flex shadow" (click)="selectReport(item)">
              <div class="text-center p-2 m-2"><h6>{{  item.reportName }} </h6></div>
            </div>
          }
        </div>
      </div>
    }
    <!-- No Data Found -->
    @if (savedReports.length <= 0 && selectedReport === null) {
      <div>No data found for {{widget.title}}!</div>
      <div
        class="create-report-button"
        (click)="linkToBuildReportPage()">
        Configure Report
      </div>
    }
  </div>
</div>
