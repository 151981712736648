import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertifyService, PartnerContactService, PartnerService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CleanidService } from 'core';
import { v4 as uuidv4 } from 'uuid';
import { of, timeout } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-project-data-quality-report',
  templateUrl: './project-data-quality-report.component.html',
  styleUrls: ['./project-data-quality-report.component.scss']
})
export class ProjectDataQualityReportComponent implements OnInit {

  @Input() project;
  @Input() bidNumber;
  @Output() reportIsLoading = new EventEmitter<boolean>();
  @Output() reportClosed = new EventEmitter();

  @ViewChild('modal') modalTemplate;
  openModalRef: BsModalRef;
  reportSvg = null;
  safeReportSvg = null;
  downloadUrl = null;
  
  base64Font = null;

  constructor(
    public bsModalRef: BsModalRef,
    public cleanidService: CleanidService,
    public modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private alertify: AlertifyService,
    private http: HttpClient) { }

   ngOnInit() { 
    this.reportIsLoading.emit(true);
    this.alertify.success("Report is loading")

    this.http.get('assets/Lato-Regular-Base64.txt', { responseType: 'text' }).subscribe(
      (data: string) => {
        this.base64Font = data;
      },
    );
    this.getReport();
  }

  async getReport(): Promise<void> {
    try {
      if (this.project) {
        this.reportSvg = await this.cleanidService.GetDataQualityReport(this.project.id)
        this.safeReportSvg = this.sanitizer.bypassSecurityTrustHtml(this.reportSvg);
      }
      else if (this.bidNumber) {
        this.reportSvg = await this.cleanidService.GetDataQualityReportByBidNumber(this.bidNumber)
        this.safeReportSvg = this.sanitizer.bypassSecurityTrustHtml(this.reportSvg);
      }
      this.openModalRef = this.modalService.show(this.modalTemplate, { ignoreBackdropClick: true, keyboard: false, class: 'modal-lg modal-preview-quality-report' });
      
      this.reportIsLoading.emit(false);
    } catch (error) {
      this.alertify.error("There was an error when loading the Report")
      this.reportIsLoading.emit(false);
      this.reportClosed.emit();
    }
  }

  closeReport() {


    setTimeout(() => {
      this.reportClosed.emit();
    }, 300);
    this.openModalRef.hide();
  }

  downloadReportAsPng() {
    const img = new Image();
    if (this.base64Font?.length > 0) {
      this.reportSvg = this.embedFontInSvg();
    }
    const blob = new Blob([this.reportSvg], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(blob);
  
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
        const svgElement = new DOMParser().parseFromString(this.reportSvg, "image/svg+xml").documentElement;
      const width = parseFloat(svgElement.getAttribute('width') || '500');
      const height = parseFloat(svgElement.getAttribute('height') || '500');

      // Set scaling factor for higher resolution (e.g., 2x)
      const scale = 4;
      canvas.width = width * scale;
      canvas.height = height * scale;
      context?.scale(scale, scale);

      context?.drawImage(img, 0, 0, width, height);
  
      canvas.toBlob(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob!);
        var code = this?.project?.projectCode || this?.bidNumber;
        link.download = `${code}-DataQualityReport.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        URL.revokeObjectURL(url);
      }, 'image/png');
    };
  
    img.src = url;
  }


  downloadReportAsSvg() {
    const blob = new Blob([this.reportSvg], { type: 'image/svg+xml;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    var code = this?.project?.projectCode || this?.bidNumber;
    link.download = `${code}-DataQualityReport.svg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  embedFontInSvg() {
    // Define the @font-face rule with the Base64-encoded font
    const fontFace = `
    @font-face {
        font-family: "Lato";
        font-style: normal;
        font-weight: 400;
        src: url('data:font/ttf;base64,${this.base64Font}') format('truetype');
    }`;
    
    // Inject the font-face rule into the <defs> or <style> section of the SVG
    // Ensure there is a <style> tag inside <defs> in the SVG (if not, add one)
    const styleTag = `<style type="text/css">${fontFace}</style>`;
    
    // Find the position of the <defs> or <style> in the SVG
    if (this.reportSvg.includes('<defs>')) {
        // If <defs> exists, inject the style within it
        this.reportSvg = this.reportSvg.replace('<defs>', `<defs>${styleTag}`);
    } else {
        // If no <defs> exists, create one and add the style inside
        this.reportSvg = this.reportSvg.replace('<svg', `<svg><defs>${styleTag}</defs>`);
    }

    return this.reportSvg;
  }
}


