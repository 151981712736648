<h3 class="font-weight-semi-bold nav-font18 grey5 px-4">
  <i class="fas fa-file-arrow-down grey4 mr-2 font-weight-normal"></i>Downloads
</h3>

<div class="mt-4 downloads scrollbar overflow-y px-3">

  <div class="item my-2 p-2">
    <div class="d-flex align-items-center justify-content-center pb-2">
      <h5 class="grey5 font-weight-bold mb-0">Full Data Export</h5>
      <i class="fak fa-download fa-md ml-auto cursor-pointer" tooltip="Download" container="body" (click)="download('full')"></i>
    </div>
    <div class="nav-black2 nav-font14">This export will provide all fields available (General, Device, CleanID, ResponseID, Quotas, Passthrough Variables)</div>
  </div>



  <!-- <span class="grey4 nav-font14">Participants</span>
  <div class="item my-2 p-2">
    <div class="d-flex align-items-center justify-content-center pb-2">
      <h5 class="grey5 font-weight-bold mb-0">Detail Report</h5>
      <i class="fak fa-download fa-md ml-auto cursor-pointer" tooltip="Download" container="body" (click)="download('participant')"></i>
    </div>
    <div class="nav-black2 nav-font14">This report will provide participant details in Excel</div>
  </div>

  <span class="grey4 nav-font14 mt-4 d-block">Reports</span>
  <div class="item my-2 p-2">
    <div class="d-flex align-items-center justify-content-center pb-2">
      <h5 class="grey5 font-weight-bold mb-0">Duplicate Report</h5>
      <i class="fak fa-download fa-md ml-auto cursor-pointer" tooltip="Download" container="body" (click)="download('duplicate')"></i>
    </div>
    <div class="nav-black2 nav-font14">This report provides duplicate participant details in Excel</div>
  </div>
  <div class="item my-2 p-2">
    <div class="d-flex align-items-center justify-content-center pb-2">
      <h5 class="grey5 font-weight-bold mb-0">CleanID Report</h5>
      <i class="fak fa-download fa-md ml-auto cursor-pointer" tooltip="Download" container="body" (click)="download('cleanId')"></i>
    </div>
    <div class="nav-black2 nav-font14">This report provides the full CleanID data in Excel</div>
  </div>
  <div class="item my-2 p-2" *ngIf="bidNumber == ''">
    <div class="d-flex align-items-center justify-content-center pb-2">
      <h5 class="grey5 font-weight-bold mb-0">ResponseID Report</h5>
      <i class="fak fa-download fa-md ml-auto cursor-pointer" tooltip="Download Summary" container="body" (click)="download('responseId')"></i>
    </div>
    <div class="nav-black2 nav-font14">Respondent and question level scoring. Raw data also available here
      <i class="fak fa-download fa-md ml-3 cursor-pointer" tooltip="Raw Data" container="body" (click)="download('responseIdRaw')"></i>
    </div>
  </div>

  <ng-container *ngIf="bidNumber == ''">
    <div class="item my-2 p-2" *ngIf="project?.projectStatus == 'Closed'">
      <div class="d-flex align-items-center justify-content-center pb-2">
        <h5 class="grey5 font-weight-bold mb-0">Closure Report</h5>
        <i class="fak fa-download fa-md ml-auto cursor-pointer" tooltip="Download" container="body" (click)="download('closureExport')"></i>
      </div>
      <div class="nav-black2 nav-font14">Download participants for all projects in this closure process</div>
    </div>
  </ng-container> -->

  <div class="d-flex align-items-center justify-content-center mt-4">
    <span class="grey4 nav-font14">Vendors</span>
    <span class="nav-accent-blue nav-font12 cursor-pointer ml-auto" (click)="downloadPartnerZip()">Download All</span>
  </div>
  @for (partner of partnersList; track partner) {
    <div class="item my-2 p-2" >
      <div class="d-flex align-items-center justify-content-center pb-2">
        <h5 class="grey5 font-weight-bold mb-0">{{ partner.partnerName }}</h5>
        <i class="fak fa-download fa-md ml-auto cursor-pointer" tooltip="Download" container="body" (click)="downloadByPartner(partner)"></i>
      </div>
      <div class="nav-black2 nav-font14">This report provides participant details from this vendor Excel</div>
    </div>
  }
</div>
