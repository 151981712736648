<div class="card">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-survey fa-lg mr-2 grey4 font-weight-normal"></i>Survey Testing</span>
    <i class="fas fa-arrows-maximize fa-md grey4 font-weight-normal float-right cursor-pointer" (click)="openSettings('dates')"></i>
  </div>
  <div class="card-body nav-font14 pt-2">
    <div class="row grey4 pb-3">
      <div class="col">
        <div class="d-inline-block position-relative">
          <i class="fak fa-sm fa-messages grey4"></i>
          @if(summary.totalOpen > 0) { <span class="count"></span> }
        </div>
        <span class="ml-2 nav-accent-blue nav-font14">Summary</span>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col nav-font14 grey5">
        <div class="row">
          <div class="col-5 font-weight-bold">Open/pending</div>
          <div class="col nav-font14">{{summary.totalOpen}}</div>
        </div>
        <div class="row mt-2">
          <div class="col-5 font-weight-bold">Opened</div>
          <div class="col nav-font14">{{summary.totalOpen}}</div>
        </div>
        <div class="row mt-2">
          <div class="col-5 font-weight-bold">Resolved</div>
          <div class="col nav-font14">{{summary.totalResolved}}</div>
        </div>
        <div class="row mt-2">
          <div class="col-5 font-weight-bold">Closed</div>
          <div class="col nav-font14">{{summary.totalClosed}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
