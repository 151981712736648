import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, Renderer2, TemplateRef, ViewChild,  } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DndDropEvent } from 'ngx-drag-drop';

@Component({
  selector: 'or-rid-question-mapping',
  templateUrl: './rid-question-mapping.component.html',
  styleUrls: ['./rid-question-mapping.component.scss']
})

export class RIDQuestionMappingComponent implements OnInit {

  fileValues = null;
  ridValues = null;
  fileValueDetails = [];
  moreThan3RID = false;
  mapping = {};
  valueBeingDragged = null;
  totalMappings = 0;
  saving = false;

  @Output() saveevent = new EventEmitter();
  @Output() cancelevent = new EventEmitter();

  constructor(private modalRef: BsModalRef) { }

  ngOnInit() {
    this.fileValues.sort();
    this.ridValues.sort();
    
    if (this.ridValues.length > 3) this.moreThan3RID = true;

    this.fileValues.forEach(value => {
      this.fileValueDetails.push({value: value, hasMapping: false});
    });

    this.ridValues.forEach(rid => {
        this.mapping[rid] = null;
    });
  }

  saveMapping() {
    if (this.saving) return;

    this.saving = true;
    this.saveevent.emit(this.mapping);
  }

  cancelMapping() {
    this.cancelevent.emit(true);
  }

  onDrop(event, ridValue) {
    var oeValue = event?.data?.value;
    if (!oeValue) oeValue = this.valueBeingDragged;

    if (oeValue) {
      if (ridValue == '') {
        this.fileValueDetails.find(x => x.value == oeValue).hasMapping = false;
      }
      else {
          // if different mapping exists for same ridValue
          if (this.mapping[ridValue] != null && this.mapping[ridValue] != oeValue) {
            this.fileValueDetails.find(x => x.value == this.mapping[ridValue]).hasMapping = false;
          }
          this.mapping[ridValue] = oeValue;
          this.fileValueDetails.find(x => x.value == oeValue).hasMapping = true; 
      }

      for (let ridV in this.mapping) { // remove mapping if exists for different ridValue
        if (this.mapping[ridV] == oeValue && ridValue != ridV) { // if same mapping exists for different ridValue
          this.mapping[ridV] = null;
        }        
      }
    }
    this.totalMappings = this.fileValueDetails.filter(x => x.hasMapping).length;
    this.valueBeingDragged = null;
  }

  onDragStart(event) {
    this.valueBeingDragged = event.target.innerText;
  }
}
