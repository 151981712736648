import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-homepage-sample',
  templateUrl: './project-homepage-sample.component.html',
  styleUrls: ['./project-homepage-sample.component.scss']
})
export class ProjectHomepageSampleComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
  }

  @Output() callback = new EventEmitter();

  _project:any = {};

  constructor(public router: Router) { }

  ngOnInit() {
  }

  openSettings(type) {
    this.callback.emit({section: 'settings', type: type});
  }

}
