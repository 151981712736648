<div class="card my-2 ">
  <div class="card-body">
      <strong>TEAM</strong>
      <div>
        <img src="https://uaelementor.com/wp-content/uploads/2019/08/uae-team-member-example-768x357.png">
      </div>

      <div class="col-md-6 col-sm-12">
        <div>PM</div>
        <div> <or-profile-image [name]="project.projectManager" size="small"></or-profile-image>   {{ project.projectManager }} </div>
      </div>
  </div>
</div>