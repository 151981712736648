import { Component, OnInit, Input, Output, OnDestroy, EventEmitter, Renderer2, TemplateRef, ViewChild,  } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-qc-flow-column-filter',
  templateUrl: './qc-flow-column-filter.component.html',
  styleUrls: ['./qc-flow-column-filter.component.scss']
})

export class QCFlowColumnFilterComponent implements OnInit {

  @ViewChild('dropdownFilter', {static: false}) dropdownFilter;

  @Input() type: string;
  @Input() filters: Array<string>
  @Output() filterChange = new EventEmitter();
  options = [];

  qcStatusOptions = [{label: 'Accept', value: 'Accept', selected: false, icon: 'fak fa-message-success nav-success pr-2'},
    {label: 'Pending', value: 'Pending', selected: false, icon: 'fak fa-clock grey4 nav-font14 pr-2'},
    {label: 'Reject', value: 'Reject', selected: false, icon: 'fak fa-message-error nav-font14 nav-error pr-2'}]

  myAssessmentOptions = [{label: 'Good', value: 'low', selected: false, icon: 'fal fa-square-check nav-success margin-left-1 nav-font14 pr-2'},
    {label: 'Warn', value: 'medium', selected: false, icon: 'fal fa-triangle-exclamation mediumRiskColour nav-font14 pr-2'},
    {label: 'Bad', value: 'high', selected: false, icon: 'fal fa-octagon-xmark nav-error nav-font14 pr-2'}]

  aiAdviceOptions = [
    {label: 'AI Advice', type:'header'},
    {label: 'Good', value: 'low', selected: false, icon: 'fal fa-square-check nav-success margin-left-1 nav-font14 pr-2'},
    {label: 'Warn', value: 'medium', selected: false, icon: 'fal fa-triangle-exclamation mediumRiskColour nav-font14 pr-2'},
    {label: 'Bad', value: 'high', selected: false, icon: 'fal fa-octagon-xmark nav-error nav-font14 pr-2'},
    {label: 'Content', type:'header'},
    {label: 'Good', value: 'low-content', selected: false, icon: 'fal fa-square-check nav-success margin-left-1 nav-font14 pr-2'},
    {label: 'Warn', value: 'medium-content', selected: false, icon: 'fal fa-triangle-exclamation mediumRiskColour nav-font14 pr-2'},
    {label: 'Bad', value: 'high-content', selected: false, icon: 'fal fa-octagon-xmark nav-error nav-font14 pr-2'},
    {label: 'Context', type:'header'},
    {label: 'Good', value: 'low-context', selected: false, icon: 'fal fa-square-check nav-success margin-left-1 nav-font14 pr-2'},
    {label: 'Warn', value: 'medium-context', selected: false, icon: 'fal fa-triangle-exclamation mediumRiskColour nav-font14 pr-2'},
    {label: 'Bad', value: 'high-context', selected: false, icon: 'fal fa-octagon-xmark nav-error nav-font14 pr-2'},
    {label: 'Vulgarity', type:'header'},
    {label: 'Good', value: 'low-vulgarity', selected: false, icon: 'fal fa-square-check nav-success margin-left-1 nav-font14 pr-2'},
    {label: 'Warn', value: 'medium-vulgarity', selected: false, icon: 'fal fa-triangle-exclamation mediumRiskColour nav-font14 pr-2'},
    {label: 'Bad', value: 'high-vulgarity', selected: false, icon: 'fal fa-octagon-xmark nav-error nav-font14 pr-2'}]

  ridScoreOptions = [{label: 'Low risk', value: 'low', selected: false, icon: 'fal fa-square-check nav-success margin-left-1 nav-font14 pr-2'},
    {label: 'Medium risk', value: 'medium', selected: false, icon: 'fal fa-triangle-exclamation mediumRiskColour nav-font14 pr-2'},
    {label: 'High risk', value: 'high', selected: false, icon: 'fal fa-octagon-xmark nav-error nav-font14 pr-2'}]

  openEndOptions = [{label: 'Blanks', value: 'blank'}, {label: 'Non-Answers', value: 'non answer'}, {label: 'Dupe across questions', value: 'dupeAnswer'}, {label: 'Dupe across respondents', value: 'dupeRespondent'}]

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
    if (this.type === 'qc-status') this.options = this.qcStatusOptions;
    else if (this.type === 'ai-advice') this.options = this.aiAdviceOptions;
    else if (this.type === 'rid-score') this.options = this.ridScoreOptions;
    else if (this.type === 'my-assessment') this.options = this.myAssessmentOptions;
    else if (this.type === 'open-end') this.options = this.openEndOptions;

    this.options.forEach(element => {
      if (this.filters.includes(element.value)) element.selected = true;
    });
  }

  resetFilters() {
    this.options.forEach(status => {
      status.selected = false;
    });
    this.applyFilter()
  }

  applyFilter() {
    var filters = []
    this.options.forEach(status => {
      if (status.selected) filters.push(status.value)
    });
    this.filters = filters;
    setTimeout(() => {
      this.filterChange.emit(filters)
    }, 5);
  }

}
