import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { SettingsService, AlertifyService, AuthService, RoleService} from 'core';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import { ktdTrackById, KtdGridLayout } from '@katoid/angular-grid-layout';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WidgetConfigComponent } from './widget-config/widget-config.component';
import { DashboardSetupComponent } from './dashboard-setup/dashboard-setup.component';
import { forkJoin } from 'rxjs';
declare var google: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardSetupComponent, WidgetConfigComponent]
})
export class DashboardComponent implements OnInit {

  homepage: boolean;
  now = new Date();
  greeting = "Welcome"
  mode = '';
  dashboards = [];
  layout;
  currentDashboard;
  editDashName:string;
  bsModalRef: BsModalRef;
  editSection = {};

  @ViewChild('modalConfirm')
  modalConfirmRef;
  modalRefConfirm;

  //Grid settings
  cols: number = 100;
  rowHeight: number = 10;
  trackById = ktdTrackById

  homeBannerInfo;
  homeBannerOffset = 'week';
  homeBannerDaysOffset = 7;
  showProject=true;
  showJobs=true;
  showpriorities=true;
  showUpCommingProjects=true;
  showScore=true;
  selectedUsers = [];
  showBanner = true;

  constructor(
    public http: HttpClient,
    private alertify: AlertifyService,
    public dashboardService: DashboardService,
    public auth: AuthService,
    public roleService: RoleService,
    public router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    public settingService: SettingsService) {

      this.route.params.subscribe((params) => {
        this.mode = params.mode ?? '';
        if(this.mode!='manager')
        {
          localStorage.removeItem('dashboard');
        }
      });

    }


  ngOnInit() {
    this.homepage = this.router.url.indexOf('/home') >= 0;
    var curHr = this.now.getHours()
    if (curHr < 12) this.greeting = 'Good Morning';
    else if (curHr < 18) this.greeting = 'Good Afternoon';
    else this.greeting = 'Good Evening';

    this.greeting = this.greeting + ' ' + this.auth.getUser().firstName;

    if (this.homepage && this.mode == 'manager') {
      var selectedUsers = [];
      var userIds = localStorage.getItem('dashboard');
      console.log('userIds', userIds);
      if (userIds != null && userIds != '') {
        let localusers = userIds.split(',').map(item => item.trim());
        selectedUsers = localusers;
        console.log('selected users', selectedUsers);
      }
      this.selectedUsers = selectedUsers;
      this.showBanner = selectedUsers == null  || selectedUsers.length == 0;
      this.getHomeBannerInfo();
    }
    else this.loadDashboards();
  }

  setDashboardsFilter(users) {    
    localStorage.removeItem('dashboard');
    const userIDs = users.map(user => user.id);    
    this.selectedUsers = users ?? [];
    localStorage.setItem('dashboard',userIDs);
    this.loadDashboards();
   // console.log(userIDs);
    this.showProject = false;
    this.showJobs = false;
    this.showpriorities = false;
    this.showUpCommingProjects = false;
    this.showScore = false;
    
    setTimeout(() => {
      this.showProject = true;
      this.showJobs = true;
      this.showpriorities = true;
      this.showUpCommingProjects = true;
      this.showScore = true;
    }, 0);
  }

  loadDashboards() {
    forkJoin([
      this.dashboardService.getUserDashboards(),
      this.dashboardService.getDashboardGroups(),
      this.roleService.GetUserRolesForInstance(this.auth.getUser().id, true)]).subscribe((responseList) => {

      let dashboards = responseList[0];
      let groups = responseList[1];
      groups.forEach(group => {
        group.widgets.forEach(widget => {
          if (widget.settings !== null) widget.settings = JSON.parse(widget.settings);
          if (widget.roles !== null) widget.roles = JSON.parse(widget.roles);

          widget.enableEdit = widget.enableCongigure = widget.enableRemove = true;
        });
      });

      let correctedDashboardSettings: any[] = [];
      dashboards?.forEach(dashboard => {
        let roleAllowedWidgets: any[] = [];
        dashboard.widgets.forEach(widget => {
          // result is a list of all widgets that match widget that have the same user roles
          let result = groups.filter(group => {
            return group.widgets.filter(item => {
              return item.type === widget.type
            });
          });
          result.forEach(group => {
            group.widgets.forEach(wid => {
              // only try adding to widgets list if there are matches
              if (roleAllowedWidgets.filter(item => item.type === wid.type).length <= 0) {
                let widgetList = dashboard.widgets.filter(w => wid.type === w.type);
                // get list of widgets, including duplicate ones to add to the list
                widgetList.forEach(x => {
                  roleAllowedWidgets.push(x);
                })
              }
            });
          })
        });
        // create a new dasboard with the updated settings
        correctedDashboardSettings.push({
          id: dashboard.id,
          layout: dashboard.layout,
          name: dashboard.name,
          widgets: roleAllowedWidgets
        });
      });
      if (correctedDashboardSettings) this.dashboards = correctedDashboardSettings;
      if (this.dashboards.length > 0) this.openDashboard(this.dashboards[0]);

      this.saveDashboardForUser();
    }, error => {
      this.alertify.error('Unable to fetch dashboards');
    });
  }

  public openModalSetup(mode) {
    const initialState = {
      editDashboard: (mode == 'new') ? null : this.currentDashboard,
      mode: mode
    };

    let modalSize = (mode == 'rename') ? 'modal-md' : 'modal-lg';

    this.bsModalRef = this.modalService.show(DashboardSetupComponent, {ignoreBackdropClick: true, keyboard: false, initialState, class: modalSize });
    this.bsModalRef.content.callbackEvent.subscribe((data) => {

      if (data !== null) {
        // callback for creating a new Dashboard
        if (data.new) {
          this.dashboards.push(data.data);
          this.openDashboard(this.dashboards[this.dashboards.length -1]);
        } else {
          this.currentDashboard = data.data;
          this.openDashboard(this.currentDashboard);
        }

        this.saveDashboardForUser();
        this.autoFitLayout();
      }
    });

    this.bsModalRef.content.saveEvent.subscribe((callback) => {
      if (callback) this.saveDashboardForUser();
    })
  }

  public openModal(template: any) {
    this.modalRefConfirm = this.modalService.show(template, {ignoreBackdropClick: true, keyboard: false });
  }

  openDashboard(dashboard){
    //TODO: WE MAY NEED TO RE-INITIALISE GRID
    dashboard.widgets.forEach(widget => {
      widget.enableEdit = widget.enableCongigure = widget.enableRemove = !this.homepage;
      widget.settings = widget.settings || {};
    });

    this.currentDashboard = dashboard;
    this.layout = this.currentDashboard.layout;
  }

  actionSelected(type) {
    if (type == 'delete') this.openModal(this.modalConfirmRef);
    else this.openModalSetup(type);
  }

  removeDashboard() {
    this.dashboards = this.dashboards.filter( e => e.id != this.currentDashboard.id);
    if (this.dashboards.length > 0) this.openDashboard(this.dashboards[0]);
    else this.currentDashboard = null;

    this.saveDashboardForUser();
    this.modalRefConfirm.hide();
  }

  newDashboard() { this.openModalSetup('new'); }

  removeWidget(widget) {
    this.currentDashboard.widgets = this.currentDashboard.widgets.filter(e => e.id != widget.id);
    this.currentDashboard.layout = this.currentDashboard.layout.filter(e => e.id != widget.id);
    this.layout = this.currentDashboard.layout;
    this.saveDashboardForUser();
  }

  openWidgetConfig(widget) {
    const initialState = {
      editWidget: widget,
    };

    this.bsModalRef = this.modalService.show(WidgetConfigComponent, {ignoreBackdropClick: true, keyboard: false, initialState});
    this.bsModalRef.content.callbackEvent.subscribe((data) => {
      if (data !== null) {
        // callback for creating a new Dashboard
        var newWidget = JSON.parse(JSON.stringify(widget));
        newWidget.settings = data.data

        // need to replace old widget with new widget otherwise component doesn't notice change
        var widgetIndex = this.currentDashboard.widgets.indexOf(widget)
        this.currentDashboard.widgets[widgetIndex] = newWidget
        this.updateWidget(widget);
        this.openDashboard(this.currentDashboard)
      }
    });
  }

  updateWidget(widget) {
    widget.updated = true
    this.saveDashboardForUser();
  }

  onLayoutUpdated(evt){
    this.currentDashboard.layout = evt;
    window.dispatchEvent(new Event('resize'));
    this.saveDashboardForUser();
  }

  saveDashboardForUser() {
   // this.settingService.setDashboardSetting(this.dashboards);
    this.dashboardService.setUserDashboards(this.dashboards).subscribe(data => {
      if (data.length <= 0) return;
    }, error => console.log(error))
  };

  autoFitLayout() {
    const layout: KtdGridLayout = [];
    let x = 0;
    let y = 0;
    let maxH = 0;
    for (let i = 0; i < this.layout.length; i++) {
      layout.push(this.layout[i]);
      if((x + layout[i].w) > this.cols){
        //new row
        y += maxH;
        maxH = layout[i].h;

        layout[i].x = 0;
        layout[i].y = y;
      } else {
        maxH = (maxH > layout[i].h)? maxH : layout[i].h;
        layout[i].x = x;
        layout[i].y = y;
      }
      layout[i].w = (window.innerWidth > 1400) ? layout[i].w :100;
      x = layout[i].x + layout[i].w;
    }
    this.layout = layout;
  }

  //#region [rgba(0,255,0,0.1)] Home Page Functions
  getHomeBannerInfo() {
    let instanceId = this.auth.getUser().instanceId;
    this.dashboardService.getHomeBannerInfo(instanceId, this.homeBannerDaysOffset).subscribe(data => {
      this.homeBannerInfo = data;
    }, error => console.log(error));
  }
  setOffset(offset) {
    this.homeBannerOffset = offset;
    if (offset === 'month') this.homeBannerDaysOffset = 30;
    else if (offset === 'week') this.homeBannerDaysOffset = 7;
    else this.homeBannerDaysOffset = 1;
    this.getHomeBannerInfo();
  }
  //#endregion Home Page Functions

  resize() { window.dispatchEvent(new Event('resize')); }

  
}
