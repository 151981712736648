<div class="card my-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="nav-secondary font-weight-bold">Field Plan</h3>
      </div>
      <div class="col-6 d-flex align-items-center justify-content-end nav-font14 grey4 infoHover">
        <i class="fak fa-info" container="" placement="bottom" tooltip="When enabled, this feature stops traffic once a project or a specific vendor's limit is reached, automatically redirecting new traffic to the 'Overquota' links. In the event of an overall project limit being reached, all traffic will be directed to the respective vendor's 'Overquota' redirect links, bypassing the survey."></i>
        <span class="mx-2">Enforce limit</span>
        <div class="">
          @if (loading) {
            <div class="loading"></div>
          }
          @if (!loading) {
            <or-toggle-button class="light" size="small"  [isChecked]="projectFieldLimitEnabled" [(ngModel)]="projectFieldLimitEnabled" (changed)="changeAllocationCheck($event)"></or-toggle-button>
          }
        </div>
      </div>
    </div>
    @if (projectFieldLimitEnabled) {
      <div class="row align-items-center mx-0">
        <div class="grey5 nav-font14 font-weight-bold mt-3">Select the vendors you want to enforce vendor limits on:</div>
      </div>
      @if (!atLeastOneVendorEnforced) {
        <div class="position-relative" dropdown #dropdownVendorsToEnforce="bs-dropdown" insideClick="true" (isOpenChange)="$event == false ? handleAllocationEnforcementChange(true) : null">
          <div class="select-vendors grey3 nav-font14 d-flex align-items-center mt-1" dropdownToggle [class.isOpen]="dropdownVendorsToEnforce.isOpen">Select Vendors
            <span class="ml-auto grey4 nav-font16 fas" [class.fa-chevron-down]="!dropdownVendorsToEnforce.isOpen" [class.fa-chevron-up]="dropdownVendorsToEnforce.isOpen"></span>
          </div>
          <div *dropdownMenu class="vendor-enforce-dropdown-menu position-absolute" >
            @if (dropdownVendorsToEnforce.isOpen) {
              <ng-template *ngTemplateOutlet="listOfVendorsForEnforce; context:{$implicit: 'dropdown'}"></ng-template>
            }
          </div>
        </div>
      }
      @else {
        <div class="mt-2">
          <ng-template *ngTemplateOutlet="listOfVendorsForEnforce; context:{$implicit: 'static'}"></ng-template>
        </div>
      }
      <div class="header-seperator"></div>
    }
  </div>
  <div class="card-body pt-2 px-3 pb-0">
    <!--  overall -->
    <div class="field-plan grey4 nav-font14 mb-3" [class.pr-2]="(projectSurveyPartnersNotDeleted?.length > 5 && projectFieldLimitEnabled) || (projectSurveyPartnersNotDeleted?.length > 8 && !projectFieldLimitEnabled)" [class.editing]="project.editMode?.fullLaunchQuota != null || project.editMode?.softLaunchQuota != null">
      <div class="align-items-center d-flex">
        <span class="nav-font14 grey5 font-weight-bold">Overall Project</span>
      </div>
      <div class="d-flex align-items-center justify-content-center actual first-row last-row first-col">
        <div [ngClass]="(projectFieldLimitEnabled && ((project.projectStatus == 'Soft Launch' && completes?.length >= project.softLaunchQuota) || (project.projectStatus !== 'Soft Launch' && completes?.length >= project.fullLaunchQuota))) ? 'bg-danger text-white' : ''">
          <span class="font-weight-bold">{{ completes?.length  }}</span>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center sep">/</div>
      <div class="py-2 d-flex align-items-center limit first-row last-row last-col">
        @if (project.projectStatus != 'Soft Launch') {
          @if (project.editMode == null || project.editMode.fullLaunchQuota == null) {
            <span class="editable font-weight-bold" (click)="enableEdit(project, 'fullLaunchQuota', 'text')">{{ project.fullLaunchQuota }} n</span>
          }
          @if (project.editMode != null  && project.editMode.fullLaunchQuota != null) {
            <input type="number" (focus)="$event.target.select()" class="form-control form-control-sm d-inline" [(ngModel)]="project.editMode.fullLaunchQuota.value">
            <div class="d-inline-flex actions">
              <button (click)="cancelProjectAllocationChange(project)" type="button" class="btn nav-btn-primary btn-xs shadow-none mx-1 fieldPlanCancelProjectAllocation">
                <i class="fas fa-times"></i>
              </button>
              <button (click)="saveProjectAllocationChange(project, 'fullLaunchQuota')" type="button" class="btn nav-btn-accent btn-xs shadow-none fieldPlanSaveProjectAllocation">
                <i class="fas fa-check"></i>
              </button>
            </div>
          }
        }
        @if (project.projectStatus == 'Soft Launch') {
          @if (project.editMode == null || project.editMode.softLaunchQuota == null) {
            <span class="editable" (click)="enableEdit(project, 'softLaunchQuota', 'text')">{{ project.softLaunchQuota }} n</span>
          }
          @if (project.editMode != null  && project.editMode.softLaunchQuota != null) {
            <input type="number" (focus)="$event.target.select()" class="form-control form-control-sm d-inline" [(ngModel)]="project.editMode.softLaunchQuota.value">
            <div class="d-inline-flex actions">
              <button (click)="cancelProjectAllocationChange(project)" type="button" class="btn nav-btn-primary btn-xs shadow-none mx-1 fieldPlanCancelProjectAllocation">
                <i class="fas fa-times"></i>
              </button>
              <button (click)="saveProjectAllocationChange(project, 'softLaunchQuota')" type="button" class="btn nav-btn-accent btn-xs shadow-none fieldPlanSaveProjectAllocation">
                <i class="fas fa-check"></i>
              </button>
            </div>
          }
        }
      </div>
      <div class="or-dropdown-status d-flex align-items-center justify-content-end fieldPlanProjectStatus" [class.test-status]="project.projectStatus == 'Test'">
        <or-dropdown-status (updatePartners)="updatePartnerStatus($event, null)" (changed)="projectStatusChanged($event)" [status]="project.projectStatus" [projectId]="project.id" [surveyLink]="project?.projectSegments[0]?.surveyLink"></or-dropdown-status>
      </div>
    </div>
    <!-- by vendor -->
    <div class="field-plan grey4 nav-font14 font-weight-bold" [class.pr-2]="(projectSurveyPartnersNotDeleted?.length > 5 && projectFieldLimitEnabled) || (projectSurveyPartnersNotDeleted?.length > 8 && !projectFieldLimitEnabled)">
      <div class="pt-2">Vendor
        <i (click)="sortVendors(vendorsSortedBy == 'partnerName-asc' ? 'partnerName-desc' : 'partnerName-asc')" class="far fa-solid fa-sort grey4 text-center cursor-pointer ml-1"></i>
      </div>
      <div class="px-2 pb-1 pt-2 text-center actual first-row first-col">Actual</div>
      <div class="align-items-center justify-content-center sep"></div>
      <div class="px-2 pt-2 limit first-row last-col">Limit</div>
      <div class="px-2 pt-2">Status
        <i (click)="sortVendors(vendorsSortedBy == 'partnerStatus-asc' ? 'partnerStatus-desc' : 'partnerStatus-asc')" class="far fa-solid fa-sort grey4 text-center cursor-pointer ml-1"></i>
      </div>
    </div>
    @if (projectSurveyPartnersNotDeleted.length == 0) {
      <div class="field-plan">
        <a (click)="navigateToLinksAndVendors()" class="navfont-14 nav-accent-blue font-weight-bold my-2 mr-2 cursor-pointer partner-name">
          <div class="row">
            <div class="col-1">
              <i class="far fa-plus pr-2"></i>
            </div>
            <div class="col">
              Add vendor<br> to begin
            </div>
          </div>
        </a>
      </div>
    }
    <div class="scrollbar overflow-y" [class.widenForScrollbar]="(projectSurveyPartnersNotDeleted?.length > 5 && projectFieldLimitEnabled) || (projectSurveyPartnersNotDeleted?.length > 8 && !projectFieldLimitEnabled)" [class.vendorEnforceDropdownExists]="projectFieldLimitEnabled && !atLeastOneVendorEnforced" [class.vendorPickerExists]="projectFieldLimitEnabled && atLeastOneVendorEnforced">
      @for (partner of projectSurveyPartners; track partner; let idx = $index) {
        <div class="field-plan grey4 nav-font14" [class.editing]="partner.editMode?.partnerFullQuota != null || partner.editMode?.partnerSoftQuota != null">
          @if (partner.isDeleted == 0) {
            @if (partner.validatedSample) {
              <a (click)="openModal('edit');" class="nav-accent-blue cursor-pointer font-weight-bold partner-name">
                {{ partner.partnerName }}
              </a>
            }
            @if (!partner.validatedSample) {
              <span  class="nav-accent-blue font-weight-bold my-2 text-truncate mr-2 cursor-pointer partner-name" tooltip="Copy Entry Link" (click)="copyUrl(partner.partnerId)">
                {{ partner.partnerName }}
              </span>
            }
            @if (completes != null) {
              <div class="text-center d-flex align-items-center justify-content-center actual"
                [class.last-row]="(idx == projectSurveyPartners.length-1)"
                [class.first-col]="(idx == projectSurveyPartners.length-1)"
                [ngClass]="(projectFieldLimitEnabled && partner.enforcePartnerQuota && ((partner.partnerStatus == 'Soft Launch' && partnerCompletes[partner.partnerName] >= partner.partnerSoftQuota) || (partner.partnerStatus == 'Full Launch' && partnerCompletes[partner.partnerName] >= partner.partnerFullQuota))) ? 'bg-danger text-white' : ''">
                <span>{{ partnerCompletes[partner.partnerName] }}</span>
              </div>
            }
            <div class="d-flex align-items-center justify-content-center sep">/</div>
            @if (partner.partnerStatus != 'Soft Launch') {
              <div class="d-flex align-items-center limit" [ngClass]="(idx === projectSurveyPartners.length-1) ? 'last-row last-col' : ''">
                @if (partner.editMode ==  null || partner.editMode.partnerFullQuota == null) {
                  <span class="editable" (click)="enableEdit(partner, 'partnerFullQuota', 'text')">{{ partner.partnerFullQuota }} n</span>
                }
                @if (partner.editMode != null && partner.editMode.partnerFullQuota != null) {
                  <input type="number" (focus)="$event.target.select()" class="form-control form-control-sm d-inline" [(ngModel)]="partner.editMode.partnerFullQuota.value">
                  <div class="d-inline-flex actions">
                    <button (click)="cancelPartnerAllocationChange(partner, 'partnerFullQuota')" type="button" class="btn nav-btn-primary btn-xs shadow-none mx-1">
                      <i class="fas fa-times"></i>
                    </button>
                    <button (click)="savePartnerAllocationChange(partner, 'partnerFullQuota')" type="button" class="btn nav-btn-accent btn-xs shadow-none">
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                }
              </div>
            }
            @if (partner.partnerStatus == 'Soft Launch') {
              <div class="d-flex align-items-center actual font-weight-bold" [ngClass]="(idx === projectSurveyPartners.length-1) ? 'last-row first-col' : ''">
                @if (partner.editMode ==  null || partner.editMode.partnerSoftQuota == null) {
                  <span class="editable" (click)="enableEdit(partner, 'partnerSoftQuota', 'text')">{{ partner.partnerSoftQuota }} n</span>
                }
                @if (partner.editMode != null && partner.editMode.partnerSoftQuota != null) {
                  <input type="number" (focus)="$event.target.select()" class="form-control form-control-sm d-inline" [(ngModel)]="partner.editMode.partnerSoftQuota.value">
                  <div class="d-inline-flex actions">
                    <button (click)="cancelPartnerAllocationChange(partner, 'partnerSoftQuota')" type="button" class="btn nav-btn-primary btn-xs shadow-none mx-1">
                      <i class="fas fa-times"></i>
                    </button>
                    <button (click)="savePartnerAllocationChange(partner, 'partnerSoftQuota')" type="button" class="btn nav-btn-accent btn-xs shadow-none">
                      <i class="fas fa-check"></i>
                    </button>
                  </div>
                }
              </div>
            }
            <div class="or-dropdown-status d-flex align-items-center justify-content-end fieldPlanPartnerStatus" [class.test-status]="partner.partnerStatus == 'Test'">
              <or-dropdown-status
                (changed)="updatePartnerStatus($event, partner)"
                (updateProject)="projectStatusChanged($event)"
                [status]="partner.partnerStatus"
                [values]="['Test', 'Soft Launch', 'Full Launch', 'Paused', 'Closed']"
                [projectId]="project.id"
                [surveyLink]="project?.projectSegments[0]?.surveyLink"
                [partnerId]="partner.partnerId">
              </or-dropdown-status>
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>

<ng-template #listOfVendorsForEnforce let-mode>
  <div class="{{mode}} listOfVendorsToEnforce nav-font14 grey5">
    @if (projectSurveyPartnersNotDeleted.length <= 3) {
      <div class="col pl-2">
        @for (partner of projectSurveyPartnersNotDeleted; track partner.id) {
          <ng-template *ngTemplateOutlet="vendorForEnforceList; context:{$implicit: partner, mode: mode}"></ng-template>
        }
      </div>
    }
    @else {
      <div class="row mx-0">
        <div class="col-6 pl-2 pr-0">
          @for (partner of projectSurveyPartnersNotDeleted; track partner.id; let idx = $index) {
            @if (idx <= vendorAmountInColOne-1) {
              <ng-template *ngTemplateOutlet="vendorForEnforceList; context:{$implicit: partner, mode: mode}"></ng-template>
            }
          }
        </div>
        <div class="col-6 pl-2 pr-2">
          @for (partner of projectSurveyPartnersNotDeleted; track partner.id; let idx = $index) {
            @if (idx > vendorAmountInColOne-1) {
              <ng-template *ngTemplateOutlet="vendorForEnforceList; context:{$implicit: partner, mode: mode}"></ng-template>
            }
          }
        </div>
      </div>
    }
    
  </div>
</ng-template>

<ng-template #vendorForEnforceList let-partner let-mode="mode">
  <div class="d-flex align-items-center"> 
    <div class="or-checkbox mr-4">
      <input [(ngModel)]="partner.editEnforcePartnerQuota" type="checkbox" name="{{partner.id}}" id="{{partner.id}}" (change)="mode !== 'dropdown' ? handleAllocationEnforcementChange($event, partner) : allocationEnforcementChange = true">
      <label for="{{partner.id}}"></label>
    </div>
    <div class="text-nowrap text-truncate">{{partner.partnerName}}</div>
  </div>
</ng-template>