import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'core';

@Component({
  selector: 'app-project-homepage-team',
  templateUrl: './project-homepage-team.component.html',
  styleUrls: ['./project-homepage-team.component.scss']
})
export class ProjectHomepageTeamComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
  }

  @Output() callback = new EventEmitter();

  _project:any = {};
  secondaryPms:any = [];
  programmers:any = [];
  sales:any = [];

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.getTeamMembers();
  }

  getTeamMembers() {
    this._project.pm = this._project?.projectTeam.find(e => e.projectRole === 'PM')?.displayName;
    this._project.projectTeam.filter(e => e.projectRole === 'Secondary PM').map(e => this.secondaryPms.push({ name: e.displayName, type: 'pm' }));
    this._project.projectTeam.filter(e => e.projectRole === 'Programmer').map(e => this.programmers.push({ name: e.displayName, type: 'pm' }));

    this.userService.GetUsers().subscribe((users) => {
      users.filter(e => e.id === this._project.internalAccountOwnerId).map(e => this.sales.push({ name: e.firstName + ' ' + e.lastName, type: 'pm' }));
    });
  }

  openSettings(type) {
    this.callback.emit({section: 'settings', type: type});
  }

}
