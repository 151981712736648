import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, HostListener} from '@angular/core';

@Component({
  selector: 'app-project-homepage',
  templateUrl: './project-homepage.component.html',
  styleUrls: ['./project-homepage.component.scss']
})
export class ProjectHomepageComponent implements OnInit, AfterViewInit {

  @Input() public set data(data) {
    this._project = data?.project;
    this.respondents = data?.respondents;
  }

  @Input() public set stage(data) {
    this._stage = data;
    console.log(this._stage);
    this.updateGridRowSpan();
  }

  @Input() _stage: any;
  @Output() callback = new EventEmitter();

  _project:any = {};
  respondents: any = [];
  cols =  [];
  colWidth = 360;
  cardInFullscreen: any = null;

  constructor() { }

  ngOnInit() {
    this.updateGridRowSpan();
  }

  ngAfterViewInit() {
    this.updateGridRowSpan();
  }

  updateGridRowSpan(): void {
    if (!this.cardInFullscreen) {
      const cardElement = document.getElementsByClassName('homepageCardContainer').item(0);
      let cardsWidth = cardElement.getBoundingClientRect().width;
      let colCount = Math.floor(cardsWidth / this.colWidth);
      if(colCount < 3) colCount = 3;
      this.cols = [];
  
      for(let colIdx = 0; colIdx < colCount; colIdx++) {
        let col = { index: colIdx, cards: [] };
        for(let i=colIdx; i < this._stage?.homepageCards.length; i+=colCount) {
          col.cards.push(this._stage?.homepageCards[i]);
        }
        this.cols.push(col);
      }
    }
   else {
    this.cols = this.cols.filter(col => col.cards.some(x => x.type === this.cardInFullscreen.type));
    this.cols[0].cards = this.cols[0].cards.filter(x => x.type === this.cardInFullscreen.type);
   }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateGridRowSpan();
  }

  cardCallback(evt) {
    this.callback.emit(evt);
  }

  changeCardToFullscreen(cardType) {
    this._stage?.homepageCards.forEach(card => {
      if (card?.type === cardType) {
        this.cardInFullscreen = card;
      }
      else {
        card.show = false;
      }
    });
    this.updateGridRowSpan();
  }

  exitCardFullscreen() {
    this._stage?.homepageCards.forEach(card => {
        card.show = true;
    });
    this.cardInFullscreen = null;
    this.updateGridRowSpan();
  }

}
