import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, Renderer2, ViewChildren, TemplateRef } from '@angular/core';
import { AlertifyService, ProjectService, AuthService, Project, PartnerService, PartnerContactService, SettingsService,UtilsService, DropdownService } from 'core';
import { v4 as uuidv4 } from 'uuid';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, of } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-project-links-and-vendors',
  templateUrl: './project-links-and-vendors.html',
  styleUrls: ['./project-links-and-vendors.scss'],
  providers: [ProjectService]
})
export class ProjectLinksAndVendorsComponent implements OnInit {

  @Input() project: Project;
  @Output() projectStatusChange = new EventEmitter();
  @Output() onChange = new EventEmitter();

  @Input() public set respondents(data) {
    if (data.length >  0) {
      this.processData(data);
    } else {
      this.refreshPerformance()
      this.vendorPerformanceData = this.projectPerformance
    }
  }

  editLinkMode:any = {};
  urlList;
  dataReady = false;
  projectVendors = [];
  partners = [];
  partnerContacts:any = {};
  newVendors:any[] = [];
  addContactMode = false;
  newContact:any = {};
  @ViewChild('modal') modalRef: any;
  openModalRef: BsModalRef;
  openModalRefContact: BsModalRef;
  openModalRefRateCard: BsModalRef;
  newContactVendor;
  contactFirstName;
  contactLastName;
  companyPosition;
  phoneNumber;
  email;
  profilePhoto;
  totalVendors:number = 0;
  totalVendorsSelected = 0;
  isORInstance = false;
  fieldSaved;
  projectPerformance;
  vendorPerformanceData;
  vendorToDelete;
  openModalDeleteVendor: any;
  instanceId: string = null;
  model: any = {};
  summary: any;

  @ViewChild('inputLiveUrl') inputLiveUrl: ElementRef;
  @ViewChild('inputTestUrl') inputTestUrl: ElementRef;

  @ViewChildren('completeRedirectURL') completeRedirectURL;
  @ViewChildren('terminateRedirectURL') terminateRedirectURL;
  @ViewChildren('overQuotaRedirectURL') overQuotaRedirectURL;
  @ViewChildren('qcRedirectURL') qcRedirectURL;

  @ViewChildren('partnerProjectNumber') partnerProjectNumber;
  @ViewChildren('partnerFullQuota') partnerFullQuota;
  @ViewChildren('partnerSoftQuota') partnerSoftQuota;
  @ViewChildren('buyCPI') buyCPI;
  @ViewChild('modalDeleteVendor') modalDeleteVendor: TemplateRef<any>;

  @ViewChild('modalRateCPICard')
  modalRefRateCPICard: TemplateRef<any>;

  constructor(
    private partnerService: PartnerService,
    private projectService: ProjectService,
    private alertify: AlertifyService,
    private partnerContactService: PartnerContactService,
    private dropdownService: DropdownService,
    public utils: UtilsService,
    public modalService: BsModalService,
    private settingsService: SettingsService,
    private router: Router,
    private renderer: Renderer2,
    public auth: AuthService
    ) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.isORInstance = this.auth.isORInstance();
    this.settingsService.getSurveyLinkUrls().subscribe(data => {
      this.urlList = data;
      this.dataReady = true;
    });

    this.loadVendors(this.project.projectSegments[0].projectSurveyPartners);
  }

  refresh(){
    this.projectService.GetProject(this.project.id, '').subscribe(data => {
      this.project = data;
      this.loadVendors(this.project.projectSegments[0].projectSurveyPartners);
      this.onChange.emit();
    });
  }

  loadVendors(vendors){
    this.projectVendors = vendors.sort(e => e.partnerName);
    this.totalVendors = this.projectVendors.filter(x => !x.isDeleted).length;

    this.projectVendors.forEach(vendor => {
      vendor.partnerPms = this.project.projectTeam.filter(e => e.projectRole == 'Vendor PM' && e.partnerId.toLowerCase() == vendor.partnerId.toLowerCase()).map( e => ({ name: e.displayName, id: e.userId}));

      if(vendor.partnerPms == null){
        vendor.partnerPms.push({
          id: vendor.partnerContactId,
          name: vendor.partnerContactName
        });
      }
      // load vendor rate cards
      this.partnerService.GetPartnerRateCards(vendor.partnerId).subscribe(data => {
        vendor.rateCards = data.filter(e => e.enabled || e.id == vendor.partnerRateCardId);
        let rateCard = vendor.rateCards.find(e => e.id == vendor.partnerRateCardId);
        if(rateCard != null) {
          vendor.expectedCPI = this.getCpiFromRateCard(rateCard, this.project.projectSegments[0].projectIR, this.project.projectSegments[0].projectLOI);
          vendor.partnerRateCardName = rateCard.name;
        }
        else {
          vendor.partnerRateCardName = 'Manual';
        }
      });


      // has live traffic
      setTimeout(() => {
        vendor.hasTraffic = this.summary[vendor.partnerId] != null && this.summary[vendor.partnerId].starts > 0;
      }, 300);

    });

  }

  rateCardSelected(vendor, rateCard, updateDb = true) {
    vendor.partnerRateCardId = rateCard?.id || null;
    vendor.partnerRateCardName = rateCard?.name || 'Manual';
    this.fieldSaved = 'partnerRateCardId';
    this.updateVendorCPI(vendor)
    if (updateDb) this.updateVendor(vendor);
  }

  updateVendorCPI(vendor, updateDb = false) {
    if(vendor.partnerRateCardId != null) {
      let currentCpi = vendor.buyCPI;
      let rateCard = vendor.rateCards.find(e => e.id == vendor.partnerRateCardId);
      vendor.expectedCPI = this.getCpiFromRateCard(rateCard, this.project.projectSegments[0].projectIR, this.project.projectSegments[0].projectLOI);
      if(this.project.metrics.complete >= 10) {
        let actualIR = Math.round((this.project.metrics.complete * 100) / (this.project.metrics.complete + this.project.metrics.clientTerminate));
        let loi = Math.round(this.project.metrics.medianLoi * 60 *24);
        vendor.buyCPI = this.getCpiFromRateCard(rateCard, actualIR, loi);
      }
      else vendor.buyCPI = vendor.expectedCPI;

      if(updateDb && currentCpi != vendor.buyCPI) {
        this.fieldSaved = 'buyCPI';
        this.updateVendor(vendor);
      }
    }
  }

  getCpiFromRateCard(rateCard, ir, loi) {
    let irRange = rateCard.rateTable.cols; //an array of ir numbers
    let loiRange = rateCard.rateTable.rows; //an array of loi numbers

    //get the ir index which should get the lowest ir value that is greater than the project ir
    let irIndex = irRange.findIndex(e => e >= ir);
    let loiIndex = loiRange.findIndex(e => e >= loi);

    if(irIndex == -1 || loiIndex == -1) return rateCard.defaultCPI;

    let cpi = rateCard.rateTable.matrix[loiIndex][irIndex];
    return cpi ?? rateCard.defaultCPI;
  }
  viewRateCard(vendor) {
    this.model = vendor.rateCards.find(e => e.id == vendor.partnerRateCardId);
    this.openModalRefRateCard = this.modalService.show(this.modalRefRateCPICard, {class: 'nav-modal-style modal-md modal-partners'});
  }

  processData(data) {

    data.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    this.vendorPerformanceData = data;
    this.getVendorsSummary();
  }

  enableEditLinks(type:string) {
    if (type === 'liveUrl') {
      this.editLinkMode.liveUrl=true;
      setTimeout(() => {
        this.inputLiveUrl.nativeElement.focus();
      }, 200);
    }
    else {
      this.editLinkMode.testUrl=true;
      setTimeout(() => {
        this.inputTestUrl.nativeElement.focus();
      }, 200);
    }
  }

  enableVendorField(type:string, vendor: any) {

    if(vendor?.edit != null && vendor?.edit[type] == true) return;

    vendor.edit = {};
    vendor.oldValue = {};

    vendor.edit[type] = true;
    vendor.oldValue[type] = vendor[type];

    if (type === 'partnerPms') {
      this.partnerContactService.GetPartnerContacts(vendor.partnerId).subscribe( data => {
        vendor.contacts = data;
      });
    }
  }

  checkFocus(type:string, index) {
    const elm = this[type].toArray()[index];
    if (elm && elm?.nativeElement === document.activeElement) {
      this.renderer.addClass(elm.nativeElement, 'border');
      this.renderer.removeClass(elm.nativeElement, 'border-0');
    } else if (elm && this.renderer) {
      this.renderer.addClass(elm.nativeElement, 'border-0');
      this.renderer.removeClass(elm.nativeElement, 'border');
    }
  }

  onBlur(type:string, vendor, index) {
    this.fieldSaved = type;
    const actualValue = vendor[type];
    const oldValue = vendor.oldValue[type];
    if (actualValue !== oldValue)
      setTimeout(() => {
        this.updateVendor(vendor);
        this.checkFocus(type, index);
      }, 200);
    else {
      this.checkFocus(type, index);
    }
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.saveLinks();
    }
  }

  onKeyPressVendors(event: KeyboardEvent, vendor:any) {
    if (event.key === "Enter") {
      this.updateVendor(vendor);
    }
  }

  copyRedirectUrls() {
    const totalString = 'Redirects\n\n' + 'Complete  = ' + this.urlList.completeRedirectURL + '\n' +
    'Terminate = ' + this.urlList.terminateRedirectURL + '\n' +
    'Overquota = ' + this.urlList.overQuotaRedirectURL + '\n' +
    'QC        = ' + this.urlList.qcRedirectURL;

    this.utils.copyTextToClipboard(totalString);
    this.alertify.success('Redirect URLs are copied');
  }

  openPartner(partner){

    this.router.navigateByUrl('/vendors/' + partner.partnerId);
  }

  openVendorView(vendor){
    const url = `/projects/${this.project.id}/vendorview/${vendor.partnerId}`;
    window.open(window.location.origin + url, '_blank');
  }

  saveLinks() {

    this.projectService.UpdateProjectSurveyLinks(this.project.id, this.project.projectSegments[0].surveyLink, this.project.projectSegments[0].testSurveyLink, this.project.projectSegments[0].surveyPlatForm).subscribe(data => {

      if (this.editLinkMode?.testUrl) this.alertify.success('Test URL updated');
      if (this.editLinkMode?.liveUrl) this.alertify.success('Live URL updated');

      this.editLinkMode = {};
    }, error => {
      this.alertify.error('Survey Links failed to update');
    });
  }

  deleteVendor(vendor) {
    this.projectService.DeletePartnerFromProject(this.project.id, vendor.partnerId).subscribe(data => {
      this.alertify.success('Vendor has been removed');
      vendor.isDeleted = true;
      this.totalVendors = this.projectVendors.filter(x => !x.isDeleted).length;
    }, error => {
      this.alertify.error('There was an issue removing this Vendor');
    });
  }

  openDeleteVendorModal(vendor) {
      this.openModalDeleteVendor = this.modalService.show(this.modalDeleteVendor, { ignoreBackdropClick: true, keyboard: true, class: 'nav-modal-style nav-modal-tasks' });
      this.vendorToDelete = vendor
  }
  closeDeleteVendorModal() { this.openModalDeleteVendor.hide(); }

  deleteVendorModal() {
    this.deleteVendor(this.vendorToDelete)
    this.closeDeleteVendorModal()
  }

  deleteNewVendor(index) {
   this.newVendors.splice(index, 1);
  }

  addVendor() {
    if(this.partners.length == 0) {
      this.dropdownService.getPartners().subscribe(data => {
        this.partners = data;
      });
    }
    this.newVendors = [];
    this.addEmptyVendor();
    this.openModalRef = this.modalService.show(this.modalRef as any, { ignoreBackdropClick: true, keyboard: false, class: 'nav-modal-new-vendor nav-modal-style' });
  }

  addEmptyVendor(id = null) {
    this.newVendors.push(this.createEmptyVendor(id));
  }

  createEmptyVendor(id = null) {
    return {
      id : null,
      isDeleted: false,
      segmentId: this.project.projectSegments[0].id,
      partnerId: id,
      partnerStatus: 'Test',
      partnerStartDate: null,
      partnerFullQuota: 0,
      partnerSoftQuota: 0,
      buyCPI: 0,
      surveyEntryUrl: '',
      completeRedirectURL: '',
      terminateRedirectURL: '',
      overQuotaRedirectURL: '',
      qcRedirectURL: '',
      partnerName: '',
      partnerImage: '',
      partnerContactId: null,
      partnerContactName: '',
      partnerContactEmail: '',
      partnerContactPhone: '',
      partnerProjectNumber: '',
      completedSurveyCount: 0,
      partnerPms: [],
      contacts: [],
      partnerRateCardId: null,
      partnerRateCardName: null
    };
  }

  confirmSave() {
    const existing = this.projectVendors.find(e => this.newVendors.some(x => x.partnerId.toUpperCase() === e.partnerId.toUpperCase()));
    if(existing != null && !existing.isDeleted){
        this.alertify.error(`${existing?.partnerName} vendor has already been added`);
        return;
    }

    // Validations for OR instances
    if (this.isORInstance) {
      let notOK = this.newVendors.some(x => !x.partnerFullQuota || x.partnerFullQuota <= 0 || !x.buyCPI || x.buyCPI <= 0);
      if (notOK) {
        this.alertify.error('Please fill in all required fields');
        return;
      }
    }

    if(this.newVendors.some(x => x.id == null)) {

      this.newVendors = this.newVendors.map(vendor => {
        vendor.id = uuidv4();
        vendor.surveyEntryUrl =  this.urlList ? this.urlList.entryURL + vendor.id + '?' + this.projectService.generateEntryUrlParams(this.project.projectSegments[0].surveyLink, vendor) : "";
        var selected = this.partners.find(e => e.value.toUpperCase() == vendor.partnerId.toUpperCase());
        vendor.partnerName = selected?.name;

        return vendor;
      });
    }

    this.saveVendors();
  }

  saveVendors() {

    const saveRequests = this.newVendors.map(vendor => {
      const vendorPms = vendor.partnerPms.map(e => e.id);
      return this.projectService.SaveProjectPartner(this.project.id, vendor, vendorPms);
    });

    forkJoin(saveRequests).subscribe(results => {
        this.closeModal();
        this.refresh();
        this.alertify.success('Vendors saved sucessfully');
      }, error => {
        this.alertify.error('Failed to save vendors');
      }
    );
  }

  autoUpdateSoftLaunch(vendor) {
    if(vendor.partnerSoftQuota == 0) {
      vendor.partnerSoftQuota = Math.floor(vendor.partnerFullQuota/10);
    }
  }

  closeModal() {
    this.openModalRef.hide();
    this.openModalRef = null;
    this.newVendors = [];
    setTimeout(() => {
      this.addEmptyVendor();
    }, 200);
  }

  setNewVendor(event, newVendorIndex) {

    let newVendor = this.newVendors[newVendorIndex];
    const selectedValue = event[0]?.value;
    const selectedName = event[0]?.name;

    if(selectedValue == null) {
      this.totalVendorsSelected -=1;
      this.newVendors[newVendorIndex] = this.createEmptyVendor(null);
      return;
    }
    newVendor.partnerId = selectedValue.toLowerCase();
    newVendor.partnerName = selectedName;
    this.totalVendorsSelected +=1;
    let partner:any;

    this.partnerService.GetPartner(newVendor.partnerId, true).subscribe( data => {
      partner = data;
      this.partnerContactService.GetPartnerContacts(newVendor.partnerId).subscribe( data => {
        newVendor.contacts = data;
      });

      // load vendor rate cards
      this.partnerService.GetPartnerRateCards(newVendor.partnerId).subscribe(data => {
        newVendor.rateCards = data.filter(e => e.enabled || e.id == newVendor.partnerRateCardId);
        let rateCard = newVendor.rateCards.find(e => e.id == newVendor.partnerRateCardId);
        if(rateCard != null) {
          newVendor.expectedCPI = this.getCpiFromRateCard(rateCard, this.project.projectSegments[0].projectIR, this.project.projectSegments[0].projectLOI);
          newVendor.partnerRateCardName = rateCard.name;
        }
        else {
          newVendor.partnerRateCardName = 'Manual';
        }
      });

      var existing = this.projectVendors.find(e => e.partnerId.toLowerCase() == newVendor.partnerId);
      if(existing != null){
        newVendor.id = existing.id;
        newVendor.completeRedirectURL = existing.completeRedirectURL;
        newVendor.terminateRedirectURL = existing.terminateRedirectURL;
        newVendor.overQuotaRedirectURL = existing.overQuotaRedirectURL;
        newVendor.qcRedirectURL = existing.qcRedirectURL;
        newVendor.partnerFullQuota= existing.partnerFullQuota;
        newVendor.partnerSoftQuota= existing.partnerSoftQuota;
        newVendor.buyCPI = existing.buyCPI;
        newVendor.partnerProjectNumber = existing.partnerProjectNumber;
        newVendor.partnerPms = existing.partnerPms;
      }
      else{
        newVendor.completeRedirectURL = partner.completeRedirectURL;
        newVendor.terminateRedirectURL = partner.terminateRedirectURL;
        newVendor.overQuotaRedirectURL = partner.overQuotaRedirectURL;
        newVendor.qcRedirectURL = partner.qcRedirectURL;
      }
    });

  }

  handleUpdateSoftLaunch(type:string, event: KeyboardEvent, vendor) {
    this.fieldSaved = type;
    let OK: boolean = false;

    if (event == null) OK = true;
    else {
      if (event.key === "Enter") {
        OK = true;
      }
    }

    if(OK) {
      if (vendor.partnerSoftQuota == 0) {
        vendor.partnerSoftQuota = Math.floor(vendor.partnerFullQuota/10);
        this.updateVendor(vendor);
      } else {
        this.updateVendor(vendor);
      }
    }
  }

  updateVendor(vendor) {
    var vendorPms = vendor.partnerPms.map( e => e.id);

    vendor.edit = {};

    this.projectService.SaveProjectPartner(this.project.id, vendor, vendorPms).subscribe(data => {

      if (this.fieldSaved === 'completeRedirectURL') this.alertify.success('Complete URL updated');
      else if (this.fieldSaved === 'terminateRedirectURL') this.alertify.success('Term URL updated');
      else if (this.fieldSaved === 'overQuotaRedirectURL') this.alertify.success('OverQuota URL updated');
      else if (this.fieldSaved === 'qcRedirectURL') this.alertify.success('QC URL updated');
      else if (this.fieldSaved === 'partnerProjectNumber') this.alertify.success('Project number updated');
      else if (this.fieldSaved === 'partnerFullQuota') this.alertify.success('Allocation updated');
      else if (this.fieldSaved === 'partnerSoftQuota') this.alertify.success('Soft Launch updated');
      else if (this.fieldSaved === 'buyCPI') this.alertify.success('CPI updated');
      else if (this.fieldSaved === 'partnerPms') this.alertify.success('Vendor contact updated');
      else if (this.fieldSaved === 'partnerRateCardId') this.alertify.success('CPI source updated');
    }, error => {
      this.alertify.error('Vendor failed to update');
    });
  }

  getPartneContactNames(vendor): string {
    return vendor.partnerPms.map(p => p.name).join(', ');
  }

  updateVendorPM(type, event, vendor){
    this.fieldSaved = type;
    vendor.partnerPms = event;
    this.updateVendor(vendor);
  }

  addNewContact(vendor, modal) {
    this.newContactVendor = vendor;

    this.contactFirstName = null;
    this.contactLastName = null;
    this.companyPosition = null;
    this.phoneNumber = null;
    this.email = null;
    this.profilePhoto = null;

    this.newContact = {
      id: uuidv4(),
      mode: 'new',
      firstName: null,
      lastName: '',
      phone: '',
      email: '',
      role: 'Vendor',
      companyPosition: '',
      profilePhoto: '',
      partnerId: vendor.partnerId,
      address: {
        address1: '',
        address2: '',
        city: '',
        country: ''
      },
      social: {
        facebook: '',
        linkedIn: '',
        twitter: '',
        skype: ''
      }
    };

    this.openModalRefContact = this.modalService.show(modal, {animated: true, keyboard: false, class: 'nav-modal-style' });
  }

  refreshPerformance() {
    this.projectService.GetProjectParticipants(this.project.id, "").subscribe(data => {

      this.projectPerformance = data.map(e => {
        const ret: any = { ...e };
        const inTime = new Date(e.date);
        ret.date = new Date(inTime.getFullYear(), inTime.getMonth(), inTime.getDate());
        ret.partner = ret.vendor;
        ret.partnerId = ret.vendorId;
        ret.complete = (e.completeLois == null) ? 0 : e.completeLois.length;
        return ret;
      }).sort((a, b) => (new Date(a.in).valueOf() - new Date(b.in).valueOf()));

      this.vendorPerformanceData = this.projectPerformance;
      this.getVendorsSummary();
    });
  }

  getVendorsSummary() {
    let summary: Record<string, any> = {};

    this.vendorPerformanceData.forEach(item => {
      if (!item.vendor) return;

      if (!summary[item.vendorId]) {
        summary[item.vendorId] = {
          vendor: item.vendor,
          total: 0,
          starts: 0,
          complete: 0,
          navTerminate: 0,
          clientOverquota: 0,
          limitOverquota: 0,
          overquota: 0,
          clientTerminate: 0,
          clientQc: 0,
          autoQc: 0,
          cid: 0,
          mobileBlock: 0,
          vid: 0,
          dropoff: 0,
          dupe: 0,
          loi: 0
        };
      }

      const vendor = summary[item.vendorId];

      vendor.total += item.total || 0;
      vendor.starts += item.starts || 0;
      vendor.complete += item.complete || 0;
      vendor.navTerminate += item.navTerminate || 0;
      vendor.clientOverquota += item.clientOverquota || 0;
      vendor.limitOverquota += item.limitOverquota || 0;
      vendor.overquota += item.overquota || 0;
      vendor.clientTerminate += item.clientTerminate || 0;
      vendor.clientQc += item.clientQc || 0;
      vendor.autoQc += item.autoQc || 0;
      vendor.cid += item.cid || 0;
      vendor.mobileBlock += item.mobileBlock || 0;
      vendor.vid += item.vid || 0;
      vendor.dropoff += item.dropoff || 0;
      vendor.dupe += item.dupe || 0;
    });

    this.summary = summary;
  }

  containsVendorName(name) {
    for (let obj of this.vendorPerformanceData) {
      if (obj.vendor == name) return true;
    }
    return false;
  }

  getVendors = () => { return of(this.partners); }

  profilePhotoUploadCompleted(img) {
    img.url = this.profilePhoto;
  }

  saveNewContact() {
    this.newContact.firstName = this.contactFirstName;
    this.newContact.lastName = this.contactLastName;

    this.newContact.companyPosition = this.companyPosition;
    this.newContact.email = this.email;
    this.newContact.profilePhoto = this.profilePhoto;

    if(this.newContact.email == null || this.newContact.email == "") {
      this.alertify.error('Email is required');
      return;
    }

    this.partnerContactService.AddPartnerContact(this.newContact).subscribe( data => {
      this.newContactVendor.partnerPms.push({
        id: this.newContact.id,
        name: this.newContact.firstName + ' ' + this.newContact.lastName
      });
      this.alertify.success('Contact added successfully');
      this.openModalRefContact.hide();
      }, error => {
        this.alertify.error('Unable to add contact');
      },
      () => {
        this.addContactMode = false;
      });
  }

  cancelNewContact() {
    this.openModalRefContact.hide();
  }

  projectStatusUpdated(event) {
    this.projectStatusChange.emit(event);
  }
  copyEntryUrl(vendor){
    const url = this.getEntryUrl(vendor);
    this.utils.copyTextToClipboard(url);
    this.alertify.success('Link copied to clipboard');
  }
  copyUrl(url){
    if (!url) return;
    this.utils.copyTextToClipboard(url);
    this.alertify.success('Link Copied');
  }
  getEntryUrl(vendor){

    const url = this.urlList ? this.urlList.entryURL + vendor.id + '?' + this.projectService.generateEntryUrlParams(this.project.projectSegments[0].surveyLink, vendor) : "";
    if(url != vendor.surveyEntryUrl){
      //The url does not match what is saved so we save here

      const newLinks = {
        entryURL : url,
        completeRedirectURL: vendor.completeRedirectURL,
        terminateRedirectURL: vendor.terminateRedirectURL,
        overQuotaRedirectURL : vendor.overQuotaRedirectURL,
        qcRedirectURL: vendor.qcRedirectURL
      };
      vendor.surveyEntryUrl = url;
      this.projectService.SavePartnerProjectRedirects(this.project.id, vendor.partnerId, newLinks).subscribe({
        next: (data) => {    },
        error: (e) => this.alertify.error('There was an error updating the Entry Link for ' + vendor.partnerName)
      })

    }
    return url;
  }
  copyRedirects(vendor, type) {
    let copyString = '';
    if (type === 'completeRedirectURL') copyString =  vendor.completeRedirectURL;
    else if (type === 'terminateRedirectURL') copyString =  vendor.terminateRedirectURL;
    else if (type === 'overQuotaRedirectURL') copyString = vendor.overQuotaRedirectURL;
    else if (type === 'qcRedirectURL') copyString =  vendor.qcRedirectURL;

    this.utils.copyTextToClipboard(copyString);
    this.alertify.success('Link copied to clipboard');
  }


  showLinkSecurity(vendor){
    let url = vendor.completeRedirectURL ?? "";
    return url.toLowerCase().includes("[sha1]") || url.toLowerCase().includes("[sha256]") || url.toLowerCase().includes("[ed25519]");
  }
}
