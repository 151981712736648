<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header px-3 pt-3 mx-3">
          <div class="row align-items-center">
            <div class="col pl-0">
              <h3 class="nav-secondary font-weight-bold pb-0 mb-0">Files & Links</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-header px-3 pt-3 mx-3">
          <div class="row align-items-center">
            <div class="col pl-0">
              <h3 class="nav-secondary font-weight-bold pb-0 mb-0">Exports</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    @if (isJibunu) {
    <div class="col">
      <div class="card">
        <div class="card-header px-3 pt-3 mx-3">
          <div class="row align-items-center">
            <div class="col pl-0">
              <div class="row">
                <div class="col">
                  <h3 class="nav-secondary font-weight-bold pb-1">Data Exports ({{ dataFiles.reports.length }})</h3>
                </div>
                @if (jibunuLinkAuthenticated) {
                <div class="col d-flex align-items-center justify-content-end">
                  <button type="button" class="btn btn-sm shadow-none font-weight-bold nav-btn-accent"
                    [class]="generatingReport.length > 0 ? 'generate-btn-disabled' : ''"
                    [disabled]="generatingReport.length > 0"
                    [tooltip]="generatingReport.length > 0 ? 'Please wait. Only one file can be generated at a time.' : ''"
                    (click)="prepareGenerateDataFile()">Generate Data File
                  </button>
                </div>
                }
              </div>
            </div>
          </div>
        </div>

        @if (datafileLoading) {
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
          </div>
        </div>
        }
        @else if (jibunuLinkAuthenticated) {
        <div class="card-body px-3 pt-1 scrollbar overflow-y">
          @if (this.generatingReport.length > 0) {
          <div class="grey4 nav-font12 text-center">
            <div class="spinner-border spinner-border-md " role="status"></div>
            <div class="d-inline-block ml-2 text-left">
              <div>Generating new data file</div>
              <div>{{percentageGen}}% Complete</div>
            </div>
          </div>
          }
          <table class="table mb-0 mt-3 border-bottom">
            <tbody>
              @for (file of dataFiles.reports.slice().reverse(); track file) {
              @if (file.status == 'Completed') {
              <tr>
                <td>
                  <div class="grey4 nav-font12">
                    <div class="font-weight-bold">UTC: {{getFileGenDateInUTC(file)}} / Local:
                      {{getFileGenDateInLocalTime(file)}}</div>
                    <div>{{ file.language }} Languages/ Filtered by {{file.filter_Name}} / {{file.respondent_count}}
                      case{{file.respondent_count > 1 ? 's' : ''}}</div>
                    <div>Date: {{formatDateShort(file.start_time)}} - {{formatDateShort(file.end_time)}}</div>
                  </div>
                </td>

                <td>
                  <div class="text-center grey4 nav-font18 cursor-pointer" (click)="downloadDataFile(file)">
                    <span container="body"><i class="fa fa-download"></i></span>
                    <div class="grey2 nav-font12">{{file.friendly_Name_Override}}</div>
                  </div>
                </td>
              </tr>

              @if (file.friendly_Name_Override.toLowerCase() == "csv") {
              <tr>
                <td>
                  <div class="grey4 nav-font12">
                    <div class="font-weight-bold">UTC: {{getFileGenDateInUTC(file)}} / Local:
                      {{getFileGenDateInLocalTime(file)}}</div>
                  </div>
                </td>

                <td>
                  <div class="text-center grey4 nav-font18 cursor-pointer" (click)="downloadDataFile(file, 'layout')">
                    <span container="body"><i class="fa fa-download"></i></span>
                    <div class="grey2 nav-font12">Layout</div>
                  </div>
                </td>
              </tr>
              }
              }
              }
            </tbody>
          </table>
        </div>
        }
        @else {
        <div class="card-body px-3 pt-1 scrollbar overflow-y">
          <div>Please check the jibunu test link to make sure it is correct!</div>
        </div>
        }
      </div>
    </div>
    }
  </div>
</div>


<!-- Modal Generate Data File -->
<ng-template #modal>
  <div class="card shadow-none border-0">
    <div class="card-body pt-2">
      <div class="row mt-2 mb-4">
        <div class="col-5">
          <span class="nav-font18 nav-secondary font-weight-bold">Generate Data File</span>
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <i class="fas fa-close cursor-pointer grey4" (click)="closeModal()"></i>
        </div>
      </div>

      <div class="scrollbar overflow-y overflow-x-hidden pr-2">
        <form>
          <div class="mb-3">
            <label class="grey4 font-weight-bold">Report type</label>
            <or-dropdown-standard [values]="reportTypeDropdownValues" [value]="dataGenRequestBody.format"
              (selected)="changeReportType($event)"></or-dropdown-standard>
          </div>

          <div class="mb-3">
            <label class="grey4 font-weight-bold">Start date</label>
            <div class="input-group w-50 mb-3 form-control-date">
              <input type="text" autocomplete="off" name="fileStartDate" readonly [(ngModel)]="dataGenStartDate"
                (ngModelChange)="updateDatetime('dataGenStartDate', $event)" bsDatepicker placeholder="Select"
                #dfStartDate="bsDatepicker"
                [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, returnFocusToInput: true,adaptivePosition: true, customTodayClass: 'todays-date',  selectFromOtherMonth: true }"
                class="form-control nav-font14 date d-inline bg-white w-50 px-2">
              <span class="input-group-text bg-white" (click)="dfStartDate.toggle()"><i
                  class="fak fa-calendar grey4"></i></span>
            </div>
          </div>

          <div class="mb-3">
            <label class="grey4 font-weight-bold">End date</label>
            <div class="input-group w-50 mb-3 form-control-date">
              <input type="text" autocomplete="off" name="fileEndtDate" readonly [(ngModel)]="dataGenEndDate"
                (ngModelChange)="updateDatetime('dataGenEndDate', $event)" bsDatepicker placeholder="Select"
                #dfEndDate="bsDatepicker"
                [bsConfig]="{ dateInputFormat: 'MM/DD/YY', showWeekNumbers:false, returnFocusToInput: true,adaptivePosition: true, customTodayClass: 'todays-date',  selectFromOtherMonth: true }"
                class="form-control nav-font14 date d-inline bg-white w-50 px-2">
              <span class="input-group-text bg-white" (click)="dfEndDate.toggle()"><i
                  class="fak fa-calendar grey4"></i></span>
            </div>
          </div>

          <div class="mb-3">
            <label class="grey4 font-weight-bold">Language</label>
            <or-dropdown-standard [values]="languageDropdownValues" [value]="dataGenRequestBody.language"
              (selected)="changeLanguage($event)"></or-dropdown-standard>
          </div>

          <div class="mb-3">
            <label class="grey4 font-weight-bold">Filter</label>
            <or-dropdown-standard [values]="filterDropdownValues" [value]="dataGenRequestBody.filter"
              (selected)="changeFilter($event)"></or-dropdown-standard>
          </div>
        </form>
      </div>

      <div class="col d-flex align-items-center justify-content-end">
        <span type="button" class="btn btn-sm nav-btn-primary shadow-none font-weight-bold"
          (click)="closeModal()">Cancel</span>
        <span type="button" class="btn ml-2 btn-sm nav-btn-accent shadow-none font-weight-bold"
          (click)="generateDataFile()">Generate</span>
      </div>


    </div>
  </div>
</ng-template>