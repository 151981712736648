<div class="card">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-nav-status-invoiced fa-lg mr-2 grey4 font-weight-normal"></i>
      Invoice
      <span class="nav-font14 grey4 ml-2 font-weight-normal">
        {{closureDetails?.invoiceDate !== null ? 'Invoiced on ' + (closureDetails?.invoiceDate | date: 'MM/dd/yy'): 'Project not invoiced'}}
      </span>
    </span>
    <i class="fas fa-arrows-maximize fa-md grey4 font-weight-normal float-right cursor-pointer" (click)="openInvoice()"></i>
  </div>
  <div class="card-body nav-font14 py-1">
    <div class="row">
      <div class="col">
        <div class="row mb-2 grey5">
          <div class="col-5">
            <i class="fak fa-invoice mr-2 nav-success font-weight-normal"></i>
          </div>
          <div class="col px-0">
            <div class="nav-font14 font-weight-bold">Billing date</div>
            <div class="nav-font14 font-weight-normal">{{closureDetails?.billingDate | date: 'MM/dd/yy'}}</div>
          </div>
        </div>
      </div>
      <div class="col d-flex align-items-start">
        <button class="btn d-flex nav-btn-primary shadow-none px-2 mt-2" (click)="downloadInvoice()">
          <i class="fak fa-download mr-2 grey4 font-weight-normal"></i>
          <span class="font-weight-bold nav-secondary nav-font14 text-nowrap">Download invoice</span>
        </button>
      </div>
    </div>
  </div>
</div>
