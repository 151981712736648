<div class="card">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-my-tasks fa-md mr-2 grey4 font-weight-normal"></i>Schedule</span>
    <i class="fas fa-arrows-maximize fa-md grey4 font-weight-normal float-right cursor-pointer" (click)="openSchedule()"></i>
  </div>
  <div class="card-body nav-font14 pt-2">
    <div class="row align-items-start">
      <div class="col nav-font14 nav-accent-blue">
        @if (!hasTasks) {
          <span class="d-block grey5 nav-font16 mb-3">
            Tasks have not been added to this stage
          </span>
          <span class="d-block nav-font14 grey5 font-weight-bold nav-link-accent cursor-pointer" style="text-decoration: underline;" (click)="openSchedule()">Click here to add tasks</span>
        } @else {
          <div class="row nav-font14">
            <div class="col-7 chart ml-3 px-0">
              <highcharts-chart
                id="tasksOverviewChart"
                [Highcharts]="highcharts"
                [options]="highchartsOptionsTasksOverview"
                style="width: 100%; height: 100%; display: block;">
              </highcharts-chart>
            </div>
            <div class="col grey5 nav-font14 mt-4 px-0">
              @if (taskCount["complete"] > 0) {
                <div>
                  <i class="fas fa-square complete nav-font16 mr-2"></i>
                  <span>Complete</span>
                </div>
              }
              @if (taskCount["pending"] > 0) {
                <div>
                  <i class="fas fa-square pending nav-font16 mr-2"></i>
                  <span>Pending</span>
                </div>
              }
              @if (taskCount["overdue"] > 0) {
                <div>
                  <i class="fas fa-square overdue nav-font16 mr-2"></i>
                  <span>Overdue</span>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
