import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertifyService, AuthService } from 'core';

@Component({
  selector: 'app-project-homepage-client',
  templateUrl: './project-homepage-client.component.html',
  styleUrls: ['./project-homepage-client.component.scss']
})
export class ProjectHomepageClientComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
  }

  @Output() callback = new EventEmitter();

  _project:any = {};
  clientView = false;

  constructor(private alertify: AlertifyService, public auth: AuthService) { }

  ngOnInit() {
    this.clientView = this.auth.getUser().access.clientView;
  }

  openSettings(type) {
    this.callback.emit({section: 'settings', type: type});
  }

  openClientView(){
    const url = `/projects/${this._project.id}/clientview/${this._project?.clientId}`;
    window.open(window.location.origin + url, '_blank');
  }

  copyToClipboard(text: string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();

    try {
      navigator.clipboard.writeText(text);
      this.alertify.success('Text copied to clipboard');
    } catch (err) {
      this.alertify.error('Failed to copy text to clipboard');
    }
    document.body.removeChild(textarea);
  }

}
