import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'core';
import { FavoritesService } from 'projects/core/src/services/favorites.service';

@Component({
  selector: 'app-project-card-survey',
  templateUrl: './project-card-survey.component.html',
  styleUrls: ['./project-card-survey.component.scss']
})
export class ProjectCardSurveyComponent implements OnInit {

  @Input() project: any;
  @Input() summaryPage  = false;
  @Input() isFavorite  = false;
  @Output() favoriteChange = new EventEmitter();
  @Input() actions: [];
  @Input() isGroup: false;
  @Output() action = new EventEmitter();
  @Input() items: any;

  constructor(private favoritesService: FavoritesService, public auth: AuthService) {}

  ngOnInit() { }

  toggleFavorite() {

    this.isFavorite = !this.isFavorite;
    if (this.isFavorite ) {
      this.favoritesService.setProjectFavorite(this.project.id).subscribe(() => {
        this.favoriteChange.emit(this.isFavorite);
      }, error => console.error(error));
    }
    else {
      this.favoritesService.removeProjectFavorite(this.project.id).subscribe(() => {
        this.favoriteChange.emit(this.isFavorite);
      }, error => console.error(error));
    }
  }

  actionSelected(type) {
    const row = this.project;
    this.action.emit({row, type});
  }
}
