import { Component, OnInit } from '@angular/core';
import {AuthService, AlertifyService, InstanceService} from 'core';
import { ActivatedRoute, Router } from '@angular/router';
import { login } from '../../_models/login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model = {} as login;
  returnUrl: string;
  availableUsers = [];
  siteSectionsLinks = [
    { type: 'ProjectList', url: '/projects' },
    { type: 'Team', url: '/team' },
    { type: 'Vendors', url: '/vendors' },
    { type: 'Clients', url: '/clients' },
    { type: 'ReportsCleanID', url: '/reports/cleanid/api' },
    { type: 'ReportsCleanIDNav', url: '/reports/cleanid/nav' },
    { type: 'ReportsResponseID', url: '/reports/responseid' },
    { type: 'TaskList', url: '/schedule' },
    { type: 'ReportsPerformance', url: '/reports/performance' },
    { type: 'Licensee', url: '/reports/licensee' },
    { type: 'ReportsAudit', url: '/reports/audit' },
    { type: 'ReportsReportBuilder', url: '/reports/builder' },
    { type: 'ReportsWorkload', url: '/reports/workload' },
    { type: 'ReportsClient', url: '/reports/client/invoice' },
    { type: 'ReportsClientColl', url: '/reports/client/coll' },
    { type: 'ReportsVendor', url: '/reports/vendor' },
    { type: 'ReportsResponseIDApi', url: '/reports/responseid/api' },
    { type: 'ReportsResponseIDNav', url: '/reports/responseid/nav' },
    { type: 'Dashboard', url: '/dashboard' },
    { type: 'Home', url: '/home' },
    { type: 'HomeManager', url: '/home/manager' },
    { type: 'Account', url: '/account' }
  ];

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private alertify: AlertifyService,
    private instanceService: InstanceService) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {

    this.auth.login(this.model).subscribe(next => {
      if(next.user != null){
        this.alertify.success('You are now Logged In');
      }
      else {
        this.alertify.message('Please select a Workspace to access.');
        this.availableUsers = next.availableUsers;
      }
    }, error => {
      if(error.error == "NO_SUPPORT_ACCESS"){
        this.alertify.error('Log in fail. Request support access to be enabled.');
      }
      else {
        this.alertify.error('Log in failed. Please try again.');
      }
    }, () => {

    });
  }

}
