@if (comments) {
<div class="h-100 p-3 mt-2">
  <h3 class="w-100 nav-primary text-center">Comments</h3>
  <div class="filters btn-group w-100 mt-2">
    <button type="button" class="btn btn-sm border-0" [class.active]="statusFilter.indexOf('open') > -1"
      (click)="toggleFilter('open')">
      New @if (totalNewComments > 0 && !sessionFilterId && !questionId) {
      <span class="badge badge-pill ml-1 font-weight-normal new">{{totalNewComments}}</span>
      }
    </button>
    <button type="button" class="btn btn-sm border-0" [class.active]="statusFilter.indexOf('pending') > -1"
      (click)="toggleFilter('pending')">
      In Progress @if (totalPendingComments > 0 && !sessionFilterId && !questionId) {
      <span class="badge badge-pill ml-1 font-weight-normal pending">{{totalPendingComments}}</span>
      }
    </button>
    <button type="button" class="btn btn-sm border-0" [class.active]="statusFilter.indexOf('closed') > -1"
      (click)="toggleFilter('closed')">Closed</button>
  </div>
  @if (questionName) {
  <h5 class="badge badge-pill font-weight-normal text-white mt-3 mb-0 px-3 nav-bg-accent-blue text-uppercase">
    {{questionName}}
    <i class="fas fa-xs fa-close text-white ml-2" (click)="cleanFilters()"></i>
  </h5>
  }
  @if (sessionFilterName) {
  <h5 class="badge badge-pill font-weight-normal text-white mt-3 mb-0 px-3 nav-bg-accent-blue">
    {{sessionFilterName}}
    <i class="fas fa-xs fa-close text-white ml-2" (click)="cleanFilters()"></i>
  </h5>
  }
  @if (comments.length > 0) {
  <div class="scrollbar overflow-y mt-3" [class.filtered]="sessionFilterId || questionId">
    @for (item of comments; track item) {
    <div class="card mb-2 mr-2">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <h5 class="nav-secondary w-100 font-weight-bold">{{item.createdBy}}</h5>
          <div class="status">
            @if (item.status == 'open' && item.isNew) {
            <h5 class="badge badge-pill mx-2 px-3 my-0 font-weight-normal new">New</h5>
            }
            @if (item.status == 'pending') {
            <h5 class="badge badge-pill mx-2 px-3 my-0 font-weight-normal pending">Needs Approval</h5>
            }
            @if (item.status == 'approved') {
            <h5 class="badge badge-pill mx-2 px-3 my-0 font-weight-normal approved">Approved</h5>
            }
            @if (item.status == 'cancelled') {
            <h5 class="badge badge-pill mx-2 px-3 my-0 font-weight-normal cancelled">Cancelled</h5>
            }
          </div>
          <div class="btn-group d-block text-right" dropdown container="body">
            <span dropdownToggle type="button">
              <i class="fas fa-ellipsis-v or-secondary opacity-70 ml-1 fa-lg"></i>
            </span>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
              <li role="menuitem" class="p-2 cursor-pointer">
                <div class="btn px-3 py-1" (click)="viewSurveyPath(item)" data-commentid=0>
                  <i class="fak fa-survey fa-lg grey4"></i>
                  <span class="mx-2 align-self-center">View survey path</span>
                </div>
              </li>
              @if (item.status !== 'cancelled' && surveyStatus != 'finalized') {
              <hr class="nav-border1 mx-1 my-0">
              <li role="menuitem" class="p-2 cursor-pointer" (click)="confirm('cancel', item.id)">
                <div class="btn px-3 py-1">
                  <i class="fa-regular fa-circle-xmark fa-lg grey4"></i>
                  <span class="mx-2 align-self-center">Cancel comment</span>
                </div>
              </li>
              }
            </ul>
          </div>
        </div>
        <h6 class="mb-2 pb-3 text-muted">
          {{ item.commentType == 'client-change' ? 'Client change submitted ' + (item.createdDate | timeAgo) : 'Error
          submitted ' + (item.createdDate | timeAgo) }}
        </h6>
        <div class="row">
          <div class="col-4 pr-0">
            @if (item.questionText) {
            <span class="font-weight-bold cursor-pointer mb-2" (click)="jumpToQuestion(item)">Question:</span>
            }
          </div>
          @if (client==='decipher') {
          <div class="col">
            @if (item.questionId || item.anwerId) {
            <span class="font-weight-bold">
              ({{item.questionId.replace('question_','') | uppercase}})
            </span>
            }
            <span class="font-weight-normal">{{item.questionText}}</span>
          </div>
          }
          @if (client==='confirmit') {
          <div class="col">
            @if (item.questionId || item.anwerId) {
            <span class="font-weight-normal">
              ({{item.questionId | uppercase}})
            </span>
            }
            <span class="font-weight-normal">{{item.questionText}}</span>
          </div>
          }
        </div>
        @if (item.answerText) {
        <div class="row">
          <div class="col-4 pr-0">
            <span class="font-weight-bold cursor-pointer mb-2">Answer:</span>
          </div>
          <div class="col">
            <span class="font-weight-normal">{{item.answerText}}</span>
          </div>
        </div>
        }
        <div class="row">
          <div class="col-4 pr-0 d-flex">
            <span class="font-weight-bold mb-2">Comment:
            </span>
            @if(this.auth.getUser().id===item.createdById) {
            <i class="fak fa-edit nav-font14 ml-auto pt-1 grey4 cursor-pointer" (click)="updateComment(item)" tooltip="Edit"></i>
            }
          </div>
          <div class="col pl-2">
            @if (!openComment[item.id]){
            <span class="font-weight-normal comment-text" [innerHTML]="item.commentText"></span>
            }
            @if (item?.attachments.length > 0 && !openComment[item.id]) {
              <div class="attachments mt-3">
                @for (file of item?.attachments; track file; let idx = $index) {
                  <div class="file alert d-flex align-items-center justify-content-center" [ngClass]="(idx+1) === files?.length ? 'mb-3' : 'mb-1'" role="alert">
                    <i class="fa-light fa-paperclip-vertical nav-font14 font-weight-normal grey4"></i>
                    <span class="mx-2 grey5 text-truncate" [tooltip]="file.name" placement="bottom">{{file.name}}</span>
                    <i class="fak fa-download grey4 cursor-pointer ml-auto nav-font14 font-weight-normal" (click)="download(file)"></i>
                  </div>
                }
              </div>
            }

          </div>
        </div>
        @if (openComment[item.id]) {
        <div class="row">
          <div class="col-12 pr-0 ckeditor position-relative">
            <or-wysiwyg (messageEvent)="receiveMessageEditComment(item, $event)" (messageCount)="getCount(item, $event)"
              [config]="editorConfig"
              [data]="item?.editReplyText == null ? '' : item.editReplyText"></or-wysiwyg><br>
          </div>

          <div class="grid-open w-100 d-flex align-items-end justify-content-end">
            <div>
              <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold"
                (click)="this.openComment[item.id] = false;">Cancel</button>
            </div>
            <div>
              <button type="button" class="btn btn-sm nav-btn-accent shadow-none" (click)="saveComment(item)">
                Save Comment
              </button>
            </div>
          </div>
        </div>
        }


        <div class="card-footer actions text-right d-flex px-0 pb-0 mt-2">
          @if (surveyStatus != 'finalized') {
          @if (!openComment[item.id]) {
          <a class="btn btn-sm text-secondary d-flex align-items-center px-0"
            [ngClass]="{'text-secondary' : item.totalNewReplies == 0, 'text-success' : item.totalNewReplies > 0}"
            (click)="toggleReply(item)">
            <i class="fak fa-lg fa-messages d-block"></i>
            <span class="mx-2">{{item.replies?.length}}</span>
          </a>
          }
          @if (item.status == 'open') {
          <div class="grid-open w-100 d-flex align-items-end justify-content-end">
            <div>
              @if (!openComment[item.id]) {
              <span [tooltip]="item.totalNewReplies > 0 ? 'View unread replies' : ''" container="body">
                <button type="button" class="btn btn-sm shadow-none" [disabled]="item.totalNewReplies > 0 "
                  [ngClass]="{'st-btn-primary': item.totalNewReplies == 0, 'nav-btn-disabled': item.totalNewReplies > 0}"
                  (click)="confirm('sendForApproval', item.id)">Resolved
                </button>
              </span>
              }
            </div>
            <div>
              @if (!openComment[item.id]) {
              <button type="button" class="btn btn-sm nav-btn-accent shadow-none" (click)="viewMore(item)">
                {{ !openReply[item.id] ? 'Add Comment' : 'Cancel' }}
              </button>
              }
            </div>
          </div>
          }
          @if (item.status != 'open') {
          <div class="grid-open w-100 d-flex align-items-end justify-content-end">
            @if (item.status == 'pending') {
            <div class="d-flex align-items-center position-relative user">
              <span tooltip="Submitted for approval by {{item.sentForApprovalBy}}" placement="bottom" container="body">
                <or-profile-image [name]="item.sentForApprovalBy" size="small"></or-profile-image>
              </span>
            </div>
            }
            @if (item.status == 'approved') {
            <div class="d-flex align-items-center position-relative user">
              <span tooltip="Approved by {{item.approvedBy}}" placement="bottom" container="body">
                <or-profile-image [name]="item.approvedBy" size="small"></or-profile-image>
              </span>
            </div>
            }
            @if (item.status == 'cancelled') {
            <div class="d-flex align-items-center position-relative user">
              <span tooltip="Cancelled by {{item?.cancelledBy}}" placement="bottom" container="body">
                <or-profile-image [name]="item?.cancelledBy" size="small"></or-profile-image>
              </span>
            </div>
            }
            <div>
              <button type="button" class="btn btn-sm shadow-none"
                [ngClass]="{'st-btn-primary': item.totalNewReplies == 0, 'nav-btn-disabled': item.totalNewReplies > 0}"
                (click)="confirm('reopen', item.id)">Reopen
              </button>
            </div>
            @if (item.status == 'pending') {
            <div>
              <button type="button" class="btn btn-sm shadow-none"
                [ngClass]="{'nav-btn-accent': item.totalNewReplies == 0, 'nav-btn-disabled': item.totalNewReplies > 0}"
                (click)="confirm('approve', item.id)">Approve
              </button>
            </div>
            }
          </div>
          }
          }
        </div>
        @if (openReply[item.id]) {
        <div class="replies">
          <hr class="px-3 nav-border1 mx-1">
          @for (r of item?.replies; track r) {
          <div class="row text-right mt-2 m-1">
            <div class="col text-left">
              <div class="row">
                <div class="col px-0 text-left">
                  <span class="font-weight-bold nav-secondary">{{r.createdBy}}</span>
                </div>
                <div class="col-5 px-0 text-right">
                  <span class="text-muted">{{r.createdTime | timeAgo}}</span>
                </div>
              </div>
              <div class="row mt-2">
                <span [innerHTML]="r.replyText"></span>
              </div>
            </div>
          </div>
          <hr class="nav-border-white mx-1 my-0">
          }
          <div>
            <div class="ckeditor position-relative">
              <or-wysiwyg (messageEvent)="receiveMessage(item, $event)" (messageCount)="getCount(item, $event)"
                [config]="editorConfig" [data]="item?.replyText == null ? '' : item.replyText"></or-wysiwyg>
              <div class="save-reply cursor-pointer">
                <i class="fa-regular fa-paper-plane-top" (click)="saveReply(item)"></i>
              </div>
            </div>
            <div class="col mt-2 px-0">
              <div class="ckeditor position-relative">
                <h6 class="grey5">
                  <h6 class="d-inline">{{item?.commentLength || 0}}</h6> / <span
                    style="font-size: larger; font-weight: bolder;">∞</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
    }
  </div>
  }
  @if (comments.length == 0) {
  <div class="d-flex justify-content-center align-items-center h-75">
    @if (statusFilter.indexOf('open') > -1) {
    <h3 class="text-center grey2">
      <p>No new comments</p>
      <img class="img-empty-comments my-3" src="/assets/imgs/st-open-comments.png" />
    </h3>
    }
    @if (statusFilter.indexOf('pending') > -1) {
    <h3 class="text-center grey2">
      <p>No comments pending</p>
      <img class="img-empty-comments my-3" src="/assets/imgs/st-pending-comments.png" />
    </h3>
    }
    @if (statusFilter.indexOf('closed') > -1) {
    <h3 class="text-center grey2">
      <p>No closed comments</p>
      <img class="img-empty-comments my-3" src="/assets/imgs/st-closed-comments.png" />
      <p>Comments will appear once approved or cancelled.</p>
    </h3>
    }
  </div>
  }
</div>
}


<ng-template #modalConfirmApprove>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="openModalRef.hide();"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h3 class="font-weight-bold nav-secondary text-center">Are you sure you want to approve this comment?</h3>
    <h5 class="grey5 text-center d-block my-3">
      <i class="fak fa-info grey4 fa-md" (click)="openModalRef.hide();"></i>
      You can reopen a comment after it's approved.
    </h5>
    @if (isLive) {
    <h5 class="nav-error d-block">
      <i class="fak fa-warning fa-md" (click)="openModalRef.hide();"></i>
      This testing session is occurring while you are in live mode. The testing data may end up in the live dataset.
    </h5>
    }
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
        <input [(ngModel)]="notAskAgainApprove" type="checkbox" name="doNotAskAgain" id="doNotAskAgain">
        <label for="doNotAskAgain"></label>
        <small class="pl-4 grey4">Don't ask again</small>
      </div>
      <div class="col text-right px-0">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">Cancel</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="approve()">Approve</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalConfirmSentForApproval>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="openModalRef.hide();"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h3 class="font-weight-bold nav-secondary text-center">Are you sure you want to resolve this comment?</h3>
    @if (isLive) {
    <h5 class="nav-error d-block">
      <i class="fak fa-warning fa-md" (click)="openModalRef.hide();"></i>
      This testing session is occurring while you are in live mode. The testing data may end up in the live dataset.
    </h5>
    }
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
        <input [(ngModel)]="notAskAgainApproval" type="checkbox" name="doNotAskAgain" id="doNotAskAgain">
        <label for="doNotAskAgain"></label>
        <small class="pl-4 grey4">Don't ask again</small>
      </div>
      <div class="col text-right pr-0">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">No</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="sendForApproval()">Yes, Resolve</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalConfirmCancel>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="openModalRef.hide();"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h3 class="font-weight-bold nav-secondary text-center">Are you sure you want to cancel this comment?</h3>
    <h5 class="grey5 text-center d-block my-3">
      <i class="fak fa-info grey4 fa-md" (click)="openModalRef.hide();"></i>
      You can reopen a comment after it's cancelled.
    </h5>
    @if (isLive) {
    <h5 class="nav-error d-block">
      <i class="fak fa-warning fa-md" (click)="openModalRef.hide();"></i>
      This testing session is occurring while you are in live mode. The testing data may end up in the live dataset.
    </h5>
    }
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100">
      <div class="col text-right pr-0">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">No</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="cancel()">Yes, cancel</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalConfirmReopen>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="openModalRef.hide();"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h3 class="font-weight-bold nav-secondary text-center">Are you sure you want to reopen this comment?</h3>
    @if (isLive) {
    <h5 class="nav-error d-block my-3">
      <i class="fak fa-warning fa-md" (click)="openModalRef.hide();"></i>
      This testing session is occurring while you are in live mode. The testing data may end up in the live dataset.
    </h5>
    }
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
        <input [(ngModel)]="notAskAgainReopen" type="checkbox" name="doNotAskAgain" id="doNotAskAgain">
        <label for="doNotAskAgain"></label>
        <small class="pl-4 grey4">Don't ask again</small>
      </div>
      <div class="col text-right pr-0">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">No</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="reopen()">Yes, reopen</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalSurveyPath>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="openModalRef.hide();"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h3 class="font-weight-bold nav-secondary text-center">Comment Survey Path</h3>
    @if (activities.length > 0) {
    <div class="mt-4 scrollbar overflow-y" style="height: 550px">
      <div class="table-responsive bg-white">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="border-top-0 text-center align-middle border-right">
                <h5 class="grey5 font-weight-bold mb-0">Question</h5>
              </th>
              <th scope="col" class="border-top-0 text-center align-middle">
                <h5 class="grey5 font-weight-bold mb-0">Selected Answer</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            @for (item of activities; track item) {
            <tr>
              <td class="border-right text-center align-middle">
                <h5 class="grey5 mb-0">{{item.questionID | uppercase}}</h5>
              </td>
              <td class="align-middle">
                <h5 class="grey5 mb-0">{{item.answerID}} - {{item.answerText}}</h5>
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
    }
    @if (activities.length == 0) {
    <div class="col text-center mt-4">
      <h5>No activities.</h5>
    </div>
    }
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      <div class="col d-flex align-items-end justify-content-end">
        @if (activities.length > 0) {
        <button type="button" class="btn btn-sm nav-btn-accent">
          Export <i class="fak fa-export ml-1"></i>
        </button>
        }
      </div>
    </div>
  </div>
</ng-template>
