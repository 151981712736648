import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AlertifyService, AuthService, Project,FeasibilityService } from 'core';
import { ExportFilesService } from 'projects/core/src/services/exportFiles.service';

@Component({
  selector: 'app-project-download',
  templateUrl: './project-download.component.html',
  styleUrls: ['./project-download.component.scss']
})
export class ProjectDownloadComponent implements OnInit {

  @Input() public set data(data) {
    this.project = data?.project;
    this.bidNumber = data?.bidNumber || '';
    this.projectType = data?.projectType;
  }

  bidNumber = '';
  project = {} as Project;
  projectType: any;
  projectList: Array<string> = [];

  constructor(
    private alertify: AlertifyService,
    private feasibiltyService: FeasibilityService,
    public auth: AuthService,
    private exportFilesService: ExportFilesService) { }

  ngOnInit() { }

  // Imports and Exports
  download(type) {
    if (type === 'participant') this.downloadParticipantData(false);
    else if (type === 'duplicate') this.downloadDuplicateData();
    else if (type === 'cleanId') this.downloadCleanIdData();
    else if (type === 'closureExport') this.downloadParticipantData(true);
    else if (type === 'responseId') this.downloadRidSummaryData();
    else if (type === 'responseIdRaw') this.downloadRidRawData();
    else if (type === 'full') this.downloadFulldata();
  }

  downloadFulldata() { 
    let projectIdList = [];
    if (this.project != null) {
      let filename = 'Full-' + this.project.projectCode + '-' + formatDate(new Date(), 'MM-dd-yyyy HH:mm:ss', 'en').toString() + '.xlsx';
      projectIdList.push(this.project?.id);
      this.exportFilesService.fullReport(projectIdList, filename, '').subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
    } else {
      if (this.bidNumber != '') {
        let filename = 'Full-' + this.bidNumber + '-' + formatDate(new Date(), 'MM-dd-yyyy HH:mm:ss', 'en').toString() + '.xlsx';
        this.exportFilesService.fullReport(projectIdList, filename, this.bidNumber ).subscribe(url => {
          window.location.assign(url);
        }, error => {
          this.alertify.error(error);
        }, () => {
          // All Good
        });
      }
    }
    
    this.alertify.message('Your file will download once ready');
 
   
    
   
  }
  downloadRidRawData() {
    let filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '-ResponseIdRaw.xlsx';;
    this.alertify.message('Your file will download once ready');

      this.exportFilesService.downloadResponseIdRaw(this.project?.id, filename).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
   
  }
  downloadRidSummaryData() {
    let filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '-ResponseIdSummary.xlsx';;
    this.alertify.message('Your file will download once ready');

      this.exportFilesService.downloadResponseIdSummary(this.project?.id, filename).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
   
  }

  downloadParticipantData(isClosure: boolean) {
    let filename = '';
    this.alertify.message('Your file will download once ready');
    if (isClosure && this.bidNumber == '') this.projectList = this.project.closureDetails.projectList.split(",");
    else if (this.bidNumber == '') this.projectList = [this.project?.id];

    if (this.bidNumber == '') filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '.xlsx';
    else filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '- Bid Number - ' + this.bidNumber + '.xlsx';

    if (this.auth.isVendor() && !isClosure) {
      if (this.bidNumber == '') {
        this.exportFilesService.downloadVendorParticipantData(this.projectList, filename, this.auth.getUser().vendorId).subscribe(url => {
          window.location.assign(url);
        }, error => {
          this.alertify.error(error);
        }, () => { });
      } else if (this.bidNumber != '') {
        this.exportFilesService.downloadVendorParticipantDataSummary(this.bidNumber, filename, this.auth.getUser().vendorId).subscribe(url => {
          window.location.assign(url);
        }, error => {
          this.alertify.error(error);
        }, () => { });
      }
    } else {
      if (this.bidNumber == '') {
        this.exportFilesService.downloadParticipantData(this.projectList, filename, true).subscribe(url => {
          window.location.assign(url);
        }, error => {
          this.alertify.error(error);
        }, () => {
          // All Good
        });
      } else if (this.bidNumber != '') {
        this.exportFilesService.downloadParticipantDataSummary(this.bidNumber, filename, isClosure).subscribe(url => {
          window.location.assign(url);
        }, error => {
          this.alertify.error(error);
        }, () => {
          // All Good
        });
      }
    }
  }
  downloadDuplicateData() {
    let filename = '';
    this.alertify.message('Your file will download once ready');
    if (this.bidNumber == '') this.projectList = [this.project?.id];
    if (this.bidNumber == '') filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '-Dupes.xlsx';
    else filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '- Bid Number - ' + this.bidNumber + '-Dupes.xlsx';

    if (this.bidNumber == '') {
      this.exportFilesService.downloadDuplicateData(this.projectList, filename).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
    } else if (this.bidNumber != '') {
      this.exportFilesService.downloadDuplicateDataSummary(this.bidNumber, filename).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
    }
  }
  downloadCleanIdData() {
    let filename = '';
    this.alertify.message('Your file will download once ready');
    if (this.bidNumber == '') this.projectList = [this.project?.id];
    if (this.bidNumber == '') filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '-CleanId.xlsx';
    else filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '- Bid Number - ' + this.bidNumber + '-CleanId.xlsx';

    if (this.bidNumber == '') {
      this.exportFilesService.downloadCleanID(this.projectList, filename, false).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
    } else if (this.bidNumber != '') {
      this.exportFilesService.downloadCleanIDSummary(this.bidNumber, filename, false).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
    }
  }

  downloadPartnerCompletes() {
    this.alertify.message('Your file will download once ready');
    if (this.bidNumber == '') {
      const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '.zip';
      this.exportFilesService.downloadPartnerCompletes(this.project.id, filename, this.project.projectCode).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => { });
    } else if (this.bidNumber != '') {
      const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.bidNumber + '.zip';
      this.exportFilesService.downloadPartnerCompletesSummary(this.bidNumber, filename).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => { });
    }
  }

  downloadPartnerZip() {
    this.alertify.message('Your file will download once ready');
    if (this.bidNumber == '') {
      const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '.zip';
      this.exportFilesService.downloadPartnerParticipants(this.project.id, filename).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => { });
    } else if (this.bidNumber != '') {
      const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.bidNumber + '.zip';
      this.exportFilesService.downloadPartnerParticipantsSummary(this.bidNumber, filename).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => { });
    }
  }

  downloadByPartner(partner) {
    this.alertify.message('Your file will download once ready');
    if (this.bidNumber == '') {
      const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.project.projectCode + '-' + partner.partnerName + '.xlsx';
      this.exportFilesService.downloadParticipantsByPartner(this.project.id, partner.partnerId, filename).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => { });
    } else if (this.bidNumber != '') {
      const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this.bidNumber + '.xlsx';
      this.exportFilesService.downloadParticipantsByPartnerSummary(this.bidNumber, partner.partnerId, filename).subscribe(url => {
        window.location.assign(url);
      }, error => {
        this.alertify.error(error);
      }, () => { });
    }
  }

  get partnersList(): any { return this.project?.projectSegments[0]?.projectSurveyPartners; }
}
