<div class="">
    <div class="card-header row mx-0 mb-2 pt-3 pl-4">
        <div class="nav-secondary nav-font18 font-weight-bold pt-2">Match Variables</div>
        <div class="ml-auto">
            <span (click)="cancelMapping()" class="far fa-times grey4"></span>
        </div>
    </div>
    <div class="nav-font14 grey5 px-4">
        <div class="info-text-container">
            <span class="mb-2">
                We found a mismatch between your OE questions to review in Data Cleaning with what we have stored from the RID variables sent via API in your survey.
                If you have more OE Questions than RID Variables only match those sent via API in your survey.
            </span>
            <br>
            <span class="font-weight-bold">
                Drag and drop OE Variable to the correct RID Variable.
            </span>
        </div>
        <div class="row mx-0">
            <div [ngClass]="moreThan3RID ? 'col-4' : 'col-6'">
                <div class="variable-header">OE Variables</div>
            </div>
            <div [ngClass]="moreThan3RID ? 'col-8' : 'col-6'">
                <div class="variable-header">RID Variables</div>
            </div>
        </div>
        <div class="row mx-0 overflow-y scrollableContainer">
            <div class="d-flex justify-content-center first-col" [ngClass]="moreThan3RID ? 'col-4' : 'col-6'"
                dndDropzone dndDragoverClass="dragover" (dndDrop)="onDrop($event, '')">
                <div class="oe-variable-container">
                    @for (col of fileValueDetails; track $index) {
                        <div>
                            @if (!col.hasMapping) {
                                <div class="oe-variable mb-3" [dndDraggable]="col" [dragData]="col" (dndStart)="onDragStart($event)">
                                    <span class="variable-name">{{col.value}}</span>
                                </div>
                            }
                            @else if (col.value === valueBeingDragged){
                                <div class="oe-value-placeholder" dndPlaceholderRef dndDropzone (dndDrop)="onDrop($event, '')"></div>
                            }
                        </div>
                    }
                </div>
            </div>
            <div class="d-flex justify-content-center saecond-col" [ngClass]="moreThan3RID ? 'col-8 pr-0' : 'col-6'">
                <div class="rid-variable-container" [class.biggerSize]="moreThan3RID" >
                    @for (value of ridValues; track $index) {
                        <div class="rid-mapping" dndDragoverClass="dragover" dndDropzone (dndDrop)="onDrop($event, value)" [class.mb-3]="!moreThan3RID">
                            <div class="rid-variable">
                                <span class="variable-name">{{value}}</span>
                            </div>
                            @if (mapping[value]) {
                                <div class="oe-variable" [dndDraggable]="{value: mapping[value]}" (dndStart)="onDragStart($event)">
                                    <span class="variable-name">{{mapping[value]}}</span>
                                </div>
                            }
                            @else {
                                <div class="oe-value-placeholder"></div>
                             }
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer py-4">
        <div class="d-flex justify-content-end">
            <div class="btn nav-btn-primary mx-2 shadow-none" (click)="cancelMapping()">Cancel</div>
            @if (saving) {
                <span class="loading"></span>
            }
            <div class="btn nav-btn-accent shadow-none mx-2" (click)="saveMapping()">Save</div>
        </div>
    </div>
</div>