import { Component, OnInit } from '@angular/core';
import { AuthService, DropdownService, AlertifyService } from 'core';
import { ExportFilesService } from 'projects/core/src/services/exportFiles.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-cleanid-report',
  templateUrl: './cleanid-report.component.html',
  styleUrls: ['./cleanid-report.component.scss']
})
export class CleanidReportComponent implements OnInit {
  selectedProject = null;
  constructor(public auth: AuthService, private dropdownService: DropdownService, private alertify: AlertifyService, private exportFilesService: ExportFilesService) {

  }

  ngOnInit() {
  }
  projectSelected(project){
    this.selectedProject = (project == null || project.length !== 1) ? null : project[0];
  }
  getProjectList = (filter) => {
    return this.dropdownService.projects(filter, '', 'survey');
  }

  downloadCleanIDListFile(eventId:string){
    if(eventId == null) {
      this.alertify.error('Please type  an EventId or Event Name first.');
      return;
    }
    this.alertify.message('The download will start when ready.');
    const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + eventId + '.xlsx';
    this.exportFilesService.downloadCleanID(eventId, filename,true).subscribe(url => {
      window.location.assign(url);
    }, error => {
      this.alertify.error('There was a problem downloading this file');
   },
   () => {
      // All Good
   });

  }
}
