
<div class="card-body pb-0">
  <div class="row px-2 mb-2">
    <ng-container>
      <!-- <div class="col-3">
        <small class="text-nowrap grey4 d-block">{{ project.totalDaysLeft || 0}} {{project.totalDaysLeft == 1 ? 'Day' : 'Days'}}  Left</small>
      </div> -->
      <div class="col">
        <div class="rule">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              [ngClass]="getProgressStyle()"
              [style.width.%]="project.percentageComplete"
              aria-valuenow=""
              aria-valuemin="0"
              aria-valuemax="100">
              <div class="vl" [style.right.%]="project.percentageDaysLeft"></div>
            </div>
          </div>
        </div>
      </div>
    <div class="col-3  p-0 m-0">
      <small class="d-block grey4 text-center ">{{ project.complete || 0}}/{{ project.fullLaunchQuota || 0}} n</small>
    </div>
    </ng-container>



  </div>
  <div class="row mt-4 pt-2 pb-2 px-3 border-top footer">
    <div class="col-4">
      <div class="mt-2">
        <span>
          <or-profile-image [names]="projectManagerNames" showInitials="true" size="medium"></or-profile-image>
        </span>
      </div>
    </div>   
    <div class="col-4 px-0"   [ngStyle]="{'visibility': statusVisible ? 'visible' : 'hidden'}">
      <span class="nav-font14 mb-1 nav-secondary">Field status</span>
      <div class=" ml-auto text-left or-dropdown-status">
        <or-dropdown-status [status]="project.projectStatus" [projectId]="project.id" [disabled]="!auth.isInternal()"></or-dropdown-status>
      </div>
    </div>
    <div class="col-4 px-0 text-center">
    </div>
  </div>
</div>
