import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { HighchartsChartModule } from 'highcharts-angular';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { ComponentsModule, HelpersModule, DirectivesModule } from 'core';
import { ReportsModule } from '../reports/reports.module';

import { ProjectsComponent } from './projects.component';
import { ProjectFamilySummaryComponent } from './project-family-summary/project-family-summary.component';
import { ProjectGanttViewComponent } from './project-gantt-view/project-gantt-view.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectSetupComponent } from './project-setup/project-setup.component';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { ProjectCloseComponent } from './project-close/project-close.component';
import { ProjectCardInfieldComponent } from './project-card-infield/project-card-infield.component';
import { ProjectCardInfoComponent } from './project-card-info/project-card-info.component';
import { ProjectCardRelatedComponent } from './project-card-related/project-card-related.component';
import { ProjectCardQuotaComponent } from './project-card-quota/project-card-quota.component';
import { ProjectCardFieldPlanComponent } from './project-card-field-plan/project-card-field-plan.component';
import { ProjectCardSurveyPerformanceChartComponent } from './project-card-survey-performance-chart/project-card-survey-performance-chart.component';
import { ProjectFamilySurveyPerformanceTableComponent } from './project-family-survey-performance-table/project-family-survey-performance-table.component';
import { ProjectCardSurveyPerformanceTableComponent } from './project-card-survey-performance-table/project-card-survey-performance-table.component';
import { ProjectCardCleanidComponent } from './project-card-cleanid/project-card-cleanid.component';
import { ProjectCardIdSuiteSettingsComponent } from './project-card-idsuite-settings/project-card-idsuite-settings.component';
import { ProjectCardDedupeComponent } from './project-card-dedupe/project-card-dedupe.component';
import { ProjectAuditComponent } from './project-audit/project-audit.component';
import { ProjectAlertsComponent } from './project-alerts/project-alerts.component';
import { ProjectCardTeamComponent } from './project-card-team/project-card-team.component';
import { ProjectReconciliationComponent } from './project-reconciliation/project-reconciliation.component';
import { ProjectCardTestModeComponent } from './project-card-test-mode/project-card-test-mode.component';
import { ProjectCardClosedComponent } from './project-card-closed/project-card-closed.component';
import { ProjectCardSurveyComponent } from './project-card-survey/project-card-survey.component';
import { ProjectCardSurveySummaryComponent } from './project-card-survey-summary/project-card-survey-summary.component';
import { ProjectCardBidComponent } from './project-card-bid/project-card-bid.component';
import { ProjectCardDocumentsComponent } from './project-card-documents/project-card-documents.component';
import { ProjectCardFinanceComponent } from './project-card-finance/project-card-finance.component';
import { ProjectQuotaSetupComponent } from './../projects/project-quota/project-quota-setup/project-quota-setup.component';
import { ProjectQuotaMetricsComponent } from './../projects/project-quota/project-quota-metrics/project-quota-metrics.component';
import { ProjectIdSuiteComponent } from './../projects/project-id-suite/project-id-suite.component';
import { QCFlowColumnFilterComponent } from './../projects/project-id-suite/qc-flow-column-filter/qc-flow-column-filter.component';
import { NotesComponent } from './../notes/notes.component';
import { ScheduleComponent } from '../schedule/schedule.component';
import { ProjectThirdpartyStatusComponent } from './project-thirdparty-status/project-thirdparty-status.component';
import { ProjectCloseVendorEmailComponent } from './project-close-vendor-email/project-close-vendor-email.component';
import { ProjectLinksAndVendorsComponent } from './project-links-and-vendors/project-links-and-vendors';
import { RulesComponent } from '../rules/rules.component';
import { ProjectCardResponseIdComponent } from './project-card-response-id/project-card-response-id.component';
import { ProjectCardIdsuiteComponent } from './project-card-idsuite/project-card-idsuite.component';
import { ProjectEmailLogComponent } from './project-email-log/project-email-log.component';
import { ProjectCardVendorComponent } from './project-card-vendor/project-card-vendor.component'
import { ProjectCardIdSuiteResponseIdComponent } from './project-card-idsuite-responseid/project-card-idsuite-responseid.component';
import { ProjectCardAssetsComponent } from './project-card-assets/project-card-assets.component';
import { ProjectInfoComponent } from './project-info/project-info.component';
import { ProjectHomepageComponent } from './project-homepage/project-homepage.component';
import { ProjectHomepageDatesComponent } from './project-homepage/project-homepage-dates/project-homepage-dates.component';
import { ProjectHomepageSampleComponent } from './project-homepage/project-homepage-sample/project-homepage-sample.component';
import { ProjectHomepageTeamComponent } from './project-homepage/project-homepage-team/project-homepage-team.component';
import { ProjectHomepageClientComponent } from './project-homepage/project-homepage-client/project-homepage-client.component';
import { ProjectHomepageScheduleComponent } from './project-homepage/project-homepage-schedule/project-homepage-schedule.component';
import { ProjectHomepageFieldManagementComponent } from './project-homepage/project-homepage-field-management/project-homepage-field-management.component';
import { ProjectHomepageSurveyLinksVendorsComponent } from './project-homepage/project-homepage-survey-links-vendors/project-homepage-survey-links-vendors.component';
import { ProjectHomepageSurveyTestingComponent } from './project-homepage/project-homepage-survey-testing/project-homepage-survey-testing.component';
import { ProjectDownloadComponent } from './project-download/project-download.component';
import { MytaskComponent } from '../mytask/mytask.component';
import { ProjectProgrammingComponent } from './project-programming/project-programming.component';
import { ProjectCardReconciliationComponent } from './project-card-reconciliation/project-card-reconciliation.component';
import { ProjectVendorFinanceComponent } from './project-vendor-finance/project-vendor-finance.component';
import { CanDeactivateGuard } from '../../_guards/candeactivate.guard.service';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HelpersModule,
    DirectivesModule,
    BsDropdownModule,
    BsDatepickerModule,
    AccordionModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    ReportsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    HighchartsChartModule
  ],
  declarations: [
    ProjectsComponent,
    ProjectDetailsComponent,
    //ProjectBidComponent,
    ProjectCardInfieldComponent,
    ProjectSetupComponent,
    ProjectFamilySummaryComponent,
    ProjectGanttViewComponent,
    ProjectSettingsComponent,
    ProjectCloseComponent,
    ProjectCardInfoComponent,
    ProjectCardRelatedComponent,
    ProjectCardQuotaComponent,
    ProjectQuotaSetupComponent,
    ProjectQuotaMetricsComponent,
    ProjectIdSuiteComponent,
    QCFlowColumnFilterComponent,
    ScheduleComponent,
    ProjectCardFieldPlanComponent,
    ProjectCardSurveyPerformanceChartComponent,
    ProjectFamilySurveyPerformanceTableComponent,
    ProjectCardSurveyPerformanceTableComponent,
    ProjectCardCleanidComponent,
    ProjectCardIdSuiteSettingsComponent,
    ProjectCardIdsuiteComponent,
    ProjectCardIdSuiteResponseIdComponent,
    ProjectCardDedupeComponent,
    ProjectAuditComponent,
    ProjectCardTeamComponent,
    ProjectReconciliationComponent,
    ProjectCardResponseIdComponent,
    ProjectCardTestModeComponent,
    ProjectCardSurveyComponent,
    ProjectCardSurveySummaryComponent,
    ProjectCardBidComponent,
    ProjectCardClosedComponent,
    ProjectCardDocumentsComponent,
    ProjectCardAssetsComponent,
    NotesComponent,
    RulesComponent,
    ProjectThirdpartyStatusComponent,
    ProjectCardFinanceComponent,
    ProjectCloseVendorEmailComponent,
    ProjectLinksAndVendorsComponent,
    ProjectVendorFinanceComponent,
    ProjectEmailLogComponent,
    ProjectCardVendorComponent,
    ProjectInfoComponent,
    ProjectDownloadComponent,
    MytaskComponent,
    ProjectProgrammingComponent,
    ProjectCardReconciliationComponent,
    ProjectHomepageComponent,
    ProjectHomepageDatesComponent,
    ProjectHomepageTeamComponent,
    ProjectHomepageSampleComponent,
    ProjectHomepageClientComponent,
    ProjectHomepageScheduleComponent,
    ProjectHomepageFieldManagementComponent,
    ProjectHomepageSurveyLinksVendorsComponent,
    ProjectHomepageSurveyTestingComponent,
    ProjectAlertsComponent
  ],
  providers: [provideNgxMask(), CanDeactivateGuard]
})
export class ProjectsModule { }
