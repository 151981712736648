
<div class="card mt-5" style="max-width: 450px; margin:0 auto">
  <div class="card-header bg-secondary text-white">
    <h5>Reset Password</h5>
  </div>
  <div class="card-body">
    <form #forgotForm="ngForm"  (ngSubmit)="reset();" class="form-signin text-center">
      <div>
        <img alt="OpinionRoute NavigatOR" class="mb-5 w-100" src="/assets/imgs/OpinionRoute.jpg" />
      </div>
      <label for="inputEmail" class="sr-only">Email</label>
      <input type="email" id="inputEmail" class="form-control" name="email" placeholder="Email" [(ngModel)]="model.email" required="" autofocus="">
      <p class="text-right"><a [routerLink]="['/login']"><small class="or-secondary">Go Back</small></a></p>
      <button class="btn btn-lg btn-secondary btn-block mt-3" [disabled]="!forgotForm.valid" type="submit">Email Reset Link</button>
    </form>
  </div>
  <div class="card-footer text-center position-relative or-secondary-bg text-white">
    <small>© 2025 OpinionRoute, LLC. | All Rights Reserved.</small>
  </div>
</div>
