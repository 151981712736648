<div class="card my-2">
  <div class="card-header">
    <div class="row align-items-center">
      <div [class]="mode == 'reduced' ? 'col' : 'col-2'">
        <h3 class="nav-secondary font-weight-bold">Quotas</h3>
      </div>
      <div class="col text-right pr-0">
        @if (mode == 'expanded') {
          <span >view by &nbsp;</span>
        }
        @if (quotaDetails?.quotaGroups?.length > 0) {
          <div class="btn-group" role="group">
            <button type="button" (click)="setQuotaData('start')" class="btn {{ limitMode=='start' ? 'bg-secondary text-white' : 'bg-light border' }} {{mode == 'reduced' ? 'btn-xs' : 'btn-sm' }}">
              Starts
            </button>
            <button type="button" (click)="setQuotaData('complete')" class="btn {{ limitMode=='complete' ? 'bg-secondary text-white' : 'bg-light border' }} {{mode == 'reduced' ? 'btn-xs' : 'btn-sm' }}">
              Completes
            </button>
          </div>
        }
      </div>
      <div class="text-right pl-0" [class]="mode == 'reduced' ? 'col-3' : 'col-1'">
        <i (click)="openQuotaPage()" class="fas fa-cog fa-lg cursor-pointer"></i>
      </div>
    </div>
  </div>

  <!-- card body for when reduced mode-->
  @if (!_project.isQuotaEnabled && mode == 'reduced') {
    <div class="card-body">
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row my-2 nav-bg-primary">
        <div class="col p-3 text-center">
          <div class="row m-2">
            <div class="col">
              <i class="far fa-question-circle fa-2x or-secondary"></i>
            </div>
          </div>
          <div class="row m-2">
            <div class="col">
              <p class="font-weight-bold or-secondary">Quotas disabled.</p>
              <small class="d-block mb-3 or-secondary">
                Quotas are not enabled for this Project.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col"></div>
      </div>
    </div>
  }

  @if (_project.isQuotaEnabled && mode == 'reduced') {
    <div class="card-body scrollbar overflow-y">
      @if (!loading && quotaDetails?.quotaGroups?.length > 0 && (currentPage == null)) {
        <div class="row">
          <div class="col or-secondary text-center">
            <div class="h6">No Quota Data available.</div>
          </div>
        </div>
      }
      @if (quotaDetails?.quotaGroups?.length <= 0) {
        <div class="row">
          <div class="col or-secondary text-center">
            <div class="h6">No Quota Questions set up.</div>
          </div>
        </div>
      }
      @for (group of currentPage?.groups; track group) {
        <div class="mb-4">
          <div class="row mb-3">
            <div class="col">
              <div class="h6 or-title">{{ group.name }}</div>
            </div>
            <div class="col-3 text-center">
              @if (vendorLimitsEnabled || currentPage?.label == 'Overall') {
                <small class="font-weight-bold or-secondary opacity-70">{{ limitType | titlecase }}</small>
              }
              @if (!vendorLimitsEnabled && currentPage?.label != 'Overall') {
                <small class="font-weight-bold or-secondary opacity-70">Percent</small>
              }
            </div>
          </div>
          @for (quota of group.quotas; track quota) {
            <div class="row mb-3">
              @if (quota?.label != 'not asked' || currentPage?.label == 'Overall') {
                <div class="col-4">
                  @if (quota.label.length>24) {
                    <small class="text-nowrap d-block" tooltip="{{quota.label}}">{{ (quota.label  | slice:0:20)+'...'}}</small>
                  }
                  @if (quota.label.length<=24) {
                    <small class="text-nowrap d-block">{{quota.label}}</small>
                  }
                </div>
                <div class="col ml-3 pl-0">
                  <div class="rule">
                    <hr class="or-line" />
                    @if (quota?.label != 'not asked') {
                      <!-- enforced -->
                      @if (limitCheck == 'enforced') {
                        <!-- vendors enabled -->
                        @if (vendorLimitsEnabled || currentPage?.label == 'Overall') {
                          @if (limitType == 'number') {
                            @if (limitMode == 'start' && metrics.starts > 0) {
                              <or-progress-guage [value]="(quota.starts * 100) / quota.limit" [expected]="100" bgColor="bg-info" bgDanger="bg-secondary" mode="quota"></or-progress-guage>
                            }
                            @if (limitMode == 'complete' && metrics.completes > 0) {
                              <or-progress-guage [value]="(quota.completes * 100) / quota.limit" [expected]="100" bgColor="bg-info" bgDanger="bg-secondary" mode="quota"></or-progress-guage>
                            }
                          }
                          @if (limitType == 'percent') {
                            @if (limitMode == 'start' && metrics.starts > 0) {
                              <or-progress-guage [value]="(quota.starts * 100) / group.starts" [expected]="quota.limit" [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                            }
                            @if (limitMode == 'complete' && metrics.completes > 0) {
                              <or-progress-guage [value]="(quota.completes * 100) / group.completes" [expected]="quota.limit" [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                            }
                          }
                        }
                        <!-- vendors NOT enabled -->
                        @if (!vendorLimitsEnabled && currentPage?.label != 'Overall') {
                          @if (limitMode == 'start' && metrics.starts > 0) {
                            <or-progress-guage [value]="(quota.starts * 100) / group.starts"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                          }
                          @if (limitMode == 'complete' && metrics.completes > 0) {
                            <or-progress-guage [value]="(quota.completes * 100) / group.completes"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                          }
                        }
                      }
                      <!-- tracking -->
                      @if (limitCheck == 'tracking') {
                        <!-- vendors enabled -->
                        @if (vendorLimitsEnabled || currentPage?.label == 'Overall') {
                          @if (limitType == 'number') {
                            @if (limitMode == 'start' && metrics.starts > 0) {
                              <or-progress-guage [value]="(quota.starts * 100) / quota.limit" [expected]="100" bgColor="bg-info" bgDanger="bg-info" mode="quota"></or-progress-guage>
                            }
                            @if (limitMode == 'complete' && metrics.completes > 0) {
                              <or-progress-guage [value]="(quota.completes * 100) / quota.limit" [expected]="100" bgColor="bg-info" bgDanger="bg-info" mode="quota"></or-progress-guage>
                            }
                          }
                          @if (limitType == 'percent') {
                            @if (limitMode == 'start' && metrics.starts > 0) {
                              <or-progress-guage [value]="(quota.starts * 100) / group.starts" [expected]="quota.limit" [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                            }
                            @if (limitMode == 'complete' && metrics.completes > 0) {
                              <or-progress-guage [value]="(quota.completes * 100) / group.completes" [expected]="quota.limit" [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                            }
                          }
                        }
                        <!-- vendors NOT enabled -->
                        @if (!vendorLimitsEnabled && currentPage?.label != 'Overall') {
                          @if (limitMode == 'start' && metrics.starts > 0) {
                            <or-progress-guage [value]="(quota.starts * 100) / group.starts"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                          }
                          @if (limitMode == 'complete' && metrics.completes > 0) {
                            <or-progress-guage [value]="(quota.completes * 100) / group.completes"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                          }
                        }
                      }
                    }
                    <!-- tracking only -->
                    @if (limitCheck == 'tracking only' || quota?.label == 'not asked') {
                      @if (limitMode == 'start' && metrics.starts > 0) {
                        <or-progress-guage [value]="(quota.starts * 100) / group.starts"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                      }
                      @if (limitMode == 'complete' && metrics.completes > 0) {
                        <or-progress-guage [value]="(quota.completes * 100) / group.completes"  [bgColor]="getBgColor(group, quota)" [bgDanger]="getBgColor(group, quota)" mode="quota"></or-progress-guage>
                      }
                    }
                  </div>
                </div>
                <div class="col-3 px-0 text-center">
                  <small class="d-block or-secondary">
                    @if (quota?.label != 'not asked') {
                      <!-- enforced -->
                      @if (limitCheck == 'enforced') {
                        <!-- vendors enabled -->
                        @if (vendorLimitsEnabled || currentPage?.label == 'Overall') {
                          @if (limitType == 'number') {
                            @if (limitMode == 'start' && metrics.starts > 0) {
                              <strong>{{ quota.starts }}</strong> / {{ quota.limit < 99999 ? quota.limit : '*' }}
                            }
                            @if (limitMode == 'complete' && metrics.starts > 0) {
                              <strong>{{ quota.completes }}</strong> / {{ quota.limit < 99999 ? quota.limit : '*'}}
                            }
                          }
                          @if (limitType == 'percent') {
                            @if (limitMode == 'start' && metrics.starts > 0) {
                              <strong>{{ quota.starts / group.starts | percent }}</strong> / {{ quota.limit < 99999 ? quota.limit + '%': '*'}}
                            }
                            @if (limitMode == 'complete' && metrics.starts > 0) {
                              <strong>{{ quota.completes / group.completes | percent }}</strong> / {{ quota.limit < 99999 ? quota.limit + '%' : '*'}}
                            }
                          }
                        }
                        <!-- vendors NOT enabled -->
                        @if (!vendorLimitsEnabled && currentPage?.label != 'Overall') {
                          @if (limitMode == 'start' && metrics.starts > 0) {
                            <strong class="ml-2">{{ (quota.starts  / group.starts || 0) | percent }}</strong>
                          }
                          @if (limitMode == 'complete' && metrics.completes > 0) {
                            <strong  class="ml-2">{{ (quota.completes / group.completes || 0) | percent }}</strong>
                          }
                        }
                      }
                      <!-- tracking -->
                      @if (limitCheck == 'tracking') {
                        <!-- vendors enabled -->
                        @if (vendorLimitsEnabled || currentPage?.label == 'Overall') {
                          @if (limitType == 'number') {
                            @if (limitMode == 'start' && metrics.starts > 0) {
                              <strong>{{ quota.starts }}</strong> / {{ quota.limit < 99999 ? quota.limit : '*'}}
                            }
                            @if (limitMode == 'complete' && metrics.completes > 0) {
                              <strong>{{ quota.completes }}</strong> / {{ quota.limit < 99999 ? quota.limit : '*'}}
                            }
                          }
                          @if (limitType == 'percent') {
                            @if (limitMode == 'start' && metrics.starts > 0) {
                              <strong>{{ quota.starts / group.starts | percent }}</strong> / {{ quota.limit < 99999 ? quota.limit + '%' : '*'}}
                            }
                            @if (limitMode == 'complete' && metrics.completes > 0) {
                              <strong>{{ quota.completes / group.completes | percent }}</strong> / {{ quota.limit < 99999 ? quota.limit + '%': '*'}}
                            }
                          }
                        }
                        <!-- vendors NOT enabled -->
                        @if (!vendorLimitsEnabled && currentPage?.label != 'Overall') {
                          @if (limitMode == 'start' && metrics.starts > 0) {
                            <strong class="ml-2">{{ (quota.starts  / group.starts || 0) | percent }}</strong>
                          }
                          @if (limitMode == 'complete' && metrics.completes > 0) {
                            <strong  class="ml-2">{{ (quota.completes / group.completes || 0) | percent }}</strong>
                          }
                        }
                      }
                    }
                    @if (limitCheck == 'tracking only' || quota?.label == 'not asked') {
                      @if (limitMode == 'start' && metrics.starts > 0) {
                        <strong class="ml-2">{{ (quota.starts  / group.starts || 0) | percent }}</strong>
                      }
                      @if (limitMode == 'complete' && metrics.completes > 0) {
                        <strong  class="ml-2">{{ (quota.completes / group.completes || 0) | percent }}</strong>
                      }
                    }
                  </small>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  }
  @if (quotaDetails?.quotaGroups?.length > 0) {
    <div class="card-footer py-2 or-title">
      @if (_project.isQuotaEnabled && mode == 'reduced') {
        <div class="row d-flex">
          <div class="col-3 text-right">
            <i (click)="changePage('-')" class="fas fa-arrow-left fa-lg cursor-pointer or-title opacity-90"></i>
          </div>
          <div class="col">
            <div class="text-center">
              <span class="h6 or-title">{{currentPage?.label}}</span>
            </div>
          </div>
          <div class="col-3 text-left">
            <i (click)="changePage('+')" class="fas fa-arrow-right fa-lg cursor-pointer or-title opacity-90"></i>
          </div>
        </div>
      }
    </div>
  }

</div>
