import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ClosureService } from 'projects/core/src/services/closure.service';

@Component({
  selector: 'app-project-homepage-closure',
  templateUrl: './project-homepage-closure.component.html',
  styleUrls: ['./project-homepage-closure.component.scss']
})
export class ProjectHomepageClosureComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.getClosureDetails();
  }

  @Output() callback = new EventEmitter();

  _project:any = {};
  closureDetails: any;
  closureComplete = false;
  steps:any = {}
  percent:any = 0;

  constructor(private closureService: ClosureService) { }

  ngOnInit() {
  }

  getClosureDetails() {
    this.closureService.GetClosureDetails(this._project.id).subscribe(data => {
      this.processDetails(data);
    }, error => {
    });
  }

  processDetails(data) {
    let closureDetails = data;

    if (closureDetails.invoiceDate != null && typeof closureDetails.invoiceDate === 'string') {
      closureDetails.invoiceDate = new Date(closureDetails.invoiceDate);
    }
    if (closureDetails.billingDate != null && typeof closureDetails.billingDate === 'string') {
      closureDetails.billingDate = new Date(closureDetails.billingDate);
    }
    // Default Invoice and Billing Number
    // TO DO: This is quite specific to OpinionRoute so may need way to configure this
    if (closureDetails.invoiceNum == null || closureDetails.invoiceNum === '') {
      closureDetails.invoiceNum = this._project.bidNumber + 'I-1';
    }
    if (closureDetails.billingNum == null || closureDetails.billingNum === '') {
      closureDetails.billingNum = 'PB_' + this._project.bidNumber;
    }

    // If invoiced mark true
    this.closureComplete = (this._project.projectStatus === 'Invoiced' || closureDetails.step7CompletedBy != null);

    // Mark false even if invoiced to allow for a confirm
    if (closureDetails.step4CompletedBy === null) this.closureComplete = false; // Vendor Pricing
    if (closureDetails.step5CompletedBy === null) this.closureComplete = false; // Margin

    this.closureDetails = closureDetails;
    this.getStepStatus(this.closureDetails);
    this.calculateCompletionPercentage(this.closureDetails);
  }

  getStepStatus(data: any) {
    const steps = [
        { key: "step1CompletedBy", label: "Upload final completes" },
        { key: "step2CompletedBy", label: "Reconcile IDs" },
        { key: "step3CompletedBy", label: "Client Pricing" },
        { key: "step4CompletedBy", label: "Vendor Pricing" },
        { key: "step5CompletedBy", label: "Final margin review" },
        { key: "step7CompletedBy", label: "Invoice" },
    ];

    let currentStep: string | null = null;
    let upcomingStep: string | null = null;

    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      if (data[step.key] === null) {
          if (!currentStep) {
              currentStep = step.label;
          } else if (!upcomingStep) {
              upcomingStep = step.label;
              break;
          }
      }
    }

    if (!currentStep && !upcomingStep) {
        currentStep = steps[steps.length - 1].label;
    }
    this.steps = { currentStep, upcomingStep };
  }

  calculateCompletionPercentage(data: any) {
    const stepKeys = [
        "step1CompletedBy",
        "step2CompletedBy",
        "step3CompletedBy",
        "step4CompletedBy",
        "step5CompletedBy", //step6 obsolete
        "step7CompletedBy"
    ];

    const completedSteps = stepKeys.filter((key) => data[key] !== null).length;
    const percentage = (completedSteps / stepKeys.length) * 100;
    this.percent = percentage.toFixed(0);
  }

  openClosure() {
    this.callback.emit({section: 'close', type: null});
  }

}
