<div class="nav-content container col-6">
  <form [formGroup]="changePasswordForm" (ngSubmit)="submitChangePassword()" class="form-signin  mt-5 text-center">
    <h1 class="h3 mb-3 font-weight-normal">Change Password</h1>

    <div class="form-group">
      <input type="password" class="form-control" [ngClass]="submitted && utils.showCssValidField(changePasswordForm, 'currentPassword')" placeholder="Current Password" id="currentPassword" formControlName="currentPassword">
    </div>

    <div class="form-group">
      <input type="password" class="form-control" [ngClass]="submitted && utils.showCssValidField(changePasswordForm, 'password')" placeholder="New Password" id="password" formControlName="password">
      @if (changePasswordForm.get('password').hasError('passwordComplexity')) {
        <div class="invalid-feedback">Password does not match complexity rules</div>
      }
    </div>

    <div class="form-group">
      <input type="password" id="confirmPassword" formControlName="confirmPassword" class="form-control" placeholder="Confirm New Password"
        [ngClass]="{'is-invalid': changePasswordForm.get('confirmPassword').errors && changePasswordForm.get('confirmPassword').touched ||changePasswordForm.get('confirmPassword').touched &&changePasswordForm.hasError('mismatch')}">

        @if (changePasswordForm.hasError('mismatch') && changePasswordForm.get('confirmPassword').touched) {
          <div class="invalid-feedback">Passwords must match</div>
        }
      </div>


      <button class="btn btn-lg btn-primary btn-block" type="submit">Update Password</button>

      <div class="or-primary mt-5"> Password must be at least 6 characters and contain an uppercase and lowercase character, a digit, and a non-alphanumeric character</div>

      <p class="mt-5 mb-3 text-muted">©2024</p>
    </form>
  </div>

