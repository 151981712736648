<div class="card">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-clients fa-xl mr-2 grey4 font-weight-normal"></i>Client</span>
    <div class="float-right">
      <div class="row align-items-center">
        <div class="col d-flex align-items-center">
          @if (clientView) {
            <div class="cursor-pointer pr-2" (click)="openClientView()">
              <i class="nav-font14 fa-light fa-eye mr-2 grey4"></i>
              <span class="nav-font14 grey5 font-weight-bold nav-link-accent">Open client's view</span>
            </div>
          }
          <div class="pl-2" [class.border-left]="clientView">
            <i class="fas fa-arrows-maximize fa-md grey4 font-weight-normal float-right cursor-pointer" (click)="openSettings('client')"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body nav-font14 pt-2">
    <div class="row align-items-start">
      <div class="col-5 grey5 nav-font14 font-weight-bold pr-0">
        <span class="">Client</span>
      </div>
      <div class="col nav-font14 pl-0 nav-accent-blue">
        @if (!_project.clientName) {
          <span class="d-block cursor-pointer nav-accent-blue font-weight-bold" (click)="openSettings('client')">
            <i class="fas fa-plus font-weight-normal mr-1"></i>Add
          </span>
        } @else {
          <span class="cursor-pointer" [routerLink]="['/clients', _project?.clientId]">{{_project.clientName}}</span>
        }
      </div>
    </div>
    <div class="row align-items-start mt-3">
      <div class="col-5 grey5 nav-font14 font-weight-bold pr-0">
        <span class="">Point of contact</span>
      </div>
      <div class="col nav-font14 pl-0">
        @if (!_project.clientContactName && !_project.clientContactEmail) {
          <span class="d-block cursor-pointer nav-link-accent font-weight-bold" (click)="openSettings('client')">
            <i class="fas fa-plus font-weight-normal mr-1"></i>Add
          </span>
        } @else {
          <span class="d-block grey5">{{_project?.clientContactName}}</span>
          @if (_project?.clientContactEmail) {
            <div class="d-flex align-items-center">
              <span class="d-inline-block nav-accent-blue text-truncate" style="width: 130px;">{{_project?.clientContactEmail}}</span>
              <i class="far fa-copy mr-2 grey4 font-weight-normal ml-3 cursor-pointer" (click)="copyToClipboard(_project?.clientContactEmail)"></i>
            </div>
          }
        }
      </div>
    </div>
    <div class="row align-items-start mt-3">
      <div class="col-5 grey5 nav-font14 font-weight-bold pr-0">
        <span class="">Project number</span>
      </div>
      <div class="col nav-font14 pl-0 grey5">
        @if (!_project.clientPo) {
          <span class="d-block cursor-pointer nav-link-accent font-weight-bold" (click)="openSettings('client')">
            <i class="fas fa-plus font-weight-normal mr-1"></i>Add
          </span>
        } @else {
          <span class="">{{_project.clientPo}}</span>
        }
      </div>
    </div>
  </div>
</div>
