import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CleanidService, ReportsService, AlertifyService, Project, AuthService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-project-card-idsuite-settings',
  templateUrl: './project-card-idsuite-settings.component.html',
  styleUrls: ['./project-card-idsuite-settings.component.scss']
})
export class ProjectCardIdSuiteSettingsComponent implements OnInit {

  @Input() public set project(data: Project) {
    this._project = JSON.parse(JSON.stringify(data));
  }
  @Input() clientMode: boolean = false;

  @ViewChild('myIframe') public myIframe;
  iframeUrl = '';

  _project;
  section = 'CleanID';

  unsavedSettings = false;
  reportLoading = false;
  previewingReport = false;

  constructor(
    private cleanidService: CleanidService,
    private alertify: AlertifyService,
    private reportService: ReportsService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    public auth: AuthService) { }

  ngOnInit() {
    this.loadSection('CleanID');
  }


  loadSection(section) {
    this.section = section;
    let reportId = this.section === 'CleanID' ? (this.clientMode)? '178': '175' : '139';
    let projectIdentifier = this.section === 'CleanID' ? this._project.projectCode : this._project.id.toUpperCase();
    this.reportService.GenerateMetabaseEmbedTokenProject(reportId, projectIdentifier).subscribe( data => {
      this.iframeUrl = data;
      const iframe = this.myIframe.nativeElement;
      if (iframe != null) {
        iframe.src = this.iframeUrl;
      }
     }, error => {
 
     });

  }
}
