<div class="card">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-vendors fa-xl mr-2 grey4 font-weight-normal"></i>Survey Links & Vendors</span>
    <i class="fa-light fa-square-arrow-right fa-lg go-to-page-icon float-right cursor-pointer" (click)="openLinksVendors()"></i>
  </div>
  <div class="card-body nav-font14 pt-2">
    <div class="row align-items-start">
      <div class="col nav-font14 nav-accent-blue">
        @if (!summary.totalLinks && !summary.totalVendors) {
          <span class="d-block grey5 nav-font16 mb-3">
            Links and Vendors have not been added to this project.
          </span>
          <span class="d-block nav-font14 font-weight-bold nav-link-accent cursor-pointer" style="text-decoration: underline;" (click)="openLinksVendors()">Click here to add</span>
        } @else {
          <div class="row nav-font14">
            <div class="col pr-0">
              <span class="nav-secondary d-flex align-items-center">Survey Links
                <span class="grey4 mx-1">({{summary.totalLinks}})</span>
                <i class="fak nav-font16" [ngClass]="summary.totalLinks == 2 ? 'fa-message-success nav-success' : 'fa-message-warning nav-error'" [tooltip]="getTooltip()" container="body" placement="bottom"></i>
              </span>
            </div>
            @if (!summary.totalLinks) {
              <div class="col pl-0">
                <span class="d-block nav-font14 font-weight-bold nav-link-accent cursor-pointer" style="text-decoration: underline;" (click)="openLinksVendors()">Click here to add links</span>
              </div>
            }
          </div>
          <hr class="my-3">
          <div class="row nav-font14">
            <div class="col pr-0">
              <span class="nav-secondary">Vendors
                <span class="grey4 mr-1">({{summary.totalVendors}})</span>
              </span>
            </div>
            @if (!summary.totalVendors) {
              <div class="col pl-0">
                <span class="d-block nav-font14 font-weight-bold text-nowrap nav-link-accent cursor-pointer" style="text-decoration: underline;" (click)="openLinksVendors()">Click here to add vendors</span>
              </div>
            }
          </div>

          @if (summary.totalVendors) {
            @for (vendor of getVendors; track vendor; let idx = $index) {
              <div class="row my-2 nav-font14">
                <div class="col pr-0 d-flex align-items-center">
                  <span class="nav-accent-blue d-flex align-items-center">{{vendor.partnerName}}
                    <i class="fak nav-font16 mx-1" [ngClass]="vendor.totalLinks == 4 ? 'fa-message-success nav-success' : 'fa-message-warning nav-error'"
                      [tooltip]="getTooltipVendor(vendor)"
                      placement="bottom"
                      data-html="true"
                      container="body"></i>
                  </span>
                </div>
                <div class="col pl-0 nav-black2 or-dropdown-status d-flex justify-content-center">
                  <or-dropdown-status
                    [values]="['Test', 'Soft Launch', 'Full Launch', 'Paused', 'Closed']"
                    [status]="vendor.partnerStatus"
                    [disabled]="true"
                    [partnerId]="vendor.partnerId"
                    [projectId]="_project.id"
                    [surveyLink]="_project?.projectSegments[0]?.surveyLink">
                  </or-dropdown-status>
                </div>
              </div>
            }
            @if (summary.totalVendors > 5) {
              <div class="row mt-3 nav-font14">
                <div class="col text-right">
                  <span class="d-block nav-font14 font-weight-bold text-nowrap nav-link-accent cursor-pointer" style="text-decoration: underline;" (click)="showAllVendors = !showAllVendors">
                    {{ showAllVendors ? 'View less' : 'View all' }}
                  </span>
                </div>
              </div>
            }
          }
        }
      </div>
    </div>
  </div>
</div>
