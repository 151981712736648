<div class="container-fluid nav-content">
  <!-- BREADCRUMBS -->
  <div class="row mb-3">
    <div class="col-12 breadcrumb mr-auto bg-transparent">
      <div class="d-flex align-items-center">
        <div class="grey4 d-flex align-items-center">
          <app-pagetitle [subPageOpen]="true"></app-pagetitle>
          <div class="">
            <span class="nav-font14 font-weight-bold nav-secondary text-uppercase cursor-pointer">
              {{ partner.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3 mx-0">
      <div class="col d-flex align-items-center">
        <or-profile-image [url]="partner.img" [name]="partner.name" size="medium"
          showInitials="true"></or-profile-image>
        <span class="nav-font24 grey6 ml-2">{{ partner.name }}</span>
      </div>
    </div>
    @if (partner) {
    <div class="container-fluid partner-details mt-3 mx-3">
      <div class="col   d-flex justify-content-end">
        <div class="position-absolute mt-2">
          <span type="button" class="shadow-none grey4 nav-font14 font-weight-normal mr-2" (click)="copyAllRedirects()">
            <i class="mr-2 cursor-pointer fak fa-copy"></i>Copy redirect URLs
          </span>
          <i class="far grey1 fa-thin fa-lg fa-pipe cursor-default"></i>
          <span type="button" class="shadow-none grey4 nav-font14 font-weight-normal ml-2"
            (click)="partnerEdit(this.partner)">
            <i class="mr-2 cursor-pointer fak fa-edit"></i>Edit Vendor
          </span>
        </div>
      </div>
      <div>
        <hr>
        <tabset #tabSet>
          <tab tabOrder="1" heading="Projects ({{ (projects == null) ? '-': projects.length }})">
            <div class="row my-4">
              <div class="col d-flex align-items-center">
                <div class="input-group copy-search">
                  <div class="input-group-prepend">
                    <div class="input-group-text border-right-0">
                      <i class="fas fa-search cursor-default"></i>
                    </div>
                  </div>
                  <input [(ngModel)]="filterText" type="search" class="form-control form-control-sm"
                    (ngModelChange)="updateProjects()" placeholder="Search project">
                </div>
              </div>
              <!--<div class="col filters d-flex justify-content-end px-4 my-2">
                    <or-filter [values]="filterValues" position="left"></or-filter>
                  </div>-->
            </div>
            @if (projects?.length>0) {
            <div class="my-3">
              <or-list [items]="projects" [layout]="columns" rowid="id" [actions]="rowActions"
                (action)="handleAction($event)" clickroute="/projects"></or-list>
            </div>
            }
          </tab>
          <tab tabOrder="2" heading="Contacts ({{ (partnerContacts == null) ? '-': partnerContacts.length }})">
            <div class="row my-4">
              <div class="col d-flex align-items-center">
                <button class="btn nav-btn-accent shadow-none font-weight-bold mr-3 nav-font14"
                  (click)="contactAdd()">Add Contact</button>
                <div class="input-group copy-search">
                  <div class="input-group-prepend">
                    <div class="input-group-text border-right-0">
                      <i class="fas fa-search cursor-default"></i>
                    </div>
                  </div>
                  <input [(ngModel)]="filterContacts" type="search" class="form-control form-control-sm"
                    (ngModelChange)="updateContacts()" placeholder="Search contact">
                </div>
              </div>
              <div class="col d-flex justify-content-end px-4 my-2">
              </div>
            </div>
            <div class="scrollbar overflow-x" width="100%">
              <table class="table contacts table-hover table-light nav-font14 familyTable mb-0">
                <thead class="grey5">
                  <tr>
                    <th width="260" (click)="SortBy('name')"><span class="ml-3">Contact Name </span><i
                        class="fas fa-sm or-secondary fa-sort"></i></th>
                    <th width="260" (click)="SortBy('title')">Job Title <i class="fas fa-sm or-secondary fa-sort"></i>
                    </th>
                    <th width="200" (click)="SortBy('phone')">Phone Number <i
                        class="fas fa-sm or-secondary fa-sort"></i></th>
                    <th width="150" (click)="SortBy('email')">E-mail <i class="fas fa-sm or-secondary fa-sort"></i></th>
                    <th width="150">Vendor Portal Access </th>
                    <th width="150">Last Active</th>
                    <th width="72" class=" text-center">Options </th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  @for (contact of partnerContacts; track contact) {
                  <ng-container class="row">
                    <tr>
                      <td>
                        <div class="row">
                          <div class="col ml-3 d-flex align-items-center">
                            <or-profile-image showOnHover="true" [name]="contact.name" [url]="contact?.image"
                              showInitials="true"></or-profile-image>
                            <span class="p-0 ml-3 text-truncate grey5 d-inline-block">{{ contact.name }}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <span class="p-0 text-truncate grey5">{{ contact.title }}</span>
                      </td>
                      <td>
                        <span class="p-0 text-truncate grey5">{{ contact.fullPhone }}</span>
                      </td>
                      <td>
                        <span class="p-0 text-truncate grey5">{{ contact.email }}</span>
                      </td>
                      <td>
                        <span class="p-0 text-truncate grey5">{{ contact.portalAccess }}</span>
                      </td>
                      <td>
                        <span class="p-0 text-truncate grey5">{{ contact.lastLogin | date: 'MMM d, y' }}</span>
                      </td>
                      <td class=" text-center">
                        <div class="btn-group btn-group-sm" dropdown container="body">
                          <button dropdownToggle type="button" class="btn btn-outline-none" controls="dropdown-basic">
                            <i class="fak fa-lg fa-ellipsis-vertical grey4 nav-font18"></i>
                          </button>
                          <div *dropdownMenu class="dropdown-menu dropdown-menu-right cursor-pointer" role="menu"
                            aria-labelledby="button-basic">
                            <a class="dropdown-item" role="menuitem" (click)="contactEdit(contact)">Edit contact</a>
                            <a class="dropdown-item" role="menuitem" (click)="contactDelete(contact)">Delete contact</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  }
                </tbody>
              </table>
            </div>
          </tab>
          <tab tabOrder="3" heading="Notes ({{ (notes == null) ? '-': notes.length }}) "
            (selectTab)="multiLineOverflows()">
            <div class="">
              <div class="">
                <div class="my-4">
                  <div class=" px-3 row">
                    <div class="d-flex align-items-start mx-3">
                      <button (click)="addNote()" type="button"
                        class="btn nav-btn-accent shadow-none px-2 mr-3 nav-font14 text-nowrap font-weight-bold"
                        [disabled]="newNote != null"
                        [ngClass]="newNote == null && editingNote == null ? '' : 'disabled'">
                        Add Note
                      </button>
                      <div class="input-group copy-search">
                        <div class="input-group-prepend">
                          <div class="input-group-text border-right-0">
                            <i class="fas fa-search cursor-default"></i>
                          </div>
                        </div>
                        <input [(ngModel)]="filterNotes" type="search" class="form-control form-control-sm"
                          [class.disabled]="newNote || editingNote" (ngModelChange)="updateNotes(partner.allNotes)"
                          placeholder="Search keyword">
                      </div>
                    </div>
                    <div class="col d-flex align-items-center justify-content-end pr-0">
                      <div>
                        @for (subject of noteFilterValues; track subject) {
                        <div
                          class="ml-1 mr-2 subjectPill text-nowrap d-inline-block cursor-pointer nav-font14 align-items-center mb-2"
                          [class.disabled]="(newNote || editingNote || partner.allNotes?.length == 0)"
                          [class.subject-filter-selected]="subject.selected"
                          (click)="subject.selected = !subject.selected; applyFilterNotes()">{{subject.label}}
                          @if (subject.selected) {
                          <i class="far fa-times nav-font12 grey4 ml-2"></i>
                          }
                        </div>
                        }
                      </div>
                    </div>
                    <div class="d-inline align-items-center justify-content-end ml-auto">
                      <span class="fak fa-download grey4 px-2" (click)="exportNotes(notes)"
                        [ngClass]="(newNote == null && partner.allNotes?.length > 0 && editingNote == null) ? 'cursor-pointer' : 'disabled'"></span>
                    </div>
                  </div>
                  <div class="px-3 my-4">
                    @if (notes?.length == 0 && newNote == null) {
                    <p class="grey4 nav-font14">No notes available for this vendor.</p>
                    }
                    <!-- new note card  -->
                    @if (newNote != null) {
                    <div class="card mb-3">
                      <div class="card-header">
                        <div class="row py-1">
                          <div class="col">
                            <div class="nav-secondary font-weight-bold nav-font18">
                              Add Note
                            </div>
                          </div>
                          <div class="col">
                            <div class="nav-secondary font-weight-bold nav-font18 float-right">
                              <i class="fas fa-times grey4" (click)="cancelNewNote()"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body pt-1">
                        <div class="row py-1 mb-1">
                          <div class="col-5 d-flex align-items-center subjectDropdown mb-1"
                            [class.placeholder-text]="!newNote.subject">
                            <div class="grey4 font-weight-bold nav-font14 text-nowrap ">
                              Subject
                            </div>
                            <or-dropdown-list class="" noSelectionText="Select subject" showWhenClosed="true"
                              createEnabled="true" createText="Add new subject" [values]="subjects"
                              [value]="newNote.subject" (selected)="applySubject($event, newNote)"
                              (created)="createSubject($event)"></or-dropdown-list>
                          </div>
                        </div>
                        <div class="row py-1">
                          <div class="col-5 d-flex align-items-center ">
                            <div class="grey4 font-weight-bold nav-font14 text-nowrap">
                              Note Title
                            </div>
                            <input [(ngModel)]="newNote.title"
                              class="form-control-nav form-control-sm mx-3 w-100 note-title"
                              placeholder="Type note title">
                          </div>
                        </div>
                        <div class="row py-1">
                          <div class="col-12 my-3 grey5 nav-font14">
                            <or-groupnotes-wysiwyg [data]="newNote.body"
                              (messageEvent)="newNote.body = $event"></or-groupnotes-wysiwyg>
                          </div>
                        </div>
                        <div class="row py-1">
                          <div class="px-3 pt-1 lookupTableContainer">
                            @if (newNote.showTags != true) {
                            <span class="font-weight-bold nav-accent-blue nav-font14" type="button"
                              (click)="newNote.showTags = true"><i class="far fa-plus"></i> &nbsp; Add tag</span>
                            }
                            @if (newNote.showTags == true) {
                            <or-lookup-table (changed)="addTags(newNote, $event)" type="vendor-note-tag"
                              [selectedValuesString]="newNote.tagIds"
                              placeholder="Search tag or type and press enter to create a tag"></or-lookup-table>
                            }
                          </div>
                          <div class="col ml-auto text-right d-inline">
                            <button type="button" class="btn btn-sm nav-btn-primary mx-1 shadow-none py-1 px-3"
                              (click)="cancelNewNote()">Cancel </button>
                            <button type="button" class="btn btn-sm nav-btn-accent shadow-none py-1 px-3"
                              (click)="createNewNote()">Save </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    <!-- edit note card  -->
                    @if (editingNote != null) {
                    <div class="card mb-3">
                      <div class="card-header">
                        <div class="row py-1">
                          <div class="col">
                            <div class="nav-secondary font-weight-bold nav-font18">
                              Edit Note
                            </div>
                          </div>
                          <div class="col">
                            <div class="nav-secondary font-weight-bold nav-font18 float-right">
                              <i class="fas fa-times grey4" (click)="cancelEditNote()"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body pt-1">
                        <div class="row py-1 mb-1">
                          <div class="col-5 d-flex align-items-center subjectDropdown mb-1"
                            [class.placeholder-text]="!editingNote.subject">
                            <div class="grey4 font-weight-bold nav-font14 text-nowrap ">
                              Subject
                            </div>
                            <or-dropdown-list class="" noSelectionText="Select subject" showWhenClosed="true"
                              createEnabled="true" createText="Add new subject" [values]="subjects"
                              [value]="editingNote.subject ?? null" (selected)="applySubject($event, editingNote)"
                              (created)="createSubject($event)"></or-dropdown-list>
                          </div>
                        </div>
                        <div class="row py-1">
                          <div class="col-5 d-flex align-items-center ">
                            <div class="grey4 font-weight-bold nav-font14 text-nowrap">
                              Note Title
                            </div>
                            <input [(ngModel)]="editingNote.title"
                              class="form-control-nav form-control-sm mx-3 w-100 note-title"
                              placeholder="Type note title">
                          </div>
                        </div>
                        <div class="row py-1">
                          <div class="col-12 my-3 grey5 nav-font14">
                            <or-groupnotes-wysiwyg [data]="editingNote.body"
                              (messageEvent)="editingNote.body = $event"></or-groupnotes-wysiwyg>
                          </div>
                        </div>
                        <div class="row py-1">
                          <div class="px-3 pt-1 lookupTableContainer">
                            @if (editingNote.showTags != true) {
                            <span class="font-weight-bold nav-accent-blue nav-font14" type="button"
                              (click)="editingNote.showTags = true"><i class="far fa-plus"></i> &nbsp; Add tag</span>
                            }
                            @if (editingNote.showTags == true) {
                            <or-lookup-table (changed)="addTags(editingNote, $event)" type="vendor-note-tag"
                              [selectedValuesString]="editingNote.tagIds"
                              placeholder="Search tag or type and press enter to create a tag"></or-lookup-table>
                            }
                          </div>
                          <div class="col ml-auto text-right d-inline">
                            <button type="button" class="btn btn-sm nav-btn-primary mx-1 shadow-none py-1 px-3"
                              (click)="cancelEditNote()">Cancel </button>
                            <button type="button" class="btn btn-sm nav-btn-accent shadow-none py-1 px-3"
                              (click)="saveNote()">Save </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    <!-- latest versions of notes -->
                    @if (notes?.length > 0) {
                    @for (note of notes; track note) {
                    <div class="card mb-3 note" [ngClass]="(editingNote != null || newNote != null )? 'editMode' : ''">
                      <div class="card-header px-3">
                        <div class="row">
                          <div class="col-3 d-flex">
                            <or-profile-image [url]="note.createdByImage" [name]="note.createdByName"
                              size="medium2"></or-profile-image>
                            <div class="mx-2">
                              <div class=" font-weight-bold nav-font14 grey5">
                                {{note.createdByName}}
                              </div>
                              <div class="grey2 nav-font12">
                                {{note.originalNoteId ? 'Edited' : 'Created'}} {{note.createdOn | date: 'LLL dd yyyy @
                                HH:mm a'}}
                              </div>
                            </div>
                          </div>
                          <div class="row ml-auto mr-3 align-items-center">
                            @for (tag of note.tags; track tag) {
                            <span class="mx-1 tagPill nav-font12">{{tagOptions[tag]}}</span>
                            }
                            @if (note.subject) {
                            <span class="ml-1 mr-2 subjectPill nav-font14 pt-0"> {{subjectOptions[note.subject]}}</span>
                            }
                            <!-- <span class="fak fa-ellipsis-vertical grey5 cursor-pointer mx-2"></span> -->
                            <div class="btn-group btn-group-sm" dropdown container="body">
                              <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-none"
                                controls="dropdown-basic">
                                <i class="fak fa-ellipsis-vertical grey4 nav-font18"></i>
                              </button>
                              <div id="dropdown-basic" *dropdownMenu
                                class="dropdown-menu dropdown-menu-right nav-font14 py-0" role="menu"
                                aria-labelledby="button-basic">
                                <a class="dropdown-item cursor-pointer px-3" role="menuitem"
                                  (click)="editNote(note)">Edit Note</a>
                                <a class="dropdown-item cursor-pointer px-3" role="menuitem"
                                  (click)="openDeleteNoteConfirm(note)">Delete Note</a>
                                <a class="dropdown-item px-3" role="menuitem"
                                  [ngClass]="!note.originalNoteId ? 'disabledItem cursor-default' : 'cursor-pointer'"
                                  (click)="openNoteHistory(note)">View note history</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body indented pb-1 pt-0">
                        @if (note.title != null && note.title != '') {
                        <div class="row">
                          <div class="col-5 d-flex align-items-center font-weight-bold grey5 nav-font16"
                            [innerHTML]="'<p>'+note.title +'</p>' | highlight: filterNotes">
                          </div>
                        </div>
                        }
                        <div class="row pb-1 pt-2">
                          <div class="col-12 nav-font14 grey5 text-left note-body" [id]="note.id"
                            [class.line-clamp]="!note.expand" [innerHTML]="note.body | highlight: filterNotes">
                          </div>
                        </div>
                        @if (note.hasExpand) {
                        <div class="row p-0 view-more">
                          <div class="col">
                            <span class="nav-font14 nav-accent-blue cursor-pointer float-right pt-3"
                              (click)="note.expand = !note.expand">View {{note.expand ? 'less' : 'more'}}
                              <i class="fa-solid grey4 nav-font16 mx-1" [class.fa-chevron-up]="note.expand"
                                [class.fa-chevron-down]="!note.expand"></i>
                            </span>
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                    }
                    }
                  </div>
                </div>
              </div>
            </div>
          </tab>
          <tab tabOrder="4" heading="Redirects & Security">
            <div class="my-4 nav-font14 grey5">
              <div class="card">
                <div class="card-body mt-1">
                  <div class="link-security mx-1">
                    <div class="nav-secondary nav-font18 font-weight-bold mb-4 pb-2">Link Security</div>
                    <div class="mb-4 mx-3">
                      <div class="row align-items-center">
                        <div class="first-col">
                          <span>Link security type</span>
                        </div>
                        <div class="col-4">
                          @if (auth.isAdmin()) {
                          <div class="btn-group" dropdown #dropdownLinkSecurity="bs-dropdown"
                            class="linkSecurityTypeDropdown">
                            <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex">
                              <div
                                class="linkSecurityTypeContainer dropdown-container cursor-pointer d-flex align-items-center grey5"
                                [ngClass]="dropdownLinkSecurity.isOpen ? 'open' : 'closed'">
                                {{linkSecurityTypes[partner.linkSecurity]}}
                                <i class="far grey4 float-right nav-font16 ml-auto"
                                  [class.fa-angle-up]="dropdownLinkSecurity.isOpen"
                                  [class.fa-angle-down]="!dropdownLinkSecurity.isOpen">
                                </i>
                              </div>
                            </div>
                            <div id="dropdownLinkSecurityType" *dropdownMenu
                              class="dropdown-menu dropdown-menu-right py-0 nav-font14" role="menu"
                              aria-labelledby="button-basic" (click)="$event.stopPropagation()">
                              <div class="" [ngStyle]="">
                                <ul class=" py-0 px-0 my-0 noShadow">
                                  @for (type of linkSecurityTypes | keyvalue: orderByRowNum; track type) {
                                  <li class="expandable grey5 nav-font14 d-flex align-items-center px-0"
                                    (click)="partner.linkSecurity=type.key; dropdownLinkSecurity.toggle(true); onChange('linkSecurityType');">
                                    <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2 py-1">
                                      <span>{{type.value}}</span>
                                    </div>
                                  </li>
                                  }
                                </ul>
                              </div>
                            </div>
                          </div>
                          }
                          @if (!auth.isAdmin()) {
                          <div>
                            {{ linkSecurityTypes[partner.linkSecurity] }}
                          </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr class="line-break mx-3">
                  </div>
                  <div class="link-redirects mx-1 pt-4">
                    @if (partner.linkSecurity !== 'server-to-server' && partner.linkSecurity !== 'hashing') {
                    <div class="nav-secondary nav-font18 font-weight-bold mb-4 pb-2">Redirect Links</div>
                    }
                    @if (partner.linkSecurity === 'server-to-server') {
                    <div class="nav-secondary nav-font18 font-weight-bold mb-4 pb-2">Server to Server Webhooks</div>
                    }
                    @if (partner.linkSecurity === 'hashing') {
                    <div class="nav-secondary nav-font18 font-weight-bold mb-4 pb-2">Link Hashing</div>
                    }
                    @if (partner.linkSecurity === 'hashing') {
                    <div class="row mb-4 align-items-center mx-0">
                      <div class="first-col">
                        <span>Link hashing type</span>
                      </div>
                      <div class="col-2">
                        @if (auth.isAdmin()) {
                        <div class="btn-group" dropdown #dropdownHashingType="bs-dropdown"
                          class="linkHashingTypeDropdown">
                          <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex">
                            <div
                              class="linkHashingTypeContainer dropdown-container cursor-pointer d-flex align-items-center grey5"
                              [ngClass]="dropdownHashingType.isOpen ? 'open' : 'closed'">
                              {{partner.linkHashingType}}
                              <i class="far grey4 float-right nav-font16 ml-auto"
                                [class.fa-angle-up]="dropdownHashingType.isOpen"
                                [class.fa-angle-down]="!dropdownHashingType.isOpen">
                              </i>
                            </div>
                          </div>
                          <div id="dropdownLinkHashingType" *dropdownMenu
                            class="dropdown-menu dropdown-menu-right py-0 nav-font14" role="menu"
                            aria-labelledby="button-basic" (click)="$event.stopPropagation()">
                            <div class="" [ngStyle]="">
                              <ul class=" py-0 px-0 my-0 noShadow">
                                @for (type of linkHashingTypes; track type) {
                                <li class="expandable grey5 nav-font14 d-flex align-items-center px-0"
                                  (click)="partner.linkHashingType=type; changeHashType(); dropdownHashingType.toggle(true)">
                                  <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2 py-1">
                                    <span>{{type}}</span>
                                  </div>
                                </li>
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                        }
                        @if (!auth.isAdmin()) {
                        <div>
                          {{ partner.linkHashingType }}
                        </div>
                        }
                      </div>
                    </div>
                    <div class="row align-items-center mx-0">
                      <div class="first-col">
                        <span>Hash parameter</span>
                        <i class="fal fa-info-circle cursor-default grey4 nav-font14 ml-2" placement="bottom"
                          tooltip="The name of the paramater to append to the url that has the hash value."></i>
                      </div>
                      <div class="col-2 link-redirect">
                        <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                          (keypress)="onKeyPress($event)" (blur)="onBlur('hashParams', null)"
                          [(ngModel)]="partner.hashParameters" [placeholder]="'Enter hash parameter'"
                          class="form-control form-control-nav nav-font14 border">
                                </textarea>
                        <div class="copyRedirect position-absolute cursor-pointer" placement="bottom"
                          tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                          (click)="copyLinks(partner, 'hashParams')">
                          <i class="far fa-copy grey4"></i>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center mx-0 mt-4">
                      <div class="first-col">
                        <span>Link hashing key</span>
                        <i class="fal fa-info-circle cursor-default grey4 nav-font14 ml-2" placement="bottom"
                          tooltip="The security key provided by the Vendor to sign the URL."></i>
                      </div>
                      <div class="col link-redirect">
                        <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                          (keypress)="onKeyPress($event)" (blur)="onBlur('hashKey', null)"
                          [(ngModel)]="partner.linkHashingKey" [placeholder]="'Type key'"
                          class="form-control form-control-nav nav-font14 border">
                                </textarea>
                        <div class="copyRedirect position-absolute cursor-pointer" placement="bottom"
                          tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                          (click)="copyLinks(partner, 'hashKey')">
                          <i class="far fa-copy grey4"></i>
                        </div>
                      </div>
                    </div>
                    <div class="pt-4">
                      <hr class="line-break mx-3">
                    </div>
                    }
                    <div class="mb-2 pb-1 mx-3">
                      @for (redirect of redirectLinkData; track redirect; let i = $index) {
                      @if (redirect.webhookDetails || partner.linkSecurity != 'server-to-server') {
                      @if (partner.linkSecurity === 'server-to-server' || partner.linkSecurity === 'hashing') {
                      <div class="row align-items-center mb-4" [class.mt-4]="i == 0">
                        <div class="grey5 nav-font16 font-weight-bold">
                          {{redirect.heading}}
                        </div>
                      </div>
                      }
                      <div class="row align-items-center"
                        [class.mb-4]="!partner.linkSecurity && i < redirectLinkData.length-1"
                        [class.mb-3]="partner.linkSecurity">
                        <div class="first-col">
                          @if (redirect.type === 'qc') {
                          <span>Auto QC redirect link</span>
                          }
                          @if (redirect.type !== 'qc') {
                          <span>{{redirect.type | titlecase}} redirect link</span>
                          }
                        </div>+
                        <div class="col link-redirect">
                          <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                            (keypress)="onKeyPress($event)" (blur)="onBlur(redirect.type+'RedirectURL', redirect.type)"
                            [placeholder]="'www.url.com'" [(ngModel)]="redirect.redirectURL"
                            class="form-control form-control-nav nav-font14 border"
                            [class.grey4-text]="partner.linkSecurity === 'hashing'">
                                    </textarea>
                          <div class="copyRedirect position-absolute cursor-pointer" placement="bottom"
                            tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                            (click)=" copyLinks(partner, redirect.type)">
                            <i class="far fa-copy grey4"></i>
                          </div>
                        </div>
                      </div>
                      @if (partner.linkSecurity === 'hashing') {
                      <div class="row align-items-center" [class.mb-4]="i < redirectLinkData.length-1">
                        <div class="first-col">
                          <span class="grey4">Enter string to hash</span>
                        </div>
                        <div class="col link-redirect">
                          <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                            (keypress)="onKeyPress($event)" (blur)="onBlur('hashString', redirect.type)"
                            [placeholder]="'Enter part of link for hash'" [(ngModel)]="redirect.stringToHash"
                            class="form-control form-control-nav nav-font14 border">
                                      </textarea>
                          <div class="copyRedirect position-absolute cursor-pointer" placement="bottom"
                            tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                            (click)=" copyLinks(partner, redirect.type)">
                            <i class="far fa-copy grey4"></i>
                          </div>
                        </div>
                      </div>
                      }
                      @if (partner.linkSecurity === 'server-to-server') {
                      <div class="row mb-4 align-items-center">
                        <div class="first-col">
                          Method
                        </div>
                        @if (auth.isAdmin()) {
                        <div class="col-2 link-redirect">
                          <ng-container
                            *ngTemplateOutlet="methodDropdown; context: { redirect: redirect }"></ng-container>
                        </div>
                        }
                        @if (!auth.isAdmin()) {
                        <div class="col-2">
                          {{ redirect.webhookDetails.method }}
                        </div>
                        }
                      </div>
                      <div class="row mb-4 align-items-center">
                        <div class="first-col">
                          URL/Endpoint
                        </div>
                        <div class="col link-redirect">
                          <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                            (keypress)="onKeyPress($event)" (blur)="onBlur('webhookEndpoint', redirect.type)"
                            [placeholder]="'www.url.com'" [(ngModel)]="redirect.webhookDetails.endpoint"
                            class="form-control form-control-nav nav-font14 border">
                                      </textarea>
                          <div class="copyRedirect position-absolute cursor-pointer" placement="bottom"
                            tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                            (click)=" copyLinks(partner, redirect.type)">
                            <i class="far fa-copy grey4"></i>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4 pb-1 ">
                        <div class="first-col">
                          Parameters
                        </div>
                        <div class="col px-0">
                          @for (parameter of redirect?.webhookDetails?.parameters; track parameter; let p = $index) {
                          <div class="col d-flex align-items-center mb-3">
                            <i [style.visibility]="auth.isAdmin() ? 'visible' : 'hidden'"
                              class="fak fa-trash nav-error nav-font20 mr-1 cursor-pointer"
                              (click)="removeByIndex(redirect.type, redirect?.webhookDetails?.parameters, p)"></i>
                            <div class="d-inline-block text-nowrap second-col grey4 mx-2">Variable name</div>
                            <div class="link-redirect d-inline-block w-100">
                              <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                                (keypress)="onKeyPress($event)" (blur)="onBlur('parameterName', redirect.type, p)"
                                [placeholder]="'Type name'" [(ngModel)]="parameter.name"
                                class="form-control form-control-nav nav-font14 border">
                                            </textarea>
                              <div class="copyRedirect position-absolute cursor-pointer" placement="bottom"
                                tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                                (click)="copyLinks(partner, redirect.type, 'parameter-name', p)">
                                <i class="far fa-copy grey4"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col d-flex align-items-center mb-4 ml-3 pl-4">
                            <div class="d-inline-block text-nowrap second-col grey4 ml-1">Value</div>
                            <div class="link-redirect d-inline-block mr-3 col px-0">
                              <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                                (keypress)="onKeyPress($event)" (blur)="onBlur('parameterValue', redirect.type, p)"
                                [placeholder]="'Type parameter'" [(ngModel)]="parameter.value"
                                class="form-control form-control-nav nav-font14 border">
                                            </textarea>
                              <div class="copyRedirect right-8 position-absolute cursor-pointer" placement="bottom"
                                tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                                (click)="copyLinks(partner, redirect.type, 'parameter-value', p)">
                                <i class="far fa-copy grey4"></i>
                              </div>
                            </div>
                          </div>
                          }
                          @if (auth.isAdmin()) {
                          <div class="col-10 nav-accent-blue nav-font14 font-weight-bold cursor-pointer"
                            (click)="addBlankField(redirect?.webhookDetails?.parameters)">
                            <i class="far fa-plus mr-2"></i> Add parameter variable
                          </div>
                          }
                        </div>
                      </div>
                      <div class="row mb-4 pb-1 ">
                        <div class="first-col">
                          Header
                        </div>
                        <div class="col px-0">
                          @for (header of redirect?.webhookDetails?.headers; track header; let h = $index) {
                          <div class="col d-flex align-items-center mb-3">
                            <i [style.visibility]="auth.isAdmin() ? 'visible' : 'hidden'"
                              class="fak fa-trash nav-error nav-font20 mr-1 cursor-pointer"
                              (click)="removeByIndex(redirect.type, redirect?.webhookDetails?.headers, h)"></i>
                            <div class="d-inline-block text-nowrap second-col grey4 mx-2">Variable name</div>
                            <div class="link-redirect d-inline-block w-100">
                              <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                                (keypress)="onKeyPress($event)" (blur)="onBlur('headerName', redirect.type, h)"
                                [placeholder]="'Type name'" [(ngModel)]="header.name"
                                class="form-control form-control-nav nav-font14 border">
                                            </textarea>
                              <div class="copyRedirect position-absolute cursor-pointer" placement="bottom"
                                tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                                (click)="copyLinks(partner, redirect.type, 'header-name', h)">
                                <i class="far fa-copy grey4"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col d-flex align-items-center mb-4 ml-3 pl-4">
                            <div class="d-inline-block text-nowrap second-col grey4 ml-1 ">Value</div>
                            <div class="link-redirect d-inline-block mr-3 col px-0">
                              <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                                (keypress)="onKeyPress($event)" (blur)="onBlur('headerValue', redirect.type, h)"
                                [placeholder]="'Type header'" [(ngModel)]="header.value"
                                class="form-control form-control-nav nav-font14 border">
                                            </textarea>
                              <div class="copyRedirect right-8 position-absolute cursor-pointer" placement="bottom"
                                tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                                (click)="copyLinks(partner, redirect.type,'header-value', h)">
                                <i class="far fa-copy grey4"></i>
                              </div>
                            </div>
                          </div>
                          }
                          @if (auth.isAdmin()) {
                          <div class="col-10 nav-accent-blue nav-font14 font-weight-bold cursor-pointer"
                            (click)="addBlankField(redirect?.webhookDetails?.headers)">
                            <i class="far fa-plus mr-2"></i> Add header variable
                          </div>
                          }
                        </div>
                      </div>
                      <div class="row" [class.mb-4]="i < redirectLinkData.length-1">
                        <div class="first-col">
                          Body
                        </div>
                        <div class="col px-0">
                          @for (body of redirect?.webhookDetails?.body; track body; let b = $index) {
                          <div class="col d-flex align-items-center mb-3">
                            <i [style.visibility]="auth.isAdmin() ? 'visible' : 'hidden'"
                              class="fak fa-trash nav-error nav-font20 mr-1 cursor-pointer"
                              (click)="removeByIndex(redirect.type, redirect?.webhookDetails?.body, b)"></i>
                            <div class="d-inline-block text-nowrap second-col grey4 mx-2">Variable name</div>
                            <div class="link-redirect d-inline-block w-100">
                              <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                                (keypress)="onKeyPress($event)" (blur)="onBlur('bodyName', redirect.type, b)"
                                [placeholder]="'Type name'" [(ngModel)]="body.name"
                                class="form-control form-control-nav nav-font14 border">
                                            </textarea>
                              <div class="copyRedirect position-absolute cursor-pointer" placement="bottom"
                                tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                                (click)=" copyLinks(partner, redirect.type, 'body-name', b)">
                                <i class="far fa-copy grey4"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col d-flex align-items-center mb-4 ml-3 pl-4">
                            <div class="d-inline-block text-nowrap second-col grey4 ml-1 ">Value</div>
                            <div class="link-redirect d-inline-block mr-3 col px-0">
                              <textarea autosize [minRows]="1" [disabled]="!auth.isAdmin()" spellcheck="false"
                                (keypress)="onKeyPress($event)" (blur)="onBlur('bodyValue', redirect.type, b)"
                                [placeholder]="'Type body'" [(ngModel)]="body.value"
                                class="form-control form-control-nav nav-font14 border">
                                            </textarea>
                              <div class="copyRedirect right-8 position-absolute cursor-pointer" placement="bottom"
                                tooltip="Copy to clipboard" containerClass="copyRedirect-tooltip"
                                (click)=" copyLinks(partner, redirect.type, 'body-value', b)">
                                <i class="far fa-copy grey4"></i>
                              </div>
                            </div>
                          </div>
                          }
                          @if (auth.isAdmin()) {
                          <div class="col-10 nav-accent-blue nav-font14 font-weight-bold cursor-pointer"
                            (click)="addBlankField(redirect?.webhookDetails?.body)">
                            <i class="far fa-plus mr-2"></i> Add body variable
                          </div>
                          }
                        </div>
                      </div>
                      }
                      @if (partner.linkSecurity && i < redirectLinkData.length-1) { <div class="pb-4">
                        <hr class="line-break mx-3">
                    </div>
                    }
                    }
                    }
                  </div>
                </div>
              </div>
            </div>
      </div>
      </tab>
      <tab tabOrder="5" heading="Supplier API">
        <div class="nav-font14 grey5">
          <div class="col d-flex align-items-center px-0 my-4">
            <span class="grey5 nav-font16 font-weight-normal">Enable Supplier API</span>
            <i class="fal fa-info-circle cursor-default grey4 nav-font14 mx-2" placement="bottom"
              tooltip="Enable Supplier API to allow the Vendor to access the Supplier API."></i>
            <or-toggle-button id="enabled" name="enabled" size="small" class="light"
              [(ngModel)]="partner.supplierApiEnabled" (changed)="toggleApiEnabled($event)"></or-toggle-button>
          </div>
          @if (partner.supplierApiEnabled) {
          <div class="card">
            <div class="card-body mt-1">
              <div class="mx-1">
                <div class="nav-secondary nav-font18 font-weight-bold mb-4 pb-2">API Credentials</div>
                <div class="mb-4 mx-3">
                  <div class="row align-items-center">
                    <div class="col d-flex align-items-center">
                      <span class="">ID<i class="fal fa-info-circle cursor-default grey4 nav-font14 mx-2"
                          placement="bottom" tooltip=""></i></span>
                      <div class="link-redirect w-75">
                        <input type="text" [disabled]="true" [value]="partner.id"
                          class="form-control form-control-sm form-control-nav nav-font14 border" />
                      </div>
                    </div>
                    <div class="col d-flex align-items-center">
                      <span class="">API Key<i class="fal fa-info-circle cursor-default grey4 nav-font14 mx-2"
                          placement="bottom" tooltip=""></i></span>
                      <div class="link-redirect w-75">
                        <input type="text" [(ngModel)]="supplierApiKeyTemp" (keypress)="onKeyPress($event)"
                          (blur)="onBlur(null, 'ApiKey')" placeholder="Enter hash parameter"
                          class="form-control form-control-sm  form-control-nav nav-font14 border" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <hr class="line-break mx-3">
              </div>
              <div class="mx-1 pt-4">
                <div class="nav-secondary nav-font18 font-weight-bold mb-4 pb-2">CPI Rate Cards</div>
                <div class="ratecard-grid mb-4">
                  @for (rateCard of partnerRateCards; track rateCard) {
                  <div class="card" [class.bg-light]="!rateCard?.enabled">
                    @if(!rateCard?.enabled) {
                    <div class="d-flex w-100 align-items-center justify-content-center position-absolute">
                      <span class="nav-font10 badge bg-light border position-absolute text-right mt-2">DISABLED</span>
                    </div>
                    }
                    <div class="card-body">
                      <div class="row">
                        <div class="col text-center">
                          <div class="nav-font16 font-weight-bold grey5 mb-3">{{rateCard.name}}</div>
                          <button class="btn nav-btn-primary px-2 shadow-none font-weight-bold nav-font14"
                            (click)="openCard(rateCard)">
                            <i class="fak fa-go-to-link mr-2 grey4 nav-font12"></i>Open
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                  <div class="card add cursor-pointer" (click)="addCard()">
                    <div class="card-body">
                      <div class="row justify-content-center mt-1">
                        <div class="mb-2 cursor-pointer circle add-value">
                          <i class="fa fa-plus fa-lg nav-accent-blue font-weight-normal"></i>
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <div class="nav-font16 font-weight-bold grey5">Add card</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </tab>
      @if (auth.isSuperAdmin()) {
      <tab tabOrder="5" heading="Quality">
        <div class="my-4 nav-font14 grey5">
          <div class="card">
            <div class="card-body mt-1">
              <div class="link-security mx-1">
                <div class="nav-secondary nav-font18 font-weight-bold mb-4 pb-2">Vendor Quality</div>
                <div class="mb-4 mx-3">
                  <div class="row align-items-center">
                    <div class="first-col">
                      <span>CleanID Threshold</span>
                    </div>
                    <div class="col-4">
                      <div class="btn-group w-100" dropdown #dropdownFraudDetectionThreshold="bs-dropdown">
                        <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex dropdown-container"
                          [ngClass]="dropdownFraudDetectionThreshold.isOpen ? 'open' : 'closed'">
                          <span class="nav-font14 cursor-default"
                            [class.grey2]="partner.defaultCleanIdThreshold == null">{{partner.defaultCleanIdThreshold ==
                            null ? 'Select threshold' : cleanIdThresholds[partner.defaultCleanIdThreshold]}}</span>
                          <div class="cursor-pointer d-flex align-items-center grey6 ml-auto">
                            <i class="far grey4 float-right nav-font16 ml-auto"
                              [class.fa-angle-up]="dropdownFraudDetectionThreshold.isOpen"
                              [class.mt-1]="!dropdownFraudDetectionThreshold.isOpen"
                              [class.fa-angle-down]="!dropdownFraudDetectionThreshold.isOpen">
                            </i>
                          </div>
                        </div>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-right py-0 nav-font14 m-0 w-100"
                          role="menu" aria-labelledby="button-basic">
                          <div class="menu-container overflow-y">
                            <ul class=" py-0 px-0 my-0 noShadow">
                              @for (threshold of cleanIdThresholds | keyvalue : asIsOrder; track threshold; let t =
                              $index) {
                              <li role="menuitem" class="expandable grey4 nav-font14 d-flex align-items-center px-0"
                                (click)="partner.defaultCleanIdThreshold=threshold.key; savePartner('defaultCleanIdThreshold')">
                                <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2">
                                  <span>{{threshold.value}}</span>
                                </div>
                              </li>
                              @if (threshold.value != 'Using API') {
                              <hr class="p-0 my-0 mx-1">
                              }
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </tab>
      }
      </tabset>
    </div>
  </div>
  }
</div>


<ng-template #modalContact>
  <div class="modal-header">
    <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">{{ model?.id ? 'Edit Vendor Contact' : 'Add Vendor
      Contact' }}</h2>
    <div class="d-flex justify-content-end">
      <i class="fas fa-close grey4" (click)="closeModal()"></i>
    </div>
  </div>
  <div class="modal-body nav-font14">
    <form [formGroup]="contactForm" novalidate>
      <!-- <div class="row align-items-center mb-4">
            <div class="col-4 d-flex justify-content-center pr-5">
              <img [src]="model?.profilePhoto || '../../../assets/imgs/user-instagram.png'" height="64px" width="64px" class="rounded-circle" alt="">
            </div>
            <div class="col upload">
              <or-upload
                mainMsg="Select profile photo to upload"
                smallMsg="or drag and drop .JPEG or .PNG here"
                [includeProgressBar]=false
                acceptImages=true
                showFileName=false
                directToS3=true
                showFileIcon=false
                class="upload"
                (uploadCompleted)="uploadCallback($event)">
              </or-upload>
            </div>
          </div> -->
      <div class="row align-items-center mb-4">
        <div class="col-4 grey4">
          <label class="required">First name</label>
        </div>
        <div class="col">
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="First name"
            formControlName="firstName" id="firstName"
            [ngClass]="submitted && utils.showCssValidField(contactForm, 'firstName')" />
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-4 grey4">
          <label class="required">Last name</label>
        </div>
        <div class="col">
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Last name"
            formControlName="lastName" id="lastName"
            [ngClass]="submitted && utils.showCssValidField(contactForm, 'lastName')" />
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-4 grey4">Job title</div>
        <div class="col">
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Programmer"
            formControlName="companyPosition" id="companyPosition" />
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-4 grey4">Phone number</div>
        <div class="col">
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="(333) 333-4444"
            formControlName="phone" id="phone" />
        </div>
      </div>
      <div class="row align-items-center mb-4">
        <div class="col-4 grey4">
          <label class="required">Email</label>
        </div>
        <div class="col-8">
          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="E-mail"
            (focus)="emailFocus=true" (blur)="onBlurEmail();" formControlName="email" id="email" />
        </div>
        @if (model?.id && (emailFocus || contactForm.get('newemail').value != contactForm.get('email').value)) {
        <div class="col-4"></div>
        <div class="col-8">
          <div class="alert alert-warning mx-0 px-1 mt-2">
            Changing email will change login credentials
          </div>
        </div>
        }
      </div>
      <div class="col text-right pr-0 mt-4">
        <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14"
          (click)="closeModal()">Cancel</button>
        <button type="button" [disabled]="!contactForm.valid"
          [ngClass]="contactForm.valid ? 'nav-btn-accent' : 'nav-btn-disabled'"
          class="btn shadow-none font-weight-bold nav-font14" (click)="contactSave()">Save</button>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #modalConfirm>
  <div class="modal-header">
    <div class="row d-flex w-100 align-items-center">
      <div class="col">
        <h3 class="font-weight-bold nav-secondary">
          <i class="fak fa-message-warning grey4 fa-lg ml-2"></i>
          Warning: Deleting Contact
        </h3>
      </div>
      <div class="col-2 text-right px-0">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h5 class="grey5 d-block my-3">
      Are you sure you want to delete this contact? This action is irreversible, and the contact will be permanently
      lost.
    </h5>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100 align-items-center">
      <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
        <input [(ngModel)]="notAskAgainDeleteContact" type="checkbox" name="notAskAgainDeleteContact"
          id="notAskAgainDeleteContact">
        <label for="notAskAgainDeleteContact"></label>
        <small class="pl-4 grey4">Don't ask again</small>
      </div>
      <div class="col text-right px-0">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="confirmDelete()">Delete</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalNoteConfirm>
  <div class="modal-header">
    <div class="row d-flex w-100 align-items-center">
      <div class="col">
        <h3 class="font-weight-bold nav-secondary">
          <i class="fak fa-message-warning grey4 fa-lg ml-2"></i>
          Warning: Deleting Note
        </h3>
      </div>
      <div class="col-2 text-right px-0">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h5 class="grey5 d-block my-3">
      Are you sure you want to delete this note? This action is irreversible, and the note will be permanently lost.
    </h5>
  </div>
  <div class="modal-footer mt-1">
    <div class="row d-flex w-100">
      <div class="col text-right pr-0">
        <button class="btn btn-sm nav-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="deleteNote()">Delete</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalNoteHistory>
  <div class="modal-header">
    <div class="col">
      <div class="row d-flex align-items-center pl-1">

        <h2 class="nav-secondary m-2 font-weight-bold nav-font18">Note History</h2>
        <div class="d-flex justify-content-end ml-auto ">
          <i class="fas fa-close grey4 pb-3 pr-1" (click)="closeModal()"></i>
        </div>
      </div>
      <div class="row pl-3 mt-3 mb-2">
        <div class=" grey5 nav-font10 d-flex align-items-center">
          <span class="pr-3 nav-font14 grey4">Highlight changes</span>
          <or-toggle-button [(ngModel)]="showDifferences" class="light"></or-toggle-button>
        </div>

        <div class="col-1 text-right ml-auto">
          <span class="fak fa-download grey4 px-2 cursor-pointer" (click)="exportNoteHistory(noteHistory)"></span>
        </div>
      </div>
      <!-- <div *ngIf="showDifferences" class="row mb-2">
              <div class="col grey5 nav-font14">
                Notes edited with added or removed text are highlighted for easy identification and reference.
              </div>
            </div> -->
    </div>

  </div>
  <div class="modal-body pt-0 nav-font16 grey5">


    @for (note of noteHistory; track note; let i = $index) {
    <div class="card mb-3 note" [ngClass]="editingNote != null ? 'editMode' : ''">
      <div class="card-header px-3">
        <div class="row">
          <div class="col-3 d-flex">
            <or-profile-image [url]="note.createdByImage" [name]="note.createdByName" size="medium2"></or-profile-image>
            <div class="mx-2">
              <div class=" font-weight-bold nav-font14 grey5">
                {{note.createdByName}}
              </div>
              <div class="grey2 nav-font12">
                {{note.originalNoteId ? 'Edited' : 'Created'}} {{note.createdOn | date: 'LLL dd @ HH:mm a'}}
              </div>
            </div>
          </div>
          <div class="row ml-auto mr-3 align-items-center">
            @for (tag of note.tags; track tag) {
            <span class="mx-1 tagPill nav-font12">{{tagOptions[tag]}}</span>
            }
            @if (note.subject) {
            <span class="ml-1 mr-2 subjectPill nav-font14 pt-0"> {{subjectOptions[note.subject]}}</span>
            }
          </div>
        </div>
      </div>
      <div class="card-body indented pt-0" [ngClass]="showDifferences ? 'pb-4' : 'pb-1'">
        @if (note.title != null && note.title != '') {
        <div class="row py-1">
          <div class="col-5 d-flex align-items-center font-weight-bold grey5 nav-font16">
            {{note.title}}
          </div>
        </div>
        }
        <div class="row pb-1 pt-2">
          @if (showDifferences) {
          <div class="col-12 nav-font14 grey5 text-left"
            [innerHTML]="note.body | compareText: (i < noteHistory.length -1 ? noteHistory[i+1].body : '')"></div>
          }
          @if (!showDifferences) {
          <div class="col-12 nav-font14 grey5 text-left" [innerHTML]="note.body"></div>
          }
        </div>
      </div>
    </div>
    }
  </div>
  <div class="modal-footer">

  </div>
</ng-template>

<ng-template #modalEditVendor>
  <div class="modal-header">
    <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Edit Vendor</h2>
    <div class="d-flex justify-content-end">
      <i class="fas fa-close grey4" (click)="closeModal()"></i>
    </div>
  </div>
  <div class="modal-body nav-font14 scrollbar overflow-y pb-3">
    <or-vendor-edit [partnerForm]="partnerForm" [openModalRef]="openModalRef" [model]="model"
      (saveVendor)="getPartnerFormEvent($event)"></or-vendor-edit>
  </div>
</ng-template>

<ng-template #modalRateCPICard>
  <or-vendor-edit-cpi [partnerRateCardForm]="partnerRateCardForm" [openModalRef]="openModalRef" [model]="model"
    (saveVendorCpi)="saveVendorRateCard($event)"></or-vendor-edit-cpi>
</ng-template>

<!-- server to server method picker -->
<ng-template #methodDropdown let-redirect="redirect">
  <div class="btn-group" dropdown #dropdown="bs-dropdown" class="methodTypeDropdown">
    <div id="button-basic" dropdownToggle controls="dropdown-basic" class="d-flex">
      <div class="methodTypeContainer dropdown-container cursor-pointer d-flex align-items-center grey5"
        [ngClass]="dropdown.isOpen ? 'open' : 'closed'">
        {{redirect.webhookDetails.method}}
        <i class="far grey4 float-right nav-font16 ml-auto" [class.fa-angle-up]="dropdown.isOpen"
          [class.fa-angle-down]="!dropdown.isOpen">
        </i>
      </div>
    </div>
    <div id="dropdownMethod" *dropdownMenu class=" dropdown-menu dropdown-menu-right py-0 nav-font14" role="menu"
      aria-labelledby="button-basic" (click)="$event.stopPropagation()">
      <div class="" [ngStyle]="">
        <ul class=" py-0 px-0 my-0 noShadow">
          @for (type of methodTypes; track type) {
          <li class="expandable grey5 nav-font14 d-flex align-items-center px-0"
            (click)="redirect.webhookDetails.method=type; onBlur('webhookMethod', redirect.type); dropdown.toggle(true)">
            <div class="dropdown-list-item cursor-pointer font-weight-normal w-100 px-2 py-1">
              <span>{{type}}</span>
            </div>
          </li>
          }
        </ul>
      </div>
    </div>
  </div>
</ng-template>