import { Component, OnInit, Input, Renderer2, TemplateRef, ViewChild, ViewChildren, HostListener, Output, EventEmitter, ElementRef, HostBinding} from '@angular/core';
import { AlertifyService, AuthService, ProjectDataService, QCFlowDetails, QCFlowFile, QCFlowFileColumn, QCFlowRule, UtilsService, } from 'core';
import { ExportFilesService } from 'projects/core/src/services/exportFiles.service';
import { PercentPipe, TitleCasePipe, formatDate } from '@angular/common';
import Highcharts, { chart } from 'highcharts';
import { BsModalService } from 'ngx-bootstrap/modal';
import { QCFlowRecord } from 'projects/core/src/models/qc-flow-records';
import { QCFlowService } from 'projects/core/src/services/qcFlow.service';
import { forkJoin, timeout } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { time } from 'console';
import { RecruitidComponent } from 'projects/admin/src/app/sections/recruitid/recruitid.component';
import { RIDQuestionMappingComponent } from './rid-question-mapping/rid-question-mapping.component';
import { callbackify } from 'util';

@Component({
  selector: 'app-project-id-suite',
  templateUrl: './project-id-suite.component.html',
  styleUrls: ['./project-id-suite.component.scss'],
  providers: [TitleCasePipe, PercentPipe]
})

export class ProjectIdSuiteComponent implements OnInit {
  highcharts = Highcharts;
  highchartsOptionsRespondentOverview: Highcharts.Options;
  highchartsOptionsQuestionOverview: Highcharts.Options;

  @Output() onChange = new EventEmitter();

  @ViewChild('respondentIdSearch')
  respondentIdSearch: ElementRef;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;
  modalConfirmData: any; // This will hold your modal data

  @ViewChild('modalFileError')
  modalRefFileError: TemplateRef<any>;

  @ViewChild('modalProcessError')
  modalRefProcessError: TemplateRef<any>;

  @ViewChild('modalRespondentView')
  modalRefRespondent: TemplateRef<any>;

  @ViewChild('modalReconcile')
  modalRefReconcile: TemplateRef<any>;

  @ViewChild('modalQCRules')
  modalRefQCRules: TemplateRef<any>;

  openModalRef: any;

  @ViewChild('bulkActionDropdown', { static: false }) bulkActionDropdown;
  @ViewChild('bulkActionsAssessmentDropdown', { static: false }) bulkActionsAssessmentDropdown;
  @ViewChild('bulkActionsAIAdviceDropdown', { static: false }) bulkActionsAIAdviceDropdown;
  @ViewChildren('dropdownQCStatus') dropdownQCStatuses;

  listenerFn: () => void;
  propagateChange: (_: any) => {};


  @Input() public set project(data) {
    this._project = data;
    this.s3buckPath = "qcFlow/" + this._project.id;
    this.getQCFlowData();
  }

  @Input() isClient = true;

  FileSaver = require('file-saver');
  _project;
  s3buckPath: string;
  inQCFlow = false;
  qcFlowStage = 'summary';
  qcFlowDetails: QCFlowDetails = null;
  qcFlowSummary = null;
  qcFlowRecords = null;
  qcFlowRules: QCFlowRule[] = [];
  aiConditionExists = false;
  currentRespondent = null;
  questions;
  reviewers = [];
  fileHistorySortType = '';
  deleteQCFileId = '';
  pending = false;
  processing = false;
  processError = false;
  percentComplete = 0;
  dataLoading = true;
  loadProgressInterval = null;
  textSearchInterval = null;
  isSection = false;
  sampleOnlyORProject = false;
  usedDecipher = false;
  canImport = false;
  savingDetails = false;

  // data cleaning
  openEnds = [];
  flags = [];
  prevScrollTop;
  prevScrollLeft;
  expandFlags = false;
  dataCleaningFilterText = '';
  respondentViewFilterText = '';
  filteredRecords = [];
  filteredFileRecordIds = {}; // record IDs returned
  filesFiltered = []; // the IDS of the files we are filtering on
  filterByFileOptions = []; // options for the filter ID dropdown
  rowsPerPage = 100;
  rowsPerPageForPagination = 100;
  currentPage = 1;
  originalRecords: QCFlowRecord[] = []; // to revert to if changes are not saved
  originalDetails: QCFlowDetails = null; // to revert accepted and rejected count to if changes are not saved
  bulkActionAIAdviceQuestion = null;
  bulkActionAssessmentQuestion = null;
  selectAllRespondents = false;
  oneRecordSelected = false;
  questionLevelLeft = 0;
  changeLog = [];
  changeIdx = -1;
  highlightChangedItems = {};
  overflowingY = false;
  overflowingX = false;
  respondentViewOverflowingY = false;
  respondentViewOverflowingX = false;
  wrapText = true;
  fullscreen = false;
  // qc rules
  canSaveRules = false;
  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };

  postRuleTotals = {
    accepts: null,
    rejects: null,
    pending: null,
  }


  respondentLevelColumns = [
    { value: 'respondent-id', label: 'OR1', width: 145, left: 0, respondentViewWidth: 190, hasFilter: false, class: ' sticky-cell text-nowrap header-seperator pl-1 pr-2' },
    { value: 'qc-status', label: 'QC Status', width: 132, left: 145, respondentViewWidth: 132, hasFilter: true, class: ' sticky-cell text-nowrap header-seperator px-2', filters: [] },
    { value: 'total-flags', label: 'Total Flags', width: 144, left: 277, respondentViewWidth: 144, hasFilter: false, class: ' sticky-cell text-nowrap header-seperator px-2' },
  ]
  questionLevelColumns = [
    { value: 'open-end', label: '', width: 220, respondentViewWidth: 342, hasFilter: true, class: '', filters: {} },
    { value: 'rid-score', label: 'RID Result', width: 123, respondentViewWidth: 123, hasFilter: true, class: ' text-nowrap border-left ', filters: {} },
    { value: 'ai-advice', label: 'AI Advice', width: 117, respondentViewWidth: 112, hasFilter: true, class: ' text-nowrap border-left ', filters: {} },
    { value: 'my-assessment', label: 'My Assessment', width: 154, respondentViewWidth: 150, hasFilter: true, class: ' text-nowrap border-left ', filters: {} },
  ]

  qcRuleSubjectArray = [
    { key: 'AI', value: 'AI advice' },
    { key: 'Flag', value: 'Flag' },
    { key: 'My', value: 'My assessment' },
    { key: 'RID', value: 'RID Result' },
    { key: 'Blank', value: 'Blanks' },
    { key: 'NonAnswer', value: 'Non-Answers' },
    { key: 'DupeAnswer', value: 'Dupe across questions' },
    { key: 'DupeRespondent', value: 'Dupe across respondents' },
  ];
  qcRuleRiskDictionary = {
    'high': 'Bad',
    'medium': 'Warn',
    'low': 'Good',
  }

  qcRuleRIDDictionary = {
    'high': 'High',
    'medium': 'Medium',
    'low': 'Low',
  }

  qcRuleFlagValueDictionary = {
    "'0'" : 'Not failed',
    "'1'" : 'Failed',
  }
  qcRuleOperatorDictionary = {
    '==': 'Equal to',
    '>': 'Greater than',
    '<': 'Less than',
  }

  qcRuleFlagsDictionary = {}
  qcRuleOEDictionary = {}

  // upload screen
  respondentIdColumnIndex = null;
  respondentIdColumnId = null;
  filteredOpenEndColumns = [];
  filteredFlagColumns = [];
  filteredIdColumns = [];
  openEndFilterText = ''
  flagFilterText = ''
  respondentIdFilterText = '';
  selectedOpenEndCols = [];
  selectedFlagCols = [];
  canUpload = false;

  idColumn = null;
  hasQuestionLabels = false;
  actualUploadedFileName;
  vendorDataAcceptanceTotal;

  fileProcessed;
  uploadedFile;
  uploadedFileId;
  wrongColumns;
  rowCount;
  filteredRowCount = 0;
  settingsExist = false;

  constructor(
    private modalService: BsModalService,
    public auth: AuthService,
    private qcFlowService: QCFlowService,
    private alertify: AlertifyService,
    private renderer: Renderer2,
    public utils: UtilsService,
    private projectDataService: ProjectDataService,
    private exportFilesService: ExportFilesService,
    public titleCasePipe: TitleCasePipe,
    public percentPipe: PercentPipe) {}

    
  @HostBinding('class.fullscreen') get fullscreenClass() {
      return this.fullscreen;
  }
  
  ngOnInit() {
    this.sampleOnlyORProject = this.auth.isORInstance() && (this._project.type === 'Sample Only' || this._project.type === 'SampleOnly');
    this.checkCanUpload(true);
    // this.openRIDMappingModal({ridValues: ['1', '2', '3', '4', '5', '6'], fileValues: ['A', 'B', 'C', 'D', 'E', 'F']}, null);
  }

  canDeactivate(showDialog: boolean): boolean {
    if (this.changeIdx >= 0 && this.inQCFlow) {    // Check if changes have been made
      if (showDialog)
        this.openWarningNotSavedDialog();
      return false;
    }
    return true;
  }

  openFileSettings(inQCFlow) {
    if (!this.pending && !this.processing && this.changeIdx < 0) {
      this.inQCFlow = inQCFlow;
      this.qcFlowStage = 'settings';
    }
  }

  cancelQCFlow() {
    // exit the workflow
    if (this.qcFlowStage === 'data-cleaning' && this.changeIdx > -1) this.openWarningNotSavedDialogGoToSummary();
    else {
      if (this.qcFlowStage === 'settings' && this.inQCFlow) {
        this.qcFlowStage = 'data-cleaning';
        // once table is drawn check for overflow
        this.checkOverflow();
      }
      else {
        if (!this.settingsExist) {
          this.qcFlowDetails = null;
          this.filteredOpenEndColumns = [];
          this.selectedOpenEndCols = [];
          this.selectedFlagCols = [];
          this.filteredFlagColumns = [];
          this.respondentIdColumnIndex = null;
          this.respondentIdColumnId = null;
          this.respondentIdFilterText = '';
          this.usedDecipher = false;
        }
        this.uploadedFile = null;
        this.fileProcessed = false;
        this.inQCFlow = false;
        this.wrongColumns = null;
        this.qcFlowStage = 'summary';
      }
      this.checkCanUpload(true);
    }
    this.savingDetails = false;
  }


  deleteFileCallback() {
    this.uploadedFile = null;
    this.uploadedFileId = null;
    this.fileProcessed = false;
    this.inQCFlow = false;
    this.wrongColumns = null;
  }

  retryFileImport() {
    this.uploadedFile = null;
    this.uploadedFileId = null;
    this.fileProcessed = false;
    this.wrongColumns = null;
    this.openModalRef.hide();
  }

  clearSessionData() {
    this.qcFlowService.ClearData(this._project.id).subscribe((data) => {
      // reset variables
      this.qcFlowDetails = null;
      this.qcFlowRecords = null
      this.openEnds = [];
      this.flags = [];
      this.filteredOpenEndColumns = [];
      this.filteredFlagColumns = [];
      this.uploadedFile = null;
      this.fileProcessed = null;
      this.settingsExist = false;
      this.usedDecipher = false;

      this.cancelQCFlow();
      this.alertify.success('Data cleaning session data successfully deleted');

      this.onChange.emit();
    }, error => {
      this.alertify.error('Unable to clear data cleaning Session data')
    })
  }

  deleteQCFile() {
    if (!this.deleteQCFile) return;

    this.qcFlowService.DeleteFile(this._project.id, this.deleteQCFileId).subscribe((data) => {
      this.cancelQCFlow();

      this.getQCFlowData();
      this.alertify.success('QC file successfully deleted')

      this.onChange.emit();

    }, error => {
      this.alertify.error('Unable to delete QC file')
    })
  }

  getQCFlowData() {

    this.qcFlowService.GetDetails(this._project.id).subscribe((data) => {
      this.qcFlowDetails = null;
      this.canImport = data.canImport;
      let qcFlowDetails = data.qcFlowDetails;

      if(qcFlowDetails?.status?.currentStatus === 'Error') {
        this.openModalRef = this.modalService.show(this.modalRefProcessError, { ignoreBackdropClick: true, class: 'modal-dialog-centered'});
        return;
      }

      if (qcFlowDetails?.status?.currentStatus === 'Processing') this.processing = true;
      else this.processing = false;

      if (qcFlowDetails) {
        if (qcFlowDetails?.status?.currentStatus === 'Pending' && qcFlowDetails.settings.enhanceWithAI) this.pending = true;
        else this.pending = false;

        if (!this.pending && !this.processing) {
          this.reviewers = qcFlowDetails.status.reviewers.map(x => { return { name: x.name, type: null } });
          this.qcFlowRules = JSON.parse(JSON.stringify(qcFlowDetails.settings.rules));
          this.aiConditionExists = qcFlowDetails.settings.rules.some(rule => rule.checks.some(check => check.metric === 'AI'));

          // get only active QC files
          const nonDeletedFiles = qcFlowDetails.status.fileHistory.filter(file => !file.isDeleted);
          qcFlowDetails.status.fileHistory = nonDeletedFiles;
          this.filterByFileOptions = qcFlowDetails.status.fileHistory.filter(file => !file.isDeleted).map(file => { return { label: file.fileName, value: file.id, selected: false, icon: '' } });

          if (qcFlowDetails.status.fileHistory.some(file => file.fileName === 'decipherAPI')) this.usedDecipher = true;

          this.settingsExist = qcFlowDetails.settings.columns.length > 0;
          this.questions = {}
          let openEnds = [];
          let flags = [];

          let qcRuleFlagsDictionary = {}
          let qcRuleOEDictionary = {}

          if (this.openEnds.length == 0 && this.flags.length == 0) {
            qcFlowDetails.settings.columns.forEach(q => {
              this.questions[q.id] = q.text;
              q.expand = true;

              if (q.type === 'open-end') {
                openEnds.push(q)
                qcRuleOEDictionary[q.id] = q.id;
              }
              else if (q.type === 'flag') {
                flags.push(q)
                qcRuleFlagsDictionary[q.id] = q.id;
              }
            });

            let expandedByDefault = openEnds.length  < 3;
            openEnds.forEach(q => {
              q.expand = expandedByDefault;
            });
            openEnds[0].expand = true;

            this.openEnds = openEnds;
            this.flags = flags;

            let oeIndex = 1;
            let numOEs = this.openEnds.length;
            this.openEnds.forEach(oe => {
              qcRuleOEDictionary[oeIndex] = `${oeIndex} of ${numOEs} questions`;
            });

            let flagIndex = 1;
            let numFlags = this.flags.length;
            this.flags.forEach(flag => {
              qcRuleOEDictionary[flagIndex] = `${flagIndex} of ${numFlags} questions`;
            });

            this.qcRuleOEDictionary = qcRuleOEDictionary;
            this.qcRuleFlagsDictionary = qcRuleFlagsDictionary;

          }
          this.qcFlowDetails = qcFlowDetails;
          this.originalDetails = JSON.parse(JSON.stringify(qcFlowDetails));
          this.createRespondentOverviewChart();

          this.qcFlowService.GetRecords(this._project.id).subscribe((qcFlowRecords) => {
            if (qcFlowRecords.length > 0 && !this.processing) {
              qcFlowRecords = this.enrichQCFlowRecords(qcFlowRecords);
              this.qcFlowRecords = qcFlowRecords;
              this.originalRecords = JSON.parse(JSON.stringify(qcFlowRecords));

              // check if need to rerun rule check
              let lastFile = qcFlowDetails.status.fileHistory[qcFlowDetails.status.fileHistory.length - 1];
              if (lastFile && !lastFile.ruleCheckCompleted) {
                let matchedRows = this.calculateRuleChanges(this.qcFlowRecords, false);
                if (matchedRows.length > 0) {
                  let decisions = this.applyRulesBulk(matchedRows);
                  this.addToChangeLog(decisions, null);
                }
              }

              this.updateColumns();
              this.updateIdList();
              this.updateTable();
            }
            else {
              this.qcFlowRecords = null;
              this.dataLoading = false;
            }
          });
        }
        else {
          this.qcFlowDetails = qcFlowDetails;
          this.pollForUpdate();
        }
      }
      this.dataLoading = (this.pending || this.processing)? false : (qcFlowDetails != null);
    });
  }

  retryQcFlow() {
    this.qcFlowService.RetryQCFlow(this._project.id).subscribe((data) => {
      this.getQCFlowData();
      this.alertify.success('Initiated retry of QC flow')
    }, error => {
      this.alertify.error('Unable to retry QC flow, please contact support')
    });

    this.cancelDialog();
  }
  cancelLastFile(){
    this.qcFlowService.CancelLastFile(this._project.id).subscribe((data) => {
      this.cancelQCFlow();

      this.getQCFlowData();
      this.alertify.success('The previous file has been cancelled successfully')

      this.onChange.emit();

    }, error => {
      this.alertify.error('Unable to cancel, please contact support')
    })
    this.cancelDialog();
  }


  async pollForUpdate() {

    let percentComplete = (this.qcFlowDetails?.status == null) ? .01 : (this.qcFlowDetails.status.respondentProcessedCount == 0 ? .01 : ((this.qcFlowDetails.status.respondentProcessedCount)/this.qcFlowDetails.status.respondentCount))
    if(percentComplete < .7 ) {
      let intervalTime = (this.percentComplete < .2) ? 500 : (this.percentComplete < .4) ? 1000 : (this.percentComplete < .5) ? 3000 : 5000;
      clearInterval(this.loadProgressInterval);
      this.loadProgressInterval= setInterval(() => {
        if(this.percentComplete < .7) this.percentComplete += .01;
        else clearInterval(this.loadProgressInterval);
      }, intervalTime);
    }
    else {
      this.percentComplete = percentComplete;
    }

    if (this.pending) {
      setTimeout(() => {
        if (this.pending) {
          this.getQCFlowData();
        }
      }, 15000)
    }
    else if (this.processing) {
      setTimeout(() => {
        if (this.processing) {
          this.getQCFlowData();
        }
      }, 5000)
    }
  }

  // only use this at first save of details because it might overwrite with old details while lambda is still working
  saveQCFlowDetails() {
    if (this.savingDetails) return;

    this.savingDetails = true;
    this.qcFlowService.SaveDetails(this._project.id, this.qcFlowDetails).subscribe((result) => {
      var qcFlowDetails = result?.item1;
      var rIDMappingDetails = result?.item2;

      if (rIDMappingDetails) {
        this.savingDetails = false;
        this.openRIDMappingModal(rIDMappingDetails, qcFlowDetails);
      }
      else {
        this.processing = true;
        this.settingsExist = true;
        this.cancelQCFlow();

        if (this.openModalRef?.content instanceof RIDQuestionMappingComponent) {
          this.alertify.success('Variables updated');
          this.openModalRef.hide();
          this.openModalRef = null;
        }
        window.scrollTo(0, 0);
        this.getQCFlowData();
      }
    }, error => {
      if (this.openModalRef?.content instanceof RIDQuestionMappingComponent) {
        this.openModalRef.content.saving = false;
        this.alertify.error('Couldn\'t update variables');
      }
      else {
        this.alertify.error('Couldn\'t save QCFlow settings');
      }
      this.savingDetails = false;
    }, () => {
    }
    )
  }

  openRIDMappingModal(mappingValues, qcFlowDetails) {

    this.openModalRef = this.modalService.show(RIDQuestionMappingComponent, 
      { ignoreBackdropClick: true, 
        class: 'modal-lg modal-dialog-centered modal-rid-question-mapping' + (mappingValues.ridValues.length > 3 ? ' biggerSize' : ''), 
        initialState: mappingValues });
      
    this.openModalRef.content.cancelevent.subscribe((data) => {
      if (data == true && !this.openModalRef.content.saving) {
        this.openModalRef.hide();
        this.qcFlowDetails.status.fileHistory.pop();
        this.openModalRef = null;
      }
      this.qcFlowDetails.settings.responseidMappings = [];
    })

    this.openModalRef.content.saveevent.subscribe((data) => {
      this.qcFlowDetails = qcFlowDetails;
      
      if (data != null) {        
        var ridMappingsArray = [];  

        Object.keys(data).forEach((key) => {
          ridMappingsArray.push({colName: data[key], questionId: key});
        });

        this.qcFlowDetails.settings.responseidMappings = ridMappingsArray;
        this.saveQCFlowDetails();
      }
    })
  }

  saveQCFlowRules() {

    this.qcFlowRules.forEach(rule => {
      rule.checks.forEach(check => {
        if (check.questionId) {
          check.questionId = check.questionId.toString();
          check.questionCount = check.questionCount?.toString();
        }
      });
    });

    this.qcFlowDetails.settings.rules = this.qcFlowRules;


    this.qcFlowService.SaveRules(this._project.id, this.qcFlowDetails).subscribe(() => {
      this.aiConditionExists = this.qcFlowRules.some(rule => rule.checks.some(check => check.metric === 'AI'));

      let matchedRows = this.calculateRuleChanges(this.qcFlowRecords, true);
      if (matchedRows.length > 0) {
        let decisions = this.applyRulesBulk(matchedRows);
        this.addToChangeLog(decisions, null);
      }

      this.alertify.success('QC rules have been applied and saved');
      this.checkCanSaveRules();
      this.cancel();
    }, error => {
      this.alertify.error('Couldn\'t save QC rules');
    }, () => { }
    )
  }

  changeQcRuleMetric(rule, check) {
    if (rule && check) {
      check.operator = null;
      check.value = null;
      check.questionId = null;
      check.questionCount = null;
      this.doAllRuleChecks(rule);
      this.populateQcQuestions(check);
    }
  }

  populateQcQuestions(check) {

    check.dropdownQuestions = [];
    check.dropdownFlags = [];

    if (check.metric === 'Flag') {
      this.flags.map(item => {
        check.dropdownFlags.push({ text: item.id, value: item.id });
        this.qcRuleFlagsDictionary[item.id] = item.id;

      });

      const numFlags = this.flags.length;
      for (let i = 1; i <= numFlags; i++) {
        check.dropdownFlags.push({ text: `${i} of ${numFlags} flags`, value: i, type: 'count' });
        this.qcRuleFlagsDictionary[i] = `${i} of ${numFlags} flags`;

      }
    }
    else {

      this.openEnds.map(item => {
        check.dropdownQuestions.push({ text: item.id, value: item.id });
        this.qcRuleOEDictionary[item.id] = item.id;
      });

      const numQuestions = this.openEnds.length;
      for (let i = 1; i <= numQuestions; i++) {
        check.dropdownQuestions.push({ text: `${i} of ${numQuestions} questions`, value: i, type: 'count' });
        this.qcRuleOEDictionary[i] = `${i} of ${numQuestions} questions`;
      }
    }
  }

  changeRowsPerPage(value) {
    this.rowsPerPage = value;

    setTimeout(() => {
      this.rowsPerPageForPagination = value;
    }, 10);
  }

  changeQcRuleValue(rule, index=null, check= null) {
    if (rule) {
      if (index!=null && check) this.checkIfRuleOverridesAnother(rule, index, check);
      this.doAllRuleChecks(rule);
    }
  }

  changeQcRuleQuestion(rule, check) {
    if (rule && check) {
      if (check.metric == 'Flag') {
        const type = check.dropdownFlags.find(x => x.value == check.questionId)?.type;
        check.questionCount = (type == 'count') ? check.questionId : null;
      } else {
        const type = check.dropdownQuestions.find(x => x.value == check.questionId)?.type;
        check.questionCount = (type == 'count') ? check.questionId : null;
      }
      this.doAllRuleChecks(rule);
    }
  }

  deleteQcRule(rule, idx) {
    this.qcFlowRules.splice(idx, 1);
    this.doAllRuleChecks(rule);
  }

  deleteQcRuleCheck(rule, idx) {
    rule.checks.splice(idx, 1);
    this.doAllRuleChecks(rule);
  }

  addQcRule(type) {
    this.qcFlowRules.push({
      id: uuidv4(),
      name: '',
      status: type,
      logic: 'And',
      checks: [{
        metric: null,
        operator: null,
        value: null,
        questionId: null,
        questionCount: null
      }]
    });
    this.doAllRuleChecks();
  }

  addQcRuleCheck(rule) {
    if (rule) {
      rule.checks.push({
        metric: null,
        operator: null,
        value: null,
        questionId: null,
        questionCount: null
      });
      this.doAllRuleChecks(rule);
    }
  }

  checkCanAddCondition(rule) {
    const canAdd = !rule.checks.some(item => !item.metric || !item.value?.trim() || ( item.metric !='Blank' && item.metric !='NonAnswer' &&item.metric !='DupeAnswer' && !item.questionId));
    rule.canAddCondition = canAdd;
  }

  checkCanSaveRules() {
    var canSave = false;

    var change = this.checkForRulesChange();
    if (change) {
      canSave = (this.qcFlowRules.length == 0 || (this.qcFlowRules.length > 0 && !this.qcFlowRules.some(rule => rule.checks.some(item => item.metric === null || item.value === null || ( item.metric !='Blank' && item.metric !='NonAnswer' &&item.metric !='DupeAnswer' && item.questionId == null)))));
    }
    this.canSaveRules = canSave;
  }

  checkForRulesChange() {
    let qcFlowRulesCopy = JSON.parse(JSON.stringify(this.qcFlowRules));
    let originalRulesCopy = JSON.parse(JSON.stringify(this.qcFlowDetails.settings.rules));
    qcFlowRulesCopy.forEach(rule => {
      delete rule.canAddCondition;
      rule.checks.forEach(check => {
        delete check.dropdownQuestions;
        delete check.dropdownFlags;
      });
    });
    originalRulesCopy.forEach(rule => {
      delete rule.canAddCondition;
      rule.checks.forEach(check => {
        delete check.dropdownQuestions;
        delete check.dropdownFlags;
      });
    });
    if (JSON.stringify(qcFlowRulesCopy) === JSON.stringify(originalRulesCopy)) {
      return false;
    }
    return true;
  }

  doAllRuleChecks(rule = null) {
    if (rule) this.checkCanAddCondition(rule);
    this.checkCanSaveRules();
    this.calculateRuleChanges(this.qcFlowRecords, true);
  }

  cancel() {
    this.openModalRef.hide();
    this.openModalRef = null;
    setTimeout(() => {
      this.qcFlowRules = JSON.parse(JSON.stringify(this.qcFlowDetails.settings.rules));
      this.aiConditionExists = this.qcFlowRules.some(rule => rule.checks.some(check => check.metric === 'AI'));
    }, 500);

  }

  addFileToQCFlow(fileDto) {

    this.processing = true;
    this.percentComplete = .1;

    this.qcFlowService.AddAnotherFile(this._project.id, fileDto).subscribe((qcFlowDetails) => {
      this.savingDetails = false;
      this.qcFlowDetails = qcFlowDetails
     }, error => {
      this.alertify.error('Couldn\'t add new file');
      this.savingDetails = false;
    }, () => {
      this.cancelQCFlow();
      this.pollForUpdate()
    }
    )
  }

  enrichQCFlowRecords(qcFlowRecords) {
    qcFlowRecords.forEach(respondent => {
      respondent.openEnds = {}
      respondent.flags = {}
      respondent.selected = false;
    });
    qcFlowRecords.forEach(respondent => {
      this.openEnds.forEach(q => {
        if (q.type === 'open-end') {
          respondent.openEnds[q.id] = respondent.questions.find(x => x.id == q.id);

          if(respondent.openEnds[q.id] == undefined) {
            respondent.openEnds[q.id] = {type: 'open-end', id: q.id, answer: '', analysis: {}, responseIdApi:{}};
          }

          let oe = respondent.openEnds[q.id]
          if (oe) {

            var ridScore = oe.responseIdApi.score;
            if (ridScore != null && ridScore >= 0) {
              if (ridScore <= 20) {
                oe.responseIdApi.icon = 'fal fa-square-check nav-success cursor-default'
                oe.responseIdApi.label = 'Low risk'
              }
              else if (ridScore >= 21 && ridScore <= 25) {
                oe.responseIdApi.icon = 'fal fa-triangle-exclamation mediumRiskColour cursor-default'
                oe.responseIdApi.label = 'Medium risk'
              }
              else if (ridScore >= 26) {
                oe.responseIdApi.icon = 'far fa-octagon-xmark nav-error cursor-default'
                oe.responseIdApi.label = 'High risk'
              }

              // create tooltip
              oe.responseIdApi.tooltip = `<div class="font-weight-normal nav-font14 grey5">
                <div class="d-flex align-items-center nav-font14 font-weight-bold grey5">
                    <div class="mr-2">ResponseID Result:</div>
                    <div class="ml-auto row mx-0">
                      <div class="icon-container">
                        <i class="${oe.responseIdApi.icon} mr-2 text-left nav-font14"></i>
                      </div>
                        ${oe.responseIdApi.label}
                    </div>
                </div>
                <div class="mt-4 mb-2 text-left font-weight-bold">Fraudulent events flagged:</div>`
                var flagSummaryArray = oe.responseIdApi?.flagSummary ? oe.responseIdApi.flagSummary.split(',') : [];
                if (flagSummaryArray.length > 0) {
                  oe.responseIdApi.tooltip += `<ul class="text-left pl-4 ml-1 mb-0">`;
                  if (flagSummaryArray.includes('LG')) oe.responseIdApi.tooltip += `<li>Language</li>`;
                  if (flagSummaryArray.includes('DA')) oe.responseIdApi.tooltip += `<li>Dupe Answer</li>`;
                  if (flagSummaryArray.includes('DR')) oe.responseIdApi.tooltip += `<li>Duplicate</li>`;
                  if (flagSummaryArray.includes('SP')) oe.responseIdApi.tooltip += `<li>Speed</li>`;
                  if (flagSummaryArray.includes('VB')) oe.responseIdApi.tooltip += `<li>Verbose</li>`;
                  if (flagSummaryArray.includes('CS')) oe.responseIdApi.tooltip += `<li>Console</li>`;
                  if (flagSummaryArray.includes('TL')) oe.responseIdApi.tooltip += `<li>Translation</li>`;
                  oe.responseIdApi.tooltip += `</ul>`;
                }
                else oe.responseIdApi.tooltip += `<div class="text-left ml-2 pl-1">None</div>`;

              oe.analysis.tooltip += `</div>`
            }

            // create response tooltip
            let responseFlags = 0;
            if (oe.answer == null || oe.answer == '') responseFlags++;
            else {
              if (oe.analysis?.dupeQuestionCount > 0 ) responseFlags++;
              if (oe.analysis?.dupeRespondentCount > 0 ) responseFlags++;
              if (oe.analysis.riskLevel == 'non answer') responseFlags++;
            }
            oe.tooltip = `<div class="font-weight-normal nav-font14 grey5">
            <div class="d-flex align-items-center nav-font14 font-weight-bold grey5">
                <div class="mr-2">Open-end Answer Flags:</div>
                <div class="ml-auto row mx-0">
                  <div class="icon-container">
                    <span class="mr-2 text-left nav-font14">${responseFlags}</span>
                  </div>
                </div>
            </div>
            <div class="mt-4 mb-2 text-left font-weight-bold">Fraudulent events flagged:</div>`
            if (responseFlags > 0) {
              oe.tooltip += `<ul class="text-left pl-4 ml-1 mb-0">`
              if (oe.answer == null || oe.answer == '') oe.tooltip += `<li>Blank</li>`
              else {
                if (oe.analysis?.dupeQuestionCount > 0 ) oe.tooltip += `<li>Duplicate Across Answers (${oe.analysis.dupeQuestionCount})</li>`
                if (oe.analysis?.dupeRespondentCount > 0 ) oe.tooltip += `<li>Duplicate Across Respondents (${oe.analysis.dupeRespondentCount})</li>`
                if (oe.analysis.riskLevel == 'non answer') oe.tooltip += `<li>Non-Answer</li>`
              }

              oe.tooltip += `</ul>`;
            }
            else oe.tooltip += `<div class="text-left ml-2 pl-1">None</div>`

             oe.tooltip += `</div>`

            if (oe.analysis != null) {
              if (oe.analysis.riskLevel == 'low') oe.analysis.riskLevelIcon = 'fal fa-square-check nav-success '
              else if (oe.analysis.riskLevel === 'high') oe.analysis.riskLevelIcon = 'far fa-octagon-xmark nav-error'
              else if (oe.analysis.riskLevel != null && oe.analysis.riskLevel != '') oe.analysis.riskLevelIcon = 'fal fa-triangle-exclamation mediumRiskColour'

              if (oe.analysis.riskLevel != null) {
                oe.analysis.riskLevelLabel = oe.analysis.riskLevel.toLowerCase() == 'low' ? 'Good' : (oe.analysis.riskLevel.toLowerCase() === 'high' ? 'Bad' : 'Warn');


                var contentScore = oe.analysis.contentScore;
                if (contentScore >= 3) oe.analysis.contentLabel = 'Good', oe.analysis.contentIcon = 'fal fa-square-check nav-success';
                else if (contentScore == 2) oe.analysis.contentLabel = 'Warn', oe.analysis.contentIcon = 'fal fa-triangle-exclamation mediumRiskColour';
                else oe.analysis.contentLabel = 'Bad', oe.analysis.contentIcon = 'far fa-octagon-xmark nav-error';

                var contextScore = oe.analysis.contextScore;
                if (contextScore >= 3) oe.analysis.contextLabel = 'Good', oe.analysis.contextIcon = 'fal fa-square-check nav-success';
                else if (contextScore == 2) oe.analysis.contextLabel = 'Warn', oe.analysis.contextIcon = 'fal fa-triangle-exclamation mediumRiskColour';
                else oe.analysis.contextLabel = 'Bad', oe.analysis.contextIcon = 'far fa-octagon-xmark nav-error';

                var vulgarityScore = oe.analysis.vulgarityScore;
                if (vulgarityScore <= 1) oe.analysis.vulgarityLabel = 'Good', oe.analysis.vulgarityIcon = 'fal fa-square-check nav-success';
                else if (vulgarityScore <= 3) oe.analysis.vulgarityLabel = 'Warn', oe.analysis.vulgarityIcon = 'fal fa-triangle-exclamation mediumRiskColour';
                else oe.analysis.vulgarityLabel = 'Bad', oe.analysis.vulgarityIcon = 'far fa-octagon-xmark nav-error';

                // create tooltip
                oe.analysis.tooltip = `<div class="font-weight-normal nav-font14 grey5">
                  <div class="d-flex align-items-center nav-font14 font-weight-bold grey5">
                      <div>Summary:</div>
                      <div class="ml-auto row mr-3">
                        <div class="icon-container">
                          <i class="${oe.analysis.riskLevelIcon} mr-2 text-left nav-font14"></i>
                        </div>
                          ${oe.analysis.riskLevelLabel}
                      </div>
                  </div>`
                if (oe.analysis.summary != null && oe.analysis.riskLevel != 'non answer') {
                  oe.analysis.tooltip += `<div class="my-3 pb-3 text-left"> ${oe.analysis.summary}</div>`
                }
                else if (oe.analysis.summary != null) {
                  oe.analysis.tooltip += `<div class="mt-3 text-left"> ${oe.analysis.summary}</div>`
                }
                if (oe.analysis.riskLevel == 'non answer') {
                  oe.analysis.tooltip += `<div class="mb-1 mt-3 text-left"> Answer was vague.</div>`
                }
                oe.analysis.tooltip += `<hr class="grey1 mb-3">
                  <div class="row text-nowrap mt-3">
                      <div class="col-5 font-weight-bold text-left pr-1">Answer analysis:</div>
                      <div class="col-3 grey4 padding-left14 pr-0 mr-3">
                          <div class="mb-2 text-left">Content: </div>
                          <div class="mb-2 text-left">Context: </div>
                          <div class="mb-2 text-left">Vulgarity: </div>
                      </div>
                      <div class="col font-weight-bold px-1 ml-3">
                           <div class="row mb-2">
                            <div class="icon-container text-center">
                              <i class="${oe.analysis.contentIcon} nav-font14 mr-2"></i>
                            </div>
                            ${oe.analysis.contentLabel}
                          </div>
                          <div class="row mb-2">
                            <div class="icon-container text-center">
                              <i class="${oe.analysis.contextIcon} nav-font14 mr-2"></i>
                            </div>
                            ${oe.analysis.contextLabel}
                          </div>
                          <div class="row mb-2">
                            <div class="icon-container text-center">
                              <i class="${oe.analysis.vulgarityIcon} nav-font14 mr-2"></i>
                            </div>
                            ${oe.analysis.vulgarityLabel}
                          </div>
                      </div>
                  </div>
                </div>`
              }
            }

          }
        }
      });

      this.flags.forEach(q => {
        let flagCol = respondent.questions.find(x => x.id == q.id)
        respondent.flags[q.id] = flagCol;
      });
    });
    return qcFlowRecords;
  }

  processFile(file) {
    this.fileProcessed = false;
    this.usedDecipher = false;
    this.actualUploadedFileName = file?.origFile.name;
    this.percentComplete = 0;
    this.loadProgressInterval = null;

    if (this.uploadedFile !== null && this.uploadedFile !== '') {
      const fileName = this.uploadedFile.substring(this.uploadedFile.lastIndexOf('/') + 1);
      const url = this.replaceAll(this.uploadedFile, '/', '@');
      this.uploadedFileId = fileName.slice(0, -4);
      this.qcFlowService.UploadFile(this._project.id, fileName, url).subscribe((data) => {
        if (data) {
          if(!data?.error) {
            if (!this.qcFlowDetails || this.qcFlowDetails.status.fileHistory.length == 0) {
              this.qcFlowDetails = data?.details;

              // get only active QC files
              const nonDeletedFiles = this.qcFlowDetails.status.fileHistory.filter(file => !file.isDeleted);
              this.qcFlowDetails.status.fileHistory = nonDeletedFiles;

              this.respondentIdColumnIndex = null;
              this.respondentIdColumnId = null;
              this.updateColumns();
              this.updateIdList();
            }

            this.rowCount = data?.rowCount;
            this.fileProcessed = true;
          }

          else {
            const error = data.errorDetails
            if (error?.reason === 'structure') {
              this.wrongColumns = error?.data;
              this.openModalRef = this.modalService.show(this.modalRefFileError, { ignoreBackdropClick: true, class: 'modal-dialog-centered'});
            }
          }
        }
      }, error => {
        this._project.errorMessage = error;
        this.alertify.error(error);
      });
    }
  }

  replaceAll(str, find, replace) {
    let escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

  setColumnType(col: QCFlowFileColumn, values, type) {
    let isChecked = (<HTMLInputElement>values.target).checked

    if (isChecked) col.type = type;
    else col.type = null;

    col.selected = isChecked;

    this.updateColumns();
    this.checkCanUpload();
  }

  checkCanUpload(isInit = false) {
    if (!this.settingsExist) {
      const anySelected = this.filteredOpenEndColumns.some(x => x.selected);
      const anySelectedWithoutText = this.filteredOpenEndColumns.some(x => x.selected && !x.text.trim());

      if (!isInit && anySelectedWithoutText) {
        this.alertify.error('Enter the question text to proceed');
      }

      this.canUpload = (this.fileProcessed && this.respondentIdColumnIndex !== null && anySelected && !anySelectedWithoutText);
    }
    else this.canUpload = true;
  }

  selectAllColumns(values, type) {
    let isChecked = (<HTMLInputElement>values.target).checked
    if (type === 'flag') {
      this.filteredFlagColumns.map(x => {
        x.selected = isChecked;
        x.type = isChecked ? type : null;
      })
    }
    else if (type === 'open-end') {
      this.filteredOpenEndColumns.map(x => {
        x.selected = isChecked;
        x.type = isChecked ? type : null;
      })
    }

    this.updateColumns();
  }

  getScrollbarMarginLeft() {
    var marginLeft = this.questionLevelLeft;
    if (this.expandFlags) marginLeft += (90 * this.flags.length);
    return marginLeft-1 + 'px';
  }

  getLastRowHeight() {
      // Get the container div
    const container = document.getElementsByClassName('dataCleaningTableContainer')[0];

    // get me the difference in pixels between th ebottom of the container and the top of th elast row with id 'filler-row'
    const lastRow = document.getElementById('filler-row');
    const containerTop = container.getBoundingClientRect().top;
    const lastRowTop = lastRow.getBoundingClientRect().top;

    const diff = 509 - (lastRowTop - containerTop);
    return diff + 'px';
  }

  selectAllRecords(val) {
    this.filteredRecords.forEach(record => {
      record.selected = val && record.showRow;
    });
    this.selectAllRespondents = val;
    this.oneRecordSelected = val;
  }

  selectRecord(respondent) {
    if (!respondent.selected && this.selectAllRespondents) {
      this.checkForSelectedRecord();
      this.selectAllRespondents = false;
    }
    if (respondent.selected) this.oneRecordSelected = true;
  }

  getFileRespondentIDs() {
    this.qcFlowService.getFileRespondentIDs(this._project.id, this.filesFiltered).subscribe(respondentIds => {
      this.filteredFileRecordIds = respondentIds;
      this.updateTable();
    }, error => {
      this.alertify.error('Unable to filter by file')
    })
  }

  checkForSelectedRecord() {
    let oneRecordSelected = false;
    for (let i = 0, len = this.filteredRecords.length; i < len; i++) {
      if (this.filteredRecords[i].selected) {
        oneRecordSelected = true;
        break
      }
    }
    this.oneRecordSelected = oneRecordSelected;
  }

  resetAllToPending() {
    let respondents = this.qcFlowRecords;
    this.saveBulkDecision(respondents, 'Pending', 'Bulk');
  }

  applyBulkAction(type, decision) {
    let respondents = this.filteredRecords.filter(x => x.selected);

    if (type === 'assessment') {
      let questionIds = []
      if (this.bulkActionAssessmentQuestion != 'All questions') questionIds.push(this.bulkActionAssessmentQuestion);
      else questionIds = this.openEnds.map(o => { return o.id })

      this.saveBulkAssessment(respondents, questionIds, decision);
    }
    else if (type === 'status') {
      this.saveBulkDecision(respondents, decision, 'Bulk')
    }
    else if (type === 'ai') {
    }
  }

  saveBulkAssessment(respondents, questionIds, assessment) {

    //map respondents to a change log for assessments
    let assessmentChanges = [];
    respondents.map(respondent => {
      questionIds.map(qId => {
        let change = {
          respondentId: respondent.respondentId,
          questionId: qId,
          originalAssessment: respondent.openEnds[qId].myAssessment,
          assessment: assessment
        };
        respondent.openEnds[qId].myAssessment = assessment;
        assessmentChanges.push(change);
      })
    });


    let changes = this.calculateRuleChanges(respondents, false);
    let decisions = [];
    if (changes.length > 0) {
      decisions = this.applyRulesBulk(changes);
    }

    this.addToChangeLog(decisions, assessmentChanges);

    this.bulkActionDropdown.toggle(true);
  }

  saveBulkDecision(respondents, decisionType, source) {

    let decisions = respondents.map(respondent => ({
      respondentId: respondent.respondentId,
      originalType: respondent.decision.type,
      type: decisionType,
      originalSource: respondent.decision.source,
      source: source,
      originalRuleId: respondent.decision.ruleId,
      ruleId: null
    }));

    respondents.forEach(respondent => {
      respondent.decision.type = decisionType;
      respondent.decision.source = source;
      respondent.decision.ruleId = null;
    });

    this.addToChangeLog(decisions, null);

    this.bulkActionDropdown.toggle(true);
  }

  updateRIDCol(newRIDCol, dropdown) {
    let col = this.qcFlowDetails.settings.columns.find(x => x.id === newRIDCol)
    if (col == null) {
      this.respondentIdColumnIndex = null;
      this.respondentIdColumnId = null;
      if (dropdown) {
        if (dropdown.isOpen) dropdown.toggle(true);
        dropdown.blur();
      }
    }
    else {
      col.type = null;
      col.selected = false;
      this.respondentIdColumnIndex = col.index;
      this.respondentIdColumnId = newRIDCol;
      this.respondentIdFilterText = newRIDCol;
      this.filteredIdColumns = this.qcFlowDetails?.settings.columns;
    }
    this.updateColumns();
  }

  setIdIfExists(event) {
    if (event.key === 'Enter') {
      let col = this.qcFlowDetails.settings.columns.find(x => x.id.toLowerCase() === this.respondentIdFilterText.toLowerCase())
      if (col) {
        this.respondentIdSearch.nativeElement.blur();
        return true;
      }
    }
    return false;
  }

  setIdIfExistsOnBlur(dropdown) {
    // set timeout of 5 ms to allow the click event to fire first
    setTimeout(() => {
      let col = this.qcFlowDetails.settings.columns.find(x => x.id.toLowerCase() === this.respondentIdFilterText.toLowerCase())
      if (col) {
        this.updateRIDCol(col.id, null);
      }
      else {
        this.respondentIdFilterText = '';
        this.updateIdList();
      }
      if (dropdown.isOpen) dropdown.toggle(true);
    }, 5);

  }

  updateIdList() {
    const idFilter = this.respondentIdFilterText.toLowerCase();
    if (idFilter === '') {
      this.filteredIdColumns = this.qcFlowDetails?.settings.columns;
    }
    else {
      this.filteredIdColumns = this.qcFlowDetails.settings.columns.filter(e => e.id.toLowerCase().indexOf(idFilter) >= 0)

    }
    this.updateRIDCol(null, null);
  }

  updateColumns() {

    //if no flags we remove that column
    if (this.qcFlowRecords != null &&  this.flags.length == 0) {
      this.respondentLevelColumns = this.respondentLevelColumns.filter(x => x.value != 'total-flags');
    } 
    //if in client view and sample only opinion route project we remove RID Result column
    if (this.sampleOnlyORProject && this.isClient) {
      this.questionLevelColumns = this.questionLevelColumns.filter(x => x.value != 'rid-score');
      this.qcRuleSubjectArray = this.qcRuleSubjectArray.filter(x => x.key != 'RID');
    }
    let lastCol = this.respondentLevelColumns[this.respondentLevelColumns.length - 1];
    this.questionLevelLeft = lastCol.left + lastCol.width;

    const openEndFilter = this.openEndFilterText.toLowerCase();
    const flagFilter = this.flagFilterText.toLowerCase();
    const rIndex = this.respondentIdColumnIndex;

    if (openEndFilter === '') {
      this.filteredOpenEndColumns = this.qcFlowDetails.settings.columns.filter(x => x.type != 'flag' && x.index != rIndex);
    }
    else {
      this.filteredOpenEndColumns = this.qcFlowDetails.settings.columns.filter(e => e.type != 'flag' && e.id.toLowerCase().indexOf(openEndFilter) >= 0 && e.index != rIndex)
    }

    if (flagFilter === '') {
      this.filteredFlagColumns = this.qcFlowDetails.settings.columns.filter(x => x.type != 'open-end' && x.index != rIndex);
    }
    else {
      this.filteredFlagColumns = this.qcFlowDetails.settings.columns.filter(e => e.type != 'open-end' && e.id.toLowerCase().indexOf(flagFilter) >= 0 && e.index != rIndex)
    }

    this.selectedFlagCols = this.qcFlowDetails.settings.columns.filter(x => x.type === 'flag' && x.selected);
    this.selectedOpenEndCols = this.qcFlowDetails.settings.columns.filter(x => x.type === 'open-end' && x.selected)
    this.checkCanUpload();
  }
  Execute() {
     // save to file history
     let fileRecord: QCFlowFile = {
      id: this.uploadedFileId,
      fileName: 'decipherAPI',
      rowCount: 0,
      submittedById: this.auth.getUser().id,
      submittedByName: this.auth.getUser().name,
      submittedByImage: this.auth.getUser().image,
      submittedOn: new Date().toISOString(),
      isDeleted: false
    }
    if (!this.settingsExist) {
      this.qcFlowDetails.settings.showMyAssessment = true;
      fileRecord.ruleCheckCompleted = true;

      this.filteredFlagColumns.forEach(flag => {
        let col = this.qcFlowDetails.settings.columns.find(x => x.index == flag.index);
        if (col != null) {
          col.type = flag.type;
          col.selected = flag.selected;
        }
      });
      this.filteredOpenEndColumns.forEach(openEnd => {
        let col = this.qcFlowDetails.settings.columns.find(x => x.index == openEnd.index);
        if (col != null) {
          col.type = openEnd.type;
          col.selected = openEnd.selected;
        }
      });
      let idIndex = this.respondentIdColumnIndex;
      if (idIndex != null) {
        let respondentCol = this.qcFlowDetails.settings.columns.find(x => x.index == idIndex)
        respondentCol.type = 'respondent-id';
        respondentCol.selected = true;
      }

      this.qcFlowDetails.status.respondentCount = 0;
      this.qcFlowDetails.status.respondentProcessedCount = 0;
      this.qcFlowDetails.status.currentStatus = 'Processing';
      this.qcFlowDetails.status.fileHistory.push(fileRecord);  
      this.usedDecipher = true;
      this.saveQCFlowDetails();
    }

    else { // if not first file
      this.addFileToQCFlow(fileRecord)
    }
  }
  
  uploadComplete() {
    if (this.uploadedFile && this.uploadedFileId) {
      // save to file history
      let fileRecord: QCFlowFile = {
        id: this.uploadedFileId,
        fileName: this.actualUploadedFileName,
        rowCount: this.rowCount,
        submittedById: this.auth.getUser().id,
        submittedByName: this.auth.getUser().name,
        submittedByImage: this.auth.getUser().image,
        submittedOn: new Date().toISOString(),
        isDeleted: false
      }

      if (!this.settingsExist) {
        this.qcFlowDetails.settings.showMyAssessment = true;
        fileRecord.ruleCheckCompleted = true,

        this.filteredFlagColumns.forEach(flag => {
          let col = this.qcFlowDetails.settings.columns.find(x => x.index == flag.index);
          if (col != null) {
            col.type = flag.type;
            col.selected = flag.selected;
          }
        });
        this.filteredOpenEndColumns.forEach(openEnd => {
          let col = this.qcFlowDetails.settings.columns.find(x => x.index == openEnd.index);
          if (col != null) {
            col.type = openEnd.type;
            col.selected = openEnd.selected;
          }
        });
        let idIndex = this.respondentIdColumnIndex;
        if (idIndex != null) {
          let respondentCol = this.qcFlowDetails.settings.columns.find(x => x.index == idIndex)
          respondentCol.type = 'respondent-id';
        }
        this.qcFlowDetails.status.respondentCount = this.rowCount;
        this.qcFlowDetails.status.respondentProcessedCount = 0;
        this.qcFlowDetails.status.currentStatus = 'Processing';
        this.qcFlowDetails.status.fileHistory.push(fileRecord);
        this.saveQCFlowDetails();
      }

      else { // if not first file
        this.processing = true;
        this.addFileToQCFlow(fileRecord)
      }
    }
  }

  downloadUploadedFile(file) {
    this.qcFlowService.GetFile(this._project.id, file.id).subscribe(data => {
      this.FileSaver.saveAs(data, file.fileName);
    });
  }

  openQuestionSummary() {
    this.inQCFlow = true;
    this.qcFlowStage = 'question-summary'
  }

  openMyData() {
    if (this.qcFlowDetails && !this.pending && !this.processing) {
      this.inQCFlow = true;
      if (this.qcFlowRecords) {
        this.prevScrollTop = 0;
        this.prevScrollLeft = 0;
        this.qcFlowStage = 'data-cleaning';
        setTimeout(() => {
          this.checkOverflow();
        }, 100);

      }
    }
  }

  openQCRules() {
    if (this.qcFlowRules.length == 0) {
      this.calculateRuleChanges(this.qcFlowRecords, true);
    }
    else {
      this.qcFlowRules.map(rule => {
        rule.checks.map(check => {
          this.populateQcQuestions(check);
        });
        this.doAllRuleChecks(rule);
        return rule;
      });
    }

    this.openModalRef = this.modalService.show(this.modalRefQCRules, { ignoreBackdropClick: true, class: 'modal-lg modal-dialog-centered modal-qc-rules' });
  }

  openRespondent(respondent) {
    this.setCurrentRespondent(respondent);
    this.openModalRef = this.modalService.show(this.modalRefRespondent, { ignoreBackdropClick: true, class: 'modal-lg modal-dialog-centered modal-respondent-view' });
    this.checkOverflowRespondentView();
  }

  confirmReconcile(event) {

    //there was a bug where the button would stay focused after clicking it and then tooltip would re-appear
    const targetElement = event.target as HTMLElement;
    if (targetElement) {
      targetElement.blur();
    }

    if (!this.pending && !this.processing) {
      this.createRespondentOverviewChart();
      this.openModalRef = this.modalService.show(this.modalRefReconcile, { ignoreBackdropClick: true, class: ' modal-dialog-centered modal-reconcile-confirm' });
    }
  }

  syncScroll(event) {

    if (this.qcFlowStage === 'data-cleaning') {
      // close all dropdowns
      this.dropdownQCStatuses.forEach(dropdown => {
        if (dropdown.isOpen) dropdown.toggle(true);
      });

      const currentScrollTop = (event.target as HTMLElement).scrollTop;
      const currentScrollLeft = (event.target as HTMLElement).scrollLeft;

      const container = document.querySelector('.dataCleaningTableContainer');
      const thead = document.querySelector('thead');

      // if scrolling vertically
      if (currentScrollTop > this.prevScrollTop || currentScrollTop < this.prevScrollTop) {
        if (container && thead) {
          thead.style.transform = `translateY(${container.scrollTop}px)`;
          thead.style.zIndex = '3';
        }
      }

      // Update the previous scroll positions
      this.prevScrollTop = currentScrollTop;
      this.prevScrollLeft = currentScrollLeft;
    }
  }

  syncScrollRespondentView(event) {
    if (this.qcFlowStage === 'data-cleaning') {
      const currentScrollTop = (event.target as HTMLElement).scrollTop;
      const currentScrollLeft = (event.target as HTMLElement).scrollLeft;

      const container = document.querySelector('.questionLevel ')[0];
      const thead = document.querySelector('.questionLevelHead')[0];

      // if scrolling vertically
      if (currentScrollTop > this.prevScrollTop || currentScrollTop < this.prevScrollTop) {
        if (container && thead) {
          thead.style.transform = `translateY(${container.scrollTop}px)`;
          thead.style.zIndex = '3';
        }
      }

      // Update the previous scroll positions
      this.prevScrollTop = currentScrollTop;
      this.prevScrollLeft = currentScrollLeft;
    }
  }

  applyFilters(type, filters, openEndIndex) {
    if (type === 'qc-status') {
      let col = this.respondentLevelColumns.find(x => x.value === type);
      col.filters = filters;
    }
    else if (type === 'file') {
      this.filesFiltered = filters;
    }
    else {
      let openEndCol = this.questionLevelColumns.find(x => x.value === type);
      openEndCol.filters[openEndIndex] = filters;
    }

    if (type != 'file' || this.filesFiltered.length == 0) {
      this.updateTable();
    }
    else {
      this.getFileRespondentIDs();
    }
  }

  sortTable(sortBy, id = null) {
    let A;
    let B
    let alreadySortedAsc = true;
    this.qcFlowRecords.sort((a, b) => {

      if (sortBy === 'respondent-id') {
        A = a.respondentId.toLowerCase();
        B = b.respondentId.toLowerCase();
      }
      else if (sortBy === 'qc-status') {
        A = a.decision.type;
        B = b.decision.type;
      }
      else if (sortBy === 'total-flags') {
        A = a.qcFlagCount;
        B = b.qcFlagCount;
      }
      else if (sortBy === 'flag' && id != null) {
        A = a.flags[id].answer;
        B = b.flags[id].answer;
      }
      else if (sortBy === 'open-end' && id != null) {
        A = a.openEnds[id].answer?.toLowerCase();
        B = b.openEnds[id].answer?.toLowerCase();
      }
      else if (sortBy === 'rid-score' && id != null) {
        A = a.openEnds[id].responseIdApi.score;
        B = b.openEnds[id].responseIdApi.score;
      }
      else if (sortBy === 'ai-advice' && id != null && this.qcFlowDetails.settings.enhanceWithAI) {
        A = this.getAssessmentOrder(a.openEnds[id].analysis.riskLevelLabel?.toLowerCase());
        B = this.getAssessmentOrder(b.openEnds[id].analysis.riskLevelLabel?.toLowerCase());
      }
      else if (sortBy === 'my-assessment' && id != null) {
        A = this.getAssessmentOrder((a.openEnds[id].myAssessment == null) ? '' : a.openEnds[id].myAssessment.toLowerCase());
        B = this.getAssessmentOrder((b.openEnds[id].myAssessment == null) ? '' : b.openEnds[id].myAssessment.toLowerCase());
      }
      let value = this.sort(A, B);

      if (value < 0) alreadySortedAsc = false
      return value;
    })
    if (alreadySortedAsc) this.qcFlowRecords.reverse()

    this.updateTable()
  }

  getAssessmentOrder(value) {
    switch (value) {
      case 'non answer':
        value = 0;
        break;
      case 'high':
        value = 1;
        break;
      case 'medium':
        value = 2;
        break;
      case 'low':
        value = 3;
        break;
      default:
        value = 5
        break;
    }
    return value;
  }


  sort(A, B) {
    let comparison = 0;
    if (A > B) {
      comparison = 1;
    } else if (A < B) {
      comparison = -1;
    }
    return comparison;
  }

  updateRespondentViewTable() {
    const respondentViewFilter = this.respondentViewFilterText.toLowerCase();
    let filteredOpenEnds = {};

    // first filter based on search text
    if (respondentViewFilter === '') {
      filteredOpenEnds = this.currentRespondent.openEnds;
    }
    else {
      for (let i in this.currentRespondent.openEnds) {
        let e = this.currentRespondent.openEnds[i]
        if (e.answer != null && e.answer.toLowerCase().indexOf(respondentViewFilter) >= 0) {
          filteredOpenEnds[e.id] = e;
        }
      }
    }
    this.currentRespondent.filteredOpenEnds = filteredOpenEnds;
  }
  updateTextFilter(){

    if(this.textSearchInterval) clearInterval(this.textSearchInterval);
    this.textSearchInterval = setInterval(() => {
      clearInterval(this.textSearchInterval);
      this.updateTable();
    }, 500);
  }

  updateTable() {
    const prevFiltered = JSON.stringify(this.filteredRecords);
    const dataCleaningFilter = this.dataCleaningFilterText.toLowerCase();

    let statusColumn = this.respondentLevelColumns.find(col => col.value === 'qc-status' && col.filters.length > 0);
    let statusFilters = statusColumn ? statusColumn.filters : [];
    let assessmentFilters = [];
    this.questionLevelColumns.forEach(element => {
      //if element.filteres is not empty object
      if(element.filters != null && Object.keys(element.filters).length > 0){
        assessmentFilters.push({ value: element.value, filters:element.filters});
      }
    });

    let filteredRecords =  this.qcFlowRecords;
    filteredRecords.forEach(record => {
      record.showRow = true;

      if (this.filesFiltered.length > 0) {
        record.showRow = this.filteredFileRecordIds[record.respondentId];
      }

      if (record.showRow && dataCleaningFilter !== '') {
        let textFound = false;
        if(record.respondentId.toLowerCase().indexOf(dataCleaningFilter) >= 0) textFound = true;
        for (let i in record.openEnds) {
          if (record.openEnds[i].answer != null && record.openEnds[i].answer.toLowerCase().indexOf(dataCleaningFilter) >= 0) {
            textFound = true;
            break
          }
        }
        record.showRow = textFound;
      }
      if(record.showRow && statusFilters.length > 0){
        record.showRow = statusFilters.includes(record.decision.type);
      }

      if(record.showRow && assessmentFilters.length > 0){
        let matchesFilter = true;
        //loop through all the filters
        for (let filter of assessmentFilters) {
          if (filter.value == 'my-assessment') {
            for (let oeId in filter.filters) {
              if (filter.filters[oeId].length > 0  && !filter.filters[oeId].includes(record.openEnds[oeId].myAssessment)) matchesFilter = false;
            }
          }

          if (filter.value == 'rid-score') {
            for (let oeId in filter.filters) {
              let score = record.openEnds[oeId].responseIdApi.score
              if (filter.filters[oeId].length > 0  && !(
                    (filter.filters[oeId].includes('low') && score >= 0 && score <= 20)
                    || (filter.filters[oeId].includes('medium') && score >= 21 && score <= 25)
                    || (filter.filters[oeId].includes('high') && score >= 26)
                  )) matchesFilter = false;
            }
          }

          if (filter.value == 'ai-advice') {
            for (let oeId in filter.filters) {
              let aiFilters = filter.filters[oeId];
              if (aiFilters.length > 0) {

                //top level ai filter
                if (aiFilters.includes('low') || aiFilters.includes('medium') || aiFilters.includes('high')) {
                  var riskLevel = record.openEnds[oeId].analysis.riskLevel;
                  if (riskLevel == 'non answer') riskLevel = 'medium';
                  if(!aiFilters.includes(riskLevel)) matchesFilter = false;
                }
                //content
                if (aiFilters.includes('low-content') || aiFilters.includes('medium-content') || aiFilters.includes('high-content')) {
                  var label = record.openEnds[oeId].analysis.contentLabel;
                  if(! ( (aiFilters.includes('low-content') && label == 'Good')
                  || (aiFilters.includes('medium-content') && label == 'Warn')
                  || (aiFilters.includes('high-content') && label == 'Bad')) )
                    matchesFilter = false;
                }
                //context
                if (aiFilters.includes('low-context') || aiFilters.includes('medium-context') || aiFilters.includes('high-context')) {
                  var label = record.openEnds[oeId].analysis.contextLabel;
                  if(! ( (aiFilters.includes('low-context') && label == 'Good')
                  || (aiFilters.includes('medium-context') && label == 'Warn')
                  || (aiFilters.includes('high-context') && label == 'Bad')) )
                    matchesFilter = false;
                }
                //vulgarity
                if (aiFilters.includes('low-vulgarity') || aiFilters.includes('medium-vulgarity') || aiFilters.includes('high-vulgarity')) {
                  var label = record.openEnds[oeId].analysis.vulgarityLabel;
                  if(! ( (aiFilters.includes('low-vulgarity') && label == 'Good')
                  || (aiFilters.includes('medium-vulgarity') && label == 'Warn')
                  || (aiFilters.includes('high-vulgarity') && label == 'Bad')) )
                    matchesFilter = false;
                }
              }
            }
          }
          if (filter.value == 'open-end') {
            for (let oeId in filter.filters) {
              if(filter.filters[oeId].length > 0){
                let matchAnyFilter = false;
                if (filter.filters[oeId].includes('blank') && (record.openEnds[oeId].answer == null || record.openEnds[oeId].answer == '')) matchAnyFilter = true;
                if (filter.filters[oeId].includes('non answer') && record.openEnds[oeId].analysis.riskLevel == 'non answer') matchAnyFilter = true;
                if (filter.filters[oeId].includes('dupeAnswer') && record.openEnds[oeId].analysis.dupeQuestionCount > 0) matchAnyFilter = true;
                if (filter.filters[oeId].includes('dupeRespondent') && record.openEnds[oeId].analysis.dupeRespondentCount > 0) matchAnyFilter = true;

                if(!matchAnyFilter) matchesFilter = false;
              }

            }
          }
        }
        record.showRow = matchesFilter;
      }

    });

    const container = document.querySelector('.dataCleaningTableContainer');
    if (container) {
      container.scrollTop = 0;
    }

    //get count of all records with showRow = true
    this.filteredRecords = filteredRecords.filter(x => x.showRow);
    this.filteredRowCount = this.filteredRecords.length;
    this.currentPage = 1;
    this.dataLoading = false;

    if (JSON.stringify(this.filteredRecords) !== prevFiltered) this.selectAllRespondents = false;
    this.checkForSelectedRecord();
    this.checkOverflow();
  }
  onPageChange(page) {
    this.currentPage = page;
    // change scroller to 0
    const container = document.querySelector('.dataCleaningTableContainer');
    if (container) {
      container.scrollTop = 0;
    }
  }
  setCurrentRespondent(respondent){
    let index = this.filteredRecords.findIndex(x => x.respondentId == respondent.respondentId);

    let currentRespondent = {
      index: index,
      respondentId: respondent.respondentId,
      showMyAssessment: true,
      expandFlags: false,
      decision: respondent.decision,
      qcFlagCount: respondent.qcFlagCount,
      flags: respondent.flags,
      openEnds: respondent.openEnds,
      filteredOpenEnds: respondent.openEnds
    }

    if(this.openEnds.length < 3) {
      this.openEnds.forEach(oe => {
        currentRespondent.openEnds[oe.id].expand = true;

      });
    }
    this.currentRespondent = currentRespondent;
  }

  myAssessmentChange(respondent, openEnd, assessment) {

    let newValue = respondent.openEnds[openEnd.id].myAssessment == assessment ? null : assessment;

    let changedAssessment = {
      respondentId: respondent.respondentId,
      questionId: openEnd.id,
      originalAssessment: respondent.openEnds[openEnd.id].myAssessment,
      assessment: newValue
    }
    respondent.openEnds[openEnd.id].myAssessment = newValue;

    let decisions = null;
    let matchDetails = this.checkRulesForRecord(respondent, false);
    if (matchDetails != null) {
      decisions = this.applyRulesBulk([matchDetails]);
    }

    this.addToChangeLog(decisions, [changedAssessment]);

  }

  enhanceWithAI(newValue) {
    if (!this.qcFlowDetails.settings.enhanceWithAI || !this.aiConditionExists) {
      this.saveSettings('enhanceWithAI', newValue);
      if(newValue) {
        this.pending = true;
        this.pollForUpdate();
      }
    }
  }
  toggleShowMyAssessment() {
    this.saveSettings('showMyAssessment', this.qcFlowDetails.settings.showMyAssessment);
  }
  saveSettings(setting, newValue) {
    if (this.qcFlowStage === 'settings' && (!this.canUpload || !this.fileProcessed)) return;
    else {
      this.qcFlowService.SaveSetting(this._project.id, setting, newValue).subscribe((data) => {
        if (setting === 'enhanceWithAI') {
          this.qcFlowDetails.settings.enhanceWithAI = newValue;
          if (newValue == true) this.alertify.success('AI successfully applied.')
        }
      }, error => {
        if (setting === 'enhanceWithAI') {
          if (newValue == true) this.alertify.error('Couldn\'t apply AI.')
          if (newValue == false) this.alertify.error('Couldn\'t remove AI.')
        }
      });
    }
  }

  decisionChange(respondent, decisionType, source) {
    let change = {
      respondentId: respondent.respondentId,
      originalType: respondent.decision.type,
      type: decisionType,
      originalSource: respondent.decision.source,
      source: source,
      originalRuleId: respondent.decision.ruleId,
      ruleId: null
    }
    respondent.decision.type = decisionType;

    this.addToChangeLog([change], null);
  }
  addToChangeLog(decisions, assessments) {
    if (this.changeLog.length == 0) {
      this.changeLog.push({ decisions: decisions, assessments: assessments })
      this.changeIdx = 0;
    }
    else {
      this.changeLog = this.changeLog.slice(0, this.changeIdx + 1)
      this.changeLog.push({ decisions: decisions, assessments: assessments });
      this.changeIdx = this.changeLog.length - 1;
    }
    this.updateStatusCounts();
  }
  undoAction() {
    if (this.changeIdx < 0) return;

    let action = this.changeLog[this.changeIdx];
    if (action.decisions != null) {
      action.decisions.forEach((decision) => {
        let respondent = this.qcFlowRecords.find(x => x.respondentId == decision.respondentId);
        if (respondent && respondent.decision) {
          respondent.decision.type = decision.originalType;
          respondent.decision.source = decision.originalSource;
          respondent.decision.ruleId = decision.originalRuleId;
          this.highlightChangedItems[respondent.respondentId] = true;
        }
      });
    }

    if (action.assessments != null) {
      action.assessments.forEach((assessment) => {
        let respondent = this.qcFlowRecords.find(x => x.respondentId == assessment.respondentId);
        if (respondent && respondent.openEnds[assessment.questionId]) {
          respondent.openEnds[assessment.questionId].myAssessment = assessment.originalAssessment;
          this.highlightChangedItems[respondent.respondentId] = true;
        }
      });
    }
    this.changeIdx -= 1;

    this.clearHightlightChangedItems();
    this.updateStatusCounts();

  }
  redoAction() {
    if (this.changeIdx >= this.changeLog.length - 1) return;

    let action = this.changeLog[this.changeIdx + 1];
    if (action.decisions != null) {
      action.decisions.forEach((decision) => {
        let respondent = this.qcFlowRecords.find(x => x.respondentId == decision.respondentId);
        if (respondent && respondent.decision) {
          respondent.decision.type = decision.type;
          respondent.decision.source = decision.source;
          respondent.decision.ruleId = decision.ruleId;
          this.highlightChangedItems[respondent.respondentId] = true;
        }
      });
    }
    if (action.assessments != null) {
      action.assessments.forEach((assessment) => {
        let respondent = this.qcFlowRecords.find(x => x.respondentId == assessment.respondentId);
        if (respondent && respondent.openEnds[assessment.questionId]) {
          respondent.openEnds[assessment.questionId].myAssessment = assessment.assessment;
          this.highlightChangedItems[respondent.respondentId] = true;
        }
      });
    }
    this.changeIdx += 1;

    this.clearHightlightChangedItems();
    this.updateStatusCounts();
  }
  clearHightlightChangedItems() {
    setTimeout(() => {
      this.highlightChangedItems = {};
    }, 300);

  }
  updateStatusCounts() {
    this.qcFlowDetails.status.acceptedCount = this.qcFlowRecords.filter(x => x.decision.type === 'Accept').length;
    this.qcFlowDetails.status.rejectedCount = this.qcFlowRecords.filter(x => x.decision.type === 'Reject').length;
    this.qcFlowDetails.status.respondentProcessedCount = this.qcFlowRecords.length;
  }


  saveAllChanges(reconcile = false) {
    //loop through all changes from changeLog and create a record in an array of QCFlowBulkAction for each respondent with the latest decision and assessment for each question
    let changes = [];
    this.dataLoading = true;

    this.changeLog.forEach((change) => {

      if (change.decisions != null) {
        change.decisions.forEach((decision) => {
          let respondent = changes.find(x => x.respondentId == decision.respondentId);
          if (respondent) {
            respondent.type = decision.type;
            respondent.source = decision.source;
            respondent.ruleId = decision.ruleId;
          }
          else {
            changes.push({
              respondentId: decision.respondentId,
              type: decision.type,
              source: decision.source,
              ruleId: decision.ruleId,
              questions: []
            });
          }
        });
      }

      if (change.assessments != null) {
        change.assessments.forEach((assessment) => {
          let respondent = changes.find(x => x.respondentId == assessment.respondentId);
          if (respondent) {
            let question = respondent.questions.find(x => x.id == assessment.questionId);
            if (question) {
              question.myAssessment = assessment.assessment;
            }
            else {
              respondent.questions.push({ id: assessment.questionId, myAssessment: assessment.assessment })
            }
          }
          else {
            changes.push({
              respondentId: assessment.respondentId,
              decision: '',
              questions: [{ id: assessment.questionId, myAssessment: assessment.assessment }]
            });
          }
        });
      }
    }
    );

    //call service to Save All Records
    this.qcFlowService.SaveRecords(this._project.id, changes).subscribe((data) => {
      this.changeLog = [];
      this.changeIdx = -1;

      this.getQCFlowData();

      if(reconcile)
        this.reconcileIds();
      this.alertify.success('Changes saved successfully');
    }, error => {
      this.alertify.error(error);
      //this.alertify.error('Couldn\'t save changes');
    });

  }


  downloadAnalysis() {
    if (this.qcFlowDetails && !this.pending && !this.processing) {
      this.alertify.message('Your file will download once ready');
      const filename = formatDate(new Date(), 'MM-dd-yyyy', 'en').toString() + '-' + this._project.projectCode + '.xlsx';
      this.exportFilesService.downloadQCFlowAnalysis(this._project.id, filename).subscribe(url => {
        this.utils.downloadXLSX(url, filename);
      }, error => {
        this.alertify.error(error);
      }, () => {
        // All Good
      });
    }
  }

  prepareStringForCsv(inputString: string): string {
    if (inputString === '' || inputString == null) return ''
    let escapedString = inputString.replace(/"/g, '""');
    if (escapedString.includes(',') || escapedString.includes('"')) {
      escapedString = `"${escapedString}"`;
    }
    escapedString = escapedString.replace(/\n/g, ' ');

    return escapedString;
  }

  createRespondentOverviewChart() {
    let chartData = [];
    chartData.push({
      name: 'Accepted', y: this.qcFlowDetails.status.acceptedCount,
      innerSize: '50%',
      className: 'acceptedSlice'
    })
    chartData.push({ name: 'Pending', y: this.qcFlowDetails.status.respondentCount - (this.qcFlowDetails.status.acceptedCount + this.qcFlowDetails.status.rejectedCount), className: 'pendingSlice' })
    chartData.push({ name: 'Rejected', y: this.qcFlowDetails.status.rejectedCount, className: 'rejectedSlice' })

    let totalRespondents = this.qcFlowDetails.status.respondentCount;
    this.highchartsOptionsRespondentOverview = {
      credits: {
        enabled: false
      },
      chart: {
        animation: false,
        plotBorderWidth: null,
        plotBackgroundColor: null,
        plotShadow: false,
        margin: [0, 0, 0, 0],
        events: {
          render: function () {
          },
          load: this.onRespondentOverviewChartLoad.bind(this),
        },
      },

      title: {
        text: `<div class="text-center"><span class="grey5 nav-font24">${totalRespondents}</span><div class="nav-font12 font-weight-normal grey4 d-block">Respondents</div></div>`,
        align: 'center',
        useHTML: true,
        verticalAlign: 'middle',
        // y: 95
      },
      plotOptions: {
        pie: {
          states: {
            inactive: {
              enabled: false,
            },
          },
          shadow: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          borderRadius: '0px',
          showInLegend: false,
          point: {
            events: {
              legendItemClick: function () {
                return false;
              }
            }
          },
          slicedOffset: 5
        },
        series: {
          states: {
            hover: {
              animation: false,
              enabled: false,
            }
          },
          point: {
            events: {
              mouseOver: function (this, e) {
                let newRadius = this.shapeArgs.r + 4;
                this.graphic.animate({
                  r: newRadius,
                  innerR: '53px',
                }, {
                  duration: 300
                });

                this.graphic.shadow({
                  color: 'rgba(1, 59, 92, 0.16)', // Shadow color
                  offsetX: 4, // Shadow offset X
                  offsetY: 4, // Shadow offset Y
                  width: 10 // Shadow width
                });
              },
              mouseOut: function (this,) {
                let newRadius = this.shapeArgs.r;
                this.graphic.animate({
                  r: newRadius,
                  innerR: '57px'
                }, {
                  duration: 300
                });
                this.graphic.shadow(false);
              },
            },
          },
          events: {
            mouseOut: function (this) {
              this.chart.title.attr({
                text: `<div class="text-center"><span class="grey5 nav-font24 font-weight-bold">${totalRespondents}</span><div class="nav-font12 font-weight-normal grey4 d-block">Respondents</div></div>`
              })
              this.update({
                type: 'pie',
                size: '90%'
              })
            },
          }
        },
      },
      series: [{
        type: 'pie',
        innerSize: '114px',
        data: chartData,
        size: '90%'
      }],

      tooltip: {
        animation: false,
        enabled: true,
        borderRadius: 0,
        borderWidth: 0,
        shadow: false,
        shared: true,
        useHTML: false,
        format: null,
        style: {
          opacity: 0
        },
        formatter: function (this, e) {
          e.chart.title.attr({
            y: 100,
            animation: false,
            text: `<div class="text-center nav-font12 font-weight-normal d-block grey4"><span class="grey5 font-weight-bold nav-font24">${this.y}</span><div class="">${this.key}<div>Respondents</div></div>`
          });
          e.chart.tooltip.hide();
          return null
        }
      }
    };
  }

  onRespondentOverviewChartLoad(event) {
    let acceptedSlice = document.getElementsByClassName('acceptedSlice');
    let rejectedSlice = document.getElementsByClassName('rejectedSlice');
    let pendingSlice = document.getElementsByClassName('pendingSlice');
    for (let i = 0; i < acceptedSlice.length; i++) {
      this.renderer.setStyle(acceptedSlice[i], 'fill', '#32BA31');
      this.renderer.setStyle(acceptedSlice[i], 'fill-opacity', 1);
    }
    for (let i = 0; i < rejectedSlice.length; i++) {
      this.renderer.setStyle(rejectedSlice[i], 'fill', '#D02325');
      this.renderer.setStyle(rejectedSlice[i], 'fill-opacity', 1);
    }
    for (let i = 0; i < pendingSlice.length; i++) {
      this.renderer.setStyle(pendingSlice[i], 'fill', '#B5B8BD');
      this.renderer.setStyle(pendingSlice[i], 'fill-opacity', 1);
    }
  }

  handleModalConfirm(mode) {

    this.cancelDialog();
    // ad timeout of 1 second

    if (mode === 'clear-session') this.clearSessionData();
    else if (mode === 'reset-data') this.resetAllToPending();
    else if (mode === 'delete-file') this.deleteQCFile();
    else if (mode === 'save-changes') this.saveAllChanges();
    else if (mode === 'save-changes-go-to-summary') this.saveAllChanges();
  }

  openClearSessionDialog() {
    const initialState = {
      title: 'Warning: Deleting all data',
      icon: 'fak fa-message-warning nav-font20',
      message: "Are you sure you want to delete all of the data? This action will remove all Respondent IDs from data cleaning and reset their status to original status.",
      mode: 'clear-session',
      confirmBtnText: 'Delete',
      cancelCallback: () => this.cancelDialog()
    };
    this.openModalRef = this.modalService.show(this.modalRefConfirm);
    this.modalConfirmData = initialState;
  }

  openResetRecordsDialog() {
    const initialState = {
      title: 'Warning: Reset Data',
      icon: 'fak fa-message-warning nav-font20',
      message: `Are you sure you want to reset your data? This will reset all QC statuses to pending. You can easily restore your information using the 'Undo' button whenever needed.`,
      mode: 'reset-data',
      confirmBtnText: 'Reset Data',
      cancelCallback: () => this.cancelDialog()
    };
    this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'modal-dialog-centered' });
    this.modalConfirmData = initialState;
  }

  openWarningNotSavedDialogGoToSummary() {
    const initialState = {
      title: 'Warning: Unsaved Changes',
      icon: 'fak fa-message-error nav-font20',
      message: `You have unsaved changes. Please save before leaving to ensure your data is preserved.`,
      mode: 'save-changes-go-to-summary',
      confirmBtnText: 'Save',
      cancelBtnText: 'Exit without Save',
      cancelCallback: () => this.cancelWarningNotSavedGoToSummary()
    };
    this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'modal-dialog-centered' });
    this.modalConfirmData = initialState;
  }

  openWarningNotSavedDialog() {
    const initialState = {
      title: 'Warning: Unsaved Changes',
      icon: 'fak fa-message-error nav-font20',
      message: `You have unsaved changes. Please save before leaving to ensure your data is preserved.`,
      mode: 'save-changes',
      confirmBtnText: 'Save',
      cancelBtnText: 'Exit without Save',
      cancelCallback: () => this.cancelWarningNotSaved()
    };
    this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'modal-dialog-centered' });
    this.modalConfirmData = initialState;
  }

  openConfirmDeleteDialog(id) {
    const initialState = {
      title: 'Warning: Deleting file and associated data',
      icon: 'fak fa-message-warning nav-font20',
      message: 'Are you sure you want to delete this file? This action will remove Respondent IDs from data cleaning and reset their status to original status.',
      mode: 'delete-file',
      confirmBtnText: 'Delete',
      cancelCallback: () => this.cancelDialog()
    };

    this.deleteQCFileId = id;
    this.openModalRef = this.modalService.show(this.modalRefConfirm);
    this.modalConfirmData = initialState;
  }

  cancelWarningNotSaved() {
    this.cancelDialog();
    this.projectDataService.setAnyData({type: 'idsuite-unsaved-changes', data: true, isSection: this.isSection});
  }

  cancelWarningNotSavedGoToSummary() {
    this.cancelDialog();
    this.changeIdx = -1;
    this.qcFlowRecords = JSON.parse(JSON.stringify(this.originalRecords));
    this.updateTable();
    this.qcFlowDetails.status.acceptedCount = this.originalDetails.status.acceptedCount;
    this.qcFlowDetails.status.rejectedCount = this.originalDetails.status.rejectedCount;
    this.cancelQCFlow();
  }

  cancelDialog() {
    this.openModalRef.hide();
    setTimeout(() => {
      this.modalConfirmData = null;
    }, 1000);
  }

  reconcileIds() {
    this.openModalRef.hide();

    this.qcFlowService.ReconcileIds(this._project.id).subscribe((data) => {
      if (data) {
        if (!data?.errorList || data?.errorList.length == 0) {
          var reconcileDateTime = data?.reconcileDate;

          this.alertify.success('Respondent IDs have been reconciled.');
          if (reconcileDateTime) this.qcFlowDetails.status.lastReconciled = reconcileDateTime;
          this.onChange.emit();
        }
        else {
          if (data.errorList && data.errorList.length > 0) {
            const error = data.errorList[0];
            if (error?.type === 'structure') {
              this.wrongColumns = error?.data;
              this.openModalRef = this.modalService.show(this.modalRefFileError, { ignoreBackdropClick: true, class: 'modal-dialog-centered'});
            }
            else if (error?.type === 'configuration') {
              this.alertify.error(error.message);
            }
            else {
              this.alertify.error('There was an issue reconciling the data.');
            }
          }
          else {
            this.alertify.error('There was an issue reconciling the data.');
          }
        }
      }
    }, error => {
      if (error === 'There was an issue reconciling the data.' || error === 'There was an issue connecting to the survey.') {
        this.alertify.error(error);
      }
      else this.alertify.error('There was an issue reconciling the data.');
    })
  }

  calculateRuleChanges(respondents: QCFlowRecord[], resetOldRules: boolean): any {
    let newAcceptsTotal = 0; // to display the difference in summary
    let newRejectsTotal = 0;
    let newPendingTotal = 0;
    let matchedRuleRespondents = [];

    respondents.forEach(record => {
      let result = this.checkRulesForRecord(record, resetOldRules);
      if (result?.newStatus === 'Accept' || (!result && record.decision.type === 'Accept')) {
        newAcceptsTotal += 1;
      }
      if (result?.newStatus === 'Reject' || (!result && record.decision.type === 'Reject')) {
        newRejectsTotal += 1;
      }
      if (result?.newStatus === 'Pending' || (!result && record.decision.type === 'Pending')) {
        newPendingTotal += 1;
      }

      if (result != null) matchedRuleRespondents.push(result);

    });

    let acceptsDiff = newAcceptsTotal - (this.qcFlowDetails?.status.acceptedCount || 0)
    let rejectsDiff = newRejectsTotal - (this.qcFlowDetails?.status.rejectedCount || 0)
    let pendingDiff = newPendingTotal - (this.qcFlowDetails?.status.respondentProcessedCount - (this.qcFlowDetails?.status.acceptedCount + this.qcFlowDetails?.status.rejectedCount) || 0)

    this.postRuleTotals = {
      accepts: { total: newAcceptsTotal, diff: acceptsDiff, diffDisplay: this.addSign(acceptsDiff) },
      rejects: { total: newRejectsTotal, diff: rejectsDiff, diffDisplay: this.addSign(rejectsDiff) },
      pending: { total: newPendingTotal, diff: pendingDiff, diffDisplay: this.addSign(pendingDiff) },
    }
    return matchedRuleRespondents;
  }

  getDiffClass(valueWithSign) {
    let sign = valueWithSign.charAt(0)
    if (sign === '+') return 'text-success'
    else if (sign === '-') return 'text-danger'
    return ''
  }

  addSign(value) {
    if (value > 0) return '+' + value;
    else return value.toString();
  }

  checkRulesForRecord(record, resetOldRules) {
    let rules = this.qcFlowRules
    let result = null;
    let matchDetails = {
      record: record,
      newStatus: null,
      ruleId: null
    }

    let matchedRule = false;

    for (let i = 0; i < rules.length; i++) {

      let rule = rules[i];
      let totalChecks = rule.checks.length;
      let checksMatched = 0;

      var aiConditionExistsInRule = rule.checks.some(check => check.metric === 'AI');
      if (aiConditionExistsInRule && !this.qcFlowDetails.settings.enhanceWithAI) continue;

      for (let x = 0; x < rule.checks.length; x++) {
        let check = rule.checks[x];
        check.operator = !check.operator ? '=' : check.operator
        let actualValue = null;
        let actualValues = {}; // used when check is based on multiple openEnds/flags

        if (check.metric === 'AI') {
          if (check.questionCount) {
            for (let key in record.openEnds) {
              let aiAdvice = record.openEnds[key].analysis.riskLevel;
              if (actualValues[aiAdvice]) actualValues[aiAdvice] += 1
              else actualValues[aiAdvice] = 1;
            }
          }
          else if (check.questionId) {
            actualValue = record.openEnds[check.questionId]?.analysis?.riskLevel;
          }

        }
        else if (check.metric === 'My') {
          if (check.questionCount) {
            for (let key in record.openEnds) {
              let myAssessment = record.openEnds[key]?.myAssessment
              if (actualValues[myAssessment]) actualValues[myAssessment] += 1
              else actualValues[myAssessment] = 1;
            }
          }
          else if (check.questionId) {
            actualValue = record.openEnds[check.questionId]?.myAssessment;
          }
        }
        else if (check.metric === 'RID') {
          var ridRanges = {'low': [0, 20], 'medium': [21, 25], 'high': [26]}
          if (check.questionCount) {
            var matchHigh = false;
            var matchLow = false;
            for (let key in record.openEnds) {
              let ridScore = record.openEnds[key].responseIdApi.score ?? "";
              matchLow = this.compareValues(ridScore.toString(), '>=', ridRanges[check.value][0].toString());

              if (matchLow && check.value !== 'high') {
                matchHigh = this.compareValues(ridScore.toString(), '<=', ridRanges[check.value][1].toString());
              }
              if (matchLow && (matchHigh || check.value === 'high')) {
                if (actualValues[check.value]) actualValues[check.value] += 1
                else actualValues[check.value] = 1;
              }
            }
          }
          else if (check.questionId) {
            let ridScore = record.openEnds[check.questionId]?.responseIdApi?.score || 0;
            for (var scoreRange in ridRanges) {
              let match = this.compareValues(ridScore.toString(), '>=', ridRanges[scoreRange][0].toString());
              if (match && scoreRange !== 'high') {
                match = this.compareValues(ridScore.toString(), '<=', ridRanges[scoreRange][1].toString());
              }
              if (match) {
                actualValue = scoreRange;
                break;
              }
            }
          }
        }
        else if (check.metric === 'Flag') {
          if (check.questionCount) {
            for (let key in record.flags) {
              let flagValue = `'${record.flags[key].answer}'`
              if (actualValues[flagValue]) actualValues[flagValue] += 1
              else actualValues[flagValue] = 1;
            }
          }
          else if (check.questionId) {
            actualValue = `'${record.flags[check.questionId].answer}'`;
          }
        }
        // actual comparison
        let matchedCheck = false;
        if(check.metric === 'DupeAnswer'){
          let count = 0;
          for (let key in record.openEnds) {
            if(record.openEnds[key].analysis.dupeQuestionCount > 0) count += 1;
          }
          matchedCheck = this.compareValues(count, check.operator, check.value);
        }
        else if(check.metric === 'Blank'){
          let count = 0;
          for (let key in record.openEnds) {
            if (record.openEnds[key].answer == null || record.openEnds[key].answer === '') count += 1;
          }
          matchedCheck = this.compareValues(count, check.operator, check.value);
        }
        else if(check.metric === 'NonAnswer'){
          let count = 0;
          for (let key in record.openEnds) {
            if(record.openEnds[key].analysis.riskLevel === 'non answer') count += 1;
          }
          matchedCheck = this.compareValues(count, check.operator, check.value);
        }
        else if(check.metric === 'DupeRespondent'){
          let matchedOes = {}
          for (let key in record.openEnds) {
            if(this.compareValues(record.openEnds[key].analysis.dupeRespondentCount, check.operator, check.value))
              matchedOes[key] = true;
          }
          matchedCheck =  (check.questionCount) ? Object.keys(matchedOes).length >= parseInt(check.questionCount) : matchedOes[check.questionId];
        }
        else {
          let value = (check.questionCount ? (actualValues[check.value?.toLowerCase()] || 0) : actualValue)?.toString()
          let checkValue = (check.questionCount ? check.questionCount : check.value?.toLowerCase())?.toString()
          let operator = check.questionCount ? '>=' : check.operator;
          matchedCheck = this.compareValues(value, operator, checkValue);
        }
        if (rule.logic === 'And') {
          if (matchedCheck) checksMatched += 1;
          else break;
        }
        else if (rule.logic === 'Or' && matchedCheck) matchedRule = true;
      }

      if (rule.logic === 'And') matchedRule = checksMatched === totalChecks

      if (matchedRule) {
        matchDetails.newStatus = rule.status;
        matchDetails.ruleId = rule.id
        if (rule.status === 'Accept' || rule.status === 'Reject') {
          result = matchDetails;
          break;
        }
      }
    }
    if (resetOldRules && !matchDetails.newStatus && record.decision.source === 'Rule') {
      matchDetails.newStatus = 'Pending'
      result = matchDetails;
    }
    return result;
  }

  compareValues(value, operator, checkValue) {
    let match = false;
    if (value != null) {
      switch (operator) {
        case '=':
        case '==': {
          if (value == checkValue) match = true
          break;
        }
        case '>': {
          if (value > checkValue) match = true
          break;
        }
        case '<': {
          if (value < checkValue) match = true
          break;
        }
        case '>=': {
          if (value >= checkValue) match = true
          break;
        }
        case '<=': {
          if (value <= checkValue) match = true
          break;
        }
      }
      return match;
    }
  }

  checkOverflow() {
    // wait for table to render after change
    setTimeout(() => {
      let table = document.querySelector('.dataCleaningTableContainer');
      if (table) {
        if (table.scrollWidth > table.clientWidth) this.overflowingX = true
        else this.overflowingX = false

        if (table.scrollHeight > table.clientHeight) this.overflowingY = true
        else this.overflowingY = false
      }
    }, 100);
  }

  checkOverflowRespondentView() {
    // wait for table to render after change
    setTimeout(() => {
      let table = document.querySelector('.respondentViewTable.questionLevel');
      if (table) {
        if (table.scrollWidth > table.clientWidth) this.respondentViewOverflowingX = true
        else this.respondentViewOverflowingX = false

        if (table.scrollHeight > table.clientHeight) this.respondentViewOverflowingY = true
        else this.respondentViewOverflowingY = false
      }
    }, 100);
  }

  applyRulesBulk(matchedRuleRespondents): any {

    let decisions = [];
    matchedRuleRespondents.forEach(matchedRuleRespondent => {

      let respondent = this.qcFlowRecords.find(x => x.respondentId == matchedRuleRespondent.record.respondentId);
      var change = {
        respondentId: matchedRuleRespondent.record.respondentId,
        originalType: respondent.decision.type,
        type: matchedRuleRespondent.newStatus,
        originalSource: respondent.decision.source,
        source: matchedRuleRespondent.newStatus === 'Pending' ? respondent.decision.originalSource : 'Rule',
        originalRuleId: respondent.decision.ruleId,
        ruleId: matchedRuleRespondent.newStatus === 'Pending' ? null : matchedRuleRespondent.ruleId
      }
      decisions.push(change);

      respondent.decision.type = change.type;
      respondent.decision.source = change.source;
      respondent.decision.ruleId = change.ruleId

    });

    return decisions;

  }

  getObjectLength(dict) {
    return Object.keys(dict).length
  }

  getObjectByIndex(index, dict) {
    const id = Object.keys(dict)[index];
    return dict[id];
  }

  // getting next index because index of 0 fails *ngIf when declaring variable in html file
  getNextIndexByKey(key, dict) {
    const keys = Object.keys(dict);
    return keys.indexOf(key) + 1;
  }

  @HostListener('window:resize', ['$event'])
    onResize(event) {
      this.checkOverflow()
    }

  sortFileHistory(type) {
    // type = 'name' or 'date' or 'size'
   if (type === 'date') {
      if (this.fileHistorySortType === 'date') {
        this.qcFlowDetails?.status.fileHistory.reverse();
      } else {
        this.qcFlowDetails?.status.fileHistory.sort((a, b) => {
          return new Date(b.submittedOn).getTime() - new Date(a.submittedOn).getTime();
        });
        this.fileHistorySortType = 'date';
      }
    }
    if (type === 'name') {
      if (this.fileHistorySortType === 'name') {
        this.qcFlowDetails?.status.fileHistory.reverse();
      } else {
        this.qcFlowDetails?.status.fileHistory.sort((a, b) => {
          return a.fileName.localeCompare(b.fileName);
        });
        this.fileHistorySortType = 'name';
      }
    }
    if (type === 'size') {
      if (this.fileHistorySortType === 'size') {
        this.qcFlowDetails?.status.fileHistory.reverse();
      } else {
        this.qcFlowDetails?.status.fileHistory.sort((a, b) => {
          return a.rowCount - b.rowCount;
        });
        this.fileHistorySortType = 'size';
      }
    }
  }

  checkIfRuleAlreadyExists(checks, index, metric, value, item) {
    var exists = false;
    var i = 0;
    checks.forEach(check => {
      if (i != index && check.metric === metric) {
        if (check.value === value && check.questionId.toString() === item.toString()) {
          exists = true;
        }
      }
      i++;
    });
    return exists;
  }

  checkIfRuleOverridesAnother(rule, index, c) {
    var i = 0;
    rule.checks.forEach(check => {
      if (i != index && check.metric === c.metric) {
        if (check.value === c.value && check.questionId.toString() === c.questionId.toString()) {
          c.questionId = null;
        }
      }
      i++;
    });
  }
  getQcRuleSubjectValue(metric: string): string {
    const item = this.qcRuleSubjectArray.find(item => item.key === metric);
    return item ? item.value : 'Select subject';
  }
  asIsOrder(a, b) {
    return 1;
  }

  importDecipherQuestionsAPI() {
    this.qcFlowService.importDecipherQuestionsAPI(this._project.id).subscribe(
      data => {
        if (data) {
          if(!data?.errorList || data?.errorList.length == 0) {
            if (!this.qcFlowDetails || this.qcFlowDetails.status.fileHistory.length == 0) {
              this.qcFlowDetails = data?.details;

              // get only active QC files
              const nonDeletedFiles = this.qcFlowDetails.status.fileHistory.filter(file => !file.isDeleted);
              this.qcFlowDetails.status.fileHistory = nonDeletedFiles;

              this.respondentIdColumnIndex = null;
              this.respondentIdColumnId = null;
              this.updateColumns();
              this.updateIdList();
            }

            this.rowCount = 0;
            this.fileProcessed = true;
          }
          else {
            if (data.errorList && data.errorList.length > 0) {
              const error = data.errorList[0];

              if (error?.type === 'structure') {
                this.wrongColumns = error?.data;
                this.openModalRef = this.modalService.show(this.modalRefFileError, { ignoreBackdropClick: true, class: 'modal-dialog-centered'});
              }
              else if (error?.type === 'configuration') {
                this.alertify.error(error.message);
              }
              else {
                this.alertify.error('There was an issue importing the data.');
              }
            }
            else {
              this.alertify.error('There was an issue importing the data.');
            }
          }
          this.usedDecipher = true;
          this.respondentIdFilterText = '';
          this.uploadedFile = null;
          this.uploadedFileId = null;
        }
      },
      error => {
        if (error === 'There was an issue importing the data.' || error === 'There was an issue connecting to the survey.') {
          this.alertify.error(error);
        }
        else this.alertify.error('There was an issue importing the data.');
      },
      () => {
        //this.loadData();
        this.onChange.emit();
      }
    );
  }
}
