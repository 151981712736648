<div class="container-fluid mb-5 nav-content">
  <!-- BREADCRUMBS -->
  <div class="row">
    <div class="col-12 breadcrumb mr-auto bg-transparent">
      <div class="d-flex align-items-center">
        <div class="grey4 d-flex align-items-center">
          <app-pagetitle></app-pagetitle>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col d-flex align-items-center">
      <button class="btn nav-btn-accent shadow-none font-weight-bold mr-3 nav-font14" (click)="partnerAdd()">Add Vendor</button>
      <div class="input-group copy-search">
        <div class="input-group-prepend">
          <div class="input-group-text border-right-0">
            <i class="fas fa-search cursor-default"></i>
          </div>
        </div>
        <input [(ngModel)]="filterText" type="search" class="form-control form-control-sm" (ngModelChange)="updateList()"
          placeholder="Search vendor or primary contact">
        </div>
      </div>
    </div>

    <div class="scrollbar overflow-x" width="100%">
      <table class="table vendors table-hover table-light nav-font14 familyTable mb-0">
        <thead class="grey5">
          <tr>
            <th width="260" (click)="SortBy('name')"><span class="ml-3">Vendor </span><i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="260" (click)="SortBy('name')"><span class="ml-3">ORVID </span><i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="260" (click)="SortBy('audienceType')" >Audience Type <i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="260" (click)="SortBy('regionsServed')" >Regions Served <i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="260" (click)="SortBy('tags')" >Tags <i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="260" (click)="SortBy('website')" >Website <i class="fas fa-sm or-secondary fa-sort"></i></th>
            <th width="72" class=" text-center">Options </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          @if (partners?.length > 0) {
            @for (partner of partners; track partner) {
              <tr>
                <td>
                  <div class="d-flex align-items-center ml-3">
                    <or-profile-image showOnHover="true" [name]="partner.name" [url]="partner.img" showInitials="true"></or-profile-image>
                    <a  [routerLink]="['/vendors', partner.id]" class="nav-accent-blue font-weight-bold nav-link p-0 text-truncate ml-3"> {{ partner.name }}</a>
                  </div>
                </td>
                <td>
                  <div class="cursor-pointer" (click)="copyToClipboard(partner?.id)">
                    <span tooltip="Copy">
                      {{ (partner?.id.length > 8 ? (partner?.id | slice:0:8) + '...' : partner?.id) }}
                    </span>
                    <i class='far fa-clipboard'></i>
                  </div>
                </td>
                <td>
                  {{partner?.audienceLabel}}
                </td>
                <td>
                  {{partner?.regionsLabel}}
                </td>
                <td>
                  @for (item of partner?.tags; track item) {
                    <span class="badge badge-pill text-white font-weight-normal mr-1">{{tagsLabel[item]}}</span>
                  }
                </td>
                <td>
                  <a class="p-0 nav-accent-blue font-weight-bold text-nowrap" [href]="partner.website" target="_blank">{{ partner.website }}</a>
                </td>
                <td class=" text-center">
                  <div class="btn-group btn-group-sm" dropdown #ddActions="bs-dropdown" container="body">
                    <button dropdownToggle type="button" class="btn btn-outline-none" controls="dropdown-basic">
                      <i class="fak fa-lg fa-ellipsis-vertical grey5"></i>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-right cursor-pointer" role="menu" aria-labelledby="button-basic" (mouseleave)="ddActions.hide()">
                      <a class="dropdown-item" role="menuitem" (click)="openPartner(partner)">Open vendor page</a>
                      <a class="dropdown-item" role="menuitem" (click)="partnerEdit(partner)">Edit vendor info</a>
                      <a class="dropdown-item" role="menuitem" (click)="partnerDelete(partner)">Delete vendor</a>
                    </div>
                  </div>
                </td>
              </tr>
            }
          }
          @if (partners?.length == 0) {
            <tr>
              <td colspan="4">
                <span class="grey5 nav-font14">No vendors available. Add a vendor to begin.</span>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    @if (showLoadMore) {
      <button class="nav-btn-primary shadow-none w-100 mb-3 mt-3" (click)="showMore()">Load all</button>
    }

  </div>

  <ng-template #modalPartner>
    <div class="modal-header">
      <h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">{{ model?.id ? 'Edit Vendor' : 'Add New Vendor' }}</h2>
      <div class="d-flex justify-content-end">
        <i class="fas fa-close grey5" (click)="closeModal()"></i>
      </div>
    </div>
    <div class="modal-body nav-font14 scrollbar overflow-y pb-3">
      <or-vendor-edit [partnerForm]="partnerForm" [openModalRef]="openModalRef" [model]="model" (saveVendor)="getPartnerFormEvent($event)"></or-vendor-edit>
    </div>
  </ng-template>

  <ng-template #modalConfirm>
    <div class="modal-header">
      <div class="row d-flex w-100 align-items-center">
        <div class="col">
          <h3 class="font-weight-bold nav-secondary">
            <i class="fak fa-message-warning grey5 fa-lg ml-2"></i>
            Warning: Deleting Vendor
          </h3>
        </div>
        <div class="col-2 text-right px-0">
          <i class="fas fa-close grey5" (click)="closeModal()"></i>
        </div>
      </div>
    </div>
    <div class="modal-body pt-0">
      <h5 class="grey5 d-block my-3">
        Are you sure you want to delete the vendor <span class="font-weight-bold grey5">{{model?.name}}?</span> This action is irreversible, and the client will be permanently lost.
      </h5>
    </div>
    <div class="modal-footer">
      <div class="row d-flex w-100 align-items-center">
        <div class="col-5 px-0 or-checkbox d-flex justify-content-start">
          <input type="checkbox" name="notAskAgainDeleteVendor"  (change)="this.utils.createDialogList($event.target.checked, 'partner')" id="notAskAgainDeleteVendor">
          <label for="notAskAgainDeleteVendor"></label>
          <small class="pl-4 grey5">Don't ask again</small>
        </div>
        <div class="col text-right px-0">
          <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="closeModal()">Cancel</button>
          <button class="btn btn-sm nav-btn-accent shadow-none" (click)="confirmDelete()">Delete</button>
        </div>
      </div>
    </div>
  </ng-template>
