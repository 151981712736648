@if (_widget != null) {
  <div class="row">
    <div class="col-10 h6 mb-0 d-flex align-items-center">
      <div class="h6 m-0 p-0 d-flex align-items-center">
        @if (editEnabled == null) {
          <div>
            {{ title }}
            @if (_widget.enableEdit == true) {
              <span (click)="enableEdit()">
                <i class="fas fa-edit cursor-pointer or-secondary ml-2 opacity-70"></i>
              </span>
            }
          </div>
        }
        @if (editEnabled != null) {
          <input [(ngModel)]="editEnabled" maxlength="100" type="text" class="form-control form-control-sm inline mr-1" placeholder="Widget Title">
          <div class="d-inline-flex actions">
            <button type="button" class="btn btn-sm btn-outline-secondary shadow-sm mx-1" (click)="cancel()"><i class="fas fa-times fa-xs"></i></button>
            <button type="button" class="btn btn-sm btn-outline-secondary shadow-sm" (click)="save()"><i class="fas fa-check fa-xs"></i></button>
          </div>
        }
      </div>
    </div>
    @if (_widget.enableConfigure == true || _widget.enableRemove == true) {
      <div class="col-2 text-right">
        <div class="btn-group btn-group-sm" dropdown container="body">
          <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-none" controls="dropdown-basic">
            <span><i class="fa fa-ellipsis-v"></i></span>
          </button>
          <div  id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
            @if (_widget.enableConfigure) {
              <a class="dropdown-item" role="menuitem" (click)="config()">Configure Widget</a>
            }
            @if (_widget.enableRemove) {
              <a class="dropdown-item" role="menuitem" (click)="removed()">Remove Widget</a>
            }
          </div>
        </div>
      </div>
    }
  </div>
}
