<div class="container-fluid px-2 mx-1" style="z-index: 5;">
  <div class="col d-flex align-items-center px-0 mb-3">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0">{{_stage?.name}}</span>
  </div>
  <div class="homepageCardContainer" [ngClass]="cardInFullscreen ? 'w-100' : 'd-flex'">
    @if (_stage) {
      @for (col of cols; track col.index) {
        <div [class.homepage-grid]="!cardInFullscreen">
          @for (card of col.cards; track card?.id) {
            @if (card.show != false) {
              <div [class.item]="!cardInFullscreen">
                @if (card?.type === 'dates') {
                <app-project-homepage-dates [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-dates>
                }
                @if (card?.type === 'team') {
                <app-project-homepage-team [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-team>
                }
                @if (card?.type === 'sample-specification') {
                <app-project-homepage-sample [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-sample>
                }
                @if (card?.type === 'schedule') {
                <app-project-homepage-schedule [project]="_project" [stage]="_stage" (callback)="cardCallback($event)"></app-project-homepage-schedule>
                }
                @if (card?.type === 'client') {
                <app-project-homepage-client [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-client>
                }
                @if (card?.type === 'quotas') {
                  <app-project-quota-metrics [project]="_project" (goToQuotaSetup)="cardCallback({section: 'quotas', type: null})" [respondents]="respondents" [fullscreen]="cardInFullscreen?.type == 'quotas'" (toggleCardSize)="cardInFullscreen?.type == card?.type ? exitCardFullscreen() : changeCardToFullscreen(card?.type)"></app-project-quota-metrics>
                }
                @if (card?.type === 'survey-testing') {
                  <app-project-homepage-survey-testing [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-survey-testing>
                }
                @if (card?.type === 'survey-links-vendors') {
                  <app-project-homepage-survey-links-vendors [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-survey-links-vendors>
                }
                @if (card?.type === 'field-management') {
                  <app-project-homepage-field-management [data]="{project: _project, respondents: respondents}" (callback)="cardCallback($event)"></app-project-homepage-field-management>
                }

              @if (card?.type === 'quality') {
                <app-project-homepage-quality [card]="card" [project]="_project" [respondents]="respondents" (callback)="cardCallback($event)"></app-project-homepage-quality>
              }
              @if (card?.type === 'datacleaning') {
              <app-project-homepage-data-cleaning [card]="card" [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-data-cleaning>
              }

                @if (card?.type === 'closure') {
                <app-project-homepage-closure [card]="card" [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-closure>
                }
                @if (card?.type === 'invoice') {
                <app-project-homepage-invoice [card]="card" [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-invoice>
                } 
              </div>
            }
          }
        </div>
      }
    }
  </div>
</div>
