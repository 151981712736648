<div class="nav-content">
    <!-- BREADCRUMBS -->
    <div class="row">
      <div class="col-12 breadcrumb mr-auto bg-transparent mb-3 py-0">
        <div class="d-flex align-items-center">
          <div class="nav-secondary d-flex align-items-center">
            <app-pagetitle></app-pagetitle>
          </div>
        </div>
      </div>
    </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="sectionbtn nav-item ml-auto">
          <a class="btn nav-btn-accent btn-sm mr-2 mb-2 shadow-none"
            href="https://nav2.navigatorsurveys.com/onlinehelp?page=en/kb/articles/dashboards-labor" id="help_link"
            target="_new"><i class="fa-regular fa-book-open"></i>&nbsp;&nbsp;View Knowledge Base</a>
        </div>
      </div>
    </div>
    <div class="tab-content">
      <ul role="tablist" class="nav nav-tabs" aria-label="Tabs">
        <li class="nav-item">
          <a href="javascript:void(0);" role="tab" class="nav-link" [class.active]="currentIdx === 0" aria-controls=""
            aria-selected="true" (click)="loadDashboard(0)">
            <span>Not Started</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0);" role="tab" class="nav-link" [class.active]="currentIdx === 1" aria-controls=""
            aria-selected="true" (click)="loadDashboard(1)">
            <span>Active</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="javascript:void(0);" role="tab" class="nav-link" [class.active]="currentIdx === 2" aria-controls=""
            aria-selected="true" (click)="loadDashboard(2)">
            <span>Closed</span>
          </a>
        </li>
      </ul>
    </div>

    <div>
      <iframe #myIframe frameborder="0" width="100%" height="8000" allowtransparency></iframe>
    </div>
  </div>
</div>