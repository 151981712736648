<div class="nav-content">
  <!-- BREADCRUMBS -->
  <div class="row">
    <div class="col-12 breadcrumb mr-auto bg-transparent mb-0 py-0">
      <div class="d-flex align-items-center">
        <div class="nav-secondary d-flex align-items-center">
          <app-pagetitle></app-pagetitle>
        </div>
      </div>
    </div>
  </div>
  @if (!homepage) {
    <div class="container-fluid">
      <div class="col">
        @if (dashboards?.length > 0) {
          <div class="btn-group mr-3 mb-1" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-secondary dropdown-toggle" aria-controls="dropdown-basic">
              <span class="mx-2 h5">{{ currentDashboard?.name }}</span>
              <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="width: 220px">
              @for (dashboard of this.dashboards; track dashboard; let i = $index) {
                <li role="menuitem" class="p-2">
                  <or-action-button [label]="dashboard.name" (selected)="openDashboard(dashboard)"></or-action-button>
                </li>
              }
              <li role="menuitem" class="p-2">
                <or-action-button label="+ Add New" [style]="'dark'" (selected)="newDashboard()"></or-action-button>
              </li>
            </ul>
          </div>
        }
        @if (dashboards?.length == 0) {
          <or-action-button label="+ Add New" [style]="'dark'" (selected)="newDashboard()"></or-action-button>
        }
      </div>
      <div class="col text-right">
        @if (currentDashboard != null) {
          <button type="button" class="btn btn-sm btn-secondary" (click)="actionSelected('add-widget')">
            <i class="fas fa-plus cursor-pointer"></i> Add Widget
          </button>
          <div class="btn-group" dropdown>
            <span id="button-basic" dropdownToggle type="button" class="btn-lg p-2" controls="dropdown-basic">
              <span><i class="fas fa-cog or-secondary opacity-70"></i></span>
            </span>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic" style="width: 100px;">
              <li role="menuitem" class="p-1">
                <or-action-button label="Rename" (selected)="actionSelected('rename')"></or-action-button>
              </li>
              <li role="menuitem" class="p-1">
                <or-action-button label="Delete" (selected)="actionSelected('delete')"></or-action-button>
              </li>
            </ul>
          </div>
        }
      </div>
    </div>
  }
  @if (homepage) {
    <div class="container-fluid">
      <div class="w-100">
        <div class="nav-font16 grey4 text-center">{{ now | date:'fullDate'}}</div>
        <div class="nav-font20 font-weight-bold grey5 text-center">{{ greeting }}</div>
          <div class="row justify-content-center d-flex align-items-center my-4">
            @if (mode == 'manager') {
              <div class="d-flex mr-3">
                <span class="nav-secondary nav-font14 mr-2">Manager View</span>
                <app-widget-filter-users (onChange)="setDashboardsFilter($event)" (dropdownClosed)="selectedUsers.length == 0 ? showBanner=true : showBanner=false"></app-widget-filter-users>
              </div>
            }
            @if (mode != 'manager' || showBanner) {
              <div class="btn-group summary grey4 nav-font14 pr-3">
                <button type="button" class="btn" dropdown container="body">
                  <span id="button-basic" dropdownToggle type="button" class="border-right p-1 px-3" controls="dropdown-basic">
                    <span class="font-weight-bold">
                      @if (homeBannerOffset !== 'Today') {
                        <span class="font-weight-normal">My</span>
                      }
                      {{homeBannerOffset}}
                    </span>
                    <i class="far fa-chevron-down grey4 ml-2"></i>
                  </span>
                  <ul *dropdownMenu class="dropdown-menu position-relative cursor-pointer" role="menu" aria-labelledby="button-basic" style="width: 60px;">
                    <a class="btn px-3 py-1 dropdown-item" (click)="setOffset('today')">Today</a>
                    <a class="btn px-3 py-1 dropdown-item" (click)="setOffset('week')">Week</a>
                    <a class="btn px-3 py-1 dropdown-item" (click)="setOffset('month')">Month</a>
                  </ul>
                </button>
                <button type="button" class="btn px-3 py-1 summary-item">
                  <i class="fas fa-check grey4 font-weight-normal"></i>
                  <span class="mx-2 font-weight-bold grey5">{{homeBannerInfo?.tasksCompleted}}</span>
                  <span class="grey4">Tasks Completed</span>
                </button>
                <button type="button" class="btn px-3 py-1 summary-item">
                  <i class="fak fa-medal grey4 fa-lg font-weight-normal"></i>
                  <span class="mx-2 font-weight-bold grey5">{{homeBannerInfo?.projectsCompleted}}</span>
                  <span class="grey4">Projects Completed</span>
                </button>
              </div>
            }
          </div>
      </div>
      <div class="row mt-3">
        <div class="col-8">
          @if (showProject) {
            <div class="my-3">
              <app-widget-current-projects-tasks></app-widget-current-projects-tasks>
            </div>
          }
          @if (showJobs) {
            <div class="my-3">
              <app-widget-jobs-to-be-done></app-widget-jobs-to-be-done>
            </div>
          }
        </div>
        <div class="col-4">
          @if (showUpCommingProjects) {
            <div class="py-3 h-100">
              <app-widget-upcoming-projects></app-widget-upcoming-projects>
            </div>
          }
        </div>
      </div>
      <div class="row">
        <div class="col-8">
          @if (showpriorities) {
            <div class="my-0 h-100">
              <app-widget-my-priorities></app-widget-my-priorities>
            </div>
          }
        </div>
        <div class="col-4">
          @if (showScore) {
            <div class="my-0">
              <app-widget-ytd></app-widget-ytd>
            </div>
          }
        </div>
      </div>
    </div>
  }
  @if (!homepage && currentDashboard != null) {
    <div class="container-fluid">
      <ktd-grid
        [cols]="cols"
        [rowHeight]="rowHeight"
        [layout]="this.layout"
        gap=6
        (layoutUpdated)="onLayoutUpdated($event)">
        @for (widget of currentDashboard?.widgets; track trackById($index, widget)) {
          <ktd-grid-item [id]="widget.id" [draggable]="!homepage" [resizable]="!homepage">
            <!-- Your grid item content goes here -->
            <div class="widget">
              @if (widget.type =='project-by-manager') {
                <app-widget-project-by-manager [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-project-by-manager>
              }
              @if (widget.type =='project-by-category') {
                <app-widget-project-by-category [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-project-by-category>
              }
              @if (widget.type =='project-health-by-client') {
                <app-widget-project-health-by-client [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-project-health-by-client>
              }
              @if (widget.type =='newsfeed') {
                <app-widget-newsfeed [widget]="widget"  (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-newsfeed>
              }
              @if (widget.type =='project-health') {
                <app-widget-project-health  [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-project-health>
              }
              @if (widget.type =='project-favourite') {
                <app-widget-project-favourite  [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-project-favourite>
              }
              @if (widget.type =='project-margin') {
                <app-widget-margin  [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-margin>
              }
              @if (widget.type =='revenue-by-manager') {
                <app-widget-revenue-by-manager [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-revenue-by-manager>
              }
              @if (widget.type =='custom-report') {
                <app-widget-custom-report [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-custom-report>
              }
              @if (widget.type =='jobs-to-be-done') {
                <app-widget-jobs-to-be-done [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-jobs-to-be-done>
              }
              @if (widget.type =='my-priorties') {
                <app-widget-my-priorities [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-my-priorities>
              }
              @if (widget.type == 'ytd') {
                <app-widget-ytd [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-ytd>
              }
              @if (widget.type == 'upcoming-projects') {
                <app-widget-upcoming-projects [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)" (onopenconfig)="openWidgetConfig(widget)"></app-widget-upcoming-projects>
              }
              <!-- placeholders -->
              @if (widget.type =='placeholder') {
                <div class="card border-0 h-100">
                  <div class="card-header border-bottom">
                    <app-widget-title [widget]="widget" ></app-widget-title>
                  </div>
                  <div class="card-body">
                    PLACEHOLDER
                  </div>
                </div>
              }
              @if (widget.type =='tasks') {
                <div><h1> TO DO: TASKS</h1></div>
              }
              @if (widget.type =='partner-fraud-rate') {
                <app-widget-partner-fraud-rate [widget]="widget" (onremove)="removeWidget(widget)" (onupdate)="updateWidget(widget)"></app-widget-partner-fraud-rate>
              }
              <!-- Optional Custom placeholder template -->
              <ng-template ktdGridItemPlaceholder>
                <!-- Custom placeholder content goes here -->
              </ng-template>
            </div>
          </ktd-grid-item>
        }
      </ktd-grid>
    </div>
  }
  @if (homepage) {
    <div class="text-center nav-font14 grey3 mt-4 oRSmallText">
      <span>© 2024 OpinionRoute, LLC. | All Rights Reserved.</span>
    </div>
  }
</div>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Do you wish to delete this Dashboard?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="removeDashboard()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="modalRefConfirm.hide()">No</button>
  </div>
</ng-template>
