import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-project-homepage-quality',
  templateUrl: './project-homepage-quality.component.html',
  styleUrls: ['./project-homepage-quality.component.scss']
})
export class ProjectHomepageQualityComponent implements OnInit, OnChanges {

  @Input() public set project(data) {
    this._project = data;
  }
  @Input() respondents;
  @Output() callback = new EventEmitter();

  _project:any = {};
  completes = 0;
  clientQcs = 0;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.respondents) {
      this.respondents = changes.respondents.currentValue;
      this.calculateDAR();
    }
  }

  ngOnInit() {
  }

  calculateDAR() {
    var clientQcs = 0;
    var completes = 0;

    this.respondents.forEach(respondent => {
      clientQcs += respondent.clientQc;
      completes += respondent.complete;
    });

    this.clientQcs = clientQcs;
    this.completes = completes;
  }

  openQuality() {
    this.callback.emit({section: 'quality', type: null});
  }

}
