import { Component, OnInit, Input } from '@angular/core';
import { ProjectService, AuthService } from 'core';
declare var google: any;

@Component({
  selector: 'app-project-family-survey-performance-table',
  templateUrl: './project-family-survey-performance-table.component.html',
  styleUrls: ['./project-family-survey-performance-table.component.scss']
})
export class ProjectFamilySurveyPerformanceTableComponent implements OnInit {

  @Input() mode = 'reduced';
  @Input()  public set respondents(data) {
    if(data != null) this.processData(data);
  }
  @Input() familyName;

  data: any;
  partners: Array<number>;
  projects;
  summary: any;
  tableData: any[];
  tableMode = 'projects';
  loading = true;
  dates: Array<any>;
  viewByText = 'Date';
  viewByList = [
    { id: 'projects', name: 'Project' },
    { id: 'partners', name: 'Vendor' },
    { id: 'inDate', name: 'Date' }
  ];

  perfCardViewSettings = {
    showPreSurveyBreakdown: false,
    showInSurveyBreakdown: true,
    showKPIBreakdown: false,
    sortOrder: 'desc'
  };

  showNavBreakdown = false;

  constructor(
    private projectService: ProjectService,
    public auth: AuthService) { }

  ngOnInit() {
    this.perfCardViewSettings = this.projectService.getPerformanceCardView();
  }

  handlePerfCardViewSettings(type) {
    this.perfCardViewSettings.showPreSurveyBreakdown = (type === 'PreSurvey') ? !this.perfCardViewSettings.showPreSurveyBreakdown : this.perfCardViewSettings.showPreSurveyBreakdown;
    this.perfCardViewSettings.showInSurveyBreakdown = (type === 'InSurvey') ? !this.perfCardViewSettings.showInSurveyBreakdown : this.perfCardViewSettings.showInSurveyBreakdown;
    this.perfCardViewSettings.showKPIBreakdown = (type === 'KPISurvey') ? !this.perfCardViewSettings.showKPIBreakdown : this.perfCardViewSettings.showKPIBreakdown;
    this.projectService.setPerformanceCardView(this.perfCardViewSettings);
  }

  processData(data) {
    console.log(data);
    const handledpartners = {};
    const vendorList = [];
    const handledprojects = {};
    const projectList = [];
    const handledDates = {};
    const dateList = [];

    if(data == null) return;

    let summary = { open: this.auth.isInternal(), total: 0, starts: 0, complete : 0, navTerminate:0, clientOverquota: 0, limitOverquota: 0, overquota: 0, clientTerminate: 0, clientQc : 0, autoQc: 0, cid: 0, mobileBlock: 0, vid: 0, dropoff: 0, dupe: 0, loi: 0};

    data.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    data.forEach(e => {
      summary = this.addSummaryValues(summary, e, false);
      if (e.vendor != null) {
        if (! handledpartners[e.vendorId]) {
          handledpartners[e.vendorId] = true;
          vendorList.push(e.vendor);
        }
      }

      if (! handledprojects[e.projectId]) {
        handledprojects[e.projectId] = true;
        projectList.push(e.project);
      }

      if (!handledDates[e.date]) {
        const isValidDate = e.date && e.date instanceof Date && !isNaN(e.date.getTime()) && e.date.getFullYear() > 1901;

        if (isValidDate) {
          handledDates[e.date] = true;
          dateList.push(e.date.toString());
        }
      }
    });

    this.updateCalcs(summary);
    summary.loi = this.medianLOI(data);
    this.summary = summary;
    this.partners = vendorList;
    this.projects = projectList;
    this.dates = dateList;
    this.sortDates();
    this.data = data;
    this.setTableData(this.tableMode);
    this.loading = false;
  }

  sortDates() {
    if (this.perfCardViewSettings?.sortOrder === 'desc' || this.perfCardViewSettings?.sortOrder == null)
      this.dates = this.dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    else
      this.dates = this.dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  }

  shortenProjectName(name) {
    if (this.familyName && name) {
      if (this.familyName.trim() != "" && this.projects.length > 1) return name.replace(this.familyName,'...');
    }
    return name
  }

  setTableData(mode) {
    const open = this.tableData == null ? [] : this.tableData.filter(e => e.open);
    this.viewByText = this.viewByList.find(x => x.id == mode)?.name;

    this.tableData = [];
    this.tableMode = mode;

    let lists;

    if (mode === 'projects') {
      lists = [this.projects, this.partners];
    } else if (mode === 'partners') {
      lists = [this.partners, this.projects];
    } else if (mode === 'inDate') {
      lists = [this.dates, this.projects];
    }

    for (const topVal of lists[0]) {
      let topData;

      if (mode === 'projects') {
        topData = this.data.filter(e => e.project === topVal && e.vendor !== null);
      } else if (mode === 'partners') {
        topData = this.data.filter(e => e.vendor === topVal);
      } else if (mode === 'inDate') {
        topData = this.data.filter(e => e.date.toString() === topVal);
      }

      let summary: any = {
        open: false,
        label: topVal,
        total: 0,
        starts: 0,
        complete: 0,
        navTerminate: 0,
        clientOverquota: 0,
        limitOverquota: 0,
        overquota: 0,
        clientTerminate: 0,
        clientQc: 0,
        autoQc: 0,
        cid: 0,
        mobileBlock: 0,
        vid: 0,
        dropoff: 0,
        dupe: 0,
        loi: 0,
        sub: []
      };

      topData.forEach(e => {
        summary = this.addSummaryValues(summary, e, true);
      });

      summary.loi = this.medianLOI(topData);
      this.updateCalcs(summary);

      for (const sub of lists[1]) {
        let subData;

        if (mode === 'projects') {
          subData = topData.find(e => e.vendor === sub);
        } else if (mode === 'partners' || mode === 'inDate') {
          subData = topData.find(e => e.project === sub);
        }

        if(subData != null) {
          subData.label = sub;
          this.updateCalcs(subData);
          subData.loi = this.medianLOI([subData]);
          summary.sub.push(subData);
        }
      }

      if(open.find(e => e.label === summary.label) != null) {
        summary.open = true;
      }

      this.tableData.push(summary);
    }
    console.log(this.tableData);
  }

  addSummaryValues(summary, e, sub) {
    if (sub == false && e.vendor != null) {
      summary.starts += e.starts;
      summary.complete += e.complete;
      summary.clientOverquota += e.clientOverquota;
      summary.limitOverquota += e.limitOverquota;
      summary.overquota += e.overquota;
      summary.navTerminate += e.navTerminate;
      summary.clientTerminate += e.clientTerminate;
      summary.clientQc += e.clientQc;
      summary.autoQc += e.autoQc ?? 0;
      summary.cid += e.cid;
      summary.mobileBlock += e.mobileBlock ?? 0;
      summary.dropoff += e.dropoff;
      summary.dupe += e.dupe;
    }
    else if (sub) {
      summary.starts += e.starts;
      summary.complete += e.complete;
      summary.clientOverquota += e.clientOverquota;
      summary.limitOverquota += e.limitOverquota;
      summary.overquota += e.overquota;
      summary.navTerminate += e.navTerminate;
      summary.clientTerminate += e.clientTerminate;
      summary.clientQc += e.clientQc
      summary.autoQc += e.autoQc ?? 0;
      summary.cid += e.cid;
      summary.mobileBlock += e.mobileBlock ?? 0;
      summary.dropoff += e.dropoff;
      summary.dupe += e.dupe;
    }
    return summary;
  }

  updateCalcs(data) {
    data.total = data.starts + data.cid + data.dupe + data.overquota + data.mobileBlock + data.navTerminate;
    data.qcRate = data.clientQc === 0 ? 0 : ((data.clientQc) / (data.complete + data.clientQc));
    data.autoQcRate = data.autoQc === 0 ? 0 : ((data.autoQc) / (data.complete + data.autoQc));
    data.ir = data.complete === 0 ? 0 : (data.complete) / (data.complete + data.clientTerminate);
  }

  medianLOI(data) {
    let lois = [];
    data.forEach(e => {
      if (e.completeLois != null) {
        lois = lois.concat(e.completeLois.map(f => f.loi));
      }
    });
    const LOIs = lois.sort((a, b) => a - b);
    if (LOIs.length === 0) { return 0; }

    const mid = Math.floor(LOIs.length / 2);
    const loi = LOIs.length % 2 !== 0 ? LOIs[mid] : (LOIs[mid - 1] + LOIs[mid]) / 2;
    return Math.round(loi/1000);
  }

  showCellValue( val, percent) {
    if (val === null) {
      return isNaN(percent) || percent === 0 ? '-' : Math.round(percent * 100)  + '%';
    } else if ( percent == null) {
      return val === 0 ? '-' : val;
    }
    else {
      return val === 0 ? '-' :  Math.round(percent * 100)  + '%' + ' (' + val + ')';
    }
  }

  sortTable() {
    if (this.tableMode === 'projects') this.projects.reverse()
    else if (this.tableMode === 'partners') this.partners.reverse()
    this.setTableData(this.tableMode);
  }
  getPreColumnCount() {
    let count = 0;
    if (this.perfCardViewSettings.showPreSurveyBreakdown)
    {
      count = 3;
      if(this.summary.cid > 0)count++;
      if(this.summary.mobileBlock > 0)count++;
      if(this.summary.navTerminate > 0)count++;
    }
    return count;
  }
}
