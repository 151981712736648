<div class="nav-content">
  <div class="user-menu mb-3">
    <div class="items d-flex mt-3 pr-4 align-items-center">
      <div class="mx-4 menu-item pb-1 cursor-pointer" (click)="switchSection('settings')"
        [class.active]="section==='settings'">
        <span class="nav-font14">
          User Settings
        </span>
      </div>
      @if (auth.isInternal()) {
        <div class="mx-4 menu-item pb-1 cursor-pointer" (click)="switchSection('support')"
          [class.active]="section==='support'">
          <span class="nav-font14">
            Support Access
          </span>
        </div>
      }
    </div>
  </div>
  @if (section === 'settings') {
    <div class="container-fluid px-4">
      <div class="card border-0 mt-2 mx-0 w-auto" style="width:940px !important">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <h4 class="grey5 font-weight-bold mb-0 d-inline">Upload your profile picture</h4>
            </div>
          </div>
        </div>
        <div class="card-body pt-2">
          <form [formGroup]="editForm" novalidate (ngSubmit)="edit()">
            <input type="hidden" class="form-control" formControlName="id" id="id" placeholder="" required="true">
            <div class="row align-items-center mb-4 vendor-upload-image">
              <div class="col-2 d-flex justify-content-center pr-3">
                <img [src]="model?.image || '../../../assets/imgs/user-instagram.png'" height="64px" width="64px"
                  class="rounded-circle" alt="">
                </div>
                <div class="col-6 upload">
                  <or-upload mainMsg="Select profile photo to upload" smallMsg="or drag and drop .JPEG or .PNG here"
                    [includeProgressBar]=false acceptImages=true showFileName=false directToS3=true showFileIcon=false
                    class="upload" (uploadCompleted)="uploadCallback($event)">
                  </or-upload>
                </div>
              </div>
              <div class="row align-items-start mb-4">
                <div class="col">
                  <div class="row align-items-center mb-4">
                    <div class="col grey4">
                      <label class="required grey5 font-weight-bold">Your first name</label>
                      <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Sam Smith"
                        formControlName="firstName" id="firstName"
                        [ngClass]="submitted && utils.showCssValidField(editForm, 'firstName')" />
                      </div>
                    </div>
                    <div class="row align-items-center mb-4">
                      <div class="col grey4">
                        <label class="required grey5 font-weight-bold">E-mail</label>
                        <input type="text" class="form-control form-control-sm form-control-nav" disabled="true" id="email"
                          value="{{model.email}}" />
                        </div>
                      </div>
                      <div class="row align-items-center mb-4">
                        <div class="col grey4">
                          <label class="grey5 font-weight-bold">Title</label>
                          <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Title"
                            maxlength="30" formControlName="title" id="title" placeholder="Title" />
                          </div>
                        </div>
                        <div class="row align-items-center mb-4">
                          <div class="col grey4 country">
                            <label class="required grey5 font-weight-bold">Country</label>
                            <or-type-ahead name="countryList" [labelField]="'value'" [valueField]="'value'"
                              [(ngModel)]="countryList" [ngModelOptions]="{standalone: true}" (valueChange)="setCountry($event)"
                              [selectedValues]="countryList" [showLoading]="false" class="mr-2 w-100"
                              [dataCallback]="getCountryList" placeholder="Type to Find">
                            </or-type-ahead>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="row align-items-center mb-4">
                          <div class="col grey4">
                            <label class="required grey5 font-weight-bold">Your last name</label>
                            <input type="text" class="form-control form-control-sm form-control-nav" placeholder="Sam Smith"
                              id="lastName" formControlName="lastName"
                              [ngClass]="submitted && utils.showCssValidField(editForm, 'lastName')" />
                            </div>
                          </div>
                          <div class="row align-items-center mb-3">
                            <div class="col grey4">
                              <label class="grey5 font-weight-bold">Phone Number</label>
                              <div class="row">
                                <div class="col-4 pr-1 countrycode" [class.noborder]="callingCode?.length > 0">
                                  <div class="input-group mb-2">
                                    <or-type-ahead name="countryList" [labelField]="'name'" [valueField]="'value'"
                                      [(ngModel)]="callingCode" [ngModelOptions]="{standalone: true}"
                                      (valueChange)="setCallingCode($event)" [selectedValues]="callingCode" [showLoading]="false"
                                      [type]="'countryCode'" containerBody=true class="w-100 form-control-nav form-control-sm"
                                      [dataCallback]="getCountryCode" placeholder="Code">
                                    </or-type-ahead>
                                  </div>
                                </div>
                                <div class="col-5 pl-0">
                                  <input id="phone" type="text" class="form-control form-control-sm form-control-nav"
                                    formControlName="phone" placeholder="Number">
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row align-items-center mb-4">
                              <div class="col grey4">
                                <label class="grey5 font-weight-bold">Slack username or channel URL for alerts</label>
                                <input type="text" class="form-control form-control-sm form-control-nav"
                                  formControlName="slackUsername" id="slackUsername" placeholder="Slack username" />
                                </div>
                              </div>
                              <div class="row align-items-center mb-4">
                                <div class="col grey4 manager">
                                  <label class="grey5 font-weight-bold">My manager</label>
                                  <or-type-ahead [selectedValues]="selectedManagers === null ? [] : selectedManagers"
                                    [labelField]="'name'" [valueField]="'id'" [dataCallback]="getUserList"
                                    placeholder="Search for a User" (valueChange)="setManager($event)">
                                  </or-type-ahead>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col text-right pr-0">
                            <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14"
                            (click)="cancel()">Cancel</button>
                            <button type="submit" class="btn shadow-none font-weight-bold nav-font14 nav-btn-accent">Save</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                }

                @if (section === 'support') {
                  <div class="container-fluid px-4">
                    @if ((auth.isAdmin() || auth.isSuperAdmin()) && this.auth.isORInstance()) {
                      <div class="text-left mb-3">
                        <!-- <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" (click)="cancel()">Cancel</button> -->
                        <button type="button" (click)="openModelSupport()" class="btn shadow-none font-weight-bold nav-font14 nav-btn-accent">Send support access request</button>
                      </div>
                    }
                    <div class="card border-0 mt-2 mx-0 w-auto" style="width:940px !important">
                      <div class="card-body py-3">
                        <div class="mb-2">
                          <label for="supportEnable" class="font-weight-bold nav-font16 grey5">Enable support access</label>
                          <button type="button" class="btn btn-sm border-0 ml-2">
                            <or-toggle-button [id]="'supportEnable'" [(ngModel)]="supportEnable" (changed)="toggleChange($event)" class="light"></or-toggle-button>
                          </button>
                        </div>
                        <div class="mb-2">
                          <span class="grey5 nav-font14">Click the toggle to securely initiate or enable support access for your account.</span>
                        </div>
                        @if (supportEnable) {
                          <div class="card request mt-4">
                            <div class="card-body">
                              @if (supportRequest.requestSentFrom) {
                                <div class="row grey5 nav-font14">
                                  <div class="col mb-1">
                                    <label class="grey5 font-weight-bold d-inline-block mr-4" style="width: 110px;">Request from</label>
                                    <span>navigator-support&#64;opinionroute.com</span>
                                  </div>
                                </div>
                              }
                              <div class="row grey5 nav-font14">
                                @if (supportRequest.requestSentFrom) {
                                  <div class="col">
                                    <label class="grey5 font-weight-bold d-inline-block mr-4" style="width: 110px;">Request date</label>
                                    <span>{{supportRequest.requestSent | date: 'MM/dd/yyyy'}}</span>
                                  </div>
                                }
                              </div>
                              <div class="row grey5 nav-font14">
                                @if (supportRequest.status==='Approved') {
                                  <div class="col">
                                    <label class="grey5 font-weight-bold d-inline-block mr-4" style="width: 110px;">User action</label>
                                    <span>Allowed access on {{lastUpdatedTime | date:'short'}}</span>
                                  </div>
                                }
                              </div>
                              <hr class="my-2 w-100">
                              <span class="grey5 nav-font14">To approve this request, set an expiration below. This determines how long the access will be active before it ends.</span>
                              <div class="row grey5 nav-font14 mt-2">
                                <div class="col d-flex align-items-start">
                                  <label class="font-weight-bold mr-4 mt-1" style="width: 110px;">Access expires on</label>
                                  <div class="input-group mb-2 form-control-date">
                                    <input type="text"
                                      autocomplete="off"
                                      name="accessExpiresTime"
                                      readonly
                                      bsDatepicker
                                      placeholder="00/00/00"
                                      #dpStartDate="bsDatepicker"
                                      [bsValue]="accessExpiresDate"
                                      (bsValueChange)="accessExpiresDate= $event; checkCanSave()"
                                      [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', isAnimated: true, isOpen: true, showWeekNumbers:false, customTodayClass: 'todays-date' }"
                                      class="form-control form-control-sm nav-font14 date d-inline bg-white px-2">
                                      <span class="input-group-text bg-white" (click)="dpStartDate.toggle()"><i class="fak fa-calendar grey4"></i></span>
                                    </div>
                                    <select class="form-control form-control-sm form-control-nav d-inline ml-2" style="width: 131px;" (change)="checkCanSave(true)" [(ngModel)]="accessExpiresTimeTemp">
                                      <option value="0">00:00 AM</option>
                                      <option value="0">12:00 AM</option>
                                      <option value="1">1:00 AM</option>
                                      <option value="2">2:00 AM</option>
                                      <option value="3">3:00 AM</option>
                                      <option value="4">4:00 AM</option>
                                      <option value="5">5:00 AM</option>
                                      <option value="6">6:00 AM</option>
                                      <option value="7">7:00 AM</option>
                                      <option value="8">8:00 AM</option>
                                      <option value="9">9:00 AM</option>
                                      <option value="10">10:00 AM</option>
                                      <option value="11">11:00 AM</option>
                                      <option value="12">12:00 PM</option>
                                      <option value="13">1:00 PM</option>
                                      <option value="14">2:00 PM</option>
                                      <option value="15">3:00 PM</option>
                                      <option value="16">4:00 PM</option>
                                      <option value="17">5:00 PM</option>
                                      <option value="18">6:00 PM</option>
                                      <option value="19">7:00 PM</option>
                                      <option value="20">8:00 PM</option>
                                      <option value="21">9:00 PM</option>
                                      <option value="22">10:00 PM</option>
                                      <option value="23">11:00 PM</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="row grey5 nav-font14 align-items-center">
                                  <div class="col mb-1 d-flex align-items-center">
                                    <label class="font-weight-bold mr-4" style="width: 110px;">Select time zone</label>
                                    <select class="form-control form-control-sm form-control-nav d-inline" style="width: 270px;" (change)="checkCanSave(true)" [(ngModel)]="supportRequest.accessExpiresTimeZone">
                                      @for (zone of timezones; track zone) {
                                        <option [value]="zone?.offset">UTC{{zone?.offset}} - {{zone?.timeZoneName}}</option>
                                      }
                                    </select>
                                  </div>
                                  <div class="col text-right">
                                    <button type="button" [disabled]="denyButton"  *ngIf="denybtn"
                                      class="btn nav-btn-primary mx-2 shadow-none nav-font14"
                                      [class.nav-error]="cancelButton == 'Remove access'"
                                      (click)="denyAccessRequest()">
                                      @if (cancelButton == 'Remove access') {
                                        <i class="fak fa-trash nav-error mr-2"></i>
                                      }
                                      <span class="font-weight-bold">{{cancelButton}}</span>
                                    </button>
                                    <button type="button" [disabled]="allowbuttonDisabled" (click)="saveAccessRequest()" class="btn shadow-none font-weight-bold nav-font14 nav-btn-accent">{{saveButton}}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  }

                  <ng-template #modalSupport>
                    <div class="modal-header">
                      <div class="d-flex w-100 align-items-center">
                        <i class="fak fa-send-email mr-2 grey4 nav-font24"></i><h2 class="nav-secondary mb-0 ml-1 font-weight-bold nav-font18">Send Support Access Request</h2>
                      </div>
                      <div class="d-flex justify-content-end editable">
                        <i class="fas fa-close grey4 editable" (click)="openModalRef.hide()"></i>
                      </div>
                    </div>
                    <div class="modal-body nav-font14 grey5 pr-3 pb-3">
                      <div class="row align-items-start mb-2">
                        <div class="col grey5">
                          <span class="d-block">Enter the user's email below and click 'send' to request support access</span>
                          <input type="text" #supportEmail class="form-control form-control-sm form-control-nav mt-3" placeholder="Type e-mail" id="SupportEmail" />
                        </div>
                      </div>
                      <div class="row mt-4">
                        <div class="col d-flex justify-content-end">
                          <button type="button" class="btn nav-btn-primary mx-2 shadow-none font-weight-bold nav-font14" (click)="openModalRef.hide()">Cancel</button>
                          <button type="button" class="btn shadow-none font-weight-bold nav-font14 nav-btn-accent" (click)="sendSupportAcessEmail(supportEmail.value)">Send</button>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                </div>
